import { IInputValidator } from '../../interfaces/inputValidators/type';
import { injectable } from 'inversify';
import { IPollFiles } from '../../types';
import { DCValidationError } from '../../customError/docComponentError';

@injectable()
export class T360AdapterGetNotificationFilesInputValidator implements IInputValidator {
    performValidation = (nodeObj: IPollFiles): boolean => {
        // Condition can be added to fail the operation
        // if not received needed inputs

        if (nodeObj?.guids && nodeObj.guids instanceof Array) {
            return true;
        } else {
            throw new DCValidationError(
                'T360 Get Notification Files Input Validation Failed Params: Guids: ' + nodeObj?.guids,
            );
        }
    };
}
