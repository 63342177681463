export enum QueueItemStatus {
    NEW = 'NEW',
    STARTED = 'STARTED',
    COMPLETED = 'COMPLETED',
    STOPPED = 'STOPPED',
    PROCESSING = 'PROCESSING',
    ERROR = 'ERROR',
    FAILED = 'FAILED',
    CANCELLED = 'CANCELLED',
    PAUSE = 'PAUSE',
    RESUME = 'RESUME',
    PENDING = 'PENDING',
}

export enum CollectionNames {
    QUEUECONFIG = 'queueconfig',
    QUEUEPROPS = 'queueprops',
    QUEUE = 'queue',
}
