import { injectable } from 'inversify';
import { baseCommunicationService, BaseCommunicationEvent } from '@wk/office-companion-js-common';
import { Observable } from 'rxjs';

export enum LogLevelType {
    Error = 'error',
    Warn = 'warn',
    Info = 'info',
    Debug = 'debug',
    Verbose = 'verbose',
}

export enum AppSettingsChannel {
    UpdateStoreFilePath = 'app-update-store-file-path',
    UpdateLogLevel = 'app-update-log-level',
    UpdateSpellCheckLanguages = 'update-spellcheck-languages',
}

export class StoreFilePathDto {
    /**
     * the path in application settings where processed files will be temporary stored
     */
    public storeFilePath: string;
}

export class LogLevelDto {
    /**
     * the log level of all central-process log messages
     *
     * @type LogLevelType
     *
     * @link LogLevelType.Error, will log errors messages only
     * @link LogLevelType.Warn, will log errors and warnings messages
     * @link LogLevelType.Info will log errors, warnings, and info messages
     * @link LogLevelType.Debug will log all messages but verbose
     * @link LogLevelType.Verbose all messages will be logged
     */
    public logLevel: LogLevelType;
}

export class SpellCheckLanguageDto {
    /**
     * the language code selected in the tray settings and set in the browser window
     */
    public code: string;
}

@injectable()
export class AppSettingsService {
    public updateStoreFilePath(request: StoreFilePathDto): Observable<BaseCommunicationEvent<void>> {
        return baseCommunicationService.invoke<void>(AppSettingsChannel.UpdateStoreFilePath, request);
    }

    public updateLogLevel(request: LogLevelDto): Observable<BaseCommunicationEvent<void>> {
        return baseCommunicationService.invoke<void>(AppSettingsChannel.UpdateLogLevel, request);
    }

    public updateSpellCheckLanguages(request: SpellCheckLanguageDto[]): Observable<BaseCommunicationEvent<void>> {
        return baseCommunicationService.invoke<void>(AppSettingsChannel.UpdateSpellCheckLanguages, request);
    }
}
