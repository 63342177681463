import React from 'react';

interface ITabPanel {
    index: any;
    value: any;
    className?: string;
    tabName?: string;
    isSubTab?: boolean;
}

const TabPanel: React.FC<ITabPanel> = ({ children, value, index, className, tabName, isSubTab }) => {
    const idValue = isSubTab ? 'scrollable-auto-subtabpanel-' : 'scrollable-auto-tabpanel-';
    return (
        <div
            role="tabpanel"
            id={`${idValue + index}`}
            hidden={value !== index}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            className={className}
            data-testid={tabName}>
            {children}
        </div>
    );
};

export default TabPanel;
