import { getLogger as getLoggerInternal, Logger, LogLevel } from '@wk/elm-uui-common';
import { CHMessagingScope } from '@wk/elm-uui-context-handler';
import { messageBusDispatch } from '../components/contextLayerService/messageBusService';

/**
 * Returns a logger to be used to log messages.
 * @param loggerName The name of the logger. All logging names will be prefixed with UUI. automatically.
 * @returns Logger instance
 */
export const getLogger = (loggerName: string): Logger => {
    return getLoggerInternal('UUI.' + loggerName);
};

export const readLogLevelFromLocalStorage = (): LogLevel | undefined => {
    return localStorage.getItem('loggerLevel') !== null
        ? (parseInt(localStorage.getItem('loggerLevel')!) as LogLevel)
        : undefined;
};

export const saveLogLevelToLocalStorage = (logLevel: LogLevel): void => {
    localStorage.setItem('loggerLevel', logLevel.toString());
    messageBusDispatch({
        type: 'SetLoggingLevel',
        scope: CHMessagingScope.OtherInstances,
        message: logLevel.toString(),
    });
};
