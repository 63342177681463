import { injectable } from 'inversify';
import { apiRestFetchGet } from '../../restApi/fetch.utils';
import { JRAdapterActionPerformer } from './actionPerformer';
import { IActionableEntity } from '../../interfaces/actionEntity/type';
import { IDocOperationInvocation } from '../../interfaces/urlPattern/types';
import { factory } from '../../ConfigLog4j';
import * as convert from 'xml-js';
import { supportedEntityTypesArray, supportedEntityTypesMap } from '../utils/maps';
import { DCResponse } from '../../types';

const log = factory.getLogger('JRAdapterGetSupportedEntityTypesActionPerformer');

@injectable()
export class JRAdapterGetSupportedEntityTypesActionPerformer extends JRAdapterActionPerformer {
    fetchRequest = apiRestFetchGet;

    async performAction(
        obj: IActionableEntity,
        urlObj: Promise<string>,
        urlPatternObj: IDocOperationInvocation,
    ): Promise<DCResponse> {
        const response = await super.performAction(obj, urlObj, urlPatternObj);

        const outputInfo = async () => {
            const xmlData = await response.text();
            const dataObj = JSON.parse(convert.xml2json(xmlData));
            const jsonArray = dataObj['elements'][0]['elements'];
            const jsonObject: Record<string, string> = {};

            // Get all entity metadata and filter only those that we are interested in

            jsonArray.forEach((command: { [x: string]: { [x: string]: string }[] }) => {
                if (supportedEntityTypesArray.includes(command['elements'][0]['text'])) {
                    jsonObject[command['attributes']['href'].replace('/', '')] = command['elements'][0]['text'];
                }
            });
            log.debug('Command Object Created');
            const supporteETypesOutput: Record<number, string> = {};
            for (const key in jsonObject) {
                const value = jsonObject[key];
                supporteETypesOutput[key] = supportedEntityTypesMap[value];
            }
            return supporteETypesOutput;
            // return jsonObject;
        };
        const response2: DCResponse = Object.assign(new DCResponse(), response);
        response2.outputInfo = outputInfo;
        return response2;
    }
}
