import { CapabiltyEnum } from '../../enums/capability';
import { injectable } from 'inversify';
import { JRAdapterOperationPattern } from '../urlEntity/operationPattern';
import { IAddEmail_JR } from '../types';
import { DCEntityTypeEnum } from '../../types';

@injectable()
export class JRAdapterAddEmailUrlPattern extends JRAdapterOperationPattern {
    operationName = CapabiltyEnum.ADD_EMAIL;

    async getUrl(_nodeObj: IAddEmail_JR): Promise<string> {
        return this.hostName + '/' + this.objectRelative + '/' + this.ObjectTypeMap[DCEntityTypeEnum.EmailDocument];
    }
}
