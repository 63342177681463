/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { UUIHistory } from '../../hooks/useUUIHistory';
import { UUILocation } from '../../hooks/useUUILocation';
import { dispatchAddAndSelectSavedView, dispatchUpdateSavedView } from './context/listScreenAsyncActions';
import { saveCurrentView } from './listScreenHelpers';
import { IListScreenMetadata, IListScreenState, ISavedView, ListScreenDispatch } from './types';

export const ACTION_DIALOG_SAVE = 1;
export const ACTION_DIALOG_SAVEAS = 2;
export const ACTION_DIALOG_UNDO = 3;
export const ACTION_DIALOG_REPLACE = 4;
export const ACTION_DIALOG_NOACTION = 5;
export const ACTION_DIALOG_RENAME = 6;

export const saveTabAction = (
    listScreenState: IListScreenState,
    listScreenDispatch: ListScreenDispatch,
    savedViewUrl: string,
    listScreenUrl: string | undefined,
    closeActionType: any,
    location: UUILocation,
    history: UUIHistory,
): void => {
    const metadata = listScreenState.metadata!;
    const listDataPageObject = listScreenState.listData!.page;
    const savedViewPost: ISavedView = {
        ...listDataPageObject.savedView,
        isSystemDefault: false,
        columns: [listDataPageObject['column-data']],
        filters: listDataPageObject.filters,
        sort: listDataPageObject.sortInfo,
    };
    if (listDataPageObject.savedView.isSystemDefault || listDataPageObject.savedView.isUserDefault) {
        savedViewPost.isUserDefault = true;
    }

    saveCurrentView(savedViewPost, metadata.screenId, listScreenUrl, savedViewUrl).then((json) => {
        const savedView = {
            ...savedViewPost,
            id: json.id,
            name: json.name,
        };
        if (listDataPageObject.savedView.isSystemDefault) {
            // If saving a system default view, then add new view as user default
            dispatchAddAndSelectSavedView(listScreenState, listScreenDispatch, savedView, location, history);
        } else {
            dispatchUpdateSavedView(listScreenState, listScreenDispatch, savedView, location, history);
        }
        if (closeActionType) {
            listScreenDispatch({ type: closeActionType });
        }
    });
};

const isDuplicateTabName = (
    currentTab: ISavedView,
    newTabText: string,
    metadata: IListScreenMetadata,
    defaultText: string,
    isEmbeddedList: boolean,
) => {
    if ((currentTab.isUserDefault || currentTab.isSystemDefault) && !isEmbeddedList) {
        return newTabText.toLowerCase() === metadata.screenDisplayName.toLowerCase();
    } else if (newTabText.toLowerCase() === defaultText.toLowerCase() && isEmbeddedList) {
        return true;
    } else {
        return newTabText.toLowerCase() === currentTab.name.toLowerCase();
    }
};

// check duplicate tab name with validation rule
const checkDuplicateTabName = (
    metadata: IListScreenMetadata,
    newTabText: string,
    appResources: string,
    defaultText: string,
    isEmbeddedList: boolean,
): string => {
    let tabNameError = '';
    metadata.savedViews.forEach((currentTab: ISavedView) => {
        // a value is present, ensure no dupes
        if (isDuplicateTabName(currentTab, newTabText, metadata, defaultText, isEmbeddedList)) {
            let matchingTabName;
            if (newTabText.toLowerCase() === defaultText.toLowerCase()) {
                matchingTabName = defaultText;
            } else {
                matchingTabName =
                    currentTab.isUserDefault || currentTab.isSystemDefault
                        ? metadata.screenDisplayName
                        : currentTab.name;
            }

            tabNameError = appResources ? appResources.replace('{tabName}', matchingTabName) : 'Tab already exists.';
        }
    });
    return tabNameError;
};

export const saveViewIsValid = (
    metadata: IListScreenMetadata,
    appResources: any,
    saveAsRadioSelection: string,
    newTabName?: string,
    replaceTabId?: number,
    isEmbeddedList?: boolean,
): string => {
    let error = '';
    if (saveAsRadioSelection === 'saveAsNewTab') {
        const newTabText = newTabName;
        if (newTabText && newTabText.trim().length > 0) {
            const tabNameError = checkDuplicateTabName(
                metadata,
                newTabText,
                appResources.dialogContentInputDuplicateValidation,
                appResources.listscreenDefaultDropdownText,
                isEmbeddedList!,
            );
            error = tabNameError;
        } else {
            error = appResources.dialogContentInputValidation;
        }
    } else if (saveAsRadioSelection === 'saveAsReplaceTab') {
        if (replaceTabId === -1) {
            error = appResources.dialogContentSelectValidation;
        }
    }

    return error;
};
