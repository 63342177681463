import { LoggerInterface } from './logger.interface';

export class Logger implements LoggerInterface {
    private readonly prefix: string = 'OC_JS:';

    public log<T>(info: T): void {
        console.log(this.prefix, info);
    }

    public info<T>(info: T): void {
        console.info(this.prefix, info);
    }

    public error(...args: unknown[]): void {
        console.error(this.prefix, ...args);
    }
}
