import { IItemScreen } from './../services/interface';
import { ScreenName } from '../enum/enum';
import { injectable } from 'inversify';
import { ICHUploadType } from '../interfaces/operations';
import 'reflect-metadata';

@injectable()
export class ScreenHandler {
    private screenRequest: IItemScreen;

    constructor() {
        this.screenRequest = {};
    }

    public async getItemScreenRequest(
        screenName: ScreenName,
        payloadMeta: Record<string, string>,
        contextObj: ICHUploadType,
        title?: string,
    ): Promise<IItemScreen> {
        switch (screenName) {
            case ScreenName.QUICKFILE_ADD_DOCUMENT: {
                return this.initializeAddDocumentItemPage(screenName, payloadMeta, contextObj, title || '');
            }
            case ScreenName.QUICKFILE_CHECKIN_DOCUMENT: {
                return this.initializeQFCheckInItemPage(screenName, payloadMeta, contextObj);
            }
            case ScreenName.CHECKIN_DOCUMENT: {
                return this.initializeCheckInItemPage(screenName, payloadMeta, contextObj);
            }
            default:
                throw new Error('Unsupported screenName: ' + screenName);
        }
    }

    private async initializeAddDocumentItemPage(
        screenName: ScreenName,
        payloadMeta: Record<string, string>,
        contextObj: ICHUploadType,
        title: string,
    ): Promise<IItemScreen> {
        const payload = {
            name: {
                inputValue: payloadMeta.name,
                displayValue: payloadMeta.name,
            },
            document_original_type: {
                inputValue: payloadMeta.extension,
                displayValue: payloadMeta.extension,
            },
            documentName: {
                inputValue: payloadMeta.fileName,
                displayValue: payloadMeta.fileName,
            },
            comments: {
                inputValue: payloadMeta.comments,
                displayValue: payloadMeta.comments,
            },
            parentEntityId: {
                inputValue: payloadMeta.parentEntityId,
                displayValue: payloadMeta.parentEntityId,
            },
            document_folder_name: {
                inputValue: payloadMeta.folderId || '',
                displayValue: '',
            },
        };

        this.screenRequest.context = contextObj;
        this.screenRequest.payload = payload;
        this.screenRequest.screenName = screenName;
        this.screenRequest.title = title;
        return this.screenRequest;
    }

    private async initializeQFCheckInItemPage(
        screenName: ScreenName,
        payloadMeta: Record<string, string>,
        contextObj: ICHUploadType,
    ): Promise<IItemScreen> {
        const payload = {
            name: {
                inputValue: payloadMeta.name,
                displayValue: payloadMeta.name,
            },
            document_original_type: {
                inputValue: payloadMeta.extension,
                displayValue: payloadMeta.extension,
            },
            documentName: {
                inputValue: payloadMeta.fileName,
                displayValue: payloadMeta.fileName,
            },
            comments: {
                inputValue: payloadMeta.comments,
                displayValue: payloadMeta.comments,
            },
            parentEntityId: {
                inputValue: payloadMeta.parentEntityId,
                displayValue: payloadMeta.parentEntityId,
            },
        };

        this.screenRequest.context = contextObj;
        this.screenRequest.payload = payload;
        this.screenRequest.screenName = screenName;
        this.screenRequest.title = `Check in`;
        return this.screenRequest;
    }

    private async initializeCheckInItemPage(
        screenName: ScreenName,
        payloadMeta: Record<string, string>,
        contextObj: ICHUploadType,
    ): Promise<IItemScreen> {
        const payload = {
            name: {
                inputValue: payloadMeta.name,
                displayValue: payloadMeta.name,
            },
            document_original_type: {
                inputValue: payloadMeta.extension,
                displayValue: payloadMeta.extension,
            },
            documentName: {
                inputValue: payloadMeta.fileName,
                displayValue: payloadMeta.fileName,
            },
            comments: {
                inputValue: payloadMeta.comments,
                displayValue: payloadMeta.comments,
            },
            parentEntityId: {
                inputValue: payloadMeta.parentEntityId,
                displayValue: payloadMeta.parentEntityId,
            },
        };

        this.screenRequest.context = contextObj;
        this.screenRequest.payload = payload;
        this.screenRequest.screenName = screenName;
        this.screenRequest.title = `Check In`;
        return this.screenRequest;
    }
}
