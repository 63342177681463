import { ITransformer } from './types';
import { ICHDeleteFolder } from '../interfaces/operations';
import { IDeleteDocument } from '@wk/elm-uui-doc-component';
import 'reflect-metadata';
import { injectable } from 'inversify';

@injectable()
export class CtxDeleteFolderTransformer implements ITransformer {
    data!: ICHDeleteFolder;

    transform(): IDeleteDocument {
        const nodeObj = {} as IDeleteDocument;
        nodeObj.docId = this.data.documentId;
        nodeObj.entityContext = {
            name: this.data?.associatedEntityName || '',
            id: this.data?.associatedEntityId,
            type: this.data?.associatedEntityType,
        };
        nodeObj.extendedProps = {};
        nodeObj.extendedProps.loggedInUser = this.data?.loggedInUser;
        return nodeObj;
    }
}
