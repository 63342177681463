import { IInputValidator } from '../../interfaces/inputValidators/type';
import { injectable } from 'inversify';
import { IAddEmail } from '../../types';
import { DCValidationError } from '../../customError/docComponentError';
import { IAddEmail_t360 } from '../types';

@injectable()
export class T360AdapterAddEmailInputValidator implements IInputValidator {
    performValidation = (nodeObj: IAddEmail): boolean => {
        // Condition can be added to fail the operation
        // if not received needed inputs

        const nodeObj1 = nodeObj as IAddEmail_t360;
        if (nodeObj1.file && nodeObj1?.entityContext?.id) {
            return true;
        } else {
            throw new DCValidationError('Add Email T360 Params: EntityId : ' + nodeObj1?.entityContext?.id);
        }
    };
}
