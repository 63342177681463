import { IMessageBus, UUIReduxAction } from './types';
import { uniqueId } from 'lodash';

// TODO: entityWasDeleted needs to be deleted
export const messageBusReducer = (state: IMessageBus, action: UUIReduxAction): IMessageBus => {
    switch (action.type) {
        case 'RefreshUUIForEntity':
            return {
                ...state,
                refreshUUIForEntityIsProcessed: false,
                refreshUUIForEntity: {
                    ...action.refreshUUIForEntity,
                    key: uniqueId('RefreshUUIKey_'),
                },
            };
        case 'MarkRefreshUUIForEntityAsProcessed':
            return {
                ...state,
                refreshUUIForEntityIsProcessed: true,
                suppressNextRefresh: false,
            };
        case 'SuppressNextRefresh':
            return {
                ...state,
                suppressNextRefresh: true,
            };
    }
    return state;
};
