import React from 'react';
import { ControlTypeProps } from '../types';
import ReadOnlyControl from './readOnlyControl';
import { useFormContext } from 'react-hook-form';
import { ControlLabel } from './controlLabel';
import { UUIInput } from '../../common/uuiInput';
import CurrencyControl from './currencyControl';
import { Grid } from '@material-ui/core';
import { getReactHookFormFieldName, getValidationMessagesForField } from '../itemScreenHelpers';

const MoneyControl: React.FC<ControlTypeProps> = ({ field, fieldData, readOnly }) => {
    const { register, errors } = useFormContext();

    if (readOnly) {
        return <ReadOnlyControl field={field} fieldData={fieldData} />;
    }

    return (
        <Grid container>
            <Grid item xs={7} sm={9} md={10}>
                <ControlLabel
                    data-testid="moneyControl"
                    field={field}
                    control={
                        <Grid item xs={12}>
                            <UUIInput
                                fullWidth
                                id={getReactHookFormFieldName(field)}
                                name={getReactHookFormFieldName(field)}
                                error={getValidationMessagesForField(field, errors, 'errors').length > 0}
                                warning={getValidationMessagesForField(field, errors, 'warnings').length > 0}
                                inputRef={register}
                                defaultValue={fieldData.inputValue || ''}
                            />
                        </Grid>
                    }
                />
            </Grid>
            <Grid item xs={5} sm={3} md={2}>
                <CurrencyControl field={field} fieldData={fieldData} />
            </Grid>
        </Grid>
    );
};

export default MoneyControl;
