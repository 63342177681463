import React from 'react';
// import Tabs from '../common/tabs';
import UUITab from '../common/uuiTab';
import UUITabs from '../common/uuiTabs';
import { useListScreenState } from './context/listScreenContext';
import { useListScreenSavedViews } from './context/listScreenHooks';

const ListScreenSavedViewTabs: React.FC = () => {
    const listScreenState = useListScreenState();
    const metadata = listScreenState.metadata!;
    const { firstSavedViewValue, orderedSavedViews, selectedSavedViewId, selectedSavedViewName, loadSavedView } =
        useListScreenSavedViews();

    return (
        <div>
            <UUITabs
                textColor="primary"
                value={selectedSavedViewId}
                name={selectedSavedViewName}
                onChange={loadSavedView}
                data-testid="tabs-container">
                <UUITab value={firstSavedViewValue} label={metadata.screenDisplayName} />
                {orderedSavedViews.map((savedView) => (
                    <UUITab key={savedView.id} value={savedView.id} label={savedView.name} />
                ))}
            </UUITabs>
        </div>
    );
};

export default ListScreenSavedViewTabs;
