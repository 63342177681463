import { getActivePassportContext, getPassportContextDefaultLandingPageUrl } from '../utils/passportContextHelper';
import { useReduxSelector } from './useReduxSelector';

export const useHomePageUrl = (): string => {
    const appsMenu = useReduxSelector((state) => state.appsMenu);
    const appResources = useReduxSelector((state) => state.appResources);
    try {
        return getPassportContextDefaultLandingPageUrl(getActivePassportContext(appsMenu), appResources.v3HomeUrl);
    } catch (error) {
        return '';
    }
};
