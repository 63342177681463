import React from 'react';
import css from './menuHeader.module.scss';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

interface ISingleLevelMenuProps {
    toggleDrawer: () => void;
    activePassportContext: any;
}

const MenuHeader: React.FC<ISingleLevelMenuProps> = ({ toggleDrawer, activePassportContext }) => {
    return (
        <div className={css.menuActionPanel}>
            <IconButton
                edge="start"
                data-testid="menuButton"
                aria-label="menu"
                onClick={() => {
                    toggleDrawer();
                }}
                className={css.menuIcon}>
                <MenuIcon />
            </IconButton>
            <Typography className={css.menuActionPanelText}>{activePassportContext.displayName}</Typography>
            <IconButton
                size="small"
                onClick={() => {
                    toggleDrawer();
                }}
                data-testid="menu-close-icon"
                aria-label="Close Menu"
                className={css.closeIcon}>
                <CloseIcon className={css.closeIconCore} />
            </IconButton>
        </div>
    );
};

export default MenuHeader;
