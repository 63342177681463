import { IDocTransformer } from '../../interfaces/transformer/type';
import { injectable } from 'inversify';
import { factory } from '../../ConfigLog4j';
import { IMatterDoc, IRenameDocument } from '../../types';
import { JRAdapterRenameDocumentActionableEntity } from '../actionableEntity/renameDocumentActionableEntity';
import { mapHref, mapNames } from '../utils/maps';
import { toBase64 } from '../../utils/main.utils';
import { attribute_Rename } from '../types';

const log = factory.getLogger('JRAdapterRenameDocumentEntityTransformer');

@injectable()
export class JRAdapterRenameDocumentEntityTransformer implements IDocTransformer {
    matterDoc: IMatterDoc;
    async transformIntoActionableEntity(nodeObj: IRenameDocument): Promise<JRAdapterRenameDocumentActionableEntity> {
        log.debug('Calling transformIntoActionableEntity');
        const obj = {} as JRAdapterRenameDocumentActionableEntity;
        // TO CREATE ACTIONABLE ENTITY FROM nodeObj and matterDoc
        obj.attributes = {
            // name: nodeObj.name,
            // associatedEntityName: nodeObj.entityContext.type,
            // documentFile: { name: nodeObj.name },
            // [mapNames[nodeObj.entityContext.type]]: {
            //   href: '../../' + mapHref[nodeObj.entityContext.type] + '/' + nodeObj.entityContext.id,
            // },
        } as attribute_Rename & { documentFile: { name: string } };

        if (nodeObj?.name) {
            obj.attributes.name = nodeObj.name;
            obj.attributes.documentFile = { name: nodeObj.name };
        }

        if (nodeObj.entityContext?.type) {
            obj.attributes.associatedEntityName = nodeObj.entityContext.type;
        }

        if (nodeObj.file) {
            obj.data = await toBase64(nodeObj.file);
        }

        if (nodeObj?.entityContext?.id && nodeObj?.entityContext?.type) {
            obj.attributes[mapNames[nodeObj.entityContext.type]] = {
                href: '../../' + mapHref[nodeObj.entityContext.type] + '/' + nodeObj.entityContext.id,
            };
        }

        return obj;
    }
}
