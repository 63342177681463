import { ContainerModule, interfaces } from 'inversify';
import { IDocActionPerformer } from './interfaces/actionPerformers/type';
import { IDocTransformer } from './interfaces/transformer/type';

import { IInputValidator } from './interfaces/inputValidators/type';

import { T360AdapterTransformFulfiller } from './t360Adapter/transformFulfiller/transformFulfiller';

import { T360AdapterRenameFolderEntityTransformer } from './t360Adapter/transformer/renameFolderTransformer';

import { T360AdapterAddFolderEntityTransformer } from './t360Adapter/transformer/addFolderTransformer';
import { T360AdapterAddFolderInputValidator } from './t360Adapter/inputValidator/addFolderInputValidator';

import { T360AdapterCheckInActionPerformer } from './t360Adapter/actionPerformer/checkInActionPerformer';
import { T360AdapterCheckInEntityTransformer } from './t360Adapter/transformer/checkInTransformer';
import { T360AdapterCheckInInputValidator } from './t360Adapter/inputValidator/checkInInputValidator';

import { T360AdapterAddDocumentActionPerformer } from './t360Adapter/actionPerformer/addDocumentActionPerformer';
import { T360AdapterAddDocumentEntityTransformer } from './t360Adapter/transformer/addDocumentTransformer';
import { T360AdapterAddDocumentInputValidator } from './t360Adapter/inputValidator/addDocumentInputValidator';

import { IDMToken } from './interfaces/dmTokenHandler/type';
import { ITransformFulfiller } from './interfaces/transformFulfiller/transformFulfiller';
import { IDocAdaptor } from './interfaces/adapter/types';

import { T360Adapter } from './t360Adapter';
import { T360AdapterAccessToken } from './t360Adapter/tokenHandler/tokenHandler';

import { T360AdapterAddEmailActionPerformer } from './t360Adapter/actionPerformer/addEmailActionPerformer';
import { T360AdapterAddEmailEntityTransformer } from './t360Adapter/transformer/addEmailTransformer';
import { T360AdapterAddEmailInputValidator } from './t360Adapter/inputValidator/addEmailInputValidator';

import { T360AdapterDocumentMetadataActionPerformer } from './t360Adapter/actionPerformer/documentMetadataActionPerformer';
import { T360AdapterDocumentMetadataEntityTransformer } from './t360Adapter/transformer/documentMetadataTransformer';

import { T360AdapterDocumentFileNameMetadataActionPerformer } from './t360Adapter/actionPerformer/documentMetadataFileNameActionPerformer';
import { T360AdapterDocumentFileNameMetadataEntityTransformer } from './t360Adapter/transformer/documentMetadataFileNameTransformer';

import { T360AdapterDocumentMetadataEmailActionPerformer } from './t360Adapter/actionPerformer/documentMetadataEmailActionPerformer';

import { T360AdapterGetNotificationFilesActionPerformer } from './t360Adapter/actionPerformer/getNotificationFilesActionPerformer';
import { T360AdapterGetNotificationFilesEntityTransformer } from './t360Adapter/transformer/getNotificationFilesTransformer';
import { T360AdapterGetNotificationFilesInputValidator } from './t360Adapter/inputValidator/getNotificationFilesInputValidator';

import { T360AdapterGetSupportedEntityTypesActionPerformer } from './t360Adapter/actionPerformer/getSupportedEntityTypesActionPerformer';
import { T360AdapterActionPerformer } from './t360Adapter/actionPerformer/actionPerformer';
import { T360AdapterEntityTransformer } from './t360Adapter/transformer/transformer';

import { T360AdapterEditDocumentEntityTransformer } from './t360Adapter/transformer/editDocumentTransformer';
import { T360AdapterEditFolderEntityTransformer } from './t360Adapter/transformer/editFolderTransformer';
import { T360AdapterValidateDocumentEntityTransformer } from './t360Adapter/transformer/validateDocumentTransformer';
import { T360AdapterValidateCheckInDocumentEntityTransformer } from './t360Adapter/transformer/validateCheckInDocumentTransformer';
import { T360AdapterFolderMetadataActionPerformer } from './t360Adapter/actionPerformer/folderMetadataActionPerformer';

export const t360Container = new ContainerModule((bind: interfaces.Bind) => {
    // T360Adapter AddFolder bindings

    bind<IDocTransformer>('T360AdapterAddFolderActionTransformer').to(T360AdapterAddFolderEntityTransformer);
    bind<IInputValidator>('T360AdapterAddFolderInputValidator').to(T360AdapterAddFolderInputValidator);

    bind<IDMToken>('T360AdapterToken').to(T360AdapterAccessToken);
    bind<IDocAdaptor>('T360Adapter').to(T360Adapter);
    bind<ITransformFulfiller>('T360AdapterTransformFulfiller').to(T360AdapterTransformFulfiller);

    bind<IDocTransformer>('T360AdapterAddDocumentActionTransformer').to(T360AdapterAddDocumentEntityTransformer);
    bind<IInputValidator>('T360AdapterAddDocumentInputValidator').to(T360AdapterAddDocumentInputValidator);

    bind<IDocTransformer>('T360AdapterValidateDocumentActionTransformer').to(
        T360AdapterValidateDocumentEntityTransformer,
    );
    bind<IDocTransformer>('T360AdapterValidateCheckInDocumentActionTransformer').to(
        T360AdapterValidateCheckInDocumentEntityTransformer,
    );
    // T360Adapter DownloadDocument bindings

    // T360Adapter CheckIn bindings

    bind<IDocTransformer>('T360AdapterCheckInActionTransformer').to(T360AdapterCheckInEntityTransformer);
    bind<IInputValidator>('T360AdapterCheckInInputValidator').to(T360AdapterCheckInInputValidator);

    bind<IDocActionPerformer>('T360AdapterAddEmailActionPerformer').to(T360AdapterAddEmailActionPerformer);

    bind<IDocActionPerformer>('T360AdapterAddDocumentActionPerformer').to(T360AdapterAddDocumentActionPerformer);

    bind<IDocActionPerformer>('T360AdapterGetNotificationFilesActionPerformer').to(
        T360AdapterGetNotificationFilesActionPerformer,
    );
    bind<IDocActionPerformer>('T360AdapterCheckInActionPerformer').to(T360AdapterCheckInActionPerformer);

    bind<IDocTransformer>('T360AdapterAddEmailActionTransformer').to(T360AdapterAddEmailEntityTransformer);
    bind<IInputValidator>('T360AdapterAddEmailInputValidator').to(T360AdapterAddEmailInputValidator);

    // T360Adapter DeleteEmail bindings

    // T360Adapter RenameFolder bindings
    bind<IDocTransformer>('T360AdapterRenameFolderActionTransformer').to(T360AdapterRenameFolderEntityTransformer);

    // T360Adapter DownloadEmail bindings

    // Document Metadata
    bind<IDocActionPerformer>('T360AdapterDocumentMetadataActionPerformer').to(
        T360AdapterDocumentMetadataActionPerformer,
    );
    bind<IDocTransformer>('T360AdapterDocumentMetadataActionTransformer').to(
        T360AdapterDocumentMetadataEntityTransformer,
    );

    // Document Metadata FileName
    bind<IDocActionPerformer>('T360AdapterDocumentMetadataFileNameActionPerformer').to(
        T360AdapterDocumentFileNameMetadataActionPerformer,
    );

    bind<IDocTransformer>('T360AdapterDocumentMetadataFileNameActionTransformer').to(
        T360AdapterDocumentFileNameMetadataEntityTransformer,
    );

    bind<IDocActionPerformer>('T360AdapterDocumentMetadataEmailActionPerformer').to(
        T360AdapterDocumentMetadataEmailActionPerformer,
    );

    // T360Adapter GetNotificationFiles bindings

    bind<IDocActionPerformer>('T360AdapterActionPerformer').to(T360AdapterActionPerformer);
    bind<IDocTransformer>('T360AdapterGetNotificationFilesActionTransformer').to(
        T360AdapterGetNotificationFilesEntityTransformer,
    );
    bind<IInputValidator>('T360AdapterGetNotificationFilesInputValidator').to(
        T360AdapterGetNotificationFilesInputValidator,
    );
    bind<IDocTransformer>('T360AdapterActionTransformer').to(T360AdapterEntityTransformer);

    bind<IDocActionPerformer>('T360AdapterGetSupportedEntityTypesActionPerformer').to(
        T360AdapterGetSupportedEntityTypesActionPerformer,
    );

    bind<IDocTransformer>('T360AdapterEditDocumentActionTransformer').to(T360AdapterEditDocumentEntityTransformer);
    bind<IDocTransformer>('T360AdapterEditFolderActionTransformer').to(T360AdapterEditFolderEntityTransformer);

    // Document Metadata
    bind<IDocActionPerformer>('T360AdapterFolderMetadataActionPerformer').to(T360AdapterFolderMetadataActionPerformer);
});
