import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import { getMaxLogMessageLength, initializeLoggerFactory } from '@wk/elm-uui-common';
import { EventType, ToastType } from '@wk/elm-uui-context-handler';
import { clPublish } from '../contextLayerService/contextLayerService';
import React, { useEffect, useState } from 'react';
import { useReduxDispatch } from '../../hooks/useReduxDispatch';
import { useReduxSelector } from '../../hooks/useReduxSelector';
import { ILogLevel } from '../../reducers/types';
import { saveLogLevelToLocalStorage } from '../../utils/loggingService';
import ActionDialog from '../common/actionDialog';
import css from './logLevelSelectorDialog.module.scss';

export interface ILogLevelSelectorDialog {
    logLevels: ILogLevel[];
    defaultLogLevel: ILogLevel;
}

export const LogLevelSelectorDialog: React.FC<ILogLevelSelectorDialog> = ({ logLevels, defaultLogLevel }) => {
    const [logLevelRadioSelection, setLogLevelRadioSelection] = useState<ILogLevel | undefined>(defaultLogLevel);

    const reduxDispatch = useReduxDispatch();
    const appResources = useReduxSelector((state) => state.appResources);
    const isLogLevelSelectorDialogOpen = useReduxSelector((state) => state.ui.helpSection.logLevelSelectorDialogOpen);
    const storedLogLevel = useReduxSelector((state) => state.ui.helpSection.logLevel);
    useEffect(() => {
        if (storedLogLevel.value) {
            setLogLevelRadioSelection(storedLogLevel);
        }
    }, [storedLogLevel]);

    const closeClickHandler = () => {
        reduxDispatch({ type: 'LogLevelSelectorDialogOpen', isOpen: false });
        setLogLevelRadioSelection(defaultLogLevel);
    };

    const actionButtonClickHandler = () => {
        const selectedLogLevel = logLevelRadioSelection as unknown as ILogLevel;
        saveLogLevelToLocalStorage(selectedLogLevel.key);
        initializeLoggerFactory(selectedLogLevel.key, getMaxLogMessageLength());
        clPublish({
            name: EventType.TOAST,
            toast: {
                toastMessage: `Log Level successfully changed to ${selectedLogLevel.value}`,
                type: ToastType.SUCCESS,
            },
        });
        reduxDispatch({ type: 'SaveLogLevel', updateLogLevel: selectedLogLevel });
        reduxDispatch({ type: 'LogLevelSelectorDialogOpen', isOpen: false });
    };

    return (
        <ActionDialog
            open={isLogLevelSelectorDialogOpen}
            handleActionClose={closeClickHandler}
            actionButtonClickHandler={actionButtonClickHandler}
            title={appResources.helpPageDiagnostics}
            actionButtonText={appResources.buttonSave}
            disableActionButton={false}
            disableCloseButtonIcon={true}>
            <FormControl component="fieldset">
                <FormLabel component="legend" className={css.legendText}>
                    {appResources.helpPageTroubleshootingLog}
                </FormLabel>
                <RadioGroup
                    aria-label="logger-level-options"
                    value={logLevelRadioSelection?.key.toString()}
                    onChange={(event) => {
                        const selectedValue = logLevels.find((val) => val.key.toString() === event.target.value);
                        setLogLevelRadioSelection(selectedValue);
                    }}
                    data-testid="log-level-selector"
                    className={css.radioGroup}>
                    {logLevels.map((level: ILogLevel, index: number) => {
                        return (
                            <FormControlLabel
                                key={index}
                                value={level.key.toString()}
                                control={
                                    <Radio
                                        color="primary"
                                        data-testid={'loglevel-' + level.key.toString()}
                                        className={css.radioIcon}
                                    />
                                }
                                label={level.value}
                                classes={{
                                    label: css.logLevelText,
                                }}
                            />
                        );
                    })}
                </RadioGroup>
            </FormControl>
        </ActionDialog>
    );
};
