import { Container, interfaces } from 'inversify';
import { commonContainer } from '../../common/common.module';
import { TokenManagmentService } from './tokenManagment.service';

const importedContainers = [commonContainer];

const TokenManagmentContainer: interfaces.Container = importedContainers.reduce(
    (container, imports) => Container.merge(imports, container),
    new Container(),
);

TokenManagmentContainer.bind<TokenManagmentService>(TokenManagmentService).toSelf().inSingletonScope();

export const TokenManagment = TokenManagmentContainer.get(TokenManagmentService);
