import { commonContainer } from '../../common/common.module';
import { Container, interfaces } from 'inversify';
import { FileOperationsInterface, FileOperationsService } from './fileOperations.service';

const importedContainers = [commonContainer];

const fileContainer: interfaces.Container = importedContainers.reduce(
    (container, imports) => Container.merge(imports, container),
    new Container(),
);

fileContainer.bind<FileOperationsInterface>(FileOperationsService).toSelf().inSingletonScope();

export { fileContainer };
