import { IconButton, Tooltip } from '@material-ui/core';
import { IQueueItem, QueueItemStatus } from '@wk/elm-uui-context-handler';
import React, { useEffect, useRef } from 'react';
import { useReduxDispatch } from '../../hooks/useReduxDispatch';
import { useReduxSelector } from '../../hooks/useReduxSelector';
import {
    closeFailedUploadFlyout,
    openFailedUploadFlyout,
    readClosedFailedUploadFlyoutAtFromLocalStorage,
    updateUploadingFlyoutState,
} from '../../utils/activitiesService';
import WkQueueErrorIcon from '../icons/wkQueueErrorIcon';
import WkQueueIcon from '../icons/wkQueueIcon';
import WkQueueSuccessIcon from '../icons/wkQueueSuccessIcon';
import ProgressIndicatorFlyout from './progressIndicatorFlyout';
import css from './uploadQueueView.module.scss';
import classNames from 'classnames';

export interface IUploadQueueView {
    anchorEl?: any;
}

const UploadQueueView: React.FC<IUploadQueueView> = ({ anchorEl }) => {
    const isHistoryDialogOpen = useReduxSelector((state) => state.ui.historyScreen.isHistoryDialogOpen);
    const appResources = useReduxSelector((state) => state.appResources);
    const uploadHistoryItems = useReduxSelector((state) => state.ui.uploadHistoryItems);
    const isUserScreenDialogOpen = useReduxSelector((state) => state.ui.userScreen.isUserScreenDialogOpen);
    const isFailedUploadFlyoutOpen = useReduxSelector((state) => state.ui.historyScreen.isFailedUploadFlyoutOpen);
    const reduxDispatch = useReduxDispatch();

    const buttonEl = useRef(null);
    let icon = <WkQueueIcon className={isHistoryDialogOpen ? css.iconClear : css.iconWhite} />;
    // todo: remove null
    let count: string | null = null;
    let newFailedUploadCount = 0;

    const queueCount = uploadHistoryItems.filter(
        (item: IQueueItem) =>
            item.status === QueueItemStatus.NEW ||
            item.status === QueueItemStatus.STARTED ||
            item.status === QueueItemStatus.PENDING ||
            item.status === QueueItemStatus.PROCESSING,
    ).length;

    if (uploadHistoryItems.some((item: IQueueItem) => item.status === QueueItemStatus.FAILED)) {
        icon = <WkQueueErrorIcon className={isHistoryDialogOpen ? css.iconError : css.iconWhite} />;

        const closedFailedUploadFlyoutAt = readClosedFailedUploadFlyoutAtFromLocalStorage();
        if (closedFailedUploadFlyoutAt) {
            const lastFailedUploadFlyoutCloseDate = new Date(closedFailedUploadFlyoutAt);

            newFailedUploadCount = uploadHistoryItems.filter((item: IQueueItem) => {
                const failedQueueItemDate = new Date(item.created);
                return item.status === QueueItemStatus.FAILED && failedQueueItemDate > lastFailedUploadFlyoutCloseDate;
            }).length;
        }
    } else if (queueCount > 0) {
        icon = <WkQueueIcon className={isHistoryDialogOpen ? css.iconClear : css.iconWhite} />;
        count = queueCount > 99 ? '99+' : queueCount.toString();
    } else if (uploadHistoryItems.some((item: IQueueItem) => item.status === QueueItemStatus.COMPLETED)) {
        icon = <WkQueueSuccessIcon className={isHistoryDialogOpen ? css.iconSuccess : css.iconWhite} />;
    }

    useEffect(() => {
        if (newFailedUploadCount > 0 && !isFailedUploadFlyoutOpen) {
            openFailedUploadFlyout(reduxDispatch);
        }
    }, [isFailedUploadFlyoutOpen, newFailedUploadCount, reduxDispatch]);

    const openCloseHistoryScreenDialog = () => {
        closeFailedUploadFlyout(reduxDispatch);
        updateUploadingFlyoutState(false, reduxDispatch);
        if (!isHistoryDialogOpen) {
            isUserScreenDialogOpen && reduxDispatch({ type: 'CloseUserScreenDialog' });
            reduxDispatch({ type: 'OpenHistoryScreenDialog' });
        } else {
            reduxDispatch({ type: 'CloseHistoryScreenDialog' });
        }
    };

    return (
        <div>
            <Tooltip
                title={appResources.historyItemActivity}
                classes={{
                    tooltip: css.tooltip,
                }}>
                <IconButton
                    ref={buttonEl}
                    color="inherit"
                    disableRipple={isHistoryDialogOpen}
                    onClick={openCloseHistoryScreenDialog}
                    className={isHistoryDialogOpen ? css.activeProfile : undefined}
                    data-testid="history-button">
                    <span
                        className={classNames(css.uploadCount, { [css.uploadCountTitle]: isHistoryDialogOpen })}
                        data-testid="history-upload-count">
                        {count}
                    </span>
                    {icon}
                </IconButton>
            </Tooltip>
            <ProgressIndicatorFlyout anchorEl={anchorEl ? anchorEl : buttonEl.current} />
        </div>
    );
};

export default UploadQueueView;
