import { IInputValidator } from '../../interfaces/inputValidators/type';
import { injectable } from 'inversify';
// import { ValidationUtils } from '../../libraries/validationUtils';
import { IValidationInfo } from '../../dmInfoHandler/types';
// import { isNodeEnvironment } from '../../utils';
import { IAddDocument } from '../../docManager';
import { DCValidationError } from '../../customError/docComponentError';
import { IAddDocument_T360 } from '../types';

@injectable()
export class T360AdapterAddDocumentInputValidator implements IInputValidator {
    validationInfo!: IValidationInfo;

    performValidation = (nodeObj: IAddDocument): boolean => {
        const nodeObj1 = nodeObj as IAddDocument_T360;
        // Condition can be added to fail the operation
        // if not received needed inputs
        // const validator = new ValidationUtils();
        // const fileName = Reflect.get(nodeObj, 'documentName');
        // const file = Reflect.get(nodeObj, 'file');
        // const extendedProps = Reflect.get(nodeObj, 'extendedProps');

        // let fileSize;
        if (!nodeObj1?.file) {
            // if (isNodeEnvironment()) {
            //   fileSize = file.length;
            // } else {
            //   fileSize = file.size;
            // }
            // } else {
            throw new Error('Input Validation Failed');
        }
        if (
            nodeObj1?.documentName &&
            nodeObj1?.documentFolderId &&
            nodeObj1?.viewAccessType &&
            nodeObj1?.editAccessType
            // validator.checkSpecialCharacters(fileName, this.validationInfo) &&
            // validator.checkDocumentFileSize(fileSize, validator.getFileSize(this.validationInfo, extendedProps)) &&
            // validator.checkFileType(fileName, this.validationInfo)
        ) {
            return true;
        } else {
            throw new DCValidationError(
                'Add Document for T360 Params:  Name: ' +
                    nodeObj1?.documentName +
                    'DocumentFolderId: ' +
                    nodeObj1.documentFolderId +
                    ' View AccessType: ' +
                    nodeObj1?.viewAccessType +
                    ' Edit Access Type: ' +
                    nodeObj1?.editAccessType,
            );
        }
    };
}
