import React, { useEffect, useRef } from 'react';
import { Grid, ListItem, ListItemText, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import composeRefs from '@seznam/compose-react-refs';
import { Draggable, DropResult } from 'react-beautiful-dnd';
import ReorderableList from '../listScreen/reorderableList';
import css from './transferBasketList.module.scss';
import TransferBasketPanel from './transferBasketPanel';
import { ITransferBasketItem } from './types';
import { useReduxSelector } from '../../hooks/useReduxSelector';

export interface ITransferBasketList {
    items: ITransferBasketItem[];
    currentlySelectedItem?: ITransferBasketItem;
    onSelectItem: (item: ITransferBasketItem) => void;
    onDoubleClickItem: (item: ITransferBasketItem) => void;
    onReorder: (items: ITransferBasketItem[]) => void;
    onBlur: () => void;
}

const TransferBasketList: React.FC<ITransferBasketList> = ({
    items,
    currentlySelectedItem,
    onSelectItem,
    onDoubleClickItem,
    onReorder,
    onBlur,
}) => {
    const sortedItems = [...items].sort((a, b) => a.listOrder! - b.listOrder!);

    const onDragEnd = (result: DropResult) => {
        const { destination, source } = result;
        // if no destination, or dropped in the original position
        if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
            return;
        }

        let reorderedList = [...sortedItems];
        const movingItems = reorderedList.splice(source.index, 1);
        reorderedList.splice(destination.index, 0, movingItems[0]);
        reorderedList = reorderedList.map((item, i) => {
            return { ...item, listOrder: i };
        });
        onReorder(reorderedList);
    };

    return (
        <TransferBasketPanel onBlur={onBlur}>
            <ReorderableList onDragEnd={onDragEnd}>
                {sortedItems.map((currentItem, index) => {
                    const isShowBorder = items.length > 5 && index === 4;
                    return (
                        <ReorderableListItem
                            currentItem={currentItem}
                            key={currentItem.id}
                            index={index}
                            onSelectItem={onSelectItem}
                            onDoubleClickItem={onDoubleClickItem}
                            isSelected={currentlySelectedItem && currentlySelectedItem.id === currentItem.id}
                            className={isShowBorder ? css.separatorBorder : ''}
                        />
                    );
                })}
            </ReorderableList>
        </TransferBasketPanel>
    );
};

interface ITransferBasketListTitle {
    itemsCount: number;
}

export const TransferBasketListTitle: React.FC<ITransferBasketListTitle> = ({ itemsCount }) => {
    const transferBasketListResources = useReduxSelector((state) => ({
        listscreenColumnsSelected: state.appResources.listscreenColumnsSelected,
        listscreenColumnsSelectedCountText: state.appResources.listscreenColumnsSelectedItemsCount.replace(
            '{count}',
            itemsCount.toString(),
        ),
        listscreenColumnsMobileViewportMessage: state.appResources.listscreenColumnsMobileViewportMessage,
    }));

    return (
        <>
            <Grid container justify="space-between" alignItems="center" className={css.transferBasketContainer}>
                <Grid item>
                    <Typography className={css.headerStyle} variant="h6">
                        {transferBasketListResources.listscreenColumnsSelected}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography className={css.subtitle2} variant="subtitle2" align="right" color="textSecondary">
                        {transferBasketListResources.listscreenColumnsSelectedCountText}
                    </Typography>
                </Grid>
            </Grid>

            <div>
                <Typography variant="caption" data-testid={'selectionLimitMsg'}>
                    {transferBasketListResources.listscreenColumnsMobileViewportMessage}
                </Typography>
            </div>
        </>
    );
};

interface IReorderableListItem {
    currentItem: ITransferBasketItem;
    index: number;
    onSelectItem: (item: ITransferBasketItem) => void;
    onDoubleClickItem: (item: ITransferBasketItem) => void;
    isSelected: boolean | undefined;
    className: string;
}

const ReorderableListItem: React.FC<IReorderableListItem> = ({
    currentItem,
    index,
    onSelectItem,
    onDoubleClickItem,
    isSelected,
    className,
}) => {
    const listItemRef = useRef<HTMLLIElement>(null);
    const isItemEnabled: any = currentItem.selectedDisplayValue === 'Name';

    useEffect(() => {
        if (isSelected) {
            listItemRef.current!.focus();
        }
    }, [isSelected]);

    return (
        <Draggable draggableId={currentItem.id} index={index}>
            {(provided, snapshot) => (
                <ListItem
                    {...provided.draggableProps}
                    ref={composeRefs<HTMLLIElement>(listItemRef, provided.innerRef)}
                    data-testid="selectedTransferBasketItem"
                    component="li"
                    role="listitem"
                    className={`${css.listItem} ${snapshot.isDragging ? css.listItemDragging : className}`}
                    button={currentItem.isItemUnselectable as true}
                    onClick={(e: any) => {
                        if (currentItem.isItemUnselectable) {
                            onSelectItem(currentItem);
                            e.preventDefault();
                        }
                    }}
                    onDoubleClick={(e: any) => {
                        if (currentItem.isItemUnselectable) {
                            onDoubleClickItem(currentItem);
                            e.preventDefault();
                        }
                    }}
                    selected={isSelected}>
                    <ListItemText
                        primary={currentItem.selectedDisplayValue}
                        classes={{
                            primary: isItemEnabled && !currentItem.isItemUnselectable ? css.disabledItem : '',
                        }}
                    />
                    <Box {...provided.dragHandleProps} className={css.dragHandle} role="group">
                        <DragHandleIcon />
                    </Box>
                </ListItem>
            )}
        </Draggable>
    );
};

export default TransferBasketList;
