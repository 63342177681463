import { injectable } from 'inversify';
import { IAuthorizationInfo, IAuthScheme } from '../../interfaces/authScheme/type';
import { IDMToken } from '../../interfaces/dmTokenHandler/type';

@injectable()
export class T360AdapterAccessToken implements IDMToken {
    authScheme: IAuthScheme;

    getAuthInfo = (): Promise<IAuthorizationInfo> => {
        return this.authScheme.getAuthData();
        // return new Error('Method Not implemented');
    };
}
