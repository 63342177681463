import { injectable } from 'inversify';
import { OfficeService } from '../common/office.service';
import { WordContext } from './wordContext';
import { OfficeAppType } from '../common/officeAppType.enum';

export interface WordInterface {
    run<T>(batch: (context: WordContext) => Promise<T> | T): Promise<T>;
}

@injectable()
export class WordService implements WordInterface {
    constructor(private office: OfficeService) {}

    public run<T>(batch: (context: WordContext) => Promise<T> | T): Promise<T> {
        return this.office.run<T>(WordContext, batch, OfficeAppType.Word);
    }
}
