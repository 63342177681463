import { injectable, inject } from 'inversify';
import { IEventService } from './eventContextHandler.service';
import { factory } from '../configLog4J';
import { IDialog } from './interface';
import { EventType } from '../enum/enum';
import { deleteFromPersistentStorage } from '../utils/main.utils';

const log = factory.getLogger('DialogService');

type CloseCallback = () => void;

@injectable()
export class DialogService {
    private _eventService: IEventService;
    constructor(@inject('EventContextHandler') eventService: IEventService) {
        this._eventService = eventService;
    }

    private async getCloseCallback(filePaths: string[]): Promise<CloseCallback> {
        return async () => {
            await deleteFromPersistentStorage(filePaths);
            return;
        };
    }

    public async showDialog(dialog: IDialog, filePaths?: string[]): Promise<void> {
        log.info('triggered dialog to show');
        if (!dialog.onCloseCallback && filePaths) {
            dialog.onCloseCallback = await this.getCloseCallback(filePaths);
        }
        this._eventService.publish({
            name: EventType.DIALOG,
            dialog,
        });
    }
}
