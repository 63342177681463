import { IInputValidator } from '../../interfaces/inputValidators/type';
import { injectable } from 'inversify';
// import { ValidationUtils } from '../../libraries/validationUtils';
import { IValidationInfo } from '../../dmInfoHandler/types';
import { IRenameFolder } from '../../docManager';
import { DCValidationError } from '../../customError/docComponentError';

@injectable()
export class JRAdapterRenameFolderInputValidator implements IInputValidator {
    validationInfo: IValidationInfo;
    performValidation = (nodeObj: IRenameFolder): boolean => {
        // const validator = new ValidationUtils();

        if (nodeObj?.docId && nodeObj?.name) {
            return true;
        } else {
            throw new DCValidationError(
                'JR Passport. Rename Folder Input Validation. Params: docId: ' +
                    nodeObj?.docId +
                    'Name: ' +
                    nodeObj?.name,
            );
        }
    };
}
