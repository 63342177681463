import { injectable } from 'inversify';
import { baseCommunicationService, BaseCommunicationEvent } from '@wk/office-companion-js-common';
import { Observable } from 'rxjs';

export enum AppStorageChannel {
    Auth = 'app-auth-storage',
}

export class AuthInfoRequestDto {
    /**
     * set to `true` if token should be updated (made new) on pouchDb server
     *
     */
    public refresh?: boolean;
}

export class AuthInfoDto {
    public token: string;
}

@injectable()
export class AppStorageService {
    public auth(request?: AuthInfoRequestDto): Observable<BaseCommunicationEvent<AuthInfoDto>> {
        return baseCommunicationService.invoke<AuthInfoDto>(AppStorageChannel.Auth, request);
    }
}
