import { injectable } from 'inversify';
import { ITransformFulfiller } from '../../interfaces/transformFulfiller/transformFulfiller';
import { IMatterDoc } from '../../types';

@injectable()
export class JRAdapterTransformFulfiller implements ITransformFulfiller {
    getMatterDetails = async (): Promise<IMatterDoc> => {
        return {} as IMatterDoc;
    };
}
