import { injectable } from 'inversify';
import { apiFetchGet } from '../../api/fetchUtils';
import { T360AdapterActionPerformer } from './actionPerformer';
import { convertToMapMetaData, getHostName } from '../../utils/main.utils';
import { IActionableEntity } from '../../interfaces/actionEntity/type';
import { emailMatchMetadata } from '../utils/maps';
import { DCResponse } from '../../types';

@injectable()
export class T360AdapterDocumentMetadataEmailActionPerformer extends T360AdapterActionPerformer {
    fetchRequest = apiFetchGet;

    async performAction(obj: IActionableEntity, urlObj: Promise<string>): Promise<DCResponse> {
        const response = await super.performAction(obj, urlObj);

        const outputInfo = async () => {
            const jsonObject = await response.json();
            jsonObject.downloadURL = getHostName() + '/' + jsonObject.downloadUrl;
            return convertToMapMetaData(emailMatchMetadata, jsonObject);
        };
        const response2: DCResponse = Object.assign(new DCResponse(), response);
        response2.outputInfo = outputInfo;
        return response2;
    }
}
