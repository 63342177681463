import { config } from '../../src/target/t360/config';

/**
 * Build a canonical relative path based on the application's base path and the path passed in.
 *
 * @param uriPath URI path.
 * @returns Canonical relative path.
 * @example
 *
 * buildPath('specific/path')
 * // => '/base/specific/path'
 *
 * buildPath('/specific')
 * // => '/base/specific'
 */
export const buildPath = (uriPath: string): string => {
    const basePath = getBasePath();

    const startRelativePathSymbolsPattern = /^.?\//;
    const isValidPath = startRelativePathSymbolsPattern.test(uriPath);
    if (!isValidPath) {
        throw new Error('The "uriPath" must start with "/" or "./" symbols.');
    }

    const validPath = uriPath.replace(startRelativePathSymbolsPattern, '');

    return `${basePath}/${validPath}`;
};

/**
 * Gets the application's base path.
 *
 * @returns Base path.
 * @remarks
 *
 * The relative base path with 'dot' like `'.', './'` is converted to an empty string.
 * The relative base path with 'dot' like `'./base-path'` is removed 'dot' symbol.
 *
 * @example
 *
 * [.env] PUBLIC_URL= '.' or './'
 * getBasePath()
 * // => ''
 *
 * [.env] PUBLIC_URL= '/spa' or './spa'
 * getBasePath()
 * // => '/spa'
 */
export const getBasePath = (): string => {
    const basePath = config.get('PUBLIC_URL', '');

    if (!basePath) {
        return '';
    }

    const onlyRelativeBasePathPattern = /^.?\/?$/;
    if (onlyRelativeBasePathPattern.test(basePath)) {
        return '';
    }

    const relativeStartBasePath = /^\.+/;
    const resultBasePath = basePath.replace(relativeStartBasePath, '');

    return resultBasePath;
};
