import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const WkOpenInDesktopIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon {...props} viewBox="0 0 16 16">
        <g transform="translate(-1190 -262)">
            <rect width="16" height="16" transform="translate(1190 262)" style={{ fill: 'none' }} />
            <g transform="translate(1190 262)">
                <path d="M12.571,16H0V3.428H10.146L9,4.571H1.143V14.856H11.428V7l1.143-1.143V16ZM6.689,10.12h0l-.808-.807,8.167-8.17h-2.62V0H16V4.571H14.856V1.954L6.69,10.12Z" />
            </g>
        </g>
    </SvgIcon>
);
