import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const WkTriangleExclamationIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="wkTriangleExclamationIcon" viewBox="0 0 16 16" {...props}>
        <g transform="translate(-356.886 -570)">
            <rect style={{ fill: 'none' }} width="16" height="16" transform="translate(357 570)" />
            <g transform="translate(357 569.976)">
                <g transform="translate(-0.114 1.024)">
                    <path
                        style={{ fill: '#e5202e' }}
                        d="M8,10.4a.773.773,0,0,1,.8.7h0a.752.752,0,0,1-1.5.1c-.1-.5.2-.8.7-.8Z"
                        transform="translate(0.056 -1.099)"
                    />
                    <path
                        style={{ fill: '#e5202e' }}
                        d="M8.7,5.8V9a.773.773,0,0,1-.8.7A.858.858,0,0,1,7.3,9V5.8a.773.773,0,0,1,.8-.7A.669.669,0,0,1,8.7,5.8Z"
                        transform="translate(0.057 -1.067)"
                    />
                    <path
                        style={{ fill: '#e5202e' }}
                        d="M5.262,2.49A3.185,3.185,0,0,1,9.63,1.5a4.707,4.707,0,0,1,.993.993l4.865,8.042a2.8,2.8,0,0,1,.4,1.489,2.858,2.858,0,0,1-.894,2.085A3.145,3.145,0,0,1,12.708,15H3.077a3.314,3.314,0,0,1-2.284-.894,2.855,2.855,0,0,1-.5-3.574h0Zm2.681-.2a1.885,1.885,0,0,0-1.489.794L1.588,11.128h0a1.871,1.871,0,0,0-.2.794,1.594,1.594,0,0,0,.5,1.092,1.832,1.832,0,0,0,1.191.5h9.631a1.644,1.644,0,0,0,1.191-.5,1.594,1.594,0,0,0,.5-1.092,1.871,1.871,0,0,0-.2-.794L9.432,3.185a1.75,1.75,0,0,0-1.489-.894Z"
                        transform="translate(0.114 -1.024)"
                    />
                </g>
            </g>
        </g>
    </SvgIcon>
);

export default WkTriangleExclamationIcon;
