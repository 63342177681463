import { Chip, Grid } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import useWindowWidth from '../../hooks/useWindowWidth';
import UUIButton from '../common/uuiButton';
import { measureStringWidth } from '../../utils/utilities';
import { useListScreenDispatch, useListScreenState } from './context/listScreenContext';
import EditMultipleMoreMenu from './editMultipleMoreMenu';
import css from './multipleSelectionBar.module.scss';
import { getBulkOperations } from './listScreenHelpers';
import { dispatchListScreenBulkOperation } from './context/listScreenAsyncActions';
import { useRefreshList } from './context/listScreenHooks';
import { useReduxSelector } from '../../hooks/useReduxSelector';

export const MultipleSelectionBar: React.FC = () => {
    const listScreenState = useListScreenState();
    const listScreenDispatch = useListScreenDispatch();
    const appResources = useReduxSelector((state) => state.appResources);
    const leftItemRef = useRef<HTMLDivElement>(null);
    const rightItemRef = useRef<HTMLDivElement>(null);
    const [isSnapped, setIsSnapped] = useState(true);
    const refreshList = useRefreshList();
    const metadata = listScreenState.metadata!;

    const bulkOperations = getBulkOperations(metadata, listScreenState.mode);
    const initialOperation = bulkOperations[0];
    const windowWidth = useWindowWidth(100);

    useEffect(() => {
        const KABOB_WIDTH = 45;
        const TEXT_PADDING = 26;
        const additionalTextSpacing = bulkOperations.length > 1 ? KABOB_WIDTH + TEXT_PADDING : TEXT_PADDING * 2;

        if (listScreenState.checkedRows.length > 0 && leftItemRef.current) {
            const availableSpace = windowWidth - leftItemRef.current.offsetWidth;
            const neededSpaceForShowingInitialOperation = initialOperation
                ? measureStringWidth(initialOperation?.displayName, '14px') + additionalTextSpacing
                : 0;

            setIsSnapped(neededSpaceForShowingInitialOperation > availableSpace);
        }
    }, [initialOperation, isSnapped, listScreenState.checkedRows.length, bulkOperations.length, windowWidth]);

    if (listScreenState.checkedRows.length === 0) {
        return null;
    }

    const operationClickHandler = (operationId: number) => {
        dispatchListScreenBulkOperation(operationId, listScreenState, listScreenDispatch, refreshList);
    };

    return (
        <Grid container className={css.container} data-testid="msoBar" justify="space-between">
            <Grid item className={css.leftItem} ref={leftItemRef}>
                <Chip
                    className={css.count}
                    data-testid="multipleSelectionCount"
                    label={listScreenState.checkedRows.length}
                />
                {' ' + appResources.listscreenMultiSelectBarSelected}
                <UUIButton
                    className={css.clearAll}
                    color="primary"
                    onClick={() => listScreenDispatch({ type: 'ClearAllCheckedRows' })}>
                    {appResources.listscreenMultiSelectBarClearAll}
                </UUIButton>
            </Grid>
            {bulkOperations.length > 0 && (
                <Grid ref={rightItemRef} className={css.rightItem} item>
                    {!isSnapped && (
                        <UUIButton
                            color="primary"
                            onClick={() => operationClickHandler(initialOperation?.id)}
                            classes={{ label: css.buttonLabel }}>
                            {initialOperation?.displayName}
                        </UUIButton>
                    )}
                    {(bulkOperations.length > 1 || (bulkOperations.length === 1 && isSnapped)) && (
                        <EditMultipleMoreMenu
                            bulkOperations={bulkOperations}
                            operationClickHandler={operationClickHandler}
                            isSnapped={isSnapped}
                        />
                    )}
                </Grid>
            )}
        </Grid>
    );
};

export default MultipleSelectionBar;
