export enum OverlayDialogButtonAction {
    Reload,
    NavigateToHome,
    InstallUpdate,
    logOff,
}

export interface IOverlayDialogButton {
    text: string;
    action: OverlayDialogButtonAction;
}
