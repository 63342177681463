import { isSection } from '../itemScreenCommon';
import { IItemScreenField } from '../types';
import { IItemPageModel, ISectionEntry } from './types';

export const getItemScreenModel = (fields: IItemScreenField[]): IItemPageModel => {
    // initialize an empty tab model
    const model: IItemPageModel = {
        tabs: {},
        sections: [],
    };

    let topLevelSectionGroupIndex = 0;

    /**
     * This function takes a field and returns the name of the tab/section group along with a boolean indicating if it is a section group or not. False means it's a tab group.
     * @param field The field to process
     * @returns an array where the first element is the tabGroup or sectionGroup string and the second element is true if this is a section group or false if it is a tab group
     */
    const getTabOrSectionGroupName = (field: IItemScreenField): [string, boolean] => {
        // if all fields have a tabGroup defined, then there are no fields outside of a tab (DefaultInvoiceHeaderSearchResult is an example of this)
        // if this happens, we auto-convert the first tab to a section to be displayed at the top of an item page.
        const screenHasOnlyTabsAndNoSectionsDefined = fields.find((f) => f.tabGroup === '') === undefined;
        const shouldConvertTabGroupToSectionGroup = (tabGroupName: string) =>
            screenHasOnlyTabsAndNoSectionsDefined && tabGroupName === fields[0].tabGroup;

        let name: string | null;
        let isSectionGroup = false;

        // In Phase 1 for T360, we will allow them to provide both a tabGroup and a sectionGroup
        // and we will concatenate them together to create a tabGroup string that would be compatible
        // with Passport. In Phase 2, we can update Passport to be able to provide both a tab group and section group.
        if (field.tabGroup && field.sectionGroup) {
            name = `${field.tabGroup}--${field.sectionGroup}`;
            isSectionGroup = shouldConvertTabGroupToSectionGroup(field.tabGroup);
        } else {
            name = field.tabGroup;
            if (!!name && shouldConvertTabGroupToSectionGroup(name)) {
                isSectionGroup = true;
                const nameParts = name.split(/__/g);
                if (nameParts.length > 1) {
                    name = nameParts[1];
                    topLevelSectionGroupIndex++;
                }
            }

            if (!name) {
                name = field.sectionGroup;
                if (name) {
                    topLevelSectionGroupIndex++;
                } else {
                    name = `{__topLevelSection${topLevelSectionGroupIndex}}`;
                }
                isSectionGroup = true;
            }
        }
        return [name, isSectionGroup];
    };

    // This is the main processing loop. acc is the accumulator which will become the item screen model.
    fields.reduce((acc, field: IItemScreenField): IItemPageModel => {
        const [name, isSectionGroup] = getTabOrSectionGroupName(field);

        if (isSectionGroup) {
            const sectionNameArray = name.split(/--/g);
            let existingSection = acc.sections.find((fs) => fs.sectionTitle === sectionNameArray[0]);
            if (existingSection) {
                existingSection = processSectionGroup(sectionNameArray, existingSection, field);
            } else {
                acc.sections.push(processSectionGroup(sectionNameArray, undefined, field));
            }
        } else {
            const [tabGroupName, sectionGroupName] = splitIntoTabAndSectionGroupNames(name);
            const [tabName, subTabName] = getTabAndSubTabNamesFromTabGroupName(tabGroupName, field);

            // find existing tab by name in the model
            let tabEntry = acc.tabs[tabName];

            // tab entry doesn't exist, create it and add it to the model
            if (!tabEntry) {
                tabEntry = {
                    sections: [],
                    subTabs: {},
                };
                acc.tabs[tabName] = tabEntry;
            }

            // if there is no subtabs, then process any section groups into this tab. sectionNameArray will always contain at least 1 entry because
            // we create a section group called '' for any fields that are not in a section OOTB.
            if (subTabName.length === 0) {
                const sectionNameArray = sectionGroupName.split(/--/g);
                let existingSection = tabEntry.sections.find((fs) => fs.sectionTitle === sectionNameArray[0]);
                if (existingSection) {
                    existingSection = processSectionGroup(sectionNameArray, existingSection, field);
                } else {
                    tabEntry.sections.push(processSectionGroup(sectionNameArray, existingSection, field));
                }
            } else {
                // first check the sub tab entry exists
                let subTabEntry = tabEntry.subTabs[subTabName];
                // if it doesn't exist, create it
                if (!subTabEntry) {
                    subTabEntry = {
                        subTabs: {},
                        sections: [],
                    };
                    tabEntry.subTabs[subTabName] = subTabEntry;
                }
                // now process the section groups into this subTab.
                const sectionNameArray = sectionGroupName.split(/--/g);
                let existingSection = subTabEntry.sections.find((fs) => fs.sectionTitle === sectionNameArray[0]);
                if (existingSection) {
                    existingSection = processSectionGroup(sectionNameArray, existingSection, field);
                } else {
                    subTabEntry.sections.push(processSectionGroup(sectionNameArray, existingSection, field));
                }
            }
        }

        return acc;
    }, model);
    return model;
};

/**
 * This takes the concatenated tabGroup string and splits it into a tab group string and a section group string.
 *
 * @param name the original tabGroup string that might contain tab group AND section group
 * @returns an array where the first element is the tabGroupName and the second element is the section group name.
 * If no section name is found, this returns a '' for the section name which will be the default nameless section.
 */
const splitIntoTabAndSectionGroupNames = (name: string): string[] => {
    let tabGroupName = name;
    let sectionGroupName = '';
    const dashCount = subStringOccurenceCount(name, /--/g);
    const underscoreCount = subStringOccurenceCount(name, /__/g);
    if (dashCount >= 1 && underscoreCount === 1) {
        const nameParts = tabGroupName.split('__');
        if (subStringOccurenceCount(nameParts[0], /--/g) === 0) {
            // ex: TabGroup1__Extra Fields--SectionGroup1....
            sectionGroupName = tabGroupName.substring(tabGroupName.indexOf('--') + 2);
            tabGroupName = tabGroupName.substring(0, tabGroupName.indexOf('--'));
        } else {
            // ex: TabGroup1--Financials__Extra Fields
            // ex: TabGroup1--Financials__Extra Fields--SectionGroup1...
            if (subStringOccurenceCount(nameParts[1], /--/g) > 0) {
                sectionGroupName = tabGroupName.substring(secondIndexOf(tabGroupName, '--') + 2);
                tabGroupName = tabGroupName.substring(0, secondIndexOf(tabGroupName, '--'));
            }
        }
    }
    return [tabGroupName, sectionGroupName];
};

/**
 * This takes a tabGroup string and returns an array that contains the tab group name and the subTab group name.
 * @param tabGroupName This must ONLY contain the tabGroup string. The section group part must have been stripped off already.
 * @param field The field that this tabGroup string is from
 * @returns an array where the first element is the tabGroupName and the second element is the subTab group name.
 */
const getTabAndSubTabNamesFromTabGroupName = (tabGroupName: string, field: IItemScreenField) => {
    const dashCount = subStringOccurenceCount(tabGroupName, /--/g);
    const underscoreCount = subStringOccurenceCount(tabGroupName, /__/g);
    let subTabName = '';
    if (dashCount === 0 && underscoreCount === 0) {
        // create a new tab with the field name as the tab name
        tabGroupName = field.displayName;
    } else if (dashCount === 1 && underscoreCount === 0) {
        // create a subTab with the name given after the -- separator
        tabGroupName = tabGroupName.substring(tabGroupName.indexOf('--') + 2);
        subTabName = field.displayName;
    } else if (dashCount === 0 && underscoreCount === 1) {
        tabGroupName = tabGroupName.substring(tabGroupName.indexOf('__') + 2);
    } else if (dashCount === 1 && underscoreCount === 1) {
        tabGroupName = tabGroupName.substring(tabGroupName.indexOf('--') + 2);
        const parts = tabGroupName.split('__');
        tabGroupName = parts[0];
        subTabName = parts[1];
    }
    return [tabGroupName, subTabName];
};

/**
 * This adds a field to the item screen model. It is smart
 * will add the field to the correct section group and create any section groups
 * that are missing
 *
 * @param sectionGroups an array of section group names for the field
 * @param initialSectionEntry the top level section entry. If undefined is passed, it will create the top level section group
 * @param field the field that will be added to the section entry
 * @returns the initialSectionEntry object with the field added in the correct spot.
 */
const processSectionGroup = (
    sectionGroups: string[],
    initialSectionEntry: ISectionEntry | undefined,
    field: IItemScreenField,
): ISectionEntry => {
    // anything longer than 3 levels will be reduced to 3 levels
    // ex: Section1--Sub Section1--Sub Sub Section1--Sub Sub Sub Section1--Sub Sub Sub Sub Section1
    // becomes Section1--Sub Section1--Sub Sub Sub Sub Section1
    if (sectionGroups.length > 3) {
        sectionGroups = sectionGroups.slice(0, 2).concat([sectionGroups[sectionGroups.length - 1]]);
    }
    let currentSectionEntry: ISectionEntry;
    if (!initialSectionEntry) {
        const se: ISectionEntry = {
            sectionTitle: sectionGroups[0],
            fieldsAndSections: [],
        };
        currentSectionEntry = se;
    } else {
        currentSectionEntry = initialSectionEntry;
    }
    sectionGroups.shift();
    processSectionGroupEntry(sectionGroups, currentSectionEntry, field);
    return currentSectionEntry;
};

/**
 * This should only get called from processSectionGroup.
 */
const processSectionGroupEntry = (
    sectionNameArray: string[],
    currentSectionEntry: ISectionEntry,
    field: IItemScreenField,
) => {
    sectionNameArray.map((sectionName) => {
        const sectionEntry = currentSectionEntry.fieldsAndSections.find(
            (fs) => isSection(fs) && fs.sectionTitle === sectionName,
        ) as ISectionEntry | undefined;
        if (!sectionEntry) {
            const newSectionEntry: ISectionEntry = {
                sectionTitle: sectionName,
                fieldsAndSections: [],
            };
            currentSectionEntry.fieldsAndSections.push(newSectionEntry);
            currentSectionEntry = newSectionEntry;
        } else {
            currentSectionEntry = sectionEntry;
        }
    });
    currentSectionEntry.fieldsAndSections.push(field);
};

const secondIndexOf = (str: string, searchStr: string) => {
    return str.indexOf(searchStr, str.indexOf(searchStr) + 1);
};

const subStringOccurenceCount = (str: string, searchExp: RegExp) => {
    return (str.match(searchExp) || []).length;
};
