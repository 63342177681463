import { injectable } from 'inversify';
import { apiFetchGet } from '../../api/fetchUtils';
import { T360AdapterActionPerformer } from './actionPerformer';
import { convertToMapMetaData, getHostName } from '../../utils/main.utils';
import { matchMetadata } from '../utils/maps';
import { factory } from '../../ConfigLog4j';
import { T360AdapterDocumentMetadataActionableEntity } from '../actionableEntity/documentMetadataActionableEntity';
import { DCResponse } from '../../types';

const log = factory.getLogger('T360AdapterDocumentMetadataActionPerformer');

@injectable()
export class T360AdapterDocumentMetadataActionPerformer extends T360AdapterActionPerformer {
    fetchRequest = apiFetchGet;

    async performAction(
        obj: T360AdapterDocumentMetadataActionableEntity,
        urlObj: Promise<string>,
    ): Promise<DCResponse> {
        let response = await super.performAction(obj, urlObj);

        if (obj?.version) {
            const jsonObject = await response.json();
            const url = await urlObj;

            let version = 0;
            if (jsonObject.documentMetadata.documentId != 0) {
                const versionList = jsonObject.documentMetadata.versionList;
                version = versionList[versionList.length - 1];
            }

            const urlMod: string = url.replace('/version/1', '/version/' + version);
            const urlFunc = async () => {
                return urlMod;
            };

            response = await super.performAction(obj, urlFunc());
            log.debug('response in mod url' + urlMod);
        }

        const outputInfo = async () => {
            const jsonObject = await response.json();
            jsonObject.documentMetadata.downloadURL = getHostName() + '/' + jsonObject.documentMetadata.downloadURL;

            if (jsonObject?.documentMetadata?.versionList && jsonObject?.documentMetadata?.versionList?.length > 0) {
                log.debug(
                    'Latest Version is: ' +
                        jsonObject.documentMetadata.versionList[jsonObject.documentMetadata.versionList.length - 1],
                );
                jsonObject.documentMetadata.latestVersion =
                    jsonObject.documentMetadata.versionList[jsonObject.documentMetadata.versionList.length - 1];
            }
            return convertToMapMetaData(matchMetadata, jsonObject.documentMetadata);
        };

        const response2: DCResponse = Object.assign(new DCResponse(), response);
        response2.outputInfo = outputInfo;
        return response2;
    }
}
