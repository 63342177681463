import { injectable } from 'inversify';
import { OfficeService } from '../common/office.service';
import { OfficeAppType } from '../common/officeAppType.enum';
import { PowerPointContext } from './powerPointContext';

export interface PowerPointInterface {
    run<T>(batch: (context: PowerPointContext) => Promise<T> | T): Promise<T>;
}

@injectable()
export class PowerPointService {
    constructor(private office: OfficeService) {}

    public run<T>(batch: (context: PowerPointContext) => Promise<T> | T): Promise<T> {
        return this.office.run<T>(PowerPointContext, batch, OfficeAppType.PowerPoint);
    }
}
