import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const wkQueueIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="wkQueueIcon" viewBox="0 0 20 20" {...props}>
        <g id="Group_6810" transform="translate(-1247 -18)">
            <path
                id="Color_2_"
                d="M1257,38a9.944,9.944,0,0,1-10-9.9h1.5a8.517,8.517,0,0,0,8.4,8.4h.1a8.562,8.562,0,0,0,7.1-3.8h-4V31.2h6.2v6.1h-1.5V34.4a11.284,11.284,0,0,1-3.4,2.7A13.28,13.28,0,0,1,1257,38Zm10-9.9h-1.5a8.517,8.517,0,0,0-8.4-8.4h-.1a8.562,8.562,0,0,0-7.1,3.8h4V25h-6.2V18.9h1.5v2.9a11.284,11.284,0,0,1,3.4-2.7,9.071,9.071,0,0,1,4.3-1A10.249,10.249,0,0,1,1267,28.1Z"
            />
        </g>
        <rect id="Rectangle_2543" data-name="Rectangle 2543" width="20" height="20" fill="none" />
    </SvgIcon>
);

export default wkQueueIcon;
