import { IQueueCapability, IQueueConfig, IQueueController, IQueueSubscribed } from '../interfaces/interface';
import { myContainer } from '../core';
import { QueueSubscribedMap } from '../map';
import { getSecretKey } from '@wk/elm-uui-doc-component';
import { interfaces } from 'inversify';
import { getDatabaseName } from '../utils/database.utils';
import { getQueueManager, IQueueServiceInterface } from '@wk/elm-uui-queuemanager';
import { CapabiltyEnum, CHSupportedDocumentTypesEnum } from '../enum/enum';
import { ICHUploadResponse } from '../interfaces/operations';
import { notifyFlyout } from '../utils/messaging.utils';
import { factory } from '../configLog4J';

const log = factory.getLogger('queueCapability.module');

myContainer
    .bind<interfaces.Provider<IQueueCapability>>('Provider<IQueueCapability>')
    .toProvider<IQueueCapability>((context) => {
        return () => {
            const capability = context.container.get<IQueueCapability>('QueueCapability');
            // For setting the QueueSubscriptionMap
            for (const [operationName, subObj] of Object.entries(QueueSubscribedMap)) {
                const queueSubscribedObj = myContainer.get<IQueueSubscribed>('QueueSubscribed');
                for (const [queueFunctionName, bindingObj] of Object.entries(subObj)) {
                    if (bindingObj && Object.keys(bindingObj).length > 0) {
                        // eslint-disable-next-line @typescript-eslint/ban-types
                        const obj: object = myContainer.get(bindingObj?.bindingName);
                        if (Reflect.has(obj, bindingObj?.functionName)) {
                            const funcObject = Reflect.get(obj, bindingObj?.functionName);
                            const finalObj = { func: funcObject, obj: obj };
                            Reflect.set(queueSubscribedObj, queueFunctionName, finalObj);
                        } else {
                            throw Error(
                                'Expected ' + bindingObj?.functionName + ' not found in ' + bindingObj?.bindingName,
                            );
                        }
                    }
                }
                capability.queueSubscribedMap.set(operationName, queueSubscribedObj);
            }

            return async () => {
                const databaseName = await getDatabaseName();
                const secretKey = await getSecretKey();
                capability.queueManagerObj = await getQueueManagerObj(databaseName, secretKey);
                await capability.queueSubscription();
                await capability.timerInterval();
                await capability.profileSwitch();
                await capability.cleanFileResources();
                await capability.queueProcessingCount();
                return capability;
            };
        };
    });

/**
 * Factory method to create QueueCapability Object
 */
let singletonObj: Promise<IQueueCapability> | null = null;
export const getQueueCapability = async (): Promise<IQueueCapability> => {
    if (singletonObj == null) {
        const queueFactoryPromise: () => () => Promise<IQueueCapability> =
            myContainer.get('Provider<IQueueCapability>');

        const factoryObj = queueFactoryPromise();
        singletonObj = factoryObj();
    }

    return singletonObj;
};

/**
 * Factory method to create QueueManager Object
 */
let singletonQueueObj: Promise<IQueueServiceInterface> | null = null;
export const getQueueManagerObj = async (databaseName: string, secretKey: string): Promise<IQueueServiceInterface> => {
    if (singletonQueueObj == null) {
        singletonQueueObj = getQueueManager(databaseName, secretKey);
    }

    return singletonQueueObj;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const breadCrumbsforQueue = (nodeObj: any, operation: CapabiltyEnum): any => {
    const EMAILS = CHSupportedDocumentTypesEnum.EMAIL + 's';
    const DOCUMENTS = CHSupportedDocumentTypesEnum.DOCUMENT + 's';

    if (nodeObj.folderArr && nodeObj.folderArr[0]?.value) {
        nodeObj.folderArr = nodeObj.folderArr.map((obj: { value: string }) => obj.value);
    }

    if (operation === CapabiltyEnum.ADD_EMAIL && (!nodeObj.folderArr || nodeObj.folderArr[0] !== EMAILS)) {
        nodeObj.folderArr = [EMAILS];
        nodeObj.folderId = undefined;
    } else if (
        (operation === CapabiltyEnum.ADD_DOCUMENT || operation === CapabiltyEnum.CHECKIN_DOCUMENT) &&
        (!nodeObj.folderArr || nodeObj.folderArr[0] !== DOCUMENTS)
    ) {
        nodeObj.folderArr = [DOCUMENTS];
        nodeObj.folderId = undefined;
    }
    return nodeObj;
};

export const implementQueue = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    nodeObj: any,
    operation: CapabiltyEnum,
    error?: string,
): Promise<ICHUploadResponse> => {
    const updatedNodeObj = notifyFlyout(nodeObj);
    const modifiedNodeObj = breadCrumbsforQueue(updatedNodeObj, operation);
    const queueObj = await getQueueCapability();
    const groupId = nodeObj.groupId;
    const noOfItems = nodeObj.noOfItems;
    const uploadTime = nodeObj.uploadTime;
    log.debug('using Queue Manager');
    return queueObj.doOperation(modifiedNodeObj, operation, error, groupId, noOfItems, uploadTime);
};

export const getQueue = async (): Promise<IQueueServiceInterface> => {
    try {
        const databaseName = await getDatabaseName();
        const secretKey = await getSecretKey();
        return await getQueueManagerObj(databaseName, secretKey);
    } catch (error) {
        log.error('getQueue encountered error ' + error);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return undefined;
    }
};

/**
 * @param queueConfig - The Queue Config.
 * @public
 * @description
 * sets the queue control
 */
export const setQueueControl = (queueConfig: IQueueConfig): void => {
    const queueContainer: IQueueController = myContainer.get<IQueueController>('QueueController');
    queueContainer.setQueueController(queueConfig);
};
