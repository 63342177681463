import { ClientObject } from '../common/clientObject';
import { Selection } from './selection';
import { RequestType } from '../comObject';

export class Explorer extends ClientObject {
    public clearSelection(): void {
        this.reference.request({ type: RequestType.Invoke, name: 'ClearSelection' });
    }

    public get selection(): Selection {
        return this.reference.requestObject({
            type: RequestType.GetProperty,
            creator: Selection,
            name: 'Selection',
        }).value;
    }
}
