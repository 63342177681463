import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const WkCircleLetteriIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="WkCircleLetteriIcon" viewBox="0 0 16 16" {...props}>
        <g>
            <rect style={{ fill: 'none' }} width="16" height="16" />
            <path
                style={{ fill: '#05659F' }}
                d="M8,0C3.6,0,0,3.6,0,8c0,4.4,3.6,8,8,8c2.1,0,4.2-0.8,5.6-2.3C15.2,12.1,16,10.1,16,8C16,3.6,12.4,0,8,0z
		 M8.5,12.8H7.5V6.4h1.1V12.8z M8.5,5.3H7.5V3.2h1.1V5.3z"
            />
        </g>
    </SvgIcon>
);

export default WkCircleLetteriIcon;
