import { ICHEditDocument, ICHEditFolder, ICHUploadResponse, ICHUploadType } from './interfaces/operations';
import {
    checkOut,
    addDocument,
    cancelCheckOut,
    renameDocument,
    renameFolder,
    deleteDocument,
    deleteFolder,
    deleteEmail,
    deleteEmailFolder,
    checkInDocument,
    addFolder,
    addEmail,
    downloadDocument,
    downloadDocumentVersion,
    documentMetadata,
    ICheckOut,
    ICancelCheckOut,
    IAddDocument,
    IRenameDocument,
    IDocumentMetadata,
    IDownloadDocument,
    IAddEmail,
    IRenameFolder,
    IDeleteDocument,
    IDeleteEmail,
    ICheckIn,
    IAddFolder,
    DCResponse,
} from '@wk/elm-uui-doc-component';

import { IDocContextHandler } from './interfaces/interface';
import { injectable } from 'inversify';
import 'reflect-metadata';

import { QueueCapability } from './capability/queueCapability';
import { QueueController } from './queueController';
// import { checkQueueAndImplement } from './util';
import { IQueuePropItem } from './services';
import { CapabiltyEnum, CHSupportedDocumentTypesEnum } from './enum/enum';
// import { factory } from './configLog4J';
import {
    ICHAddFolder,
    ICHCancelCheckout,
    ICHCheckOut,
    ICHDeleteDocMetaType,
    ICHDeleteDocument,
    ICHDeleteEmail,
    ICHDeleteFolder,
    ICHDocumentMetadata,
    ICHDownloadDocument,
    ICHQuickFileIndicator,
    ICHRenameDocument,
    ICHRenameFolder,
} from './interfaces/operations';

import { CtxtCheckOutTransformer } from './transform/ctxtCheckOutTransformer';
import { getTransformedObject } from './transform/helper';
import { CtxCancelCheckOutTransformer } from './transform/ctxCancelCheckoutTransformer';
import { CtxAddTransformer } from './transform/ctxAddDocumentTransformer';
import { CtxDocumentMetadataTransformer } from './transform/ctxDocumentMetadataTransformer';
import { CtxDownloadDocumentTransformer } from './transform/ctxDownloadDocumentTransformer';
import { CtxAddEmailTransformer } from './transform/ctxAddEmailTransformer';
import { CtxAddFolderTransformer } from './transform/ctxAddFolderTransformer';
import { CtxRenameDocumentTransformer } from './transform/ctxRenameDocument';
import { CtxRenameFolderTransformer } from './transform/ctxRenameFolder';
import { CtxDeleteDocumentTransformer } from './transform/ctxDeleteDocumentTransformer';
import { CtxDeleteEmailTransformer } from './transform/ctxDeleteEmailTransformer';
import { CtxCheckInDocumentTransformer } from './transform/ctxCheckInDocumentTransformer';
// const log = factory.getLogger('UIContextHandler');
import { factory } from './configLog4J';
import { implementQueue } from './capability';

const log = factory.getLogger('UIContextHandler');
const usageLog = factory.getLogger('UsageLogging');

@injectable()
export class UIContextHandler implements IDocContextHandler {
    // private _eventService: IEventService;

    queueController!: QueueController;
    getDocScreenNameToLaunch?: (() => Promise<Response>) | undefined;
    // TO DO
    supportedOperations = async (): Promise<Array<string>> => await ['TEST'];
    // TO DO
    performOperation = async (): Promise<Array<string>> => await ['TEST'];
    // TO DO
    getContextInfo = async (): Promise<Array<string>> => await ['TEST'];

    // injected from inversify
    queueCapability!: QueueCapability;

    performDragAndDrop = async (contextObject: ICHUploadType): Promise<ICHUploadResponse> => {
        usageLog.info('Invoked UI Context performDragAndDrop');
        log.debug('inside drag and drop printing input Obj' + JSON.stringify(contextObject));
        const obj = {} as ICHUploadResponse;
        obj.response = new DCResponse();
        obj.responseText = 'DnD obj successfully added';
        return obj;
    };

    constructor() {
        // this._eventService = eventService;
    }

    checkOut = (contextObj: ICHCheckOut): Promise<Response> => {
        usageLog.info('Invoked UI Context checkOut');
        const nodeObj = getTransformedObject<ICHCheckOut, ICheckOut, CtxtCheckOutTransformer>(
            CapabiltyEnum.CHECKOUT,
            contextObj,
        );

        return checkOut(nodeObj);
    };

    cancelCheckOut = (contextObj: ICHCancelCheckout): Promise<Response> => {
        usageLog.info('Invoked UI Context cancelCheckOut');
        const nodeObj = getTransformedObject<ICHCancelCheckout, ICancelCheckOut, CtxCancelCheckOutTransformer>(
            CapabiltyEnum.CANCEL_CHECKOUT,
            contextObj,
        );
        return cancelCheckOut(nodeObj);
    };

    addDocument = async (contextObj: ICHUploadType): Promise<ICHUploadResponse> => {
        usageLog.info('Invoked UI Context addDocument');
        const nodeObj = getTransformedObject<ICHUploadType, IAddDocument, CtxAddTransformer>(
            CapabiltyEnum.ADD_DOCUMENT,
            contextObj,
        );

        if (this.queueController.AddDocument) {
            return await implementQueue(nodeObj, CapabiltyEnum.ADD_DOCUMENT);
        } else {
            return { response: await addDocument(nodeObj) };
        }
    };

    addDocumentQueue = async (queueData: Record<string, unknown>): Promise<ICHUploadResponse> => {
        usageLog.info('Invoked UI Context addDocumentQueue');
        if (!queueData?.extendedProps) {
            queueData.extendedProps = {};
        }

        const queueData1 = queueData as unknown as IAddDocument;

        queueData1.extendedProps.uploadGuid = queueData.uploadGuid as string;

        const responseObj: DCResponse = await addDocument(queueData as unknown as IAddDocument);

        return { response: responseObj };
    };

    // TODO - Add implementation once doc component function is ready
    editDocument = async (contextObj: ICHEditDocument): Promise<Response> => {
        usageLog.info('Invoked UI Context editDocument');
        return await new Response('ToBeImplemented:' + JSON.stringify(contextObj));
    };

    renameDocument = (contextObj: ICHRenameDocument): Promise<Response> => {
        usageLog.info('Invoked UI Context renameDocument');
        const nodeObj = getTransformedObject<ICHRenameDocument, IRenameDocument, CtxRenameDocumentTransformer>(
            CapabiltyEnum.RENAME_DOCUMENT,
            contextObj,
        );
        return renameDocument(nodeObj);
    };
    renameFolder = (contextObj: ICHRenameFolder): Promise<Response> => {
        usageLog.info('Invoked UI Context renameFolder');
        const nodeObj = getTransformedObject<ICHRenameFolder, IRenameFolder, CtxRenameFolderTransformer>(
            CapabiltyEnum.RENAME_FOLDER,
            contextObj,
        );
        return renameFolder(nodeObj);
    };
    deleteDocument = (contextObj: ICHDeleteDocMetaType): Promise<Response> => {
        usageLog.info('Invoked UI Context deleteDocument');
        const nodeObj = getTransformedObject<ICHDeleteDocument, IDeleteDocument, CtxDeleteDocumentTransformer>(
            CapabiltyEnum.DELETE_DOCUMENT,
            contextObj,
        );
        return deleteDocument(nodeObj);
    };

    // TODO - Add implementation once doc component function is ready
    editFolder = async (contextObj: ICHEditFolder): Promise<Response> => {
        usageLog.info('Invoked UI Context editFolder');
        return await new Response('ToBeImplemented: ' + JSON.stringify(contextObj));
    };

    deleteFolder = (contextObj: ICHDeleteFolder): Promise<Response> => {
        usageLog.info('Invoked UI Context deleteFolder');
        const nodeObj = getTransformedObject<ICHDeleteDocument, IDeleteDocument, CtxDeleteDocumentTransformer>(
            CapabiltyEnum.DELETE_DOCUMENT,
            contextObj,
        );

        return deleteFolder(nodeObj);
    };
    deleteEmail = (contextObj: ICHDeleteFolder): Promise<Response> => {
        usageLog.info('Invoked UI Context deleteEmail');
        const nodeObj = getTransformedObject<ICHDeleteEmail, IDeleteEmail, CtxDeleteEmailTransformer>(
            CapabiltyEnum.DELETE_EMAIL,
            contextObj,
        );

        return deleteEmail(nodeObj);
    };

    deleteEmailFolder = (contextObj: ICHDeleteEmail): Promise<Response> => {
        usageLog.info('Invoked UI Context deleteEmailFolder');
        const nodeObj = getTransformedObject<ICHDeleteEmail, IDeleteEmail, CtxDeleteEmailTransformer>(
            CapabiltyEnum.DELETE_EMAIL,
            contextObj,
        );

        return deleteEmailFolder(nodeObj);
    };

    checkInDocument = async (contextObj: ICHUploadType): Promise<ICHUploadResponse> => {
        usageLog.info('Invoked UI Context checkInDocument');
        const nodeObj = getTransformedObject<ICHUploadType, ICheckIn, CtxCheckInDocumentTransformer>(
            CapabiltyEnum.CHECKIN_DOCUMENT,
            contextObj,
        );

        nodeObj.existingDocumentName = nodeObj?.documentName;
        if (this.queueController.CheckInDocument) {
            return await implementQueue(nodeObj, CapabiltyEnum.CHECKIN_DOCUMENT);
        } else {
            return checkInDocument(nodeObj) as ICHUploadResponse;
        }
    };

    checkInQueue = async (queueData: Record<string, unknown>): Promise<Response> => {
        // const nodeObj = queueItem.data;
        return checkInDocument(queueData as unknown as ICheckIn);
    };

    addFolder = (contextObj: ICHAddFolder): Promise<Response> => {
        usageLog.info('Invoked UI Context addFolder');
        const nodeObj = getTransformedObject<ICHAddFolder, IAddFolder, CtxAddFolderTransformer>(
            CapabiltyEnum.ADD_FOLDER,
            contextObj,
        );

        return addFolder(nodeObj);
    };
    addEmail = async (contextObj: ICHUploadType): Promise<ICHUploadResponse> => {
        usageLog.info('Invoked UI Context addEmail');
        const nodeObj = getTransformedObject<ICHUploadType, IAddEmail, CtxAddEmailTransformer>(
            CapabiltyEnum.ADD_EMAIL,
            contextObj,
        );

        const response = await addEmail(nodeObj);
        return { response };
    };
    downloadDocument = (contextObj: ICHDownloadDocument): Promise<Response> => {
        usageLog.info('Invoked UI Context downloadDocument');
        const nodeObj = getTransformedObject<ICHDownloadDocument, IDownloadDocument, CtxDownloadDocumentTransformer>(
            CapabiltyEnum.DOWNLOAD_DOCUMENT,
            contextObj,
        );

        const { extendedProps } = nodeObj;
        if (extendedProps?.version) {
            return downloadDocumentVersion(nodeObj);
        }
        return downloadDocument(nodeObj);
    };
    documentMetadataContext = (contextObj: ICHDocumentMetadata): Promise<Response> => {
        const nodeObj = getTransformedObject<ICHDocumentMetadata, IDocumentMetadata, CtxDocumentMetadataTransformer>(
            CapabiltyEnum.DOCUMENT_METADATA,
            contextObj,
        );

        return documentMetadata(nodeObj);
    };

    showDocument = async (): Promise<Response> => {
        throw new Error('Open or download document is not supported');
    };

    showDocumentVersion = async (): Promise<Response> => {
        throw new Error('Open or download document version is not supported');
    };

    overwriteCheckout = async (): Promise<Response> => {
        throw new Error('Not Supported');
    };

    keepCheckout = (): Promise<Response> => {
        throw new Error('Not Supported');
    };

    keepCancelCheckout = (): Promise<Response> => {
        throw new Error('Not Supported');
    };

    removeCancelCheckout = (): Promise<Response> => {
        throw new Error('Not Supported');
    };

    performQuickFile = (): Promise<ICHUploadResponse | ICHUploadResponse[]> => {
        throw new Error('Not Supported');
    };

    updateDocument = (): Promise<ICHUploadResponse> => {
        throw new Error('Not Supported');
    };

    getIsQuickFileEnabled = async (
        _isFolder?: boolean,
        _documentType?: CHSupportedDocumentTypesEnum,
    ): Promise<ICHQuickFileIndicator> => {
        throw new Error('Not Supported');
    };

    initialize = async (): Promise<void> => {
        throw new Error('Not supported');
    };

    getAllProps = (): Promise<IQueuePropItem[]> => {
        throw new Error('Not supported');
    };
}
