import { injectable } from 'inversify';
import { OfficeService } from '../common/office.service';
import { OfficeAppType } from '../common/officeAppType.enum';
import { ExcelContext } from './excelContext';

export interface ExcelInterface {
    run<T>(batch: (context: ExcelContext) => Promise<T> | T): Promise<T>;
}

@injectable()
export class ExcelService {
    constructor(private office: OfficeService) {}

    public run<T>(batch: (context: ExcelContext) => Promise<T> | T): Promise<T> {
        return this.office.run<T>(ExcelContext, batch, OfficeAppType.Excel);
    }
}
