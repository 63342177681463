import { getLogger as getLoggerInternal, MessageType, ErrorType } from '@wk/elm-uui-common';

export const factory = {
    getLogger: (loggerName: string) => {
        const logger = () => getLoggerInternal(`DM.${loggerName}`);
        return {
            error: (msg: MessageType, error?: ErrorType) => logger().error(msg, error),
            warn: (msg: MessageType, error?: ErrorType) => logger().warn(msg, error),
            info: (msg: MessageType, error?: ErrorType) => logger().info(msg, error),
            debug: (msg: MessageType, error?: ErrorType) => logger().debug(msg, error),
        };
    },
};
