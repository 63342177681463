import { IOfficeDocumentService } from '../../services';
import { injectable } from 'inversify';
import { Excel, FileOperations, SaveOptions } from '@wk/office-companion-js';
import { factory } from '../../configLog4J';
import { DocumentDefaultExtensions } from '../../enum/enum';
import { v4 as uuid } from 'uuid';
import { getTemporaryFileName } from '../../utils/main.utils';

const log = factory.getLogger('ExcelService');

@injectable()
export class ExcelService implements IOfficeDocumentService {
    private hasValue = false;
    private documentPath = '';

    public async isNewDocument(): Promise<boolean> {
        try {
            await Excel.run(async (context) => {
                const doc = context.currentWorkbook;
                await context.sync();
                context.load(doc, ['isNew']);
                await context.sync();
                this.hasValue = doc.isNew;
            });
        } catch (error) {
            log.error(error);
        }
        return this.hasValue;
    }

    public async isDocumentSaved(): Promise<boolean> {
        try {
            await Excel.run(async (context) => {
                const doc = context.currentWorkbook;
                await context.sync();
                context.load(doc, ['saved']);
                await context.sync();
                this.hasValue = doc.saved;
            });
        } catch (error) {
            log.error(error);
        }
        return this.hasValue;
    }

    public async save(): Promise<void> {
        try {
            await Excel.run(async (context) => {
                const doc = context.currentWorkbook;
                await context.sync();
                doc.save();
                await context.sync();
            });
        } catch (error) {
            log.error(error);
        }
    }

    public async saveAs(filePath: string): Promise<void> {
        try {
            await Excel.run(async (context) => {
                const doc = context.currentWorkbook;
                await context.sync();
                doc.saveAs(filePath);
                await context.sync();
            });
        } catch (error) {
            log.error(error);
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public async close(saveOptions: SaveOptions): Promise<void> {
        try {
            const saveChanges = saveOptions === SaveOptions.SaveChanges ? true : false;
            await Excel.run(async (context) => {
                const doc = context.currentWorkbook;
                await context.sync();
                doc.close(saveChanges);
                await context.sync();
            });
        } catch (error) {
            log.error(error);
        }
    }

    public async getCurrentDocument(): Promise<string> {
        try {
            await Excel.run(async (context) => {
                const { currentWorkbook } = context;
                await context.sync();

                context.load(currentWorkbook, ['name']);
                await context.sync();
                this.documentPath = currentWorkbook.name;
            });
        } catch (error) {
            this.documentPath = '';
            log.error(error);
        }
        return this.documentPath;
    }

    public async getCurrentDocumentFullName(): Promise<string> {
        try {
            await Excel.run(async (context) => {
                const { currentWorkbook } = context;
                await context.sync();
                context.load(currentWorkbook, ['normalizedFullName']);
                await context.sync();
                this.documentPath = currentWorkbook.normalizedFullName;
            });
        } catch (error) {
            this.documentPath = '';
            log.error(error);
        }
        return this.documentPath;
    }

    public async getActiveDocumentSelection(): Promise<boolean> {
        try {
            await Excel.run(async (context) => {
                const { currentWorkbook } = context;
                await context.sync();
                this.hasValue = currentWorkbook.isResolved && !currentWorkbook.isNull;
            });
        } catch (error) {
            log.error(error);
        }
        return this.hasValue;
    }

    public async isReadOnly(): Promise<boolean> {
        try {
            await Excel.run(async (context) => {
                const { currentWorkbook } = context;
                await context.sync();
                context.load(currentWorkbook, ['readOnly']);
                await context.sync();
                this.hasValue = currentWorkbook.readOnly;
            });
        } catch (error) {
            log.error(error);
        }
        return this.hasValue;
    }

    public async isBusy(): Promise<boolean> {
        log.debug('isBusy,isExcelBusy');
        try {
            await Excel.run(async (context) => {
                const { application } = context;
                context.load(application, ['busy']);
                await context.sync();
                this.hasValue = application.busy;
            });
        } catch (error) {
            log.error(error);
        }
        return this.hasValue;
    }

    public async getDefaultExtension(): Promise<string> {
        return DocumentDefaultExtensions.Excel;
    }

    public async saveAsInTempStorage(): Promise<string> {
        const id = uuid();
        await Excel.run(async (context) => {
            const { currentWorkbook } = context;
            await context.sync();
            context.load(currentWorkbook, ['name']);
            await context.sync();
            const path = await FileOperations.resolvePath({
                rootName: 'temporaryFileStorage',
                parts: [id],
                filename: await getTemporaryFileName(currentWorkbook.name),
            });
            currentWorkbook.saveAs(path.filePath);
            this.documentPath = path.filePath;
            await context.sync();
        });
        return this.documentPath;
    }

    public async activateWindow(uniqueId: string): Promise<void> {
        await FileOperations.open(uniqueId);
    }
}
