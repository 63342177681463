import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const WkFileIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="wkFileIcon" viewBox="1 0 22 22" {...props}>
        <path d="M11.2,0.8h-8v22.4h17.6V10.4h-9.6V0.8z M12.8,0.8v8h8L12.8,0.8z" />
    </SvgIcon>
);

export default WkFileIcon;
