import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const wkCheckInIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="wkCheckInIcon" viewBox="0 0 24 24" {...props}>
        <g transform="translate(-103.8 -346)">
            <path
                d="M109.3,362h-3.5v-16h8.1l3.9,3.9V362h-5.3v-1.2h4.1v-9.4h-4v-4h-5.4v13.4h2.1L109.3,362
		L109.3,362z M113.9,347.9v2.1h2.1L113.9,347.9z"
            />
            <path d="M112.6,356.6l-1.1-1v6.5h-1.1v-6.5l-1.1,1.1l-0.7-0.7l2.4-2.4l2.4,2.3L112.6,356.6z" />
        </g>
    </SvgIcon>
);

export default wkCheckInIcon;
