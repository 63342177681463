import { ERROR_MESSAGE_TO_HANDLE_DATA_INTERNALLY, NETWORK_DOWN_ERROR } from '../../constants';
import { IStatusInfo } from '../../types';
import { IWindowProps } from '../types';
import { factory } from '../../ConfigLog4j';
import { UUIFetch } from '@wk/elm-uui-common';
const log = factory.getLogger('T360Utils');

export const getAccessToken = async (): Promise<string> => {
    const windProps = (window as unknown as IWindowProps).Props;
    const userObj = await windProps['userManager'].getUser();
    if (userObj?.access_token) {
        return userObj.access_token;
    } else {
        log.error('access not available, so retrying');
        throw Error(ERROR_MESSAGE_TO_HANDLE_DATA_INTERNALLY);
    }
};

// Used singleton to not rely on window props
// Intermittent: To cover rare scenario logout when queue is processing -> windProps['userManager'].getUser() is undefined
let singleton: null | Promise<{ access_token: string; profile: { network_id: number; sub: number } }> = null;
export const getNetworkId = async (): Promise<string> => {
    const windProps = (window as unknown as IWindowProps).Props;
    if (singleton == null) {
        const currentUser = windProps['userManager'].getUser();
        singleton = currentUser;
    }
    const userProps = await singleton;
    if (userProps?.profile?.network_id) {
        return userProps?.profile?.network_id.toString();
    } else {
        throw new Error('NetworkId could not be retrieved');
    }
};

export const getAPIServer = (): string => {
    const windProps = (window as unknown as IWindowProps).Props;
    return windProps['apiContextRoot'] + windProps['apiContextPath'];
};

export const getUserId_T360 = async (): Promise<number> => {
    const windProps = (window as unknown as IWindowProps).Props;
    const currentUser = await windProps['userManager'].getUser();
    return currentUser.profile.sub;
};

/**
 * To return statusInfo for t360 upload operations
 */
export const handleUploadErrors = (errorMessage: string): IStatusInfo => {
    if (errorMessage) {
        if (errorMessage.includes('response text:')) {
            const regMatch = new RegExp(/response text:(.*\})/).exec(errorMessage);
            const docError: Record<string, string> = {};
            if (regMatch) {
                const regObj: { warnings?: null; errors: Record<number, string[]> } = JSON.parse(regMatch[1]);
                const errorObj = regObj.errors;
                for (const key in errorObj) {
                    const docArray = errorObj[key];
                    docError[docArray[0]] = docArray[1];
                }
                return { errors: docError };
            } else {
                const regMatch2 = new RegExp(/response text: *(.*), *request *id/).exec(errorMessage);
                if (regMatch2) {
                    let matchedString = regMatch2[1];
                    const regMatchCode = new RegExp(/response code: *(.*?),/).exec(errorMessage);
                    if (regMatchCode && regMatchCode[1] === 'n/a') {
                        UUIFetch.triggerNetworkDownHandler();
                        matchedString = matchedString.replace('n/a', NETWORK_DOWN_ERROR);
                        log.error("Network is down, retrying the operation");
                        throw Error(ERROR_MESSAGE_TO_HANDLE_DATA_INTERNALLY);
                    }
                    return { errors: { default: matchedString } };
                } else {
                    throw new Error('Error could be parsed');
                }
            }
        } else if (errorMessage.includes('tus:')) {
            const errMessage = errorMessage.split('tus:')[1].trim();

            return { errors: { default: errMessage } };
        } else {
            throw new Error('Error could be parsed');
        }
    }
    throw new Error('Error could be parsed');
};
