import { injectable } from 'inversify';
import { IDocServiceRequest } from '../types';
import { IInputValidator } from '../interfaces/inputValidators/type';

@injectable()
export class DummyValidator implements IInputValidator {
    performValidation = (_nodeObj: IDocServiceRequest): boolean => {
        // Condition can be added to fail the operation
        // if not received needed inputs

        return true;
    };
}
