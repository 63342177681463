import { CHMessagingScope, IRefreshUUIForEntity } from '@wk/elm-uui-context-handler';
import * as H from 'history';
import { isEqual } from 'lodash';
import { uniqueId } from 'lodash';
import queryString from 'query-string';
import { manuallyDecrementPromiseCounter, manuallyIncrementPromiseCounter, trackPromise } from 'react-promise-tracker';
import { UUIHistory } from '../../../hooks/useUUIHistory';
import { UUILocation } from '../../../hooks/useUUILocation';
import { IApplicationUrls, IAppResources, INotification, UUIReduxDispatch } from '../../../reducers/types';
import { apiFetch, ERROR_404_MESSAGE } from '../../../utils/fetchUtils';
import { determineRedirectUrl, showConfirmationDialog } from '../../common/screenHelpers';
import { ScreenMode, UUIHistoryListState } from '../../common/types';
import {
    handleClFileDownload,
    isContextLayerOperation,
    performListScreenContextLayerOperation,
} from '../../contextLayerService/contextLayerHelpers';
import { clOpenDesktopLink, isOC } from '../../contextLayerService/contextLayerService';
import { messageBusDispatch } from '../../contextLayerService/messageBusService';
import { getItemScreenPropsFromUrl } from '../../itemScreen/itemScreenHelpers';
import { IItemScreenJSON, ItemScreenDispatch } from '../../itemScreen/types';
import {
    generatePagePostObject,
    getBreadCrumbsForRow,
    getCellDisplayValue,
    getListLinkPath,
    removeSavedViewAsync,
    scrollToTopOfEmbeddedList,
} from '../listScreenHelpers';
import {
    IFolderedListBreadCrumb,
    IListScreenColumn,
    IListScreenMetadata,
    IListScreenOperation,
    IListScreenPageData,
    IListScreenPostObject,
    IListScreenRow,
    IListScreenRows,
    IListScreenState,
    IParentItemInfo,
    ISavedView,
    ListScreenDispatch,
    RefreshList,
    IPerformListOperationResponse,
} from '../types';
import {
    addAndSelectSavedView,
    addBreadCrumb,
    loadBreadCrumb,
    updateAndSelectSavedView,
    updateMetadataSavedView,
    updateSavedView,
} from './listScreenReducer';

export const dispatchInitializeListScreen = (
    metadataUrl: string,
    dataUrl: string,
    isEmbeddedList: boolean,
    mode: ScreenMode | undefined,
    listScreenDispatch: ListScreenDispatch,
    reduxDispatch: UUIReduxDispatch,
    isMounted: React.MutableRefObject<boolean>,
    parentItemInfo: IParentItemInfo | undefined,
    listScreenHistoryObject: UUIHistoryListState | undefined,
    appResources: IAppResources,
): void => {
    Promise.all([
        apiFetch<IListScreenMetadata>(
            listScreenHistoryObject?.listMetadataUrl || metadataUrl,
            listScreenHistoryObject?.postObject,
        ),
        apiFetch<IListScreenRows>(listScreenHistoryObject?.listDataUrl || dataUrl, listScreenHistoryObject?.postObject),
    ])
        .then(([metadata, listData]) => {
            // bail if user has navigated away
            if (!isMounted.current) {
                return;
            }
            listScreenDispatch({
                type: 'InitializeListScreen',
                metadataUrl,
                dataUrl,
                metadata,
                listData,
                mode,
                isEmbeddedList,
                parentItemInfo,
                listScreenHistoryObject,
                appResources,
            });
            if (!isEmbeddedList) {
                reduxDispatch({ type: 'SetPageTitle', title: metadata.screenDisplayName });
            }
        })
        .catch((error) => {
            if (!error.message.includes(ERROR_404_MESSAGE)) {
                return;
            }
            // To refresh All instances in stale cases
            messageBusDispatch({
                type: 'RefreshUUIForEntity',
                scope: CHMessagingScope.AllInstances,
                message: {
                    isEntityDeleted: true,
                    entityInstanceId: parentItemInfo?.parentInstanceId.toString(),
                    entityTypeId:
                        parentItemInfo?.parentEntityId !== undefined ? parentItemInfo?.parentEntityId.toString() : '',
                    parentItemInfo: parentItemInfo,
                },
            });
        });
};

interface IDispatchRefreshList {
    postObject: IListScreenPostObject;
    metadataUrl: string;
    dataUrl: string;
    isEmbeddedList: boolean;
    currentListScreenStatePageObject: IListScreenPageData | undefined;
    listScreenDispatch: ListScreenDispatch;
    reduxDispatch: UUIReduxDispatch;
    isMounted: React.MutableRefObject<boolean>;
    fetchAll?: boolean;
    getIsCancelled?: () => boolean;
    setRequestIsActive?: (value: boolean) => void;
    refreshUUIForEntity?: IRefreshUUIForEntity;
}

export const dispatchRefreshList = ({
    postObject,
    metadataUrl,
    dataUrl,
    isEmbeddedList,
    currentListScreenStatePageObject,
    listScreenDispatch,
    reduxDispatch,
    isMounted,
    fetchAll,
    getIsCancelled,
    setRequestIsActive,
    refreshUUIForEntity,
}: IDispatchRefreshList): Promise<void> => {
    const shouldJustFetchData = postObject.page['column-data'] === currentListScreenStatePageObject?.['column-data'];
    return new Promise<void>((resolve) => {
        setRequestIsActive?.(true);
        manuallyIncrementPromiseCounter();
        if (shouldJustFetchData && !fetchAll) {
            apiFetch<IListScreenRows>(dataUrl, postObject, { skipTracking: true })
                .then((listData) => {
                    setRequestIsActive?.(false);
                    if (!isMounted.current || getIsCancelled?.()) {
                        if (!getIsCancelled?.()) {
                            manuallyDecrementPromiseCounter();
                        }
                        resolve();
                    } else {
                        manuallyDecrementPromiseCounter();
                        handleRefreshListResponse({
                            newListData: listData,
                            listScreenDispatch,
                            reduxDispatch,
                            isEmbeddedList: isEmbeddedList,
                            postObject,
                            previousListScreenStatePageObject: currentListScreenStatePageObject,
                            resolve,
                        });
                    }
                })
                .catch((error) => {
                    if (error.message.includes(ERROR_404_MESSAGE)) {
                        if (isOC() && refreshUUIForEntity) {
                            // To refresh All instances in stale cases
                            messageBusDispatch({
                                type: 'RefreshUUIForEntity',
                                scope: CHMessagingScope.AllInstances,
                                message: {
                                    ...refreshUUIForEntity,
                                    isEntityDeleted: true,
                                },
                            });
                        }
                    }
                });
        } else {
            Promise.all([
                apiFetch<IListScreenMetadata>(metadataUrl, postObject),
                apiFetch<IListScreenRows>(dataUrl, postObject),
            ]).then(([metadata, listData]) => {
                setRequestIsActive?.(false);
                if (!isMounted.current || getIsCancelled?.()) {
                    if (!getIsCancelled?.()) {
                        manuallyDecrementPromiseCounter();
                    }
                    resolve();
                } else {
                    manuallyDecrementPromiseCounter();
                    handleRefreshListResponse({
                        newMetadata: metadata,
                        newListData: listData,
                        listScreenDispatch,
                        reduxDispatch,
                        isEmbeddedList: isEmbeddedList,
                        postObject,
                        previousListScreenStatePageObject: currentListScreenStatePageObject,
                        resolve,
                    });
                }
            });
        }
    });
};

export const dispatchListScreenNonBulkOperation = (
    operationId: number,
    listScreenState: IListScreenState,
    applicationUrls: IApplicationUrls,
    history: H.History,
    reduxDispatch: UUIReduxDispatch,
    refreshList: RefreshList,
): void => {
    const metadata = listScreenState.metadata!;
    const operation = metadata.operations.find((op) => op.id === operationId)!;

    if (operation.desktopLink && isOC()) {
        clOpenDesktopLink(operation.desktopLink).then(() => {
            console.log('Finished opening desktop');
        });
    } else if (operation.targetScreenRenderingStyle?.toLowerCase() === 'normal') {
        const redirectUrl = determineRedirectUrl({
            operation,
            applicationUrls,
            parentItemInfo: listScreenState.parentItemInfo,
        });
        if (redirectUrl) {
            history.push(redirectUrl);
        }
    } else if (operation.targetScreenId) {
        reduxDispatch({
            type: 'OpenItemScreenDialog',
            itemScreenProps: {
                screenId: operation.targetScreenId,
                mode: operation.targetScreenMode!.toLowerCase() as ScreenMode,
                parentItemInfo: listScreenState.parentItemInfo,
                popupTitle: operation.displayName,
                operationContext: operation.operationContext,
                onClosePopupInline: () => {
                    refreshList();
                },
                entityTypeId: listScreenState?.metadata?.entityId?.toString(),
            },
        });
    }
};

export const dispatchListScreenBulkOperation = (
    operationId: number,
    listScreenState: IListScreenState,
    listScreenDispatch: ListScreenDispatch,
    refreshList: RefreshList,
): void => {
    const metadata = listScreenState.metadata!;
    const listData = listScreenState.listData!;
    const operation = metadata.operations.find(
        (op) => op.targetScreenName === metadata.screenName && op.id === operationId,
    );
    // will remove this in the future once we implement all types of list screen bulk operations
    if (operation !== undefined) {
        const postObject = generatePagePostObject(listData.page, { pageNumber: 1 });
        postObject.operation = operationId;
        postObject.selectedIds = listScreenState.checkedRows;
        refreshList({ postObject }).then(() => {
            listScreenDispatch({ type: 'ClearAllCheckedRows' });
            if (!operation.noOp) {
                messageBusDispatch({
                    type: 'RefreshUUIForEntity',
                    scope: CHMessagingScope.OtherInstances,
                    message: {
                        entityTypeId: metadata.entityId.toString(),
                    },
                });
            }
        });
    } else {
        console.log('Implement operation with ID: ' + operationId);
    }
};

export const dispatchListScreenRowOperation = async (
    operation: IListScreenOperation,
    row: IListScreenRow,
    appResources: IAppResources,
    applicationUrls: IApplicationUrls,
    history: H.History,
    listScreenState: IListScreenState,
    reduxDispatch: UUIReduxDispatch,
    refreshList: RefreshList,
    listScreenDispatch: ListScreenDispatch,
): Promise<void> => {
    if (operation.confirmationMessage) {
        const shouldProceed = await showConfirmationDialog(reduxDispatch, operation.confirmationMessage, appResources);
        if (!shouldProceed) {
            return;
        }
    }
    if (operation.desktopLink && isOC()) {
        clOpenDesktopLink(operation.desktopLink).then(() => {
            console.log('Finished opening desktop');
        });
    } else if (
        operation.targetScreenRenderingStyle === 'Normal' ||
        operation.type === 'File' ||
        operation.contextLayerOperationName
    ) {
        if ((operation.id && operation.id > 0) || isContextLayerOperation(operation)) {
            const listData = listScreenState.listData!;
            const postObject = generatePagePostObject(listData.page);
            if (isContextLayerOperation(operation)) {
                const promise = performListScreenContextLayerOperation(
                    operation.contextLayerOperationName!,
                    listScreenState,
                    row,
                    appResources.username,
                );
                if (operation.type === 'File') {
                    listScreenDispatch({ type: 'DisableRow', id: row.id });
                } else {
                    // show the global spinner
                    trackPromise(promise);
                }
                promise.then((response) => {
                    if (operation.type === 'File') {
                        handleClFileDownload(response as Response);
                        listScreenDispatch({ type: 'EnableRow', id: row.id });
                    } else {
                        refreshList({ postObject });
                    }
                });
            } else {
                const performOperationUrl = getPerformOperationUrl(applicationUrls, listScreenState, row, operation);
                apiFetch<IPerformListOperationResponse>(performOperationUrl)
                    .then((response) => {
                        const notification = getNotificationOptions(response);
                        if (notification) {
                            reduxDispatch({
                                type: 'ShowNotification',
                                notification,
                            });
                        }
                        refreshList().then(() => {
                            if (!operation.noOp) {
                                messageBusDispatch({
                                    type: 'RefreshUUIForEntity',
                                    scope: CHMessagingScope.OtherInstances,
                                    message: {
                                        isEntityDeleted: operation.isBrowserBackOnComplete,
                                        entityInstanceId: row.id.toString(),
                                        entityTypeId:
                                            listScreenState.metadata?.entityId !== undefined
                                                ? listScreenState.metadata?.entityId.toString()
                                                : '',
                                        parentItemInfo:
                                            listScreenState.parentItemInfo !== undefined
                                                ? listScreenState.parentItemInfo
                                                : undefined,
                                    },
                                });
                            }
                        });
                    })
                    .catch((error) => {
                        if (!error.message.includes(ERROR_404_MESSAGE)) {
                            return;
                        }
                        // To refresh All instances in stale cases
                        messageBusDispatch({
                            type: 'RefreshUUIForEntity',
                            scope: CHMessagingScope.AllInstances,
                            message: {
                                isEntityDeleted: operation.isBrowserBackOnComplete,
                                entityInstanceId: row.id.toString(),
                                entityTypeId:
                                    listScreenState.metadata?.entityId !== undefined
                                        ? listScreenState.metadata?.entityId.toString()
                                        : '',
                                parentItemInfo:
                                    listScreenState.parentItemInfo !== undefined
                                        ? listScreenState.parentItemInfo
                                        : undefined,
                            },
                        });
                    });
            }
        } else {
            const redirectUrl = determineRedirectUrl({
                operation,
                applicationUrls,
                selectedRowId: row.id,
                entityId: listScreenState?.metadata?.entityId.toString() || '',
            });
            if (redirectUrl) {
                history.push(redirectUrl);
            }
        }
    } else {
        // dispatch the action to open the full screen dialog
        reduxDispatch({
            type: 'OpenItemScreenDialog',
            itemScreenProps: {
                ...getItemScreenPropsFromUrl(operation.linkUrl!.replace('{selectedRowId}', row.id.toString())),
                onClosePopupInline: () => {
                    refreshList();
                },
                popupTitle: operation.targetScreenName,
                operationContext: operation.operationContext,
                entityTypeId: listScreenState?.metadata?.entityId?.toString(),
            },
        });
    }
};

const getPerformOperationUrl = (
    applicationUrls: IApplicationUrls,
    listScreenState: IListScreenState,
    row: IListScreenRow,
    operation: IListScreenOperation,
): string => {
    const apiPath = Props['apiContextRoot'] + Props['apiContextPath'];
    const screenURL =
        apiPath +
        applicationUrls.performListScreenOperationPath
            .replace('{screenId}', listScreenState.metadata?.screenId.toString() || '')
            .replace('{entityInstanceId}', row.id?.toString() || '')
            .replace('{operationId}', operation.id.toString())
            .replace('{parentEntityId}', listScreenState.metadata?.entityId.toString() || '')
            .replace('{parentInstanceId}', listScreenState.parentItemInfo?.parentInstanceId?.toString() || '');
    return screenURL;
};

const getNotificationOptions = (response: IPerformListOperationResponse): INotification | undefined => {
    const key = uniqueId(Date.now().toString());
    if (response.successMessage) {
        return {
            key,
            message: response.successMessage,
            options: {
                variant: 'success',
                persist: false,
            },
        };
    }
    if (response.errors) {
        const message = Object.values(response.errors)
            .map((field) => field[1])
            .join('\n');
        return {
            key,
            message,
            options: {
                variant: 'error',
                persist: true,
            },
        };
    }
    return undefined;
};

export const dispatchRemoveTab = (
    listScreenState: IListScreenState,
    listScreenDispatch: ListScreenDispatch,
    removeTabUrl: string,
    selectedTabId: number,
): Promise<void> => {
    return new Promise<void>((resolve) => {
        removeSavedViewAsync(listScreenState.metadata!.screenId, selectedTabId, removeTabUrl).then((json) => {
            if (json.result === 'success') {
                listScreenDispatch({
                    type: 'RemoveSavedView',
                    existingSavedViewIndex: listScreenState.metadata!.savedViews.findIndex(
                        (sv) => sv.id == selectedTabId,
                    ),
                });
                resolve();
            }
        });
    });
};

export const dispatchPostRemoveTab = (
    isCurrentTabBeingRemoved: boolean,
    listScreenState: IListScreenState,
    listScreenDispatch: ListScreenDispatch,
    refreshList: RefreshList,
): void => {
    if (isCurrentTabBeingRemoved) {
        let savedViewToLoad = listScreenState.metadata?.savedViews.find((sv) => sv.isUserDefault);
        if (savedViewToLoad == undefined) {
            savedViewToLoad = listScreenState.metadata?.savedViews.find((sv) => sv.isSystemDefault);
        }
        const pageInfo = generatePagePostObject(listScreenState.listData!.page, {
            'column-data': savedViewToLoad!.columns ? savedViewToLoad!.columns[0] : undefined,
            filters: savedViewToLoad!.filters,
            sortInfo: savedViewToLoad!.sort,
            pageNumber: 1,
            savedView: savedViewToLoad,
        });
        refreshList({ postObject: pageInfo, resetBaselinePageData: true });
        listScreenDispatch({ type: 'CloseTabOptionsRemoveTabDialog' });
        listScreenDispatch({ type: 'CloseListScreenEditDialog' });
    } else {
        listScreenDispatch({ type: 'CloseTabOptionsRemoveTabDialog' });
    }
};

export const dispatchLoadFolder = (
    row: IListScreenRow,
    columns: IListScreenColumn[],
    listScreenDispatch: ListScreenDispatch,
    listScreenState: IListScreenState,
    parentItemScreenDispatch: ItemScreenDispatch | undefined,
    reduxDispatch: UUIReduxDispatch,
    location: UUILocation,
    history: UUIHistory,
): void => {
    const apiPath = Props['apiContextRoot'] + Props['apiContextPath'];
    const nameColumn = columns.find((c) => c.name === 'name')!;
    const itemScreenUrl = apiPath + getListLinkPath(row, nameColumn).replace('.do', '.json');
    if (parentItemScreenDispatch) {
        parentItemScreenDispatch({
            type: 'SetTabEmbeddedListMetadata',
            metadata: {
                isDocumentOrEmailTab: listScreenState.metadata?.isDocumentEntity,
                entityName: listScreenState.metadata?.entityName,
                folderId: row.id,
                breadCrumbs: getBreadCrumbsForRow(listScreenState, row),
                entityTypeId: listScreenState.metadata?.entityId?.toString(),
            },
        });
    }
    apiFetch<IItemScreenJSON>(itemScreenUrl)
        .then((nestedItemScreenJson) => {
            const documentsField = nestedItemScreenJson.metadata.fields.find(
                (field) => field.name === 'documents' || field.name === 'documents[]',
            )!;
            const listMetadataUrl =
                documentsField.sourceListScreen!.selectedListScreenMetadataUrl ||
                documentsField.sourceListScreen!.selectedListScreenUrl + '&_metadata=true';
            const listDataUrl = documentsField.sourceListScreen!.selectedListScreenUrl;
            const qs = queryString.parse(listDataUrl.split('?')[1]);
            const postObject = generatePagePostObject(listScreenState.listData!.page, { pageNumber: 1 });
            postObject['search-keywords'] = listScreenState.quickSearchString;

            Promise.all([
                apiFetch<IListScreenMetadata>(listMetadataUrl, postObject),
                apiFetch<IListScreenRows>(listDataUrl, postObject),
            ]).then(([metadata, listData]) => {
                handleRefreshListResponse({
                    newMetadata: metadata,
                    newMetadataUrl: listMetadataUrl,
                    newListData: listData,
                    newListDataUrl: listDataUrl,
                    newParentItemInfo: {
                        parentEntityId: qs.parentEntityId,
                        parentEntityName: qs.parentEntityName,
                        parentInstanceId: qs.parentInstanceId,
                        parentFieldName: qs.parentFieldName,
                    },
                    listScreenDispatch,
                    reduxDispatch,
                    isEmbeddedList: true,
                });
                const breadCrumb = {
                    folderName: getCellDisplayValue(row, nameColumn),
                    listMetadataUrl,
                    listDataUrl,
                    parentItemInfo: {
                        ...listScreenState.parentItemInfo,
                        parentInstanceDisplayName: row._metadata.objDisplayValue,
                    } as IParentItemInfo,
                    folderId: row.id.toString(),
                };
                listScreenDispatch({
                    type: 'AddBreadCrumb',
                    breadCrumb,
                });
                updateHistoryBreadCrumbsState(
                    location,
                    history,
                    addBreadCrumb(listScreenState.breadCrumbs, breadCrumb),
                    breadCrumb,
                    postObject,
                );
                scrollToTopOfEmbeddedList();
            });
        })
        .catch((error) => {
            if (error.message.includes(ERROR_404_MESSAGE)) {
                if (isOC()) {
                    // To refresh All instances in stale cases
                    messageBusDispatch({
                        type: 'RefreshUUIForEntity',
                        scope: CHMessagingScope.AllInstances,
                        message: {
                            isEntityDeleted: true,
                            entityInstanceId: row.id.toString(),
                            entityTypeId:
                                listScreenState.metadata?.entityId !== undefined
                                    ? listScreenState.metadata?.entityId.toString()
                                    : '',
                            parentItemInfo:
                                listScreenState.parentItemInfo !== undefined
                                    ? listScreenState.parentItemInfo
                                    : undefined,
                        },
                    });
                }
            }
        });
};

export const dispatchLoadFolderFromBreadcrumb = (
    breadCrumb: IFolderedListBreadCrumb,
    listScreenState: IListScreenState,
    listScreenDispatch: ListScreenDispatch,
    reduxDispatch: UUIReduxDispatch,
    location: UUILocation,
    history: UUIHistory,
): void => {
    const postObject = generatePagePostObject(listScreenState.listData!.page, { pageNumber: 1 });
    postObject['search-keywords'] = listScreenState.quickSearchString;

    Promise.all([
        apiFetch<IListScreenMetadata>(breadCrumb.listMetadataUrl, postObject),
        apiFetch<IListScreenRows>(breadCrumb.listDataUrl, postObject),
    ]).then(([metadata, listData]) => {
        handleRefreshListResponse({
            newMetadata: metadata,
            newMetadataUrl: breadCrumb.listMetadataUrl,
            newListData: listData,
            newListDataUrl: breadCrumb.listDataUrl,
            newParentItemInfo: breadCrumb.parentItemInfo,
            listScreenDispatch,
            reduxDispatch,
            isEmbeddedList: true,
        });
        listScreenDispatch({ type: 'ClickBreadCrumb', breadCrumb });
        updateHistoryBreadCrumbsState(
            location,
            history,
            loadBreadCrumb(listScreenState.breadCrumbs, breadCrumb),
            breadCrumb,
            postObject,
        );
        scrollToTopOfEmbeddedList();
    });
};

export const dispatchAddAndSelectSavedView = (
    listScreenState: IListScreenState,
    listScreenDispatch: ListScreenDispatch,
    savedView: ISavedView,
    location: UUILocation,
    history: UUIHistory,
): void => {
    const newState = addAndSelectSavedView(listScreenState, savedView);
    updateHistorySavedViewState(location, history, newState);
    listScreenDispatch({ type: 'AddAndSelectSavedView', savedView });
};

export const dispatchUpdateSavedView = (
    listScreenState: IListScreenState,
    listScreenDispatch: ListScreenDispatch,
    savedView: ISavedView,
    location: UUILocation,
    history: UUIHistory,
): void => {
    const existingSavedViewIndex = listScreenState.metadata!.savedViews.findIndex((sv) => sv.id === savedView.id);
    const newState = updateSavedView(listScreenState, existingSavedViewIndex, savedView);
    updateHistorySavedViewState(location, history, newState);
    listScreenDispatch({
        type: 'UpdateSavedView',
        existingSavedViewIndex,
        savedView,
    });
};

export const dispatchUpdateAndSelectSavedView = (
    listScreenState: IListScreenState,
    listScreenDispatch: ListScreenDispatch,
    savedView: ISavedView,
    location: UUILocation,
    history: UUIHistory,
): void => {
    const existingSavedViewIndex = listScreenState.metadata!.savedViews.findIndex((sv) => sv.id === savedView.id);
    const newState = updateAndSelectSavedView(listScreenState, existingSavedViewIndex, savedView);
    updateHistorySavedViewState(location, history, newState);
    listScreenDispatch({
        type: 'UpdateAndSelectSavedView',
        existingSavedViewIndex,
        savedView,
    });
};

export const dispatchUpdateMetadataSavedView = (
    listScreenState: IListScreenState,
    listScreenDispatch: ListScreenDispatch,
    savedView: ISavedView,
    location: UUILocation,
    history: UUIHistory,
): void => {
    const existingSavedViewIndex = listScreenState.metadata!.savedViews.findIndex((sv) => sv.id === savedView.id);
    const newState = updateMetadataSavedView(listScreenState, existingSavedViewIndex, savedView);
    updateHistorySavedViewState(location, history, newState);
    listScreenDispatch({
        type: 'UpdateMetadataSavedView',
        existingSavedViewIndex,
        savedView,
    });
};

// private functions

// postObject: if no post object is provided, then a get occurred which should reset everything, so clear
// listData: if postObject is undefined, then listData is optional as well since we have nothing to compare against
const clearCheckedRowsIfNeeded = (
    postObject: IListScreenPostObject | undefined,
    currentListPageData: IListScreenPageData | undefined,
    listScreenDispatch: ListScreenDispatch,
): void => {
    if (shouldClearCheckedRows(currentListPageData, postObject)) {
        listScreenDispatch({ type: 'ClearAllCheckedRows' });
    }
};

export const shouldClearCheckedRows = (
    currentPageObject: IListScreenPageData | undefined,
    postObject: IListScreenPostObject | undefined,
): boolean => {
    return (
        !postObject ||
        !currentPageObject ||
        !isEqual(postObject.page.filters, currentPageObject.filters) ||
        postObject.page.savedView.id !== currentPageObject.savedView.id
    );
};

interface HandleRefreshListResponseArgs {
    newMetadata?: IListScreenMetadata;
    newListData: IListScreenRows;
    newMetadataUrl?: string; // for loading folders ... this url will change for each subfolder
    newListDataUrl?: string; // for loading folders ... this url will change for each subfolder
    newParentItemInfo?: IParentItemInfo;
    listScreenDispatch: ListScreenDispatch;
    reduxDispatch: UUIReduxDispatch;
    isEmbeddedList: boolean;
    postObject?: IListScreenPostObject;
    previousListScreenStatePageObject?: IListScreenPageData;
    resolve?: (value?: void | PromiseLike<void> | undefined) => void;
}

const handleRefreshListResponse = ({
    newMetadata,
    newListData,
    newMetadataUrl,
    newListDataUrl,
    newParentItemInfo,
    listScreenDispatch,
    reduxDispatch,
    isEmbeddedList,
    postObject,
    previousListScreenStatePageObject,
    resolve,
}: HandleRefreshListResponseArgs) => {
    clearCheckedRowsIfNeeded(postObject, previousListScreenStatePageObject, listScreenDispatch);
    listScreenDispatch({
        type: 'RefreshListScreen',
        metadata: newMetadata,
        metadataUrl: newMetadataUrl,
        listData: newListData,
        listDataUrl: newListDataUrl,
        parentItemInfo: newParentItemInfo,
    });
    if (!isEmbeddedList && newMetadata) {
        reduxDispatch({ type: 'SetPageTitle', title: newMetadata.screenDisplayName });
    }
    if (resolve) {
        resolve();
    }
};

const updateHistorySavedViewState = (location: UUILocation, history: UUIHistory, newState: IListScreenState) => {
    if (!location.state) {
        location.state = {};
    }
    location.state.listScreen = {
        ...location.state.listScreen,
        postObject: generatePagePostObject(newState.listData!.page),
        baselinePageData: newState.baselinePageData,
    };
    history.replace(location);
};

const updateHistoryBreadCrumbsState = (
    location: UUILocation,
    history: UUIHistory,
    breadCrumbs: IFolderedListBreadCrumb[],
    activeBreadCrumb: IFolderedListBreadCrumb,
    postObject: IListScreenPostObject | undefined,
) => {
    if (!location.state) {
        location.state = {};
    }
    location.state.listScreen = {
        ...location.state.listScreen,
        postObject,
        ...activeBreadCrumb,
        breadCrumbs,
    };
    history.replace(location);
};
