import React from 'react';
import classNames from 'classnames';
import { IItemScreenDataElement, IItemScreenField } from './types';
import css from './itemScreenAccountingField.module.scss';

interface IItemScreenAccountingField {
    field: IItemScreenField;
    fieldData: IItemScreenDataElement;
}

const ItemScreenAccountingField: React.FC<IItemScreenAccountingField> = ({ field, fieldData }) => {
    return (
        <div className={css.fieldContainer}>
            <label
                className={classNames(css.fieldLabel, {
                    [css.hightlightFieldLabel]: field.fieldStyle === 'HighLight',
                })}>
                {field.displayName}
            </label>
            <label
                className={classNames(css.fieldValue, {
                    [css.hightlightFieldValue]: field.fieldStyle === 'HighLight',
                })}>
                {fieldData.displayValue}
            </label>
        </div>
    );
};

export default ItemScreenAccountingField;
