import { IDocTransformer } from '../../interfaces/transformer/type';
import { injectable } from 'inversify';
import { factory } from '../../ConfigLog4j';
import { IMatterDoc, IRenameFolder } from '../../types';
import { JRAdapterRenameFolderActionableEntity } from '../actionableEntity/renameFolderActionableEntity';
import { mapNames, mapHref } from '../utils/maps';
import { attribute_Rename } from '../types';

const log = factory.getLogger('JRAdapterRenameFolderEntityTransformer');

@injectable()
export class JRAdapterRenameFolderEntityTransformer implements IDocTransformer {
    matterDoc: IMatterDoc;
    transformIntoActionableEntity(nodeObj: IRenameFolder): JRAdapterRenameFolderActionableEntity {
        log.debug('Calling transformIntoActionableEntity');
        const obj = {} as JRAdapterRenameFolderActionableEntity;
        // TO CREATE ACTIONABLE ENTITY FROM nodeObj and matterDoc
        obj.attributes = {
            // name: nodeObj.name,
            isFolder: true,
            // associatedEntityName: nodeObj.entityContext.type,
            // [mapNames[nodeObj.entityContext.type]]: {
            //   href: '../../' + mapHref[nodeObj.entityContext.type] + '/' + nodeObj.entityContext.id,
            // },
        } as attribute_Rename;

        if (nodeObj?.name) {
            obj.attributes.name = nodeObj.name;
        }

        if (nodeObj.entityContext?.type) {
            obj.attributes.associatedEntityName = nodeObj.entityContext.type;
        }

        if (nodeObj?.entityContext?.id && nodeObj?.entityContext?.type) {
            obj.attributes[mapNames[nodeObj.entityContext.type]] = {
                href: '../../' + mapHref[nodeObj.entityContext.type] + '/' + nodeObj.entityContext.id,
            };
        }

        return obj;
    }
}
