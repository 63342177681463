import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useUUIHistory } from '../../../hooks/useUUIHistory';
import { useUUILocation } from '../../../hooks/useUUILocation';
import TabPanel from '../../common/tabPanel';
import { ScreenMode, UUIHistoryListState } from '../../common/types';
import UUITab from '../../common/uuiTab';
import UUITabs from '../../common/uuiTabs';
import { scrollToTopOfEmbeddedList } from '../../listScreen/listScreenHelpers';
import { useItemScreenDispatch, useItemScreenState } from '../context/itemScreenContext';
import { getActiveParentAndSubTabMeta, getParentTabMetaByIndex } from '../itemScreenHelpers';
import ItemScreenView from '../itemScreenView';
import css from './itemScreenTabs.module.scss';
import { ItemScreenTabMap } from './types';

interface IItemScreenTabsProps {
    mode: ScreenMode;
    tabs: ItemScreenTabMap;
    subTabs?: boolean;
    parentTabIndex?: number;
    mainTabStickyHeight?: number;
    listStickyHeight?: number;
    addToTabHeight?: (ht: number) => void;
}

// This is a recursive component
const ItemScreenTabs: React.FC<IItemScreenTabsProps> = ({
    mode,
    tabs,
    subTabs,
    parentTabIndex,
    mainTabStickyHeight = 0,
    listStickyHeight,
    addToTabHeight,
}) => {
    const [mainTabHeight, setMainTabHeight] = useState(0);
    const [, setTotalTabHeight] = useState(listStickyHeight ? listStickyHeight : 0);
    const tabsRef = useRef<HTMLDivElement>(null);
    const cummTabHeightRef = useRef<number>(listStickyHeight ? listStickyHeight : 0);
    const itemScreenState = useItemScreenState();
    const itemScreenDispatch = useItemScreenDispatch();
    // the following 2 variables store the active tab for THIS level of tabs (main level OR subtab level)
    const activeTabOrSubtab = subTabs
        ? getParentTabMetaByIndex(itemScreenState, parentTabIndex)?.activeSubTab
        : itemScreenState.activeTab;
    const visitedTabsOrSubtabs = subTabs
        ? getParentTabMetaByIndex(itemScreenState, parentTabIndex)?.visitedSubTabs
        : itemScreenState.visitedTabs;

    const [activeParentTabMeta] = getActiveParentAndSubTabMeta(itemScreenState);
    const location = useUUILocation();
    const history = useUUIHistory();
    const [embeddedListHistoryState, setEmbeddedListHistoryState] = useState<{
        [tabKey: string]: UUIHistoryListState | undefined;
    }>({});

    const calcTabHeight = useCallback(() => {
        if (tabsRef.current) {
            const tabsClass = subTabs ? '.subtabs-container' : '.tabs-container';
            const ht = tabsRef.current.querySelector(tabsClass)!.getBoundingClientRect().height;
            if (!subTabs) {
                setMainTabHeight(ht);
                setTotalTabHeight(ht);
                cummTabHeightRef.current = ht;
            } else if (addToTabHeight) {
                addToTabHeight(ht);
            }
        }
    }, [subTabs, addToTabHeight]);

    useEffect(() => {
        // activate the first subtab when clicking a parent tab with subtabs the first time
        if (subTabs && activeTabOrSubtab === undefined) {
            itemScreenDispatch({ type: 'SetActiveSubTab', tabIndex: 0 });
        }
        // this is for when a subtab has the editsApplied dialog, and user clicks a parent tab to navigate away,
        // then comes back ... we have to reactivate the selected subtab since it was removed from visitedSubTabs
        // as part of the SetActiveTab reducer when the user navigated away.
        if (!subTabs && activeTabOrSubtab !== undefined) {
            // does the tab have subtabs but the active subtab isn't in the visitedSubTabs array? If so, then
            // call the SetActiveSubTab action to reinitialize the subtab.
            if (
                activeParentTabMeta &&
                activeParentTabMeta.activeSubTab !== undefined &&
                activeParentTabMeta.visitedSubTabs &&
                activeParentTabMeta.visitedSubTabs.find((vst) => vst.index === activeParentTabMeta.activeSubTab) ===
                    undefined
            ) {
                itemScreenDispatch({ type: 'SetActiveSubTab', tabIndex: activeParentTabMeta.activeSubTab });
            }
        }
    }, [activeParentTabMeta, activeTabOrSubtab, itemScreenDispatch, subTabs]);

    useEffect(() => {
        calcTabHeight();
    }, [calcTabHeight]);

    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
    }, [activeTabOrSubtab]);

    if (Object.keys(tabs).length === 0 || activeTabOrSubtab === undefined) {
        return null;
    }

    const tabsStyle =
        mainTabStickyHeight > 0
            ? {
                  top: mainTabStickyHeight + 'px',
              }
            : undefined;

    const addToTotalTabHeight = (height: number) => {
        cummTabHeightRef.current = mainTabHeight + height;
        setTotalTabHeight(cummTabHeightRef.current);
    };

    /**
     * When navigating between tabs, if the list screen in the tab we are leaving has some
     * history state, and the tab wasn't marked to be reset, store off the history state
     * so that if the user returns to the tab, the history state will be restored for that tab.
     */
    const storeEmbeddedListHistoryState = (newTabIndex: number) => {
        const currentTabKey = subTabs
            ? `${itemScreenState.activeTab}_${activeTabOrSubtab}`
            : itemScreenState.activeTab.toString();
        const newTabKey = subTabs ? `${itemScreenState.activeTab}_${newTabIndex.toString()}` : newTabIndex.toString();
        setEmbeddedListHistoryState({
            ...embeddedListHistoryState,
            [currentTabKey]: location.state?.listScreen ? location.state.listScreen : undefined,
        });

        // now store the current tab listscreen state in history state
        location.state = { ...location.state, listScreen: embeddedListHistoryState[newTabKey] };
        history.replace(location);
    };

    const handleTabChange = (_: ChangeEvent<unknown>, newTabIndex: number): void => {
        itemScreenDispatch({ type: subTabs ? 'SetActiveSubTab' : 'SetActiveTab', tabIndex: newTabIndex });
        calcTabHeight();
        storeEmbeddedListHistoryState(newTabIndex);
        scrollToTopOfEmbeddedList();
    };

    return (
        <div ref={tabsRef} id="tabs">
            <UUITabs
                className={subTabs ? 'subtabs-container' : 'tabs-container'}
                style={tabsStyle}
                value={activeTabOrSubtab}
                onChange={handleTabChange}
                isSubTab={subTabs}>
                {Object.keys(tabs).map((tabName, index) => (
                    <UUITab
                        key={index}
                        label={tabName}
                        id={`scrollable-auto-tab-${index}`}
                        data-testid={`tab-${tabName}`}
                        isSubTab={subTabs}
                    />
                ))}
            </UUITabs>
            {Object.entries(tabs).map(([tabName, tabEntry], i) => {
                if (!visitedTabsOrSubtabs?.find((vt) => vt.index === i)) {
                    return;
                }

                return (
                    <TabPanel
                        value={activeTabOrSubtab}
                        index={i}
                        key={i}
                        tabName={tabName}
                        className={!subTabs ? css.tabPanelRoot : undefined}
                        isSubTab={subTabs}>
                        {Object.keys(tabs[tabName].subTabs).length > 0 ? (
                            <ItemScreenTabs
                                mode={mode}
                                tabs={tabs[tabName].subTabs}
                                subTabs={true}
                                parentTabIndex={i}
                                mainTabStickyHeight={mainTabHeight}
                                listStickyHeight={cummTabHeightRef.current}
                                addToTabHeight={addToTotalTabHeight}
                            />
                        ) : (
                            <ItemScreenView
                                sections={tabEntry.sections}
                                stickyTopPosition={listStickyHeight ? listStickyHeight : cummTabHeightRef.current}
                                tabIndex={i}
                                parentTabIndex={parentTabIndex}
                            />
                        )}
                    </TabPanel>
                );
            })}
        </div>
    );
};

export default ItemScreenTabs;
