import { NotImplementedException } from '../exceptions';
import { OcRendererEvent, OcRenderer } from './ocRenderer';

export class LocalOcRenderer implements OcRenderer {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public hasListener(channel: string, listenerId: string): boolean {
        throw new NotImplementedException('valid only in oc');
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public invoke<T>(channel: string, ...args: unknown[]): Promise<T> {
        throw new NotImplementedException('valid only in oc');
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public on(args: string | ((event: OcRendererEvent, ...args: unknown[]) => void)): string {
        throw new NotImplementedException('valid only in oc');
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public removeListener(channel: string, listenerId: string): void {
        throw new NotImplementedException('valid only in oc');
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public send(...args: unknown[]): void {
        throw new NotImplementedException('valid only in oc');
    }
}
