export enum FileExtensions {
    Eml = '.eml',
    Excel = '.xlsx',
    Excel97_2003 = '.xls',
    Ffjpeg = '.ffjpeg',
    Gif = '.gif',
    Htm = '.htm',
    Html = '.html',
    Iejpeg = '.iejpeg',
    Jpe = '.jpe',
    Jpeg = '.jpeg',
    Jpg = '.jpg',
    Msg = '.msg',
    Odp = '.odp',
    Ods = '.ods',
    Odt = '.odt',
    Pdf = '.pdf',
    PowerPoint = '.pptx',
    PowerPoint97_2003 = '.ppt',
    Prj = '.prj',
    Rar = '.rar',
    Rtf = '.rtf',
    Txt = '.txt',
    Vcf = '.vcf',
    Word = '.docx',
    Word97_2003 = '.doc',
    Xml = '.xml',
    Zip = '.zip',
    ExcelMacroEnabled = '.xlsm',
    ExcelBinary = '.xlsb',
    WordMacroEnabled = '.docm',
    Word97_2003_Template = '.dot',
    WordMacroEnabledTemplate = '.dotm',
    WordTemplate = '.dotx',
    Csv = '.csv',
    Xlam = '.xlam',
    Xltx = '.xltx',
    Pot = '.pot',
    Potx = '.potx',
    Pps = '.pps',
    Ppsx = '.ppsx',
    Pptm = '.pptm',
    Xltm = '.xltm',
    Tif = '.tif',
    Tiff = '.tiff',
    Gaf = '.gaf',
    arc = '.arc',
    Gef = '.gef',
    Saf = '.saf',
    Gsa = '.gsa',
    Nsf = '.nsf',
    Ms5 = '.ms5',
    Ms6 = '.ms6',
    Ept = '.ept',
    Gept = '.gept',
    Tpr = '.tpr',
    Bpa = '.bpa',
    Tv = '.tv',
    Op2 = '.op2',
    Ac_ = '.ac_',
    As1 = '.as1',
    Eypar = '.eypar',
    Eyrpt = '.eyrpt',
    Ac = '.ac',
    Dbf = '.dbf',
    Fpt = '.fpt',
    Cvw = '.cvw',
    Bak = '.bak',
    Imd = '.imd',
    Png = '.png',
    Vsdx = '.vsdx',
    One = '.one',
    Vsd = '.vsd',
    Vdx = '.vdx',
    Mp4 = '.mp4',
    Bmp = '.bmp',
    Asf = '.asf',
    Wma = '.wma',
    Wmv = '.wmv',
    Wm = '.wm',
    Asx = '.asx',
    Wax = '.wax',
    Wvx = '.wvx',
    Wmx = '.wmx',
    Wpl = '.wpl',
    Dvr = '.dvr-ms',
    Wmd = '.wmd',
    Avi = '.avi',
    Mpg = '.mpg',
    Mpeg = '.mpeg',
    M1v = '.m1v',
    Mp2 = '.mp2',
    Mp3 = '.mp3',
    Mpa = '.mpa',
    Mpe = '.mpe',
    M3u = '.m3u',
    Mid = '.mid',
    Midi = '.midi',
    Rmi = '.rmi',
    Aif = '.aif',
    Aifc = '.aifc',
    Aiff = '.aiff',
    Au = '.au',
    Snd = '.snd',
    Wav = '.wav',
    Cda = '.cda',
    Ivf = '.ivf',
    Wmz = '.wmz',
    Wms = '.wms',
    Mov = '.mov',
    M4a = '.m4a',
    M4v = '.m4v',
    Mp4v = '.mp4v',
    ThreeG2 = '.3g2',
    ThreeGp2 = '.3gp2',
    ThreeGp = '.3gp',
    ThreeGpp = '.3gpp',
    Aac = '.aac',
    Adt = '.adt',
    Adts = '.adts',
    M2ts = '.m2ts',
    Tef = '.tef',
    Ran = '.ran',
    Bbk = '.bbk',
    Tcf = '.tcf',
    Eyje = '.eyje',
    Cdx = '.cdx',
    Tv5 = '.tv5',
}
