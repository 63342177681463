import { DefaultAuthenticationProvider } from './DefaultAuthenticationProvider';
import { IAuthenticationProvider } from './types';

let authenticationProvider: IAuthenticationProvider = DefaultAuthenticationProvider;

export function setAuthenticationProvider(provider: IAuthenticationProvider): void {
    authenticationProvider = provider;
}

export function getAuthenticationProvider(): IAuthenticationProvider {
    if (!authenticationProvider) {
        throw new Error('Authentication Provider not previously set up.');
    }

    return authenticationProvider;
}
