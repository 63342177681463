import { IAppsMenu, IPassportContext } from '../reducers/types';

export const getPassportContextDefaultLandingPageUrl = (
    passportContext: IPassportContext,
    fallbackLandingPage: string,
): string => {
    return passportContext.defaultLandingPage ?? fallbackLandingPage;
};

export const getActivePassportContext = (appsMenu: IAppsMenu): IPassportContext => {
    return appsMenu.passportContexts.find((pc) => pc.id === appsMenu.activeContext)!;
};

/*
 * Retrieve the App function landing page URL.
 */
export const getPassportContextLandingPageUrl = (
    passportContext: IPassportContext,
    fallbackLandingPage: string,
): string => {
    return passportContext.defaultLandingPage || fallbackLandingPage;
};
