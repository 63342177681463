import { ExceptionStatus } from '@wk/office-companion-js-common';

export enum ComErrorCode {
    InnerException = 100,
    NotComObject = 101,
    NotResolvedReferenceObject = 102,
    BadRequest = ExceptionStatus.BAD_REQUEST,
    InternalServerError = ExceptionStatus.INTERNAL_SERVER_ERROR,
    NotImplemented = ExceptionStatus.NOT_IMPLEMENTED,
    GATEWAY_TIMEOUT = ExceptionStatus.GATEWAY_TIMEOUT,
}

export enum ComErrorScope {
    ComObject = 'ComObject',
    ComReference = 'ComReference',
    ComBridge = 'ComBridge',
}

export interface ComErrorData {
    [key: string]: any;
}

export class ComError<TInnerException = Record<string, any>> extends Error {
    public name = 'ComError';
    public readonly status: ComErrorCode | ExceptionStatus | string;
    public readonly scope: ComErrorScope;
    public readonly message: string;
    public readonly error?: keyof ComErrorCode | string;
    public readonly data?: ComErrorData | string;
    public readonly errors?: (Record<string, any> | string)[];
    public readonly innerException?: TInnerException;

    constructor({ message, error, ...params }: Omit<ComError, 'name'>) {
        super(message);

        this.error = error || ComErrorCode[params.status];
        Object.assign(this, params);
    }
}
