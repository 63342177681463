import { IAlertDialog } from '../components/common/alertDialog';
import { UUIReduxAction } from './types';

export const alertDialogsReducer = (state: IAlertDialog[], action: UUIReduxAction): IAlertDialog[] => {
    switch (action.type) {
        case 'OpenAlertDialog':
            return state.concat([action.alertDialog]);
        case 'RemoveAlertDialog':
            return state.filter((alertDialog) => alertDialog.id !== action.id);
    }
    return state;
};
