import { ITransformer } from './types';
import { ICHRenameFolder } from '../interfaces/operations';
import { IRenameFolder } from '@wk/elm-uui-doc-component';
import 'reflect-metadata';
import { injectable } from 'inversify';

@injectable()
export class CtxRenameFolderTransformer implements ITransformer {
    data!: ICHRenameFolder;

    transform(): IRenameFolder {
        const nodeObj = {} as IRenameFolder;
        nodeObj.entityContext = {
            name: this.data.associatedEntityName || '',
            id: this.data.associatedEntityId,
            type: this.data.associatedEntityType,
        };

        const extendedProps = <IRenameFolder['extendedProps']>{};
        // T360
        // T360
        if (this.data?.editAccessType) {
            nodeObj['editAccessType'] = this.data.editAccessType;
        }
        // T360
        if (this.data?.viewAccessType) {
            nodeObj['viewAccessType'] = this.data.viewAccessType;
        }
        if (this.data.props?.admittedMembers) {
            extendedProps['admittedMembers'] = this.data.props.admittedMembers;
        }
        nodeObj.docId = this.data.documentId;
        nodeObj.extendedProps = extendedProps;
        nodeObj.name = this.data.name;
        return nodeObj;
    }
}
