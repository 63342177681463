import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const WkOpenCheckCircleIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="wkOpenCheckCircleIcon" viewBox="0 0 24 24" {...props}>
        <path d="M8,16a8,8,0,1,1,8-8A8.009,8.009,0,0,1,8,16ZM8,1.067A6.86,6.86,0,0,0,1.067,8,6.859,6.859,0,0,0,8,14.933,6.859,6.859,0,0,0,14.933,8,6.859,6.859,0,0,0,8,1.067ZM6.4,11.733h0L3.2,8.2l.733-.733L6.4,10.2l5.666-5.933L12.8,5,6.4,11.733Z" />
    </SvgIcon>
);

export default WkOpenCheckCircleIcon;
