import { IInputValidator } from '../../interfaces/inputValidators/type';
import { injectable } from 'inversify';
// import { ValidationUtils } from '../../libraries/validationUtils';
import { IValidationInfo } from '../../dmInfoHandler/types';
// import { isNodeEnvironment } from '../../utils';
import { ICheckIn } from '../../docManager';
import { ICheckIn_JR } from '../types';
import { DCValidationError } from '../../customError/docComponentError';

@injectable()
export class JRAdapterCheckInInputValidator implements IInputValidator {
    validationInfo: IValidationInfo;
    performValidation = (nodeObj: ICheckIn): boolean => {
        const nodeObj1 = nodeObj as ICheckIn_JR;
        // const validator = new ValidationUtils();
        // const fileName = nodeObj1?.name;
        // const file = nodeObj1?.file;

        // const extendedProps = nodeObj1?.extendedProps;

        // let fileSize;
        if (!nodeObj1?.file) {
            //   if (isNodeEnvironment() && file instanceof Buffer) {
            //     fileSize = file.length;
            //   } else {
            //     fileSize = (file as Blob).size;
            //   }
            // } else {
            throw new DCValidationError('JR Passport: Check in Input validation. File not found in input');
        }

        if (
            nodeObj?.name
            // validator.checkDocumentFileSize(fileSize, validator.getFileSize(this.validationInfo, extendedProps)) &&
            // validator.checkFileType(fileName, this.validationInfo)
            // validator.checkSpecialCharacters(fileName, this.validationInfo)
        ) {
            return true;
        } else {
            throw new DCValidationError('JR Passport: Check in Input validation. Params: Name: ' + nodeObj.name);
        }
    };
}
