import { Collapse, Divider, ListItemText, MenuItem } from '@material-ui/core';
import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconExpandMore from '@material-ui/icons/ExpandMore';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useReduxDispatch } from '../../hooks/useReduxDispatch';
import { IMenuItem, AppStore } from '../../reducers/types';
import { getItemScreenPropsFromUrl } from '../itemScreen/itemScreenHelpers';
import css from './multiLevelMenu.module.scss';

interface IMultiLevelMenuProps {
    section: IMenuItem[];
    open: boolean;
    displayValue: string;
    megaMenu?: boolean;
    dataTestId?: string;
    menuId: number;
    handleMenuSelection: (menuId: number) => void;
    toggleDrawer: () => void;
    className?: string;
}

const MultiLevelMenu: React.FC<IMultiLevelMenuProps> = ({
    section,
    open,
    displayValue,
    megaMenu,
    dataTestId,
    menuId,
    handleMenuSelection,
    toggleDrawer,

    className,
}) => {
    return (
        <>
            <Divider className={css.divider} />
            <MenuItem
                button
                onClick={() => handleMenuSelection(menuId)}
                tabIndex={0}
                data-testid={dataTestId}
                className={className}>
                <ListItemText classes={{ primary: css.menuTitle }} data-testid={`${displayValue}-menu-item`}>
                    {displayValue}
                </ListItemText>
                {open ? <IconExpandLess className={css.caretIcon} /> : <IconExpandMore className={css.caretIcon} />}
            </MenuItem>

            <Collapse in={open} timeout="auto" unmountOnExit>
                {section.map((sideMenu: IMenuItem, i: number) => (
                    <MenuItemLink
                        key={i}
                        to={
                            megaMenu && sideMenu.url !== ''
                                ? sideMenu.url
                                : `/enduser/${sideMenu.screenType}screens/show.do?id=${sideMenu.screenId}`
                        }
                        primary={sideMenu.displayValue}
                        title={displayValue}
                        handleClose={toggleDrawer}
                        isPopup={sideMenu.isPopup}
                        screenId={sideMenu.screenId}
                        screenType={sideMenu.screenType}
                        handleMenuSelection={() => handleMenuSelection(menuId)}
                    />
                ))}
            </Collapse>
        </>
    );
};

interface IMenuItemLinkProps {
    primary: string;
    to: string;
    title: string;
    isPopup: boolean;
    screenId?: number | null | undefined;
    screenType?: string | null | undefined;
    handleClose: () => void;
    handleMenuSelection: () => void;
}

const MenuItemLink: React.FC<IMenuItemLinkProps> = ({
    primary,
    to,
    title,
    isPopup,
    screenId,
    screenType,
    handleClose,
    handleMenuSelection,
}) => {
    const reduxDispatch = useReduxDispatch();
    const activeScreenId = useSelector((state: AppStore) => state.ui.globalState.activeScreenId).toString();
    const isActiveMenuItem = screenId?.toString() === activeScreenId;

    if (isPopup) {
        return (
            <MenuItem
                button
                className={`${css.menuItem} ${isActiveMenuItem ? css.selectedMenuItem : ''}`}
                tabIndex={0}
                onClick={() => {
                    reduxDispatch({
                        type: 'OpenItemScreenDialog',
                        itemScreenProps: { ...getItemScreenPropsFromUrl(to), popupTitle: primary },
                    });
                    handleMenuSelection();
                    handleClose();
                }}
                data-testid={isActiveMenuItem ? `${title}-selected-submenu-item` : `${title}-submenu-item`}>
                <ListItemText
                    primary={primary}
                    classes={{
                        primary: isActiveMenuItem ? css.selectedSubMenuItem : css.subMenuItem,
                    }}
                />
            </MenuItem>
        );
    } else {
        return (
            <MenuItem
                button
                component={Link}
                to={to}
                className={`${css.menuItem} ${isActiveMenuItem ? css.selectedMenuItem : ''}`}
                tabIndex={0}
                onClick={() => {
                    screenType !== 'list' ? reduxDispatch({ type: 'SetActiveScreenId', activeScreenId: 0 }) : null;
                    handleMenuSelection();
                    handleClose();
                }}
                data-testid={isActiveMenuItem ? `${title}-selected-submenu-item` : `${title}-submenu-item`}>
                <ListItemText
                    primary={primary}
                    classes={{
                        primary: isActiveMenuItem ? css.selectedSubMenuItem : css.subMenuItem,
                    }}
                />
            </MenuItem>
        );
    }
};

export default MultiLevelMenu;
