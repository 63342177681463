import { ITransformer } from './types';
import { ICHEditFolder } from '../interfaces/operations';
import { IEditFolder } from '@wk/elm-uui-doc-component/dist/types';
import 'reflect-metadata';
import { injectable } from 'inversify';
import { factory } from '../configLog4J';
import { IFieldValue } from '../services/interface/itemScreenMeta.interface';
const log = factory.getLogger('CtxEditFolderTransformer');

@injectable()
export class CtxEditFolderTransformer implements ITransformer {
    data!: ICHEditFolder;

    transform(): IEditFolder {
        const nodeObj = {} as IEditFolder;

        nodeObj.entityContext = {
            name: this.data?.associatedEntityName || '',
            id: this.data?.associatedEntityId,
            type: this.data?.associatedEntityType,
        };
        const extendedProps = <IEditFolder['extendedProps']>{};

        const {
            document_folder_name,
            folder_edit_access_type_name,
            folder_view_access_type_name,
            viewMembers,
            editMembers,
        }: Record<string, IFieldValue> = this.data?.payload ? this.data?.payload : {};

        log.debug('printing data: ' + JSON.stringify(this.data));

        if (document_folder_name?.value !== undefined || document_folder_name?.value) {
            nodeObj.name = (this.data?.payload?.document_folder_name?.value || this.data?.payload?.name?.value) ?? '';
        }
        nodeObj.documentFolderId = this.data?.folderId ?? '';

        if (folder_edit_access_type_name?.value !== undefined || folder_edit_access_type_name?.value) {
            nodeObj.editAccessType = this.data?.payload?.folder_edit_access_type_name?.value;
        }
        if (folder_view_access_type_name?.value !== undefined || folder_view_access_type_name?.value) {
            nodeObj.viewAccessType = this.data?.payload?.folder_view_access_type_name?.value;
        }
        nodeObj.viewMembers = (this.data?.payload?.viewMembers?.value || undefined)?.split(',').map(Number) || [];
        nodeObj.editMembers = (this.data?.payload?.editMembers?.value || undefined)?.split(',').map(Number) || [];
        extendedProps['admittedMembers'] = this.data?.props?.admittedMembers;

        log.debug('printing nodeObj: ' + JSON.stringify(nodeObj));

        return nodeObj;
    }
}
