import {
    IDocServiceRequest,
    IAddDocument,
    ICheckOut,
    ICheckIn,
    ICancelCheckOut,
    IRenameObject,
    IAddFolder,
    IDeleteDocument,
    IAddEmail,
    IDeleteEmail,
    IDownloadDocument,
    IDocumentMetadata,
    IDownloadEmailDocument,
    IRenameFolder,
    IRenameDocument,
    IDocumentMetadataFileName,
    IDocumentMetadataEmail,
    IUploadType,
    IPollFiles,
    IUserProfile,
    IEditFolder,
    IEditDocument,
    DCResponse,
    UnExpectedError,
    DocumentNameSplit,
} from './types';
import { ERROR_MESSAGE_TO_HANDLE_DATA_INTERNALLY } from './constants';
import { DocSupportedBaseEntityTypesEnum } from './enums/supportedEntityTypesEnum';
import { apiFetchPost } from './api/fetchUtils';
import { myContainer } from './inversify.config';
import { IDMInfoHandler } from './dmInfoHandler/types';
import { IDocAdaptor } from './interfaces/adapter/types';
import { getUniqueIdForDoc, getTenantId } from './utils/main.utils';
import { factory } from './ConfigLog4j';
import { CapabiltyEnum } from './enums/capability';
import { getAccessToken } from './t360Adapter/utils/utils';
import { getUserProfileObj, getBaseUrl } from './utils/main.utils';
import { isT360 } from './utils/shared.utils';

export {
    DCResponse,
    IDocServiceRequest,
    IAddDocument,
    ICheckOut,
    ICheckIn,
    ICancelCheckOut,
    IRenameObject,
    IAddFolder,
    IDeleteDocument,
    IAddEmail,
    IDeleteEmail,
    IDownloadDocument,
    IDocumentMetadata,
    IDocumentMetadataFileName,
    IDocumentMetadataEmail,
    IDownloadEmailDocument,
    IRenameFolder,
    IRenameDocument,
    apiFetchPost,
    DocSupportedBaseEntityTypesEnum,
    IEditDocument,
    IEditFolder,
    ERROR_MESSAGE_TO_HANDLE_DATA_INTERNALLY,
    UnExpectedError,
    DocumentNameSplit,
    getTenantId,
};

const log = factory.getLogger('DocManager');
const usageLog = factory.getLogger('UsageLogging');

export const checkOut = (nodeObj: ICheckOut): Promise<DCResponse> => {
    const docAdaptor: Promise<IDocAdaptor> = myContainer
        .get<IDMInfoHandler>('dmInfoHandler')
        .getAdaptor(CapabiltyEnum.CHECKOUT);
    usageLog.info('Invoked checkOut');
    log.debug('Calling Checkout with nodeObj');
    return docAdaptor.then((doc) => doc.checkOut(nodeObj));
};

export const addDocument = (nodeObj: IAddDocument): Promise<DCResponse> => {
    const docAdaptor: Promise<IDocAdaptor> = myContainer
        .get<IDMInfoHandler>('dmInfoHandler')
        .getAdaptor(CapabiltyEnum.ADD_DOCUMENT);
    usageLog.info('Invoked addDocument');
    log.debug('Calling AddDocument with nodeObj');
    return docAdaptor.then((doc) => doc.addDocument(nodeObj));
};

export const validateDocument = (nodeObj: IAddDocument): Promise<DCResponse> => {
    const docAdaptor: Promise<IDocAdaptor> = myContainer
        .get<IDMInfoHandler>('dmInfoHandler')
        .getAdaptor(CapabiltyEnum.VALIDATE_DOCUMENT);
    usageLog.info('Invoked validateDocument');
    log.debug('Calling validateDocument with nodeObj');
    return docAdaptor.then((doc) => doc.validateDocument(nodeObj));
};

export const downloadDocument = (nodeObj: IDownloadDocument): Promise<DCResponse> => {
    const docAdaptor: Promise<IDocAdaptor> = myContainer
        .get<IDMInfoHandler>('dmInfoHandler')
        .getAdaptor(CapabiltyEnum.DOWNLOAD_DOCUMENT);
    usageLog.info('Invoked downloadDocument');
    log.debug('Calling DownloadDocument with nodeObj');
    return docAdaptor.then((doc) => doc.downloadDocument(nodeObj));
};

export const downloadEmailDocument = async (nodeObj: IDownloadEmailDocument): Promise<DCResponse> => {
    const docAdaptor: Promise<IDocAdaptor> = myContainer
        .get<IDMInfoHandler>('dmInfoHandler')
        .getAdaptor(CapabiltyEnum.DOWNLOAD_EMAIL);
    usageLog.info('Invoked downloadEmailDocument');
    log.debug('Calling downloadEmailDocument with nodeObj');
    return docAdaptor.then((doc) => doc.downloadDocument(nodeObj));
};

export const downloadDocumentVersion = (nodeObj: IDownloadDocument): Promise<DCResponse> => {
    const docAdaptor: Promise<IDocAdaptor> = myContainer
        .get<IDMInfoHandler>('dmInfoHandler')
        .getAdaptor(CapabiltyEnum.DOWNLOAD_VERSION_DOCUMENT);
    usageLog.info('Invoked downloadDocumentVersion');
    log.debug('Calling downloadDocumentVersion with nodeObj');
    return docAdaptor.then((doc) => doc.downloadDocumentVersion(nodeObj));
};

export const deleteDocument = (nodeObj: IDeleteDocument): Promise<DCResponse> => {
    const docAdaptor: Promise<IDocAdaptor> = myContainer
        .get<IDMInfoHandler>('dmInfoHandler')
        .getAdaptor(CapabiltyEnum.DELETE_DOCUMENT);
    usageLog.info('Invoked deleteDocument');
    log.debug('Calling deleteDocument with nodeObj');
    return docAdaptor.then((doc) => doc.deleteDocument(nodeObj));
};

export const deleteEmail = (nodeObj: IDeleteDocument): Promise<DCResponse> => {
    const docAdaptor: Promise<IDocAdaptor> = myContainer
        .get<IDMInfoHandler>('dmInfoHandler')
        .getAdaptor(CapabiltyEnum.DELETE_EMAIL);
    usageLog.info('Invoked deleteEmail');
    log.debug('Calling deleteEmail with nodeObj');
    return docAdaptor.then((doc) => doc.deleteDocument(nodeObj));
};

export const deleteFolder = (nodeObj: IDeleteDocument): Promise<DCResponse> => {
    const docAdaptor: Promise<IDocAdaptor> = myContainer
        .get<IDMInfoHandler>('dmInfoHandler')
        .getAdaptor(CapabiltyEnum.DELETE_FOLDER);
    usageLog.info('Invoked deleteFolder');
    log.debug('Calling deleteFolder with nodeObj');
    return docAdaptor.then((doc) => doc.deleteFolder(nodeObj));
};

export const checkInDocument = async (nodeObj: ICheckIn): Promise<DCResponse> => {
    const docAdaptor: Promise<IDocAdaptor> = myContainer
        .get<IDMInfoHandler>('dmInfoHandler')
        .getAdaptor(CapabiltyEnum.CHECKIN);
    usageLog.info('Invoked checkInDocument');
    log.debug('Calling checkInDocument with nodeObj');
    return docAdaptor.then((doc) => doc.checkIn(nodeObj));
};

export const validateCheckInDocument = async (nodeObj: ICheckIn): Promise<DCResponse> => {
    const docAdaptor: Promise<IDocAdaptor> = myContainer
        .get<IDMInfoHandler>('dmInfoHandler')
        .getAdaptor(CapabiltyEnum.VALIDATE_CHECKIN_DOCUMENT);
    usageLog.info('Invoked validateCheckInDocument');
    log.debug('Calling validateCheckInDocument with nodeObj');
    return docAdaptor.then((doc) => doc.validateCheckInDocument(nodeObj));
};

export const listDocument = (nodeObj: ICheckIn): Promise<DCResponse> => {
    const docAdaptor: Promise<IDocAdaptor> = myContainer
        .get<IDMInfoHandler>('dmInfoHandler')
        .getAdaptor(CapabiltyEnum.LIST);
    usageLog.info('Invoked listDocument');
    log.debug('Calling listDocument with nodeObj');
    return docAdaptor.then((doc) => doc.list(nodeObj));
};

export const addFolder = async (nodeObj: IAddFolder): Promise<DCResponse> => {
    const docAdaptor: Promise<IDocAdaptor> = myContainer
        .get<IDMInfoHandler>('dmInfoHandler')
        .getAdaptor(CapabiltyEnum.ADD_FOLDER);
    usageLog.info('Invoked addFolder');
    log.debug('Calling addFolder with nodeObj');
    return docAdaptor.then((doc) => doc.addFolder(nodeObj));
};

export const folderMetadata = async (nodeObj: IDocumentMetadataFileName): Promise<DCResponse> => {
    const dmInfoHandler = myContainer.get<IDMInfoHandler>('dmInfoHandler');

    const docAdapter = dmInfoHandler.getAdaptor(CapabiltyEnum.FOLDER_METADATA);
    log.debug('Calling documentMetadata with nodeObj');
    return docAdapter.then((doc) => doc.folderMetadata(nodeObj));
};

// if document
export const documentMetadata = async (nodeObj: IDocumentMetadataFileName): Promise<DCResponse> => {
    let docAdapter: Promise<IDocAdaptor>;
    const dmInfoHandler = myContainer.get<IDMInfoHandler>('dmInfoHandler');
    // .getAdaptor(CapabiltyEnum.DOCUMENT_METADATA);
    if (nodeObj?.documentName || nodeObj?.fileName) {
        docAdapter = dmInfoHandler.getAdaptor(CapabiltyEnum.DOCUMENT_METADATA_FILENAME);
        log.debug('Calling documentMetadataFileName with nodeObj');

        return docAdapter.then((doc) => doc.documentMetadataFileName(nodeObj));
    }
    docAdapter = dmInfoHandler.getAdaptor(CapabiltyEnum.DOCUMENT_METADATA);
    log.debug('Calling documentMetadata with nodeObj');
    return docAdapter.then((doc) => doc.documentMetadata(nodeObj));
};

export const getUniqueID = async (nodeObj: IUploadType): Promise<string> => {
    const dmInfoHandler = myContainer.get<IDMInfoHandler>('dmInfoHandler');
    return getUniqueIdForDoc(nodeObj, await dmInfoHandler.getDMInfo());
};

export const getSecretKey = async (): Promise<string> => {
    const dmInfoHandler = myContainer.get<IDMInfoHandler>('dmInfoHandler');
    const dmInfo = await dmInfoHandler.getDMInfo();
    return dmInfo.validationInfo.secretKey;
};

export const getUserProfile = async (): Promise<IUserProfile> => {
    return getUserProfileObj();
};

export const pollFiles = async (pollfiles: IPollFiles): Promise<DCResponse> => {
    const docAdaptor: Promise<IDocAdaptor> = myContainer
        .get<IDMInfoHandler>('dmInfoHandler')
        .getAdaptor(CapabiltyEnum.GET_NOTIFICATIONS_FILES);
    log.debug('Calling poll files with guids');
    return docAdaptor.then((doc) => doc.pollFiles(pollfiles));
};

// if document
export const documentMetadataEmail = async (nodeObj: IDocumentMetadataEmail): Promise<DCResponse> => {
    // let docAdapter: Promise<IDocAdaptor>;
    const dmInfoHandler = myContainer.get<IDMInfoHandler>('dmInfoHandler');

    const docAdapter = dmInfoHandler.getAdaptor(CapabiltyEnum.DOCUMENT_METADATA_EMAIL);
    log.debug('Calling documentMetadataEmail with nodeObj');
    return docAdapter.then((doc) => doc.documentMetadataEmail(nodeObj));
};

export const checkInAll = (nodeObj: ICancelCheckOut): Promise<DCResponse> => {
    usageLog.info('Invoked checkInAll');
    return cancelCheckOut(nodeObj);
};

export const cancelCheckOut = (nodeObj: ICancelCheckOut): Promise<DCResponse> => {
    const docAdaptor: Promise<IDocAdaptor> = myContainer
        .get<IDMInfoHandler>('dmInfoHandler')
        .getAdaptor(CapabiltyEnum.CANCEL_CHECKOUT);
    usageLog.info('Invoked cancelCheckOut');
    log.debug('Calling cancelCheckOut with nodeObj');
    return docAdaptor.then((doc) => doc.cancelCheckOut(nodeObj));
};

export const renameDocument = (nodeObj: IRenameDocument): Promise<DCResponse> => {
    const docAdaptor: Promise<IDocAdaptor> = myContainer
        .get<IDMInfoHandler>('dmInfoHandler')
        .getAdaptor(CapabiltyEnum.RENAME_DOCUMENT);
    usageLog.info('Invoked renameDocument');
    log.debug('Calling renameDocument with nodeObj');
    return docAdaptor.then((doc) => doc.renameDocument(nodeObj));
};

export const renameFolder = (nodeObj: IRenameFolder): Promise<DCResponse> => {
    const docAdaptor: Promise<IDocAdaptor> = myContainer
        .get<IDMInfoHandler>('dmInfoHandler')
        .getAdaptor(CapabiltyEnum.RENAME_FOLDER);
    usageLog.info('Invoked renameFolder');
    log.debug('Calling renameFolder with nodeObj');
    return docAdaptor.then((doc) => doc.renameFolder(nodeObj));
};

export const editFolder = (nodeObj: IEditFolder): Promise<DCResponse> => {
    const docAdaptor: Promise<IDocAdaptor> = myContainer
        .get<IDMInfoHandler>('dmInfoHandler')
        .getAdaptor(CapabiltyEnum.EDIT_FOLDER);
    usageLog.info('Invoked editFolder');
    log.debug('Calling editFolder with nodeObj');
    return docAdaptor.then((doc) => doc.renameFolder(nodeObj));
};

export const editDocument = (nodeObj: IEditDocument): Promise<DCResponse> => {
    const docAdaptor: Promise<IDocAdaptor> = myContainer
        .get<IDMInfoHandler>('dmInfoHandler')
        .getAdaptor(CapabiltyEnum.EDIT_DOCUMENT);
    usageLog.info('Invoked editDocument');
    log.debug('Calling editDocument with nodeObj');
    return docAdaptor.then((doc) => doc.renameFolder(nodeObj));
};

export const addEmail = async (nodeObj: IAddEmail): Promise<DCResponse> => {
    const docAdaptor: Promise<IDocAdaptor> = myContainer
        .get<IDMInfoHandler>('dmInfoHandler')
        .getAdaptor(CapabiltyEnum.ADD_EMAIL);
    usageLog.info('Invoked addEmail');
    log.info('Calling addEmail with nodeObj');
    return docAdaptor.then((doc) => doc.addEmail(nodeObj));
};

export const deleteEmailFolder = (nodeObj: IDeleteEmail): Promise<DCResponse> => {
    const docAdaptor: Promise<IDocAdaptor> = myContainer
        .get<IDMInfoHandler>('dmInfoHandler')
        .getAdaptor(CapabiltyEnum.DELETE_FOLDER);
    usageLog.info('Invoked deleteEmailFolder');
    log.debug('Calling deleteEmailFolder with nodeObj');
    return docAdaptor.then((doc) => doc.deleteEmailFolder(nodeObj));
};

export const getSupportedEntityTypes = async (): Promise<DCResponse> => {
    // let docAdapter: Promise<IDocAdaptor>;
    const dmInfoHandler = myContainer.get<IDMInfoHandler>('dmInfoHandler');
    const docAdapter = dmInfoHandler.getAdaptor(CapabiltyEnum.GET_SUPPORTED_ENTITY_TYPES);
    log.debug('Calling getSupportedEntityTypes');
    return docAdapter.then((doc) => doc.getSupportedEntityTypes());
};

export const getAuthToken = async (): Promise<string> => {
    if (isT360()) {
        return getAccessToken();
    } else {
        return '';
    }
};

export const getDocumentName = (documentName: string, existingDocumentName: string): string => {
    if (isT360()) {
        return existingDocumentName;
    } else {
        return documentName;
    }
};

export const buildHelpFileUrl = async (
    apiPath: string,
    helpFileUrl: string,
): Promise<{ helpDocumentUrl: string; accesstoken?: string }> => {
    let helpDocumentUrl;
    if (isT360()) {
        const accesstoken = await getAccessToken();
        helpDocumentUrl = helpFileUrl;
        return { helpDocumentUrl, accesstoken };
    } else {
        helpDocumentUrl = getBaseUrl() + apiPath + helpFileUrl;
        return { helpDocumentUrl };
    }
};

export const splitNameAndExtension = (fileName: string): DocumentNameSplit => {
    if (isT360()) {
        const nameAndExtension = fileName.split(/(\.[\w-_]+)$/g);
        return {
            name: nameAndExtension[0] || '',
            extension: nameAndExtension[1] || '',
        };
    } else {
        return { name: fileName, extension: '' };
    }
};

export const DocComponent_CapabiltyEnums = CapabiltyEnum;
