import { getUserProfile } from '@wk/elm-uui-doc-component';
import { factory } from '../configLog4J';

const log = factory.getLogger('database.utils');

export const getDatabaseName = async (): Promise<string> => {
    const userProfileObj = await getUserProfile();
    const databaseName =
        userProfileObj.baseUrl?.replace('https://', '').replace('http://', '') +
        '/' +
        userProfileObj.userId +
        '_' +
        userProfileObj.tenantInfo.id;
    log.debug('DatabaseName created as' + databaseName);
    return databaseName;
};
