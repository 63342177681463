import { IInputValidator } from '../../interfaces/inputValidators/type';
import { injectable } from 'inversify';
import { IAddFolder } from '../../types';
import { ValidationUtils } from '../../libraries/validationUtils';
import { IValidationInfo } from '../../dmInfoHandler/types';
import { IAddFolder_JR } from '../types';
import { DCValidationError } from '../../customError/docComponentError';

@injectable()
export class JRAdapterAddFolderInputValidator implements IInputValidator {
    validationInfo: IValidationInfo;
    performValidation = (nodeObj: IAddFolder): boolean => {
        const nodeObj1 = nodeObj as IAddFolder_JR;
        const validator = new ValidationUtils();

        if (nodeObj1.name && validator.checkSpecialCharacters(nodeObj1.name, this.validationInfo)) {
            return true;
        } else {
            throw new DCValidationError(
                'JR Passport. Add Folder Input Validation. Params: Name: ' +
                    nodeObj1.name +
                    ' Folder Path: ' +
                    nodeObj1?.documentFolderId,
            );
        }
    };
}
