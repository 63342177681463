import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Typography,
} from '@material-ui/core';
import React from 'react';
import css from './alertDialog.module.scss';
import CloseIcon from '../icons/wkCloseIcon';
import UUIButton from './uuiButton';
import { useReduxDispatch } from '../../hooks/useReduxDispatch';
import { isPromise } from '../../utils/utilities';
import { trackPromise } from 'react-promise-tracker';

export interface IAlertDialog {
    id: string;
    title: string;
    type?: 'normal' | 'error';
    message: string;
    buttons: IAlertDialogButton[];
    onClose?(): void;
}

export interface IAlertDialogButton {
    label: string;
    onClick(): void | Promise<void>;
}

export const AlertDialog: React.FC<IAlertDialog> = ({ id, type = 'normal', title, message, buttons, onClose }) => {
    const [open, setOpen] = React.useState(true);
    const reduxDispatch = useReduxDispatch();

    return (
        <Dialog
            open={open}
            onClose={() => {
                if (onClose) {
                    onClose();
                }
                setOpen(false);
            }}
            BackdropProps={{ classes: { root: type === 'error' ? css.opaqueBackdrop : '' } }}
            onExited={() => reduxDispatch({ type: 'RemoveAlertDialog', id })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick={true}
            classes={{
                paper: css.dialogPaperRoot,
            }}>
            <DialogTitle
                disableTypography
                data-testid="alert-dialog-title"
                className={type === 'error' ? css.dialogTitleErrorRoot : css.dialogTitleRoot}>
                <div className={css.titleTextContainer}>
                    <Typography variant="h6" noWrap id="modal-title" className={css.titleText}>
                        {title}
                    </Typography>
                </div>
                <div className={css.closeIconContainer}>
                    <IconButton
                        size="small"
                        className={css.closeIcon}
                        onClick={() => {
                            if (onClose) {
                                onClose();
                            }
                            setOpen(false);
                        }}
                        data-testid="dialog-close-button"
                        aria-label="Close Dialog">
                        <CloseIcon className={css.closeIconRoot} />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent classes={{ root: css.contentRoot }}>
                <DialogContentText data-testid="alert-dialog-message">{message}</DialogContentText>
            </DialogContent>
            <DialogActions className={css.dialogActions}>
                {buttons.map((button, i) => (
                    <UUIButton
                        key={i}
                        variant={i === 0 ? 'contained' : 'outlined'}
                        onClick={() => {
                            const possiblePromise = button.onClick();
                            if (isPromise(possiblePromise)) {
                                trackPromise(possiblePromise).then(() => {
                                    setOpen(false);
                                });
                            } else {
                                setOpen(false);
                            }
                        }}
                        color="primary"
                        classes={{
                            root: `${
                                buttons.length === 1
                                    ? css.outLinedButton
                                    : i === buttons.length - 1
                                    ? css.outLinedButtonWhite
                                    : i == 0
                                    ? css.containedButtons
                                    : i == 1 && css.outLinedButton0
                            }`,
                            label: css.buttonLabel,
                        }}
                        data-testid={button.label}>
                        {button.label}
                    </UUIButton>
                ))}
            </DialogActions>
        </Dialog>
    );
};
