import { objectHasProperty } from '../../utils/utilities';
import { FieldOrSection, ISectionEntry } from './tabs/types';

// get an array of rows based on the json
// this returns a nested array, each element is an array of fields for that row
export const getFieldRows = (fieldsAndSections: FieldOrSection[]): FieldOrSection[][] => {
    const fieldRows = [] as FieldOrSection[][];
    let fieldRow = [] as FieldOrSection[];
    const nonDeprecatedFieldsAndSections = fieldsAndSections.filter((f) => isSection(f) || !f.deprecated);
    nonDeprecatedFieldsAndSections.map((fieldOrSection, i) => {
        if (
            i == 0 ||
            isSection(fieldOrSection) ||
            fieldOrSection.isNewLine ||
            fieldOrSection.controlType === 'Multi Line Text'
        ) {
            if (fieldRow.length > 0) {
                fieldRows.push(fieldRow);
            }
            fieldRow = [];
        }
        fieldRow.push(fieldOrSection);
        if (
            isSection(fieldOrSection) ||
            i == nonDeprecatedFieldsAndSections.length - 1 ||
            fieldOrSection.controlType === 'Multi Line Text'
        ) {
            fieldRows.push(fieldRow);
            fieldRow = [];
        }
    });
    return fieldRows;
};

export const isSection = (
    fieldOrSection: FieldOrSection,
): fieldOrSection is ISectionEntry & Record<'sectionTitle', unknown> =>
    objectHasProperty(fieldOrSection, 'sectionTitle');

export const isTabStyleAccounting = (fieldOrSection: FieldOrSection): boolean =>
    Object.values(fieldOrSection).includes('Accounting');
