import { LocalOcRenderer } from './localOcRenderer';
import { OcRenderer } from './ocRenderer';
import { BrowserUtilitiesService } from '../browser/browserUtilities.service';
import { OcExposeInfo } from './ocInfo';

declare global {
    interface Window {
        api: OcRenderer;
        // need ts-ignore here to avoid library usage type check error in dev mode
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ocInfo: OcExposeInfo;
    }
}

interface OcClient {
    ocRenderer: OcRenderer;
}

function createRealIpcClient(): OcClient {
    return {
        ocRenderer: window.api,
    };
}

function createStubIpcClient(): OcClient {
    return {
        ocRenderer: new LocalOcRenderer(),
    };
}

export const browserUtilitiesService = new BrowserUtilitiesService();

const ocClient: OcClient = browserUtilitiesService.isElectron() ? createRealIpcClient() : createStubIpcClient();

export const { ocRenderer } = ocClient;
