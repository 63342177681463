import { injectable, inject } from 'inversify';
import { apiRestFetchGet } from '../../restApi/fetch.utils';
import { JRAdapterActionPerformer } from './actionPerformer';
import * as convert from 'xml-js';
import { convertToMapMetaDataFolder } from '../../utils/main.utils';
import { matchMetadataFolder } from '../utils/maps';
import { IDocOperationInvocation } from '../../interfaces/urlPattern/types';
import { DCResponse } from '../../types';

import { JRAdapterDocumentMetadataActionableEntity } from '../actionableEntity/documentMetadataActionableEntity';

@injectable()
export class JRAdapterFolderMetadataActionPerformer extends JRAdapterActionPerformer {
    fetchRequest = apiRestFetchGet;

    @inject('JRAdapterDownloadDocumentUrlPattern') public downloadUrl: IDocOperationInvocation;
    @inject('JRAdapterDownloadVersionDocumentUrlPattern') public downloadUrlVersion: IDocOperationInvocation;

    async performAction(
        obj: JRAdapterDocumentMetadataActionableEntity,
        urlObj: Promise<string>,
        urlPatternObj: IDocOperationInvocation,
    ): Promise<DCResponse> {
        const response = await super.performAction(obj, urlObj, urlPatternObj);

        const outputInfo = async () => {
            const xmlData = await response.text();

            const dataObj = JSON.parse(convert.xml2json(xmlData));

            if ('elements' in dataObj['elements'][0]) {
                const jsonArray = dataObj['elements'][0]['elements'][0]['elements'];

                const jsonObject: Record<string, string> = {};

                jsonArray.forEach((command: { [x: string]: { [x: string]: string } }) => {
                    jsonObject[command['attributes']['name']] = command['attributes']['value'];
                });

                return convertToMapMetaDataFolder(matchMetadataFolder, jsonObject);
            } else {
                return convertToMapMetaDataFolder(matchMetadataFolder, { id: '0' });
            }
        };

        const response2: DCResponse = Object.assign(new DCResponse(), response);
        response2.outputInfo = outputInfo;
        return response2;
    }
}
