import * as builder from 'xmlbuilder';
import { attributeTypes } from '../types';

const attributeTypes = {
    uniqueId: 'String',
    isFolder: 'Boolean',
    name: 'String',
    path: 'String',
    relatedEntityName: 'String',
    relatedEntityId: 'String',
    pabuId: 'String',
    associatedEntityName: 'String',
    createdBy: 'String',
    updatedBy: 'String',
    comments: 'String',
    documentFile: 'File',
    file: 'File',
    matter: 'SingleAssociation',
    organization: 'SingleAssociation',
    person: 'SingleAssociation',
    invoice: 'SingleAssociation',
    emailDocumentMatter: 'SingleAssociation',
    parentDocument: 'SingleAssociation',
};

const passportDefaultConfiguration = {
    namespace: 'ns1',
    entities: 'http://datacert.com/rest/model',
};

// create Xml object

export const createXmlObject = (finalJson: attributeTypes): string => {
    const entities = passportDefaultConfiguration.namespace + ':' + 'Entities';
    const entity = passportDefaultConfiguration.namespace + ':' + 'Entity';
    const attribute = passportDefaultConfiguration.namespace + ':' + 'Attribute';
    const embeddedAttribute = passportDefaultConfiguration.namespace + ':' + 'EmbeddedAttribute';
    const xmlns = 'xmlns:' + passportDefaultConfiguration.namespace;

    const rootEntities = builder.create(entities);
    rootEntities.att(xmlns, passportDefaultConfiguration.entities);
    const rootEntity = rootEntities.ele(entity);

    // rootEntity.att('name', finalJson.entityName);

    for (const key in finalJson.attributes) {
        const item = rootEntity.ele(attribute);
        item.att('name', key);
        item.att('type', attributeTypes[key]);
        if (typeof finalJson.attributes[key] === 'object' && attributeTypes[key] === 'File') {
            for (const key1 in finalJson.attributes[key]) {
                const embedded = item.ele(embeddedAttribute);
                embedded.att('name', key1);
                embedded.att('type', attributeTypes[key1]);
                embedded.att('value', finalJson.attributes[key][key1]);
            }
        } else if (typeof finalJson.attributes[key] === 'object' && attributeTypes[key] === 'SingleAssociation') {
            for (const key1 in finalJson.attributes[key]) {
                const embedded = item.ele(entity);
                embedded.att(key1, finalJson.attributes[key][key1]);
            }
        } else {
            item.att('value', finalJson.attributes[key]);
        }
    }
    const xml = rootEntities.end();
    return xml;
};
