import { ICHUploadResponse } from './../interfaces/operations';
import { IQueueServiceInterface, IQueueItem, QueueItemStatus, QueueItemProcess } from '@wk/elm-uui-queuemanager';
import { injectable } from 'inversify';
import { IQueueCapability, IQueueSubscribed } from '../interfaces/interface';
import { deleteFromPersistentStorage, isOC, isString, isStringArray } from '../utils/main.utils';
import { factory } from '../configLog4J';
import { arrayRemove } from '../utils/main.utils';
import { pollFiles } from '@wk/elm-uui-doc-component';
import { CapabiltyEnum } from '../enum/enum';
import { getContextEnvInfo } from '../contextHandler';
import { setIntervalAsync } from 'set-interval-async/fixed';
import { v4 as uuid } from 'uuid';
import { IDocumentsService, IOfficeService, CHMessagingService } from '../services';
import { inject } from 'inversify';
import { SaveOptions } from '@wk/office-companion-js';
import { FILES_TO_CLEANUP, virusScanPollingTimer } from '../constants';
import { ICHUploadType } from '../interfaces/operations';
// import { uniqueAlreadyPresent } from '../constants';

const log = factory.getLogger('QueueCapability');

enum statusEnum {
    NEW = 'New',
    SCANNING = 'Scanning',
    FAILED = 'Failed',
    SUCCESS = 'Success',
    UNAPPLICABLE = 'Unapplicable',
}

export interface FilesToCleanUp {
    filepath: string;
    fileId?: string;
    uniqueId?: string;
}

interface IFileNotification {
    fileId: string;
    id: number;
    result: string;
    status: statusEnum;
}

@injectable()
export class QueueCapability implements IQueueCapability {
    ids: string[] = [];
    queueManagerObj!: IQueueServiceInterface;
    queueSubscribedMap = new Map<string, IQueueSubscribed>();
    queueSubscription: () => Promise<void>;
    cleanFileResources: () => Promise<void>;
    queueProcessingCount: () => void;
    groupIds_Success = {};
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    currentProcessingElements = 0;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    timerInterval: () => any;
    profileSwitch: () => void;
    clearOcDataOnQueueComplete: (persistedItems: Set<FilesToCleanUp>) => Promise<void>;
    private _documentsService: IDocumentsService;
    private _officeService: IOfficeService;
    private _chMessageService: CHMessagingService;
    private uniqueID: string;
    constructor(
        @inject('DocumentsService') documentsService: IDocumentsService,
        @inject('OfficeService') officeService: IOfficeService,
        @inject('CHMessagingService') chMessageService: CHMessagingService,
    ) {
        this._documentsService = documentsService;
        this._officeService = officeService;
        this._chMessageService = chMessageService;
        this.uniqueID = '';

        this.cleanFileResources = async () => {
            let cleanupCompleted = true;
            this.queueManagerObj.elementsActiveRemoved.subscribe(async (queueItems) => {
                if (cleanupCompleted) {
                    cleanupCompleted = false;
                    const filesToCleanUp: Set<FilesToCleanUp> = new Set();
                    const promises = queueItems.map(async (queueItem: IQueueItem) => {
                        const isClose = queueItem.data.isClose as boolean;
                        // remove file path
                        let path = '';
                        if (queueItem.data.filePath) {
                            path = isString(queueItem.data.filePath) ? queueItem.data.filePath : '';
                        } else if (queueItem.data.filePaths) {
                            path = isStringArray(queueItem.data.filePaths) ? queueItem.data.filePaths[0] : '';
                        }
                        const { entityTypeId, documentId, associatedEntityTypeId, associatedEntityId, folderId } =
                            queueItem.data;
                        // Close Document
                        if (isClose) {
                            const uniqueId = (queueItem.data.uniqueId as string) || '';
                            const isFileExists = await this._officeService.isFileExists(uniqueId);
                            if (isFileExists) {
                                const activeFile = await this._officeService.getCurrentDocumentFullName();
                                if (activeFile) {
                                    const isSamePath = await this._officeService.compareFilePaths(path, activeFile);
                                    const isCheckedOutPath = await this._officeService.isActiveDocument(uniqueId, true);
                                    if ((isSamePath || isCheckedOutPath) && queueItem.status === QueueItemStatus.COMPLETED) {
                                        await this._officeService.close(SaveOptions.DoNotSaveChanges);
                                    }
                                }
                            }
                        }

                        const fileData: FilesToCleanUp = { filepath: path };
                        if (queueItem.data.fileId) {
                            fileData.fileId = queueItem.data.fileId as string;
                        }
                        if (queueItem.status === QueueItemStatus.COMPLETED) {
                            if (queueItem.data.props && (queueItem.data.props as Record<string, unknown>).uniqueId) {
                                this.uniqueID = (queueItem.data.props as Record<string, unknown>).uniqueId as string;
                            } else if (queueItem.data.uniqueId) {
                                this.uniqueID = queueItem.data.uniqueId as string;
                            }

                            const isFileExists = await this._officeService.isFileExists(this.uniqueID);
                            if (isFileExists) {
                                fileData.uniqueId = this.uniqueID;
                            }
                        }
                        filesToCleanUp.add(fileData);

                        if (this.queueManagerObj.checkIfLeader()) {
                            if (queueItem.status === QueueItemStatus.COMPLETED) {
                                this._chMessageService.notifyRefreshUUIForEntity(
                                    entityTypeId as string,
                                    documentId as string,
                                    associatedEntityTypeId as string,
                                    associatedEntityId as string,
                                    folderId as string,
                                );
                            }

                            await this.queueManagerObj.updateProcessIndicator(
                                queueItem,
                                QueueItemProcess.FILEPATH_REMOVED,
                            );
                        }
                    });
                    await Promise.all(promises);

                    const filesToCleanUpFromQueue = (await this.queueManagerObj.getProps()).find(
                        (property) => property.key === FILES_TO_CLEANUP
                    );
                    const addFilesToCleanUp = filesToCleanUpFromQueue?.value
                        ? new Set([...JSON.parse(filesToCleanUpFromQueue.value), ...filesToCleanUp])
                        : filesToCleanUp;
                    this.queueManagerObj.setProps(FILES_TO_CLEANUP, JSON.stringify([...addFilesToCleanUp]));
                    cleanupCompleted = true;
                }
            });
        };

        this.queueProcessingCount = async () => {
            this.queueManagerObj.elementsStarted.subscribe((val) => {
                log.debug('no of processing elements:' + val.length);
                this.currentProcessingElements = val.length;
            });
        };

        this.queueSubscription = async () => {
            this.queueManagerObj.processObservable.subscribe(async (queueItem): Promise<void> => {
                let elementProcessed = false;
                log.debug('Is leader ' + this.queueManagerObj.checkIfLeader());
                if (this.queueManagerObj.checkIfLeader() && queueItem) {
                    elementProcessed = this.ids.includes(queueItem.uniqueId);
                    log.debug(
                        'queueItem Processing Status' +
                            ' ' +
                            queueItem.uniqueId +
                            ' ' +
                            queueItem.status +
                            ' ' +
                            this.ids +
                            ' ' +
                            elementProcessed,
                    );
                }

                // To make sure it does not pass
                // when it is already processed
                try {
                    if (
                        this.queueManagerObj.checkIfLeader() &&
                        queueItem &&
                        queueItem.status === 'PROCESSING' &&
                        !elementProcessed
                    ) {
                        const queueSubObj = this.queueSubscribedMap.get(
                            queueItem.operation + '_' + getContextEnvInfo(),
                        );

                        if (queueSubObj) {
                            try {
                                const queueData = queueItem.data;

                                const queueDataName: string = queueData.name as string;
                                log.debug('Making  the queueItem Started ' + queueItem.uniqueId);
                                this.ids.push(queueItem.uniqueId);
                                await queueSubObj.updateStarted(this.queueManagerObj, queueItem);
                                this.ids = arrayRemove(this.ids, queueItem.uniqueId);

                                if (queueData?.file) {
                                    log.debug('getting the attachment for the queueItem' + queueItem.uniqueId);
                                    const fileObj = await this.queueManagerObj.getDocument(queueItem, queueDataName);
                                    log.debug('Successfullly received the attachment' + typeof fileObj);
                                    queueData.file = fileObj;
                                }
                                queueData.uploadGuid = queueItem.uniqueId;
                                if (queueItem?.queue_item_unique_key) {
                                    const result = await this.queueManagerObj.getStartedItemsWithUniqueId(
                                        queueItem.uniqueId,
                                        queueItem.queue_item_unique_key
                                    );
                                    if (result) {
                                        log.debug(
                                        'Found a queueItem with ' +
                                            queueItem.queue_item_unique_key +
                                            ' so updating the uniqueId in uploadGuid'
                                        );
                                        queueData.uploadGuid = result.uniqueId;
                                    } else {
                                        queueData.uploadGuid = queueItem.uniqueId;
                                    }
                                }

                                const response1 = await queueSubObj.handleSubscribedFunc(queueData);
                                log.debug(
                                    ' QUEUE RESPONSE status >>>>>> ' +
                                        response1.response?.ok +
                                        ' status code >>> ' +
                                        response1.response?.status,
                                );
                                log.debug(' QUEUE RESPONSE >>>>>> ' + JSON.stringify(response1));
                                if (response1.response?.ok === false) {
                                    log.error(
                                        'StatusTextError thrown' + JSON.stringify(response1.response.statusInfo.errors),
                                    );
                                    throw Error(Object.values(response1.response.statusInfo.errors).join(' | '));
                                } else if (response1.responseText) {
                                    throw Error(response1.responseText);
                                }

                                if (response1.response) {
                                    if (response1.response.statusText === 'Pending') {
                                        await queueSubObj.handlePendingResponse(this.queueManagerObj, queueItem);
                                    } else {
                                        await queueSubObj.handleResponse(
                                            this.queueManagerObj,
                                            queueItem,
                                            response1.response,
                                        );
                                    }
                                    await queueSubObj.afterResponse(
                                        this.queueManagerObj,
                                        queueItem,
                                        response1.response,
                                    );
                                } else {
                                    const errObj = new Error('Server response not found');
                                    await queueSubObj.handleError(this.queueManagerObj, queueItem, errObj.message);
                                }
                            } catch (error) {
                                await queueSubObj.handleError(this.queueManagerObj, queueItem, error.message);
                            }
                        } else {
                            log.error('Queue Function to be called not matched ', queueSubObj);
                        }
                    }
                } catch (err) {
                    log.error(' Error happened from QueueCapability ' + err);
                }
            });
        };

        this.profileSwitch = () => {
            const beforeUnloadListener = async () => {
                // Only if leader pause it
                if (this.queueManagerObj.checkIfLeader()) {
                    this.pauseQueue();
                }
            };

            addEventListener('beforeunload', beforeUnloadListener, {
                capture: true,
            });
        };

        this.clearOcDataOnQueueComplete = async (persistedItems: Set<FilesToCleanUp>): Promise<void> => {
            log.info('calling clear oc persist data on queue complete');
            log.debug(`files to clean up ${JSON.stringify([...persistedItems])}`);
            for (const persistedItem of persistedItems) {
                await deleteFromPersistentStorage([persistedItem.filepath]);
                if (persistedItem.fileId) {
                    this._documentsService.removeInjectedFile(persistedItem.fileId);
                }
                if (persistedItem.uniqueId) {
                    await this._documentsService.clearFileReference(persistedItem.uniqueId);
                }
                persistedItems.delete(persistedItem);
                log.debug(`cleaned up persisted file path ${persistedItem.filepath}`);
                await this.queueManagerObj.setProps(FILES_TO_CLEANUP, JSON.stringify([...persistedItems]));
            }
        };

        this.timerInterval = () => {
            return setIntervalAsync(async () => {
                if (this.queueManagerObj.checkIfLeader()) {
                    const filesToCleanUpFromQueue = (await this.queueManagerObj.getProps()).find(
                        (property) => property.key === FILES_TO_CLEANUP
                    );
                    if (filesToCleanUpFromQueue?.value) {
                        log.debug('files are vailable to delete from persist and clear file references');
                        const filesToCleanUp: Set<FilesToCleanUp> = new Set(JSON.parse(filesToCleanUpFromQueue.value));
                        this.clearOcDataOnQueueComplete(filesToCleanUp);
                    }

                    const queueItemsPending = await this.queueManagerObj.getElementsPending();
                    const guids = queueItemsPending.map((queueItem) => {
                        return queueItem.uniqueId.replace(/-/gi, '');
                    });

                    // Only if guids is present
                    if (guids.length > 0) {
                        const responseObj = await pollFiles({
                            entityContext: { name: '', type: '', id: '' },
                            guids: guids,
                        });

                        const notificationObj: IFileNotification[] = await responseObj.json();

                        const pendingPromises = notificationObj.map((res) => {
                            // finding the queueItem
                            const queueItem = queueItemsPending.find((queueItem) => {
                                return queueItem.uniqueId.replace(/-/gi, '') === res.fileId;
                            });

                            let queueSubObj;
                            if (queueItem) {
                                // finding the queueSubscription Obj
                                queueSubObj = this.queueSubscribedMap.get(
                                    queueItem.operation + '_' + getContextEnvInfo(),
                                );

                                if (res.status === statusEnum.SUCCESS) {
                                    if (queueItem) {
                                        return queueSubObj?.handleResponse(
                                            this.queueManagerObj,
                                            queueItem,
                                            new Response(statusEnum.SUCCESS),
                                        );
                                    }
                                } else if (res.status === statusEnum.FAILED || res.status === statusEnum.UNAPPLICABLE) {
                                    return queueSubObj?.handleError(this.queueManagerObj, queueItem, res.result);
                                }
                            }

                            return;
                        });
                        await Promise.allSettled(pendingPromises);
                    }
                }
            }, virusScanPollingTimer);
        };
    }

    addItem = async (
        nodeObj: ICHUploadType,
        operation: CapabiltyEnum,
        error?: string,
        groupId?: string,
        noOfItems?: number,
        uploadTime?: number,
    ): Promise<IQueueItem> => {
        let fileObj!: Blob;

        // let queueDocument: Record<string, number> = {};

        // const checkInDoc = nodeObj as ICheckIn;

        // Only for queue
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const queueDocument = nodeObj as Record<string, any>;
        // file paramter prent in this.transformedInput
        // assigning name and adding the fileObj in attachment
        if (isOC()) {
            queueDocument.file = null;
        }
        if (!isOC() && nodeObj?.file) {
            fileObj = nodeObj?.file as Blob;
            queueDocument.file = nodeObj.name;
        }

        const uniqueId =  nodeObj.props?.uniqueId
            ? nodeObj.props?.uniqueId
            : uuid()

        let statusArray: Array<QueueItemStatus> = [];
        // CheckIn case
        if (nodeObj?.documentId) {
            statusArray = statusArray.concat([
                QueueItemStatus.STARTED,
                QueueItemStatus.PROCESSING,
                QueueItemStatus.NEW,
                QueueItemStatus.ERROR,
            ]);
            // add Document
        } else {
            statusArray = statusArray.concat([
                QueueItemStatus.COMPLETED,
                QueueItemStatus.STARTED,
                QueueItemStatus.PROCESSING,
                QueueItemStatus.NEW,
                QueueItemStatus.ERROR,
            ]);
        }
        // TO DO add the unique Id constraint
        // Disabling to unblock
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        // const queueIdPresent = await this.queueManagerObj.checkQueueIndicatorPresent(uniqueId, statusArray);

        let queueItemAdded;
        // if unique Id not present
        // if (!queueIdPresent) {
        // log.debug('Unique Doc id ' + uniqueId + 'not present. So adding item');
        if (error) {
            queueItemAdded = await this.queueManagerObj.addFailedItem(
                queueDocument,
                operation,
                'contextHandler',
                error,
                uploadTime,
                groupId,
                noOfItems,
            );
        } else {
            queueItemAdded = await this.queueManagerObj.addItem(
                queueDocument,
                operation,
                'contextHandler',
                uploadTime,
                groupId,
                noOfItems,
                uniqueId,
            );
            if (!isOC() && nodeObj?.file && nodeObj?.name) {
                await this.queueManagerObj.addDocument(queueItemAdded, queueDocument.name, fileObj, fileObj.type);
            }
        }
        // unique id present
        // } else {
        //   log.error('Unique Doc Id' + uniqueId + ' already presenr in db. So throwing error');
        //   throw new Error(uniqueAlreadyPresent);
        // }

        return queueItemAdded;
    };

    pauseQueue = (): void => {
        this.queueManagerObj.setQueuePaused();
    };

    doOperation = async (
        nodeObj: ICHUploadType,
        operation: CapabiltyEnum,
        error?: string,
        groupId?: string,
        noOfItems?: number,
        uploadTime?: number,
    ): Promise<ICHUploadResponse> => {
        if (operation === CapabiltyEnum.ADD_EMAIL) {
            await this.queueManagerObj.updateConfig(0, 1);
        }

        return { queueItem: await this.addItem(nodeObj, operation, error, groupId, noOfItems, uploadTime) };
    };
}
