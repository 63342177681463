import { IOverlayDialog } from '@wk/elm-uui-context-handler';
import { UUIReduxAction } from './types';

export const overlayDialogReducer = (
    state: IOverlayDialog | undefined,
    action: UUIReduxAction,
): IOverlayDialog | undefined => {
    switch (action.type) {
        case 'OpenOverlayDialog':
            return action.overlayDialog;
        case 'CloseOverlayDialog':
            return undefined;
    }
    return state;
};
