import { CHMessagingScope } from '@wk/elm-uui-context-handler';
import { messageBusDispatch } from '../components/contextLayerService/messageBusService';
import { UUIReduxDispatch } from '../reducers/types';

export const readClosedFailedUploadFlyoutAtFromLocalStorage = (): string | null => {
    return localStorage.getItem('closeFailedUploadFlyoutAt');
};

export const openFailedUploadFlyout = (reduxDispatch: UUIReduxDispatch): void => {
    updateFailedUploadFlyoutState(true, reduxDispatch);
    failedUploadFlyoutMessageBus('true');
};

export const closeFailedUploadFlyout = (reduxDispatch: UUIReduxDispatch): void => {
    localStorage.setItem('closeFailedUploadFlyoutAt', new Date().toJSON());
    updateFailedUploadFlyoutState(false, reduxDispatch);
    failedUploadFlyoutMessageBus('false');
};

const updateFailedUploadFlyoutState = (isOpen: boolean, reduxDispatch: UUIReduxDispatch): void => {
    reduxDispatch({
        type: 'SetFailedUploadFlyoutOpen',
        isOpen: isOpen,
    });
};

const failedUploadFlyoutMessageBus = (isOpen: string): void => {
    messageBusDispatch({
        type: 'FailedUploadFlyoutOpen',
        scope: CHMessagingScope.OtherInstances,
        message: isOpen,
    });
};

export const updateBatchUploadFlyoutCount = (count: string, reduxDispatch: UUIReduxDispatch): void => {
    reduxDispatch({
        type: 'SetBatchUploadFlyoutCount',
        count: count,
    });
    reduxDispatch({
        type: 'SetNewUploadCountUpdated',
        isUpdated: true,
    });
};

export const updateUploadingFlyoutState = (isOpen: boolean, reduxDispatch: UUIReduxDispatch): void => {
    reduxDispatch({
        type: 'SetIsUploadingFlyoutOpen',
        isOpen: isOpen,
    });
};
