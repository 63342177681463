import { DCEntityTypeEnum, DCResponse, IDocServiceRequest } from '../types';
import { injectable } from 'inversify';
import * as convert from 'xml-js';
import { factory } from '../ConfigLog4j';
import { apiRestFetchGet } from '../restApi/fetch.utils';
import { isAuthToken, getToken } from '../utils/auth.utils';
import { baseUrl } from '../utils/main.utils';
import {
    actionPerformerMethods,
    ICommandNameUrlPattern,
    inputValidators,
    transformOperation,
    urlPatternMethodAndCommandName,
} from './utils/maps';
import { GetUrlEnum } from '../enums/urlPattern';
import { IActionableEntity } from '../interfaces/actionEntity/type';
import { EValidator } from '../enums/validators';
import { Adapter } from '../adapter';
import { OPERATION_NOT_SUPPORTED } from '../constants';

const log = factory.getLogger('JRAdapter');

@injectable()
export class JRAdapter extends Adapter {
    objectRelative: string;

    constructor() {
        super();
        const func = async () => {
            let token = '';
            if (isAuthToken()) {
                token = await getToken();
            }

            return apiRestFetchGet(baseUrl() + '/datacert/api/command/', {}, token).then((response) => {
                return response.text().then(
                    (xml: string) => {
                        const data = convert.xml2json(xml);
                        const dataObj = JSON.parse(data);

                        const jsonArray: Array<Record<string, string>> = dataObj['elements'][0]['elements'];

                        const jsonObject = {};
                        jsonArray.forEach((command) => {
                            jsonObject[command['attributes']['ActionName']] = command['attributes']['Id'];
                        });

                        log.debug('Command Object Created');

                        return jsonObject;
                    },
                    (error: Error) => {
                        throw new Error(error.message);
                    },
                );
            });
        };

        this.commandIds = func();

        this.objectRelative = 'datacert/api/entity';

        this.ObjectTypeMap = {
            [DCEntityTypeEnum.Matter]: 'MatterMatterManagementDocument',
            [DCEntityTypeEnum.Organization]: 'OrganizationMatterManagementDocument',
            [DCEntityTypeEnum.Person]: 'PersonMatterManagementDocument',
            [DCEntityTypeEnum.InvoiceHeader]: 'InvoiceMatterManagementDocument',
            [DCEntityTypeEnum.EmailDocument]: 'EmailDocument',
        };
    }

    // TO DO MOBVED to PARENT
    getMappedOperation(capabilityEnum: string): string {
        if (capabilityEnum in transformOperation) {
            return transformOperation[capabilityEnum];
        }
        return capabilityEnum;
    }

    getActionPerformerMethod(
        capabilityEnum: string,
    ): (arg0: string, arg1: IActionableEntity, arg2: string) => Promise<DCResponse> {
        if (capabilityEnum in actionPerformerMethods) {
            return actionPerformerMethods[capabilityEnum];
        } else {
            throw Error('No ActionPerformer Method Found' + 'for ' + capabilityEnum);
        }
    }

    getUrlPatternMethodAndCommandName(capabilityEnum: string): ICommandNameUrlPattern {
        if (capabilityEnum in urlPatternMethodAndCommandName) {
            return urlPatternMethodAndCommandName[capabilityEnum];
        } else {
            throw Error('No CommandName and UrlPattern Method Found ' + 'for ' + capabilityEnum);
        }
    }

    getInputValidators(capabilityEnum: string): Array<EValidator> {
        if (capabilityEnum in inputValidators) {
            return inputValidators[capabilityEnum];
        } else {
            log.debug('No Input Validator Found ' + 'for ' + capabilityEnum);
            return [];
        }
    }

    editFolder(_docReq: IDocServiceRequest): Promise<DCResponse> {
        throw Error(OPERATION_NOT_SUPPORTED);
    }
    editDocument(_docReq: IDocServiceRequest): Promise<DCResponse> {
        throw Error(OPERATION_NOT_SUPPORTED);
    }

    getUrlEnum: GetUrlEnum = GetUrlEnum.PATTERN_CREATION;
}
