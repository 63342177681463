import { CapabiltyEnum } from '../../enums/capability';
import { injectable } from 'inversify';
import { IGetSupportedEntityTypes } from '../../types';
import { JRAdapterDownloadPattern } from '../urlEntity/downloadPattern';

@injectable()
export class JRAdapterGetSupportedEntityTypesUrlPattern extends JRAdapterDownloadPattern {
    operationName = CapabiltyEnum.GET_SUPPORTED_ENTITY_TYPES;
    async getUrl(nodeObj: IGetSupportedEntityTypes): Promise<string> {
        const baseUrl = await super.getUrl(nodeObj);
        const url =
            baseUrl +
            '/' +
            'metadata?searchParameters=((name)(LIKE)(Document);(name)(LIKE)(Organization);(name)(LIKE)(Invoice);(name)(LIKE)(Matter))';
        return url;
    }
}
