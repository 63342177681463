import { IDocActionPerformer } from '../../interfaces/actionPerformers/type';
import { IActionableEntity } from '../../interfaces/actionEntity/type';
import { factory } from '../../ConfigLog4j';
import { IDMToken } from '../../interfaces/dmTokenHandler/type';
import { injectable } from 'inversify';
import { getAccessToken } from '../utils/utils';
import { IDocOperationInvocation } from '../../interfaces/urlPattern/types';
import { DCResponse } from '../../types';

const log = factory.getLogger('T360AdapterActionPerformer');

@injectable()
export class T360AdapterActionPerformer implements IDocActionPerformer {
    tokenHandler: IDMToken;

    fetchRequest: (arg0: string, arg1: IActionableEntity, arg2: string) => Promise<DCResponse>;

    performAction(
        obj: IActionableEntity,
        urlObj: Promise<string>,
        _?: IDocOperationInvocation,
        extendedUrl?: string,
    ): Promise<DCResponse> {
        return urlObj?.then(async (url) => {
            const accessToken = await getAccessToken();
            log.debug('Performing action with token');
            if (extendedUrl) {
                url = url + extendedUrl;
            }

            return this.fetchRequest(url, obj, 'Bearer ' + accessToken);
        });
    }
}
