import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { config } from '../config';
import { getUserManager } from '../infrastructure/oidc';
import { FormTextControl } from '../../../components/common/formTextControl';
import clientPackage from '../../../../package.json';
import clPackage from '@wk/elm-uui-context-handler/package.json';
import docComponentPackage from '@wk/elm-uui-doc-component/package.json';

const CustomAboutComponent = () => {
    const [items, setItems] = useState({ t360version: '', networkName: '', loginName: '', date: '' });
    useEffect(() => {
        getUserManager()
            .getUser()
            .then((userinfo) => {
                fetch(`${config.get('REACT_APP_API_URL')}/api/v4/views/helpservice/about`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${String(userinfo?.access_token)}`,
                    },
                })
                    .then((res) => res.json())
                    .then((result) => {
                        setItems(result.AboutInfo);
                    });
            });
    }, []); // tslint:disable-line

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormTextControl title="T360 Version" content={items.t360version} />
                </Grid>
                <Grid item xs={6}>
                    <FormTextControl title="UI Version" content={clientPackage.version} />
                </Grid>
                <Grid item xs={6}>
                    <FormTextControl title="CL Version" content={clPackage.version} />
                </Grid>
                <Grid item xs={6}>
                    <FormTextControl title="DC Version" content={docComponentPackage.version} />
                </Grid>
                <Grid item xs={6}>
                    <FormTextControl title="Network Name" content={items.networkName} />
                </Grid>
                <Grid item xs={6}>
                    <FormTextControl title="Login Name" content={items.loginName} />
                </Grid>
                <Grid item xs={12}>
                    <FormTextControl title="Date" content={items.date} />
                </Grid>
                <Grid item xs={12}>
                    <FormTextControl title="URL" content={config.get('REACT_APP_UI_URL')} />
                </Grid>
                <Grid item xs={12}>
                    <FormTextControl title="User Agent" content={window.navigator.userAgent} />
                </Grid>
            </Grid>
        </div>
    );
};

export { CustomAboutComponent };
