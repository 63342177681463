import {
    IDMInfoHandler,
    IAdapterInfo,
    IDMObj,
    IValidationInfo,
    ISupportedOperations,
    IDmInfoFromSource,
    IDMTypeInfo,
} from './types';
import { inject, injectable } from 'inversify';
import { IDocAdaptor } from '../interfaces/adapter/types';
import { factory } from '../ConfigLog4j';
import { AdapterEnum } from '../enums/adapter';
import { AuthSchemeEnum } from '../enums/authScheme';
import { CapabiltyEnum } from '../enums/capability';

import { dmInfoFetch } from '../restApi/fetch.utils';
import { IAuthData } from '../interfaces/authScheme/type';
import { adapterSwitch, authSwitch } from './utils';
import { baseUrl, getHostName } from '../utils/main.utils';
import { getToken, isAuthToken } from '../utils/auth.utils';
import { JRADAPTER_SECRET_KEY } from '../constants';
const log = factory.getLogger('DMInfoHandler');

@injectable()
export class DMInfoHandler implements IDMInfoHandler {
    constructor(
        @inject('Factory<IDocAdaptor>')
        private docAdapterFactory: (adapter: IAdapterInfo) => (inpOperation: string) => IDocAdaptor,
    ) {}

    singletonObj: Promise<IAdapterInfo> | null = null;
    getDMInfo = async (): Promise<IAdapterInfo> => {
        const getDmInfoRelativeUrl = () => {
            return 'documentinformationservice/documentinfo';
        };

        const getDmInfoFromBaseSystem = async (url: string) => {
            let token = '';
            if (isAuthToken()) {
                token = await getToken();
            }
            const response = await dmInfoFetch(url, token);

            return response.json();
        };

        const transformAdapterInfo = async (dmInfoFromSvc: IDmInfoFromSource) => {
            const dmInfoList: IDMObj[] = [];
            let adapter = {} as AdapterEnum;
            let authScheme = {} as AuthSchemeEnum;
            let validationInfo = {} as IValidationInfo;
            let authInfo = {} as IAuthData;
            let supportedOperations = {} as ISupportedOperations[];
            let supportedEntityTypes = {} as Record<string, number>;
            const dmTypeInfo: IDMTypeInfo = dmInfoFromSvc.dmInfo.dmDetails.dmDetail[0];

            // For Single DM
            // TO DO: Handle Multiple DM
            adapter = adapterSwitch[dmTypeInfo.dmAccessInfo.dmName];
            authScheme = authSwitch[dmTypeInfo.dmAccessInfo.dmAccessType];
            validationInfo = dmTypeInfo.dmConfig;
            authInfo = dmTypeInfo.dmStorageInfo;
            supportedOperations = dmTypeInfo.supportedOperations;
            supportedEntityTypes = dmTypeInfo.supportedEntityTypes;

            return setAdaptorInfo(
                dmInfoList,
                adapter,
                authScheme,
                validationInfo,
                authInfo,
                supportedOperations,
                supportedEntityTypes,
            );
        };

        const setAdaptorInfo = (
            dmInfos: IDMObj[],
            adapter: AdapterEnum,
            authScheme: AuthSchemeEnum,
            validationInfo: IValidationInfo,
            authInfo: IAuthData,
            supportedOperations: ISupportedOperations[],
            supportedEntityTypes: Record<string, number>,
        ) => {
            const adapterInfo = {} as IAdapterInfo;
            adapterInfo.dmInfos = dmInfos;
            adapterInfo.adapter = adapter;
            adapterInfo.authScheme = authScheme;
            adapterInfo.validationInfo = validationInfo;
            adapterInfo.authInfo = authInfo;
            adapterInfo.authInfo.hostname = getHostName();
            adapterInfo.supportedOperations = supportedOperations;
            adapterInfo.supportedEntityTypes = supportedEntityTypes;
            return adapterInfo;
        };

        if (this.singletonObj == null) {
            const dmInfoUrl = baseUrl() + '/' + getDmInfoRelativeUrl();
            try {
                log.debug('Calling the dmInfo:' + dmInfoUrl);
                const data: IDmInfoFromSource = await getDmInfoFromBaseSystem(dmInfoUrl);

                this.singletonObj = transformAdapterInfo(data);
            } catch (error) {
                log.debug('Error in receiving the dmInfo Object ' + error);
                log.debug('So temporarily returning it as JR dmInfo');
                this.singletonObj = this.getDMInfo2();
            }
            return this.singletonObj;
        } else {
            return this.singletonObj;
        }
    };

    // TO DO
    // JR dmInfo to be removed when the JR dmInfo is added
    async getDMInfo2(): Promise<IAdapterInfo> {
        // JR Adapter
        const adapterInfo: IAdapterInfo = {
            adapter: AdapterEnum.JRADAPTER,
            authScheme: AuthSchemeEnum.BASIC_AUTHENTICATION,
            authInfo: {
                client_id: '',
                client_secret: '',
                hostname: baseUrl(),
                identity_server: '',
                port: '',
                storId: '',
                username: '',
                password: '',
                granttype: '',
                scope: '',
            },
            validationInfo: {
                documentFileSizeLimit: 1992294400,
                emailFileSizeLimit: 262144000,
                specialCharacterNotAllowed: '[<>:"/\\|?*#/^]',
                entityDefaultLength: 255,
                fileTypeNotAllowed: ['exe', 'zip'],
                secretKey: JRADAPTER_SECRET_KEY,
            },
            dmInfos: [],
        };
        return adapterInfo;
    }

    // TO DO DOCSERVICE and SPOL to be retrived using a generic Function
    // THIS FUNCTION CAN INTERACT with the base System which can
    // PROVIDE DETAILS
    // log.debug('factory DocService' + factory('DocService'));
    getAdaptor = async (operation: CapabiltyEnum): Promise<IDocAdaptor> => {
        const dmInfoObj = await this.getDMInfo();

        log.debug('Get Adaptor for the following  adaptor' + dmInfoObj.adapter);

        // TO DO
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        // const docAdaptorfactory: any = myContainer.get<IDocAdaptor>('Factory<IDocAdaptor>');
        const docAdapter = this.docAdapterFactory(dmInfoObj);
        const docService: IDocAdaptor = docAdapter(operation);
        return docService;
        //  });
    };
}
