import { getBannerService } from '../contextHandler';
import {
    ICHCancelCheckoutDocMetaType,
    ICHCheckoutDocMetaType,
    keepCancelCheckout,
    keepCheckout,
    overwriteCheckout,
    removeCancelCheckout,
} from '../contextManager';

export const Dialog = {
    buttons: {
        ok: 'Ok',
        save: 'Save',
        save_as: 'Save as',
        save_and_upload: 'Save & Upload',
        save_with_a_new_name: 'Save As',
        overwrite: 'Overwrite',
        keep: 'Keep',
        remove: 'Remove',
        close: 'Close',
        cancel: 'Cancel',
        retry: 'Retry',
        apply: 'Apply',
        proceed: 'Proceed',
        close_automatically: 'Close Automatically',
        upload_document: 'Upload document',
        select_file: 'Select file',
        open_containing_folder: 'Open containing folder',
        refresh: 'Refresh',
        switch: 'Switch',
        cancel_action: 'Cancel',
        showResults: 'Apply Filter or Click Here to Show Results',
        discard_changes: 'Discard Changes',
        cancel_discard: `Don't Discard`,
    },
    confirm: {
        upload_document: {
            title: 'Upload Document',
        },
        upload_new_active_document: {
            title: 'Upload Document',
            message: 'Documents must be saved before they can be uploaded.',
        },
        upload_exist_active_document: {
            title: 'Upload Document',
            message: `Documents with unsaved changes must be saved before upload. Select 'Save' to save changes to the current document and upload, or 'Save As' to save the document under another name and upload.`,
        },
        upload_exist_active_saveas_document: {
            title: 'Upload Document',
            message: `Documents with unsaved changes must be saved before upload. Select 'Save As' to save the document under another name and upload.`,
        },
        file_is_already_open: {
            title: 'Error',
            message:
                'The action cannot be performed because the file is open in another application. Switch to the application where the file is open and retry your action.',
        },
        network_event: {
            title: 'Network Switch',
            message: 'Documents are in Process.Do You want to Proceed?',
        },
    },
    migration_failure: {
        title: 'Warning',
        message:
            'There was an issue while migrating upload-download information during auto-upgrade. As a result, some files may have failed. Please retry.',
        onCloseCallback: (): void => {
            return;
        },
        buttons: [
            {
                label: 'Close',
                callback: (): void => {
                    return;
                },
            },
        ],
    },
    document: {
        discard_check_out: {
            title: 'Discard Check Out',
            message: 'A local copy of the document file exists. Do you want to remove or save the local copy?',
            buttons: [
                {
                    label: 'Remove',
                    callback: (args: ICHCancelCheckoutDocMetaType): void => {
                        removeCancelCheckout(args);
                    },
                },
                {
                    label: 'Keep',
                    callback: (args: ICHCancelCheckoutDocMetaType): void => {
                        keepCancelCheckout(args);
                    },
                },
                {
                    label: 'Cancel',
                    callback: (): void => {
                        return;
                    },
                },
            ],
        },
        overwrite_check_out: {
            title: 'Check Out',
            message: 'A local copy of the document file already exists. Overwrite or keep your local copy?',
            onCloseCallback: async (): Promise<void> => {
                const bannerObj = getBannerService();
                await bannerObj.reopenBanner();
                return;
            },
            buttons: [
                {
                    label: 'Overwrite',
                    callback: (args: ICHCheckoutDocMetaType): void => {
                        overwriteCheckout(args);
                    },
                },
                {
                    label: 'Keep',
                    callback: (args: ICHCheckoutDocMetaType): void => {
                        keepCheckout(args);
                    },
                },
                {
                    label: 'Cancel',
                    callback: (): void => {
                        return;
                    },
                },
            ],
        },
        checkin_not_found: {
            title: 'Check In error',
            message:
                'This document `{documentFileName}` cannot be checked in as it cannot be found. Please upload again.',
        },
        checkin_wrong_file_name: {
            title: 'Check In error',
            message:
                'The file name `{documentFileName}` is not valid because it does not match the list and cannot be uploaded. Please select valid file name to upload.',
        },
        switch_options: {
            title: 'Error',
            message: `This action cannot be performed because the file is open in another application. Switch to the application where the file is open and retry your action.`,
            onCloseCallback: (): void => {
                return;
            },
            buttons: [
                {
                    label: 'Switch',
                    callback: (): void => {
                        return;
                    },
                },
                {
                    label: 'Cancel',
                    callback: (): void => {
                        return;
                    },
                },
            ],
        },
        upload_document: {
            title: 'Upload Document',
        },
        document_mismatch: {
            title: 'Error',
            message: 'Something went wrong. If the problem persists, Please contact your Administrator.',
        },
    },
};
