import 'reflect-metadata';
import { CtxDocumentMetadataFileNameTransformer } from './../transform/ctxDocumentMetadataFileNameTransformer';
import {
    FileUploadControl,
    ICHDocumentMetadata,
    ICHDocumentMetadataFileName,
    ICHResponse,
    ICHUploadType,
    IContextInputType,
} from './../interfaces/operations';
import { DocumentEntity } from '../dto/documentEntity';
import {
    IDocumentMetadata,
    documentMetadata,
    folderMetadata,
    IDocumentMetadataFileName,
    DocSupportedBaseEntityTypesEnum,
} from '@wk/elm-uui-doc-component';
import { inject, injectable } from 'inversify';
import 'reflect-metadata';
import { Locale } from '../locale';
import {
    Entities,
    DocumentType,
    EventType,
    ToastType,
    CapabiltyEnum,
    DocumentState,
    CHMessagingScope,
    CHMessageType,
    HttpStatusCode,
    EntityTypes,
} from '../enum/enum';
import { FileOperations } from '@wk/office-companion-js';
import { getTenantId, getAuthToken, DCResponse } from '@wk/elm-uui-doc-component';
import { factory } from '../configLog4J';
import { Dialog } from '../../src/configurations';
import {
    CheckoutPathDescriptor,
    IDownloadParams,
    SplitDocumentsAndEmails,
    StaleValidationParams,
} from '../interfaces/interface';
import { CtxDocumentMetadataTransformer } from '../transform/ctxDocumentMetadataTransformer';
import { getTransformedObject } from '../transform/helper';
import {
    getSupportedEntityTypesInfo,
    handleCallback,
    throwUnExpectedError,
    deleteFromPersistentStorage,
    isValidDocumentId,
} from '../utils/main.utils';
import { v4 as uuid } from 'uuid';
import { getBannerService } from '../contextHandler';
import { CHMessagingService, MessageService } from './message.service';
import { IOfficeService } from './office.service';
import { IEventService } from './eventContextHandler.service';
import { IEmailService } from './email.service';
import { FileExtensions } from '../enum/file-extensions.enum';
import { DialogService } from './dialog.service';

const log = factory.getLogger('DocumentsService');

export interface IDocumentsService {
    isCurrentUser(authenticatedUser: string, checkedOutByUsername: string): boolean;
    isDocumentLockedForMe(authenticatedUser: string, checkedOutByUsername: string): boolean;
    isCheckedOutByMe(lockedBy: string): boolean;
    isCheckedOutByOther(authenticatedUser: string, lockedBy: string): boolean;
    getCheckedOutMessage(authenticatedUser: string, lockedBy: string, documentFileName: string): string | void;
    getRelativePath(documentMeta: IDocumentMetadata): string;
    encodeURI(url: string): string;
    getUniqueId(documentMeta: IDocumentMetadata): Promise<string>;
    downloadAndOpen(downloadParams: IDownloadParams): Promise<Response | string>;
    getUniqueIdFromMeta(documentEntity: DocumentEntity): Promise<string>;
    getExistingDocument(fileName?: string, entityInstanceId?: string, folderId?: string): Promise<boolean>;
    setInputFiles(inputFileId: string, filePaths: string[]): Promise<void>;
    getFiles(inputFileId: string): FileList | undefined;
    prepareSwitchDialog(uniqueId: string): Promise<void>;
    getCheckoutPathDescriptor(uniqueId: string): Promise<CheckoutPathDescriptor>;
    getInjectedFile(fileId: string, localFullPath?: string): Promise<FileList | undefined>;
    getDocumentMeta(
        docObject: ICHDocumentMetadataFileName | ICHDocumentMetadata,
        hasDocumentId: boolean,
    ): Promise<DocumentEntity | undefined>;
    getFolderMeta(docObject: ICHDocumentMetadataFileName | ICHDocumentMetadata): Promise<DocumentEntity | undefined>;
    removeInjectedFile(fileInputId: string): void;
    clearFileReference(uniqueId: string): Promise<void>;
    getEntityType(contextObj: ICHDocumentMetadata): Promise<string>;
    wasStaleDocument(
        contextObj: ICHDocumentMetadata,
        staleDcouemntType?: string,
        opertionType?: CapabiltyEnum,
    ): Promise<boolean>;
    handleIsApplicationBusy(operationName: string): Promise<string>;
    isActiveDocumentSelection(): Promise<boolean>;
    createFileUploadControl(): FileUploadControl;
    hasFileExtensionMatch(docEntity: DocumentEntity, newDocument: string, contextObj: ICHUploadType): Promise<boolean>;
    handleResponse(
        contextObj: IContextInputType,
        response: DCResponse,
        message?: string,
        itemPageOperation?: boolean,
        isEntityDeleted?: boolean,
    ): Promise<ICHResponse>;
    handleErrorMessage(staleParams: StaleValidationParams): Promise<string>;
    openAndActivateDocument(uniqueId: string): Promise<void>;
    transformValidationResponse(response: DCResponse): DCResponse;
    buildPayload(contextObj: ICHUploadType, fileName: string): void;
    splitDocumentsAndEmails(paths: string[], contextObj: ICHUploadType): SplitDocumentsAndEmails;
}

@injectable()
export class DocumentsService implements IDocumentsService {
    constructor(
        @inject('MessageService') private _messageService: MessageService,
        @inject('OfficeService') private _officeService: IOfficeService,
        @inject('EventContextHandler') private _eventService: IEventService,
        @inject('EmailService') private _emailService: IEmailService,
        @inject('CHMessagingService') private _chMessageService: CHMessagingService,
        @inject('DialogService') private _dialogService: DialogService,
    ) {}

    public isCurrentUser(authenticatedUser: string, checkedOutByUsername: string): boolean {
        return (authenticatedUser || '').toLowerCase() === (checkedOutByUsername || '').toLowerCase();
    }

    public isDocumentLockedForMe(authenticatedUser: string, checkedOutByUsername: string): boolean {
        const isCurrentUser = this.isCurrentUser(authenticatedUser, checkedOutByUsername);
        return !!checkedOutByUsername && !isCurrentUser;
    }

    public isCheckedOutByMe(lockedBy: string): boolean {
        return !!lockedBy;
    }

    public isCheckedOutByOther(authenticatedUser: string, lockedBy: string): boolean {
        const isLockedForMe = this.isDocumentLockedForMe(authenticatedUser, lockedBy);
        return isLockedForMe;
    }

    public getCheckedOutMessage(authenticatedUser: string, lockedBy: string, documentFileName: string): string | void {
        if (this.isCheckedOutByOther(authenticatedUser, lockedBy)) {
            return this._messageService.compileTemplate(Locale.documents.delete_document.checked_out_by_other, {
                documentFileName,
                lockedBy,
            });
        } else if (this.isCheckedOutByMe(lockedBy)) {
            return this._messageService.compileTemplate(Locale.documents.delete_document.checked_out_by_me);
        }
        return undefined;
    }

    public getRelativePath(documentMeta: IDocumentMetadata): string {
        const { type: associatedEntityName, id: associatedEntityId } = documentMeta.entityContext;
        return Entities[associatedEntityName.toUpperCase()] + '_' + associatedEntityId + '_' + documentMeta.docId + '/';
    }

    public encodeURI(url: string): string {
        return encodeURI(url);
    }

    public async getUniqueId(documentMeta: IDocumentMetadata): Promise<string> {
        // TODO - Call Doc Component - To Generate Unique ID for each Document
        const tenantID = await getTenantId();
        const { type, id } = documentMeta.entityContext;
        return tenantID + '_' + Entities[type.toUpperCase()] + id + '_' + DocumentType['DOCUMENT'] + documentMeta.docId;
    }

    public async getUniqueIdFromMeta(documentEntity: DocumentEntity): Promise<string> {
        // TODO - Call Doc Component - To Generate Unique ID for each Document
        const tenantID = await getTenantId();
        const { relatedEntityType, relatedEntityId, id } = documentEntity;
        return (
            tenantID +
            '_' +
            Entities[relatedEntityType.toUpperCase()] +
            relatedEntityId +
            '_' +
            DocumentType['DOCUMENT'] +
            id
        );
    }

    public async downloadAndOpen(downloadParams: IDownloadParams): Promise<Response | string> {
        let authHeader = {};
        const authToken = await getAuthToken();
        if (authToken) {
            authHeader = {
                Authorization: 'Bearer ' + authToken,
            };
        }

        const temporary = !downloadParams.isCheckedOutByMe;
        log.debug('uniqueId ' + downloadParams.uniqueId + ' isCheckedoutByOther ' + !!downloadParams.isCheckedOutByMe);
        if (downloadParams.uniqueId) {
            return await this._officeService.download(this.encodeURI(downloadParams.url), {
                openWhenDone: false,
                uniqueId: downloadParams.uniqueId,
                requestHeaders: authHeader,
            });
        } else {
            log.debug('cl metaData ' + downloadParams.metaData);
            const url = this.encodeURI(downloadParams.url);
            return await this._officeService.download(url, {
                temporary,
                openWhenDone: true,
                requestHeaders: authHeader,
                metadata: downloadParams.metaData,
            });
        }
    }

    public async isActiveDocumentSelection(): Promise<boolean> {
        const isActiveDocument = await this._officeService.getActiveDocumentSelection();
        return isActiveDocument;
    }

    public async getExistingDocument(
        fileName?: string,
        entityInstanceId?: string,
        folderId?: string,
    ): Promise<boolean> {
        log.debug('Received fileName:' + fileName);
        log.debug('Received entityInstanceId:' + entityInstanceId);
        log.debug('Received folderId:' + folderId);
        // TODO : Call Doc Component to get document meta of existing document and change return type of this method
        return true;
    }

    public async setInputFiles(inputFileId: string, filePaths: string[]): Promise<void> {
        try {
            await FileOperations.setInputFiles(`#${inputFileId}`, filePaths);
        } catch (error) {
            log.error('Error : ' + JSON.stringify(error));
        }
    }

    public getFiles(inputFileId: string): FileList | undefined {
        try {
            const fileList = document.getElementById(inputFileId) as HTMLInputElement;
            if (fileList.files && fileList.files.length) {
                return fileList.files;
            }
        } catch (error) {
            log.error('Error : ', error);
        }
        return undefined;
    }

    public removeInjectedFile(fileId: string): void {
        const fileList = document.getElementById(fileId) as HTMLInputElement;
        fileList.remove();
    }

    public async getInjectedFile(fileId: string, localFullPath: string): Promise<FileList | undefined> {
        const fullPath = localFullPath || (await this._officeService.getCurrentDocumentFullName());
        const paths: string[] = [];
        paths.push(fullPath);

        // Injects File Path to get File Content
        await this.setInputFiles(fileId, paths);
        const files = await this.getFiles(fileId);
        return files;
    }

    public async getCheckoutPathDescriptor(uniqueId: string): Promise<CheckoutPathDescriptor> {
        const isLocalCopyExists = await this._officeService.isFileExists(uniqueId);
        if (isLocalCopyExists) {
            const { path, fileName } = await FileOperations.getMetadata(uniqueId);
            const localFullPath = path;
            const name = fileName;
            return { name, localFullPath, isLocalCopyExists };
        } else {
            return { name: '', localFullPath: '', isLocalCopyExists: false };
        }
    }

    public async prepareSwitchDialog(uniqueId: string): Promise<void> {
        const { buttons } = Dialog.document.switch_options;
        buttons.map((element) => {
            if (element.label == Dialog.buttons.switch) {
                element.callback = async () => {
                    await handleCallback(this.openAndActivateDocument.bind(this, uniqueId));
                    return;
                };
            }
        });

        this._dialogService.showDialog({
            title: Dialog.document.switch_options.title,
            message: this._messageService.compileTemplate(Dialog.document.switch_options.message),
            buttons: Dialog.document.switch_options.buttons,
        });
    }
    public async openAndActivateDocument(uniqueId: string): Promise<void> {
        const isFileExists = await this._officeService.isFileExists(uniqueId);
        if (isFileExists) {
            if (!(await FileOperations.isOpen(uniqueId))) {
                await FileOperations.open(uniqueId);
                return;
            }
            const { path } = await FileOperations.getMetadata(uniqueId);
            log.info('OC | Notify Other instances to open file');
            this._chMessageService.notify({
                message: JSON.stringify({ uniqueId: uniqueId, path: path }),
                scope: CHMessagingScope.OtherInstances,
                type: CHMessageType.activateWindow,
            });
        } else {
            this.showToast(ToastType.ERROR, Locale.documents.local_copy_not_found);
        }
    }

    public async handleResponse(
        contextObj: ICHUploadType,
        response: DCResponse,
        message?: string,
        itemPageOperation = true,
        isEntityDeleted?: boolean,
        refresh = true,
    ): Promise<ICHResponse> {
        if (response && response.ok) {
            if (refresh) {
                this._chMessageService.notifyRefreshUUIForEntity(
                    contextObj.entityTypeId,
                    contextObj.documentId,
                    contextObj.associatedEntityTypeId,
                    contextObj.associatedEntityId,
                    contextObj.folderId,
                    isEntityDeleted,
                );
            }
            this._eventService.publish({
                name: EventType.TOAST,
                toast: {
                    toastMessage: message || '',
                    type: ToastType.SUCCESS,
                },
            });
        } else {
            if (itemPageOperation) {
                response = this.transformValidationResponse(response);
            } else {
                const responses: string[] = [];
                let message = '';

                if (response.status === HttpStatusCode.FORBIDDEN) {
                    return message;
                }

                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                for (const [_key, value] of Object.entries(response.statusInfo.errors)) {
                    responses.push(value);
                }

                if (responses.length > 0) {
                    message = responses.join(' | ');
                    if (response.status == 400 && response.statusInfo.errors.default) {
                        return ''; // retrun from here if response status 400 to avoid duplicate toast message to display.
                    }
                    this._eventService.publish({
                        name: EventType.TOAST,
                        toast: {
                            toastMessage: message,
                            type: ToastType.ERROR,
                        },
                    });
                }
                return message;
            }
        }
        return { response: response };
    }

    public async handleErrorMessage(staleParams: StaleValidationParams): Promise<string> {
        const { message, contextObj, isStaleRefresh, isEntityDeleted } = staleParams;
        // For stale refresh only active instance should show toast.
        if (message) {
            this.showToast(ToastType.ERROR, message, isEntityDeleted);
        }
        if (contextObj) {
            this._chMessageService.notifyRefreshUUIForEntity(
                contextObj.entityTypeId,
                contextObj.documentId,
                contextObj.associatedEntityTypeId,
                contextObj.associatedEntityId,
                contextObj.folderId,
                isEntityDeleted,
                isStaleRefresh,
            );
        }
        return message || Locale.failure.general_failure;
    }

    public async getDocumentMeta(
        docObject: ICHDocumentMetadataFileName | ICHDocumentMetadata,
        hasDocumentId: boolean,
    ): Promise<DocumentEntity | undefined> {
        let transformedObject: IDocumentMetadata | IDocumentMetadataFileName;
        try {
            if (!hasDocumentId) {
                log.info('OC | Gets Document Meta using name');
                transformedObject = getTransformedObject<
                    ICHDocumentMetadataFileName,
                    IDocumentMetadataFileName,
                    CtxDocumentMetadataFileNameTransformer
                >(CapabiltyEnum.DOCUMENT_META_FILENAME, docObject as ICHDocumentMetadataFileName);
            } else {
                log.info('OC | Gets Document Meta using ID');
                transformedObject = getTransformedObject<
                    ICHDocumentMetadata,
                    IDocumentMetadata,
                    CtxDocumentMetadataTransformer
                >(CapabiltyEnum.DOCUMENT_METADATA, docObject as ICHDocumentMetadata);
            }
            const response = await documentMetadata(transformedObject);
            if (response && response.ok) {
                const documentEntity = await response.outputInfo();
                log.info('OC | File Meta Response' + JSON.stringify(documentEntity));
                if (documentEntity) {
                    const { id } = documentEntity;
                    if (id != '0' || id != 0) {
                        return documentEntity;
                    } else {
                        log.warn('OC | Document not found');
                        return undefined;
                    }
                }
            } else {
                log.warn('OC | Document not found');
                return undefined;
            }

            return;
        } catch (err) {
            throwUnExpectedError(err);
            log.error('OC | Error in Document Meta' + err);
            return;
        }
    }

    public async getFolderMeta(
        docObject: ICHDocumentMetadataFileName | ICHDocumentMetadata,
    ): Promise<DocumentEntity | undefined> {
        let transformedObject: IDocumentMetadata | IDocumentMetadataFileName;
        try {
            log.info('OC | Gets Folder Meta using ID');
            transformedObject = getTransformedObject<
                ICHDocumentMetadata,
                IDocumentMetadata,
                CtxDocumentMetadataTransformer
            >(CapabiltyEnum.DOCUMENT_METADATA, docObject as ICHDocumentMetadata);
            // In doc. related operation folderId is actual docId for folderMeta
            if (!isValidDocumentId(transformedObject.docId) || docObject.folderId) {
                transformedObject.docId = docObject.folderId;
            }
            const response = await folderMetadata(transformedObject);
            if (response && response.ok) {
                const documentEntity = await response.outputInfo();
                log.info('OC | Folder File Meta Response' + JSON.stringify(documentEntity));
                if (documentEntity) {
                    const { id } = documentEntity;
                    if (id != '0' || id != 0) {
                        return documentEntity;
                    } else {
                        log.error('OC | Folder not found');
                        return undefined;
                    }
                }
            } else {
                log.error('OC | Folder not found');
                return undefined;
            }

            return;
        } catch (err) {
            log.error('OC | Error in Folder Meta', err);
            throwUnExpectedError(err);
            return;
        }
    }

    public async clearFileReference(uniqueId: string): Promise<void> {
        const isExists = await this._officeService.isFileExists(uniqueId);
        if (isExists) {
            log.info('OC | Renames file and Move file to unlinked-files Folder');
            await FileOperations.clearFileReferences(uniqueId, true);
            if (this._officeService.uniqueId === uniqueId) {
                this._officeService.uniqueId = '';
            }
        }
    }

    public async getEntityType(contextObj: ICHDocumentMetadata): Promise<string> {
        if (!contextObj.entityTypeId) {
            return Locale.responseText.entityId_not_found;
        }
        const supportedEntityTypeMap = await getSupportedEntityTypesInfo();
        const supportedEntityTypeId = supportedEntityTypeMap.get(contextObj.entityTypeId);
        if (supportedEntityTypeId == DocSupportedBaseEntityTypesEnum.EMAIL) {
            return DocSupportedBaseEntityTypesEnum.EMAIL;
        } else if (supportedEntityTypeId == DocSupportedBaseEntityTypesEnum.DOCUMENT) {
            return DocSupportedBaseEntityTypesEnum.DOCUMENT;
        } else {
            return Locale.responseText.entityId_not_found;
        }
    }

    public async wasStaleDocument(contextObj: ICHDocumentMetadata, documentStateType?: string): Promise<boolean> {
        const staleParams: StaleValidationParams = { contextObj, isEntityDeleted: true };
        switch (documentStateType) {
            case DocumentState.DOC_EXIST: {
                staleParams.isStaleRefresh = true;
                staleParams.message = Locale.stale.deleted_document;
                return await this.isDocumentAvailable(staleParams);
            }
            case DocumentState.FOLDER_EXIST: {
                staleParams.isStaleRefresh = true;
                staleParams.message = Locale.stale.deleted_folder;
                return await this.isFolderAvailable(staleParams);
            }
            case DocumentState.CHECKEDIN: {
                staleParams.message = Locale.stale.discarded_document;
                staleParams.isEntityDeleted = false;
                return await this.isDocumentCheckedIn(staleParams);
            }
            case DocumentState.CHECKEDOUT: {
                staleParams.message = Locale.stale.checkedout_document;
                staleParams.isEntityDeleted = false;
                return await this.isDocumentCheckedOut(staleParams);
            }
            case DocumentState.EMAIL_EXIST: {
                staleParams.isStaleRefresh = true;
                staleParams.message = Locale.stale.deleted_email;
                return this.isEmailAvailable(staleParams);
            }
            case DocumentState.ADD_FOLDER_EXIST: {
                // Handling operation - not to refresh current instance
                staleParams.message = Locale.stale.deleted_document;
                return await this.isFolderAvailable(staleParams);
            }
            case DocumentState.EDIT_FOLDER_EXIST: {
                // Handling summary page operation - not to show toast and refresh current instance
                return await this.isFolderAvailable(staleParams);
            }
            case DocumentState.EDIT_DOC_EXIST: {
                // Handling summary page operation - not to show toast and refresh current instance
                return await this.isDocumentAvailable(staleParams);
            }
            default: {
                return false;
            }
        }
    }

    public async handleIsApplicationBusy(operationName: string): Promise<string> {
        const reopenBanner = async () => {
            const bannerObj = getBannerService();
            await bannerObj.reopenBanner();
            return;
        };
        const okButton = {
            label: Dialog.buttons.ok,
            callback: async (): Promise<void> => {
                await handleCallback(reopenBanner.bind(this));
                return;
            },
        };
        const onCloseCallback = async () => {
            await handleCallback(reopenBanner.bind(this));
            return;
        };
        const buttons = [okButton];
        const message = this._messageService.compileTemplate(Locale.documents.excel_document_busy.message, {
            operationName,
        });
        this._dialogService.showDialog({
            title: Locale.documents.excel_document_busy.title,
            message,
            onCloseCallback,
            buttons,
        });
        return message;
    }

    public createFileUploadControl(): FileUploadControl {
        // generate random id for HTML Input File Element
        const fileUploadControlId = 'clFileUpload_' + uuid().toString();
        const fileUploadControl = document.createElement('input');
        fileUploadControl.setAttribute('type', 'file');
        fileUploadControl.setAttribute('style', 'visibility:hidden');
        fileUploadControl.setAttribute('id', fileUploadControlId);
        document.body.appendChild(fileUploadControl);
        return { fileUploadControl, fileUploadControlId };
    }

    public async hasFileExtensionMatch(
        documentEntity: DocumentEntity,
        newDocument: string,
        contextObj: ICHUploadType,
    ): Promise<boolean> {
        const newDocumentextension = this._officeService.getExtension(newDocument);
        const fileNameExtension = this._officeService.getExtension(documentEntity.documentFileName);
        if (newDocumentextension.toLowerCase() === fileNameExtension.toLowerCase()) {
            return true;
        }
        log.info('oldFile ' + documentEntity.documentFileName);
        log.info('newFile ' + newDocument);
        this.buildPayload(contextObj, documentEntity.documentFileName);
        const message = this._messageService.compileTemplate(Locale.documents.doc_file_type_doesnt_match, {
            newFileName: newDocument,
        });
        this.showToast(ToastType.ERROR, message);
        deleteFromPersistentStorage(contextObj.filePaths);
        return false;
    }

    public buildPayload(contextObj: ICHUploadType, fileName: string): void {
        const payload = {
            name: {
                value: fileName,
            },
            documentName: {
                value: fileName,
            },
            parentEntityId: {
                value: contextObj.associatedEntityTypeId || '',
            },
        };
        contextObj.payload = payload;
    }

    private async isDocumentAvailable(staleParams: StaleValidationParams): Promise<boolean> {
        if (!staleParams.contextObj) {
            return false;
        }
        const documentEntity: DocumentEntity | undefined = await this.getDocumentMeta(staleParams.contextObj, true);
        if (!documentEntity) {
            this.handleErrorMessage(staleParams);
            return false;
        }
        return true;
    }
    private async isFolderAvailable(staleParams: StaleValidationParams): Promise<boolean> {
        if (!staleParams.contextObj) {
            return false;
        }
        const documentEntity: DocumentEntity | undefined = await this.getFolderMeta(staleParams.contextObj);
        if (!documentEntity) {
            this.handleErrorMessage(staleParams);
            return false;
        }
        return true;
    }

    private async isDocumentCheckedOut(staleParams: StaleValidationParams): Promise<boolean> {
        if (!staleParams.contextObj) {
            return false;
        }
        const documentEntity: DocumentEntity | undefined = await this.getDocumentMeta(staleParams.contextObj, true);
        if (documentEntity && documentEntity.versionSeriesCheckedOutBy) {
            staleParams.message = this._messageService.compileTemplate(Locale.stale.checkedout_document, {
                lockedBy: documentEntity.versionSeriesCheckedOutBy,
            });
            this.handleErrorMessage(staleParams);
            return true;
        }
        return false;
    }

    public transformValidationResponse(response: DCResponse): DCResponse {
        let index = 0;
        const errorInfo = {};
        if (response.status !== HttpStatusCode.FORBIDDEN && response.statusInfo?.errors) {
            for (const [key, value] of Object.entries(response.statusInfo.errors)) {
                if (key && value) {
                    const obj = {};
                    obj[index] = [key !== 'default' ? key : '', value];
                    Object.assign(errorInfo, obj);
                    index++;
                }
            }
        }
        response.statusInfo = { errors: errorInfo };
        return response;
    }

    private async isDocumentCheckedIn(staleParams: StaleValidationParams): Promise<boolean> {
        if (!staleParams.contextObj) {
            return false;
        }
        const documentEntity: DocumentEntity | undefined = await this.getDocumentMeta(staleParams.contextObj, true);
        if (documentEntity && !documentEntity.versionSeriesCheckedOutBy) {
            this.handleErrorMessage(staleParams);
            return true;
        }
        return false;
    }

    private async isEmailAvailable(staleParams: StaleValidationParams): Promise<boolean> {
        if (!staleParams.contextObj) {
            return false;
        }
        const documentEntity: DocumentEntity | undefined = await this._emailService.getEmailMeta(
            staleParams.contextObj,
        );
        if (!documentEntity) {
            this.handleErrorMessage(staleParams);
            return false;
        }
        return true;
    }

    public showToast(type: ToastType, message: string, onlyThisInstance = false): string {
        this._eventService.publish({
            name: EventType.TOAST,
            toast: {
                type: type,
                toastMessage: message,
            },
            onlyThisInstance,
        });
        log.warn(message);
        return message;
    }

    public splitDocumentsAndEmails(paths: string[], contextObj: ICHUploadType): SplitDocumentsAndEmails {
        const splitDocumentsAndEmails: SplitDocumentsAndEmails = { emails: [], documents: [] };
        for (const path of paths) {
            const fileExtension = this._officeService.getExtension(path.toLowerCase());
            if (
                contextObj.associatedEntityType != EntityTypes.Invoice &&
                (fileExtension == FileExtensions.Msg || fileExtension == FileExtensions.Eml)
            ) {
                splitDocumentsAndEmails.emails.push(path);
            } else {
                splitDocumentsAndEmails.documents.push(path);
            }
        }
        log.debug(
            `no of dropped emails/documents: ${splitDocumentsAndEmails.emails.length}/${splitDocumentsAndEmails.documents.length} `,
        );
        return splitDocumentsAndEmails;
    }
}
