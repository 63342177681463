import { ITransformer } from './types';
import { ICHUploadType } from '../interfaces/operations';
import { ICheckIn } from '@wk/elm-uui-doc-component';
import 'reflect-metadata';
import { injectable } from 'inversify';

@injectable()
export class CtxCheckInDocumentTransformer implements ITransformer {
    data!: ICHUploadType;

    transform(): ICheckIn {
        const nodeObj = {} as ICheckIn;
        nodeObj.entityContext = {
            name: this.data?.associatedEntityName || '',
            id: this.data?.associatedEntityId,
            type: this.data?.associatedEntityType,
        };

        if (this.data?.file) {
            nodeObj.file = this.data.file;
        }

        if (this.data?.fileId) {
            nodeObj.fileId = this.data?.fileId;
        }

        const extendedProps = <ICheckIn['extendedProps']>{};
        if (this.data?.props) {
            extendedProps.comment = this.data?.props?.comments;
            extendedProps.keepCheckedOut = this.data?.props?.keepCheckedOut;
            extendedProps.contentType = this.data?.props?.contentType;
        }
        nodeObj.extendedProps = extendedProps;
        if (this.data?.name) {
            nodeObj.name = this.data?.name;
        }

        if (this.data?.folderPath) {
            nodeObj.folderPath = this.data?.folderPath;
        }

        if (this.data?.documentId) {
            nodeObj.docId = this.data?.documentId;
        }

        if (this.data?.payload) {
            nodeObj.extendedProps.comment = this.data?.payload?.comments?.value;
            nodeObj.name = this.data?.payload?.name?.value;
            nodeObj.documentName = this.data?.payload?.documentName?.value;
        }

        if (this.data.fileName) {
            nodeObj.documentName = this.data.fileName;
        }
        return nodeObj;
    }
}
