import React from 'react';
import { useReduxDispatch } from '../../hooks/useReduxDispatch';
import { useUUIHistory } from '../../hooks/useUUIHistory';
import { useUUILocation } from '../../hooks/useUUILocation';
import IconMapper from '../common/iconMapper';
import { UUIFlyoutBreadcrumbButton, UUIFlyoutMenu, UUIFlyoutMenuItem } from '../common/uuiFlyoutMenu';
import { dispatchLoadFolderFromBreadcrumb } from './context/listScreenAsyncActions';
import { useListScreenDispatch, useListScreenState } from './context/listScreenContext';
import { IFolderedListBreadCrumb } from './types';

interface IListScreenBreadCrumbsMenuProps {
    breadCrumb: IFolderedListBreadCrumb;
}

const ListScreenBreadCrumbsMenu: React.FC<IListScreenBreadCrumbsMenuProps> = ({ breadCrumb }) => {
    const listScreenDispatch = useListScreenDispatch();
    const listScreenState = useListScreenState();
    const reduxDispatch = useReduxDispatch();
    const location = useUUILocation();
    const history = useUUIHistory();

    // wrap the BC's redirect to capture its scope
    const breadCrumbRedirect = (breadCrumbObj = breadCrumb) => {
        dispatchLoadFolderFromBreadcrumb(
            breadCrumbObj,
            listScreenState,
            listScreenDispatch,
            reduxDispatch,
            location,
            history,
        );
    };

    return (
        <UUIFlyoutMenu
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            enableScrollbarForLongContent={true}>
            <UUIFlyoutBreadcrumbButton
                redirect={breadCrumbRedirect}
                data-testid="listscreen-breadcrumb-menu-button"
                id="rootBreadCrumb">
                {breadCrumb.folderName === '...' ? <IconMapper iconName="iconellipsis" /> : breadCrumb.folderName}
            </UUIFlyoutBreadcrumbButton>

            {listScreenState.breadCrumbs.map((breadCrumbItem, i) =>
                i !== listScreenState.breadCrumbs.length - 1 ? (
                    <UUIFlyoutMenuItem
                        key={i}
                        data-testid="listscreen-breadcrumb-menu-item"
                        onClick={() => {
                            breadCrumbRedirect(breadCrumbItem);
                        }}>
                        <div style={{ paddingLeft: i * 7 + 'px' }}>{breadCrumbItem.folderName}</div>
                    </UUIFlyoutMenuItem>
                ) : (
                    <UUIFlyoutMenuItem key={i} data-testid="listscreen-breadcrumb-menu-item" disabled={true}>
                        <div style={{ paddingLeft: i * 7 + 'px', color: 'rgba(0, 0, 0, 0.87)' }}>
                            {breadCrumbItem.folderName}
                        </div>
                    </UUIFlyoutMenuItem>
                ),
            )}
        </UUIFlyoutMenu>
    );
};

export default ListScreenBreadCrumbsMenu;
