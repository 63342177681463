(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("reflect-metadata"), require("@wk/elm-uui-common"), require("rxjs"), require("uuid"));
	else if(typeof define === 'function' && define.amd)
		define(["reflect-metadata", "@wk/elm-uui-common", "rxjs", "uuid"], factory);
	else if(typeof exports === 'object')
		exports["uuiQueueManager"] = factory(require("reflect-metadata"), require("@wk/elm-uui-common"), require("rxjs"), require("uuid"));
	else
		root["uuiQueueManager"] = factory(root["reflect-metadata"], root["@wk/elm-uui-common"], root["rxjs"], root["uuid"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__2150__, __WEBPACK_EXTERNAL_MODULE__2494__, __WEBPACK_EXTERNAL_MODULE__3832__, __WEBPACK_EXTERNAL_MODULE__459__) => {
return 