import { injectable } from 'inversify';
import { baseCommunicationService } from '@wk/office-companion-js-common';
import { catchError, map, Observable, throwError } from 'rxjs';
import { ComBridgeResponse, ComObject, GetContextData } from './comObject';
import { ComError, ComErrorData, ComErrorScope } from './comError';
import { ComObjectChannelEnum } from './comObjectChannel.enum';
import { LoggerService } from '../../../common/logger/logger.service';
import { BaseResponseException } from '@wk/office-companion-js-common';

@injectable()
export class ComBridgeService {
    constructor(private logger: LoggerService) {}

    public getContext(data: GetContextData): Observable<ComObject> {
        return baseCommunicationService.invoke<ComBridgeResponse>(ComObjectChannelEnum.GetContext, data).pipe(
            map(({ data }) => {
                return new ComObject(this.logger, data.value?.objectRefValue);
            }),
            catchError((error: BaseResponseException<Record<string, unknown> | string, ComErrorData | string>) => {
                return throwError(
                    () =>
                        new ComError({
                            ...error,
                            message: error.message,
                            scope: ComErrorScope.ComBridge,
                            innerException: error,
                        }),
                );
            }),
        );
    }
}
