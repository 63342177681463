import { LogLevel } from '@wk/elm-uui-common';
import { IAppResources, ILogLevel } from '../../reducers/types';

export const getLogLevels = (appResources: IAppResources): ILogLevel[] => {
    return [
        { key: LogLevel.Error, value: appResources.helpPageError },
        { key: LogLevel.Warn, value: appResources.helpPageWarn },
        { key: LogLevel.Info, value: appResources.helpPageInfo },
        { key: LogLevel.Debug, value: appResources.helpPageDebug },
    ];
};

export const getILogLevelFromKey = (appResources: IAppResources, logLevel: LogLevel): ILogLevel => {
    const logLevels = getLogLevels(appResources);
    const iLogLevel = logLevels.find((level) => level.key.toString() === logLevel.toString());

    return iLogLevel ? iLogLevel : logLevels[0];
};

export const getLogLevelByIndex = (appResources: IAppResources, index: number): ILogLevel => {
    const logLevels = getLogLevels(appResources);
    return logLevels[index];
};
