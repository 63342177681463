import { UUIFetch } from '@wk/elm-uui-common';
import 'isomorphic-fetch';
import { factory } from '../ConfigLog4j';
import { UNEXPECTED_ERROR } from '../constants';
import { IActionableEntity } from '../interfaces/actionEntity/type';
import { DCResponse, UnExpectedError } from '../types';
import { handleUnExpectedFetchError, UnExpectedResponseCodes } from '../utils/shared.utils';

const log = factory.getLogger('API/fetchUtils');

async function updateResponse(response: Response): Promise<DCResponse> {
    let errorJson: { Message: string } | { Failures: Record<string, string>[] } = { Message: '' };
    if (!response.ok) {
        const respStatusAvailable = UnExpectedResponseCodes.find((statusCode) => {
            return response.status == statusCode;
        });
        // If any of the response is present we throw Error
        if (respStatusAvailable) {
            log.error('Throwing error for  ' + UnExpectedResponseCodes.toString() + ' already handled by UUI commons');
            throw new UnExpectedError(UNEXPECTED_ERROR);
        }
        try {
            errorJson = await response.json();
            log.debug('Received Error:' + JSON.stringify(errorJson));
        } catch (error) {
            log.debug('Received error in response json' + error);
        }
        const dcResponse = response as DCResponse;
        if ('Message' in errorJson) {
            dcResponse.statusInfo = { errors: { default: errorJson.Message } };
        } else if ('Failures' in errorJson) {
            const errorsObj = {};
            errorJson.Failures.map((errorVal) => {
                const obj1: Record<string, string> = {};
                obj1[errorVal['PropertyName']] = errorVal['ErrorMessage'];
                Object.assign(errorsObj, obj1);
            });
            dcResponse.statusInfo = { errors: errorsObj };
        } else {
            dcResponse.statusInfo = { errors: {} };
        }
        return dcResponse;
    } else {
        return response as DCResponse;
    }
}

const fetchPostTokenOptions = (token: string) => {
    return {
        credentials: 'same-origin',
        // agent,
        headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: token,
        }),
        method: 'post',
    };
};

const fetchPutTokenOptions = (token: string) => {
    return {
        credentials: 'same-origin',
        // agent,
        headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: token,
        }),
        method: 'put',
    };
};

export const apiFetchGet = (url: string, _: IActionableEntity, token: string): Promise<DCResponse> => {
    const options = {
        method: 'GET',
        // agent,
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    };

    return UUIFetch.fetch(url, options)
        .then((response) => {
            return updateResponse(response);
        })
        .catch((err) => {
            handleUnExpectedFetchError(err);
            throw err;
        });
};

export const apiFetchDelete = (
    url: string,
    fetchPostData: Record<string, string>,
    token: string,
): Promise<DCResponse> => {
    const options = {
        method: 'DELETE',
        body: JSON.stringify(fetchPostData),
        // agent,
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    };

    return UUIFetch.fetch(url, options)
        .then((response) => {
            return updateResponse(response);
        })
        .catch((err) => {
            handleUnExpectedFetchError(err);
            throw err;
        });
};

// if fetchPostData is provided, then it will POST, otherwise it will GET
export function apiFetchForAccessToken(urlQuery: string, qs: Record<string, string>): Promise<DCResponse> {
    const params = qs;
    const query = Object.keys(qs)
        .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');

    const fetchParameters = {
        // agent,
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
        }),
        method: 'post',
    };
    return UUIFetch.fetch(urlQuery + '?' + query, fetchParameters)
        .then((response) => {
            return updateResponse(response);
        })
        .catch((err) => {
            handleUnExpectedFetchError(err);
            throw err;
        });
}

// if fetchPostData is provided, then it will POST, otherwise it will GET
export function apiFetchForT360AccessToken(
    urlQuery: string,
    qs: Record<string, string>,
    token: string,
): Promise<DCResponse> {
    const params = qs;
    const query = Object.keys(qs)
        .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');
    const fetchParameters = {
        // agent,
        body: query,
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: token,
        }),
        method: 'post',
    };

    return UUIFetch.fetch(urlQuery, fetchParameters)
        .then((response) => {
            return updateResponse(response);
        })
        .catch((err) => {
            handleUnExpectedFetchError(err);
            throw err;
        });
}

// if fetchPostData is provided, then it will POST, otherwise it will GET
export function apiFetchPost(
    url: string,
    fetchPostData: IActionableEntity,
    token: string,
    _json = true,
    _method?: string,
): Promise<DCResponse> {
    const postHeaders = fetchPostTokenOptions(token);
    const fetchParameters = fetchPostData
        ? ({ ...postHeaders, body: JSON.stringify(fetchPostData) } as RequestInit)
        : ({ credentials: 'same-origin' } as RequestInit);

    return UUIFetch.fetch(url, fetchParameters)
        .then((response) => {
            return updateResponse(response);
        })
        .catch((err) => {
            handleUnExpectedFetchError(err);
            throw err;
        });
}

export function apiFetchPut(url: string, fetchPostData: IActionableEntity, token: string): Promise<DCResponse> {
    const postHeaders = fetchPutTokenOptions(token);
    const fetchParameters = fetchPostData
        ? ({ ...postHeaders, body: JSON.stringify(fetchPostData) } as RequestInit)
        : ({ credentials: 'same-origin' } as RequestInit);

    log.debug('putting for the url' + url);

    return UUIFetch.fetch(url, fetchParameters)
        .then((response) => {
            return updateResponse(response);
        })
        .catch((err) => {
            handleUnExpectedFetchError(err);
            throw err;
        });
}
