import { injectable } from 'inversify';
import { map, Observable } from 'rxjs';
import { baseCommunicationService } from '@wk/office-companion-js-common';

enum MessageBarAction {
    Open = 'open-message-bar',
}

export interface MessageBarInterface {
    /**
     * Shows/Opens message bar in the office application
     *
     * @param params Options to initialize new message bar in the office application, please refer to {@link MessageBarOptions}
     *
     * @returns Observable which sends {@link MessageBarNotification | notification} object
     *  when one of the supported events is triggered for corresponding message bar instance
     *  Unsubscribe from this observable subscription to close message bar
     */
    show(params: MessageBarOptions): Observable<MessageBarNotification>;
}

/**
 * Arguments for {@link MessageBarService.show}
 */
export interface MessageBarOptions {
    /**
     * Text is presented on message bar as highlighted text
     */
    title?: string;

    /**
     * Text is presented on message bar as main content
     */
    message: string;

    /**
     * Icon presented on message bar as an additional attraction sign (ex.: warning)
     */
    icon?: string;

    /**
     * Buttons rendered on message bar
     *
     *  __Important__:
     *
     * Click on a button can be handled by subscribing to {@link MessageBarService.show}
     * returned observable and checking for {@link MessageBarNotification.buttonClicked} property
     */
    buttons?: MessageBarButton[];
}

export interface MessageBarButton {
    /**
     * Button id (not visible on message bar). Used to buttons clicked notifications
     */
    id: string;

    /**
     * Text rendered on message bar button
     */
    label: string;

    // todo: add doc here
    icon?: string;

    /**
     * Text to display in a message bar when button operation has started. If not set, a default text is used.
     */
    status?: string;
}

export interface MessageBarButtonClicked {
    /**
     * Button id (not visible on message bar). Used to buttons clicked notifications
     */
    id: string;

    /**
     * Button label rendered on message bar interaction elements
     */
    label?: string;
}

export interface MessageBarNotification {
    /**
     *  Contains information about `button click` event
     *
     *  Not empty when a user has clicked a button on message bar
     */
    buttonClicked?: MessageBarButtonClicked;

    /**
     *  Contains information about `message bar - opened` event
     *
     *  Not empty when message bar has been opened
     */
    created?: Record<string, unknown>;

    /**
     *  Contains information about `message bar - closed` event
     *
     *  Not empty when message bar has been closed
     */
    closed?: Record<string, unknown>;
}

@injectable()
export class MessageBarService implements MessageBarInterface {
    public show(params: MessageBarOptions): Observable<MessageBarNotification> {
        return baseCommunicationService
            .on<MessageBarNotification>(MessageBarAction.Open, params)
            .pipe(map(({ data }) => data));
    }
}
