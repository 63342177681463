import React from 'react';
import { UUIFlyoutMenu, UUIFlyoutMenuButton, UUIFlyoutMenuItem } from '../common/uuiFlyoutMenu';
import WkFilterSettingsIcon from '../icons/wkFilterSettingsIcon';
import { useListScreenDispatch, useListScreenState } from './context/listScreenContext';
import css from './listScreenActionMenu.module.scss';
import { getNonBulkOperations } from './listScreenHelpers';
import { dispatchListScreenNonBulkOperation } from './context/listScreenAsyncActions';
import { useRefreshList } from './context/listScreenHooks';
import { useReduxSelector } from '../../hooks/useReduxSelector';
import { useReduxDispatch } from '../../hooks/useReduxDispatch';
import { useUUIHistory } from '../../hooks/useUUIHistory';
import WkAddIcon from '../icons/wkAddIcon';

const ListScreenActionMenu: React.FC = () => {
    const appResources = useReduxSelector((state) => state.appResources);
    const applicationUrls = useReduxSelector((state) => state.applicationUrls);
    const history = useUUIHistory();
    const listScreenState = useListScreenState();
    const listScreenDispatch = useListScreenDispatch();
    const reduxDispatch = useReduxDispatch();
    const refreshList = useRefreshList();
    let nonBulkOperations = getNonBulkOperations(listScreenState.metadata, listScreenState.mode);
    if (!listScreenState.isEmbeddedList) {
        nonBulkOperations = nonBulkOperations.slice(1);
    }
    return (
        <UUIFlyoutMenu
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}>
            <UUIFlyoutMenuButton data-testid="listscreen-action-menu-button" rootClass={css.button}>
                <div className={css.actionLabel}>{appResources.buttonActions}</div>
            </UUIFlyoutMenuButton>
            <UUIFlyoutMenuItem
                icon={<WkFilterSettingsIcon />}
                data-testid="listscreen-action-menu-item"
                onClick={() => {
                    listScreenDispatch({ type: 'OpenListScreenEditDialog' });
                }}>
                {appResources.buttonFilterCustomizeTabs}
            </UUIFlyoutMenuItem>
            {nonBulkOperations.map((op) => (
                <UUIFlyoutMenuItem
                    icon={<WkAddIcon className={css.addIcon} viewBox="0 0 16 16" />}
                    key={op.id}
                    onClick={() =>
                        dispatchListScreenNonBulkOperation(
                            op.id,
                            listScreenState,
                            applicationUrls,
                            history,
                            reduxDispatch,
                            refreshList,
                        )
                    }
                    data-testid={`listscreen-action-menu-item-operation${op.id}`}>
                    {op.displayName}
                </UUIFlyoutMenuItem>
            ))}
        </UUIFlyoutMenu>
    );
};

export default ListScreenActionMenu;
