import { ITransformer } from './types';
import { ICHUploadType } from '../interfaces/operations';
import { IAddEmail } from '@wk/elm-uui-doc-component';
import 'reflect-metadata';
import { injectable } from 'inversify';

@injectable()
export class CtxAddEmailTransformer implements ITransformer {
    data!: ICHUploadType;

    transform(): IAddEmail {
        const nodeObj = {} as IAddEmail;
        nodeObj.entityContext = {
            name: this.data.associatedEntityName || '',
            id: this.data.associatedEntityId,
            type: this.data.associatedEntityType,
        };

        if (this.data.file) {
            nodeObj.file = this.data.file;
        }

        if (this.data?.folderPath) {
            nodeObj.folderPath = this.data.folderPath;
        }

        // let extendedProps = <IAddDocument["extendedProps"]>{};

        // if (this.data.props?.hasOwnProperty("description")) {
        //   extendedProps["description"] = this.data.props.description;
        // }
        if (this.data?.file) {
            nodeObj.file = this.data.file;
        }
        if (this.data?.folderId) {
            nodeObj.documentFolderId = this.data.folderId;
            nodeObj.folderPath = this.data.folderId;
        }
        if (this.data?.props) {
            nodeObj.extendedProps = this.data.props;
        }
        if (this.data?.name) {
            nodeObj.name = this.data.name;
        }
        if (this.data?.fileId) {
            nodeObj.fileId = this.data?.fileId;
        }
        return nodeObj;
    }
}
