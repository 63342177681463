import { ClientObject, PropertyInit } from './clientObject';
import { OfficeContextScope } from './officeContextScope';
import { ReferenceObject } from './referenceObject';

export class OfficeContext extends ClientObject {
    constructor(protected scope: OfficeContextScope, protected reference: ReferenceObject) {
        super(reference);
    }

    public async sync(): Promise<void> {
        await this.scope.resolve();
    }

    public load<T extends ClientObject, K extends keyof T>(object: T & PropertyInit<T>, propertyNames: K | K[]): void {
        this.scope.requestInitProperties(object, propertyNames);
    }
}
