import 'reflect-metadata';
import { serviceWorkerRegistration } from '../../serviceWorker/service-worker.registration';
import { InitializePassport } from './components/InitializePassport';
import './config/contentSecurityPolicy.service';
import { ConnectivityService } from './infrastructure/connectivityService';
import { LoggingService } from './infrastructure/loggingService';
import { MessageService } from './infrastructure/messageService';

declare global {
    interface Window {
        OCApplicationType: string;
    }
}

serviceWorkerRegistration.register();

const init = (): void => {
    LoggingService.initializeLoggerFactory();
    MessageService.getInstance().subscribe();
    void ConnectivityService.subscribeToUnauthorizedEvent();

    InitializePassport();
};

if (!window.ocInfo) {
    init();
} else {
    window.ocInfo
        .applicationType()
        .then((appType) => {
            window.OCApplicationType = appType;
            init();
        })
        .catch(() => {
            init();
        });
}
