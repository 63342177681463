import { IInputValidator } from '../../interfaces/inputValidators/type';
import { injectable } from 'inversify';
import { IValidationInfo } from '../../dmInfoHandler/types';
// import { ValidationUtils } from '../../libraries/validationUtils';
// import { isNodeEnvironment } from '../../utils';
import { IAddDocument } from '../../docManager';
import { IAddDocument_JR } from '../types';
import { DCValidationError } from '../../customError/docComponentError';

@injectable()
export class JRAdapterAddDocumentInputValidator implements IInputValidator {
    validationInfo: IValidationInfo;
    performValidation = (nodeObj: IAddDocument): boolean => {
        const nodeObj1 = nodeObj as IAddDocument_JR;

        // const validator = new ValidationUtils();
        // const fileName = nodeObj1.name;
        // const file = nodeObj1?.file;
        // const folderPath = nodeObj1?.folderPath + '/' + fileName;

        // const extendedProps = nodeObj1?.extendedProps;

        // let fileSize;
        if (!nodeObj1.file) {
            //   if (isNodeEnvironment() && file instanceof Buffer) {
            //     fileSize = file.length;
            //   } else {
            //     fileSize = (file as Blob).size;
            //   }
            // } else {
            throw new DCValidationError('Error in JR add Document, file is missing in input ');
        }

        if (
            nodeObj1?.name
            // validator.checkSpecialCharacters(fileName, this.validationInfo) &&
            // validator.checkDocumentFileSize(fileSize, validator.getFileSize(this.validationInfo, extendedProps)) &&
            // validator.checkFileType(fileName, this.validationInfo) &&
            // validator.checkPathLength(folderPath, this.validationInfo)
        ) {
            return true;
        } else {
            throw new DCValidationError(
                'JR Passport. Add Document Input validation. Params: Name: ' +
                    nodeObj1?.name +
                    ' Folder Path: ' +
                    nodeObj1?.folderPath,
            );
        }
    };
}
