import React from 'react';
import WkEditIcon from '../icons/wkEditIcon';
import WkDeleteIcon from '../icons/wkDeleteIcon';
import WkDownloadIcon from '../icons/wkDownloadIcon';
import WkViewProfileIcon from '../icons/wkViewProfileIcon';
import WkViewInDesktopIcon from '../icons/wkViewInDesktopIcon';
import WkCheckInIcon from '../icons/wkCheckInIcon';
import WkCheckOutIcon from '../icons/wkCheckOutIcon';
import WkUndoCheckOutIcon from '../icons/wkUndoCheckOutIcon';
import WkEmailIcon from '../icons/wkEmailIcon';
import WkShowIcon from '../icons/wkShowIcon';
import WkCloudCheckmarkIcon from '../icons/wkCloudCheckmarkIcon';
import WkCloudDownArrowIcon from '../icons/wkCloudDownArrowIcon';
import WkTriangleExclamationIcon from '../icons/wkTriangleExclamationIcon';
import WkHelpIcon from '../icons/wkHelpIcon';
import WkLogOutIcon from '../icons/wkLogOutIcon';
import WkCloseIcon from '../icons/wkCloseIcon';
import WkAddIcon from '../icons/wkAddIcon';
import WkOpenEmailIcon from '../icons/wkOpenEmailIcon';
import css from './iconMapper.module.scss';
import WkEllipsisIcon from '../icons/wkEllipsisIcon';
import { WkOpenInDesktopIcon } from '../icons/wkOpenInDesktopIcon';
import WkOpenCheckCircleIcon from '../icons/wkOpenCheckCircleIcon';
import WkCloseCircleIcon from '../icons/wkCloseCircleIcon';
import { WkAdjustIcon } from '../icons/wkAdjustIcon';

interface IIconMapper {
    iconName: string;
    customClass?: string;
}

export const iconMapping: { [key: string]: React.ReactElement } = {
    iconhelp: <WkHelpIcon />,
    iconlogout: <WkLogOutIcon />,
    iconpencil: <WkEditIcon />,
    iconemail: <WkEmailIcon style={{ width: '16px', height: '16px' }} />,
    icondoccheckin: <WkCheckInIcon />,
    icondoccheckout: <WkCheckOutIcon />,
    icondelete: <WkDeleteIcon />,
    icondocdownload: <WkDownloadIcon />,
    iconeye: <WkViewProfileIcon />,
    iconarrowleft: <WkUndoCheckOutIcon />,
    icondocundocheckout: <WkUndoCheckOutIcon />,
    iconcolorswatch: <WkViewInDesktopIcon />,
    icondocshow: <WkShowIcon style={{ verticalAlign: 'middle', marginTop: '2px' }} />,
    iconcloudcheckmark: <WkCloudCheckmarkIcon style={{ fontSize: '175px', width: '175px' }} />,
    iconclouddownarrow: <WkCloudDownArrowIcon style={{ fontSize: '175px', width: '175px' }} />,
    icontriangleexclamation: <WkTriangleExclamationIcon style={{ fontSize: '175px', width: '175px' }} />,
    iconClose: <WkCloseIcon />,
    iconadd: <WkAddIcon className={css.addIcon} viewBox="0 0 16 16" />,
    iconellipsis: <WkEllipsisIcon className={css.ellipsisIcon} viewBox="0 0 13 3" />,
    iconopenindesktop: <WkOpenInDesktopIcon className={css.openInDesktopIcon} />,
    iconopenemail: <WkOpenEmailIcon />,
    iconinvoiceapprove: <WkOpenCheckCircleIcon />,
    iconinvoicereject: <WkCloseCircleIcon />,
    iconadjust: <WkAdjustIcon />,
};

const IconMapper: React.FC<IIconMapper> = ({ iconName, customClass }) => {
    const iconComponent = iconMapping[iconName?.trim().toLocaleLowerCase()];
    return iconComponent
        ? React.cloneElement(
            iconComponent,
            customClass
                ? {
                    className: customClass,
                }
                : {},
        )
        : null;
};

export default IconMapper;
