import { IQueueItem, QueueItemStatus } from '@wk/elm-uui-context-handler';
import { UUIReduxAction } from './types';

export const uploadHistoryReducer = (state: IQueueItem[], action: UUIReduxAction): IQueueItem[] => {
    switch (action.type) {
        case 'SetUploadHistoryItems':
            {
                // Remove all history items that have the given status,
                // as they will be replaced with the given items.
                // Also remove any cancelled items.
                const newState = state.filter(
                    (item) => item.status !== action.status && item.status !== QueueItemStatus.CANCELLED,
                );
                return newState.concat(action.uploadHistoryItems);
            }
            break;
        case 'RemoveHistoryItems':
            return state.filter((item) => !action.listItems.some((i: IQueueItem) => i.uniqueId === item.uniqueId));
            break;
        case 'RemoveAllCancelledHistoryItems':
            return state.filter((item) => item.status !== QueueItemStatus.CANCELLED);
            break;
    }
    return state;
};
