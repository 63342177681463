import { ClickAwayListener, MenuList } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { useReduxSelector } from '../../hooks/useReduxSelector';
import MenuHeader from './menuHeader';
import css from './menuScreen.module.scss';
import MultiLevelMenu from './multiLevelMenu';
interface IMenuScreen {
    toggleMainMenu: () => void;
}
const MenuScreen: React.FC<IMenuScreen> = ({ toggleMainMenu }) => {
    const appResources = useReduxSelector((state) => state.appResources);
    const quickLinks = useReduxSelector((state) => state.quickLinks);
    const menuItems = quickLinks.menuItems || [];
    const megaMenu = useReduxSelector((state) => state.megaMenu);
    const passportContexts = useReduxSelector((state) => state.appsMenu.passportContexts);
    const menuActiveContext = useReduxSelector((state) => state.appsMenu.activeContext);
    const activeScreenId = useReduxSelector((state) => state.ui.globalState.activeScreenId);
    const activePassportContext = passportContexts.find(
        (passportContext: any) => passportContext.id === menuActiveContext,
    )!;
    // Quick Links
    const quickLinksMenu = menuItems.filter((item) => item.passportContexts.includes(activePassportContext.id));
    const isActiveScreenFoundInQuickLinks = quickLinksMenu.some((item) => item.screenId === Number(activeScreenId));
    const index = 0;
    let openMenu = { [`${index}`]: true };
    // Mega Menu
    const filteredMegaMenu = useMemo(() => {
        const megaMenuItem: any = [];
        megaMenu.map((column: any) => {
            column.children?.map((menu: any) => {
                if (menu.passportContexts.includes(activePassportContext.id)) {
                    megaMenuItem.push(menu);
                }
            });
        });
        return megaMenuItem;
    }, [activePassportContext.id, megaMenu]);
    if (filteredMegaMenu.length && !isActiveScreenFoundInQuickLinks) {
        const [highlightedMenu] = filteredMegaMenu.filter((menuItem: any) => {
            return menuItem.children?.some((childItem: any) => childItem.screenId === Number(activeScreenId));
        });
        if (highlightedMenu) {
            openMenu = { ...openMenu, [`${highlightedMenu.id}`]: true };
        }
    }
    const [open, setOpen] = useState(openMenu);

    function handleClick(index: any) {
        setOpen({ [`${index}`]: !open[index] });
    }
    return (
        <>
            <ClickAwayListener
                onClickAway={() => {
                    toggleMainMenu();
                }}>
                <div className={css.drawerContainer}>
                    <MenuHeader activePassportContext={activePassportContext} toggleDrawer={toggleMainMenu} />
                    <div className={css.mainMenu} role="presentation">
                        <MenuList className={css.outerContainer}>
                            {quickLinksMenu.length > 0 && (
                                <MultiLevelMenu
                                    displayValue={appResources.quickLinks}
                                    section={quickLinksMenu}
                                    open={open[0]}
                                    handleMenuSelection={() => handleClick(0)}
                                    menuId={0}
                                    toggleDrawer={toggleMainMenu}
                                    dataTestId={isActiveScreenFoundInQuickLinks ? 'activeQuickLinks' : 'quickLinks'}
                                    className={
                                        isActiveScreenFoundInQuickLinks && open[0] ? css.activeLink : css.unActiveLink
                                    }
                                />
                            )}

                            {filteredMegaMenu.map((section: any, i: number) => {
                                const isExpandable = section.children && section.children.length >= 0;
                                const children = isExpandable
                                    ? section.children.filter((item: any) => !item.isDivider)
                                    : [];
                                const isActiveScreenFoundInMegaMenu = children.some(
                                    (item: any) => item.screenId === Number(activeScreenId),
                                );

                                return (
                                    children.length > 0 && (
                                        <MultiLevelMenu
                                            key={i}
                                            dataTestId={
                                                isActiveScreenFoundInMegaMenu && open[section.id]
                                                    ? 'active-menu-item'
                                                    : 'menu-item'
                                            }
                                            megaMenu={true}
                                            displayValue={section.displayValue}
                                            section={children}
                                            open={open[section.id]}
                                            handleMenuSelection={() => handleClick(section.id)}
                                            menuId={section.id}
                                            toggleDrawer={toggleMainMenu}
                                            className={
                                                isActiveScreenFoundInMegaMenu && open[section.id]
                                                    ? css.activeLink
                                                    : css.unActiveLink
                                            }
                                        />
                                    )
                                );
                            })}
                        </MenuList>
                    </div>
                </div>
            </ClickAwayListener>
        </>
    );
};
export default MenuScreen;
