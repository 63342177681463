export const queueSchemaNoAttachments = {
    title: 'queue schema',
    description: 'describes a simple queue',
    version: 0,
    type: 'object',
    primaryKey: 'uniqueId',
    properties: {
        uniqueId: {
            type: 'string',
        },
        queue_item_unique_key: {
            type: 'string',
        },
        metaData: {
            type: 'object',
        },
        filePath: {
            type: 'string',
        },
        priority: {
            type: 'number',
        },
        retryCount: {
            type: 'number',
        },
        maxRetries: {
            type: 'number',
        },
        data: {
            type: 'object',
        },
        service: {
            type: 'string',
        },
        operation: {
            type: 'string',
        },
        markedForDelete: {
            type: 'boolean',
        },
        functionCallDetails: {
            type: 'string',
        },
        status: {
            type: 'string',
        },
        response: {
            type: 'array',
        },
        processIndicator: {
            type: 'string',
        },
        addedTime: {
            type: 'number',
        },
        error: {
            type: 'array',
        },
        hasExternalAttachment: {
            type: 'boolean',
        },
        created: {
            type: 'string',
            format: 'date-time',
        },
        location: {
            type: 'array',
        },
        elementType: {
            type: 'string',
        },
        groupId: {
            type: 'string',
        },
        noOfItems: {
            type: 'number',
        },
        eventForwarded: {
            type: 'boolean',
        },
    },
    required: ['data', 'service', 'operation', 'priority', 'maxRetries'],
    indexes: ['priority'],
    attachments: {},
};

export const queueConfig = {
    title: 'queue config',
    description: 'describes a simple queue',
    version: 0,
    type: 'object',
    primaryKey: 'id',
    properties: {
        id: {
            type: 'string',
        },
        numConcurrentElements: {
            type: 'number',
        },
        maxRetries: {
            type: 'number',
        },
    },
    required: ['numConcurrentElements', 'maxRetries'],
    indexes: ['id'],
};

export const queueProps = {
    title: 'setting Props',
    description: 'setting props',
    version: 0,
    type: 'object',
    primaryKey: 'uniqueId',
    properties: {
        uniqueId: {
            type: 'string',
        },
        key: {
            type: 'string',
        },
        value: {
            type: 'string',
        },
    },
    required: ['key', 'value'],
    indexes: ['uniqueId'],
};
