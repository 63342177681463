import { IDocTransformer } from '../../interfaces/transformer/type';
import { injectable } from 'inversify';
import { IMatterDoc, IDocumentMetadata } from '../../types';
import { T360AdapterDocumentMetadataActionableEntity } from '../actionableEntity/documentMetadataActionableEntity';
import { IDocOperationInvocation } from '../../interfaces/urlPattern/types';

@injectable()
export class T360AdapterDocumentMetadataEntityTransformer implements IDocTransformer {
    transformIntoActionableEntity(
        nodeObj: IDocumentMetadata,
        _matterDoc: IMatterDoc,
        _urlPattern: IDocOperationInvocation,
    ): T360AdapterDocumentMetadataActionableEntity {
        const obj = {} as T360AdapterDocumentMetadataActionableEntity;

        if (!(nodeObj?.extendedProps && nodeObj?.extendedProps?.version)) {
            obj.version = '1';
        }

        // TO CREATE ACTIONABLE ENTITY FROM nodeObj and matterDoc
        return obj;
    }
}
