export class DocComponentError extends Error {
    constructor(message?: string) {
        super(message);
        this.message = String(message);
        this.name = this.constructor.name;
        Error.captureStackTrace(this, this.constructor);
    }
}

export class DCValidationError extends DocComponentError {
    constructor(message?: string) {
        super('Validation failed for ${message}');
        this.message = String(message);
        this.name = 'DCValidationError';
    }
}

export class DCTransformerError extends DocComponentError {
    constructor(message?: string) {
        super('Transformation failed for ${message}');
        this.message = String(message);
        this.name = 'DCTransformerError';
    }
}

export class DCActionPerformerError extends DocComponentError {
    constructor(message?: string) {
        super('Action failed for ${message}');
        this.message = String(message);
        this.name = 'DCActionPerformerError';
    }
}

export class DCFetchError extends DocComponentError {
    constructor(message?: string) {
        super('Fetch Action failed for ${message}');
        this.message = String(message);
        this.name = 'DCFetchAction';
    }
}
