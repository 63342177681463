import { injectable } from 'inversify';
import { Observable } from 'rxjs';
import { AppVersionService, AutoUpdateStateChangeRequest, AutoUpdateValue } from './appVersion.service';
import { BaseCommunicationEvent } from '@wk/office-companion-js-common';
import { AppStorageService, AuthInfoDto, AuthInfoRequestDto } from './appStorage.service';
import { AppSettingsService, LogLevelDto, StoreFilePathDto } from './appSettings.service';
import { SpellCheckLanguageDto } from '.';

export interface ApplicationInterface {
    /**
     * Subscribe to auto-update stream
     *
     * Should subscribe to this stream in order to receive state change notifications or send state change request.
     *
     * @param dataStream request stream {@link Observable} of {@link AutoUpdateStateChangeRequest}
     *
     * @returns Observable of {@link AutoUpdateValue} which represent current auto-update state or request for client data
     */
    version(dataStream: Observable<AutoUpdateStateChangeRequest>): Observable<BaseCommunicationEvent<AutoUpdateValue>>;

    /**
     * Request credentials from RxDb replication
     *
     * @param request request data query of {@link AuthInfoRequestDto}
     *
     * @returns Observable of {@link AuthInfoDto} credential data
     */
    authStorage(request?: AuthInfoRequestDto): Observable<BaseCommunicationEvent<AuthInfoDto>>;

    /**
     * Updates application path where temporary files will be stored
     *
     * @param request request data query of {@link StoreFilePathDto}
     */
    updateStoreFilePath(request: StoreFilePathDto): Observable<BaseCommunicationEvent<void>>;

    /**
     * Updates Central-Process log level including OC_JS packages and .net add-in
     *
     * @param request request data query of {@link LogLevelDto}
     */
    updateLogLevel(request: LogLevelDto): Observable<BaseCommunicationEvent<void>>;

    /**
     * Updates users preferred spell check languages for oc browser
     *
     * @param request request data query of {@link SpellCheckLanguageDto}
     */
    updateSpellCheckLanguages(request: SpellCheckLanguageDto[]): Observable<BaseCommunicationEvent<void>>;
}

@injectable()
export class ApplicationService implements ApplicationInterface {
    constructor(
        private appVersionService: AppVersionService,
        private appStorageService: AppStorageService,
        private appSettingsService: AppSettingsService,
    ) {}

    public version(
        dataStream: Observable<AutoUpdateStateChangeRequest>,
    ): Observable<BaseCommunicationEvent<AutoUpdateValue>> {
        return this.appVersionService.versionStream(dataStream);
    }

    public authStorage(request: AuthInfoRequestDto): Observable<BaseCommunicationEvent<AuthInfoDto>> {
        return this.appStorageService.auth(request);
    }

    public updateStoreFilePath(request: StoreFilePathDto): Observable<BaseCommunicationEvent<void>> {
        return this.appSettingsService.updateStoreFilePath(request);
    }

    public updateLogLevel(request: LogLevelDto): Observable<BaseCommunicationEvent<void>> {
        return this.appSettingsService.updateLogLevel(request);
    }
    public updateSpellCheckLanguages(request: SpellCheckLanguageDto[]): Observable<BaseCommunicationEvent<void>> {
        return this.appSettingsService.updateSpellCheckLanguages(request);
    }
}
