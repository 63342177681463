import React from 'react';
import { Drawer } from '@material-ui/core';
import css from './bottomDrawer.module.scss';

type IBottomDrawer = React.PropsWithChildren<{
    className?: string;
}>;

const BottomDrawer = React.forwardRef<HTMLDivElement, IBottomDrawer>(
    ({ className, children }, ref: React.Ref<HTMLDivElement>) => {
        return (
            <Drawer
                variant="permanent"
                open={true}
                anchor="bottom"
                classes={{ paper: `MuiPaper-elevation3 ${css.bottomDrawer} ${className}` }}>
                <div ref={ref}>{children}</div>
            </Drawer>
        );
    },
);

BottomDrawer.displayName = 'BottomDrawer';

export default BottomDrawer;
