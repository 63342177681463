export const uniqueAlreadyPresent = 'Document already present in queue or already Added.Not adding';
export const Name = 'name';
export const DocId = 'docId';
export const Data = 'data';
export const File = 'file';
export const Type = 'type';
export const Attributes = 'attributes';
export const Id = 'id';
export const Description = 'description';
export const IsEmailMessage = 'isEmailMessage';
export const SenderEmail = 'senderEmail';
export const SentTime = 'sentTime';
export const SenderName = 'senderName';
export const ReceivedTime = 'receivedTime';
export const EmailRecipients = 'emailRecipients';
export const emailSubject = 'emailSubject';
export const EmailBody = 'emailBody';
export const IsAttachmentPresent = 'isAttachmentPresent';
export const UniqueId = 'uniqueId';
export const FolderPath = 'folderPath';
export const FolderPresent = 'isFolder';
export const ViewAccessType = 'viewAccessType';
export const EditAccessType = 'editAccessType';
export const SHAREPOINT = 'SPOL';
export const SHAREPOINT_TOKEN = 'SPOLToken';
export const DOCSERVICE_TOKEN = 'DocServiceToken';
export const AUTH_ERROR = 'Not able to get the Access Token';
export const INPUT_VALIDATION_ERROR = 'Input Validation Failed';
export const UserManager = 'userManager';
export const ApiContextRoot = 'apiContextRoot';
export const ApiContextPath = 'apiContextPath';
export const DATA_MISSING = 'Data File Missing';
export const T360_NETWORKSWITCH_EVENT = 'T360_NetworkSwitch';
export const Proceed_with_NW_Switch_Msg = 'Proceed with NW Switch';
export const Cancel_NW_Switch_Msg = 'Cancel NW Switch';
export const passportConstants = {
    getMatterDetailsUrlPrepend: '/datacert/api/entity/Matter/',
    getMatterDetailsAttribute: '?attributeNames=longMatterName;primaryInternalPerson;practiceAreaBusinessUnit;id',
};
export const virusScanPollingTimer = 2000;
export const Timeout = {
    JR: {
        addCheckinTimeout: 120000,
        otherFuncTimeout: 30000,
        deleteTimeout: 120000,
    },
    T360: {
        addCheckinTimeout: 5000,
        otherFuncTimeout: 3000,
    },
};
export const FILES_TO_CLEANUP = 'FilesToCleanUp';
