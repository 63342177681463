import { injectable } from 'inversify';
import { apiFetchPost } from '../../api/fetchUtils';
import { T360AdapterActionPerformer } from './actionPerformer';
import { T360AdapterGetNotificationFilesActionableEntity } from '../actionableEntity/getNotificationFilesActionableEntity';
import { IActionableEntity } from '../../interfaces/actionEntity/type';
import { IDocOperationInvocation } from '../../interfaces/urlPattern/types';
import { DCResponse } from '../../types';

@injectable()
export class T360AdapterGetNotificationFilesActionPerformer extends T360AdapterActionPerformer {
    fetchRequest = apiFetchPost;

    async performAction(
        obj: T360AdapterGetNotificationFilesActionableEntity,
        urlObj: Promise<string>,
        urlPatternObj?: IDocOperationInvocation,
        extendedUrl?: string,
    ): Promise<DCResponse> {
        return await super.performAction(obj.guids as IActionableEntity, urlObj, urlPatternObj, extendedUrl);
    }
}
