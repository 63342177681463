import { CapabiltyEnum } from '../../enums/capability';
import { injectable } from 'inversify';
import { JRAdapterRenameUrlPattern } from '../urlEntity/renameOperationPattern';
import { IRenameDocument } from '../../docManager';

@injectable()
export class JRAdapterRenameDocumentUrlPattern extends JRAdapterRenameUrlPattern {
    operationName = CapabiltyEnum.RENAME_DOCUMENT;
    async getUrl(nodeObj: IRenameDocument): Promise<string> {
        let baseUrl = await super.getUrl(nodeObj);

        baseUrl = baseUrl + '?ignoreWarnings=true';
        return baseUrl;
    }
}
