import { injectable } from 'inversify';
import { apiFetchPost } from '../../restApi/fetch.utils';
import { T360AdapterActionPerformer } from './actionPerformer';
import * as tus from 'tus-js-client';
import { factory } from '../../ConfigLog4j';
import { getAccessToken, handleUploadErrors } from '../utils/utils';
import { T360AdapterAddEmailActionableEntity } from '../actionableEntity/addEmailActionableEntity';
import { DCResponse } from '../../types';

const log = factory.getLogger('T360AdapterAddEmailActionPerformer');

@injectable()
export class T360AdapterAddEmailActionPerformer extends T360AdapterActionPerformer {
    fetchRequest = apiFetchPost;

    performAction(obj: T360AdapterAddEmailActionableEntity, urlObj: Promise<string>): Promise<DCResponse> {
        const { file, ...obj3 } = Object.assign({}, obj);

        return urlObj.then(async (url) => {
            const accessToken = await getAccessToken();
            log.debug('Performing AddEmail with token');
            return new Promise<DCResponse>((resolve, _reject) => {
                const options = {
                    endpoint: url,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    metadata: obj3 as any,
                    headers: {
                        Authorization: 'Bearer ' + accessToken,
                    },
                    onSuccess: () => {
                        const myBlob = new Blob();
                        const init = { status: 200, statusText: 'Pending' };
                        resolve(new DCResponse(myBlob, init));
                    },
                    onError: (err: Error) => {
                        const init = { status: 500, statusText: '' };
                        const myBlob = new Blob();
                        const response2: DCResponse = new Response(myBlob, init) as DCResponse;
                        response2.statusInfo = handleUploadErrors(err.message);
                        resolve(response2);
                    },
                };

                const upload = new tus.Upload(file as Blob, options);
                upload.start();
            });
        });
    }
}
