import { ThemeOptions } from '@material-ui/core';

export const appTheme: ThemeOptions = {
    overrides: {
        MuiCssBaseline: {
            '@global': {
                body: {
                    backgroundColor: '#ffffff',
                },
            },
        },
    },
    palette: {
        primary: {
            main: '#007ac3',
        },
        secondary: {
            main: '#474747',
        },
        text: {
            secondary: 'rgba(0,0,0,0.64)',
        },
        background: {
            default: '#fff',
        },
    },
    typography: {
        fontFamily: 'Fira Sans,Helvetica,Arial,sans-serif',
        button: {
            textTransform: 'none',
        },
    },
};
