import 'reflect-metadata';
import { injectable } from 'inversify';
import { IDocServiceRequest } from '@wk/elm-uui-doc-component';
import { IContextInputType } from '../interfaces/operations';

export interface ITransformerNew<T, K> {
    data: T;
    transform(): K;
}
export interface ICtxtOperationTransformerNew<T, K> {
    transform(inpObj: ITransformerNew<T, K>): K;
}
@injectable()
export class CtxtOperationTransformerNew<P, Q> implements ICtxtOperationTransformerNew<P, Q> {
    transform(inpObj: ITransformerNew<P, Q>): Q {
        return inpObj.transform();
    }
}

export interface ITransformer {
    transform(): IDocServiceRequest;
}
export interface IValidationTransformer {
    transform(): IContextInputType;
}
export interface ICtxtOperationTransformer {
    transform(inpObj: ITransformer): IDocServiceRequest;
}
@injectable()
export class CtxtOperationTransformer implements ICtxtOperationTransformer {
    transform(inpObj: ITransformer): IDocServiceRequest {
        return inpObj.transform();
    }
}

// export interface ITransformer<T, K> {
//   data: T;
//   transform(): K;
// }
// export interface ICtxtOperationTransformer<T, K> {
//   transform(inpObj: ITransformer<T, K>): K;
// }
// @injectable()
// export class CtxtOperationTransformer<P, Q> implements ICtxtOperationTransformer<P, Q> {
//   transform(inpObj: ITransformer<P, Q>): Q {
//     return inpObj.transform();
//   }
// }
