import { FullScreenOverlayIconEnum, OverlayDialogButtonAction } from '@wk/elm-uui-context-handler';
import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { OverlayDialog } from './overlayDialog';

export const ErrorOverlay: React.FC<FallbackProps> = (props) => (
    <OverlayDialog
        {...props}
        heading={window.Props.errorOverlayHeading}
        icon={FullScreenOverlayIconEnum.EXCLAMATION}
        message={[window.Props.errorOverlayMessage]}
        button={{
            text: window.Props.returnToHomeButton,
            action: OverlayDialogButtonAction.NavigateToHome,
        }}
    />
);
