import { Container } from 'inversify';

import { IQueueServiceInterface } from './queueManager.interface';
import { QueueManager } from './queueManager.service';

const myContainer = new Container();

myContainer.bind<IQueueServiceInterface>('QueueManager').to(QueueManager).inSingletonScope();

export { myContainer };
