import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from '@material-ui/core';
import UUIButton from './uuiButton';
import CloseIcon from '../icons/wkCloseIcon';
import React from 'react';
import css from './actionDialog.module.scss';
import { useReduxSelector } from '../../hooks/useReduxSelector';

interface IActionDialog {
    open: boolean;
    handleActionClose: () => void;
    actionButtonClickHandler: () => void;
    title: string;
    actionButtonText: string;
    disableActionButton: boolean;
    secondActionButtonText?: string;
    secondActionButtonClickHandler?: () => void;
    disableCloseButtonIcon?: boolean;
}

const ActionDialog: React.FC<IActionDialog> = ({
    open,
    handleActionClose,
    actionButtonClickHandler,
    title,
    actionButtonText,
    children,
    disableActionButton,
    secondActionButtonText,
    secondActionButtonClickHandler,
    disableCloseButtonIcon,
}) => {
    const appResources = useReduxSelector((state) => state.appResources);

    const closeClickHandler = () => {
        if (handleActionClose) {
            handleActionClose();
        }
    };

    return (
        <Dialog open={open} aria-labelledby="modal-title" role="dialog" classes={{ paper: css.dialogRoot }}>
            <ActionDialogTitle handleClose={closeClickHandler} disableCloseButtonIcon={disableCloseButtonIcon}>
                <Tooltip placement="top-start" arrow title={title} classes={{ tooltip: css.tooltip }}>
                    <Typography noWrap className={css.title}>
                        {title}
                    </Typography>
                </Tooltip>
            </ActionDialogTitle>
            <ActionDialogContent>{children}</ActionDialogContent>
            <ActionDialogActions>
                <UUIButton
                    data-testid="dialog-action-button"
                    variant="outlined"
                    classes={{ outlined: css.actionButton }}
                    autoFocus
                    onClick={() => actionButtonClickHandler()}
                    disabled={disableActionButton}>
                    {actionButtonText}
                </UUIButton>
                {secondActionButtonText && secondActionButtonClickHandler && (
                    <UUIButton
                        data-testid="dialog-second-action-button"
                        variant="outlined"
                        classes={{ outlined: css.secondActionButton }}
                        autoFocus
                        onClick={() => secondActionButtonClickHandler()}>
                        {secondActionButtonText}
                    </UUIButton>
                )}
                <UUIButton
                    data-testid="dialog-cancel-button"
                    variant="outlined"
                    classes={{ outlined: css.cancelButton }}
                    onClick={() => handleActionClose()}>
                    {appResources.buttonCancel}
                </UUIButton>
            </ActionDialogActions>
        </Dialog>
    );
};

interface IActionDialogTitle {
    handleClose: () => void;
    disableCloseButtonIcon?: boolean;
}

const ActionDialogTitle: React.FC<IActionDialogTitle> = ({ handleClose, disableCloseButtonIcon, children }) => (
    <DialogTitle disableTypography className={css.dialogTitleRoot}>
        <div className={css.titleTextContainer}>
            <Typography variant="h6" noWrap id="modal-title">
                {children}
            </Typography>
        </div>

        {!disableCloseButtonIcon && (
            <div className={css.closeIconContainer}>
                <IconButton
                    size="small"
                    className={css.closeIcon}
                    onClick={() => handleClose()}
                    data-testid="dialog-close-button"
                    aria-label="Close Dialog">
                    <CloseIcon className={css.closeIconRoot} />
                </IconButton>
            </div>
        )}
    </DialogTitle>
);

const ActionDialogContent: React.FC = ({ children }) => (
    <DialogContent data-testid="dialog-content" dividers className={css.actionDialogContent}>
        {children}
    </DialogContent>
);

const ActionDialogActions: React.FC = ({ children }) => (
    <DialogActions classes={{ root: css.dialogActionsRoot }}>{children}</DialogActions>
);

export default ActionDialog;
