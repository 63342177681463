import { IInputValidator } from '../../interfaces/inputValidators/type';
import { injectable } from 'inversify';
import { IValidationInfo } from '../../dmInfoHandler/types';
import { DCValidationError } from '../../customError/docComponentError';
import { IAddFolder } from '../../docManager';
import { IAddFolder_T360 } from '../types';

@injectable()
export class T360AdapterAddFolderInputValidator implements IInputValidator {
    validationInfo: IValidationInfo;
    performValidation = (nodeObj: IAddFolder): boolean => {
        // Condition can be added to fail the operation
        // if not received needed inputs

        const nodeObj1 = nodeObj as IAddFolder_T360;

        if (
            nodeObj1?.name &&
            nodeObj1?.editAccessType &&
            nodeObj1?.viewAccessType &&
            nodeObj1?.documentFolderId
            // validator.checkSpecialCharacters(nodeObj.name, this.validationInfo)
        ) {
            return true;
        } else {
            throw new DCValidationError(
                'Add Folder T360 Params: Name: ' +
                    nodeObj1?.name +
                    ' Edit Access Type: ' +
                    nodeObj1?.editAccessType +
                    ' View access Type: ' +
                    nodeObj1?.viewAccessType,
            );
        }
    };
}
