import { IDocAdaptor } from './interfaces/adapter/types';
import { DCResponse, IDocServiceRequest, IPollFiles } from './types';
import { IDmAdapter } from './interfaces/dmAdapters/types';
import { IDocCapability } from './capability/type';
import { factory } from './ConfigLog4j';
import { IActionableEntity } from './interfaces/actionEntity/type';
import { GetUrlEnum } from './enums/urlPattern';
import { ICommandNameUrlPattern } from './jRAdapter/utils/maps';
import { injectable } from 'inversify';

const log = factory.getLogger('Adaptor');

@injectable()
export abstract class Adapter implements IDocAdaptor {
    editFolder(docReq: IDocServiceRequest): Promise<DCResponse> {
        return this.capability.performDocOperation(docReq);
    }
    editDocument(docReq: IDocServiceRequest): Promise<DCResponse> {
        return this.capability.performDocOperation(docReq);
    }
    getMappedOperation(_capabilityEnum: string): string {
        throw new Error('Method not implemented.');
    }
    getActionPerformerMethod(
        _capabilityEnum: string,
    ): (arg0: string, arg1: IActionableEntity, arg2: string) => Promise<DCResponse> {
        throw new Error('Method not implemented.');
    }
    getInputValidators(_capabilityEnum: string): string[] {
        throw new Error('Method not implemented.');
    }
    getUrlPatternMethodAndCommandName(_capabilityEnum: string): ICommandNameUrlPattern {
        throw new Error('Method not implemented.');
    }

    getUrlEnum: GetUrlEnum;
    objectRelative: string;
    commandIds: Promise<Record<string, string>>;

    ObjectTypeMap: Record<string, string>;

    renameDocument(docReq: IDocServiceRequest): Promise<DCResponse> {
        // await this.commandIds;
        // throw new Error('This Operation is not allowed for JR');
        return this.capability.performDocOperation(docReq);
    }
    checkIn(docReq: IDocServiceRequest): Promise<DCResponse> {
        return this.capability.performDocOperation(docReq);
    }
    checkOut(docReq: IDocServiceRequest): Promise<DCResponse> {
        return this.capability.performDocOperation(docReq);
    }

    downloadEmailDocument(docReq: IDocServiceRequest): Promise<DCResponse> {
        return this.capability.performDocOperation(docReq);
    }
    addDocument(docReq: IDocServiceRequest): Promise<DCResponse> {
        log.debug('Calling DocService AddDocument');
        log.debug('DocService Adapter capability' + this.capability);

        return this.capability.performDocOperation(docReq);
    }

    validateDocument(docReq: IDocServiceRequest): Promise<DCResponse> {
        log.debug('Calling DocService validateDocument');
        log.debug('DocService Adapter capability' + this.capability);

        return this.capability.performDocOperation(docReq);
    }

    validateCheckInDocument(docReq: IDocServiceRequest): Promise<DCResponse> {
        log.debug('Calling DocService validateCheckInDocument');
        log.debug('DocService Adapter capability' + this.capability);

        return this.capability.performDocOperation(docReq);
    }

    downloadDocument(docReq: IDocServiceRequest): Promise<DCResponse> {
        return this.capability.performDocOperation(docReq);
    }

    downloadDocumentVersion(docReq: IDocServiceRequest): Promise<DCResponse> {
        return this.capability.performDocOperation(docReq);
    }

    addFolder(docReq: IDocServiceRequest): Promise<DCResponse> {
        return this.capability.performDocOperation(docReq);
    }
    deleteDocument(docReq: IDocServiceRequest): Promise<DCResponse> {
        return this.capability.performDocOperation(docReq);
    }

    deleteFolder(docReq: IDocServiceRequest): Promise<DCResponse> {
        return this.capability.performDocOperation(docReq);
    }

    list(docReq: IDocServiceRequest): Promise<DCResponse> {
        return this.capability.performDocOperation(docReq);
    }
    cancelCheckOut(docReq: IDocServiceRequest): Promise<DCResponse> {
        return this.capability.performDocOperation(docReq);
    }

    addEmail(docReq: IDocServiceRequest): Promise<DCResponse> {
        return this.capability.performDocOperation(docReq);
    }

    deleteEmail(docReq: IDocServiceRequest): Promise<DCResponse> {
        return this.capability.performDocOperation(docReq);
    }

    deleteEmailFolder(docReq: IDocServiceRequest): Promise<DCResponse> {
        return this.capability.performDocOperation(docReq);
    }

    renameFolder(docReq: IDocServiceRequest): Promise<DCResponse> {
        return this.capability.performDocOperation(docReq);
    }

    documentMetadata(docReq: IDocServiceRequest): Promise<DCResponse> {
        return this.capability.performDocOperation(docReq);
    }

    folderMetadata(docReq: IDocServiceRequest): Promise<DCResponse> {
        return this.capability.performDocOperation(docReq);
    }

    documentMetadataFileName(docReq: IDocServiceRequest): Promise<DCResponse> {
        return this.capability.performDocOperation(docReq);
    }

    documentMetadataEmail(docReq: IDocServiceRequest): Promise<DCResponse> {
        return this.capability.performDocOperation(docReq);
    }

    pollFiles(guids: IPollFiles): Promise<DCResponse> {
        return this.capability.performDocOperation(guids);
    }

    getSupportedEntityTypes(): Promise<DCResponse> {
        return this.capability.performDocOperation({} as IDocServiceRequest);
    }

    dmAdapters: IDmAdapter[] = [];

    capability: IDocCapability;
}
