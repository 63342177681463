import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const WkFilterSettingsIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon {...props} data-testid="wkFilterSettingsIcon">
        <g>
            <path
                d="M2.36,1.57,5.93,6.11A9.28,9.28,0,0,1,8,5.86l.19,0L6.33,3.49h19L16.79,14.31v8.17l-1.92,1V21.11a7.36,7.36,0,0,1-1.93,2v3.42h0l5.77-2.88V15L29.28,1.57Z"
                transform="translate(-2.36 -1.57)"
            />
            <path
                d="M9,16.44H9a2.26,2.26,0,1,0-2.25-2.25A2.27,2.27,0,0,0,9,16.44Zm0-3.27H9a1,1,0,1,1,0,2h0a1,1,0,0,1-.72-1.74A1,1,0,0,1,9,13.17Z"
                transform="translate(-2.36 -1.57)"
            />
            <path
                d="M12.52,19.68l2-2-1.32-1.32a4.87,4.87,0,0,0,.3-.71h1.85V12.75H13.54a3.12,3.12,0,0,0-.3-.73l1.31-1.31-2-2L11.2,10a4.55,4.55,0,0,0-.72-.3V7.85H7.61V9.69a4.55,4.55,0,0,0-.72.3L5.57,8.68l-2,2L4.86,12a7.76,7.76,0,0,0-.3.72H2.71v2.87H4.54a4.21,4.21,0,0,0,.31.73L3.54,17.66l2,2,1.32-1.31a7.76,7.76,0,0,0,.72.3v1.84h2.87V18.68a3.3,3.3,0,0,0,.73-.3Zm-6-3.19-.12-.15a3.19,3.19,0,0,1-.72-1.77l0-.19H3.94V14h1.6l0-.18A3.66,3.66,0,0,1,6.34,12l.12-.15L5.3,10.71l.27-.26,1.16,1.16.15-.12a3.27,3.27,0,0,1,1.77-.72l.19,0V9.08h.4v1.61l.19,0a3.67,3.67,0,0,1,1.79.73l.14.11,1.16-1.15.26.26-1.16,1.16.12.15a3.19,3.19,0,0,1,.72,1.77l0,.19h1.61v.4H12.48l0,.19a3.72,3.72,0,0,1-.72,1.78l-.11.15,1.16,1.2-.28.27-1.16-1.15-.14.11a3.42,3.42,0,0,1-1.78.73H9.24v1.62h-.4V17.62l-.18,0a3.8,3.8,0,0,1-1.79-.72l-.14-.11L5.57,17.92l-.27-.26Z"
                transform="translate(-2.36 -1.57)"
            />
        </g>
    </SvgIcon>
);

export default WkFilterSettingsIcon;
