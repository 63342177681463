import { Grid, IconButton, Link } from '@material-ui/core';
import ArrowBackIosSharpIcon from '@material-ui/icons/ArrowBackIosSharp';
import React from 'react';
import { useReduxDispatch } from '../../hooks/useReduxDispatch';
import { useReduxSelector } from '../../hooks/useReduxSelector';
import { readLogLevelFromLocalStorage } from '../../utils/loggingService';
import FullScreenDialog, { FullScreenDialogContent } from '../common/fullScreenDialog';
import { getLogLevels } from './helpScreenHelpers';
import css from './helpSection.module.scss';
import { LogLevelSelectorDialog } from './logLevelSelectorDialog';
import { clBuildHelpDocumentURL, clDownloadHelpPDF, isOC } from '../contextLayerService/contextLayerService';

const HelpSection: React.FC = () => {
    const appResources = useReduxSelector((state) => state.appResources);
    const applicationUrls = useReduxSelector((state) => state.applicationUrls);
    const isHelpSectionDialogOpen = useReduxSelector((state) => state.ui.helpSection.isHelpSectionDialogOpen);
    const reduxDispatch = useReduxDispatch();
    const storedLogLevel = useReduxSelector((state) => state.ui.helpSection.logLevel);
    const logLevels = getLogLevels(appResources);
    const logLevelFromLocalStorage = readLogLevelFromLocalStorage();
    const defaultLogLevel =
        logLevelFromLocalStorage && logLevelFromLocalStorage.toString() !== '0'
            ? logLevels.find((val) => val.key.toString() === logLevelFromLocalStorage.toString())
            : logLevels[0];
    const apiPath = Props['apiContextRoot'] + Props['apiContextPath'];
    const fileURL = applicationUrls.applicationDownloadHelpFileUrl.replace('{guide}', 'guide');
    const hrefValue = isOC() ? 'javascript:void(0)' : apiPath + fileURL;

    const CLDownloadHelpPdfFile = async () => {
        const clDownloadURLObj = await clBuildHelpDocumentURL(apiPath, fileURL);
        clDownloadHelpPDF(clDownloadURLObj.helpDocumentUrl);
    };

    return (
        <FullScreenDialog
            testId="helpScreen-dialog"
            isOpen={isHelpSectionDialogOpen}
            onClose={() => reduxDispatch({ type: 'CloseHelpSectionDialog' })}
            AppBarProps={{ elevation: 0 }}
            showAppHeader={true}>
            <FullScreenDialogContent className={css.fullScreenDialogContent}>
                <div className={css.headerContainer}>
                    <IconButton
                        size="small"
                        className={css.chevronStyle}
                        data-testid="historyBackChevron"
                        onClick={() => reduxDispatch({ type: 'CloseHelpSectionDialog' })}>
                        <ArrowBackIosSharpIcon color="primary" classes={{ root: css.backIconRoot }} />
                    </IconButton>
                    <span className={css.header}>{appResources.applicationLinkHelp}</span>
                </div>
                <Grid className={css.outerContainer}>
                    <Grid item xs={12}>
                        <h2 className={css.sectionTitle}>{appResources.helpPageGetHelp}</h2>
                        <Link
                            className={css.helpLinks}
                            href={hrefValue}
                            onClick={() => isOC() && CLDownloadHelpPdfFile()}
                            target="_blank"
                            download
                            data-testid="downloadFileLink">
                            {appResources.helpPageQuickStartGuide.replace('{appName}', appResources.appName)}
                        </Link>
                        <Link
                            className={css.helpLinks}
                            data-testid="contactLink"
                            onClick={() => reduxDispatch({ type: 'ContactsDialogOpen', isOpen: true })}>
                            {appResources.helpPageContactLink.replace('{appName}', appResources.appName)}
                        </Link>
                    </Grid>
                    <Grid item xs={12} className={css.diagnosticSection}>
                        <h2 className={css.sectionTitle}>{appResources.helpPageDiagnostics} </h2>
                    </Grid>
                    <Grid item xs={12} className={css.errorsSection}>
                        <div className={css.subtitle}>{appResources.helpPageTroubleshootingLog}</div>
                        <h2 className={css.errSectionTitle}>
                            {storedLogLevel.value ? storedLogLevel.value : defaultLogLevel?.value + ' '}
                            <Link
                                className={css.reportLinks}
                                href="javascript:void(0)"
                                onClick={() => reduxDispatch({ type: 'LogLevelSelectorDialogOpen', isOpen: true })}
                                data-testid="log-level-popup-button">
                                {appResources.helpPageChange}
                            </Link>
                        </h2>
                    </Grid>
                    <Grid item xs={12} className={css.ocDirectionsContainer}>
                        <div className={css.OCDirections}>{appResources.helpPageOCDirections}</div>
                    </Grid>
                    <Grid item xs={12} className={css.aboutAppSection}>
                        <h2 className={css.appsectionTitle}>
                            {appResources.helpPageAbout.replace('{appName}', appResources.appName)}{' '}
                            <Link
                                className={css.reportLinks}
                                data-testid="aboutLink"
                                onClick={() => reduxDispatch({ type: 'AboutDialogOpen', isOpen: true })}>
                                {appResources.helpPageViewDetails}
                            </Link>
                        </h2>
                    </Grid>
                    <Grid item xs={12} className={css.errorsSection} data-testid="errorSection">
                        <div
                            className={
                                css.subtitle
                            }>{`${appResources.appName} ${appResources.helpPageVersionText}`}</div>
                        <h2 className={css.errSectionTitle} data-testid="appVersion">
                            {appResources.helpPageAppVersion.replace('-SNAPSHOT', '')}
                        </h2>
                    </Grid>
                </Grid>
            </FullScreenDialogContent>
            <LogLevelSelectorDialog
                logLevels={logLevels}
                defaultLogLevel={storedLogLevel.value ? storedLogLevel : defaultLogLevel!}
            />
        </FullScreenDialog>
    );
};

export default HelpSection;
