import { IDocTransformer } from '../../interfaces/transformer/type';
import { injectable } from 'inversify';
import { factory } from '../../ConfigLog4j';
import { IMatterDoc, IDocumentMetadata } from '../../types';
import { JRAdapterDocumentMetadataActionableEntity } from '../actionableEntity/documentMetadataActionableEntity';
import { IDocOperationInvocation } from '../../interfaces/urlPattern/types';
const log = factory.getLogger('JRAdapterDocumentMetadataEntityTransformer');

@injectable()
export class JRAdapterDocumentMetadataEntityTransformer implements IDocTransformer {
    transformIntoActionableEntity(
        nodeObj: IDocumentMetadata,
        _matterDoc: IMatterDoc,
        _urlPattern: IDocOperationInvocation,
    ): JRAdapterDocumentMetadataActionableEntity {
        log.debug('Calling transformIntoActionableEntity');
        let obj = {} as JRAdapterDocumentMetadataActionableEntity;
        obj = nodeObj;
        return obj;
    }
}
