import { IItemScreenProps } from '../components/itemScreen/itemScreen';
import { UUIReduxAction } from './types';

export const itemScreenDialogsReducer = (state: IItemScreenProps[], action: UUIReduxAction): IItemScreenProps[] => {
    switch (action.type) {
        case 'OpenItemScreenDialog':
            return state.concat([action.itemScreenProps]);
        case 'RemoveItemScreenDialog':
            // remove last popup from array
            return [...state.slice(0, state.length - 1)];
    }
    return state;
};
