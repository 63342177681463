import React, { useState } from 'react';
import { Tab } from '../common/tabs';
import FunctionViewScreen from './functionViewScreen';
import css from './userScreenTabsView.module.scss';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { useReduxSelector } from '../../hooks/useReduxSelector';

const UserScreenTabsView: React.FC = () => {
    const [tabValue, setTabValue] = useState('0');
    // const [visitedTabs] = useState(['0']);
    const appResources = useReduxSelector((state) => state.appResources);

    return (
        <div>
            <TabContext value={tabValue}>
                <TabList
                    className={css.tabsRoot}
                    TabIndicatorProps={{
                        className: css.tabIndicator,
                    }}
                    indicatorColor="primary"
                    scrollButtons="off"
                    variant="scrollable"
                    textColor="primary"
                    value={tabValue}
                    onChange={(_, nv) => {
                        setTabValue(nv);
                    }}
                    aria-label={appResources.appbarUserProfileToolTip}
                    data-testid="user-tabs-container">
                    <Tab
                        label={appResources.userscreenFunctionTab}
                        classes={{
                            selected: css.tabSelected,
                            root: css.tabRoot,
                        }}
                        value="0"
                    />
                    <Tab
                        label="Remove this tab when you add another official tab. It's here to prevent console errors"
                        hidden={true}
                        value="1"
                    />
                </TabList>
                <TabPanel value={tabValue} className={css.tabPanelRoot}>
                    <FunctionViewScreen />
                </TabPanel>
            </TabContext>
        </div>
    );
};

export default UserScreenTabsView;
