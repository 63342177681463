import { merge } from 'lodash';
import React, { createContext, useContext } from 'react';
import useIsMounted from '../../../hooks/useIsMounted';
import { useReducerWithLogging } from '../../../hooks/useReducerWithLogging';
import { IListScreenState, ListScreenDispatch } from '../types';
import { listScreenReducer } from './listScreenReducer';

const defaultInitialState: IListScreenState = {
    isEmbeddedList: false,
    disabledRows: [],
    checkedRows: [],
    breadCrumbs: [],
    listScreenEditDialogOpen: false,
    listScreenEditDialogKey: new Date().getTime(),
    editsAppliedSaveDialogOpen: false,
    editsAppliedSaveAsDialogOpen: false,
    editsAppliedSaveAsDialogKey: new Date().getTime(),
    tabOptionsRenameDialogOpen: false,
    resetToDefaultDialogOpen: false,
    tabOptionsRemoveTabDialogOpen: false,
    quickSearchString: '',
};

// this is used to track that the list screen is still mounted. Should only be used
// in hooks that wrap async actions ... ex: useRefreshList()
const ListScreenIsMountedContext = createContext<React.MutableRefObject<boolean> | undefined>(undefined);

const ListScreenContext = createContext<IListScreenState>({} as IListScreenState);

const ListScreenDispatchContext = createContext<ListScreenDispatch | undefined>(undefined);

// initial state override should be used in jest tests only
interface IListScreenProvider {
    initialStateOverride?: Partial<IListScreenState>;
}

export const ListScreenProvider: React.FC<IListScreenProvider> = ({ children, initialStateOverride }) => {
    const initialState = initialStateOverride
        ? merge({}, defaultInitialState, initialStateOverride)
        : defaultInitialState;
    const [state, dispatch] = useReducerWithLogging('listscreencontext', listScreenReducer, initialState);
    const isMounted = useIsMounted();
    return (
        <ListScreenIsMountedContext.Provider value={isMounted}>
            <ListScreenContext.Provider value={state}>
                <ListScreenDispatchContext.Provider value={dispatch}>{children}</ListScreenDispatchContext.Provider>
            </ListScreenContext.Provider>
        </ListScreenIsMountedContext.Provider>
    );
};

export const useListScreenState = (): IListScreenState => {
    const context = useContext(ListScreenContext);
    if (context === undefined) {
        throw new Error('useListScreenState must be used within a ListScreenProvider');
    }
    return context;
};

export const useListScreenDispatch = (): ListScreenDispatch => {
    const context = useContext(ListScreenDispatchContext);
    if (context === undefined) {
        throw new Error('useListScreenDispatch must be used within a ListScreenProvider');
    }
    return context;
};

export const useListScreenIsMounted = (): React.MutableRefObject<boolean> => {
    const context = useContext(ListScreenIsMountedContext);
    if (context === undefined) {
        throw new Error('useListScreenIsMounted must be used within a ListScreenProvider');
    }
    return context;
};
