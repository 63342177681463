import { fileContainer } from './fileOperations.module';
import { FileOperationsInterface, FileOperationsService } from './fileOperations.service';

export const FileOperations = fileContainer.get<FileOperationsInterface>(FileOperationsService);

export {
    DownloadOptions,
    FileMeta,
    PathAlias,
    pathAliases,
    OpenDialogProperties,
    FilePathInfo,
    OpenDialogOptions,
    PersistentFileMeta,
    ResolvePathOptions,
} from './fileOperations.service';
