import { baseCommunicationService, BaseResponseException } from '@wk/office-companion-js-common';
import { catchError, map, Observable, throwError } from 'rxjs';
import { isBoolean, isDate, isInteger, isNil, isNumber, isObject, isString } from 'lodash';
import { ComError, ComErrorCode, ComErrorData, ComErrorScope } from './comError';
import { ComObjectChannelEnum } from './comObjectChannel.enum';
import { ObjectRefValueType, ValueHolder, ValueHolderType, ValueType } from './valueHolder.interface';
import { LoggerService } from '../../../common/logger/logger.service';
import { OfficeAppType } from '../common/officeAppType.enum';

interface ComBridgeValueHolder {
    valueType: ValueType;
    boolValue?: boolean;
    intValue?: number;
    floatValue?: string;
    stringValue?: string;
    dateTimeValue?: string;
    objectRefValue?: string;
    unknownValue?: unknown;
}

export interface GetContextData {
    type: OfficeAppType;
}

interface GetPropertyData {
    propertyName: string;
    index: ComBridgeValueHolder;
    extension: boolean;
}

interface SetPropertyData {
    propertyName: string;
    value: ComBridgeValueHolder;
    extension: boolean;
}

interface InvokeMethodData {
    methodName: string;
    methodArgs?: ComBridgeValueHolder[];
    extension: boolean;
}

export interface ComBridgeResponse {
    value?: ComBridgeValueHolder;
}

export class ComObject implements ObjectRefValueType {
    private _isReleased = false;

    public get isReleased(): boolean {
        return this._isReleased;
    }

    constructor(
        private logger: LoggerService,
        public readonly objectRef?: string, // public readonly value?: string,
    ) {}

    // if type is not specified in index argument then it will be calculated automatically.
    // be aware of auto-calculation of number type. 1.00 Number value will be casted to Integer instead of float
    public getProperty<T = ValueHolderType, V = ValueHolderType>(
        name: string,
        index: ValueHolder<V> | undefined,
        extension: boolean,
    ): Observable<ValueHolder<T>> {
        const requestData: GetPropertyData = {
            propertyName: name,
            index: this.toComBridgeValueHolder(index),
            extension,
        };

        return this.request<T>(ComObjectChannelEnum.GetProperty, requestData);
    }

    // if type is not specified in value argument then it will be calculated automatically.
    // be aware of auto-calculation of number type. 1.00 Number value will be casted to Integer instead of float
    public setProperty<T = ValueHolderType, V = ValueHolderType>(
        name: string,
        value: ValueHolder<V>,
        extension: boolean,
    ): Observable<ValueHolder<T>> {
        const requestData: SetPropertyData = {
            propertyName: name,
            value: this.toComBridgeValueHolder(value),
            extension,
        };

        return this.request<T>(ComObjectChannelEnum.SetProperty, requestData);
    }

    // if type is not specified in any of methodArgs argument values then it will be calculated automatically.
    // be aware of auto-calculation of number type. 1.00 Number value will be casted to Integer instead of float.
    public invoke<T = ValueHolderType, V = ValueHolderType>(
        methodName: string,
        extension: boolean,
        ...methodArgs: ValueHolder<V>[]
    ): Observable<ValueHolder<T>> {
        const requestData: InvokeMethodData = {
            methodName,
            methodArgs: methodArgs?.map((arg) => this.toComBridgeValueHolder(arg)),
            extension,
        };

        return this.request<T>(ComObjectChannelEnum.Invoke, requestData);
    }

    public release(): Observable<ValueHolder<boolean>> {
        this._isReleased = true;
        return this.request(ComObjectChannelEnum.Release);
    }

    private request<T extends ValueHolderType>(channel: ComObjectChannelEnum, data?: any): Observable<ValueHolder<T>> {
        if (!this.objectRef || !baseCommunicationService) {
            throw new ComError({
                status: ComErrorCode.NotComObject,
                message: 'Not a COM Object reference',
                scope: ComErrorScope.ComObject,
            });
        }

        return baseCommunicationService
            .invoke<ComBridgeResponse>(channel, { ...data, objectRef: String(this.objectRef) })
            .pipe(
                map(({ data }) => this.fromComBridgeValueHolder(data.value) as ValueHolder<T>),
                catchError((error: BaseResponseException<Record<string, unknown> | string, ComErrorData | string>) => {
                    return throwError(
                        () =>
                            new ComError({
                                ...error,
                                message: error.message,
                                scope: ComErrorScope.ComBridge,
                                innerException: error,
                            }),
                    );
                }),
            );
    }

    private toComBridgeValueHolder(valueHolder?: ValueHolder): ComBridgeValueHolder {
        const type = valueHolder?.type || this.getValueType(valueHolder?.value);
        const value = valueHolder?.value;

        switch (type) {
            case ValueType.Bool:
                return { valueType: type, boolValue: value as boolean };
            case ValueType.Integer:
                return { valueType: type, intValue: value as number };
            case ValueType.Float:
                return { valueType: type, floatValue: value as string };
            case ValueType.Date:
                return { valueType: type, dateTimeValue: (value as Date).toString() };
            case ValueType.String:
                return { valueType: type, stringValue: value as string };
            case ValueType.ObjectRef:
                return { valueType: type, objectRefValue: (value as ObjectRefValueType).objectRef };
            case ValueType.Void:
                return { valueType: type };
            case ValueType.Unknown:
                return { valueType: type, unknownValue: value };
        }
    }

    private getValueType<V>(value: V): ValueType {
        return (
            (isBoolean(value) && ValueType.Bool) ||
            (isInteger(value) && ValueType.Integer) ||
            (isNumber(value) && ValueType.Float) ||
            (isDate(value) && ValueType.Date) ||
            (isString(value) && ValueType.String) ||
            (isObject(value) && ValueType.ObjectRef) ||
            (!value && ValueType.Void) ||
            ValueType.Unknown
        );
    }

    private fromComBridgeValueHolder(comBridgeValue?: ComBridgeValueHolder): ValueHolder {
        if (!comBridgeValue) {
            return { value: undefined, type: ValueType.Void };
        }

        const value = this.getValue(comBridgeValue);

        return { value, type: comBridgeValue.valueType };
    }

    private getValue(comBridgeValue: ComBridgeValueHolder): ValueHolderType {
        switch (comBridgeValue.valueType) {
            case ValueType.Bool:
                return Boolean(comBridgeValue.boolValue);
            case ValueType.Integer:
                return Number(comBridgeValue.intValue);
            case ValueType.Float:
                return isNil(comBridgeValue.floatValue) ? comBridgeValue.floatValue : String(comBridgeValue.floatValue);
            case ValueType.Date:
                return comBridgeValue.dateTimeValue && new Date(comBridgeValue.dateTimeValue);
            case ValueType.String:
                return String(comBridgeValue.stringValue);
            case ValueType.ObjectRef:
                return new ComObject(this.logger, comBridgeValue.objectRefValue);
            case ValueType.Void:
                return undefined;
            case ValueType.Unknown:
                return comBridgeValue.unknownValue as ValueHolderType;
        }
    }
}
