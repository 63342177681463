import { IRefreshUUIForEntity, notifyMessagingService } from '@wk/elm-uui-context-handler';
import { isOC } from './contextLayerService';
import { UUIMessageBusAction } from './types';

/**
 * Dispatches an action on the message bus.
 *
 * @remarks
 * The action.message will get converted to a string before being placed on the bus.
 *
 * @param action - The action you wish to dispatch to the bus
 */
export const messageBusDispatch = (action: UUIMessageBusAction): void => {
    // bail if we are not in OC. The Message Bus is only available in OC.
    if (!isOC()) {
        return;
    }

    let message: string | IRefreshUUIForEntity | undefined = '';

    if (action.type !== 'CloseOverlayDialog') {
        message = action.message;
    }

    let messageStr = '';
    try {
        if (typeof message === 'string') {
            messageStr = message;
        } else {
            messageStr = JSON.stringify(message);
        }
    } catch (e) {
        // swallow
    }

    const messageData = {
        ...action,
        message: messageStr || 'tempString',
    };
    notifyMessagingService(messageData);
};
