import { ClientObject } from '../common/clientObject';
import { DocumentWindow } from './documentWindow';
import { RequestType } from '../comObject';

export class Application extends ClientObject {
    /** https://docs.microsoft.com/en-us/office/vba/api/powerpoint.application.activewindow */
    public get activeWindow(): DocumentWindow {
        return this.reference.requestObject({
            type: RequestType.GetProperty,
            creator: DocumentWindow,
            name: 'ActiveWindow',
        }).value;
    }
}
