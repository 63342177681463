import { injectable } from 'inversify';
// import { DCValidationError } from '../customError/docComponentError';
import { IInputValidator } from '../interfaces/inputValidators/type';
import { IDocServiceRequest } from '../types';

interface IDocServiceFileNameMetadata extends IDocServiceRequest {
    documentName: string;
    fileName: string;
}

@injectable()
export class FileNameMetadataInputValidator implements IInputValidator {
    performValidation = (_nodeObj: IDocServiceFileNameMetadata): boolean => {
        // Condition can be added to fail the operation
        // if not received needed inputs
        // if (nodeObj?.documentName && nodeObj?.fileName) {
        return true;
        // } else {
        //   throw new DCValidationError('File Name MetaData for T360');
        // }
    };
}
