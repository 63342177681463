import { ExceptionStatus } from './exceptionStatus.enum';

export class NotImplementedException extends Error {
    public status: ExceptionStatus.NOT_IMPLEMENTED;

    constructor(message = '') {
        super(message);

        this.name = 'NotImplementedException';
    }
}
