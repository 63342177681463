export enum ObjectClass {
    Application = 0,
    Namespace = 1,
    Folder = 2,
    Recipient = 4,
    Attachment = 5,
    AddressList = 7,
    AddressEntry = 8,
    Folders = 15,
    Items = 16,
    Recipients = 17,
    Attachments = 18,
    AddressLists = 20,
    AddressEntries = 21,
    Appointment = 26,
    RecurrencePattern = 28,
    Exceptions = 29,
    Exception = 30,
    Action = 32,
    Actions = 33,
    Explorer = 34,
    Inspector = 35,
    Pages = 36,
    FormDescription = 37,
    UserProperties = 38,
    UserProperty = 39,
    Contact = 40,
    Document = 41,
    Journal = 42,
    Mail = 43,
    Note = 44,
    Post = 45,
    Report = 46,
    Remote = 47,
    Task = 48,
    TaskRequest = 49,
    TaskRequestUpdate = 50,
    TaskRequestAccept = 51,
    TaskRequestDecline = 52,
    MeetingRequest = 53,
    MeetingCancellation = 54,
    MeetingResponseNegative = 55,
    MeetingResponsePositive = 56,
    MeetingResponseTentative = 57,
    Explorers = 60,
    Inspectors = 61,
    Panes = 62,
    OutlookBarPane = 63,
    OutlookBarStorage = 64,
    OutlookBarGroups = 65,
    OutlookBarGroup = 66,
    OutlookBarShortcuts = 67,
    OutlookBarShortcut = 68,
    DistributionList = 69,
    PropertyPageSite = 70,
    PropertyPages = 71,
    SyncObject = 72,
    SyncObjects = 73,
    Selection = 74,
    Link = 75,
    Links = 76,
    Search = 77,
    Results = 78,
    Views = 79,
    View = 80,
    ItemProperties = 98,
    ItemProperty = 99,
    Reminders = 100,
    Reminder = 101,
    Conflict = 102,
    Conflicts = 103,
    Sharing = 104,
    Account = 105,
    Accounts = 106,
    Store = 107,
    Stores = 108,
    SelectNamesDialog = 109,
    ExchangeUser = 110,
    ExchangeDistributionList = 111,
    PropertyAccessor = 112,
    StorageItem = 113,
    Rules = 114,
    Rule = 115,
    RuleActions = 116,
    RuleAction = 117,
    MoveOrCopyRuleAction = 118,
    SendRuleAction = 119,
    Table = 120,
    Row = 121,
    AssignToCategoryRuleAction = 122,
    PlaySoundRuleAction = 123,
    MarkAsTaskRuleAction = 124,
    NewItemAlertRuleAction = 125,
    RuleConditions = 126,
    RuleCondition = 127,
    ImportanceRuleCondition = 128,
    FormRegion = 129,
    CategoryRuleCondition = 130,
    FormNameRuleCondition = 131,
    FromRuleCondition = 132,
    SenderInAddressListRuleCondition = 133,
    TextRuleCondition = 134,
    AccountRuleCondition = 135,
    ClassTableView = 136,
    ClassIconView = 137,
    ClassCardView = 138,
    ClassCalendarView = 139,
    ClassTimeLineView = 140,
    ViewFields = 141,
    ViewField = 142,
    OrderField = 144,
    OrderFields = 145,
    ViewFont = 146,
    AutoFormatRule = 147,
    AutoFormatRules = 148,
    ColumnFormat = 149,
    Columns = 150,
    CalendarSharing = 151,
    Category = 152,
    Categories = 153,
    Column = 154,
    ClassNavigationPane = 155,
    NavigationModules = 156,
    NavigationModule = 157,
    MailModule = 158,
    CalendarModule = 159,
    ContactsModule = 160,
    TasksModule = 161,
    JournalModule = 162,
    NotesModule = 163,
    NavigationGroups = 164,
    NavigationGroup = 165,
    NavigationFolders = 166,
    NavigationFolder = 167,
    ClassBusinessCardView = 168,
    AttachmentSelection = 169,
    AddressRuleCondition = 170,
    UserDefinedProperty = 171,
    UserDefinedProperties = 172,
    FromRssFeedRuleCondition = 173,
    ClassTimeZone = 174,
    ClassTimeZones = 175,
    Mobile = 176,
    SolutionsModule = 177,
    Conversation = 178,
    SimpleItems = 179,
    Outspace = 180,
    MeetingForwardNotification = 181,
    ConversationHeader = 182,
    ClassPeopleView = 183,
}
