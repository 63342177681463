import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const wkDeleteIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="wkDeleteIcon" viewBox="0 0 24 24" {...props}>
        <path
            d="M14.3,16H1.7V3.4H0.6V2.3h4C4.6,1,5.6,0,6.8,0h2.3c1.2,0,2.3,1,2.3,2.2v0.1h4v1.1h-1.1L14.3,16
	L14.3,16z M2.9,3.4v11.4h10.3V3.4H2.9z M6.9,1.1c-0.6,0-1.1,0.5-1.1,1.1c0,0,0,0,0,0.1h4.6c0-0.6-0.5-1.1-1.1-1.1c0,0,0,0-0.1,0H6.9
	z M10.9,12.6H9.7V5.7h1.1V12.6z M8.6,12.6H7.4V5.7h1.1C8.6,5.7,8.6,12.6,8.6,12.6z M6.3,12.6H5.1V5.7h1.1C6.2,5.7,6.3,12.6,6.3,12.6
	z"
        />
    </SvgIcon>
);

export default wkDeleteIcon;
