import { IDocTransformer } from '../../interfaces/transformer/type';
import { injectable } from 'inversify';
import { factory } from '../../ConfigLog4j';
import { IMatterDoc, IEditFolder } from '../../types';
import { IDocOperationInvocation } from '../../interfaces/urlPattern/types';
import { IEditFolder_T360 } from '../types';
import { T360AdapterEditFolderActionableEntity } from '../actionableEntity/editFolderActionableEntity';

const log = factory.getLogger('T360AdapterEditFolderEntityTransformer');

@injectable()
export class T360AdapterEditFolderEntityTransformer implements IDocTransformer {
    transformIntoActionableEntity(
        nodeObj: IEditFolder,
        _matterDoc: IMatterDoc,
        _urlPattern: IDocOperationInvocation,
    ): T360AdapterEditFolderActionableEntity {
        log.debug('Calling transformIntoActionableEntity');
        const obj = {} as T360AdapterEditFolderActionableEntity;
        // TO CREATE ACTIONABLE ENTITY FROM nodeObj and matterDoc

        const nodeObj1 = nodeObj as IEditFolder_T360;

        if (nodeObj1.name !== undefined || nodeObj1.name) {
            obj.name = nodeObj1.name;
        }

        if (nodeObj1.description !== undefined || nodeObj1.description) {
            obj.description = nodeObj1.description;
        }

        if (nodeObj1.editAccessType !== undefined || nodeObj1.editAccessType) {
            obj.editAccessType = nodeObj1.editAccessType;
        }

        if (nodeObj1.viewAccessType !== undefined || nodeObj1.viewAccessType) {
            obj.viewAccessType = nodeObj1.viewAccessType;
        }

        if (nodeObj1?.viewMembers) {
            obj.viewMembers = nodeObj1.viewMembers;
        }

        if (nodeObj1?.editMembers) {
            obj.editMembers = nodeObj1.editMembers;
        }

        return obj;
    }
}
