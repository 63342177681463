import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const WkFolderIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="wkFolderIcon" {...props}>
        <path d="M12,3.2l-1.6-1.6H0.8v4.8h22.4V3.2H12z M0.8,22.4h22.4V8H0.8V22.4z" />
    </SvgIcon>
);

export default WkFolderIcon;
