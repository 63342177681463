import { IValidationTransformer } from './types';
import { ICHValidateAddDocument, IValidateAddDocument } from '../interfaces/operations';

import 'reflect-metadata';
import { injectable } from 'inversify';
import { factory } from '../configLog4J';
const log = factory.getLogger('CtxValidateAddDocumentTransformer');

@injectable()
export class CtxValidateAddDocumentTransformer implements IValidationTransformer {
    data!: IValidateAddDocument;

    transform(): ICHValidateAddDocument {
        const nodeObj = {} as ICHValidateAddDocument;
        log.debug('printing Payload ' + JSON.stringify(this.data));

        // PP
        if (this.data?.name) {
            nodeObj.name = this.data.name;
        }
        if (this.data?.documentFileName) {
            nodeObj.documentFileName = this.data.documentFileName;
        }

        // t360
        if (this.data?.documentName) {
            nodeObj.name = this.data.documentName;
        }
        if (this.data?.documentType) {
            nodeObj.document_original_type = this.data.documentType;
        }
        if (this.data?.viewAccessType) {
            nodeObj.viewAccessType = this.data.viewAccessType;
        }
        if (this.data?.editAccessType) {
            nodeObj.editAccessType = this.data.editAccessType;
        }
        if (this.data?.description) {
            nodeObj.comments = this.data.description;
        }
        if (this.data?.documentFolderId) {
            nodeObj.document_folder_name = this.data.documentFolderId;
        }
        if (this.data?.default) {
            nodeObj.default = this.data.default;
        }
        if (this.data?.viewMembers) {
            nodeObj.viewMembers = this.data.viewMembers;
        }
        if (this.data?.editMembers) {
            nodeObj.editMembers = this.data.editMembers;
        }
        return nodeObj;
    }
}
