import React from 'react';
import ListScreen from '../../listScreen/listScreen';
import { useItemScreenState } from '../context/itemScreenContext';
import { generateParentItemInfoObject } from '../itemScreenHelpers';
import { ControlTypeProps } from '../types';

const MultiSelectList: React.FC<ControlTypeProps> = ({ field, mode, stickyTopPosition, tabIndex, parentTabIndex }) => {
    const itemScreenState = useItemScreenState();
    const parentItemInfo = generateParentItemInfoObject(itemScreenState.itemScreenJson!, field.attributeFieldName);

    return (
        <ListScreen
            screenId={field.sourceListScreen!.screenId}
            screenUrl={field.sourceListScreen!.selectedListScreenUrl}
            screenMetadataUrl={
                field.sourceListScreen!.selectedListScreenMetadataUrl
                    ? field.sourceListScreen!.selectedListScreenMetadataUrl
                    : field.sourceListScreen!.selectedListScreenUrl + '&_metadata=true'
            }
            mode={mode}
            isEmbeddedList={true}
            parentItemInfo={parentItemInfo}
            stickyTopPosition={stickyTopPosition}
            tabIndex={tabIndex}
            parentTabIndex={parentTabIndex}
        />
    );
};

export default MultiSelectList;
