import { injectable } from 'inversify';
import { OnDestroy } from '../core';
import { Subject } from 'rxjs';
import { LoggerService } from '../../common/logger/logger.service';
import { MessagingService } from '../messaging/messaging.service';

@injectable()
export class BootstrapService implements OnDestroy {
    private unsubscribe = new Subject<void>();

    constructor(private logger: LoggerService, private messagingService: MessagingService) {}

    public bootstrap() {
        this.logger.info(`browser window bootstrap`);
        this.messagingService.startListeningForMessages();
    }

    public onDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
