import { IInputValidator } from '../../interfaces/inputValidators/type';
import { injectable } from 'inversify';
// import { ValidationUtils } from '../../libraries/validationUtils';
// import { isNodeEnvironment } from '../../utils';
import { IValidationInfo } from '../../dmInfoHandler/types';
import { DCValidationError } from '../../customError/docComponentError';
import { ICheckIn } from '../../docManager';
import { ICheckIn_T360 } from '../types';

@injectable()
export class T360AdapterCheckInInputValidator implements IInputValidator {
    validationInfo: IValidationInfo;
    performValidation = (nodeObj: ICheckIn): boolean => {
        const nodeObj1 = nodeObj as ICheckIn_T360;
        // Condition can be added to fail the operation
        // if not received needed inputs
        // const validator = new ValidationUtils();
        // const file = nodeObj1.file;
        // const fileName = nodeObj1.documentName;
        // const extendedProps = nodeObj1.extendedProps;

        // let fileSize;
        if (!nodeObj?.file) {
            //   if (isNodeEnvironment()) {
            //     fileSize = (file as Buffer).length;
            //   } else {
            //     fileSize = (file as Blob).size;
            //   }
            // } else {
            throw new DCValidationError('T360 Checkin File Not found for upload ');
        }

        if (
            nodeObj1?.documentName &&
            nodeObj1?.docId
            // validator.checkSpecialCharacters(fileName, this.validationInfo) &&
            // validator.checkDocumentFileSize(fileSize, validator.getFileSize(this.validationInfo, extendedProps)) &&
            // validator.checkFileType(fileName, this.validationInfo) &&
            // validator.checkDocumentFileSize(fileSize, validator.getFileSize(this.validationInfo, extendedProps))
        ) {
            return true;
        } else {
            throw new DCValidationError(
                'Check In T360 Params: Name: ' + nodeObj1?.documentName + ' Doc Id: ' + nodeObj1?.docId,
            );
        }
    };
}
