import React from 'react';
import { Typography } from '@material-ui/core';
import ActionDialog from '../common/actionDialog';
import { useListScreenDispatch, useListScreenState } from './context/listScreenContext';
import { saveCurrentView } from './listScreenHelpers';
import { ISavedView } from './types';
import { useReduxSelector } from '../../hooks/useReduxSelector';
import { useUUILocation } from '../../hooks/useUUILocation';
import { useUUIHistory } from '../../hooks/useUUIHistory';
import { dispatchAddAndSelectSavedView, dispatchUpdateSavedView } from './context/listScreenAsyncActions';

export const SaveActionDialog: React.FC = () => {
    const listScreenState = useListScreenState();
    const listScreenDispatch = useListScreenDispatch();
    const metadata = listScreenState.metadata!;
    const listData = listScreenState.listData!;
    const applicationUrls = useReduxSelector((state) => state.applicationUrls);
    const appResources = useReduxSelector((state) => state.appResources);
    const location = useUUILocation();
    const history = useUUIHistory();

    const actionButtonClickHandler = () => {
        const savedViewPost: ISavedView = {
            ...listData.page.savedView,
            isSystemDefault: false,
            columns: [listData.page['column-data']],
            filters: listData.page.filters,
            sort: listData.page.sortInfo,
        };
        if (listData.page.savedView.isSystemDefault || listData.page.savedView.isUserDefault) {
            savedViewPost.isUserDefault = true;
        }

        saveCurrentView(
            savedViewPost,
            metadata.screenId,
            listScreenState.dataUrl,
            applicationUrls.applicationSavedViewUrl,
        ).then((json) => {
            const savedView = {
                ...savedViewPost,
                id: json.id,
                name: json.name,
            };
            if (listData.page.savedView.isSystemDefault) {
                // If saving a system default view, then add new view as user default
                dispatchAddAndSelectSavedView(listScreenState, listScreenDispatch, savedView, location, history);
            } else {
                dispatchUpdateSavedView(listScreenState, listScreenDispatch, savedView, location, history);
            }
            if (!location.state) {
                location.state = {};
            }
            listScreenDispatch({ type: 'CloseEditsAppliedSaveDialog' });
        });
    };

    const getContentText = () => {
        let tabName = listData.page ? listData.page.savedView.name : '';

        if (listData.page && (listData.page.savedView.isSystemDefault || listData.page.savedView.isUserDefault)) {
            tabName = listScreenState.isEmbeddedList
                ? appResources.listscreenDefaultDropdownText
                : metadata.screenDisplayName;
        }

        tabName = '"' + tabName + '"';

        const contentBase = appResources.dialogContentSave;

        if (contentBase) {
            return contentBase.replace('{tabName}', tabName);
        } else {
            return '';
        }
    };

    return (
        <ActionDialog
            open={listScreenState.editsAppliedSaveDialogOpen}
            handleActionClose={() => listScreenDispatch({ type: 'CloseEditsAppliedSaveDialog' })}
            actionButtonClickHandler={actionButtonClickHandler}
            title={appResources.dialogTitleSave}
            actionButtonText={appResources.buttonSave}
            disableActionButton={false}>
            <Typography gutterBottom data-testid="save-dialog-content">
                {getContentText()}
            </Typography>
        </ActionDialog>
    );
};
