import { injectable } from 'inversify';
import { lastValueFrom, map } from 'rxjs';
import { baseCommunicationService } from '@wk/office-companion-js-common';

export const enum FileOperationsChannel {
    PersistToStorage = 'persist-to-file-storage',
    DeleteFromStorage = 'delete-from-file-storage',
    SetInputFiles = 'set-input-files',
    ResolvePath = 'resolve-path',
    Download = 'download',
    Open = 'open-downloaded-file',
    Close = 'close-downloaded-file',
    Exists = 'downloaded-file-exists',
    IsOpen = 'is-downloaded-file-open',
    Delete = 'delete-downloaded-file',
    GetMetadata = 'read-downloaded-file-meta',
    GetMetadataByPath = 'read-downloaded-file-meta-by-path',
    ClearFileReferences = 'clear-file-references',
    ShowSaveDialog = 'show-save-dialog',
    ShowOpenDialog = 'show-open-dialog',
    CompareFilePaths = 'compare-file-paths',
}

export interface FileOperationsInterface {
    open(uniqueId: string): Promise<string>;
    getMetadata(uniqueId: string): Promise<PersistentFileMeta>;
    getMetadataByPath(path: string): Promise<FileMeta>;
    download(url: string, options?: DownloadOptions): Promise<string>;
    delete(uniqueId: string): Promise<void>;
    exists(uniqueId: string): Promise<boolean>;
    isOpen(uniqueId: string): Promise<boolean>;
    persistToStorage(file: File | { path: string }, parts?: string[]): Promise<FilePathInfo>;
    deleteFromStorage(path: string): Promise<void>;
    resolvePath(params: ResolvePathOptions): Promise<FilePathInfo>;
    setInputFiles(inputSelector: string, files: string[], iframeSelector?: string): Promise<void>;
    clearFileReferences(uniqueId: string, removeEntryFromDb: boolean): Promise<boolean>;
    showSaveDialog(fileName?: string): Promise<string | undefined>;
    showOpenDialog(options?: OpenDialogOptions): Promise<string[] | undefined>;
    compareFilePaths(path1: string, path2: string): Promise<CompareFilePathsResponse>;
}

// TODO: verify DownloadOptions comments are visible from storybook
// TODO: verify all links work properly for exported `FileOperations` object
/**
 * Optional arguments for {@link FileOperationsService.download}
 */
export interface DownloadOptions {
    /**
     * Headers to add to HTTP request.
     */
    requestHeaders?: Record<string, string>;

    /**
     * `true` to consider downloaded file temporary, it will be removed on process exit,
     * stored in temporary directory (on Windows _%LocalAppData%/Temp_), {@link uniqueId} is ignored.
     *
     * The only way to open temporary file is to use {@link openWhenDone} flag.
     *
     * Default location for persistent files is user's _My Documents_ directory.
     */
    temporary?: boolean;

    /**
     * Identifier of a downloaded file to be used with operations like {@link FileOperationsService.open},
     * {@link FileOperationsService.exists}, {@link FileOperationsService.isOpen}, {@link FileOperationsService.delete}.
     *
     * Ignored if {@link temporary} flag is set to `true`
     *
     * __Important__:
     *
     * Downloads with the same {@link uniqueId} overwrite each others metadata.
     * Make sure to provide different/unique paths if required to avoid such behavior.
     */
    uniqueId?: string;

    /**
     * `true` to open downloaded file, in this case returned promise will be resolved when file is opened.
     */
    openWhenDone?: boolean;

    /**
     * Additional public information (metadata) to attach to downloaded file.
     *
     * This information is accessible via {@link FileOperationsService.getMetadata}
     * or {@link FileOperationsService.getMetadataByPath}.
     * It can be used to pass parameters to new instance of _Office Companion_ created
     * inside application triggered by 'open' operation.
     *
     * __Important__:
     *
     * Field has size limitation of 16K characters which is applied to json form of object passed.
     */
    metadata?: Record<string, unknown>;

    /**
     * `true` to ask user where to save a file, otherwise default location is used according to other options.
     */
    showSaveAsDialog?: boolean;
}

export interface FileMeta {
    /**
     * Absolute path to the file on disk
     */
    path: string;

    /**
     * Name of the file
     */
    fileName: string;

    /**
     * Custom user information attached to this file
     */
    inputMetadata?: Record<string, unknown>;
}

export interface PersistentFileMeta extends FileMeta {
    /**
     * Id of the file
     */
    uniqueId: string;
}

export enum OpenDialogProperties {
    OpenFile = 'openFile',
    OpenDirectory = 'openDirectory',
}

/**
 * Optional arguments for {@link FileOperationsService.showOpenDialog}
 */
export interface OpenDialogOptions {
    /**
     * Default path to be open in explorer.
     */
    defaultPath?: string;
    /**
     * Contains which features the dialog should use.
     *    - openFile - Allow files to be selected.
     *    - openDirectory - Allow directories to be selected.
     */
    openDialogProperties?: OpenDialogProperties[];
}

/**
 * Members of this type can be used to reference file system paths by name:
 *    - fileStorage - directory to a permanent storage for user data.
 *    - temporaryFileStorage - directory to a temporary storage for user data, contents are automatically cleaned up in an unspecified time (TBD).
 *      // TODO clarify cleanup behavior when it is implemented.
 */
export type PathAlias = typeof pathAliases[number];
export const pathAliases = ['fileStorage', 'temporaryFileStorage'] as const;

/**
 * Arguments for {@link FileOperationsService.resolvePath}
 */
export interface ResolvePathOptions {
    /**
     * Name of a special directory to be used as path root.
     */
    rootName: PathAlias;

    /**
     * Optional. Directory names which will be joined together as a relative path from root.
     */
    parts?: string[];

    /**
     * Optional. Filename to append to resolved result.
     */
    filename?: string;
}

export interface FilePathInfo {
    /**
     * Full representations of resolved path.
     */
    fullFilePath: string;

    /**
     * Short representation (if supported by os settings) of resolved path.
     *   windows: converted to 8.3 notation
     *
     * In case of short path conversion is not supported the value will be equal to {@link ResolvePathOptions.fullFilePath}
     */
    filePath: string;
}

/**
 * Return value for {@link FileOperationsService.compareFilePaths}
 */
export interface CompareFilePathsResponse {
    /**
     * Comparison result.
     */
    equal: boolean;
}

@injectable()
export class FileOperationsService implements FileOperationsInterface {
    /**
     * Opens downloaded file.
     *
     * @param uniqueId  identifier of a downloaded file matching {@link DownloadOptions.uniqueId} value
     * provided in corresponding {@link download} call
     *
     * @returns  promise which resolves with name of opened file
     */
    public open(uniqueId: string): Promise<string> {
        return lastValueFrom(
            baseCommunicationService
                .invoke<string>(FileOperationsChannel.Open, { uniqueId })
                .pipe(map(({ data }) => data)),
        );
    }

    /**
     * Checks if file exists in file system.
     *
     * @param uniqueId  identifier of a downloaded file matching {@link DownloadOptions.uniqueId} value
     * provided in corresponding {@link download} call
     *
     * @returns  promise which resolves with `true` if file exists, `false` otherwise
     */
    public exists(uniqueId: string): Promise<boolean> {
        return lastValueFrom(
            baseCommunicationService
                .invoke<boolean>(FileOperationsChannel.Exists, { uniqueId })
                .pipe(map(({ data }) => data)),
        );
    }

    /**
     * Checks if file is opened by other application.
     *
     * API's behaviour is in line with windows consideration for file busy while performing other file operations
     * Eg. Delete operation in the file system manually.You can remove txt file though txt file is already open
     * but you cannot remove word/excel/pp if the file is already open.
     * This API will consider file busy behavior similar with the above cases while performing file open check.
     *
     * @param uniqueId  identifier of a downloaded file matching {@link DownloadOptions.uniqueId} value
     * provided in corresponding {@link download} call
     *
     * @returns  promise which resolves with `true` if file is already opened, `false` otherwise
     */
    public isOpen(uniqueId: string): Promise<boolean> {
        return lastValueFrom(
            baseCommunicationService
                .invoke<boolean>(FileOperationsChannel.IsOpen, { uniqueId })
                .pipe(map(({ data }) => data)),
        );
    }

    /**
     * Reads file metadata, which also includes {@link DownloadOptions.metadata| custom data}
     * passed with {@link download} call.
     *
     * @param uniqueId  identifier of a downloaded file matching {@link DownloadOptions.uniqueId} value
     * provided in corresponding {@link download} call
     *
     * @returns  promise which resolves with metadata object if found, rejects otherwise
     */
    public getMetadata(uniqueId: string): Promise<PersistentFileMeta> {
        return lastValueFrom(
            baseCommunicationService
                .invoke<PersistentFileMeta>(FileOperationsChannel.GetMetadata, { uniqueId })
                .pipe(map(({ data }) => data)),
        );
    }

    /**
     * Reads file metadata, which also includes {@link DownloadOptions.metadata| custom data}
     * passed with {@link download} call.
     *
     * @param path  absolute path to downloaded file
     *
     * @returns  promise which resolves with metadata object if found, rejects otherwise
     */
    public getMetadataByPath(path: string): Promise<FileMeta> {
        return lastValueFrom(
            baseCommunicationService
                .invoke<FileMeta>(FileOperationsChannel.GetMetadataByPath, { path })
                .pipe(map(({ data }) => data)),
        );
    }

    /**
     * Initiates a download of the resource without navigating.
     *
     * Sanitizes filename according to operating system restrictions replacing disallowed characters.
     *
     * @param url  resource URL to download from, URL must be valid and properly encoded.
     * @param options  optional arguments, please refer to {@link DownloadOptions}
     *
     * @returns  promise which resolves with name of downloaded file when download is finished
     */
    public download(url: string, options?: DownloadOptions): Promise<string> {
        return lastValueFrom(
            baseCommunicationService
                .invoke<string>(FileOperationsChannel.Download, { url, ...options })
                .pipe(map(({ data }) => data)),
        );
    }

    /**
     * Delete file from file system.
     *
     * @param uniqueId  identifier of a downloaded file matching {@link DownloadOptions.uniqueId} value
     * provided in corresponding {@link download} call
     *
     * @returns  void promise which rejects if operation failed
     */
    public delete(uniqueId: string): Promise<void> {
        return lastValueFrom(
            baseCommunicationService
                .invoke<void>(FileOperationsChannel.Delete, { uniqueId })
                .pipe(map(({ data }) => data)),
        );
    }

    /**
     * Moves specified temporary file to storage directory
     *
     * @param file  object with path to a file in system 'temp' directory
     * @param parts  optional directory names which will be joined together as a relative path from storage directory
     *
     * @returns  promise which resolves with an absolute path where the file was moved to
     */
    public async persistToStorage(file: File | { path: string }, parts?: string[]): Promise<FilePathInfo> {
        const path = file['path'];
        if (!path) {
            throw new Error('Path property of the file must be defined');
        }
        return await lastValueFrom(
            baseCommunicationService
                .invoke<FilePathInfo>(FileOperationsChannel.PersistToStorage, { path, parts })
                .pipe(map(({ data }) => data)),
        );
    }

    /**
     * Removes file and parent directories up to storage directory if they become empty after this operation.
     *
     * If an attempt is made to remove a file outside of storage directory predefined by {@link PathAlias} an error is thrown.
     *
     * @param path  path to a file
     */
    public deleteFromStorage(path: string): Promise<void> {
        return lastValueFrom(
            baseCommunicationService
                .invoke<void>(FileOperationsChannel.DeleteFromStorage, { path })
                .pipe(map(({ data }) => data)),
        );
    }

    /**
     * Sets files for the given file input element.
     *
     * @param inputSelector  selector to find input element by
     * @param files  absolute file paths to set (relative paths and directory paths are ignored)
     * @param iframeSelector  iframe selector if input element is inside iframe
     *
     * @returns  promise which resolves when operation completed successfully, rejects otherwise
     */
    public setInputFiles(inputSelector: string, files: string[], iframeSelector?: string): Promise<void> {
        return lastValueFrom(
            baseCommunicationService
                .invoke<void>(FileOperationsChannel.SetInputFiles, { inputSelector, files, iframeSelector })
                .pipe(map(({ data }) => data)),
        );
    }

    /**
     * Resolves predefined system path, automatically creates child directories if specified and escapes illegal characters.
     *
     * @param params  arguments to pass inside the call, please refer to {@link ResolvePathOptions} for details
     *
     * @returns  promise which resolves with an absolute path to the specified target
     */
    public resolvePath(params: ResolvePathOptions): Promise<FilePathInfo> {
        return lastValueFrom(
            baseCommunicationService
                .invoke<FilePathInfo>(FileOperationsChannel.ResolvePath, params)
                .pipe(map(({ data }) => data)),
        );
    }

    /**
     * Clears local references(eg. File on downloaded file path, local Db reference) of the file associated with the {@link uniqueId}.
     *
     * @param uniqueId will be used to fetch data persisted while storing file to file system.
     * must match {@link DownloadOptions.uniqueId} value specified for `download` operation.
     * @param removeEntryFromDb will not remove the reference from the local database if passed as false.
     *
     * @returns promise which resolves with boolean value. On successful operation, returns true and on failure returns false
     */
    // TODO Need to review the API design again against the file cleanup
    public clearFileReferences(uniqueId: string, removeEntryFromDb?: boolean): Promise<boolean> {
        return lastValueFrom(
            baseCommunicationService
                .invoke<boolean>(FileOperationsChannel.ClearFileReferences, { uniqueId, removeEntryFromDb })
                .pipe(map(({ data }) => data)),
        );
    }

    /**
     * Shows Save dialog to choose the path for saving file.
     *
     * @param fileName file name to use by default
     *
     * @returns  promise which resolves with absolute path of the file chosen by the user,
     * `undefined` if the dialog is cancelled .
     */
    public showSaveDialog(fileName?: string): Promise<string | undefined> {
        return lastValueFrom(
            baseCommunicationService
                .invoke<string | undefined>(FileOperationsChannel.ShowSaveDialog, { fileName })
                .pipe(map(({ data }) => data)),
        );
    }

    /**
     * Shows open dialog to choose the file/folder to open.
     *
     * @returns promise which resolves with path of the selected file/folder or undefined if the dialog is cancelled.
     */
    public showOpenDialog(options?: OpenDialogOptions): Promise<string[] | undefined> {
        return lastValueFrom(
            baseCommunicationService
                .invoke<string[] | undefined>(FileOperationsChannel.ShowOpenDialog, { ...options })
                .pipe(map(({ data }) => data)),
        );
    }

    /**
     * Compare two file paths.
     *
     * @param origin file path to compare
     *
     * @param compared file path to compare
     *
     * @returns promise which resolves with the result of file path comparison.
     */
    public compareFilePaths(origin: string, compared: string): Promise<CompareFilePathsResponse> {
        return lastValueFrom(
            baseCommunicationService
                .invoke<CompareFilePathsResponse>(FileOperationsChannel.CompareFilePaths, { origin, compared })
                .pipe(map(({ data }) => data)),
        );
    }
}
