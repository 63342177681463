import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const WkUserProfileIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon viewBox="0 0 32 32" data-testid="WkCircleArrowIcon" {...props}>
        <path
            style={{ fill: '#FFFFFF' }}
            d="M16,17.1c2.2,0,4-1.8,4-4c0-1.1-0.4-2.1-1.2-2.8c-0.7-0.8-1.8-1.2-2.8-1.2c-2.2,0-4,1.8-4,4S13.8,17.1,16,17.1   z"
        />
        <path
            style={{ fill: '#FFFFFF' }}
            d="M18.5,17.6c-0.8,0.4-1.6,0.7-2.5,0.6c-0.9,0-1.7-0.2-2.5-0.6c-2.2,0.9-3.9,2.9-4.3,5.2h13.6   C22.4,20.5,20.7,18.5,18.5,17.6z"
        />
        <path
            style={{ fill: '#FFFFFF' }}
            d="M16,0C7.2,0,0,7.2,0,16s7.2,16,16,16s16-7.2,16-16S24.8,0,16,0z M8,24c0-1.5,0.4-3,1.2-4.3   c0.8-1.2,1.9-2.2,3.2-2.9c-1-1-1.6-2.3-1.6-3.7c0-2.8,2.3-5.1,5.1-5.1s5.1,2.3,5.1,5.1c0,1.4-0.5,2.8-1.6,3.7   c1.3,0.6,2.4,1.6,3.2,2.9C23.6,21,24,22.5,24,24H8z"
        />
        <path
            style={{ fill: '#005B92' }}
            d="M19.6,16.9c1-1,1.6-2.3,1.6-3.7c0-2.8-2.3-5.1-5.1-5.1s-5.1,2.3-5.1,5.1c0,1.4,0.6,2.7,1.6,3.7   c-1.3,0.6-2.4,1.6-3.2,2.9C8.4,21,8,22.5,8,24h16c0-1.5-0.4-3-1.2-4.3C22,18.5,20.9,17.5,19.6,16.9z M12,13.1c0-2.2,1.8-4,4-4   c1.1,0,2.1,0.4,2.8,1.2c0.7,0.7,1.2,1.8,1.2,2.8c0,2.2-1.8,4-4,4S12,15.3,12,13.1z M9.2,22.9c0.4-2.4,2.1-4.3,4.3-5.2   c0.8,0.4,1.6,0.7,2.5,0.6c0.9,0,1.7-0.2,2.5-0.6c2.2,0.9,3.9,2.9,4.3,5.2H9.2z"
        />
    </SvgIcon>
);

export default WkUserProfileIcon;
