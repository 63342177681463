import { IFilterObj } from './types';

export const isSpecificDateFilter = (filter: IFilterObj): boolean => {
    return (
        (filter.attributeType === 'Date' || filter.attributeType === 'DateTime') &&
        ['EQUALS'].indexOf(filter.comparisonType) >= 0 &&
        filter.leftValue === 'Specific Date'
    );
};

export const isMultiRowFilter = (filter: IFilterObj): boolean => {
    return ['BETWEEN'].indexOf(filter.comparisonType) >= 0 || isSpecificDateFilter(filter);
};

export const isValuelessComparisonType = (comparisonType: string): boolean => {
    return ['IS_NULL', 'IS_NOT_NULL', 'IS_EMPTY', 'IS_NOT_EMPTY'].indexOf(comparisonType) >= 0;
};

export const displayComparisonOnly = (filter: IFilterObj): boolean => {
    return (
        isValuelessComparisonType(filter.comparisonType) || (isMultiRowFilter(filter) && !isSpecificDateFilter(filter))
    );
};
