import { injectable } from 'inversify';
import { OnDestroy } from '../core';
import { map, Observable, Subject, Subscription } from 'rxjs';
import { LoggerService } from '../../common/logger/logger.service';
import { baseCommunicationService } from '@wk/office-companion-js-common';

enum MessageAction {
    SendMessage = 'send-message',
}

export enum MessagingScope {
    OtherInstances = 'OtherInstances',
    AllInstances = 'AllInstances',
}

export interface MessageData {
    message: string;
    scope?: MessagingScope | undefined;
    type: string;
}

export interface MessagingInterface {
    getResponseStream(): Observable<MessageData>;
    notify(data: MessageData): void;
}

@injectable()
export class MessagingService implements MessagingInterface, OnDestroy {
    private messageResponseStream = new Subject<MessageData>();
    private messageRequestStream = new Subject<MessageData>();
    private subscription: Subscription;

    constructor(private logger: LoggerService) {}

    public getResponseStream(): Observable<MessageData> {
        return this.messageResponseStream.asObservable();
    }

    public notify(data: MessageData): void {
        this.messageRequestStream.next(data);
    }

    public startListeningForMessages(): void {
        this.subscription = baseCommunicationService
            .on<MessageData>(MessageAction.SendMessage, this.messageRequestStream)
            .pipe(
                map(({ data }) => {
                    return data;
                }),
            )
            .subscribe({
                next: (value) => {
                    this.messageResponseStream.next(value);
                },
                complete: () => this.logger.log(`STORY - Stream has been closed on main side`),
            });
    }

    public onDestroy(): void {
        this.subscription.unsubscribe();
    }
}
