import { ClientObject, ClientObjectProperty, PropertyInit } from '../common/clientObject';
import { RequestType, ValueHolder, ValueType } from '../comObject';
import { PpWindowState } from './windowState.enum';

export class DocumentWindow extends ClientObject implements PropertyInit {
    private properties = this.clientObjectInitPropsInfo({
        windowState: { propertyName: 'windowState', name: 'WindowState' },
    });

    public getPropertyInitializers(): Map<keyof DocumentWindow, () => void> {
        return new Map<keyof DocumentWindow, () => void>(
            Object.values(this.properties).map(({ propertyName, name }) => [
                propertyName,
                (): void =>
                    this.reference.request({
                        type: RequestType.GetProperty,
                        cacheName: propertyName,
                        name,
                    }),
            ]),
        );
    }

    public get windowState(): PpWindowState {
        return this.reference.getValue<PpWindowState>(this.properties.windowState.propertyName).value;
    }

    public set windowState(value: PpWindowState) {
        this.setProperty(this.properties.windowState, { type: ValueType.Integer, value });
    }

    private setProperty<T>(property: ClientObjectProperty<DocumentWindow>, valueHolder: ValueHolder<T>): void {
        const { propertyName, name } = property;

        this.reference.request({
            type: RequestType.SetProperty,
            cacheName: propertyName,
            name,
            args: [valueHolder],
        });
    }
}
