import { injectable } from 'inversify';
import { OfficeService } from '../common/office.service';
import { OutlookContext } from './outlookContext';
import { OfficeAppType } from '../common/officeAppType.enum';

export interface OutlookInterface {
    run<T>(batch: (context: OutlookContext) => Promise<T> | T): Promise<T>;
}

@injectable()
export class OutlookService implements OutlookInterface {
    constructor(private office: OfficeService) {}

    public run<T>(batch: (context: OutlookContext) => Promise<T> | T): Promise<T> {
        return this.office.run<T>(OutlookContext, batch, OfficeAppType.Outlook);
    }
}
