import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const wkShowIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="wkShowIcon" viewBox="0 0 24 24" {...props}>
        <path
            d="M8,11.4c-1.9-0.1-3.8-0.8-5.3-2C2,8.8,1.3,8.2,0.8,7.4C0.3,6.9,0.1,6.2,0,5.5
		c0.1-0.7,0.3-1.4,0.8-1.9c0.6-0.7,1.2-1.4,2-1.9c1.5-1.2,3.4-1.9,5.3-2c1.9,0.1,3.8,0.8,5.3,2c0.7,0.5,1.4,1.2,2,1.9
		c0.5,0.5,0.7,1.2,0.8,1.9c-0.1,0.7-0.3,1.4-0.8,1.9c-0.6,0.7-1.2,1.4-2,1.9C11.8,10.5,9.9,11.2,8,11.4z M8,0.7
		C6.3,0.8,4.7,1.4,3.4,2.5c-1.4,1-2.3,2.2-2.3,3s0.9,2,2.3,3c1.3,1.1,2.9,1.7,4.6,1.8c1.7-0.1,3.3-0.7,4.6-1.8c1.4-1,2.3-2.2,2.3-3
		s-0.9-2-2.3-3C11.3,1.4,9.7,0.8,8,0.7z M8,9.2c-2.1,0-3.7-1.7-3.7-3.7C4.3,3.4,6,1.8,8,1.8s3.7,1.7,3.7,3.7c0,1-0.4,1.9-1.1,2.6
		C9.9,8.8,9,9.2,8,9.2z M8,2.8C6.5,2.8,5.3,4,5.3,5.5S6.5,8.2,8,8.2s2.7-1.2,2.7-2.7l0,0C10.7,4,9.5,2.8,8,2.8z"
        />
    </SvgIcon>
);

export default wkShowIcon;
