import { myContainer } from '../core';
import { CapabiltyEnum } from '../enum/enum';
import { IContextInputType } from '../interfaces/operations';
import { ICtxtOperationTransformerNew, ITransformerNew } from './types';

export function getTransformedObject<T extends IContextInputType, K, P extends ITransformerNew<T, K>>(
    arg0: CapabiltyEnum,
    arg1: T,
): K {
    const operationTransformer = myContainer.get<ICtxtOperationTransformerNew<T, K>>('CtxOperationTransformerNew');
    const transformerfactory: (arg0: CapabiltyEnum, arg1: T) => P = myContainer.get('Factory<ITransformerNew>');
    const transformer = transformerfactory(arg0, arg1);
    const nodeObj = operationTransformer.transform(transformer);
    return nodeObj;
}

export function getReverseTransformedObject<T, K, P extends ITransformerNew<T, K>>(arg0: CapabiltyEnum, arg1: T): K {
    const operationTransformer = myContainer.get<ICtxtOperationTransformerNew<T, K>>('CtxOperationTransformerNew');
    const transformerfactory: (arg0: CapabiltyEnum, arg1: T) => P = myContainer.get('Factory<ITransformerNew>');
    const transformer = transformerfactory(arg0, arg1);
    const nodeObj = operationTransformer.transform(transformer);
    return nodeObj;
}
