import { apiFetchDelete, apiFetchGet, apiFetchPost, apiFetchPut } from '../../api/fetchUtils';
import { CapabiltyEnum } from '../../enums/capability';
import { DocSupportedBaseEntityTypesEnum } from '../../enums/supportedEntityTypesEnum';
import { EValidator } from '../../enums/validators';
import { IMapMetadataFolder, IMapMetdata } from '../../utils/main.utils';

export const matchOperations = {
    [CapabiltyEnum.ADD_DOCUMENT]: 'ADD_DOCUMENT',
    [CapabiltyEnum.ADD_FOLDER]: 'ADD_FOLDER_NESTED',
    [CapabiltyEnum.ADD_EMAIL]: 'ADD_EMAIL',
    [CapabiltyEnum.CHECKIN]: 'CHECK_IN',
    [CapabiltyEnum.CHECKOUT]: 'CHECK_OUT',
    [CapabiltyEnum.DELETE_DOCUMENT]: 'DELETE_DOCUMENT',
    [CapabiltyEnum.DELETE_FOLDER]: 'DELETE_FOLDER',
    [CapabiltyEnum.DELETE_EMAIL]: 'DELETE_EMAIL',
    [CapabiltyEnum.DOWNLOAD_DOCUMENT]: 'DOWNLOAD_DOCUMENT',
    [CapabiltyEnum.CANCEL_CHECKOUT]: 'UNDO_CHECK_OUT',
    [CapabiltyEnum.RENAME_FOLDER]: 'RENAME_FOLDER',
    [CapabiltyEnum.RENAME_DOCUMENT]: 'RENAME_DOCUMENT',
    [CapabiltyEnum.DOCUMENT_METADATA]: 'GET_DOCUMENT_METADATA',
    [CapabiltyEnum.DOCUMENT_METADATA_FILENAME]: 'GET_DOCUMENT_METADATA_FILENAME',
    [CapabiltyEnum.DOCUMENT_METADATA_EMAIL]: 'GET_EMAIL_METADATA',
    [CapabiltyEnum.GET_NOTIFICATIONS_FILES]: 'POLL_FILES',
    [CapabiltyEnum.GET_SUPPORTED_ENTITY_TYPES]: 'GET_SUPPORTED_ENTITY_TYPES',
    [CapabiltyEnum.EDIT_DOCUMENT]: 'EDIT_DOCUMENT_PROPERTIES',
    [CapabiltyEnum.EDIT_FOLDER]: 'EDIT_FOLDER_PROPERTIES',
    [CapabiltyEnum.VALIDATE_DOCUMENT]: 'VALIDATE_ADD_DOCUMENT',
    [CapabiltyEnum.VALIDATE_CHECKIN_DOCUMENT]: 'VALIDATE_CHECK_IN_DOCUMENT',
    [CapabiltyEnum.FOLDER_METADATA]: 'GET_FOLDER_METADATA',
};

export const transformOperation = {};

export const actionPerformerMethods = {
    [CapabiltyEnum.CHECKOUT]: apiFetchPost,
    [CapabiltyEnum.ADD_DOCUMENT]: apiFetchPost,
    [CapabiltyEnum.ADD_EMAIL]: apiFetchPost,
    [CapabiltyEnum.ADD_FOLDER]: apiFetchPost,
    [CapabiltyEnum.CANCEL_CHECKOUT]: apiFetchPost,
    [CapabiltyEnum.CHECKIN]: apiFetchPost,
    [CapabiltyEnum.DELETE_DOCUMENT]: apiFetchDelete,
    [CapabiltyEnum.DELETE_EMAIL]: apiFetchDelete,
    [CapabiltyEnum.DELETE_EMAIL_FOLDER]: apiFetchPost,
    [CapabiltyEnum.DELETE_FOLDER]: apiFetchDelete,
    [CapabiltyEnum.DOCUMENT_METADATA]: apiFetchGet,
    [CapabiltyEnum.DOCUMENT_METADATA_FILENAME]: apiFetchGet,
    [CapabiltyEnum.DOWNLOAD_DOCUMENT]: apiFetchGet,
    [CapabiltyEnum.DOWNLOAD_VERSION_DOCUMENT]: apiFetchGet,
    [CapabiltyEnum.GET_NOTIFICATIONS_FILES]: apiFetchGet,
    [CapabiltyEnum.RENAME_DOCUMENT]: apiFetchGet,
    [CapabiltyEnum.RENAME_FOLDER]: apiFetchPut,
    [CapabiltyEnum.GET_SUPPORTED_ENTITY_TYPES]: apiFetchPost,
    [CapabiltyEnum.EDIT_DOCUMENT]: apiFetchPut,
    [CapabiltyEnum.EDIT_FOLDER]: apiFetchPut,
    [CapabiltyEnum.VALIDATE_DOCUMENT]: apiFetchPost,
    [CapabiltyEnum.VALIDATE_CHECKIN_DOCUMENT]: apiFetchPost,
    [CapabiltyEnum.FOLDER_METADATA]: apiFetchGet,
};

export const inputValidators = {
    [CapabiltyEnum.CHECKOUT]: [EValidator.EntityContextValidator, EValidator.DocValidator],
    [CapabiltyEnum.ADD_DOCUMENT]: [EValidator.EntityContextValidator, EValidator.DocFileSizeValidator],
    [CapabiltyEnum.ADD_EMAIL]: [EValidator.EntityContextValidator, EValidator.DocFileSizeValidator],
    [CapabiltyEnum.ADD_FOLDER]: [EValidator.EntityContextValidator],
    [CapabiltyEnum.CANCEL_CHECKOUT]: [EValidator.EntityContextValidator, EValidator.DocValidator],
    [CapabiltyEnum.CHECKIN]: [EValidator.EntityContextValidator, EValidator.DocFileSizeValidator],
    [CapabiltyEnum.DELETE_DOCUMENT]: [EValidator.EntityContextValidator, EValidator.DocValidator],
    [CapabiltyEnum.DELETE_EMAIL]: [EValidator.EntityContextValidator, EValidator.DocValidator],
    [CapabiltyEnum.DELETE_EMAIL_FOLDER]: [EValidator.EntityContextValidator, EValidator.DocValidator],
    [CapabiltyEnum.DELETE_FOLDER]: [EValidator.EntityContextValidator, EValidator.DocValidator],
    [CapabiltyEnum.DOCUMENT_METADATA]: [EValidator.EntityContextValidator, EValidator.DocValidator],
    [CapabiltyEnum.DOCUMENT_METADATA_EMAIL]: [EValidator.EntityContextValidator, EValidator.DocValidator],
    [CapabiltyEnum.DOCUMENT_METADATA_FILENAME]: [
        EValidator.EntityContextValidator,
        EValidator.DocMetaFileNameValidator,
    ],
    [CapabiltyEnum.DOWNLOAD_DOCUMENT]: [EValidator.EntityContextValidator, EValidator.DocValidator],
    [CapabiltyEnum.DOWNLOAD_VERSION_DOCUMENT]: [EValidator.EntityContextValidator],
    [CapabiltyEnum.DOWNLOAD_EMAIL]: [EValidator.EntityContextValidator, EValidator.DocValidator],
    [CapabiltyEnum.GET_NOTIFICATIONS_FILES]: [],
    [CapabiltyEnum.RENAME_DOCUMENT]: [EValidator.EntityContextValidator, EValidator.DocValidator],
    [CapabiltyEnum.RENAME_FOLDER]: [EValidator.EntityContextValidator, EValidator.DocValidator],
    [CapabiltyEnum.GET_SUPPORTED_ENTITY_TYPES]: [],
    [CapabiltyEnum.EDIT_DOCUMENT]: [EValidator.EntityContextValidator, EValidator.DocValidator],
    [CapabiltyEnum.EDIT_FOLDER]: [EValidator.EntityContextValidator],
    [CapabiltyEnum.VALIDATE_DOCUMENT]: [],
    [CapabiltyEnum.VALIDATE_CHECKIN_DOCUMENT]: [],
};

export const supportedEntityTypesMap = {
    document: DocSupportedBaseEntityTypesEnum.DOCUMENT,
    email: DocSupportedBaseEntityTypesEnum.EMAIL,
    matter: DocSupportedBaseEntityTypesEnum.MATTER,
};

export const matchMetadataFolder: IMapMetadataFolder = {
    id: 'documentFolderId',
};

export const matchMetadata: IMapMetdata = {
    id: 'documentId',
    name: 'documentName',
    description: '',
    accessToken: '',
    networkId: '',
    createdBy: null,
    creationDate: null,
    lastModifiedBy: null,
    lastModificationDate: null,
    documentFileName: 'documentName',
    webDavPath: null,
    parentLink: null,
    documentSize: null,
    changeToken: null,
    type: null,
    baseTypeId: null,
    isFolder: null,
    parentId: null,
    path: null,
    versionLabel: 'versionLabel',
    checkInComment: null,
    contentStreamId: null,
    contentStreamFileName: null,
    contentStreamLength: null,
    contentStreamMimeType: null,
    isImmutable: null,
    versionSeriesId: null,
    isVersionSeriesCheckedOut: 'isCheckedOut',
    versionSeriesCheckedOutBy: 'checkedOutByUserName',
    versionSeriesCheckedOutId: null,
    isLatestMajorVersion: null,
    isLatestVersion: 'isCurrentVersion',
    isMajorVersion: null,
    isPrivateWorkingCopy: null,
    isVersionable: null,
    isVersionSeriesPrivateWorkingCopy: null,
    contentFile: null,
    relatedEntityType: 'relatedEntityType',
    relatedEntityName: 'relatedEntityName',
    relatedEntityId: 'relatedEntityId',
    isArchived: null,
    archivedDate: null,
    pabuId: null,
    picId: null,
    isEmailMessage: null,
    isEmailRoot: null,
    senderEmailAddress: null,
    emailSubject: null,
    senderName: null,
    emailRecipients: null,
    isAttachmentPresent: null,
    emailBody: null,
    receivedTime: null,
    sentTime: null,
    uniqueId: null,
    docStorId: null,
    contentSearch: null,
    isRecursiveSearch: null,
    immediateParentId: null,
    fileKey: null,
    downloadUrl: 'downloadURL',
    latestVersion: 'latestVersion',
};

export const emailMatchMetadata: IMapMetdata = {
    id: 'emailId',
    name: null,
    description: '',
    accessToken: '',
    networkId: '',
    createdBy: null,
    creationDate: null,
    lastModifiedBy: null,
    lastModificationDate: null,
    documentFileName: null,
    webDavPath: null,
    parentLink: null,
    documentSize: null,
    changeToken: null,
    type: null,
    baseTypeId: null,
    isFolder: null,
    parentId: null,
    path: null,
    versionLabel: 'versionLabel',
    checkInComment: null,
    contentStreamId: null,
    contentStreamFileName: null,
    contentStreamLength: null,
    contentStreamMimeType: null,
    isImmutable: null,
    versionSeriesId: null,
    isVersionSeriesCheckedOut: null,
    versionSeriesCheckedOutBy: null,
    versionSeriesCheckedOutId: null,
    isLatestMajorVersion: null,
    isLatestVersion: null,
    isMajorVersion: null,
    isPrivateWorkingCopy: null,
    isVersionable: null,
    isVersionSeriesPrivateWorkingCopy: null,
    contentFile: null,
    relatedEntityType: 'relatedEntityType',
    relatedEntityName: 'relatedEntityName',
    relatedEntityId: 'relatedEntityId',
    isArchived: null,
    archivedDate: null,
    pabuId: null,
    picId: null,
    isEmailMessage: 'isEmailMessage',
    isEmailRoot: 'isEmailRoot',
    senderEmailAddress: 'senderEmailAddress',
    emailSubject: 'emailSubject',
    senderName: 'senderName',
    emailRecipients: 'emailTo',
    isAttachmentPresent: 'isAttachmentPresent',
    emailBody: 'emailBody',
    receivedTime: 'receivedTime',
    sentTime: 'sentTime',
    uniqueId: null,
    docStorId: null,
    contentSearch: null,
    isRecursiveSearch: null,
    immediateParentId: null,
    fileKey: null,
    downloadUrl: 'downloadURL',
    latestVersion: null,
};
