import { ClientObject, PropertyInit } from '../common/clientObject';
import { RequestType } from '../comObject';

export class AddressEntry extends ClientObject implements PropertyInit {
    private properties = this.clientObjectInitPropsInfo({
        id: { propertyName: 'id', name: 'ID' },
        name: { propertyName: 'name', name: 'Name' },
    });

    public getPropertyInitializers(): Map<keyof AddressEntry, () => void> {
        return new Map<keyof AddressEntry, () => void>(
            Object.values(this.properties).map(({ propertyName, name }) => [
                propertyName,
                (): void =>
                    this.reference.request({
                        type: RequestType.GetProperty,
                        cacheName: propertyName,
                        name,
                    }),
            ]),
        );
    }

    public get id(): string {
        return this.reference.getValue<string>(this.properties.id.propertyName).value;
    }

    public get name(): string {
        return this.reference.getValue<string>(this.properties.name.propertyName).value;
    }
}
