import { IDocCapability } from './type';
import { DCResponse, IDocServiceRequest, IDocType, IMatterDoc } from '../types';
import { ActionSequence, IUrlReplacer } from '../dmInfoHandler/types';
import { injectable } from 'inversify';
import { IDocOperationInvocation } from '../interfaces/urlPattern/types';
import { factory } from '../ConfigLog4j';
import { matchOperations } from '../t360Adapter/utils/maps';
import { getNetworkId } from '../t360Adapter/utils/utils';
import { getHostName } from '../utils/main.utils';
import { GetUrlEnum } from '../enums/urlPattern';
const log = factory.getLogger('Capability');

@injectable()
export class Capability implements IDocCapability {
    actionSequence: ActionSequence;

    urlReplacer: IUrlReplacer;

    urlPattern: IDocOperationInvocation;

    async performDocOperation(nodeObj: IDocType): Promise<DCResponse> {
        await this.urlPattern.commandId;
        this.validateInput(nodeObj);

        const outActionSequence: ActionSequence = await this.transformInput(nodeObj);
        return await this.performOperation(outActionSequence, nodeObj);
    }

    /**
     * Validating the doc-componet input
     *
     */
    validateInput = (nodeObj: IDocServiceRequest): boolean => {
        return this.actionSequence.inputValidator.every((inputValidatorObj) => {
            return inputValidatorObj.performValidation(nodeObj);
        });
    };

    /**
     * transform the Obj to what the api wants
     */
    async transformInput(nodeObj: IDocServiceRequest): Promise<ActionSequence> {
        const copiedInputActionSequence: ActionSequence = Object.create(this.actionSequence);
        const matterDetails: IMatterDoc | undefined = await this.actionSequence.transformFulfiller.getMatterDetails(
            nodeObj,
            this.urlPattern,
        );
        copiedInputActionSequence.actiontransformedObject =
            await this.actionSequence.actionTransformer.transformIntoActionableEntity(
                nodeObj,
                matterDetails,
                this.urlPattern,
            );

        return copiedInputActionSequence;
    }

    /**
     *
     * @param actionSequence
     * @param nodeObj
     * Creates the url and calls the action Performer
     */
    async performOperation(actionSequence: ActionSequence, nodeObj: IDocType): Promise<DCResponse> {
        log.debug('performOperation in Capability called');
        // actionSequenceList.map((actionSequence) => {

        let url: Promise<string>;

        if (this.urlPattern.getUrlEnum == GetUrlEnum.DMINFO) {
            // log.debug('this urlpattern is ', this.urlPattern.operationsUrl);
            const url_func = async () => {
                let operUrl = this.urlPattern.operationsUrl[matchOperations[this.urlPattern.operationName]];

                if (operUrl) {
                    operUrl = await operUrl.replace(/\{networkId\}/, await getNetworkId());
                    operUrl = operUrl.replace(
                        /\{entityType\}/,
                        this.urlPattern.ObjectTypeMap[nodeObj.entityContext.type],
                    );

                    operUrl = operUrl.replace(/\{entityId\}/, nodeObj.entityContext.id);

                    operUrl = operUrl.replace(/\{documentId\}/, nodeObj.docId);

                    operUrl = operUrl.replace(/\{matterId\}/, nodeObj.entityContext.id);

                    operUrl = operUrl.replace(/\{emailId\}/, nodeObj.docId);

                    if (nodeObj?.documentFolderId) {
                        operUrl = operUrl.replace(/\{documentFolderId\}/, nodeObj.documentFolderId);
                    } else {
                        operUrl = operUrl.replace(/\{documentFolderId\}/, nodeObj.docId);
                    }

                    // for replacing version
                    if (nodeObj?.extendedProps?.version) {
                        operUrl = operUrl.replace(/\{version\}/, nodeObj?.extendedProps.version);
                    } else if (actionSequence?.actiontransformedObject?.version) {
                        operUrl = operUrl.replace(/\{version\}/, actionSequence.actiontransformedObject.version);
                    }
                    // operUrl = this.urlReplacer.replacer(operUrl, nodeObj);
                    return getHostName() + '/' + operUrl;
                } else {
                    log.debug('Operation Url not found');
                    return '';
                }
            };

            url = url_func();
        } else {
            url = this.urlPattern.getUrl(nodeObj);
        }
        log.debug('before calling performAction ' + url + this.urlPattern);
        return actionSequence.actionObject.performAction(actionSequence.actiontransformedObject, url, this.urlPattern);
    }
}
