import { injectable } from 'inversify';
import { IQueryStringAppender } from './interfaces/interface';
import { apiFetchPost } from '@wk/elm-uui-doc-component';
import { UserManager, ApiContextRoot, ApiContextPath } from './constants';

const oneTimeTokenEndpoint = '/OneTimeAuthToken/create';

@injectable()
export class TokenQueryStringAppender implements IQueryStringAppender {
    public appendQueryString = async (url: string): Promise<string> => {
        // TO DO
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const windProps = (window as any).Props;

        const user = await windProps[UserManager].getUser();
        const access_token = user.access_token;

        const tokenEndpoint = windProps[ApiContextRoot] + windProps[ApiContextPath] + oneTimeTokenEndpoint;
        const postdata = { ExternalUrl: url };
        const tokenResponse = await apiFetchPost(tokenEndpoint, postdata, 'Bearer ' + access_token);

        const encryptedUrl = await tokenResponse.json();
        return encryptedUrl;
    };
}
