import 'isomorphic-fetch';
import { factory } from '../ConfigLog4j';
import * as convert from 'xml-js';
import { createXmlObject } from '../jRAdapter/libraries/createXml';
import { getValues, handleUnExpectedFetchError, isEmpty, UnExpectedResponseCodes } from '../utils/shared.utils';
import { isAuthToken } from '../utils/auth.utils';
import { IActionableEntity } from '../interfaces/actionEntity/type';
import { JRAdapterAddDocumentActionableEntity } from '../jRAdapter/actionableEntity/addDocumentActionableEntity';
import { JRAdapterCheckInActionableEntity } from '../jRAdapter/actionableEntity/checkInActionableEntity';
import { JRAdapterRenameDocumentActionableEntity } from '../jRAdapter/actionableEntity/renameDocumentActionableEntity';
import { IFetchOptions } from '../interfaces/fetch/type';
import { attributeTypes } from '../jRAdapter/types';
import { DCResponse, UnExpectedError } from '../types';
import { UUIFetch } from '@wk/elm-uui-common';
import { UNEXPECTED_ERROR } from '../constants';

const log = factory.getLogger('RestApi/fetchUtils');

export async function updateResponse(response: Response): Promise<DCResponse> {
    let docError: Record<string, string> = {};
    if (!response.ok) {
        const respStatusAvailable = UnExpectedResponseCodes.find((statusCode) => {
            return response.status == statusCode;
        });
        // If any of the response is present we throw Error
        if (respStatusAvailable) {
            log.error('Throwing error for  ' + UnExpectedResponseCodes.toString() + ' already handled by UUI commons');
            throw new UnExpectedError(UNEXPECTED_ERROR);
        }
        try {
            log.debug('Adding status Info error');
            const errorXml = await response.text();
            const errorJsonText = convert.xml2json(errorXml);
            // For handling edge case when the error has a Message
            // we assume there will be only a single Message
            if (errorXml.includes('ns1:Messages')) {
                const errorJson: { elements: [{ elements: [{ text: string }, { elements: [{ text: string }] }] }] } =
                    JSON.parse(errorJsonText);
                docError = { default: errorJson.elements[0].elements[1].elements[0].text };
            } else {
                const errorJson2: { elements: [{ elements: [{ attributes: { errors: string } }] }] } =
                    JSON.parse(errorJsonText);
                docError = getValues(errorJson2, 'errors');
            }
        } catch (error) {
            log.error('Received error in response json' + error);
        }
        const dcResponse = response as DCResponse;
        dcResponse.statusInfo = { errors: docError };
        return dcResponse;
    } else {
        return response as DCResponse;
    }
}

export function apiRestFetchGet(url: string, _: IActionableEntity, token: string): Promise<DCResponse> {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };

    // if node environment
    if (isAuthToken()) {
        headers['Authorization'] = token;
    }

    const options = {
        method: 'GET',
        headers,
        // agent,
    };

    return UUIFetch.fetch(url, options)
        .then((response) => {
            return updateResponse(response);
        })
        .catch((err) => {
            handleUnExpectedFetchError(err);
            throw err;
        });
}

function fetchTemplate(
    url: string,
    fetchPostData: IActionableEntity,
    token: string,
    methodName = 'POST',
): Promise<DCResponse> {
    const headers = {
        'Content-Type': 'application/xml',
    };

    // if node environment
    if (isAuthToken()) {
        headers['Authorization'] = token;
    }

    const options: IFetchOptions = {
        method: methodName,
        headers,
    };

    if (!isEmpty(fetchPostData)) {
        const fetchData = fetchPostData as attributeTypes;
        const xmlRequest = createXmlObject(fetchData);
        options.body = xmlRequest;
    }
    return UUIFetch.fetch(url, options).then((response) => {
        return response as DCResponse;
    });
}

export function apiFetchPost(url: string, fetchPostData: IActionableEntity, token: string): Promise<DCResponse> {
    return fetchTemplate(url, fetchPostData, token, 'POST')
        .then(async (response) => {
            return await updateResponse(response);
        })
        .catch((err) => {
            handleUnExpectedFetchError(err);
            throw err;
        });
}

export async function apiDummyResponse(): Promise<DCResponse> {
    const response = new Response();
    return (await response) as DCResponse;
}

export function dmInfoFetch(url: string, token: string): Promise<DCResponse> {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };

    if (isAuthToken()) {
        headers['Authorization'] = token;
    }

    const options = {
        method: 'GET',
        headers,
    };
    return UUIFetch.fetch(url, options).then((response) => {
        return response as DCResponse;
    });
}

export function apiFetchPut(url: string, fetchPostData: IActionableEntity, token: string): Promise<DCResponse> {
    return fetchTemplate(url, fetchPostData, token, 'PUT')
        .then(async (response) => {
            return await updateResponse(response);
        })
        .catch((err) => {
            handleUnExpectedFetchError(err);
            throw err;
        });
}

// For doing multipart FormData api calls
// TODO: see if this can be converted to fetch call
function multipartFormdata(
    url: string,
    fetchPostData: attributeTypes,
    token: string,
    method = 'POST',
): Promise<DCResponse> {
    const data = fetchPostData['data'];
    // delete fetchPostData['data'];
    const xmlRequest = createXmlObject(fetchPostData);
    const arrayBufferData = base64toArrayBuffer(data);
    const boundary = '----RubyFormBoundarycnOUbgJx42tmcwYJ';
    const bodyBoundary = '--' + boundary;

    const encoder = new TextEncoder();

    const part1 = encoder.encode(
        bodyBoundary +
            '\r\nContent-Disposition: form-data; name="xmlData"' +
            '\r\nContent-Type: application/xml' +
            '\r\n' +
            '\r\n' +
            xmlRequest +
            '\r\n' +
            bodyBoundary +
            '\r\nContent-Disposition: form-data; name=' +
            fetchPostData.attributes.documentFile.name +
            '; filename=' +
            fetchPostData.attributes.documentFile.name +
            '\r\nContent-Type: text/plain' +
            '\r\n' +
            '\r\n',
    );
    const part2 = new Uint8Array(arrayBufferData);
    const part3 = encoder.encode('\r\n' + bodyBoundary + '--');

    const body = new Uint8Array(part1.length + part2.length + part3.length);
    body.set(part1, 0);
    body.set(part2, part1.length);
    body.set(part3, part1.length + part2.length);

    const request = new XMLHttpRequest();

    return new Promise((resolve, reject) => {
        request.onreadystatechange = () => {
            // if not Done we simply return
            if (request.readyState !== 4) {
                return;
            }

            const myHeaders = new Headers();

            const locationHeader = request.getResponseHeader('location');
            const contextTypeHeader = request.getResponseHeader('content-type');
            if (locationHeader) {
                myHeaders.append('location', locationHeader);
            }

            if (contextTypeHeader) {
                myHeaders.append('content-type', contextTypeHeader);
            }
            const init = { status: request.status, statusText: request.statusText, headers: myHeaders };
            const responseObj = new DCResponse(request.response, init);

            resolve(responseObj);
        };

        request.onerror = function () {
            log.error('error occured during AddDocument');
            reject('error occured during AddDocument');
        };

        request.open(method, url);

        if (isAuthToken()) {
            request.setRequestHeader('Authorization', token);
        }

        request.setRequestHeader('Content-Type', 'multipart/form-data; boundary=' + boundary);
        request.send(body);
    });
}

// For AddDocument
export function apiFetchRestAddFile(
    url: string,
    fetchPostData: JRAdapterAddDocumentActionableEntity,
    token: string,
): Promise<DCResponse> {
    return multipartFormdata(url, fetchPostData, token, 'POST').then(async (response) => {
        return await updateResponse(response);
    });
}

const base64toArrayBuffer = (base64: string) => {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
};

// For Rename
export function apiFetchPutRestAddFile(
    url: string,
    fetchPostData: JRAdapterRenameDocumentActionableEntity,
    token: string,
): Promise<DCResponse> {
    return multipartFormdata(url, fetchPostData, token, 'PUT').then(async (response) => {
        return await updateResponse(response);
    });
}

// For CheckIn
export function apiFetchRestCheckInFile(
    url: string,
    fetchPostData: JRAdapterCheckInActionableEntity,
    token: string,
): Promise<DCResponse> {
    return multipartFormdata(url, fetchPostData, token, 'PUT').then(async (response) => {
        return await updateResponse(response);
    });
}
