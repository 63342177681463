import { ICHMessagingInterface } from '../services/interface';
import { IEventService } from '../services';
import { Dialog } from '../configurations';
import { EventType } from '../enum/enum';
import { factory } from '../configLog4J';

const log = factory.getLogger('network.utils');

export const networkSwitchDialog = async (
    messageServiceObj: ICHMessagingInterface,
    eventServiceObj: IEventService,
): Promise<void> => {
    const proceedButton = {
        label: Dialog.buttons.proceed,
        callback: () => {
            log.debug('Network Proceed called');
            eventServiceObj.publish({ name: EventType.GLOBAL_SPINNER, isOpen: true });
            messageServiceObj.notifyNetworkSwitch();
            return;
            // await this.queueCapability.pauseQueue();
        },
    };

    const cancelButton = {
        label: Dialog.buttons.cancel,
        callback: async () => {
            log.debug('Network Cancel called');
            messageServiceObj.notifyNetworkSwitch(false);
            return;
        },
    };

    const messageTemplate = 'network_event';
    const title = Dialog.confirm[messageTemplate].title;
    const message = Dialog.confirm[messageTemplate].message;
    const buttons = [proceedButton, cancelButton];

    eventServiceObj.publish({
        name: EventType.DIALOG,
        dialog: {
            title,
            message,
            buttons,
        },
    });
    return;
};
