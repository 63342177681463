export const isNodeEnvironment = (): boolean => {
    return typeof process === 'undefined' ? false : 'release' in process;
};

/**
 * Performs a delay for the specified time.
 *
 * @param {number} ms Waiting time in milliseconds.
 * @return {Promise<void>}
 */
export const sleep = (ms: number): Promise<void> =>
    new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
