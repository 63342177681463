import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const wkOpenEmailIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="wkOpenEmailIcon" viewBox="0 0 40 40" {...props}>
        <path d="M25,25H0V8.438L10.52.625a3.281,3.281,0,0,1,3.855,0L25,8.438ZM12.5,14.792a1.338,1.338,0,0,0-.834.312L2.083,23.333H22.916L13.333,15.1a1.334,1.334,0,0,0-.833-.311ZM23.333,10.73l-6.145,5.52,6.147,5.209Zm-21.666.1V21.458l6.041-5.1ZM12.5,1.667a1.5,1.5,0,0,0-.937.313L2.083,8.958l6.877,6.355,1.666-1.458a2.739,2.739,0,0,1,1.836-.7,3.042,3.042,0,0,1,1.914.7l1.563,1.353,6.875-6.25-9.375-6.98a1.5,1.5,0,0,0-.937-.311Z" />
    </SvgIcon>
);

export default wkOpenEmailIcon;
