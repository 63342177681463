import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const WkCloudDownArrowIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="wkCloudDownArrowIcon" viewBox="0 0 30 30" {...props}>
        <g transform="translate(-1616 -365)">
            <g transform="translate(1615.999 352.315)">
                <g transform="translate(0.001 15.685)">
                    <path
                        style={{ fill: '#940c72' }}
                        d="M26.257,26.785l-.039-.028-.23-.228V25.512a9.838,9.838,0,0,0-9.827-9.827,10.042,10.042,0,0,0-9.673,7.689v.238H6.144A8.565,8.565,0,0,0,0,31.847a8.706,8.706,0,0,0,8.591,8.591H22.805a7.364,7.364,0,0,0,7.355-7.352A7.605,7.605,0,0,0,26.257,26.785Zm.4,10.188a5.547,5.547,0,0,1-4,1.733H8.437a6.786,6.786,0,0,1-6.859-6.712c0-.049,0-.1,0-.148a6.688,6.688,0,0,1,5.748-6.7.786.786,0,0,0,.742-.714,8.247,8.247,0,0,1,8.095-7.168,8.1,8.1,0,0,1,8.095,8.095,6.015,6.015,0,0,1-.161,1.6.844.844,0,0,0,.477.98,5.624,5.624,0,0,1,3.546,5.148,5.219,5.219,0,0,1-1.466,3.89Z"
                        transform="translate(-0.001 -15.685)"
                    />
                </g>
                <path
                    style={{ fill: '#940c72' }}
                    d="M67.023,61.373l-3.277,3.286V55.8H62.217v8.859L58.94,61.373,57.875,62.44l5.106,5.119,5.105-5.119Z"
                    transform="translate(-47.9 -33.201)"
                />
            </g>
            <rect style={{ fill: 'none' }} width="30" height="30" transform="translate(1616 365)" />
        </g>
    </SvgIcon>
);

export default WkCloudDownArrowIcon;
