import { IUuiUserScreen, UUIReduxAction } from './types';

const setIsUserScreenDialogOpen = (state: IUuiUserScreen, isOpen: boolean): IUuiUserScreen => {
    const newState = isOpen === true ? { ...state, userScreenDialogKey: new Date().getTime() } : state;
    return { ...newState, isUserScreenDialogOpen: isOpen };
};

export const userScreenReducer = (state: IUuiUserScreen, action: UUIReduxAction): IUuiUserScreen => {
    switch (action.type) {
        case 'OpenUserScreenDialog':
            return setIsUserScreenDialogOpen(state, true);
        case 'CloseUserScreenDialog':
            return setIsUserScreenDialogOpen(state, false);
    }
    return state;
};
