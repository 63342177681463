export const Name = 'name';
export const DocId = 'docId';
export const Data = 'data';
export const File = 'file';
export const Type = 'type';
export const Attributes = 'attributes';
export const Id = 'id';
export const DocumentName = 'documentName';
export const FileName = 'fileName';
export const Description = 'description';
export const IsEmailMessage = 'isEmailMessage';
export const SenderEmail = 'senderEmail';
export const SentTime = 'sentTime';
export const SenderName = 'senderName';
export const ReceivedTime = 'receivedTime';
export const EmailRecipients = 'emailRecipients';
export const emailSubject = 'emailSubject';
export const EmailBody = 'emailBody';
export const IsAttachmentPresent = 'isAttachmentPresent';
export const UniqueId = 'uniqueId';
export const FolderPath = 'folderPath';
export const FolderPresent = 'isFolder';
export const ViewAccessType = 'viewAccessType';
export const EditAccessType = 'editAccessType';
export const SHAREPOINT = 'SPOL';
export const SHAREPOINT_TOKEN = 'SPOLToken';
export const DOCSERVICE_TOKEN = 'DocServiceToken';
export const AUTH_ERROR = 'Not able to get the Access Token';
export const INPUT_VALIDATION_ERROR = 'Input Validation Failed';
export const DATA_MISSING = 'Data File Missing';
export const OPERATION_NOT_SUPPORTED = 'Operation Not Supported';
export const DOCUMENT_FILE_SIZE_EXCEEDED = 'The document cannot be uploaded as it exceeds the limit of {fileSize}MB.';
export const EMAIL_FILE_SIZE_EXCEEDED = 'The email cannot be uploaded as it exceeds the limit of {fileSize}MB.';
export const FILE_MISSING = 'no file or stream to upload provided';
export const DOCID_ERROR = 'Document Id is missing';
export const passportConstants = {
    getMatterDetailsUrlPrepend: '/datacert/api/entity/Matter/',
    getMatterDetailsAttribute: '?attributeNames=longMatterName;primaryInternalPerson;practiceAreaBusinessUnit;id',
};

export const Timeout = {
    JR: {
        addCheckinTimeout: 120000,
        otherFuncTimeout: 30000,
        deleteTimeout: 120000,
    },
    T360: {
        addCheckinTimeout: 5000,
        otherFuncTimeout: 3000,
    },
};

// secret keys for rxdb password
// TODO: Remove when JRAdapter implements DMInfo
export const JRADAPTER_SECRET_KEY = 'JRAdapterQueuePasswordKey';
export const ERROR_MESSAGE_TO_HANDLE_DATA_INTERNALLY =
    'message for handling error internally if specific error encounters';
export const NETWORK_DOWN_ERROR =
    'There was an issue with uploading this document. Please try again, if the problem persists contact your network administrator';
export const UNEXPECTED_ERROR = 'Error condition handled and mitigated';

export const FETCH_ERROR = 'Failed to fetch';
