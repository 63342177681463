import { ICHUploadResponse } from '@wk/elm-uui-context-handler';
import fileDownload from 'js-file-download';
import { ContextLayerOperationName, CONTEXT_LAYER_OPERATION_NAMES, IBaseOperation } from '../common/types';
import { FieldValue, IItemScreenState } from '../itemScreen/types';
import { getBreadCrumbsForRow } from '../listScreen/listScreenHelpers';
import {
    IListScreenOperation,
    IListScreenRow,
    IListScreenState,
    IParentItemInfo,
    ListScreenDispatch,
} from '../listScreen/types';
import {
    clAddDocument,
    clCancelCheckOut,
    clCheckInDocument,
    clCheckOut,
    clDownloadDocument,
    clPerformDragAndDrop,
    clPerformQuickFile,
    clShowDocument,
    clUpdateDocument,
    clEditDocument,
    clEditFolder,
    clAddFolder,
    clDeleteDocument,
    clDeleteFolder,
    clDeleteEmail,
} from './contextLayerService';
import { UUIReduxDispatch } from '../../reducers/types';
import { IContextLayerArgs } from './types';

export const isContextLayerOperation = (operation: IBaseOperation): boolean => {
    return (
        !!operation.contextLayerOperationName &&
        CONTEXT_LAYER_OPERATION_NAMES.includes(operation.contextLayerOperationName)
    );
};

export const performListScreenContextLayerOperation = (
    contextLayerOperationName: ContextLayerOperationName,
    listScreenState: IListScreenState,
    documentRow: IListScreenRow,
    loggedInUser: string,
): Promise<Response | ICHUploadResponse | string> => {
    if (!listScreenState.parentItemInfo) {
        throw Error('operation must be executed within an embedded list');
    }
    const documentId = getDocumentId(
        listScreenState.metadata!.entityName,
        listScreenState.parentItemInfo,
        documentRow.id,
    );
    return performContextLayerOperation(contextLayerOperationName, documentId.toString(), {
        loggedInUser,
        associatedEntityType: documentRow._metadata.associatedEntityType!,
        associatedEntityTypeId: documentRow._metadata.associatedEntityTypeId!.toString(),
        associatedEntityId: documentRow._metadata.associatedEntityId!.toString(),
        associatedEntityName: documentRow._metadata.associatedEntityName!,
        version: documentRow._metadata.documentVersion,
        entityTypeId: listScreenState.metadata?.entityId?.toString(),
        folderArr: getBreadCrumbsForRow(listScreenState, documentRow),
    });
};

export const performItemScreenContextLayerOperation = (
    operation: IListScreenOperation,
    itemScreenState: IItemScreenState,
    loggedInUser: string,
    payload: Record<string, FieldValue>,
    breadCrumbsFromForm?: string,
): Promise<Response | ICHUploadResponse | string> => {
    if (!isContextLayerOperation(operation)) {
        throw Error('operation must be a context layer operation');
    }
    const metadata = itemScreenState.itemScreenJson!.metadata;
    const contextLayerInfo = itemScreenState.contextLayerInfo;
    // this should only happen on document summary screen, so it will have this metadata since it isDocumentEntity is true.
    const associatedEntityType = contextLayerInfo?.associatedEntityType || metadata.associatedEntityType!;
    const associatedEntityTypeId = contextLayerInfo?.associatedEntityTypeId || metadata.associatedEntityTypeId!;
    const associatedEntityName = contextLayerInfo?.associatedEntityName || metadata.associatedEntityName!;
    const associatedEntityId = contextLayerInfo?.associatedEntityId || metadata.associatedEntityId!;
    const documentId =
        itemScreenState.contextLayerInfo?.documentId || itemScreenState.itemScreenJson!.item.id?.toString();
    const entityTypeId = itemScreenState.itemScreenJson!.metadata.entityId.toString();

    const getFolderId = (): string | undefined => {
        if (contextLayerInfo?.folderId) {
            return contextLayerInfo.folderId;
        }
        // if we are on an add mode item page, check the parent info and if it doesn't match the associated info (i.e. the matter instance),
        // then we are in a subfolder, so set the folderId to the parentInstanceId so adding new subfolders will work.
        if (
            itemScreenState.mode === 'add' &&
            itemScreenState.parentItemInfo?.parentEntityId !== associatedEntityTypeId
        ) {
            return itemScreenState.parentItemInfo?.parentInstanceId;
        }

        return;
    };

    return performContextLayerOperation(
        operation.contextLayerOperationName!,
        documentId,
        {
            loggedInUser,
            associatedEntityTypeId: associatedEntityTypeId,
            associatedEntityType: associatedEntityType,
            associatedEntityId: associatedEntityId,
            associatedEntityName: associatedEntityName,
            folderId: getFolderId(),
            entityTypeId: entityTypeId,
            documentId: documentId,
            isQuickFile: contextLayerInfo?.isQuickFile,
            isDragAndDrop: contextLayerInfo?.isDragAndDrop,
            filePaths: contextLayerInfo?.filePaths,
            folderArr:
                breadCrumbsFromForm?.split(' > ') ||
                contextLayerInfo?.folderArr ||
                itemScreenState.itemScreenJson?.metadata.folderPath,
            operationContext: operation.operationContext,
        },
        payload,
    );
};

export const performContextLayerOperation = (
    contextLayerOperationName: ContextLayerOperationName,
    documentId: string | undefined,
    args: IContextLayerArgs,
    payload?: Record<string, FieldValue>,
): Promise<Response | ICHUploadResponse | string> => {
    switch (contextLayerOperationName) {
        case 'UUI Edit Document':
            return clEditDocument({
                ...args,
                documentId: documentId!.toString(),
                payload,
            });
        case 'UUI Edit Folder':
            return clEditFolder({
                ...args,
                folderId: documentId!.toString(),
                payload,
            });

        case 'UUI Lock Document':
            return clCheckOut({
                ...args,
                documentId: documentId!,
            });
        case 'UUI Unlock Document':
            return clCancelCheckOut({
                ...args,
                documentId: documentId!,
            });
        case 'UUI Download Document':
            return clDownloadDocument({
                ...args,
                documentId: documentId!.toString(),
            });
        case 'UUI Show Document':
            return clShowDocument({
                ...args,
                documentId: documentId!.toString(),
            });
        case 'UUI Add Document': {
            return clAddDocument({ ...args, payload });
        }
        case 'UUI Check In Document':
            return clCheckInDocument({
                ...args,
                documentId: documentId,
            });
        case 'UUI Update Document': {
            return clUpdateDocument({
                ...args,
                documentId: args.documentId,
                payload,
            });
        }
        case 'UUI Add Folder Command':
            return clAddFolder({
                ...args,
                folderId: args.folderId ?? args.operationContext,
                payload,
            });
        case 'UUI Delete Document':
            return clDeleteDocument({
                ...args,
                documentId: documentId!,
            });
        case 'UUI Delete Email':
            return clDeleteEmail({
                ...args,
                documentId: documentId!,
            });
        case 'UUI Delete Folder':
            return clDeleteFolder({
                ...args,
                documentId: documentId!,
            });
        default:
            throw Error('Unsupported context layer function called');
    }
};

export const handleClFileDownload = (response: Response): void => {
    if (response && response.url) {
        response.blob().then((fileBlob) => {
            const disposition = response.headers.get('content-disposition')!;
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            let filename = 'default.txt';
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
            fileDownload(fileBlob, decodeURI(filename));
        });
    }
};

export interface IHandleContextLayerPerformQuickFile {
    associatedEntityType: string;
    associatedEntityId: string;
    cancellablePromise: (
        p: Promise<ICHUploadResponse | ICHUploadResponse[]>,
    ) => Promise<ICHUploadResponse | ICHUploadResponse[]>;
    entityTypeId?: string;
    folderId?: string;
    documentId?: string;
    loggedInUser?: string;
    associatedEntityTypeId?: string;
    associatedEntityName?: string;
    folderArr?: string[];
    reduxDispatch?: UUIReduxDispatch | undefined;
    listScreenDispatch?: ListScreenDispatch;
    rowId?: number;
}

export const handleContextLayerPerformQuickFile = ({
    associatedEntityType,
    associatedEntityId,
    cancellablePromise,
    entityTypeId,
    folderId,
    documentId,
    loggedInUser,
    associatedEntityTypeId,
    associatedEntityName,
    folderArr,
    listScreenDispatch,
    rowId,
}: IHandleContextLayerPerformQuickFile): void => {
    cancellablePromise(
        clPerformQuickFile({
            associatedEntityType,
            associatedEntityId,
            associatedEntityName,
            folderId,
            entityTypeId,
            documentId,
            loggedInUser,
            associatedEntityTypeId,
            folderArr,
        }),
    ).then(() => {
        if (rowId && listScreenDispatch) {
            listScreenDispatch({ type: 'EnableRow', id: rowId });
        }
    });
};

interface IHandleContextLayerPerformDragAndDrop extends IHandleContextLayerPerformQuickFile {
    filePaths: Array<string>;
}

export const handleContextLayerPerformFileDragAndDrop = ({
    associatedEntityType,
    associatedEntityId,
    cancellablePromise,
    entityTypeId,
    folderId,
    documentId,
    loggedInUser,
    associatedEntityTypeId,
    associatedEntityName,
    filePaths,
    folderArr,
}: IHandleContextLayerPerformDragAndDrop): void => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    cancellablePromise(
        clPerformDragAndDrop({
            associatedEntityType,
            associatedEntityId,
            associatedEntityName,
            folderId,
            entityTypeId,
            documentId,
            loggedInUser,
            associatedEntityTypeId,
            filePaths,
            folderArr,
        }),
    );
};

// private functions
const getDocumentId = (entityName: string, parentInfo: IParentItemInfo, rowId: number) => {
    return entityName === 'DocumentVersion' ? parentInfo.parentInstanceId : rowId;
};
