export enum CapabiltyEnum {
    CHECKOUT = 'CheckOut',
    SHOW_DOCUMENT = 'ShowDocument',
    CHECKIN_DOCUMENT = 'CheckInDocument',
    ADD_DOCUMENT = 'AddDocument',
    ADD_FOLDER = 'AddFolder',
    EDIT_FOLDER = 'EditFolder',
    DELETE = 'Delete',
    LIST = 'List',
    CANCEL_CHECKOUT = 'CancelCheckOut',
    RENAME_DOCUMENT = 'RenameDocument',
    EDIT_DOCUMENT = 'EditDocument',
    DELETE_DOCUMENT = 'DeleteDocument',
    DOWNLOAD_DOCUMENT = 'DownloadDocument',
    DELETE_FOLDER = 'DeleteFolder',
    ADD_EMAIL = 'AddEmail',
    DELETE_EMAIL = 'DeleteEmail',
    DELETE_EMAIL_FOLDER = 'DeleteEmailFolder',
    RENAME_FOLDER = 'RenameFolder',
    DOCUMENT_METADATA = 'DocumentMetadata',
    SET_TOKEN = 'SET_TOKEN',
    GET_TOKEN = 'GET_TOKEN',
    DELETE_TOKEN = 'DELETE_TOKEN',
    GET_QUICK_FILE_SUPPORT = 'GET_QUICK_FILE_SUPPORT',
    DOCUMENT_META_FILENAME = 'DocumentMetadataFileName',
    DRAG_AND_DROP = 'DragAndDrop',
    DEFAULT = 'NotSupported',
    INITIALIZE = 'Initialize',
    GET_ALL_PROPS = 'GetAllProps',
    VALIDATE_ADD_DOCUMENT = 'ValidateAddDocument',
    VALIDATE_CheckIn_DOCUMENT = 'ValidateCheckInDocument',
    VALIDATE_EDIT_DOCUMENT = 'ValidateEditDocument',
    VALIDATE_EDIT_FOLDER = 'ValidateEditFolder',
    VALIDATE_ADD_FOLDER = 'ValidateAddFolder',
}

export enum CHSupportedEntityTypesEnum {
    MATTER = 'Matter',
    MATTERS = 'Matters',
    PEOPLE = 'People',
    ORGANIZATION = 'Organization',
    INVOICE = 'InvoiceHeader',
    EMAIL = 'EmailDocument',
    MATTERMANAGEMENTDOCUMENT = 'MatterManagementDocument',
    MATTERMATTERMANAGEMENTDOCUMENT = 'MatterMatterManagementDocument',
}

export enum CHSupportedOperationTriggersEnum {
    DND = 'DragAndDrop',
    MANUAL = 'Manual',
}

export enum CHSupportedDocumentTypesEnum {
    DOCUMENT = 'Document',
    EMAIL = 'Email',
    FOLDER = 'Folder',
}

export enum CHSupportedContextTypesEnum {
    OC_CONTEXT = 'OCContext',
    UI_CONTEXT = 'UIContext',
}

export enum Entities {
    MATTER = 'M',
    INVOICEHEADER = 'I',
}

export enum DocumentType {
    DOCUMENT = 'D',
    EMAILDOCUMENT = 'E',
}

export enum Checkout {
    OVERWRITE = 'checkout_overwrite_keep',
}

export enum DiscardCheckout {
    OVERWRITE = 'discardcheckout_remove_keep',
}

export enum ToastType {
    DEFAULT = 'default',
    ERROR = 'error',
    WARNING = 'warning',
    INFORMATION = 'information',
    SUCCESS = 'success',
}

export enum EventType {
    TOAST = 'toast',
    DIALOG = 'dialog',
    ITEMSCREEN = 'itemscreen',
    REFRESH_UUI_FOR_ENTITY = 'refreshUUIForEntity',
    UNAUTHORIZED_ACCESS_ATTEMPT = 'unAuthorizedAccessAttempt',
    NETWORK_DOWN = 'networkDown',
    NETWORK_RESTORED = 'networkRestored',
    OVERLAY = 'overlay',
    OVERLAY_CLOSE = 'overlay_close',
    GLOBAL_SPINNER = 'global_spinner',
}

export enum DialogType {
    ALERT = 'alert',
    CONFIRMATION = 'confirm',
    CUSTOM_DIALOG = 'customdialog',
}

export const DocumentDefaultExtensions = {
    Word: '.docx',
    Excel: '.xlsx',
    PowerPoint: '.pptx',
};

export enum ApplicationContextUI {
    OfficeCompanion = 'Electron', // TODO - Should be updated as "Electron" or "Renderer"
    Browser = 'UI',
}

export enum ScreenName {
    QUICKFILE_ADD_DOCUMENT = 'quickfile_add_document',
    QUICKFILE_CHECKIN_DOCUMENT = 'quickfile_checkin_document',
    DRAG_AND_DROP_ADD_DOCUMENT = 'drag_and_drop_add_document',
    DRAG_AND_DROP_CHECKIN_DOCUMENT = 'drag_and_drop_checkin_document',
    ADD_FOLDER = 'add_folder',
    RENAME_FOLDER = 'rename_folder',
    RENAME_DOCUMENT = 'rename_document',
    CHECKIN_DOCUMENT = 'checkin_document',
}

export enum DocumentState {
    checkIn = 'checkIn',
    CheckoutByOther = 'CheckoutByOther',
    DOC_EXIST = 'doc_exist',
    EMAIL_EXIST = 'email_exist',
    CHECKEDIN = 'checkedIn',
    CHECKEDOUT = 'checkedOut',
    FOLDER_EXIST = 'folder_exist',
    EDIT_FOLDER_EXIST = 'edit_folder_exist',
    EDIT_DOC_EXIST = 'edit_doc_exist',
    ADD_FOLDER_EXIST = 'add_folder_exist',
}

export enum CHMessageType {
    refreshUUI = 'RefreshUUIForEntity',
    activateWindow = 'ActivateWindow',
    ProceedWithNWSwitch = 'ProceedWithNetworkSwitch',
    CancelNWSwitch = 'CancelNetworkSwitch',
    flyoutEvent = 'FlyoutComplete',
    LOGOUT = 'Logout',
    T360_LOGOUT = 'T360_Logout',
}

export enum CHMessageAction {
    SendMessage = 'send-message',
}

export enum CHMessagingScope {
    OtherInstances = 'OtherInstances',
    AllInstances = 'AllInstances',
}

export enum CHPropsType {
    autoUpdateUrl = 'AutoUpdateUrl',
}

export enum HttpStatusCode {
    OK = 200,
    CREATED = 201,
    ACCEPTED = 202,
    NO_CONTENT = 204,
    MULTIPLE_CHOICES = 300,
    MOVED_PERMANENTLY = 301,
    FOUND = 302,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    PAYMENT_REQUIRED = 402,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    METHOD_NOT_ALLOWED = 405,
    REQUEST_TIMEOUT = 408,
    CONFLICT = 409,
    INTERNAL_SERVER_ERROR = 500,
    NOT_IMPLEMENTED = 501,
    BAD_GATEWAY = 502,
    SERVICE_UNAVAILABLE = 503,
    GATEWAY_TIMEOUT = 504,
}

export enum EmailType {
    DRAFT_EMAIL = 'Draft',
    NOT_VALID_EMAIL = 'Invalid',
    VALID = 'Valid',
    CONTACT = 'Email Contact',
    EMAIL = 'Email',
    MEETING_REQUEST_CANCELLATION = 'Meeting Request',
    NOTE = 'Note',
    TASK = 'Task',
}

export enum FullScreenOverlayIconEnum {
    DOWNLOAD = 'iconclouddownarrow',
    CHECKMARK = 'iconcloudcheckmark',
    EXCLAMATION = 'icontriangleexclamation',
}

export enum EmailMessageClass {
    APPOINTMENT = 'IPM.Appointment',
    CONTACT = 'IPM.Contact',
    EMAIL = 'IPM.Note',
    MEETING_REQUEST = 'IPM.Schedule.Meeting.Request',
    MEETING_CANCELLATION = 'IPM.Schedule.Meeting.Canceled',
    NOTE = 'IPM.StickyNote',
    TASK = 'IPM.Task',
    REPORT = 'REPORT.IPM.Note.NDR',
}

export enum EntityTypes {
    Invoice = 'Invoice',
}
