import { Chip, IconButton } from '@material-ui/core';
import KeyboardArrowUpSharpIcon from '@material-ui/icons/KeyboardArrowUpSharp';
import { Autocomplete } from '@material-ui/lab';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { apiFetch } from '../../../utils/fetchUtils';
import { UUIInput } from '../../common/uuiInput';
import WkCircleLoadingIcon from '../../icons/wkCircleLoadingIcon';
import WkSpyglassIcon from '../../icons/wkSpyglassIcon';
import { measureStringWidth } from '../../../utils/utilities';
import { ControlTypeProps, IAutoCompleteItemsResponse, IItemScreenState, ParentEntityInfo } from '../types';
import { ControlLabel } from './controlLabel';
import css from './multiSelectAutoComplete.module.scss';
import CloseIcon from '@material-ui/icons/Close';
import { ListItemTooltip } from '../../listScreen/listViewItemTextRow';
import { getReactHookFormFieldName, getValidationMessagesForField } from '../itemScreenHelpers';
import { useReduxSelector } from '../../../hooks/useReduxSelector';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useItemScreenState } from '../context/itemScreenContext';

const MultiSelectAutoComplete: React.FC<ControlTypeProps> = ({ field, fieldData, readOnly }) => {
    const { register, setValue, errors } = useFormContext();
    const [selectedValue, setSelectedValueInternal] = useState<IAutoCompleteItemsResponse[]>(
        fieldData.selectedItems || [],
    );
    const [options, setOptions] = useState<IAutoCompleteItemsResponse[]>([]);
    const [inputValue, setInputValue] = useState('');
    const [autocompleteKey, setAutocompleteKey] = useState(new Date().getTime());
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [onSelectFocus, setOnSelectFocus] = useState(false);
    const [showArrow, setShowArrow] = useState(false);
    const [noFocusChipsCount, setNoFocusChipsCount] = useState(0);
    const windowWidth = useWindowWidth(100);
    const inputRef = useRef<HTMLElement>(null);
    const firstRunRef = useRef(true);
    const appResources = useReduxSelector((state) => state.appResources);
    const registerField = getReactHookFormFieldName(field);
    const uuiPrefetchMultiAutoCompleteItems = field.sourceListScreen!.uuiPrefetchMultiAutoCompleteItems;
    const [showChevron, setChevron] = useState(false);
    const itemScreenState = useItemScreenState();

    const debounceFetch = useDebouncedCallback(
        (newInputValue) => {
            fetchAutocompleteItems(newInputValue);
        },
        300,
        { maxWait: 1000 },
    );

    useEffect(() => {
        if (!readOnly) {
            register({ name: registerField, type: 'string' });
            const fieldValues = selectedValue.map((ids) => ids.id).toString(); // grants="1,2,3";
            setValue(registerField, fieldValues);
        }
    }, [register, setValue, selectedValue, registerField, readOnly]);

    useEffect(() => {
        if (!firstRunRef.current && onSelectFocus) {
            const input = inputRef.current?.querySelector<HTMLInputElement>('input[type="text"]');
            input?.select();
            setOnSelectFocus(false);
        } else {
            firstRunRef.current = false;
        }
    }, [autocompleteKey, onSelectFocus]);

    useEffect(() => {
        const OUTER_PADDING_WIDTH = 31;
        const CHIP_PADDING_WIDTH = 20;
        const CHIPS_COUNTER_TAG_WIDTH = 30;
        const SPY_GLASS_MININPUT_WIDTH = 55;
        const MAX_CHIP_WIDTH = 210;
        const MAX_ROWS = 2;
        const initialAvailableWidth = readOnly
            ? windowWidth - OUTER_PADDING_WIDTH
            : windowWidth - OUTER_PADDING_WIDTH - SPY_GLASS_MININPUT_WIDTH;

        let availableWidthForChips = initialAvailableWidth;
        let currentRow = 1;
        let unFocusedChipsCount = 0;

        selectedValue &&
            selectedValue.forEach((chip) => {
                const showOverFlow = currentRow > MAX_ROWS;

                let chipLength = measureStringWidth(chip.value, '13px', '500');
                chipLength = chipLength > MAX_CHIP_WIDTH ? MAX_CHIP_WIDTH : chipLength + CHIP_PADDING_WIDTH;

                if (!showOverFlow) {
                    if (chipLength < availableWidthForChips) {
                        unFocusedChipsCount += 1;
                        availableWidthForChips = availableWidthForChips - chipLength;
                    } else if (currentRow <= MAX_ROWS) {
                        if (currentRow < MAX_ROWS) {
                            unFocusedChipsCount += 1;
                        }
                        availableWidthForChips = initialAvailableWidth - CHIPS_COUNTER_TAG_WIDTH;
                        currentRow += 1;
                        availableWidthForChips = availableWidthForChips - chipLength;
                    }
                }
            });
        setNoFocusChipsCount(unFocusedChipsCount);
    }, [windowWidth, selectedValue, readOnly]);

    const handleInputChange = (event: any, newInputValue: string) => {
        !uuiPrefetchMultiAutoCompleteItems && setOpen(false);
        setInputValue(newInputValue);
        if (event && (event.keyCode === 13 || event.button === 0 || event.type === 'blur')) {
            return;
        }
        if (uuiPrefetchMultiAutoCompleteItems || newInputValue.trim().length > 1) {
            debounceFetch(newInputValue.trim());
        }
    };

    const fetchAutocompleteItems = (newInputValue: string) => {
        const parentInstanceInfo: ParentEntityInfo = getParentEntityInfo(itemScreenState);
        setLoading(true);
        const prevSelectedIds = selectedValue.map((ids) => ids.id).toString();
        const requestUrl = field
            .sourceListScreen!.autocompleteItemsUrl!.replace('{value}', newInputValue !== '' ? newInputValue : '')
            .replace('{excludedIDs}', prevSelectedIds)
            .replace('{parentInstanceId}', parentInstanceInfo.parentInstanceId || '')
            .replace('{parentEntityTypeId}', parentInstanceInfo.parentEntityTypeId || '');

        apiFetch<IAutoCompleteItemsResponse[]>(requestUrl).then((data) => {
            const newData = data;
            if (newData.length !== 0) {
                selectedValue.map((val) => {
                    if (data.findIndex((d) => d.value === val.value) === -1) {
                        newData.push(val);
                    }
                });
            }

            setOptions(newData);
            setOpen(true);
            setLoading(false);
        });
    };

    const getParentEntityInfo = (itemScreenState: IItemScreenState) => {
        const parentEntityInfo = new ParentEntityInfo();
        if (itemScreenState.contextLayerInfo) {
            return Object.assign(parentEntityInfo, {
                parentInstanceId: itemScreenState.contextLayerInfo?.associatedEntityId,
                parentEntityTypeId: itemScreenState.contextLayerInfo?.associatedEntityTypeId
            });
        }
        if (itemScreenState.parentItemInfo) {
            return Object.assign(parentEntityInfo, {
                parentInstanceId: itemScreenState.parentItemInfo?.parentInstanceId,
                parentEntityTypeId: itemScreenState.parentItemInfo.parentEntityId
            });
        }
        return parentEntityInfo;
    }

    const handleOnBlur = () => {
        setChevron(false);
        setInputValue('');
        open ? setOpen(false) : null;
        setAutocompleteKey(new Date().getTime());
        if (readOnly) {
            setShowArrow(false);
        }
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Escape' && open) {
            setOpen(false);
            setAutocompleteKey(new Date().getTime());
            setOnSelectFocus(true);
        } else if ((event.key === 'ArrowDown' && !open) || (event.key === 'ArrowUp' && !open)) {
            handleOnClick();
        }
    };

    const handleOnClickStopPropagation = (event: any) => {
        event.stopPropagation();
        event.preventDefault();
    };

    const renderOptionsList = (option: any) => {
        const result = option?.display?.split(/<\/?span>/).map((text: any, index: number) => {
            return text === '|' ? (
                <span key={index} className={css.optionPipe}>
                    &nbsp;
                    {text}&nbsp;
                </span>
            ) : (
                text
            );
        });
        return result;
    };

    const setSelectedValue = (value: any) => {
        setSelectedValueInternal(value);
        setAutocompleteKey(new Date().getTime());
        setOnSelectFocus(true);
    };

    const handleOnFocus = () => {
        if (readOnly && noFocusChipsCount !== selectedValue.length) {
            setShowArrow(true);
        }
        if (options.length === 0 && open) {
            debounceFetch('');
        }
    };

    const handleOnClick = () => {
        if (uuiPrefetchMultiAutoCompleteItems && !open) {
            setChevron(true);
            debounceFetch('');
        }
    };

    const handleExpandClick = () => {
        handleOnBlur();
    };

    return (
        <Autocomplete
            key={autocompleteKey}
            ref={inputRef}
            multiple
            filterSelectedOptions
            limitTags={noFocusChipsCount}
            id={getReactHookFormFieldName(field)}
            size="small"
            value={selectedValue}
            open={readOnly ? false : uuiPrefetchMultiAutoCompleteItems ? open : open && inputValue.length > 1}
            noOptionsText={appResources.dropdownControlNoOptionsText}
            onBlur={handleOnBlur}
            onKeyDown={(event) => handleKeyDown(event)}
            options={options}
            getOptionLabel={(option) => option.value}
            getOptionSelected={(option, value) => option.value === value.value}
            renderOption={(option) => renderOptionsList(option)}
            filterOptions={(options) => options}
            onInputChange={(event, newInputValue) => handleInputChange(event, newInputValue)}
            onChange={(_: ChangeEvent<unknown>, newValue: IAutoCompleteItemsResponse[]) => {
                if (newValue) {
                    const newValues = newValue.map((ids) => ids.id).toString();
                    setValue(registerField, newValues);
                    setSelectedValue(newValue);
                    setInputValue('');
                }
            }}
            onFocus={handleOnFocus}
            clearOnEscape={!readOnly}
            ListboxProps={{ style: { maxHeight: 165, overflow: 'auto' } }}
            classes={{
                option: css.option,
                listbox: css.input,
                paper: css.paper,
                clearIndicator: css.clearIndicator,
                tagSizeSmall: css.tagSizeSmall,
                popupIndicator: css.popupIndicator,
                endAdornment: css.endAdornment,
                tag: `${readOnly ? css.tag : null}`,
                inputFocused: `${readOnly ? css.inputFocused : null}`,
            }}
            renderInput={(params) => (
                <ControlLabel
                    data-testid="multipleAutocompleteControl"
                    field={field}
                    control={
                        <UUIInput
                            {...params.InputProps}
                            inputProps={params.inputProps}
                            readOnly={readOnly}
                            error={getValidationMessagesForField(field, errors, 'errors').length > 0}
                            warning={getValidationMessagesForField(field, errors, 'warnings').length > 0}
                            data-testid="multipleAutocompleteInput"
                            onClick={handleOnClick}
                            endAdornment={
                                readOnly ? (
                                    <>
                                        <input type="hidden" data-testid="multiSelectView-testId" readOnly={readOnly} />
                                        {showArrow && (
                                            <KeyboardArrowUpSharpIcon
                                                className={css.arrowUpIcon}
                                                onClick={handleOnBlur}
                                                data-testid="arrowUpIcon"
                                            />
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {loading ? (
                                            <IconButton
                                                data-testid="multipleAutocompleteLoadingIcon"
                                                tabIndex="-1"
                                                className={css.iconButton}
                                                onClick={handleOnClickStopPropagation}
                                                aria-label="clear">
                                                <WkCircleLoadingIcon className={css.iconLoading} />
                                            </IconButton>
                                        ) : (
                                            params.InputProps.endAdornment
                                        )}
                                        <IconButton
                                            data-testid="multiAutocompleteSpyglassIcon"
                                            tabIndex="-1"
                                            className={css.iconButtonSpyglass}
                                            onClick={handleOnClickStopPropagation}
                                            aria-label="search">
                                            <WkSpyglassIcon className={css.iconSpyglass} viewBox="0 0 18 18" />
                                        </IconButton>
                                        {showChevron && (
                                            <IconButton
                                                onClick={handleExpandClick}
                                                className={css.iconExpandMore}
                                                aria-label="show more">
                                                <ExpandMoreIcon />
                                            </IconButton>
                                        )}
                                    </>
                                )
                            }
                            id={getReactHookFormFieldName(field)}
                            name={getReactHookFormFieldName(field)}
                            classes={{
                                focused: css.inputFocused,
                                input: css.inputNative,
                                root: css.multipleAutocompleteRoot,
                            }}
                            placeholder={readOnly ? '' : appResources.multiAutoCompleteSearchSelectText}
                        />
                    }></ControlLabel>
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) =>
                    readOnly ? (
                        <ListItemTooltip key={option.id} placement={'bottom-start'} title={option.value}>
                            <Chip
                                data-testid="readOnlyChips"
                                size="small"
                                label={option.value}
                                {...getTagProps({ index })}
                                deleteIcon={<CloseIcon className={css.readOnlyClass} data-testid="chipClearIcon" />}
                            />
                        </ListItemTooltip>
                    ) : (
                        <Chip
                            key={option.id}
                            size="small"
                            label={option.value}
                            {...getTagProps({ index })}
                            deleteIcon={<CloseIcon className={css.chipClearIcon} data-testid="chipClearIcon" />}
                        />
                    ),
                )
            }
        />
    );
};

export default MultiSelectAutoComplete;
