import { IQueueServiceInterface, IQueueItem } from '@wk/elm-uui-queuemanager';
import { injectable } from 'inversify';
import {
    IAfterResponse,
    IHandleError,
    IHandleResponse,
    IHandleStarted,
    IQueueSubscribed,
    ISubscribedFunction,
} from '../interfaces/interface';
import { factory } from '../configLog4J';
import { ICHUploadResponse } from '../interfaces/operations';
import { ERROR_MESSAGE_TO_HANDLE_DATA_INTERNALLY } from '@wk/elm-uui-doc-component';
const log = factory.getLogger('QueueCapability');

@injectable()
export class QueueSubscribed implements IQueueSubscribed {
    subscribedFunction!: ISubscribedFunction;

    handleResponseFunc: IHandleResponse = {
        func: async (_queueItem) => log.debug('Dummy Handle handle Response Function'),
        obj: this,
    };

    handlePendingResponseFunc: IHandleResponse = {
        func: async (_queueItem) => log.debug('Dummy Pending handle Response Function'),
        obj: this,
    };

    handleErrorFunc: IHandleError = {
        func: async (_queueItem) => log.debug('Dummy Handle Error Function'),
        obj: this,
    };
    afterResponseFunc: IAfterResponse = {
        func: async (_queueItem) => log.debug('Dummy Handle After Response Function'),
        obj: this,
    };
    handleStartedFunc: IHandleStarted = {
        func: async (_queueItem) => log.debug('Dummy Handle Started function'),
        obj: this,
    };

    handleSubscribedFunc = async (queueItem: Record<string, unknown>): Promise<ICHUploadResponse> => {
        return await this.subscribedFunction.func.call(this.subscribedFunction.obj, queueItem);
    };

    afterResponse = async (
        _queueObj: IQueueServiceInterface,
        queueItem: IQueueItem,
        response: Response,
    ): Promise<Response> => {
        await this.afterResponseFunc.func.call(this.afterResponseFunc.obj, queueItem);
        return response;
    };
    updateStarted = async (queueObj: IQueueServiceInterface, queueItem: IQueueItem): Promise<IQueueItem> => {
        await this.handleStartedFunc.func.call(this.handleStartedFunc.obj, queueItem);
        return await queueObj.updateStarted(queueItem);
    };

    handleResponse = async (
        queueObj: IQueueServiceInterface,
        queueItem: IQueueItem,
        _response: Response,
    ): Promise<IQueueItem> => {
        await this.handleResponseFunc.func.call(this.handleResponseFunc.obj, queueItem);
        return await queueObj.updateCompleted(queueItem, {} as Response);
    };

    handlePendingResponse = async (queueObj: IQueueServiceInterface, queueItem: IQueueItem): Promise<IQueueItem> => {
        await this.handlePendingResponseFunc.func.call(this.handleResponseFunc.obj, queueItem);
        return await queueObj.updatePending(queueItem);
    };

    handleError = async (
        queueObj: IQueueServiceInterface,
        queueItem: IQueueItem,
        error: string,
    ): Promise<IQueueItem | string> => {
        await this.handleErrorFunc.func.call(this.handleErrorFunc.obj, queueItem);
        if (error == ERROR_MESSAGE_TO_HANDLE_DATA_INTERNALLY) {
            return await queueObj.updateProcessingasNew(queueItem);
        } else {
            return await queueObj.updateError(queueItem, error);
        }
    };
}
