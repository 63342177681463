(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@wk/elm-uui-common"), require("rxjs"), require("uuid"));
	else if(typeof define === 'function' && define.amd)
		define(["@wk/elm-uui-common", "rxjs", "uuid"], factory);
	else if(typeof exports === 'object')
		exports["uuiContextHandler"] = factory(require("@wk/elm-uui-common"), require("rxjs"), require("uuid"));
	else
		root["uuiContextHandler"] = factory(root["@wk/elm-uui-common"], root["rxjs"], root["uuid"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__2494__, __WEBPACK_EXTERNAL_MODULE__3832__, __WEBPACK_EXTERNAL_MODULE__459__) => {
return 