import { MessageBarInterface, MessageBarService } from './messageBar.service';
import { messageBarContainer } from './messageBar.module';

export const MessageBar = messageBarContainer.get<MessageBarInterface>(MessageBarService);

export {
    MessageBarOptions,
    MessageBarButton,
    MessageBarNotification,
    MessageBarButtonClicked,
} from './messageBar.service';
