export const Locale = {
    documents: {
        doc_updated_by_different_user: 'Document has been updated. Please redo your action.',
        checked_out_not_by_me:
            'This file, {filename}, is checked out by {lockedBy}. Currently its last checked in version can only be attached, downloaded, or opened in read-only mode.',
        delete_document: {
            checked_out_by_other:
                '{documentFileName} cannot be deleted right now as it is already checked out by {lockedBy}.',
            checked_out_by_me:
                'Checked out documents cannot be deleted. This document is currently checked out by you. Your screen will refresh to reflect the changes.',
        },

        folders: {
            add_folder_conflict: 'Unable to add folder. Folder with same name already exists.',
            rename_folder_conflict: 'Unable to rename folder. Folder with same name already exists.',
        },
        local_copy_not_found: 'Unable to find the local copy of the document.',
        download_permission_not_available:
            'The operation could not be completed due to network or permissions issues. Please try again. If the issue persists, contact your network administrator.',
        quickfile_no_document: 'There is no document available to upload.',
        dnd_no_document: 'There are no files to upload. Only the files within the folder can be uploaded.',
        dnd_not_supported: 'Emails cannot be added to document summary.',
        dnd_invoice_email_feature_not_supported: 'Emails cannot be added. This feature is currently unavailable in Invoices.',
        multiple_dnd_document: 'Multiple documents cannot be uploaded simultaneously.',
        checkin: 'Unable to check in document. Document is already checked out by {lockedBy}',
        quickfile_locked:
            'Unable to upload document `{fileName}`. Document with same name already exists and is checked out by {lockedBy}.',
        quickfile: 'Unable to upload document `{documentName}`. Document with same name already exists.',
        quickfile_documentFile:
            'Unable to upload file `{fileName}`. The file associated with document `{documentName}` has the same name.',
        doc_checked_out_to_different_user:
            '{fileName} cannot be updated right now as it is already checked out by {lockedBy}.',
        doc_file_type_doesnt_match:
            'The document {newFileName} is of a different file type than the original and cannot be uploaded as a new version of the original document in document summary page.',
        upload_new_document_fileName_with_warning: {
            title: 'Upload Document',
            message: `{newFileName} will be uploaded as a new version of {oldFileName}.\n\nWarning: The File name of the new version is different than the original file name.`,
        },
        open_removed_document: 'Unable to perform operation on removed document.',
        excel_document_busy: {
            title: 'Warning',
            message: `This Excel file is currently being edited. Please finish editing the cell and then try {operationName} again.`,
        },
        upload_new_active_document: {
            title: 'Upload Document',
            message: 'Documents must be saved before they can be uploaded.',
        },
        upload_exist_active_document: {
            title: 'Upload Document',
            message: `Documents with unsaved changes must be saved before upload. Select 'Save' to save changes to the current document and upload, or 'Save As' to save the document under another name and upload.`,
        },
        upload_exist_active_saveas_document: {
            title: 'Upload Document',
            message: `Documents with unsaved changes must be saved before upload. Select 'Save As' to save the document under another name and upload.`,
        },
        save_with_new_name: `Document with the file name '{fileName}' already exists. Save the document with a new file name to proceed.`,
        upload_documents_into_email:
            'This file type cannot be added to the Emails tab. It will automatically be stored under Documents tab.',
        upload_emails_into_documents:
            'This email file cannot be added to the Documents tab. It will automatically be stored under Emails tab.',
        upload_document_and_email_combination:
            'A combination of documents and emails cannot be uploaded. Only emails will get uploaded.',
        checkin_progress_message:
            'Checking in the document. You will see the document checked in once its completed upload.',
        delete: 'deleting',
        checkout: 'checkout',
        undo_checkout: 'discard checkout',
        quickfile_doc: 'quick filing',
        drag_and_drop: 'drag and drop',
        checkin_doc: 'checkin',
    },
    responseText: {
        file_not_saved: 'File was not saved. Save the file to proceed',
        download_cancel: 'Document to download is cancelled',
        download_filePath_exceeded_limit:
            'The specified file name or folder tree path is too long. File name should be less than {maxFileNameLength} characters, or the complete file path should be less than 260 characters to do a save as in windows/office for this file.',
        download_folderPath_exceeded_limit:
            'The specified file name or folder tree path is too long. Folder path of 230 characters is too long, or the complete file path should be less than 260 characters to do a save as in windows/office for this file.',
        file_not_found: 'File was not found. Select the file manually to proceed',
        entityId_not_found: 'Entity type id is not found. Please pass an entity type id',
        file_exists: 'File already exists. Overwrite/Keep the local copy to proceed',
        checkout_documentOpened: 'Checked out file opened successfully',
        itemScreen_quickfile_add_document: 'Published ItemScreen | Quick File Add Document',
        itemScreen_quickfile_checkIn_document: 'Published ItemScreen | Quick File CheckIn Document',
        itemScreen_checkIn_document: 'Published ItemScreen | CheckIn Document',
        itemScreen_save_with_new_name_document: 'Published ItemScreen | Save with new name',
        success: {
            discard_document: 'Successfully discarded changes for {filename}.',
            edit_document: 'Document Edit was successful.',
            edit_folder: 'Folder Edit was successful.',
            delete_email: 'Email was deleted successfully.',
            delete_email_folder: 'Email Folder Delete was successful.',
            delete_document: 'Document, {filename} was deleted successfully.',
            delete_folder: 'Folder Delete was successful.',
            add_folder: 'Folder Add was successful',
            checkout_document: 'CheckOut was successful',
        },
        failures: {
            checkin_document: 'Document CheckIn was not successful',
            discard_document: 'Discard CheckOut was not successful',
            edit_document: 'Document Edit was not successful',
            edit_folder: 'Folder Edit was not successful',
            delete_email: 'Email Delete was not successful',
            delete_email_folder: 'Email Folder Delete was not successful',
            delete_document: 'Document Delete was not successful',
            delete_folder: 'Folder Delete was not successful',
            add_document: 'Document upload was not successful',
            add_folder: 'Folder Add was not successful',
            download_document: 'Document Download was not successful',
            application_busy:
                'This Excel file is currently being edited. Please finish editing the cell and then try again',
        },
        switch_dialog: `This action cannot be performed because the file is open in another application. Switch to the application where the file is open and retry your action.`,
        not_supported: 'Operation is not supported',
    },

    email: {
        success: {
            add_email: 'Email upload was successful',
            download_email: 'Email Download was successful',
            add_email_t360: 'Email has been uploaded and pending for virus scanning',
        },
        failure: {
            invalid: 'Undelivered emails or unsupported email types cannot be uploaded. Please check and try again.',
            draft: 'Draft emails cannot be uploaded. You can upload this email after it has been sent.',
            duplicate_email_to_matter:
                'E-mail document with subject ‘{subject}’ is already associated to matter ‘{matter}’.',
            add_email: 'Email not uploaded successfully',
        },
        default: {
            name_not_available: 'Untitled.msg',
            subject_name: '(no subject)',
            extension: '.msg',
        },
        responseText: {
            add_email: 'Processing Email Upload',
        },
    },
    failure: {
        discard_document: 'Unable to perform Discard CheckOut operation',
        checkout_document: 'Unable to perform CheckOut Operation',
        general_failure: 'An unexpected error has occurred and has been logged.',
        upload_document: 'Unable to perform Upload Operation',
        checkin_document: 'Unable to perform CheckIn Operation',
        blank_document: 'Zero-byte files cannot be added or updated. Please verify file before uploading.',
    },
    banner: {
        title_read_only: 'Read Only',
        title_previous_version: 'Previous Version',
        checkout_label: 'Check Out For Editing',
        read_only_message: 'This document has opened in read only view.',
        checked_out_not_by_me: ' This document has already been checked out by {lockedBy}.',
        previous_version_message:
            'You are viewing an older version of the document and only the newer/latest version of the document can be Checked Out.',
        icon: 'messageBarIconPathDemo',
        checkout_message: 'This document is open in read only view.',
        banner_loading_text: 'Loading',
    },
    stale: {
        deleted_document: 'This document was deleted recently. Your screen will refresh to reflect the changes.',
        checkedout_document:
            'Unable to check out document. Document is already checked out by {lockedBy}. Your screen will refresh to reflect the changes.',
        discarded_document:
            'This document is no longer checked out by you. Your screen will refresh to reflect the changes.',
        discarded_other_user_document: 'Unable to discard out document. Document is already checked out by {lockedBy}.',
        deleted_email: 'This email was deleted recently. Your screen will refresh to reflect the changes.',
        deleted_folder: 'This folder was deleted recently. Your screen will refresh to reflect the changes.',
        checkin_document: 'This document is already checked in. Your screen will refresh to reflect the changes.',
    },
    itemPage: {
        quickfile_add_document: `Add Document`,
        quickfile_save_with_new_name_document: `Save As`,
    },
    autoUpdate: {
        server_URL_not_provided: 'Auto Update Server URL not provided',
    },
    checkOnSpecificError: {
        download_interrupt_OC: 'Download was interrupted',
        download_filePath_exceeded_limit: 'Download process has been canceled due to file name path limitations',
    },
};
