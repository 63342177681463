(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("reflect-metadata"), require("@wk/elm-uui-common"));
	else if(typeof define === 'function' && define.amd)
		define(["reflect-metadata", "@wk/elm-uui-common"], factory);
	else if(typeof exports === 'object')
		exports["uuiDocComponent"] = factory(require("reflect-metadata"), require("@wk/elm-uui-common"));
	else
		root["uuiDocComponent"] = factory(root["reflect-metadata"], root["@wk/elm-uui-common"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__2150__, __WEBPACK_EXTERNAL_MODULE__2494__) => {
return 