import { IListScreenColumn, ISortEntry } from './types';

export const TOOLTIP_CHARACTER_LENGTH = 255;

export const parseSortData = (sortData: string | null): ISortEntry[] => {
    if (sortData === null) {
        return [];
    }
    const sorts = sortData.split('|');
    const retVal = [] as ISortEntry[];
    sorts.map((s) => {
        const values = s.split(',');
        retVal.push({ path: values[0], direction: values[1] });
    });
    return retVal;
};

export const stringifySortData = (sortData: ISortEntry[]): string => {
    const values = sortData.map((e) => `${e.path},${e.direction}`);
    return values.join('|');
};

// split the columns array into a collection of two item sets. ex:[[{0: column1}], [{0: column2, 1: column3}]]
export const getColumnItems = (columns: IListScreenColumn[]): any => {
    const arrColumns: any[] = [];
    columns.forEach((_item: any, index: number) => {
        if (index === 0) {
            arrColumns.push([columns[index]]);
        } else if (index % 2) {
            // two columns at a time - the current and next item
            arrColumns.push([columns[index], columns[index + 1]]);
        }
    });
    return arrColumns;
};
