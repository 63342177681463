import { applicationContainer } from './application.module';
import { ApplicationInterface, ApplicationService } from './application.service';

export const Application = applicationContainer.get<ApplicationInterface>(ApplicationService);

export {
    AutoUpdateStateType,
    AutoUpdateCommandType,
    AutoUpdateErrorType,
    AutoUpdateRequestType,
    AutoUpdateRequestCondition,
    AutoUpdateValueType,
    AutoUpdateRequest,
    AutoUpdateError,
    AppVersionInfo,
    DownloadProgressInfo,
    AutoUpdateValue,
    AutoUpdateState,
    UpdateServerInfo,
    AutoUpdateStateChangeRequest,
} from './appVersion.service';

export { AuthInfoDto, AuthInfoRequestDto } from './appStorage.service';
export { LogLevelType, LogLevelDto, StoreFilePathDto, SpellCheckLanguageDto } from './appSettings.service';
