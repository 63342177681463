import { IOutlookService } from '../../services';
import { injectable } from 'inversify';
import { FileOperations, MailItem, Outlook, OutlookContext } from '@wk/office-companion-js';
import { factory } from '../../configLog4J';
import { EmailItem } from '../../dto/emailItem';
import { FileExtensions } from '../../enum/file-extensions.enum';
import { v4 as uuid } from 'uuid';
import { EmailType } from '../../enum/enum';
import dayjs from 'dayjs';
import { Locale } from '../../locale';

const log = factory.getLogger('OutlookService');

@injectable()
export class OutlookService implements IOutlookService {
    public async isInspectorWindow(): Promise<boolean> {
        try {
            return await Outlook.run(async (context) => {
                const { inspector } = context;
                await context.sync();
                return inspector.isResolved && !inspector.isNull;
            });
        } catch (error) {
            log.error(' Inside | isInspectorWindow() ' + error);
            return false;
        }
    }

    public async isExplorerWindow(): Promise<boolean> {
        try {
            return await Outlook.run(async (context) => {
                const { explorer } = context;
                await context.sync();
                return explorer.isResolved && !explorer.isNull;
            });
        } catch (error) {
            log.error(' Inside | isExplorerWindow() ' + error);
            return false;
        }
    }

    public getEmailUniqueId(emailMetaData?: EmailItem): string | undefined {
        if (emailMetaData?.emailSubject && emailMetaData?.senderName && emailMetaData?.receivedTime) {
            const receivedTime = dayjs(emailMetaData?.receivedTime, { utc: true }).format('YYYY-MM-DDTHH:mm:ssZZ');
            return emailMetaData?.emailSubject + emailMetaData?.senderName + receivedTime;
        } else if (emailMetaData?.emailSubject) {
            return emailMetaData.emailSubject + Date.now();
        }

        return;
    }

    public async isMail(context: OutlookContext, mailItem: MailItem): Promise<boolean> {
        context.load(mailItem, ['isMail']);
        await context.sync();
        return mailItem.isMail;
    }

    public async isDraft(context: OutlookContext, mailItem: MailItem): Promise<boolean> {
        context.load(mailItem, ['sent']);
        await context.sync();
        return !mailItem.sent;
    }

    private async isAttachmentPresent(context: OutlookContext, mailItem: MailItem): Promise<string> {
        const { attachments } = mailItem;
        context.load(attachments, 'count');
        await context.sync();
        return attachments.count > 0 ? 'true' : 'false';
    }

    private async getSavePath(subject: string, isDragAndDrop?: boolean): Promise<string> {
        const extension = FileExtensions.Msg;
        const parts = isDragAndDrop ? ['dndEmails', uuid().toString()] : ['quick_file', uuid().toString()];
        const savePath = await FileOperations.resolvePath({
            rootName: 'fileStorage',
            parts: parts,
            filename: subject + extension,
        });

        if (savePath.filePath?.toLowerCase() === 'msg') {
            log.debug('Quick File Email without extension not supported');
        }
        return savePath.filePath;
    }

    private async getValidEmailEntity(
        context: OutlookContext,
        mailItem: MailItem,
        isDragAndDrop?: boolean,
    ): Promise<EmailItem> {
        let subject: string | undefined;
        try {
            const isAttachmentPresent = await this.isAttachmentPresent(context, mailItem);
            context.load(mailItem, [
                'subject',
                'to',
                'body',
                'senderName',
                'senderEmailAddress',
                'receivedTime',
                'sentOn',
            ]);
            await context.sync();
            subject = mailItem.subject ? `${mailItem.subject}` : Locale.email.default.subject_name;
            const savePath = await this.getSavePath(subject, isDragAndDrop);
            mailItem.saveAs(savePath);
            await context.sync();
            return {
                emailSubject: subject,
                emailRecipients: mailItem.to,
                emailBody: mailItem.body,
                senderName: mailItem.senderName,
                senderEmailAddress: mailItem.senderEmailAddress,
                receivedTime: mailItem.receivedTime.toString(),
                sentTime: mailItem.sentOn.toString(),
                isAttachmentPresent: isAttachmentPresent,
                path: savePath,
                emailType: EmailType.VALID,
            };
        } catch (error) {
            log.error(error);
            return {
                emailSubject: subject ? subject : Locale.email.default.subject_name,
            };
        }
    }

    private async getInvalidEmailEntity(
        context: OutlookContext,
        mailItem: MailItem,
        emailType: EmailType,
    ): Promise<EmailItem> {
        let subject: string | undefined;
        try {
            context.load(mailItem, ['subject']);
            await context.sync();
            subject = mailItem.subject ? `${mailItem.subject}` : Locale.email.default.subject_name;
        } catch (error) {
            log.error(error);
        }
        return {
            emailSubject: subject ? subject : Locale.email.default.subject_name,
            emailType: emailType,
        };
    }

    private async getEmailEntity(
        context: OutlookContext,
        mailItem: MailItem,
        isDragAndDrop?: boolean,
    ): Promise<EmailItem> {
        try {
            if (await this.isMail(context, mailItem)) {
                if (!(await this.isDraft(context, mailItem))) {
                    return await this.getValidEmailEntity(context, mailItem, isDragAndDrop);
                } else {
                    return await this.getInvalidEmailEntity(context, mailItem, EmailType.DRAFT_EMAIL);
                }
            } else {
                return await this.getInvalidEmailEntity(context, mailItem, EmailType.NOT_VALID_EMAIL);
            }
        } catch (error) {
            log.error(error);
            return {
                emailSubject: Locale.email.default.subject_name,
            };
        }
    }

    public async getEmailMetadata(isDragAndDrop = false): Promise<EmailItem[]> {
        const emailItemArray: EmailItem[] = [];
        if (await this.isInspectorWindow()) {
            await Outlook.run(async (context) => {
                const mailItem = context.application.activeInspector.currentItem;
                const emailEntity = await this.getEmailEntity(context, mailItem, isDragAndDrop);
                emailItemArray.push(emailEntity);
            });
        } else if (await this.isExplorerWindow()) {
            await Outlook.run(async (context) => {
                const selection = context.application.activeExplorer.selection;
                context.load(selection, ['count']);
                await context.sync();
                for (let index = 1; index <= selection.count; index++) {
                    const mailItem = selection.getItem(index);
                    const emailEntity = await this.getEmailEntity(context, mailItem, isDragAndDrop);
                    emailItemArray.push(emailEntity);
                }
            });
        }
        return emailItemArray;
    }
}
