import { ClientObject, PropertyInit } from '../common/clientObject';
import { RequestType } from '../comObject';

export class Attachments extends ClientObject implements PropertyInit {
    private properties = this.clientObjectInitPropsInfo({
        count: { propertyName: 'count', name: 'Count' },
    });

    public getPropertyInitializers(): Map<keyof Attachments, () => void> {
        return new Map<keyof Attachments, () => void>(
            Object.values(this.properties).map(({ propertyName, name }) => [
                propertyName,
                (): void =>
                    this.reference.request({
                        type: RequestType.GetProperty,
                        cacheName: propertyName,
                        name,
                    }),
            ]),
        );
    }

    public get count(): number {
        return this.reference.getValue<number>(this.properties.count.propertyName).value;
    }
}
