import { injectable } from 'inversify';
import { Observable } from 'rxjs';
import { baseCommunicationService } from '@wk/office-companion-js-common';

enum ItemOperationChannel {
    Listen = 'listen-for-item-operations',
}

export interface ItemOperationInterface {
    /**
     * Instances listening will be notified when Events triggered in Office Apps
     *
     *
     * @returns Observable which sends {@link ItemOperationNotification } object
     *  when any Events triggered in Office Apps like Save/Close/Open
     */
    listen(): Observable<{ data: ItemOperationNotification }>;
}

export interface ItemOperationSaved {
    /**
     * This will contain the Fullname of Workbook in which Saved event triggered.
     */
    itemName: string;
}

export interface ItemOperationSaving {
    /**
     * This will contain the Fullname of Document/ Workbook / Presentation in which Saving event triggered.
     */
    itemName: string;
}

export interface ItemOperationOpened {
    /**
     * This will contain the Fullname of Document/ Workbook / Presentation in which Opened event triggered.
     */
    itemName: string;
}

export interface ItemOperationClosed {
    /**
     * This will contain the Fullname of Document/ Workbook / Presentation in which Closed event triggered.
     */
    itemName: string;
}

export interface ItemOperationNotification {
    /**
     *  Contains information of event
     *
     *  Not empty when a Saved event triggered in Excel
     */
    itemOperationSaved?: ItemOperationSaved;

    /**
     *  Contains information of event
     *
     *  Not empty when a Saving event triggered in office apps Word/Excel/Powerpoint
     *  For Word and Excel Save event notification will be send if Save operation performed from the File Menu UI.
     */
    itemOperationSaving?: ItemOperationSaving;

    /**
     *  Contains information of event
     *
     *  Not empty when a Opened event triggered in office apps Word/Excel/Powerpoint
     */
    itemOperationOpened?: ItemOperationOpened;

    /**
     *  Contains information of event
     *
     *  Not empty when a Closed event triggered in office apps Word/Excel/Powerpoint
     */
    itemOperationClosed?: ItemOperationClosed;
}

@injectable()
export class ItemOperationService implements ItemOperationInterface {
    public listen(): Observable<{ data: ItemOperationNotification }> {
        return baseCommunicationService.on<ItemOperationNotification>(ItemOperationChannel.Listen);
    }
}
