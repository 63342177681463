import { injectable } from 'inversify';
import { ComBridgeService, ComObject, GetContextData } from '../comObject';
import { ClientObject } from './clientObject';
import { OfficeContextScope } from './officeContextScope';
import { ReferenceObject } from './referenceObject';
import { LoggerService } from '../../../common/logger/logger.service';
import { OfficeAppType } from './officeAppType.enum';
import { lastValueFrom } from 'rxjs';

@injectable()
export class OfficeService {
    constructor(protected comBridgeService: ComBridgeService, protected logger: LoggerService) {}

    public async run<T = void, C extends ClientObject = ClientObject>(
        ContextCreator: new (scope: OfficeContextScope, reference: ReferenceObject) => C,
        batch: (context: C) => Promise<T> | T,
        type: OfficeAppType,
    ): Promise<T> {
        const contextData: GetContextData = { type };
        const contextComObject = await lastValueFrom(this.comBridgeService.getContext(contextData));
        const scope = new OfficeContextScope(this.logger);
        const reference = this.getReference(scope, contextComObject);
        const context = new ContextCreator(scope, reference);
        const result = await batch(context);

        await scope.release();
        return result;
    }

    private getReference(scope: OfficeContextScope, contextComObject: ComObject): ReferenceObject {
        const reference = new ReferenceObject(scope);
        reference.addComObject(contextComObject);

        return reference;
    }
}
