import { IDocTransformer } from '../../interfaces/transformer/type';
import { injectable } from 'inversify';
import { factory } from '../../ConfigLog4j';
import { IMatterDoc, ICheckIn } from '../../types';
import { IDocOperationInvocation } from '../../interfaces/urlPattern/types';
import { ICheckIn_T360 } from '../types';
import { T360ValidateCheckInDocumentActionableEntity } from '../actionableEntity/validateCheckInDocumentActionableEntity';
import { T360AdapterCheckInActionableEntity } from '../actionableEntity/checkInActionableEntity';

const log = factory.getLogger('T360AdapterCheckInEntityTransformer');

@injectable()
export class T360AdapterValidateCheckInDocumentEntityTransformer implements IDocTransformer {
    transformIntoActionableEntity(
        nodeObj: ICheckIn,
        _matterDoc: IMatterDoc,
        _urlPattern: IDocOperationInvocation,
    ): T360ValidateCheckInDocumentActionableEntity {
        log.debug('Calling transformIntoActionableEntity');

        const nodeObj1 = nodeObj as ICheckIn_T360;
        let obj = {} as T360ValidateCheckInDocumentActionableEntity;
        // TO CREATE ACTIONABLE ENTITY FROM nodeObj and matterDoc

        obj = nodeObj1.extendedProps as T360AdapterCheckInActionableEntity;

        obj.documentName = nodeObj1.existingDocumentName;

        obj.documentType = nodeObj1.documentType;

        obj.documentId = nodeObj1.docId;

        obj.comment = nodeObj1.extendedProps?.comment;

        return obj;
    }
}
