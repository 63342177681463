import { IAppsMenu, UUIReduxAction } from './types';

const setActiveContextId = (state: IAppsMenu, id: number): IAppsMenu => {
    return { ...state, activeContext: id };
};

export const appsMenuReducer = (state: IAppsMenu, action: UUIReduxAction): any => {
    switch (action.type) {
        case 'SetActiveContextId':
            return setActiveContextId(state, action.contextId);
    }
    return state;
};
