import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import ToastMessage from '../toastMessage';
import css from './notifier.module.scss';
import { SnackbarProvider } from 'notistack';
import { IconButton } from '@material-ui/core';
import { useReduxSelector } from '../../../hooks/useReduxSelector';
import { useReduxDispatch } from '../../../hooks/useReduxDispatch';
import { messageBusDispatch } from '../../contextLayerService/messageBusService';
import { CHMessagingScope } from '@wk/elm-uui-context-handler';

let displayed: string[] = [];

export const Notifier: React.FC = () => (
    <SnackbarProvider
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        classes={{
            variantSuccess: css.snackbarSuccess,
            variantInfo: css.snackbarInfo,
            variantWarning: css.snackbarWarning,
            variantError: css.snackbarError,
            containerRoot: css.containerRoot,
        }}
        dense
        hideIconVariant
        maxSnack={8}
        disableWindowBlurListener={true}>
        <NotifierComponent />
    </SnackbarProvider>
);

const NotifierComponent: React.FC = () => {
    const notifications = useReduxSelector((state) => state.ui.notifications);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const reduxDispatch = useReduxDispatch();

    const storeDisplayed = (id: string) => {
        displayed = [...displayed, id];
    };

    const removeDisplayed = (id: string) => {
        displayed = [...displayed.filter((key) => id !== key)];
    };

    useEffect(() => {
        const invokeDismissToast = (key: string) => {
            messageBusDispatch({
                type: 'DismissToast',
                scope: CHMessagingScope.OtherInstances,
                message: key,
            });
            reduxDispatch({
                type: 'DismissNotification',
                key: key,
            });
        };
        const invokeRemoveToast = (key: string) => {
            messageBusDispatch({
                type: 'RemoveToast',
                scope: CHMessagingScope.OtherInstances,
                message: key,
            });
            reduxDispatch({
                type: 'RemoveNotification',
                key: key,
            });
        };
        notifications.forEach(({ key, message, dismissed = false, options }) => {
            if (dismissed) {
                // dismiss snackbar using notistack
                closeSnackbar(key);
                return;
            }
            // do nothing if snackbar is already displayed
            if (displayed.includes(key)) {
                return;
            }

            // display snackbar using notistack
            enqueueSnackbar(<ToastMessage type={options.variant} message={message} />, {
                key,
                ...options,
                // eslint-disable-next-line react/display-name
                action: (key) => {
                    return (
                        <IconButton
                            className={css.closeToastButton}
                            aria-label="close"
                            data-testid="closeIcon"
                            onClick={() => {
                                invokeDismissToast(key as string);
                            }}>
                            <CloseIcon aria-label="close" className={css.closeToastIcon} />
                        </IconButton>
                    );
                },
                onClose: (event, reason, myKey) => {
                    if (options.onClose) {
                        options.onClose(event, reason, myKey);
                    }
                },
                onExited: (_, myKey) => {
                    // remove this snackbar from redux store
                    reduxDispatch({ type: 'RemoveNotification', key: myKey as string });
                    invokeRemoveToast(myKey as string);
                    removeDisplayed(myKey as string);
                },
            });

            // keep track of snackbars that we've displayed
            storeDisplayed(key);
        });
    }, [closeSnackbar, enqueueSnackbar, notifications, reduxDispatch]);

    return null;
};
