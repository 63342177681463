import { Container, interfaces } from 'inversify';
import { commonContainer } from '../../common/common.module';
import { StreamDemoService, StreamDemoInterface } from './streamDemo.service';

const importedContainers = [commonContainer];

const streamDemoContainer: interfaces.Container = importedContainers.reduce(
    (container, imports) => Container.merge(imports, container),
    new Container(),
);

streamDemoContainer.bind<StreamDemoInterface>(StreamDemoService).toSelf().inSingletonScope();

export const StreamDemo = streamDemoContainer.get(StreamDemoService);

export { StreamDemoOptions } from './streamDemo.service';
