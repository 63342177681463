import { CapabiltyEnum } from './enum/enum';

export const QueueConfig = {
    [CapabiltyEnum.ADD_DOCUMENT]: true,
    [CapabiltyEnum.ADD_EMAIL]: true,
    [CapabiltyEnum.ADD_FOLDER]: false,
    [CapabiltyEnum.CANCEL_CHECKOUT]: false,
    [CapabiltyEnum.CHECKIN_DOCUMENT]: true,
    [CapabiltyEnum.CHECKOUT]: false,
    [CapabiltyEnum.DELETE]: false,
    [CapabiltyEnum.DELETE_DOCUMENT]: false,
    [CapabiltyEnum.DELETE_EMAIL]: false,
    [CapabiltyEnum.DELETE_EMAIL_FOLDER]: false,
    [CapabiltyEnum.DELETE_FOLDER]: false,
    [CapabiltyEnum.DOCUMENT_METADATA]: false,
    [CapabiltyEnum.DOWNLOAD_DOCUMENT]: false,
    [CapabiltyEnum.LIST]: false,
    [CapabiltyEnum.RENAME_DOCUMENT]: false,
    [CapabiltyEnum.RENAME_FOLDER]: false,
};
export const ItemScreenOperationConfig = {
    [CapabiltyEnum.ADD_FOLDER]: true,
    [CapabiltyEnum.EDIT_FOLDER]: true,
    [CapabiltyEnum.EDIT_DOCUMENT]: true,
    [CapabiltyEnum.CANCEL_CHECKOUT]: false,
    [CapabiltyEnum.CHECKOUT]: false,
    [CapabiltyEnum.DELETE_DOCUMENT]: false,
    [CapabiltyEnum.DELETE_EMAIL]: false,
    [CapabiltyEnum.DELETE_EMAIL_FOLDER]: false,
    [CapabiltyEnum.DELETE_FOLDER]: false,
};
