import { createRxDatabase, addRxPlugin, RxDatabase, RxCollection } from 'rxdb/plugins/core';
import { factory } from '../common/logging/configLog4j';
const log = factory.getLogger('database');
import { RxDBValidateZSchemaPlugin } from 'rxdb/plugins/validate-z-schema';
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder';
import { RxDBUpdatePlugin } from 'rxdb/plugins/update';
import { addPouchPlugin, getRxStoragePouch, RxStoragePouch } from 'rxdb/plugins/pouchdb';
import { RxDBMigrationPlugin } from 'rxdb/plugins/migration';
import { RxDBAttachmentsPlugin } from 'rxdb/plugins/attachments';

addRxPlugin(RxDBAttachmentsPlugin);
addRxPlugin(RxDBUpdatePlugin);
addRxPlugin(RxDBQueryBuilderPlugin);
addRxPlugin(RxDBValidateZSchemaPlugin);
addRxPlugin(RxDBMigrationPlugin);

export const getAdapter = (): RxStoragePouch => {
    if (isNodeEnvironment()) {
        return getRxStoragePouch('memory');
    } else {
        return getRxStoragePouch('idb');
    }
};

import { RxDBEncryptionPlugin } from 'rxdb/plugins/encryption';
import { MAX_RETRIES, NO_PROCESSED_ELEMENTS } from '../common/config';
import { IDBCollections, IMigrationState, IQueueConfig } from '../queueManager/queueManager.interface';
import { isNodeEnvironment } from '../common/utils';
import { CollectionNames } from '../queueManager/enums/queueItem.enum';
import { queueConfig, queueProps, queueSchemaNoAttachments } from './queue.schema';

log.debug('entering database ');

addRxPlugin(RxDBEncryptionPlugin);
// eslint-disable-next-line @typescript-eslint/no-var-requires
// addRxPlugin(require('pouchdb-adapter-idb'));
// eslint-disable-next-line @typescript-eslint/no-var-requires
// addRxPlugin(require('pouchdb-adapter-http')); //enable syncing over http
// eslint-disable-next-line @typescript-eslint/no-var-requires
addPouchPlugin(require('pouchdb-adapter-idb'));

if (isNodeEnvironment()) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    // addRxPlugin(require('pouchdb-adapter-memory'));
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    addPouchPlugin(require('pouchdb-adapter-memory'));
}

let dbPromise: Promise<IDBCollections>;

export const _create = async (name: string, secretKey: string): Promise<IDBCollections> => {
    let db: RxDatabase;
    try {
        const password = btoa(name.replace('/', '') + secretKey);
        log.debug('DatabaseService: creating database.');
        db = await createDatabase(name, password);
        log.debug('DatabaseService: created database.');

        log.debug('DatabaseService: creating collections.');
        const migrationStates = await addCollections(db);
        const hasMigrationErrors = migrationStates.some((el) => el.hasMigrationErrors === true);
        log.debug('DatabaseService: creating collections has migration errors ' + hasMigrationErrors);

        if (hasMigrationErrors) {
            log.debug('DatabaseService: Removing error occurred collections and creating new collection.');
            log.debug('Migration states: ' + JSON.stringify(migrationStates));
            await removeAndAddCollection(db, migrationStates);
        }

        const queueConfig1 = {
            id: '0',
            numConcurrentElements: NO_PROCESSED_ELEMENTS,
            maxRetries: MAX_RETRIES,
        };

        await db.queueconfig.atomicUpsert(queueConfig1);
        return { dbCollections: db, hasMigrationErrors };
    } catch (error) {
        log.debug('Error in creating db' + error);
        throw Error('Error in creating db' + error);
    } finally {
        log.debug('DatabaseService: added collection');
    }
};

/**
 *
 * @param name database Name
 * @param adapter adapter name
 * if the database instance already exists with the database Name it returns the instance
 * Else it will create the instance
 */
export const get = async (name: string, secretKey: string): Promise<IDBCollections> => {
    let db!: IDBCollections;
    if (dbPromise) {
        db = await dbPromise;
    }
    if (db && db.dbCollections && db.dbCollections.name === name) {
        log.debug('Db instance already present');
        return db;
    } else {
        if (db) {
            log.debug('created database Name:' + db.dbCollections?.name);
            log.debug('database to be created with name:' + name);
        }
        log.debug('Db instance not already present:');
        dbPromise = _create(name, secretKey);
        return dbPromise;
    }
};

export const getSequenceNextValue = async (collection: RxCollection): Promise<string> => {
    const query = collection.findOne().sort({ id: 'desc' });

    const res: IQueueConfig = await query.exec();
    const seq = parseInt(res.id) + 1;
    log.debug('getSequenceNextValue returned' + seq);
    return seq.toString();
};

const addCollections = async (db: RxDatabase): Promise<IMigrationState[]> => {
    const migrationStates: IMigrationState[] = [];
    await db.addCollections({
        [CollectionNames.QUEUECONFIG]: {
            schema: queueConfig,
        },
        [CollectionNames.QUEUE]: {
            schema: queueSchemaNoAttachments,
        },
        [CollectionNames.QUEUEPROPS]: {
            schema: queueProps,
        },
    });
    log.info('Completed adding collections');
    return migrationStates;
};

const removeAndAddCollection = async (db: RxDatabase, migrationStates: IMigrationState[]): Promise<void> => {
    for (const element of migrationStates) {
        await db[element.collectionName].remove();
        log.debug(' Removed collection successfully ' + element.collectionName);
        await db.addCollections({
            [element.collectionName]: {
                schema: element.schemaList,
            },
        });
        log.debug(' Added latest collection successfully ' + element.collectionName);
    }
    log.info('Recreated collections successfully');
};

const createDatabase = async (name: string, password: string): Promise<RxDatabase> => {
    return await createRxDatabase({
        name,
        storage: getAdapter(),
        password: password,
    });
};
