import React from 'react';
import { Tabs } from '@material-ui/core';
import { Tab as MuiTab, TabProps } from '@material-ui/core';
import css from './tabs.module.scss';

export default Tabs;

export const Tab: React.FC<TabProps> = (props) => {
    const { classes, ...other } = props;
    const tabClasses = { ...classes } || {};
    tabClasses.root = tabClasses.root ? `${tabClasses.root} ${css.tabRoot}` : css.tabRoot;
    tabClasses.wrapper = tabClasses.wrapper ? `${tabClasses.wrapper} ${css.tabWrapper}` : css.tabWrapper;
    return <MuiTab {...other} classes={tabClasses} />;
};
