import { IDocTransformer } from '../../interfaces/transformer/type';
import { IActionableEntity } from '../../interfaces/actionEntity/type';
import { injectable } from 'inversify';
import { factory } from '../../ConfigLog4j';
import { IMatterDoc, IDocumentMetadataFileName } from '../../types';
import { JRAdapterDocumentMetadataFileNameActionableEntity } from '../actionableEntity/documentMetadataFileNameActionableEntity';
import { IDocOperationInvocation } from '../../interfaces/urlPattern/types';

const log = factory.getLogger('JRAdapterDocumentMetadataFileNameEntityTransformer');

@injectable()
export class JRAdapterDocumentMetadataFileNameEntityTransformer implements IDocTransformer {
    transformIntoActionableEntity(
        nodeObj: IDocumentMetadataFileName,
        _matterDoc: IMatterDoc,
        _urlPattern: IDocOperationInvocation,
    ): IActionableEntity {
        log.debug('Calling transformIntoActionableEntity');
        const obj = {} as JRAdapterDocumentMetadataFileNameActionableEntity;
        if (nodeObj.entityContext.id) {
            obj.relatedEntityId = nodeObj.entityContext.id;
        }

        if (nodeObj.entityContext.name) {
            obj.relatedEntityName = nodeObj.entityContext.name;
        }
        if (nodeObj.entityContext.type) {
            obj.relatedEntityType = nodeObj.entityContext.type;
        }
        // TO CREATE ACTIONABLE ENTITY FROM nodeObj and matterDoc
        return obj;
    }
}
