import { baseCommunicationService, BaseCommunicationEvent } from '@wk/office-companion-js-common';
import { injectable } from 'inversify';
import { Observable } from 'rxjs';

enum TokenChannel {
    set = 'set-token',
    get = 'get-token',
    delete = 'delete-token',
}

/**
 * This class exposes functions related storing token.
 * The Service allows the user to excute set, get, delete
 * The service stores the data in the password store
 */
@injectable()
export class TokenManagmentService {
    /**
     * This exposes functions related storing token.
     *  @param id  takes id.
     *  @param secret  takes token.
     *  @param token  takes a token to store on machine..
     *
     * @returns  resolves if token is stored in Password store
     */
    public setToken(id: string, secret: string, token: string): Observable<BaseCommunicationEvent<unknown>> {
        try {
            return baseCommunicationService.invoke(TokenChannel.set, { id, secret, token });
        } catch (error) {
            throw new Error(error);
        }
    }

    /**
     * This exposes functions related getting token.
     *  @param id  takes id to find token.
     *  @param secret  takes token.
     *  @param secret  takes secret to find token.
     *
     * @returns  resolves with token if token exists in Password store
     */
    public getToken(id: string, secret: string): Observable<BaseCommunicationEvent<string>> {
        try {
            return baseCommunicationService.invoke<string>(TokenChannel.get, { id, secret, token: '' });
        } catch (error) {
            throw new Error(error);
        }
    }

    /**
     * This exposes functions related deleting token.
     *  @param id  takes id to find token.
     *  @param secret  takes secret to find token.
     *
     * @returns  resolves if token is deleted in Password store
     */
    public deleteToken(id: string, secret: string): Observable<BaseCommunicationEvent<unknown>> {
        return baseCommunicationService.invoke(TokenChannel.delete, { id, secret, token: '' });
    }
}
