import React, { createContext, useContext } from 'react';
import { IItemScreenState, ItemScreenDispatch } from '../types';
import { itemScreenReducer } from './itemScreenReducer';
import { merge } from 'lodash';
import { useForm, FormProvider } from 'react-hook-form';
import { useReducerWithLogging } from '../../../hooks/useReducerWithLogging';

const defaultInitialState: IItemScreenState = {
    activeTab: 0,
    visitedTabs: [{ index: 0 }],
};

const ItemScreenContext = createContext<IItemScreenState>({} as IItemScreenState);

const ItemScreenDispatchContext = createContext<ItemScreenDispatch | undefined>(undefined);

// initial state override should be used in jest tests only
interface IItemScreenProvider {
    initialStateOverride?: Partial<IItemScreenState>;
}

export const ItemScreenProvider: React.FC<IItemScreenProvider> = ({ children, initialStateOverride }) => {
    const initialState = initialStateOverride
        ? merge({}, defaultInitialState, initialStateOverride)
        : defaultInitialState;
    const [state, dispatch] = useReducerWithLogging('itemscreencontext', itemScreenReducer, initialState);
    const methods = useForm();
    return (
        <FormProvider {...methods}>
            <ItemScreenContext.Provider value={state}>
                <ItemScreenDispatchContext.Provider value={dispatch}>{children}</ItemScreenDispatchContext.Provider>
            </ItemScreenContext.Provider>
        </FormProvider>
    );
};

export const useItemScreenState = (): IItemScreenState => {
    const context = useContext(ItemScreenContext);
    if (context === undefined) {
        throw new Error('useItemScreenState must be used within a ItemScreenProvider');
    }
    return context;
};

export const useItemScreenDispatch = (): ItemScreenDispatch => {
    const context = useContext(ItemScreenDispatchContext);
    if (context === undefined) {
        throw new Error('useItemScreenDispatch must be used within a ItemScreenProvider');
    }
    return context;
};

export const useParentItemScreenDispatch = (): ItemScreenDispatch | undefined => {
    return useContext(ItemScreenDispatchContext);
};
