import { IUuiHistoryScreen, UUIReduxAction } from './types';

export const historyScreenReducer = (state: IUuiHistoryScreen, action: UUIReduxAction): IUuiHistoryScreen => {
    switch (action.type) {
        case 'OpenHistoryScreenDialog':
            return { ...state, isHistoryDialogOpen: true };
        case 'CloseHistoryScreenDialog':
            return { ...state, isHistoryDialogOpen: false };
        case 'SetFailedUploadFlyoutOpen':
            return { ...state, isFailedUploadFlyoutOpen: action.isOpen };
        case 'SetBatchUploadFlyoutCount':
            return { ...state, batchUploadFlyoutCount: action.count };
        case 'SetNewUploadCountUpdated':
            return { ...state, isNewUploadCountUpdated: action.isUpdated };
        case 'SetIsUploadingFlyoutOpen':
            return { ...state, isUploadingFlyoutOpen: action.isOpen };
    }
    return state;
};
