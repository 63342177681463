import { FileOperations, OpenDialogProperties } from '@wk/office-companion-js';
import { Locale } from './../locale';
import { CtxCheckInDocumentTransformer } from './../transform/ctxCheckInDocumentTransformer';
import {
    ICHUploadType,
    ICHUploadResponse,
    ICHCheckOut,
    IValidateCheckInDocument,
    ICHValidateCheckInDocument,
} from '../../src/interfaces/operations';
import {
    ICheckIn,
    checkInDocument,
    checkOut,
    ICheckOut,
    validateCheckInDocument,
    getDocumentName,
    splitNameAndExtension,
    DocumentNameSplit,
} from '@wk/elm-uui-doc-component';
import { inject, injectable } from 'inversify';
import 'reflect-metadata';
import { EventType, CapabiltyEnum, ScreenName, ToastType, DocumentState } from '../enum/enum';
import { getReverseTransformedObject, getTransformedObject } from '../transform/helper';
import { DocumentEntity } from '../dto/documentEntity';
import { ScreenHandler } from './../screenHandler/screenHandler';
import { factory } from '../configLog4J';
import {
    // implementQueue,
    convertToPascalCase,
    throwUnExpectedError,
    handleCallback,
    handleUnExpectedError,
    deleteFromPersistentStorage,
} from '../utils/main.utils';
import { Dialog } from '../configurations';
import { CtxtCheckOutTransformer } from '../transform/ctxtCheckOutTransformer';
import { CtxValidateCheckInDocumentTransformer } from '../transform/ctxValidateCheckInDocumentTransformer';
import { IFieldValue } from './interface';
import { IOfficeService } from './office.service';
import { IEventService } from './eventContextHandler.service';
import { IDocumentsService } from './documents.service';
import { DialogService } from './dialog.service';
import { CHMessagingService, MessageService } from './message.service';
import { implementQueue } from '../capability';
const log = factory.getLogger('CheckInService');

export interface ICheckInQueue extends ICheckIn {
    filePath?: string;
}

@injectable()
export class CheckInService {
    private _officeService: IOfficeService;
    private _eventService: IEventService;
    private _documentsService: IDocumentsService;
    private _messageService: MessageService;
    private _chMessageService: CHMessagingService;
    private _dialogService: DialogService;
    private _screenHandler: ScreenHandler;

    private filePath: string;
    // private isDocSummary: boolean;
    private documentEntity: DocumentEntity | undefined;
    // private loggedInUser: string;
    private screenName: ScreenName;

    constructor(
        @inject('OfficeService') officeService: IOfficeService,
        @inject('EventContextHandler') eventService: IEventService,
        @inject('DocumentsService') documentsService: IDocumentsService,
        @inject('MessageService') messageService: MessageService,
        @inject('ScreenHandler') screenHandler: ScreenHandler,
        @inject('CHMessagingService') chMessageService: CHMessagingService,
        @inject('DialogService') dialogService: DialogService,
    ) {
        this._officeService = officeService;
        this._eventService = eventService;
        this._documentsService = documentsService;
        this._messageService = messageService;
        this._screenHandler = screenHandler;
        this._chMessageService = chMessageService;
        this._dialogService = dialogService;

        // this.isDocSummary = false;
        this.screenName = ScreenName.CHECKIN_DOCUMENT;
        // this.loggedInUser = '';
        this.filePath = '';
    }

    public async processDocumentCheckIn(
        contextObj: ICHUploadType,
        isQuickFile: boolean,
        docEntity?: DocumentEntity,
    ): Promise<ICHUploadResponse> {
        try {
            const isDocumentExist = await this._documentsService.wasStaleDocument(contextObj, DocumentState.DOC_EXIST);
            if (isDocumentExist) {
                const isBusy = await this._officeService.isBusy();
                if (isBusy) {
                    const message = contextObj.isQuickFile
                        ? Locale.documents.quickfile_doc
                        : contextObj.isDragAndDrop
                        ? Locale.documents.drag_and_drop
                        : Locale.documents.checkin_doc;
                    await deleteFromPersistentStorage(contextObj.filePaths);
                    return { responseText: await this._documentsService.handleIsApplicationBusy(message) };
                }

                if (contextObj.folderArr && !docEntity && !contextObj.isSaveOrOverwrite) {
                    this._officeService.showEmailOrDocumentUploadToast(
                        contextObj.folderArr[0],
                        CapabiltyEnum.CHECKIN_DOCUMENT,
                    );
                }

                // this.isDocSummary = isDocSummary;
                // this.loggedInUser = contextObj.loggedInUser || '';
                if (docEntity) {
                    this.documentEntity = Object.assign({}, docEntity);
                    log.info('Overwrite | CheckIn ' + JSON.stringify(docEntity));
                } else {
                    this.documentEntity =
                        (await this._documentsService.getDocumentMeta(contextObj, !!contextObj.documentId)) ||
                        undefined;
                }

                if (this.documentEntity) {
                    contextObj.uniqueId = await this._documentsService.getUniqueIdFromMeta(this.documentEntity);
                    const { id } = this.documentEntity;
                    contextObj.documentId = id;
                } else {
                    log.info('OC | File not saved');
                    this._documentsService.buildPayload(contextObj, contextObj.fileName || '');
                    const errorMessage = Locale.responseText.file_not_found;
                    await implementQueue(contextObj, CapabiltyEnum.CHECKIN_DOCUMENT, errorMessage);
                    return { responseText: Locale.responseText.file_not_found };
                }

                if (!contextObj.isDragAndDrop) {
                    const currentDocName = await this._officeService.getCurrentDocument();
                    const isCheckinDoc = currentDocName === this.documentEntity?.documentFileName;
                    log.debug('isSameAsCurrentDoc : ' + isCheckinDoc);
                    const isActiveDocSelection = await this._officeService.getActiveDocumentSelection();
                    const isSaved = await this._officeService.isDocumentSaved();
                    if (isActiveDocSelection && !isSaved && isCheckinDoc) {
                        return await this.checkIfDocumentSaved(contextObj, !contextObj.isQuickFile);
                    }
                }

                this.screenName = ScreenName.CHECKIN_DOCUMENT;
                if (isQuickFile) {
                    await this.setFilePath(contextObj);
                    contextObj.name = this.documentEntity?.name;
                    contextObj.fileName = this.documentEntity?.documentFileName;
                }
                this.renderItemScreen(contextObj);
                return { responseText: Locale.responseText.itemScreen_checkIn_document };
            } else {
                await deleteFromPersistentStorage(contextObj.filePaths);
                return { responseText: Locale.stale.deleted_document };
            }
        } catch (error) {
            log.error(JSON.stringify(error));
            throwUnExpectedError(error);
        }
        return { responseText: 'Operation was not performed' };
    }

    private async setFilePath(contextObj: ICHUploadType, filePath?: string) {
        if (filePath) {
            this.filePath = filePath;
        } else if (contextObj.filePaths && contextObj.filePaths?.length > 0) {
            // DnD Operations
            this.filePath = contextObj.filePaths[0];
        } else {
            // QF Operations
            this.filePath = await this._officeService.getCurrentDocumentFullName();
        }
    }

    private async renderItemScreen(contextObj: ICHUploadType): Promise<void> {
        const documentNameSplit: DocumentNameSplit = splitNameAndExtension(this.documentEntity?.name || '');
        const itemScreenRequest = await this._screenHandler.getItemScreenRequest(
            this.screenName,
            {
                name: documentNameSplit.name,
                extension: documentNameSplit.extension,
                fileName: this.documentEntity?.documentFileName || '',
                comments: this.documentEntity?.checkInComment || '',
                parentEntityId: contextObj.associatedEntityTypeId || '',
                folderId: contextObj.folderId || '',
            },
            contextObj,
        );

        log.info('OC | ItemScreenRequest ' + JSON.stringify(itemScreenRequest));

        this._eventService.publish({
            name: EventType.ITEMSCREEN,
            itemScreen: itemScreenRequest,
        });
    }

    public async updateCheckedOutDocument(contextObj: ICHUploadType): Promise<ICHUploadResponse> {
        if (!this.documentEntity) {
            this.documentEntity =
                (await this._documentsService.getDocumentMeta(contextObj, !!contextObj.documentId)) || undefined;
        }

        log.info('OC | Update CheckedOut Document');
        if (this.documentEntity) {
            if (!contextObj.uniqueId) {
                contextObj.uniqueId = await this._documentsService.getUniqueIdFromMeta(this.documentEntity);
            }
            const { id } = this.documentEntity;
            contextObj.documentId = id;
            const data: ICheckInQueue = getTransformedObject<ICHUploadType, ICheckIn, CtxCheckInDocumentTransformer>(
                CapabiltyEnum.CHECKIN_DOCUMENT,
                contextObj,
            );

            const documentEntity =
                (await this._documentsService.getDocumentMeta(contextObj, !!contextObj.documentId)) || undefined;

            const documentNameSplit: DocumentNameSplit = splitNameAndExtension(documentEntity?.documentFileName || '');
            data.existingDocumentName = documentNameSplit.name;
            data.documentType = documentNameSplit.extension;
            const validationResponse = await validateCheckInDocument(data);
            if (validationResponse && !validationResponse.ok) {
                log.info('OC | validation failed');
                const transformData = { errors: {} };
                transformData.errors = getReverseTransformedObject<
                    IValidateCheckInDocument,
                    ICHValidateCheckInDocument,
                    CtxValidateCheckInDocumentTransformer
                >(
                    CapabiltyEnum.VALIDATE_CheckIn_DOCUMENT,
                    validationResponse.statusInfo.errors as unknown as IValidateCheckInDocument,
                );

                validationResponse.statusInfo.errors = transformData.errors;
                const transformedResponse = this._documentsService.transformValidationResponse(validationResponse);
                return { response: transformedResponse };
            }

            const checkedOutResponse = await this.downloadAndCheckOut(contextObj, true, false);
            const isOpen = await this._officeService.isFileOpen(contextObj.uniqueId);
            log.info('OC | isOpen | ' + isOpen);
            const isActive = await this._officeService.isActiveDocument(contextObj.uniqueId, true);
            log.info('OC | isActive | ' + isActive);
            if (checkedOutResponse) {
                if (isOpen && !isActive) {
                    log.info('OC | File is not active and opening switch dialog');
                    await this._documentsService.prepareSwitchDialog(contextObj.uniqueId);
                    await deleteFromPersistentStorage(contextObj.filePaths);
                    return { responseText: Locale.responseText.switch_dialog };
                }

                const { isLocalCopyExists, localFullPath } = await this._documentsService.getCheckoutPathDescriptor(
                    contextObj.uniqueId,
                );
                if (isLocalCopyExists) {
                    if (isActive && !(await this._officeService.isDocumentSaved())) {
                        return await this.checkIfDocumentSaved(contextObj, true);
                    }
                    this.filePath = localFullPath;
                    contextObj.isClose = true;
                    log.info('OC | FilePath | ' + this.filePath);
                } else {
                    if (!contextObj.filePaths) {
                        log.info('OC | File not exists');
                        const { title, message } = Dialog.document.checkin_not_found;
                        const messageInfo = this._messageService.compileTemplate(message, {
                            documentFileName: this.documentEntity.documentFileName,
                        });
                        await this.displayDialog(contextObj, this.documentEntity, messageInfo, title);
                        return { responseText: messageInfo };
                    }
                }
            } else {
                await deleteFromPersistentStorage(contextObj.filePaths);
                return { responseText: 'Document is not checkedout' };
            }

            if (!contextObj.isDragAndDrop && contextObj.documentId && this.filePath) {
                contextObj.filePaths = [];
                contextObj.filePaths[0] = this.filePath;
            }

            log.info('OC | Update Checkedout Doc');
            if (validationResponse && validationResponse.ok) {
                log.info('OC |Queue Started');
                const documentName = getDocumentName(
                    contextObj.payload?.documentName?.value as string,
                    data.existingDocumentName,
                );
                (contextObj.payload as Record<string, IFieldValue>).documentName = data.documentType
                    ? { value: documentName + data.documentType }
                    : { value: documentName };

                if (contextObj.filePaths && isActive && isOpen) {
                    const tempPath = await this._officeService.saveAsInTempStorage(true);
                    if (tempPath) {
                        contextObj.filePaths[0] = tempPath;
                    }
                }

                const result = await implementQueue(contextObj, CapabiltyEnum.CHECKIN_DOCUMENT);

                // Todo: Remove the toast when closing document, after adding it to queue, functionality implemented
                if (isActive) {
                    this.showToast(ToastType.INFORMATION, Locale.documents.checkin_progress_message, true);
                }
                return { response: result.response };
            }
        }
        return { responseText: Locale.responseText.file_not_found };
    }

    private async displayDialog(
        contextObj: ICHUploadType,
        documentEntity: DocumentEntity | undefined,
        messageInfo: string,
        title: string,
    ): Promise<void> {
        const selectFileButton = {
            label: Dialog.buttons.select_file,
            callback: async () => {
                await handleCallback(this.openFileDialog.bind(this, contextObj, documentEntity));
                return;
            },
        };

        const cancelButton = {
            label: Dialog.buttons.cancel,
            callback: () => {
                return;
            },
        };

        const buttons = [selectFileButton, cancelButton];

        this._dialogService.showDialog({
            title,
            message: messageInfo,
            buttons: buttons,
        });
    }

    private async openFileDialog(
        contextObj: ICHUploadType,
        documentEntity: DocumentEntity | undefined,
    ): Promise<ICHUploadResponse> {
        const fileNames = await FileOperations.showOpenDialog({
            openDialogProperties: [OpenDialogProperties.OpenFile],
        });
        let messageInfo: string;
        let title: string;
        if (!fileNames) {
            const { message } = Dialog.document.checkin_not_found;
            title = Dialog.document.checkin_not_found.title;
            messageInfo = this._messageService.compileTemplate(message, {
                documentFileName: documentEntity?.documentFileName,
            });
            await this.displayDialog(contextObj, documentEntity, messageInfo, title);
            return { responseText: messageInfo };
        } else if (
            fileNames &&
            (await this._officeService.getFileNameFromFullPath(fileNames[0])).toLowerCase() !==
                documentEntity?.documentFileName.toLowerCase()
        ) {
            log.info('OC | No matching file to upload');
            const newDocumentName = await this._officeService.getFileNameFromFullPath(fileNames[0]);
            title = Dialog.document.checkin_not_found.title;
            const { message } = Dialog.document.checkin_wrong_file_name;
            messageInfo = this._messageService.compileTemplate(message, {
                documentFileName: newDocumentName,
            });
            await this.displayDialog(contextObj, documentEntity, messageInfo, title);
            return { responseText: messageInfo };
        } else {
            contextObj.filePaths = [];
            contextObj.filePaths.push(fileNames[0]);
            // Gets manually selected file
            log.info('OC | Received file path from manual selection');
            this.setFilePath(contextObj, fileNames[0]);

            this.renderItemScreen(contextObj);
            return { responseText: Locale.responseText.itemScreen_checkIn_document };
        }
    }

    public async updateActiveDocument(contextObj: ICHUploadType): Promise<ICHUploadResponse> {
        log.info('OC | Update Active Document');
        if (!contextObj.isDragAndDrop && !(await this._officeService.isDocumentSaved())) {
            return await this.checkIfDocumentSaved(contextObj, false);
        }
        if (!this.documentEntity) {
            if (!contextObj.documentId) {
                this.setFilePath(contextObj);
                contextObj.name = await this._officeService.getFileNameFromFullPath(this.filePath);
                contextObj.fileName = contextObj.name;
            }
            this.documentEntity =
                (await this._documentsService.getDocumentMeta(contextObj, !!contextObj.documentId)) || undefined;
        }

        if (!contextObj.uniqueId) {
            if (this.documentEntity) {
                contextObj.uniqueId = await this._documentsService.getUniqueIdFromMeta(this.documentEntity);
                const { id } = this.documentEntity;
                contextObj.documentId = id;
            } else {
                log.info('OC | File not saved');
                const errorMessage = Locale.responseText.file_not_found;
                this._documentsService.buildPayload(contextObj, contextObj.fileName || '');
                await implementQueue(contextObj, CapabiltyEnum.CHECKIN_DOCUMENT, errorMessage);
                return { responseText: Locale.responseText.file_not_found };
            }
        }

        const data: ICheckInQueue = getTransformedObject<ICHUploadType, ICheckIn, CtxCheckInDocumentTransformer>(
            CapabiltyEnum.CHECKIN_DOCUMENT,
            contextObj,
        );

        const documentEntity =
            (await this._documentsService.getDocumentMeta(contextObj, !!contextObj.documentId)) || undefined;

        const documentNameSplit: DocumentNameSplit = splitNameAndExtension(documentEntity?.documentFileName || '');
        data.existingDocumentName = documentNameSplit.name;
        data.documentType = documentNameSplit.extension;

        const validationResponse = await validateCheckInDocument(data);
        if (validationResponse && !validationResponse.ok) {
            log.info('OC | validation failed');
            const transformData = { errors: {} };
            transformData.errors = getReverseTransformedObject<
                IValidateCheckInDocument,
                ICHValidateCheckInDocument,
                CtxValidateCheckInDocumentTransformer
            >(
                CapabiltyEnum.VALIDATE_CheckIn_DOCUMENT,
                validationResponse.statusInfo.errors as unknown as IValidateCheckInDocument,
            );

            validationResponse.statusInfo.errors = transformData.errors;
            const transformedResponse = this._documentsService.transformValidationResponse(validationResponse);
            return { response: transformedResponse };
        }
        const response = await this.downloadAndCheckOut(contextObj, !!contextObj.documentId, true);
        if (response) {
            const isOpen = await this._officeService.isFileOpen(contextObj.uniqueId);
            log.info('OC | isOpen | ' + isOpen);

            const isActive = await this._officeService.isActiveDocument(contextObj.uniqueId, true);
            log.info('OC | isActive | ' + isActive);

            if (isActive && isOpen && contextObj.documentId && !contextObj.isDragAndDrop) {
                this.filePath = await this._officeService.getCurrentDocumentFullName();
                contextObj.isClose = true;
            } else if (isActive) {
                contextObj.isClose = true;
            }
            await this.setFilePath(contextObj, this.filePath);
            contextObj.filePaths = [];
            contextObj.filePaths[0] = this.filePath;
            contextObj.isQuickFile = true;

            log.info('OC |Queue Started');
            const documentName = getDocumentName(
                contextObj.payload?.documentName?.value as string,
                data.existingDocumentName,
            );
            (contextObj.payload as Record<string, IFieldValue>).documentName = data.documentType
                ? { value: documentName + data.documentType }
                : { value: documentName };

            if (contextObj.filePaths && isActive && isOpen && !contextObj.isDragAndDrop) {
                const tempPath = await this._officeService.saveAsInTempStorage(true);
                if (tempPath) {
                    contextObj.filePaths[0] = tempPath;
                }
            }

            const result = await implementQueue(contextObj, CapabiltyEnum.CHECKIN_DOCUMENT);

            // Todo: Remove the toast when closing document, after adding it to queue, functionality implemented
            if (isActive) {
                this.showToast(ToastType.INFORMATION, Locale.documents.checkin_progress_message, true);
            }
            return { response: result.response };
        } else {
            log.info('OC | File is not checkedout to upload');
            await deleteFromPersistentStorage(contextObj.filePaths);
            return { responseText: Locale.failure.checkout_document };
        }
    }

    private showToast(type: ToastType, toastMessage: string, onlyThisInstance = false): void {
        this._eventService.publish({
            name: EventType.TOAST,
            toast: {
                type,
                toastMessage,
            },
            onlyThisInstance,
        });
    }

    public async fileUpload(contextObj: ICHUploadType): Promise<ICHUploadResponse> {
        const data: ICheckInQueue = getTransformedObject<ICHUploadType, ICheckIn, CtxCheckInDocumentTransformer>(
            CapabiltyEnum.CHECKIN_DOCUMENT,
            contextObj,
        );
        this.documentEntity =
            (await this._documentsService.getDocumentMeta(contextObj, !!contextObj.documentId)) || undefined;
        if (!contextObj.uniqueId && this.documentEntity) {
            contextObj.uniqueId = await this._documentsService.getUniqueIdFromMeta(this.documentEntity);
        }
        // Needed for T360 to checkIn the file
        const documentNameSplit: DocumentNameSplit = splitNameAndExtension(this.documentEntity?.documentFileName || '');
        data.existingDocumentName = documentNameSplit.name;
        data.documentType = documentNameSplit.extension;
        try {
            data.filePath = contextObj.filePaths && contextObj.filePaths[0];
            const { fileUploadControlId } = this._documentsService.createFileUploadControl();
            data.fileId = fileUploadControlId;
            const files: FileList | undefined = await this._documentsService.getInjectedFile(
                data.fileId,
                data.filePath,
            );

            if (files && files.length > 0) {
                log.info('OC | File Size ' + files[0].size);
                if (files[0].size === 0) {
                    log.error(Locale.failure.blank_document);
                    return { responseText: Locale.failure.blank_document };
                }

                data.file = files[0];
                data.docId = contextObj.documentId || '';
                log.info('OC | Name ' + data.name);
                log.info('OC | File Name ' + data.documentName);
                data.extendedProps.uploadGuid = contextObj.uploadGuid;
                const response = await checkInDocument(data);
                log.info('OC | CHECKIN Response' + response.ok);
                return { response };
            } else {
                return { responseText: Locale.responseText.file_not_found };
            }
        } catch (error) {
            log.error('OC | CheckIN Error');
            if (error) {
                log.error('OC | CheckIN Error ' + error);
            }
            log.info(JSON.stringify(error));
            throwUnExpectedError(error);
            return { responseText: (error as Error).message };
        }
    }

    public async downloadAndCheckOut(
        contextObj: ICHUploadType,
        hasDocumentId: boolean,
        isQuickFile = true,
    ): Promise<boolean> {
        this.documentEntity = await this._documentsService.getDocumentMeta(contextObj, hasDocumentId);
        if (!contextObj.uniqueId) {
            if (this.documentEntity) {
                contextObj.uniqueId = await this._documentsService.getUniqueIdFromMeta(this.documentEntity);
                const { id } = this.documentEntity;
                contextObj.documentId = id;
            } else {
                log.info('OC | File not saved');
                this._documentsService.buildPayload(contextObj, contextObj.fileName || '');
                const errorMessage = Locale.responseText.file_not_found;
                await implementQueue(contextObj, CapabiltyEnum.CHECKIN_DOCUMENT, errorMessage);
                return false;
            }
        }

        if (this.documentEntity) {
            const isCheckedOutByOther: boolean = this._documentsService.isCheckedOutByOther(
                contextObj.loggedInUser || '',
                this.documentEntity.versionSeriesCheckedOutBy,
            );

            log.info('OC | Document is Checkedout By ' + this.documentEntity.versionSeriesCheckedOutBy);

            if (isCheckedOutByOther) {
                const message = this._messageService.compileTemplate(
                    Locale.documents.doc_checked_out_to_different_user,
                    {
                        lockedBy: this.documentEntity.versionSeriesCheckedOutBy,
                        fileName: this.documentEntity.documentFileName,
                    },
                );
                this._documentsService.buildPayload(contextObj, this.documentEntity.documentFileName || '');
                await implementQueue(contextObj, CapabiltyEnum.CHECKIN_DOCUMENT, message);
                return false;
            }

            const isExists = await this._officeService.isFileExists(contextObj.uniqueId);
            if (!isExists && isQuickFile) {
                await this._documentsService.downloadAndOpen({
                    url: this.documentEntity.downloadUrl,
                    isCheckedOutByMe: true,
                    uniqueId: contextObj.uniqueId,
                });
                log.info('OC | File Downloaded');
            }

            const isCheckedOutByMe = this.documentEntity?.versionSeriesCheckedOutBy
                ? await this._documentsService.isCheckedOutByMe(this.documentEntity?.versionSeriesCheckedOutBy)
                : false;
            if (!isCheckedOutByMe) {
                const docToCheckOut: ICHCheckOut = {
                    documentId: this.documentEntity.id,
                    associatedEntityType: convertToPascalCase(this.documentEntity.relatedEntityType),
                    associatedEntityId: this.documentEntity.relatedEntityId,
                    loggedInUser: contextObj.loggedInUser,
                    entityTypeId: contextObj.entityTypeId,
                };
                const transformedObject = getTransformedObject<ICHCheckOut, ICheckOut, CtxtCheckOutTransformer>(
                    CapabiltyEnum.CHECKOUT,
                    docToCheckOut,
                );

                try {
                    await checkOut(transformedObject);
                    return true;
                } catch {
                    log.error('Failed to lock document before CheckIn');
                }
                return false;
            } else {
                log.info('OC | File is already checkedout by same loggedIn user');
                return true;
            }
        }
        return false;
    }

    private async checkIfDocumentSaved(contextObj: ICHUploadType, isCheckIn: boolean): Promise<ICHUploadResponse> {
        if (await this._officeService.isOutlook()) {
            return { responseText: 'Outlook is running' };
        }

        const isDocumentSaved = await this._officeService.isDocumentSaved();
        const isNewDocument = await this._officeService.isNewDocument();
        if (isNewDocument || !isDocumentSaved) {
            await this.prepareSaveDocumentDialog(contextObj, false, isCheckIn);
        }

        log.info(Locale.responseText.file_not_saved);
        return { responseText: Locale.responseText.file_not_saved };
    }

    private async prepareSaveDocumentDialog(
        contextObj: ICHUploadType,
        isNew?: boolean,
        checkIn?: boolean,
    ): Promise<void> {
        const cancelButton = {
            label: Dialog.buttons.cancel,
            callback: async () => {
                await deleteFromPersistentStorage(contextObj.filePaths);
                return;
            },
        };

        const saveButton = {
            label: Dialog.buttons.save,
            callback: async () => {
                try {
                    const isSaved = await this._officeService.saveActiveDocument();
                    if (isSaved) {
                        contextObj.isSaveOrOverwrite = true;
                        await this.processDocumentCheckIn(contextObj, checkIn || true);
                    }
                } catch (error) {
                    log.error(JSON.stringify(error));
                    handleUnExpectedError(error);
                }
            },
        };

        const saveAsButton = {
            label: Dialog.buttons.save_as,
            callback: async () => {
                try {
                    const isSaved = await this._officeService.saveAsActiveDocument();
                    if (isSaved) {
                        contextObj.isSaveOrOverwrite = true;
                        await this.processDocumentCheckIn(contextObj, checkIn || true);
                    }
                } catch (error) {
                    log.error(JSON.stringify(error));
                    handleUnExpectedError(error);
                }
            },
        };

        const saveAndUpload = { ...saveAsButton, label: Dialog.buttons.save_and_upload };
        const readOnlyDocument = await this._officeService.isReadOnly();
        const messageTemplate =
            isNew || checkIn
                ? 'upload_new_active_document'
                : readOnlyDocument
                ? 'upload_exist_active_saveas_document'
                : 'upload_exist_active_document';
        const title = Dialog.confirm[messageTemplate].title;
        const message = this._messageService.compileTemplate(Dialog.confirm[messageTemplate].message);
        const buttons =
            isNew || checkIn
                ? [checkIn ? saveButton : saveAndUpload, cancelButton]
                : readOnlyDocument
                ? [saveAsButton, cancelButton]
                : [saveButton, saveAsButton, cancelButton];

        this._dialogService.showDialog(
            {
                title,
                message,
                buttons,
            },
            contextObj.filePaths,
        );
    }
}
