import { IDocTransformer } from '../../interfaces/transformer/type';
import { IActionableEntity } from '../../interfaces/actionEntity/type';
import { injectable } from 'inversify';
import { factory } from '../../ConfigLog4j';
import { IMatterDoc, IAddFolder } from '../../types';
import { JRAdapterAddFolderActionableEntity } from '../actionableEntity/addFolderActionableEntity';
import { mapNames, mapHref, urlMap } from '../utils/maps';
import { attribute_Add, IAddFolder_JR } from '../types';

const log = factory.getLogger('JRAdapterAddFolderEntityTransformer');

@injectable()
export class JRAdapterAddFolderEntityTransformer implements IDocTransformer {
    matterDoc: IMatterDoc;
    transformIntoActionableEntity(nodeObj: IAddFolder): IActionableEntity {
        log.debug('Calling transformIntoActionableEntity');
        const obj = {} as JRAdapterAddFolderActionableEntity;

        const nodeObj1 = nodeObj as IAddFolder_JR;
        // TO CREATE ACTIONABLE ENTITY FROM nodeObj and matterDoc
        obj.attributes = {
            isFolder: true,
            // name: nodeObj1.name,
            // associatedEntityName: nodeObj1.entityContext.type,
            // parentDocument:
            //   nodeObj1.documentFolderId !== undefined
            //     ? { href: '../../' + urlMap[nodeObj1.entityContext.type] + '/' + nodeObj1.documentFolderId }
            //     : {},
        } as attribute_Add;

        if (nodeObj1.documentFolderId && nodeObj1?.entityContext?.type) {
            obj.attributes.parentDocument = {
                href: '../../' + urlMap[nodeObj1.entityContext.type] + '/' + nodeObj1.documentFolderId,
            };
        } else {
            obj.attributes.parentDocument = {};
        }

        if (nodeObj1?.name) {
            obj.attributes.name = nodeObj1.name;
        }
        if (nodeObj1.entityContext?.type) {
            obj.attributes.associatedEntityName = nodeObj1.entityContext.type;
        }
        if (nodeObj1?.entityContext?.id && nodeObj1?.entityContext?.type) {
            obj.attributes[mapNames[nodeObj1.entityContext.type]] = {
                href: '../../' + mapHref[nodeObj1.entityContext.type] + '/' + nodeObj1.entityContext.id,
            };
        }

        return obj;
    }
}
