import { ExceptionStatus } from './exceptionStatus.enum';

export class BaseResponseException<TError = unknown, T = unknown> extends Error {
    constructor(props: Partial<BaseResponseException<TError, T>>) {
        super(props.message);

        Object.assign(this, props);
    }

    public data?: T;
    public errors?: TError[] | string[];
    public error: string;
    public message: string;
    public status: ExceptionStatus | string;
    public timestamp: string;
}
