import { CapabiltyEnum } from '../../enums/capability';
import { DocSupportedBaseEntityTypesEnum } from '../../enums/supportedEntityTypesEnum';
import { EValidator } from '../../enums/validators';
import {
    apiFetchPost,
    apiFetchPut,
    apiFetchPutRestAddFile,
    apiFetchRestAddFile,
    apiFetchRestCheckInFile,
    apiRestFetchGet,
    apiDummyResponse,
} from '../../restApi/fetch.utils';
import { DCEntityTypeEnum } from '../../types';
import { IMapMetadataFolder, IMapMetdata } from '../../utils/main.utils';

// getting the matched operation
export const transformOperation = {
    [CapabiltyEnum.DOCUMENT_METADATA_EMAIL]: [CapabiltyEnum.DOCUMENT_METADATA],
};

export const actionPerformerMethods = {
    [CapabiltyEnum.CHECKOUT]: apiFetchPost,
    [CapabiltyEnum.ADD_DOCUMENT]: apiFetchRestAddFile,
    [CapabiltyEnum.ADD_EMAIL]: apiFetchRestAddFile,
    [CapabiltyEnum.ADD_FOLDER]: apiFetchPost,
    [CapabiltyEnum.CANCEL_CHECKOUT]: apiFetchPost,
    [CapabiltyEnum.CHECKIN]: apiFetchRestCheckInFile,
    [CapabiltyEnum.DELETE_DOCUMENT]: apiFetchPost,
    [CapabiltyEnum.DELETE_EMAIL]: apiFetchPost,
    [CapabiltyEnum.DELETE_EMAIL_FOLDER]: apiFetchPost,
    [CapabiltyEnum.DELETE_FOLDER]: apiFetchPost,
    [CapabiltyEnum.DOCUMENT_METADATA]: apiRestFetchGet,
    [CapabiltyEnum.DOCUMENT_METADATA_FILENAME]: apiRestFetchGet,
    [CapabiltyEnum.DOWNLOAD_DOCUMENT]: apiRestFetchGet,
    [CapabiltyEnum.DOWNLOAD_VERSION_DOCUMENT]: apiRestFetchGet,
    [CapabiltyEnum.GET_NOTIFICATIONS_FILES]: apiRestFetchGet,
    [CapabiltyEnum.RENAME_DOCUMENT]: apiFetchPutRestAddFile,
    [CapabiltyEnum.RENAME_FOLDER]: apiFetchPut,
    [CapabiltyEnum.VALIDATE_DOCUMENT]: apiDummyResponse,
    [CapabiltyEnum.VALIDATE_CHECKIN_DOCUMENT]: apiDummyResponse,
};

export interface ICommandNameUrlPattern {
    commandName: string;
    urlPattern: EUrlPattern;
}

interface IUrlPatternMethodAndCommandName {
    [key: string]: ICommandNameUrlPattern;
}

export enum EUrlPattern {
    COMMAND = 'Command',
    OPERATION = 'Operation',
    DOWNLOAD = 'Download',
    RENAME = 'Rename',
    ADDEMAIL = 'AddEmail',
}

export const urlPatternMethodAndCommandName: IUrlPatternMethodAndCommandName = {
    [CapabiltyEnum.CHECKOUT]: { commandName: 'Lock Document', urlPattern: EUrlPattern.COMMAND },
    [CapabiltyEnum.ADD_DOCUMENT]: { commandName: '', urlPattern: EUrlPattern.OPERATION },
    [CapabiltyEnum.ADD_EMAIL]: { commandName: '', urlPattern: EUrlPattern.ADDEMAIL },
    [CapabiltyEnum.ADD_FOLDER]: { commandName: '', urlPattern: EUrlPattern.OPERATION },
    [CapabiltyEnum.CANCEL_CHECKOUT]: { commandName: 'Unlock Document', urlPattern: EUrlPattern.COMMAND },
    [CapabiltyEnum.CHECKIN]: { commandName: '', urlPattern: EUrlPattern.OPERATION },
    [CapabiltyEnum.DELETE_DOCUMENT]: { commandName: 'Delete Document', urlPattern: EUrlPattern.COMMAND },
    [CapabiltyEnum.DELETE_EMAIL]: { commandName: 'Delete Document', urlPattern: EUrlPattern.COMMAND },
    [CapabiltyEnum.DELETE_EMAIL_FOLDER]: { commandName: 'Delete Document', urlPattern: EUrlPattern.COMMAND },
    [CapabiltyEnum.DELETE_FOLDER]: { commandName: 'Delete Document', urlPattern: EUrlPattern.COMMAND },
    [CapabiltyEnum.DOCUMENT_METADATA]: { commandName: '', urlPattern: EUrlPattern.OPERATION },

    [CapabiltyEnum.DOCUMENT_METADATA_FILENAME]: { commandName: '', urlPattern: EUrlPattern.OPERATION },

    [CapabiltyEnum.DOWNLOAD_DOCUMENT]: { commandName: '', urlPattern: EUrlPattern.OPERATION },
    [CapabiltyEnum.DOWNLOAD_VERSION_DOCUMENT]: { commandName: '', urlPattern: EUrlPattern.OPERATION },
    [CapabiltyEnum.GET_NOTIFICATIONS_FILES]: { commandName: '', urlPattern: EUrlPattern.DOWNLOAD },
    [CapabiltyEnum.RENAME_DOCUMENT]: { commandName: '', urlPattern: EUrlPattern.RENAME },
    [CapabiltyEnum.RENAME_FOLDER]: { commandName: '', urlPattern: EUrlPattern.RENAME },
    [CapabiltyEnum.VALIDATE_DOCUMENT]: { commandName: '', urlPattern: EUrlPattern.OPERATION },
    [CapabiltyEnum.VALIDATE_CHECKIN_DOCUMENT]: { commandName: '', urlPattern: EUrlPattern.OPERATION },
};

export const inputValidators = {
    [CapabiltyEnum.CHECKOUT]: [EValidator.EntityContextValidator, EValidator.DocValidator],
    [CapabiltyEnum.ADD_DOCUMENT]: [EValidator.EntityContextValidator, EValidator.DocFileSizeValidator],
    [CapabiltyEnum.ADD_EMAIL]: [EValidator.EntityContextValidator, EValidator.DocFileSizeValidator],
    [CapabiltyEnum.ADD_FOLDER]: [EValidator.EntityContextValidator],
    [CapabiltyEnum.CANCEL_CHECKOUT]: [EValidator.EntityContextValidator, EValidator.DocValidator],
    [CapabiltyEnum.CHECKIN]: [EValidator.EntityContextValidator, EValidator.DocFileSizeValidator],
    [CapabiltyEnum.DELETE_DOCUMENT]: [EValidator.EntityContextValidator, EValidator.DocValidator],
    [CapabiltyEnum.DELETE_EMAIL]: [EValidator.EntityContextValidator, EValidator.DocValidator],
    [CapabiltyEnum.DELETE_EMAIL_FOLDER]: [EValidator.EntityContextValidator, EValidator.DocValidator],
    [CapabiltyEnum.DELETE_FOLDER]: [EValidator.EntityContextValidator, EValidator.DocValidator],
    [CapabiltyEnum.DOCUMENT_METADATA]: [EValidator.EntityContextValidator, EValidator.DocValidator],

    [CapabiltyEnum.DOCUMENT_METADATA_FILENAME]: [
        EValidator.EntityContextValidator,
        EValidator.DocMetaFileNameValidator,
    ],
    [CapabiltyEnum.DOWNLOAD_DOCUMENT]: [EValidator.EntityContextValidator, EValidator.DocValidator],
    [CapabiltyEnum.DOWNLOAD_VERSION_DOCUMENT]: [EValidator.EntityContextValidator],
    [CapabiltyEnum.GET_NOTIFICATIONS_FILES]: [EValidator.EntityContextValidator],
    [CapabiltyEnum.RENAME_DOCUMENT]: [EValidator.EntityContextValidator, EValidator.DocValidator],
    [CapabiltyEnum.RENAME_FOLDER]: [EValidator.EntityContextValidator, EValidator.DocValidator],
    [CapabiltyEnum.VALIDATE_DOCUMENT]: [],
    [CapabiltyEnum.VALIDATE_CHECKIN_DOCUMENT]: [],
};

export const urlMap = {
    Matter: 'MatterMatterManagementDocument',
    Organization: 'OrganizationMatterManagementDocument',
    Person: 'PersonMatterManagementDocument',
    InvoiceHeader: 'InvoiceMatterManagementDocument',
    EmailDocument: 'EmailDocument',
};

export const mapNames = {
    [DCEntityTypeEnum.Organization]: 'organization',
    [DCEntityTypeEnum.Person]: 'person',
    [DCEntityTypeEnum.Matter]: 'matter',
    [DCEntityTypeEnum.InvoiceHeader]: 'invoice',
    [DCEntityTypeEnum.Matter]: 'matter',
    [DCEntityTypeEnum.EmailDocument]: 'emailDocumentMatter',
};

export const mapHref = {
    [DCEntityTypeEnum.Organization]: 'Organization',
    [DCEntityTypeEnum.Person]: 'Person',
    [DCEntityTypeEnum.Matter]: 'Matter',
    [DCEntityTypeEnum.InvoiceHeader]: 'InvoiceHeader',
    [DCEntityTypeEnum.Matter]: 'Matter',
    [DCEntityTypeEnum.EmailDocument]: 'Matter',
};

export const supportedEntityTypesArray: Array<string> = [
    'Document',
    'EmailDocument',
    'InvoiceMatterManagementDocument',
    'MatterMatterManagementDocument',
    'OrganizationMatterManagementDocument',
    'PersonMatterManagementDocument',
    'DocumentVersion',
    'Matter',
    'InvoiceHeader',
    'Organization',
];
export const supportedEntityTypesMap = {
    Document: DocSupportedBaseEntityTypesEnum.DOCUMENT,
    EmailDocument: DocSupportedBaseEntityTypesEnum.EMAIL,
    InvoiceMatterManagementDocument: DocSupportedBaseEntityTypesEnum.DOCUMENT,
    MatterMatterManagementDocument: DocSupportedBaseEntityTypesEnum.DOCUMENT,
    OrganizationMatterManagementDocument: DocSupportedBaseEntityTypesEnum.DOCUMENT,
    PersonMatterManagementDocument: DocSupportedBaseEntityTypesEnum.DOCUMENT,
    DocumentVersion: DocSupportedBaseEntityTypesEnum.DOCUMENT,
    Matter: DocSupportedBaseEntityTypesEnum.MATTER,
    InvoiceHeader: DocSupportedBaseEntityTypesEnum.INVOICE,
    Organization: DocSupportedBaseEntityTypesEnum.ORGANIZATION,
};

export const matchMetadataFolder: IMapMetadataFolder = {
    id: 'id',
};

export const matchMetadata: IMapMetdata = {
    id: 'id',
    description: '',
    accessToken: '',
    networkId: '',
    name: 'name',
    createdBy: 'createdBy',
    creationDate: 'createdAt',
    lastModifiedBy: 'lastModifiedBy',
    lastModificationDate: null,
    documentFileName: 'documentFileName',
    webDavPath: 'webDavPath',
    parentLink: 'parentLink',
    documentSize: 'documentSize',
    changeToken: null,
    type: null,
    baseTypeId: null,
    isFolder: 'isFolder',
    parentId: null,
    path: 'folderPath',
    checkInComment: 'comments',
    contentStreamId: null,
    contentStreamFileName: null,
    contentStreamLength: null,
    contentStreamMimeType: null,
    isImmutable: null,
    versionLabel: null,
    versionSeriesId: null,
    isVersionSeriesCheckedOut: null,
    versionSeriesCheckedOutBy: 'lockedBy',
    versionSeriesCheckedOutId: null,
    isLatestMajorVersion: null,
    isLatestVersion: null,
    isMajorVersion: null,
    isPrivateWorkingCopy: null,
    isVersionable: 'versionable',
    isVersionSeriesPrivateWorkingCopy: null,
    contentFile: null,
    relatedEntityType: 'associatedEntityName',
    relatedEntityName: 'davEntity',
    relatedEntityId: 'associatedEntityInstanceId',
    isArchived: 'archivableBehaviorIsArchived',
    archivedDate: 'archivableBehaviorArchivedDate',
    pabuId: null,
    picId: null,
    isEmailMessage: null,
    isEmailRoot: null,
    senderEmailAddress: null,
    emailSubject: null,
    senderName: null,
    emailRecipients: null,
    isAttachmentPresent: null,
    emailBody: null,
    receivedTime: null,
    sentTime: null,
    uniqueId: null,
    docStorId: null,
    contentSearch: null,
    isRecursiveSearch: null,
    immediateParentId: null,
    fileKey: null,
    downloadUrl: 'downloadUrl',
    latestVersion: 'latestVersion',
};
