import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import React from 'react';
import { useReduxSelector } from '../../hooks/useReduxSelector';
import UUIButton from '../common/uuiButton';
import { useListScreenDispatch, useListScreenState } from './context/listScreenContext';
import css from './editsAppliedWidget.module.scss';
import { generatePagePostObject, isEditsApplied } from './listScreenHelpers';
import { SaveActionDialog } from './saveActionDialog';
import { SaveAsActionDialog } from './saveAsActionDialog';
import { useRefreshList } from './context/listScreenHooks';

const EditsAppliedWidget: React.FC = () => {
    const listScreenState = useListScreenState();
    const listScreenDispatch = useListScreenDispatch();
    const appResources = useReduxSelector((state) => state.appResources);
    const refreshList = useRefreshList();
    const metadata = listScreenState.metadata!;
    const listData = listScreenState.listData!;

    const disableSavedViewTabs = useReduxSelector((state) => state.uuiConfiguration?.isSingleTabSavedViewMode);

    const isEditsAppliedBoolean = isEditsApplied(listScreenState.baselinePageData, metadata, listData.page);

    const undoActionHandler = () => {
        const baselinePageData = listScreenState.baselinePageData;
        if (baselinePageData) {
            const pageInfo = generatePagePostObject(listData.page, {
                'column-data': baselinePageData['column-data'],
                filters: baselinePageData.filters,
                sortInfo: baselinePageData.sortInfo,
                pageNumber: 1,
                savedView: listData.page.savedView,
            });
            refreshList({ postObject: pageInfo });
        }
    };

    if (!isEditsAppliedBoolean) {
        return null;
    }

    const isTabEditable =
        listData.page.savedView.isEditable ||
        listData.page.savedView.isSystemDefault ||
        listData.page.savedView.isUserDefault;

    const tooltipList = [
        {
            name: 'Save',
            message: appResources.editsAppliedTooltipSave,
        },
        {
            name: 'SaveAs',
            message: appResources.editsAppliedTooltipSaveAs,
        },
        {
            name: 'Undo',
            message: appResources.editsAppliedTooltipUndo,
        },
        { name: 'Caret', message: appResources.editsAppliedTooltipCaret },
    ];

    const toolTipVal = (
        <React.Fragment>
            {tooltipList.map((tooltip, i) => {
                if (tooltip.name === 'Save' && !isTabEditable) {
                    return;
                }

                return (
                    <Typography key={tooltip.name + i} noWrap={true} className={css.tooltipText}>
                        {tooltip.message}
                    </Typography>
                );
            })}
        </React.Fragment>
    );

    return (
        <>
            <div className={css.editsAppliedRoot}>
                <Grid container spacing={1}>
                    <Grid item container alignItems="center">
                        <Grid item>
                            <IconButton
                                size="small"
                                className={css.chevronIcon}
                                data-testid="editsAppliedBtn"
                                onClick={() => listScreenDispatch({ type: 'OpenListScreenEditDialog' })}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </Grid>
                        <Grid item>{appResources.listscreenTabCustomized}</Grid>
                        <Grid item className={css.infoIconWrapper}>
                            <Tooltip
                                title={toolTipVal}
                                classes={{ tooltip: css.tooltip }}
                                arrow
                                placement="top-start"
                                data-testid="editApplied-tooltip">
                                <InfoOutlinedIcon className={css.infoIcon} data-testid="editApplied-info-icon" />
                            </Tooltip>
                        </Grid>

                        <Grid item container spacing={2} className={css.buttonWrapper}>
                            {isTabEditable && (
                                <Grid item>
                                    <UUIButton
                                        data-testid="listscreen-save-button"
                                        variant="contained"
                                        classes={{ contained: css.editsAppliedButton }}
                                        onClick={() => listScreenDispatch({ type: 'OpenEditsAppliedSaveDialog' })}>
                                        {appResources.buttonSave}
                                    </UUIButton>
                                </Grid>
                            )}
                            {!disableSavedViewTabs && (
                                <Grid item>
                                    <UUIButton
                                        data-testid="listscreen-saveas-button"
                                        variant="contained"
                                        classes={{ contained: css.editsAppliedButton }}
                                        onClick={() => listScreenDispatch({ type: 'OpenEditsAppliedSaveAsDialog' })}>
                                        {appResources.buttonSaveAs}
                                    </UUIButton>
                                </Grid>
                            )}
                            <Grid item>
                                <UUIButton
                                    data-testid="listscreen-undo-button"
                                    variant="contained"
                                    classes={{ contained: css.editsAppliedButton }}
                                    onClick={() => undoActionHandler()}>
                                    {appResources.buttonUndo}
                                </UUIButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <SaveActionDialog />
            <SaveAsActionDialog key={listScreenState.editsAppliedSaveAsDialogKey} />
        </>
    );
};

export default EditsAppliedWidget;
