import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/WarningRounded';
import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import queryString from 'query-string'
import useIsMounted from '../../hooks/useIsMounted';
import { useReduxDispatch } from '../../hooks/useReduxDispatch';
import { useReduxSelector } from '../../hooks/useReduxSelector';
import { useUUIHistory } from '../../hooks/useUUIHistory';
import { useUUILocation } from '../../hooks/useUUILocation';
import { ScreenMode, ScreenRenderingStyle } from '../common/types';
import { IContextLayerInfo, IParentItemInfo } from '../listScreen/types';
import { dispatchInitializeItemScreen } from './context/itemScreenAsyncActions';
import { ItemScreenProvider, useItemScreenDispatch, useItemScreenState } from './context/itemScreenContext';
import css from './itemScreen.module.scss';
import ItemScreenHeader from './itemScreenHeader';
import { ItemScreenPopupWrapper } from './itemScreenPopupWrapper';
import ItemScreenView from './itemScreenView';
import ItemScreenTabs from './tabs/itemScreenTabs';
import { getItemScreenModel } from './tabs/tabCommon';
import { IItemScreenInitialValues } from './types';

export interface IItemScreenProps {
    screenId: number;
    entityInstanceId?: number;
    // selectedRowId is for when we redirect to an item page from a list row operation.
    selectedRowId?: number;
    filterDefinitionId?: number;
    mode: ScreenMode;
    parentItemInfo?: IParentItemInfo;
    // contextLayerInfo should only used by item screens that are published from context layer
    contextLayerInfo?: IContextLayerInfo;
    renderingStyle?: ScreenRenderingStyle;
    popupTitle?: string;
    onClosePopupInline?: () => void;
    initialValues?: IItemScreenInitialValues;
    setItemScreenKey?: React.Dispatch<React.SetStateAction<number>>;
    operationContext?: string;
    entityTypeId?: string;
}

const ItemScreen: React.FC<IItemScreenProps> = (props) => {
    // in order to refresh the item screen, we use a key here
    // so that when the bus says we need to refresh or the mode
    // of the screen changes, we completely
    // throw away all item screen state and restart from scratch
    const [itemScreenKey, setItemScreenKey] = useState(0);
    const [, setCurrentMode] = useState(props.mode);

    // if the mode changes, reinitialize the item screen.
    useEffect(() => {
        setCurrentMode((currentMode) => {
            if (props.mode && currentMode !== props.mode) {
                setItemScreenKey((key) => key + 1);
                return props.mode;
            }
            return currentMode;
        });
    }, [props.mode]);

    return <ItemScreenWrapper key={itemScreenKey} {...props} setItemScreenKey={setItemScreenKey} />;
};

const ItemScreenWrapper: React.FC<IItemScreenProps> = (props) => (
    <ItemScreenProvider>
        <ItemScreenComponent {...props} />
    </ItemScreenProvider>
);

const ItemScreenComponent: React.FC<IItemScreenProps> = (props) => {
    useInitializeItemScreen(props);
    const itemScreenState = useItemScreenState();
    useEnableMessageBusToRefreshItem(
        itemScreenState.itemScreenJson?.metadata?.entityId,
        props.entityInstanceId,
        props.setItemScreenKey,
    );
    useItemScreenHistoryState();

    const isItemScreenLoaded = itemScreenState.itemScreenJson !== undefined;
    if (!isItemScreenLoaded) {
        return null;
    }

    const { mode, renderingStyle = 'normal', popupTitle, onClosePopupInline } = props;

    const itemScreenJson = itemScreenState.itemScreenJson!;

    const itemScreenModel = getItemScreenModel(itemScreenJson.metadata.fields);

    const screenHasTabs = Object.keys(itemScreenModel.tabs).length > 0;

    const itemScreen = (
        <>
            <ItemScreenHeader />
            <div
                className={
                    renderingStyle === 'normal'
                        ? itemScreenJson.metadata.pageSubTitle || itemScreenJson.metadata.pageTag
                            ? css.fieldsContainerNormal
                            : css.fieldsContainerNoSecondary
                        : css.fieldsContainer
                }
                data-scrollid="itemscreen-view"
                data-testid="itemscreen-view"
                id="itemscreenView">
                <div className={renderingStyle === 'normal' ? css.itemScreenSummaryWrapper : undefined}>
                    <div className={renderingStyle === 'normal' ? css.itemScreenSummary : undefined}>
                        <FormValidations type="errors" />
                        <FormValidations type="warnings" />
                        <ItemScreenView
                            enableShowMore={screenHasTabs}
                            isTopSummaryView={true}
                            sections={itemScreenModel.sections}
                        />
                    </div>
                </div>
                <ItemScreenTabs mode={mode} tabs={itemScreenModel.tabs} />
            </div>
        </>
    );

    return renderingStyle === 'normal' ? (
        itemScreen
    ) : (
        <ItemScreenPopupWrapper popupTitle={popupTitle} onClose={onClosePopupInline}>
            {itemScreen}
        </ItemScreenPopupWrapper>
    );
};

interface IFormValidations {
    type: 'errors' | 'warnings';
}

const FormValidations: React.FC<IFormValidations> = ({ type }) => {
    const { errors } = useFormContext();
    const appResources = useReduxSelector((state) => state.appResources);

    const formErrorsOrWarnings: string[] = [].concat(
        ...Object.keys(errors)
            .filter((key) => key === '' && errors[key].types[type].length > 0)
            .map((key) => errors[key].types[type]),
    );

    const fieldErrorsOrWarnings: string[] = [].concat(
        ...Object.keys(errors)
            .filter((key) => key !== '' && errors[key].types[type].length > 0)
            .map((key) => errors[key].types[type]),
    );

    if (formErrorsOrWarnings.length === 0 && fieldErrorsOrWarnings.length === 0) {
        return null;
    }
    return (
        <div className={css.errorsBoxWrapper}>
            <div className={type === 'errors' ? css.errorsBox : css.warningsBox} data-testid={'form-level-' + type}>
                <div className={css.errorsIndicatorIcon}>
                    {type === 'errors' ? (
                        <ErrorIcon className={css.errorsIndicatorSvgIcon} />
                    ) : (
                        <WarningIcon className={css.warningsIndicatorSvgIcon} />
                    )}
                </div>
                <div className={css.errorsTitle}>
                    {type === 'errors' ? appResources.errorNotification : appResources.warningNotification}
                </div>
                {formErrorsOrWarnings.map((message, i) => (
                    <div key={i} className={css.errorMessage}>
                        {message}
                    </div>
                ))}
                {formErrorsOrWarnings.length === 0 && fieldErrorsOrWarnings.length > 0 && (
                    <div>
                        {type === 'errors'
                            ? appResources.validationErrorMessage
                            : appResources.validationWarningMessage}
                    </div>
                )}
            </div>
        </div>
    );
};

const useInitializeItemScreen = ({
    screenId,
    entityInstanceId,
    selectedRowId,
    filterDefinitionId,
    parentItemInfo,
    contextLayerInfo,
    mode,
    renderingStyle = 'normal',
    initialValues,
    operationContext,
    entityTypeId,
}: IItemScreenProps) => {
    const applicationUrls = useReduxSelector((state) => state.applicationUrls);
    const apiPath = Props['apiContextRoot'] + Props['apiContextPath'];
    const location = useUUILocation();
    const qs = queryString.parse(location.search);
    const sourceItemViewId = qs.id || qs.screenId;
    let screenURL =
        apiPath +
        applicationUrls.itemScreenPath
            .replace('{mode}', mode)
            .replace('{screenId}', screenId.toString())
            .replace('{entityInstanceId}', entityInstanceId?.toString() || '')
            .replace('{selectedRowId}', selectedRowId?.toString() || '')
            .replace('{filterDefinitionId}', filterDefinitionId?.toString() || '')
            .replace('{parentEntityId}', parentItemInfo?.parentEntityId?.toString() || '')
            .replace('{parentFieldName}', parentItemInfo?.parentFieldName?.toString() || '')
            .replace('{parentInstanceId}', parentItemInfo?.parentInstanceId?.toString() || '')
            .replace('{parentEntityName}', parentItemInfo?.parentEntityName?.toString() || '')
            .replace('{associatedEntityTypeId}', contextLayerInfo?.associatedEntityTypeId || '')
            .replace('{associatedEntityId}', contextLayerInfo?.associatedEntityId || '')
            .replace('{associatedEntityType}', contextLayerInfo?.associatedEntityType || '')
            .replace('{associatedEntityName}', contextLayerInfo?.associatedEntityName || '')
            .replace('{sourceItemViewId}', sourceItemViewId?.toString() || '');
    if (operationContext) {
        screenURL += '&operationContext=' + operationContext;
    }
    const reduxDispatch = useReduxDispatch();
    const itemScreenState = useItemScreenState();
    const itemScreenDispatch = useItemScreenDispatch();
    const isMounted = useIsMounted();
    const history = useUUIHistory();
    const v3HomeUrl = useReduxSelector((state) => state.appResources.v3HomeUrl);
    const isItemScreenLoaded = itemScreenState.itemScreenJson !== undefined;

    // this effect is for the initial load of the item screen. None of the dependencies should ever change for the life of the item screen.
    useEffect(() => {
        if (!isItemScreenLoaded) {
            dispatchInitializeItemScreen(
                screenURL,
                mode,
                itemScreenDispatch,
                reduxDispatch,
                isMounted,
                parentItemInfo,
                contextLayerInfo,
                renderingStyle,
                initialValues,
                location.state?.itemScreen,
                history,
                v3HomeUrl,
                entityTypeId,
                entityInstanceId?.toString(),
            );
        }
    }, [
        isMounted,
        isItemScreenLoaded,
        mode,
        itemScreenDispatch,
        reduxDispatch,
        screenURL,
        parentItemInfo,
        contextLayerInfo,
        renderingStyle,
        initialValues,
        location.state?.itemScreen,
        history,
        v3HomeUrl,
        entityTypeId,
        entityInstanceId?.toString(),
    ]);
};

const useEnableMessageBusToRefreshItem = (
    entityTypeId: number | undefined,
    entityInstanceId: number | undefined,
    setItemScreenKey?: React.Dispatch<React.SetStateAction<number>>,
) => {
    const reduxDispatch = useReduxDispatch();
    const refreshUUIForEntity = useReduxSelector((state) => state.ui.messageBus?.refreshUUIForEntity);
    const suppressNextRefresh = !!useReduxSelector((state) => state.ui.messageBus?.suppressNextRefresh);
    const refreshUUIForEntityIsProcessed = useReduxSelector(
        (state) => state.ui.messageBus?.refreshUUIForEntityIsProcessed,
    );
    const history = useUUIHistory();
    const itemScreenDialogs = useReduxSelector((state) => state.ui.itemScreenDialogs);
    const currentDialog = itemScreenDialogs[itemScreenDialogs.length - 1];

    // even if the item screen ids don't match, by running this effect, we have effectively (pun intended)
    // processed this event.
    const shouldMarkProcessed =
        !!refreshUUIForEntity && !refreshUUIForEntityIsProcessed && !!entityTypeId && !!entityInstanceId;

    // if the ids match, refresh the item
    const shouldRefresh =
        shouldMarkProcessed &&
        entityTypeId?.toString() === refreshUUIForEntity.entityTypeId &&
        entityInstanceId?.toString() === refreshUUIForEntity.entityInstanceId;

    useEffect(() => {
        // make sure we clear this so we don't end up having a stale refreshUUIForEntity in the redux store
        if (shouldMarkProcessed) {
            reduxDispatch({ type: 'MarkRefreshUUIForEntityAsProcessed' });
        }
        if (suppressNextRefresh) {
            return;
        }

        if (shouldRefresh) {
            if (refreshUUIForEntity?.isEntityDeleted) {
                if (currentDialog) {
                    reduxDispatch({ type: 'RemoveItemScreenDialog' });
                }
                if (history.length > 1) {
                    history.goBack();
                }
            } else {
                setItemScreenKey?.((itemScreenKey) => itemScreenKey + 1);
            }
        }
    }, [
        currentDialog,
        history,
        reduxDispatch,
        refreshUUIForEntity?.isEntityDeleted,
        setItemScreenKey,
        shouldMarkProcessed,
        shouldRefresh,
        suppressNextRefresh,
    ]);
};

/**
 * Watches for changes in viewstate, and updates the history state object
 * with those changes
 */
const useItemScreenHistoryState = () => {
    const itemScreenState = useItemScreenState();
    const location = useUUILocation();
    const history = useUUIHistory();
    // anytime our viewstate changes, update the history state object
    useEffect(() => {
        if (itemScreenState.viewState && !isEqual(location.state?.itemScreen?.viewState, itemScreenState.viewState)) {
            location.state = { ...location.state, itemScreen: { viewState: itemScreenState.viewState } };
            history.replace(location);
        }
    }, [itemScreenState.viewState, location, history]);
};

export default ItemScreen;
