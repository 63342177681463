import { getLogger } from '@wk/elm-uui-common';
import {
    CHMessageData,
    CHMessagingScope,
    notifyMessagingService,
    subscribeMessagingService,
} from '@wk/elm-uui-context-handler';
import { Subscription } from 'rxjs';
import { logOutSecondary } from './oidc';

const getLog = () => getLogger('t360.messageService');

export interface MessageData extends CHMessageData {
    type: MessageType;
}

export enum MessageType {
    Logout = 'T360_Logout',
    NetworkSwitch = 'T360_NetworkSwitch',
    ProceedWithNetworkSwitch = 'ProceedWithNetworkSwitch',
    CancelNetworkSwitch = 'CancelNetworkSwitch',
    NetworkState = 'NetworkState',
}

export interface MessageAction<TType extends MessageType, TMessage = unknown> {
    type: TType;
    scope?: CHMessagingScope;
    message?: TMessage;
}

export interface LogoutMessage {
    idTokenHint: string;
}
export interface NetworkStateMessage {
    networkId: number;
}

export type MessageActions =
    | MessageAction<MessageType.Logout, LogoutMessage>
    | MessageAction<MessageType.NetworkSwitch, string>
    | MessageAction<MessageType.NetworkState, NetworkStateMessage>;

type MessageHandler = (data: MessageData) => void;

export class MessageService {
    private static instance: MessageService;

    private constructor() {
        getLog().debug('Message Service has been initialized');
    }

    static getInstance(): MessageService {
        if (!MessageService.instance) {
            MessageService.instance = new MessageService();
        }
        return MessageService.instance;
    }

    notify(action: MessageActions) {
        const messageData = {
            ...action,
            message: JSON.stringify(action.message || {}),
            scope: action.scope || CHMessagingScope.OtherInstances,
        };
        getLog().debug(`Notifying: ${JSON.stringify(messageData)}`);

        setTimeout(() => {
            try {
                notifyMessagingService(messageData);
            } catch (error) {
                getLog().error('Error: ', error);
            }
        }, 100);
    }

    subscribe(observer: MessageHandler = this.defaultMessageHandler): Subscription | undefined {
        getLog().debug('Creating subscription to Message service.');

        try {
            return subscribeMessagingService(observer as (data: CHMessageData) => void);
        } catch (e) {
            getLog().error('Error: ', e);
        }

        return;
    }

    private defaultMessageHandler(data: MessageData): void {
        getLog().debug(`Receiving message: ${JSON.stringify(data)}`);

        switch (data.type) {
            case MessageType.Logout: {
                const logoutMessage: LogoutMessage = JSON.parse(data.message);
                logOutSecondary(logoutMessage?.idTokenHint);
                break;
            }
        }
    }
}
