import { IDocActionPerformer } from '../../interfaces/actionPerformers/type';
import { IActionableEntity } from '../../interfaces/actionEntity/type';
import { factory } from '../../ConfigLog4j';
import { IDMToken } from '../../interfaces/dmTokenHandler/type';
import { injectable } from 'inversify';
import { IDocOperationInvocation } from '../../interfaces/urlPattern/types';
import { DCResponse } from '../../types';
import { JRAdapterAddDocumentActionableEntity } from '../actionableEntity/addDocumentActionableEntity';

const log = factory.getLogger('JRAdapterActionPerformer');

@injectable()
export class JRAdapterValidateDocumentActionPerformer implements IDocActionPerformer {
    tokenHandler: IDMToken;

    fetchRequest: (arg0: string, arg1: IActionableEntity, arg2: string) => Promise<DCResponse>;

    performAction(
        obj: JRAdapterAddDocumentActionableEntity,
        _urlObj: Promise<string>,
        _docOper: IDocOperationInvocation,
    ): Promise<DCResponse> {
        log.debug('Calling performAction function');

        let hasValidationErrors = false;
        const statusInfo = { errors: {} };

        if (!obj.attributes.documentFile.name) {
            hasValidationErrors = true;
            statusInfo.errors['documentFileName'] = 'Value is required';
        }

        if (!obj.attributes.name) {
            hasValidationErrors = true;
            statusInfo.errors['name'] = 'Value is required';
        }

        const init = {
            status: hasValidationErrors ? 400 : 200,
            statusText: hasValidationErrors ? 'Bad Request' : 'Success',
        };
        const myBlob = new Blob();
        const response2: DCResponse = new Response(myBlob, init) as DCResponse;

        response2.statusInfo = statusInfo;

        return Promise.resolve(response2);
    }
}
