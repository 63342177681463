import { Tabs, TabsProps } from '@material-ui/core';
import { merge, omit } from 'lodash';
import React, { ChangeEvent } from 'react';
import css from './uuiTabs.module.scss';

interface UUITabsProps extends Omit<TabsProps, 'onChange'> {
    // workaround for https://github.com/mui-org/material-ui/issues/17454
    onChange: (event: ChangeEvent<unknown>, value: any) => void;
    isSubTab?: boolean;
}

const UUITabs: React.FC<UUITabsProps> = (props) => {
    let tabsClasses;
    let indicatorProps;

    if (props.isSubTab) {
        tabsClasses = merge({}, props.classes, { root: css.subTabsRoot, scrollButtons: '' });
        indicatorProps = merge({}, props.TabIndicatorProps, { className: css.subTabIndicator });
    } else {
        tabsClasses = merge({}, props.classes, { root: css.tabsRoot, scrollButtons: css.tabsScrollButtons });
        indicatorProps = merge({}, props.TabIndicatorProps, { className: css.tabIndicator });
    }

    const newProps = omit(props, 'isSubTab');

    return (
        <Tabs
            {...newProps}
            classes={tabsClasses}
            aria-label="Saved Views"
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            TabIndicatorProps={indicatorProps}>
            {props.children}
        </Tabs>
    );
};

export default UUITabs;
