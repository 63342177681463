import { injectable } from 'inversify';
import { FILE_MISSING } from '../constants';
import { DCValidationError } from '../customError/docComponentError';
import { IValidationInfo } from '../dmInfoHandler/types';
import { IInputValidator } from '../interfaces/inputValidators/type';
import { IAddDocument_JR } from '../jRAdapter/types';
import { ValidationUtils } from '../libraries/validationUtils';
import { IDocServiceRequest } from '../types';
import { isNodeEnvironment } from '../utils/shared.utils';

interface IDocServiceContainingFile extends IDocServiceRequest {
    file: Buffer | Blob | File;
}

@injectable()
export class DocFileSizeValidator implements IInputValidator {
    validationInfo!: IValidationInfo;
    performValidation = (nodeObj: IDocServiceContainingFile): boolean => {
        const nodeObj1 = nodeObj as IAddDocument_JR;

        const validator = new ValidationUtils();
        // const fileName = nodeObj1.name;
        const file = nodeObj1?.file;
        // const folderPath = nodeObj1?.folderPath + '/' + fileName;

        const extendedProps = nodeObj1?.extendedProps;

        let fileSize = 0;
        if (nodeObj1.file) {
            if (isNodeEnvironment() && file instanceof Buffer) {
                fileSize = file.length;
            } else {
                fileSize = (file as Blob).size;
            }
        } else {
            throw new DCValidationError(FILE_MISSING);
        }

        const defaultfileSize = validator.getFileSize(this.validationInfo, extendedProps);
        if (validator.checkDocumentFileSize(fileSize, defaultfileSize)) {
            return true;
        } else {
            const FILE_SIZE_EXCEEDED = validator.getValidationErrorMessage(nodeObj.extendedProps, defaultfileSize);
            throw new DCValidationError(FILE_SIZE_EXCEEDED);
        }
    };
}
