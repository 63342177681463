import { CHSupportedDocumentTypesEnum } from '@wk/elm-uui-context-handler';
import { IAssociatedEntityInfo, IBaseOperation, ScreenMode, ScreenRenderingStyle } from '../common/types';
import { IContextLayerInfo, IParentItemInfo } from '../listScreen/types';

export interface IItemScreenState {
    itemScreenJson?: IItemScreenJSON;
    mode?: ScreenMode;
    parentItemInfo?: IParentItemInfo;
    contextLayerInfo?: IContextLayerInfo;
    renderingStyle?: ScreenRenderingStyle;
    activeTab: number;
    visitedTabs: IItemScreenTabMetadata[];
    viewState?: ItemScreenViewState;
    enableQFIcon?: boolean;
    docTypeForQF?: CHSupportedDocumentTypesEnum;
}

export type ItemScreenViewState = Pick<IItemScreenState, 'activeTab' | 'visitedTabs'>;

export interface IItemScreenTabEmbeddedListMetadata {
    isDocumentOrEmailTab?: boolean;
    entityName?: string;
    folderId?: number;
    breadCrumbs?: string[];
    entityTypeId?: string;
}

export interface IItemScreenTabMetadata extends IItemScreenTabEmbeddedListMetadata {
    index: number;
    activeSubTab?: number;
    visitedSubTabs?: IItemScreenTabMetadata[];
}

export type ItemScreenAction =
    // INITIALIZE
    | {
          type: 'InitializeItemScreen';
          itemScreenJson: IItemScreenJSON;
          mode?: ScreenMode;
          parentItemInfo?: IParentItemInfo;
          contextLayerInfo?: IContextLayerInfo;
          renderingStyle: ScreenRenderingStyle;
          viewState?: ItemScreenViewState;
      }
    | { type: 'LoadSavedViewState'; viewState: ItemScreenViewState }
    | { type: 'SetActiveTab'; tabIndex: number }
    | { type: 'SetActiveSubTab'; tabIndex: number }
    | { type: 'SetTabEmbeddedListMetadata'; metadata: IItemScreenTabEmbeddedListMetadata }
    | { type: 'InitializeQF'; enableQFIcon: boolean; docTypeForQF?: CHSupportedDocumentTypesEnum };

export type ItemScreenDispatch = (action: ItemScreenAction) => void;

export type ControlType =
    | 'Multi Line Text'
    | 'Single Line Text'
    | 'Multi-Select List'
    | 'CheckBox'
    | 'Dropdown List'
    | 'Single-Autocomplete'
    | 'Money'
    | 'Currency'
    | 'Date/Time Picker'
    | 'Date Picker'
    | 'Multi-Autocomplete'
    | 'Rating'
    | 'Toggle'
    | 'File'
    | 'Literal'
    | 'FolderPicker'
    | 'AmountPicker';

export interface FieldValue {
    value: string;
    currency?: string;
}

export interface IItemScreenJSON {
    item: IItemScreenData;
    metadata: IItemScreenMetadata;
    // following keys are not used in UUI yet, but here to make Passport JSON mocks happy
    screen?: string;
    version?: number | string;
    url?: string;
}

export interface IItemScreenDataKnown {
    id: number | null;
    pageTitle: string;
    name?: IItemScreenName;
}

export interface IItemScreenName {
    inputValue?: string;
    displayValue?: string;
}

export interface IItemScreenDataUnknown {
    [key: string]: IItemScreenDataElement | number | string | null;
}

export type IItemScreenData = IItemScreenDataKnown & IItemScreenDataUnknown;

export interface IItemScreenDataElement {
    inputValue?: string;
    displayValue?: string;
    currency?: string;
    selectedItems?: IAutoCompleteItemsResponse[];
    // not used in UUI yet, but here to make Passport JSON mocks happy
    size?: number;
    contentType?: string;
}

export interface IItemScreenMetadata extends IAssociatedEntityInfo {
    screenId: number;
    screenDisplayName: string;
    entityId: number;
    pageTitle: string;
    objDisplayValue: string;
    pageSubTitle?: string;
    pageTag?: string;
    fields: IItemScreenField[];
    operations: IItemScreenOperation[];
    entityName: string;
    isDocumentEntity?: boolean;
    folderPath?: string[];
    parentEntityName?: string;
    parentEntityId?: number;
    parentEntityInstanceId?: number;
    enableQFEmails?: boolean;
    enableQFDocuments?: boolean;
    enableFileDropzone?: boolean;
    // following keys are not used in UUI yet, but here to make Passport JSON mocks happy
    deprecated?: boolean;
    screenName?: string;
    entityDisplayName?: string;
    version?: number;
}

export interface ISourceListScreen {
    screenId: number;
    selectedListScreenUrl: string;
    selectedListScreenMetadataUrl?: string;
    autocompleteItemsUrl?: string;
    uuiPrefetchMultiAutoCompleteItems?: boolean;
    // following keys are not used in UUI yet, but here to make Passport JSON mocks happy
    screenName?: string;
    url?: string;
}

export interface IItemScreenField {
    required: boolean;
    readonly: boolean;
    isNewLine: boolean;
    displayName: string;
    name: string;
    deprecated: boolean;
    controlType: ControlType;
    tabGroup: string | null;
    sectionGroup: string | null;
    tabStyle?: string;
    fieldStyle?: string;
    path: string;
    size: number;
    defaultValue: string | null;
    sourceListScreen?: ISourceListScreen;
    dropDownOptions?: IDropdownOption[];
    folderOptions?: IFolder;
    defaultId: number;
    attributeFieldName: string;
    // the following keys are not used in the UUI yet, but are here to make typescript happy for Passport JSON API mock files
    isLiveUpdate?: boolean;
    description?: string | null;
    type?: string;
    attributeIds?: number[];
}

export interface IDropdownOption {
    id: number;
    value: string;
}

export interface IFolder {
    name: string;
    id: string;
    parentId?: string;
    children?: Array<IFolder>;
}

export interface IItemScreenOperation extends IBaseOperation {
    isCloseModal: boolean;
}

export interface ControlTypeProps {
    field: IItemScreenField;
    fieldData: IItemScreenDataElement;
    mode?: ScreenMode;
    readOnly?: boolean;
    stickyTopPosition?: number;
    resetCurrentEmbeddedList?: boolean;
    tabIndex?: number;
    parentTabIndex?: number;
}

export interface IAutoCompleteItemsResponse {
    display?: string;
    id: number;
    value: string;
}

export interface IPerformOperationResponse {
    warnings?: IErrorMessages;
    errors?: IErrorMessages;
    successMessage?: string;
}

export type IErrorMessages = {
    [key: number]: string[];
};

export type IErrorMessagesWithPathKeys = {
    [key: string]: string[];
};

export interface IItemScreenInitialValues {
    [key: string]: IItemScreenInitialValue;
}

export interface IItemScreenInitialValue {
    inputValue: string;
    displayValue: string;
}

export class ParentEntityInfo {
    parentInstanceId: string = '';
    parentEntityTypeId: string = '';
}
