import { ITransformer } from './types';
import { ICHDocumentMetadata } from '../interfaces/operations';
import { IDocumentMetadata, IDocumentMetadataEmail } from '@wk/elm-uui-doc-component';
import 'reflect-metadata';
import { injectable } from 'inversify';

@injectable()
export class CtxDocumentMetadataTransformer implements ITransformer {
    data!: ICHDocumentMetadata;

    transform(): IDocumentMetadata & IDocumentMetadataEmail {
        const nodeObj = {} as IDocumentMetadata & IDocumentMetadataEmail;
        nodeObj.docId = this.data.documentId;
        nodeObj.entityContext = {
            name: this.data.associatedEntityName || '',
            id: this.data.associatedEntityId,
            type: this.data.associatedEntityType,
        };
        const extendedProps = <IDocumentMetadata['extendedProps']>{};
        if (this.data?.version) {
            extendedProps.version = this.data?.version;
        }
        nodeObj.extendedProps = extendedProps;
        nodeObj.extendedProps.loggedInUser = this.data?.loggedInUser;
        return nodeObj;
    }
}
