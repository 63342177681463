import { IOfficeDocumentService } from '../../services';
import { injectable } from 'inversify';
import { Word, FileOperations, WindowState, SaveOptions } from '@wk/office-companion-js';
import { factory } from '../../configLog4J';
import { DocumentDefaultExtensions } from '../../enum/enum';
import { v4 as uuid } from 'uuid';
import { getTemporaryFileName } from '../../utils/main.utils';

const log = factory.getLogger('WordService');

@injectable()
export class WordService implements IOfficeDocumentService {
    private hasValue = false;
    private documentPath = '';
    public async isNewDocument(): Promise<boolean> {
        try {
            await Word.run(async (context) => {
                const doc = context.currentDocument;
                await context.sync();
                context.load(doc, ['isNew']);
                await context.sync();
                this.hasValue = doc.isNew;
            });
        } catch (error) {
            log.error(error);
        }
        return this.hasValue;
    }

    public async isDocumentSaved(): Promise<boolean> {
        try {
            await Word.run(async (context) => {
                const doc = context.currentDocument;
                await context.sync();
                context.load(doc, ['saved']);
                await context.sync();
                this.hasValue = doc.saved;
            });
        } catch (error) {
            log.error(error);
        }
        return this.hasValue;
    }

    public async save(): Promise<void> {
        try {
            await Word.run(async (context) => {
                const doc = context.currentDocument;
                await context.sync();
                doc.save();
                await context.sync();
            });
        } catch (error) {
            log.error(error);
        }
    }

    public async saveAs(filePath: string): Promise<void> {
        try {
            await Word.run(async (context) => {
                const doc = context.currentDocument;
                await context.sync();
                doc.saveAs2(filePath);
                await context.sync();
            });
        } catch (error) {
            log.error(error);
        }
    }

    public async close(saveOptions: SaveOptions): Promise<void> {
        try {
            await Word.run(async (context) => {
                const doc = context.currentDocument;
                await context.sync();
                doc.close(saveOptions);
                await context.sync();
            });
        } catch (error) {
            log.error(error);
        }
    }

    public async getCurrentDocument(): Promise<string> {
        try {
            await Word.run(async (context) => {
                const { currentDocument } = context;
                await context.sync();

                context.load(currentDocument, ['name']);
                await context.sync();
                this.documentPath = currentDocument.name;
            });
        } catch (error) {
            this.documentPath = '';
            log.error(error);
        }
        return this.documentPath;
    }

    public async getCurrentDocumentFullName(): Promise<string> {
        try {
            await Word.run(async (context) => {
                const { currentDocument } = context;
                await context.sync();
                context.load(currentDocument, ['normalizedFullName']);
                await context.sync();
                this.documentPath = currentDocument.normalizedFullName;
            });
        } catch (error) {
            this.documentPath = '';
            log.error(error);
        }
        return this.documentPath;
    }

    public async getActiveDocumentSelection(): Promise<boolean> {
        try {
            await Word.run(async (context) => {
                const { currentDocument } = context;
                await context.sync();
                this.hasValue = currentDocument.isResolved && !currentDocument.isNull;
            });
        } catch (error) {
            log.error(error);
        }
        return this.hasValue;
    }

    public async isReadOnly(): Promise<boolean> {
        try {
            await Word.run(async (context) => {
                const { currentDocument } = context;
                await context.sync();
                context.load(currentDocument, ['readOnly']);
                await context.sync();
                this.hasValue = currentDocument.readOnly;
            });
        } catch (error) {
            log.error(error);
        }
        return this.hasValue;
    }

    public async isBusy(): Promise<boolean> {
        return false;
    }

    public async getDefaultExtension(): Promise<string> {
        return DocumentDefaultExtensions.Word;
    }

    public async saveAsInTempStorage(isUpload?: boolean): Promise<string> {
        const id = uuid();
        await Word.run(async (context) => {
            const { currentDocument } = context;
            await context.sync();
            context.load(currentDocument, ['name']);
            await context.sync();
            const path = await FileOperations.resolvePath({
                rootName: 'temporaryFileStorage',
                parts: [id],
                filename: await getTemporaryFileName(currentDocument.name, isUpload),
            });
            currentDocument.saveAs2(path.filePath);
            this.documentPath = path.filePath;
            await context.sync();
        });
        return this.documentPath;
    }

    public async activateWindow(uniqueId: string): Promise<void> {
        log.debug('invoked activateWindow');
        await Word.run(async (context) => {
            const { currentDocument, application } = context;
            await context.sync();
            application.visible = true;
            application.activate();
            currentDocument.activate();
            application.activeWindow.windowState = WindowState.WindowStateMaximize;
            await context.sync();
            await FileOperations.open(uniqueId);
        });
    }
}
