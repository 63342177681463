import {
    checkOut,
    ICheckOut,
    IDocumentMetadata,
    getAuthToken,
    IDownloadDocument,
    cancelCheckOut,
    documentMetadata,
    DCResponse,
} from '@wk/elm-uui-doc-component';
import { inject, injectable } from 'inversify';
import 'reflect-metadata';
import { CHMessagingService, MessageService } from './message.service';
import { IDocumentsService } from './documents.service';
import { IOfficeService } from './office.service';
import { IEventService } from './eventContextHandler.service';
import { BannerService } from './banner.service';
import * as officeCompanionJS from '@wk/office-companion-js';
import { getTransformedObject } from '../transform/helper';
import { Locale } from '../locale';
import { CapabiltyEnum, DocumentState, EventType, HttpStatusCode, ToastType } from '../enum/enum';
import { factory } from '../configLog4J';
import { DocumentEntity } from '../dto/documentEntity';
import {
    ICHCheckOut,
    ICHDocumentMetadata,
    ICHCheckoutDocMetaType,
    ICHCancelCheckoutDocMetaType,
    ICHDownloadDocMetaType,
} from '../interfaces/operations';
import { CtxtCheckOutTransformer } from '../transform/ctxtCheckOutTransformer';
import { CtxDocumentMetadataTransformer } from '../transform/ctxDocumentMetadataTransformer';
import { CtxDownloadDocumentTransformer } from '../transform/ctxDownloadDocumentTransformer';
import { Dialog } from '../configurations';
import { FileOperations, SaveOptions } from '@wk/office-companion-js';
import { handleCallback, throwUnExpectedError } from '../utils/main.utils';
import { DialogService } from './dialog.service';

const log = factory.getLogger('CheckoutDiscardService');

@injectable()
export class CheckoutDiscardService {
    private _messageService: MessageService;
    private _documentsService: IDocumentsService;
    private _officeService: IOfficeService;
    private _eventService: IEventService;
    private _chMessageService: CHMessagingService;
    private _bannerService: BannerService;
    private _dialogService: DialogService;
    private uniqueDocumentId: string;
    private isSuccess: boolean;
    constructor(
        @inject('DocumentsService') documentsService: IDocumentsService,
        @inject('MessageService') messageService: MessageService,
        @inject('OfficeService') officeService: IOfficeService,
        @inject('EventContextHandler') eventService: IEventService,
        @inject('CHMessagingService') chMessageService: CHMessagingService,
        @inject('BannerService') bannerService: BannerService,
        @inject('DialogService') dialogService: DialogService,
    ) {
        this._documentsService = documentsService;
        this._messageService = messageService;
        this._officeService = officeService;
        this._eventService = eventService;
        this._chMessageService = chMessageService;
        this.uniqueDocumentId = '';
        this._bannerService = bannerService;
        this._dialogService = dialogService;
        this.isSuccess = false;
    }

    documentMetadata = (contextObj: ICHDocumentMetadata): Promise<DCResponse> => {
        const transformedObject = getTransformedObject<
            ICHDocumentMetadata,
            IDocumentMetadata,
            CtxDocumentMetadataTransformer
        >(CapabiltyEnum.DOCUMENT_METADATA, contextObj);
        return documentMetadata(transformedObject);
    };

    setLock = async (contextObj: ICHCheckoutDocMetaType): Promise<boolean> => {
        try {
            if (!(await this._documentsService.wasStaleDocument(contextObj, DocumentState.DOC_EXIST))) {
                log.info(Locale.stale.deleted_document);
                return this.isSuccess;
            } else if (await this._documentsService.wasStaleDocument(contextObj, DocumentState.CHECKEDOUT)) {
                log.info(Locale.stale.checkedout_document);
                return this.isSuccess;
            }
            const transformedObject = getTransformedObject<ICHCheckOut, ICheckOut, CtxtCheckOutTransformer>(
                CapabiltyEnum.CHECKOUT,
                contextObj,
            );
            await checkOut(transformedObject)
                .then((response: Response) => {
                    if (response.ok) {
                        this.isSuccess = true;
                        // handled to close banner
                        this._bannerService.removeBanner(transformedObject.docId);
                    }
                    log.debug('setLock :' + response);
                })
                .catch((err: Error) => {
                    log.error('setLock :', err);
                    throwUnExpectedError(err);
                    this.showToast(ToastType.ERROR, Locale.failure.general_failure);
                });
        } catch (err) {
            log.error('setLock :' + JSON.stringify(err));
            throwUnExpectedError(err);
            this.showToast(ToastType.ERROR, (err as Error).message);
        }
        return this.isSuccess;
    };

    removeLock = async (contextObj: ICHCancelCheckoutDocMetaType): Promise<boolean> => {
        try {
            const transformedObject = getTransformedObject<ICHCheckOut, ICheckOut, CtxtCheckOutTransformer>(
                CapabiltyEnum.CHECKOUT,
                contextObj as ICHCheckOut,
            );
            if (!(await this._documentsService.wasStaleDocument(contextObj, DocumentState.DOC_EXIST))) {
                log.info(Locale.stale.deleted_document);
                return false;
            } else if (await this._documentsService.wasStaleDocument(contextObj, DocumentState.CHECKEDIN)) {
                log.info(Locale.stale.discarded_document);
                return false;
            }
            await cancelCheckOut(transformedObject)
                .then((response: DCResponse) => {
                    if (response.ok) {
                        this.isSuccess = true;
                    } else if (!response.ok) {
                        if (response.status != HttpStatusCode.FORBIDDEN) {
                            this.showToast(ToastType.ERROR, JSON.stringify(response.statusInfo.errors.default));
                        }
                        this.isSuccess = false;
                    }
                    log.debug('cancelCheckOut :' + response);
                })
                .catch((err: Error) => {
                    log.error('cancelCheckOut :', err);
                    throwUnExpectedError(err);
                    this.showToast(ToastType.ERROR, err.message);
                });
        } catch (err) {
            log.error('cancelCheckOut :' + JSON.stringify(err));
            throwUnExpectedError(err);
            this.showToast(ToastType.ERROR, (err as Error).message);
        }
        return this.isSuccess;
    };

    handleDocumentDownload = async (
        contextObj: ICHDownloadDocMetaType,
        documentEntity: DocumentEntity,
        isDownloadOnly?: boolean,
        isCheckoutRequest?: boolean,
    ): Promise<Response | string> => {
        const transformedObject = getTransformedObject<
            ICHDownloadDocMetaType,
            IDownloadDocument,
            CtxDownloadDocumentTransformer
        >(CapabiltyEnum.DOWNLOAD_DOCUMENT, contextObj);
        const { extendedProps } = transformedObject;
        const uniqueId = this.uniqueDocumentId || (await this._documentsService.getUniqueId(transformedObject));
        const isLockedForMe: boolean = this._documentsService.isDocumentLockedForMe(
            extendedProps.loggedInUser,
            documentEntity.versionSeriesCheckedOutBy,
        );
        const isCheckedOutByOther = await this._documentsService.isCheckedOutByOther(
            extendedProps.loggedInUser,
            documentEntity.versionSeriesCheckedOutBy,
        );
        const isCheckedOutByMe = documentEntity.versionSeriesCheckedOutBy && !isLockedForMe ? true : false;
        this.showToast_CheckedOutByOther(documentEntity, transformedObject);
        if (isDownloadOnly) {
            let authHeader = {};
            const authToken = await getAuthToken();
            if (authToken) {
                authHeader = { Authorization: 'Bearer ' + authToken };
            }
            return await this._officeService.download(this._documentsService.encodeURI(documentEntity.downloadUrl), {
                showSaveAsDialog: isDownloadOnly,
                requestHeaders: authHeader,
            });
        } else {
            if (isCheckedOutByMe) {
                const isFileExists = await this._officeService.isFileExists(uniqueId);
                if (!isFileExists) {
                    const response = await this._documentsService.downloadAndOpen({
                        url: documentEntity.downloadUrl,
                        isCheckedOutByMe,
                        uniqueId,
                    });
                    if (response === Locale.responseText.failures.download_document) {
                        return response;
                    }
                }
            } else {
                const docState = isCheckedOutByOther ? DocumentState.CheckoutByOther : DocumentState.checkIn;
                const message = Locale.banner.checkout_message;
                const message_checkoutByOther = `${message} ${this._messageService.compileTemplate(
                    Locale.banner.checked_out_not_by_me,
                    {
                        lockedBy: documentEntity?.versionSeriesCheckedOutBy,
                    },
                )}`;

                log.debug('msg: ' + message);
                contextObj.uniqueIdFromBanner = uniqueId;
                const metadata = {
                    title: Locale.banner.title_read_only,
                    state: docState,
                    contextObj: contextObj,
                    message:
                        docState == DocumentState.checkIn ? Locale.banner.read_only_message : message_checkoutByOther,
                    isLatestVersion: true,
                    fileName: documentEntity.documentFileName,
                };
                return await this._documentsService.downloadAndOpen({
                    url: documentEntity.downloadUrl,
                    isCheckedOutByMe,
                    metaData: metadata,
                });
            }
        }
        return await this.openAvailableDocument(uniqueId, isCheckoutRequest);
    };

    openAvailableDocument = async (uniqueId: string, isCheckoutRequest?: boolean): Promise<string> => {
        const isActive = await this._officeService.isActiveDocument(uniqueId);
        const isChekoutFromReadOnly = await this.checkOutFromReadOnlyDocument(uniqueId);
        if ((isActive || isChekoutFromReadOnly) && isCheckoutRequest) {
            const isExcel = this._officeService.isExcel;
            if (isExcel) {
                await this._officeService.saveAsInTempStorage();
            }
            log.info('OC | Invoked openAndActivateDocuemnt in active condition');
            await this._documentsService.openAndActivateDocument(uniqueId);
            // When the workbook contain volatile formula, excel seems to recalculate it in ._tmp workbook, which marks the ._tmp document as modified.
            const saveChanges =
                isExcel && !(await this._officeService.isDocumentSaved())
                    ? officeCompanionJS.SaveOptions.SaveChanges
                    : officeCompanionJS.SaveOptions.DoNotSaveChanges;

            if (this._bannerService.hasBanner || isExcel) {
                await this._officeService.close(saveChanges);
            }
            return Locale.responseText.checkout_documentOpened;
        }
        log.info('OC | Invoked openAndActivateDocuemnt in outside active condition');
        await this._documentsService.openAndActivateDocument(uniqueId);
        return Locale.responseText.checkout_documentOpened;
    };

    showDocument = async (
        contextObj: ICHDownloadDocMetaType,
        isDownloadOnly?: boolean,
        isCheckoutRequest?: boolean,
    ): Promise<Response | string> => {
        const isDocumentExist = await this._documentsService.wasStaleDocument(contextObj, DocumentState.DOC_EXIST);
        if (isDocumentExist) {
            const transformedObject = getTransformedObject<
                ICHDownloadDocMetaType,
                IDocumentMetadata,
                CtxDocumentMetadataTransformer
            >(CapabiltyEnum.DOCUMENT_METADATA, contextObj);
            const { extendedProps } = transformedObject;
            const documentEntity = await this._documentsService.getDocumentMeta(contextObj, true);
            if (documentEntity) {
                const { downloadUrl, latestVersion } = documentEntity;
                log.debug('CL latestVersion ' + latestVersion + ' CL extendedProps.version ' + extendedProps.version);

                if (!extendedProps.version) {
                    return await this.handleDocumentDownload(
                        contextObj,
                        documentEntity,
                        !!isDownloadOnly,
                        isCheckoutRequest,
                    );
                } else if (latestVersion == extendedProps.version) {
                    return await this.handleDocumentDownload(
                        contextObj,
                        documentEntity,
                        isDownloadOnly,
                        isCheckoutRequest,
                    );
                } else {
                    return await this.handleDocumentVersionDownload(downloadUrl, !!isDownloadOnly, documentEntity);
                }
            } else {
                return this._documentsService.handleErrorMessage({
                    message: Locale.stale.deleted_document,
                    contextObj,
                    isEntityDeleted: true,
                });
            }
        } else {
            return Locale.stale.deleted_document;
        }
    };

    handleDocumentVersionDownload = async (
        url: string,
        isDownloadOnly: boolean,
        documentEntity: DocumentEntity,
    ): Promise<Response | string> => {
        if (isDownloadOnly) {
            let authHeader = {};
            const authToken = await getAuthToken();
            if (authToken) {
                authHeader = { Authorization: 'Bearer ' + authToken };
            }
            return await this._officeService.download(this._documentsService.encodeURI(url), {
                showSaveAsDialog: isDownloadOnly,
                requestHeaders: authHeader,
            });
        }
        const metadata = {
            title: Locale.banner.title_previous_version,
            state: DocumentState.checkIn,
            message: Locale.banner.previous_version_message,
            isLatestVersion: false,
            contextObj: { documentId: documentEntity.id },
            fileName: documentEntity.documentFileName,
        };
        // Downloads and open the document
        return await this._documentsService.downloadAndOpen({
            url: url,
            isCheckedOutByMe: false,
            metaData: metadata,
        });
    };

    checkOut = async (contextObj: ICHCheckoutDocMetaType): Promise<Response | string> => {
        const isBusy = await this._officeService.isBusy();
        if (isBusy) {
            return await this._documentsService.handleIsApplicationBusy(Locale.documents.checkout);
        }
        const isDocumentExist = await this._documentsService.wasStaleDocument(contextObj, DocumentState.DOC_EXIST);
        if (!isDocumentExist) {
            return Locale.stale.deleted_document;
        }
        const docMeta = getTransformedObject<ICHDocumentMetadata, IDocumentMetadata, CtxDocumentMetadataTransformer>(
            CapabiltyEnum.DOCUMENT_METADATA,
            contextObj,
        );
        const uniqueId = this.uniqueDocumentId || (await this._documentsService.getUniqueId(docMeta));
        this._officeService.uniqueId = uniqueId;
        const isFileExists = await this._officeService.isFileExists(uniqueId);
        if (isFileExists) {
            const { buttons } = Dialog.document.overwrite_check_out;
            buttons.map((element) => {
                if (element.label == Dialog.buttons.keep) {
                    element.callback = async () => {
                        const keepCheckout = async (contextObj: ICHCheckoutDocMetaType, uniqueId: string) => {
                            await this.keepCheckout(contextObj, uniqueId);
                            if (!this.isSuccess) {
                                await this._bannerService.reopenBanner();
                            }
                        };
                        await handleCallback(keepCheckout.bind(this, contextObj, uniqueId));
                    };
                } else if (element.label == Dialog.buttons.overwrite) {
                    element.callback = async () => {
                        const overwriteCheckout = async (contextObj: ICHCheckoutDocMetaType, uniqueId: string) => {
                            await this.overwriteCheckout(contextObj, uniqueId);
                            if (!this.isSuccess) {
                                await this._bannerService.reopenBanner();
                            }
                        };
                        await handleCallback(overwriteCheckout.bind(this, contextObj, uniqueId));
                    };
                } else if (element.label == Dialog.buttons.cancel) {
                    element.callback = async () => {
                        await handleCallback(this._bannerService.reopenBanner.bind(this._bannerService));
                    };
                }
            });

            this._dialogService.showDialog(Dialog.document.overwrite_check_out);
            return Locale.responseText.file_exists;
        } else {
            const isDownloadOnly = false,
                isCheckoutRequest = true;
            const lockSuccess = await this.setLock(contextObj);
            if (!lockSuccess) {
                return Locale.failure.checkout_document;
            }
            this._chMessageService.notifyRefreshUUIForEntity(contextObj.entityTypeId, contextObj.documentId);
            return await this.showDocument(contextObj, isDownloadOnly, isCheckoutRequest);
        }
    };

    overwriteCheckout = async (contextObj: ICHCheckoutDocMetaType, uniqueId?: string): Promise<Response | string> => {
        const isDownloadOnly = false,
            isCheckoutRequest = true;

        if (!uniqueId) {
            const docMeta = getTransformedObject<
                ICHDocumentMetadata,
                IDocumentMetadata,
                CtxDocumentMetadataTransformer
            >(CapabiltyEnum.DOCUMENT_METADATA, contextObj);
            uniqueId = this.uniqueDocumentId || (await this._documentsService.getUniqueId(docMeta));
        }
        const isOpen = await this._officeService.isFileOpen(uniqueId);
        const isActive = await this._officeService.isActiveDocument(uniqueId);

        if (isOpen && !isActive) {
            log.info('OC | File is not active and opening switch dialog');
            await this._documentsService.prepareSwitchDialog(uniqueId);
            return Locale.responseText.switch_dialog;
        }
        // Saving checkout document as temp to close it and so that it can be move it to unlinked folder.
        const isActiveCheckOutDocument = isOpen && isActive;
        if (isActiveCheckOutDocument) {
            await this._officeService.saveAsInTempStorage();
        }

        const lockSuccess = await this.setLock(contextObj);
        if (!lockSuccess) {
            log.info('OC | Unable to checkout');
            return Locale.failure.checkout_document;
        }

        log.info('OC | Moved file to unlinked folders');
        await this._documentsService.clearFileReference(uniqueId);
        this._chMessageService.notifyRefreshUUIForEntity(contextObj.entityTypeId, contextObj.documentId);

        const response = await this.showDocument(contextObj as ICHDocumentMetadata, isDownloadOnly, isCheckoutRequest);

        // Closing temp document for active checkout document case.
        if (isActiveCheckOutDocument) {
            await this._officeService.close(SaveOptions.DoNotSaveChanges);
        }
        return response;
    };

    keepCheckout = async (contextObj: ICHCheckoutDocMetaType, uniqueId?: string): Promise<Response | string> => {
        log.info('OC | keepCheckout');
        const isDownloadOnly = false,
            isCheckoutRequest = true;
        if (!uniqueId) {
            const docMeta = getTransformedObject<
                ICHDocumentMetadata,
                IDocumentMetadata,
                CtxDocumentMetadataTransformer
            >(CapabiltyEnum.DOCUMENT_METADATA, contextObj);
            uniqueId = this.uniqueDocumentId || (await this._documentsService.getUniqueId(docMeta));
        }
        const isOpen = await this._officeService.isFileOpen(uniqueId);
        const isActive = await this._officeService.isActiveDocument(uniqueId);
        const isFileExists = await this._officeService.isFileExists(uniqueId);

        const lockSuccess = await this.setLock(contextObj);
        if (!lockSuccess) {
            return Locale.failure.checkout_document;
        }
        this._chMessageService.notifyRefreshUUIForEntity(contextObj.entityTypeId, contextObj.documentId);
        if (isFileExists && isOpen) {
            if (!isActive) {
                await FileOperations.open(uniqueId);
            }
            return Locale.responseText.success.checkout_document;
        }
        return await this.showDocument(contextObj as ICHDocumentMetadata, isDownloadOnly, isCheckoutRequest);
    };

    cancelCheckOut = async (contextObj: ICHCancelCheckoutDocMetaType): Promise<Response | string> => {
        const isBusy = await this._officeService.isBusy();
        if (isBusy) {
            return await this._documentsService.handleIsApplicationBusy(Locale.documents.undo_checkout);
        }

        const isDocumentExist = await this._documentsService.wasStaleDocument(contextObj, DocumentState.DOC_EXIST);
        if (!isDocumentExist) {
            return Locale.stale.deleted_document;
        }

        const docMeta = getTransformedObject<ICHDocumentMetadata, IDocumentMetadata, CtxDocumentMetadataTransformer>(
            CapabiltyEnum.DOCUMENT_METADATA,
            contextObj,
        );

        const uniqueId = this.uniqueDocumentId || (await this._documentsService.getUniqueId(docMeta));
        const isFileExists = await this._officeService.isFileExists(uniqueId);
        if (!isFileExists) {
            const isSuccess = await this.removeLock(contextObj);
            if (!isSuccess) {
                return Locale.failure.discard_document;
            }
            const response = await this.documentMetadata(contextObj);
            const { documentFileName } = await response.outputInfo();
            const successMessage = this._messageService.compileTemplate(Locale.responseText.success.discard_document, {
                filename: documentFileName,
            });
            this._chMessageService.notifyRefreshUUIForEntity(contextObj.entityTypeId, contextObj.documentId);
            this.showToast(ToastType.SUCCESS, successMessage);
            return Locale.responseText.success.discard_document;
        }
        const { buttons } = Dialog.document.discard_check_out;
        buttons.map((element) => {
            if (element.label == Dialog.buttons.keep) {
                element.callback = async () => {
                    await handleCallback(this.keepCancelCheckout.bind(this, contextObj));
                };
            } else if (element.label == Dialog.buttons.remove) {
                element.callback = async () => {
                    await handleCallback(this.removeCancelCheckout.bind(this, contextObj));
                };
            }
        });
        this._dialogService.showDialog(Dialog.document.discard_check_out);
        return Locale.responseText.file_exists;
    };

    removeCancelCheckout = async (contextObj: ICHCancelCheckoutDocMetaType): Promise<Response | string> => {
        const docMeta = getTransformedObject<ICHDocumentMetadata, IDocumentMetadata, CtxDocumentMetadataTransformer>(
            CapabiltyEnum.DOCUMENT_METADATA,
            contextObj,
        );

        const uniqueId = this.uniqueDocumentId || (await this._documentsService.getUniqueId(docMeta));

        const isOpen = await this._officeService.isFileOpen(uniqueId);
        const isActive = await this._officeService.isActiveDocument(uniqueId);

        if (isOpen && !isActive) {
            log.info('OC | File is not active and opening switch dialog');
            await this._documentsService.prepareSwitchDialog(uniqueId);
            return Locale.responseText.switch_dialog;
        }
        const isSuccess = await this.removeLock(contextObj);
        if (isSuccess) {
            const response = await this.documentMetadata(contextObj);
            const { documentFileName } = await response.outputInfo();
            const successMessage = this._messageService.compileTemplate(Locale.responseText.success.discard_document, {
                filename: documentFileName,
            });
            this.showToast(ToastType.SUCCESS, successMessage);
            this._chMessageService.notifyRefreshUUIForEntity(contextObj.entityTypeId, contextObj.documentId);
        } else {
            return Locale.failure.discard_document;
        }
        if (isActive) {
            await this._officeService.saveAsInTempStorage();
            await this._documentsService.clearFileReference(uniqueId);
            await this.openReadOnlyDocument(contextObj, uniqueId);
        } else {
            await this._documentsService.clearFileReference(uniqueId);
        }
        return Locale.responseText.success.discard_document;
    };

    keepCancelCheckout = async (contextObj: ICHCancelCheckoutDocMetaType): Promise<Response | string> => {
        const docMeta = getTransformedObject<ICHDocumentMetadata, IDocumentMetadata, CtxDocumentMetadataTransformer>(
            CapabiltyEnum.DOCUMENT_METADATA,
            contextObj,
        );

        const uniqueId = this.uniqueDocumentId || (await this._documentsService.getUniqueId(docMeta));
        const isOpen = await this._officeService.isFileOpen(uniqueId);
        const isActive = await this._officeService.isActiveDocument(uniqueId);

        if (isOpen && !isActive) {
            log.info('OC | File is not active and opening switch dialog');
            await this._documentsService.prepareSwitchDialog(uniqueId);
            return Locale.responseText.switch_dialog;
        }
        const isSuccess = await this.removeLock(contextObj);
        if (!isSuccess) {
            return Locale.failure.discard_document;
        }
        const response = await this.documentMetadata(contextObj);
        const { documentFileName } = await response.outputInfo();
        const successMessage = this._messageService.compileTemplate(Locale.responseText.success.discard_document, {
            filename: documentFileName,
        });
        this.showToast(ToastType.SUCCESS, successMessage);
        this._chMessageService.notifyRefreshUUIForEntity(contextObj.entityTypeId, contextObj.documentId);
        if (isActive) {
            // Saves a document to keep the updated content
            await this._officeService.saveActiveDocument();
            await this._officeService.saveAsInTempStorage();
            await this.openReadOnlyDocument(contextObj, uniqueId);
        }
        return Locale.responseText.success.discard_document;
    };

    openReadOnlyDocument = async (contextObj: ICHCancelCheckoutDocMetaType, uniqueId: string): Promise<void> => {
        const response = await this.documentMetadata(contextObj);
        if (response && response.ok) {
            const { downloadUrl, documentFileName } = await response.outputInfo();
            const message = Locale.banner.read_only_message;
            contextObj.uniqueIdFromBanner = uniqueId;
            const metadata = {
                title: Locale.banner.title_read_only,
                state: DocumentState.checkIn,
                contextObj: contextObj,
                message: message,
                isLatestVersion: true,
                fileName: documentFileName,
            };
            await this._documentsService.downloadAndOpen({
                url: downloadUrl,
                isCheckedOutByMe: false,
                metaData: metadata,
            });
            // Volatile formula triggers recalculation which modifies the document. So inform excel to save changes while closing the document.
            const saveChanges = this._officeService.isExcel ? SaveOptions.SaveChanges : SaveOptions.DoNotSaveChanges;
            await this._officeService.close(saveChanges);
        } else {
            this.showToast(ToastType.ERROR, Locale.failure.general_failure);
        }
    };

    showToast_CheckedOutByOther = (documentEntity: DocumentEntity, data: IDownloadDocument): void => {
        const isCheckedOutByOther = this._documentsService.isCheckedOutByOther(
            data?.extendedProps?.loggedInUser,
            documentEntity.versionSeriesCheckedOutBy,
        );
        if (isCheckedOutByOther) {
            const message = this._messageService.compileTemplate(Locale.documents.checked_out_not_by_me, {
                lockedBy: documentEntity?.versionSeriesCheckedOutBy,
                filename: documentEntity.documentFileName,
            });
            this.showToast(ToastType.WARNING, message);
        }
    };

    showToast = (type: ToastType, message: string): void => {
        this._eventService.publish({
            name: EventType.TOAST,
            toast: {
                type: type,
                toastMessage: message,
            },
        });
    };

    private async checkOutFromReadOnlyDocument(uniqueId: string): Promise<boolean> {
        if (this._bannerService.hasBanner) {
            const fullName = await this._officeService.getCurrentDocumentFullName();
            try {
                const meta: officeCompanionJS.FileMeta = await FileOperations.getMetadataByPath(fullName);
                if (meta.inputMetadata) {
                    const metaValues = this._bannerService.transformInputMetaData(meta.inputMetadata);
                    if (metaValues.contextObj.uniqueIdFromBanner === uniqueId) {
                        return true;
                    }
                }
            } catch (err) {
                log.error((err as Error).message);
                throwUnExpectedError(err);
            }
        }
        return false;
    }
}
