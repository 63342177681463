import { OfficeContext } from '../common/officeContext';
import { Application } from './application';
import { RequestType } from '../comObject';
import { Explorer } from './explorer';
import { Inspector } from './inspector';

export class OutlookContext extends OfficeContext {
    public get application(): Application {
        return this.reference.requestObject({
            type: RequestType.GetProperty,
            creator: Application,
            name: 'Application',
            cacheName: 'application',
            isReleasable: false,
        }).value;
    }

    public get explorer(): Explorer {
        return this.reference.requestObject({
            type: RequestType.GetProperty,
            creator: Explorer,
            name: 'Explorer',
            cacheName: 'explorer',
            isReleasable: false,
        }).value;
    }

    public get inspector(): Inspector {
        return this.reference.requestObject({
            type: RequestType.GetProperty,
            creator: Inspector,
            name: 'Inspector',
            cacheName: 'inspector',
            isReleasable: false,
        }).value;
    }
}
