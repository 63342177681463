import './app.scss';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, makeStyles, StylesProvider, ThemeProvider } from '@material-ui/core';
import { UUIFetch } from '@wk/elm-uui-common';
import React, { FC, useEffect, useState } from 'react';
import { ErrorBoundary, useErrorHandler } from 'react-error-boundary';
import { trackPromise } from 'react-promise-tracker';
import { Route } from 'react-router-dom';
import useMessageBus from './hooks/useMessageBus';
import { useReduxDispatch } from './hooks/useReduxDispatch';
import { useReduxSelector } from './hooks/useReduxSelector';
import { useUUIHistory } from './hooks/useUUIHistory';
import { handleinitializeResponse, handleJsonResponse } from './initializeCommon';
import { InitializeAppCallback, UUICustomComponents } from './initialize';
import { AppStore } from './reducers/types';
import { closeFailedUploadFlyout, readClosedFailedUploadFlyoutAtFromLocalStorage } from './utils/activitiesService';
import { ERROR_403_MESSAGE, FAILED_FETCH_MESSAGE, initializeUUIFetch } from './utils/fetchUtils';
import { getAuthenticationProvider } from './components/authentication/AuthenticationProviderService';
import { ErrorOverlay } from './components/common/errorOverlay';
import { clInitialize, isOC } from './components/contextLayerService/contextLayerService';
import { updateLocationDataForOC } from './utils/ocLocationService';
import { BlockUi, UuiAppBar, FullScreenOverlayDialog, UUIDialogs, GlobalDragAndDropHandler } from './components/core';
import { UuiPage } from './components/core';
import { appTheme } from './app.theme';

export type AppProps = {
    customComponents: UUICustomComponents;
    initializeAppCallback?: InitializeAppCallback;
};

export const App: FC<AppProps> = ({ customComponents, initializeAppCallback }: AppProps) => {
    const { AppProvider } = customComponents;

    return (
        <React.StrictMode>
            <AppProvider>
                <StylesProvider injectFirst>
                    <ThemeProvider theme={createMuiTheme(appTheme)}>
                        <CssBaseline />
                        <UUIAppWrapper
                            customComponents={customComponents}
                            initializeAppCallback={initializeAppCallback}
                        />
                    </ThemeProvider>
                </StylesProvider>
            </AppProvider>
        </React.StrictMode>
    );
};

export const UUIAppWrapper: React.FC<AppProps> = (props) => (
    <UUIErrorBoundary>
        <UuiAppContainer {...props} />
    </UUIErrorBoundary>
);

export const UuiAppContainer: React.FC<AppProps> = ({ customComponents, initializeAppCallback }) => {
    const heartBeatFunction = useReduxSelector((state) => state.uuiConfiguration?.heartBeatFunction);
    const reduxDispatch = useReduxDispatch();
    const history = useUUIHistory();
    const [initializeCallComplete, setInitialializeCallComplete] = useState(false);
    useGlobalErrorHandler();

    useEffect(() => {
        const callInitializeApp = async () => {
            if (initializeAppCallback) {
                await initializeAppCallback(reduxDispatch, history);
            } else {
                return Promise.resolve();
            }
        };

        const initializeUUI = async () => {
            initializeUUIFetch(reduxDispatch, heartBeatFunction);
            await callInitializeApp();
            const { apiContextRoot, apiContextPath, initializationPath } = window.Props;

            const response = await UUIFetch.fetch(
                apiContextRoot + apiContextPath + initializationPath,
                await getAuthenticationProvider().addRequestAuthentication({}),
            );
            const responseData = await handleinitializeResponse(response);
            // @ts-ignore
            const initialState: AppStore = handleJsonResponse(reduxDispatch, responseData);
            UUIFetch.setBaselineAPIVersion(initialState.version);
            // context layer
            if (isOC()) {
                clInitialize({
                    props: {
                        autoUpdateURL: initialState.appVersion?.autoUpdateUrl
                            ? initialState.appVersion.autoUpdateUrl
                            : '',
                        currentVersion: '',
                    },
                });
            }

            if (readClosedFailedUploadFlyoutAtFromLocalStorage() === null) {
                closeFailedUploadFlyout(reduxDispatch);
            }

            reduxDispatch({ type: 'SetInitialReduxState', state: initialState });
            setInitialializeCallComplete(true);
        };

        trackPromise(initializeUUI());
    }, [heartBeatFunction, initializeAppCallback, reduxDispatch, history]);

    useEffect(() => {
        const unlisten = history.listen((newLocation, action) => {
            if (!initializeCallComplete) {
                updateLocationDataForOC(newLocation, action);
            }
        });

        return () => unlisten();
    }, [history, initializeCallComplete]);

    return (
        <>
            {initializeCallComplete ? (
                <UUIApp customComponents={customComponents} />
            ) : (
                <>
                    <FullScreenOverlayDialog />
                    <BlockUi />
                </>
            )}
        </>
    );
};

export const UUIErrorBoundary: React.FC = (props) => (
    <ErrorBoundary FallbackComponent={ErrorOverlay}>{props.children}</ErrorBoundary>
);

const useStyles = makeStyles({
    app: {
        flexGrow: 1,
        minWidth: 360,
        backgroundColor: '#fff',
    },
    blockUiContainer: {
        position: 'relative',
        height: '100%',
    },
});

const UUIApp: React.FC<AppProps> = ({ customComponents }) => {
    const styles = useStyles();

    useMessageBus();

    return (
        <div id="uuiAppContainer" className={styles.app}>
            <UuiAppBar customComponents={customComponents} />

            <div className={styles.blockUiContainer}>
                <Route path="/enduser/:screenType/*" component={UuiPage} />
                <UUIDialogs />
                <BlockUi />
                <GlobalDragAndDropHandler />
            </div>
        </div>
    );
};

export const useGlobalErrorHandler = (): void => {
    const errorHandler = useErrorHandler();

    useEffect(() => {
        const ignoredErrorMessages = [FAILED_FETCH_MESSAGE, ERROR_403_MESSAGE];
        const errorIsHandledByDifferentOverlay = (e: ErrorEvent) =>
            ignoredErrorMessages.find((m) => e.message.includes(m) || m.includes(e.message)) !== undefined;

        const errorEventListener = (e: ErrorEvent) => {
            if (!errorIsHandledByDifferentOverlay(e)) {
                errorHandler(e);
            }
        };

        const unhandledRejectionListener = (e: PromiseRejectionEvent) => {
            if (!errorIsHandledByDifferentOverlay(e.reason)) {
                errorHandler(e.reason);
            }
        };

        window.addEventListener('error', errorEventListener);
        window.addEventListener('unhandledrejection', unhandledRejectionListener);

        return () => {
            window.removeEventListener('error', errorEventListener);
            window.removeEventListener('unhandledrejection', unhandledRejectionListener);
        };
    }, [errorHandler]);
};
