import { Container, interfaces } from 'inversify';
import { commonContainer } from '../../common/common.module';
import { SystemService } from './system.service';

const importedContainers = [commonContainer];

const SystemContainer: interfaces.Container = importedContainers.reduce(
    (container, imports) => Container.merge(imports, container),
    new Container(),
);

SystemContainer.bind<SystemService>(SystemService).toSelf().inSingletonScope();

export const System = SystemContainer.get(SystemService);
