import { CapabiltyEnum } from '../../enums/capability';
import { injectable } from 'inversify';
import { JRAdapterDownloadPattern } from '../urlEntity/downloadPattern';
import getVersion from '../utils/utils';
import { IDownloadDocument } from '../../docManager';

@injectable()
export class JRAdapterDownloadDocumentUrlPattern extends JRAdapterDownloadPattern {
    operationName = CapabiltyEnum.DOWNLOAD_DOCUMENT;
    async getUrl(nodeObj: IDownloadDocument): Promise<string> {
        let baseUrl = await super.getUrl(nodeObj);

        const token = await this.tokenHandler.getAuthInfo();

        const version = await getVersion(baseUrl, nodeObj, token);

        baseUrl = baseUrl + '/document/' + nodeObj.docId + '/' + 'DocumentVersion' + '/' + version;
        return baseUrl;
    }
}
