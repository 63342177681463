import { IValidationTransformer } from './types';
import { ICHValidateCheckInDocument, IValidateCheckInDocument } from '../interfaces/operations';

import 'reflect-metadata';
import { injectable } from 'inversify';
import { factory } from '../configLog4J';
const log = factory.getLogger('CtxValidateCheckInDocumentTransformer');

@injectable()
export class CtxValidateCheckInDocumentTransformer implements IValidationTransformer {
    data!: IValidateCheckInDocument;

    transform(): ICHValidateCheckInDocument {
        const nodeObj = {} as ICHValidateCheckInDocument;
        log.debug('printing Payload ' + JSON.stringify(this.data));
        // PP
        if (this.data?.name) {
            nodeObj.name = this.data?.name;
        }
        if (this.data?.documentFileName) {
            nodeObj.documentFileName = this.data?.documentFileName;
        }

        // T360
        if (this.data?.documentName) {
            nodeObj.name = this.data.documentName;
        }
        if (this.data?.documentType) {
            nodeObj.document_original_type = this.data.documentType;
        }
        if (this.data?.documentId) {
            nodeObj.docId = this.data.documentId;
        }
        if (this.data?.comment) {
            nodeObj.comments = this.data.comment;
        }
        if (this.data?.default) {
            nodeObj.default = this.data.default;
        }
        return nodeObj;
    }
}
