import { ClientObject, ClientObjectProperty, PropertyInit } from '../common/clientObject';
import { RequestType, ValueHolder, ValueType } from '../comObject';

export class ContactItem extends ClientObject implements PropertyInit {
    private properties = this.clientObjectInitPropsInfo({
        fullName: { propertyName: 'fullName', name: 'FullName' },
    });

    public getPropertyInitializers(): Map<keyof ContactItem, () => void> {
        return new Map<keyof ContactItem, () => void>(
            Object.values(this.properties).map(({ propertyName, name }) => [
                propertyName,
                (): void =>
                    this.reference.request({
                        type: RequestType.GetProperty,
                        cacheName: propertyName,
                        name,
                    }),
            ]),
        );
    }

    private setProperty<T>(property: ClientObjectProperty<ContactItem>, valueHolder: ValueHolder<T>): void {
        const { propertyName, name } = property;

        this.reference.request({
            type: RequestType.SetProperty,
            cacheName: propertyName,
            name,
            args: [valueHolder],
        });
    }

    public get fullName(): string {
        return this.reference.getValue<string>(this.properties.fullName.propertyName).value;
    }

    public set fullName(value: string) {
        this.setProperty(this.properties.fullName, { type: ValueType.String, value });
    }

    public display(): void {
        return this.reference.request({
            type: RequestType.Invoke,
            name: 'Display',
        });
    }
}
