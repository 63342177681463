import { Logger } from './src/logger';
import { BaseCommunicationService } from './src/browser';

export { browserUtilitiesService } from './src/globals/__global';
export { BaseCommunicationEvent } from './src/browser';
export { ApplicationType, OcExposeInfo } from './src/globals/ocInfo';
export { Logger } from './src/logger';

export * from './src/exceptions';

const logger = new Logger();

export const baseCommunicationService = new BaseCommunicationService(logger);
