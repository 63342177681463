import { getLogger } from '@wk/elm-uui-common';
import { getToken } from '@wk/elm-uui-context-handler';
import { OidcStorageHelper } from './oidcStorageHelper';

// tslint:disable: no-any
export class OidcTokenClient {
    private baseTokenClient: any;

    constructor(userManager: any) {
        this.baseTokenClient = userManager._tokenClient;
    }

    exchangeCode(args: any = {}) {
        return this.baseTokenClient.exchangeCode(args);
    }

    async exchangeRefreshToken(args: any = {}) {
        let refreshToken;
        try {
            refreshToken = await getToken(OidcStorageHelper.getId(), OidcStorageHelper.getSecret())
                .toPromise()
                .then((refreshTokenData) => refreshTokenData.data);
        } catch (e) {
            refreshToken = window.localStorage.getItem('uui_refresh_token_temp');

            getLogger('t360.oidcTokenClient').error('Context Layer Get Token Error: ', e);
        }

        if (refreshToken) {
            args.refresh_token = refreshToken;
        }
        return this.baseTokenClient.exchangeRefreshToken(args);
    }
}
