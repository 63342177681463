import { createBrowserHistory, createMemoryHistory } from 'history';
import { isUndefined } from 'lodash';

// looks like hack workaround. check and remove in the future
// in total it is better to avoid using window global properties
if (isUndefined(window.Props)) {
    window.Props = {};
}
const isJestEnvironment = Props['uiContextPath'] === 'jest';

// need named export for typescript
export const history = !isJestEnvironment
    ? createBrowserHistory({
          basename: Props['uiContextPath'] + Props['basePath'],
      })
    : createMemoryHistory();
