export enum ApplicationContextUI {
    OfficeCompanion = 'Electron', // TODO - Should be updated as "Electron" or "Renderer"
    Browser = 'UI',
}

export enum ContextHandlers {
    OfficeCompanionContextHandler = 'OCContextHandler',
    BrowserContextHandler = 'UIContextHandler',
}

export enum ContextIndicatorEnum {
    OC = 'OfficeCompanion',
    UI = 'UI',
    MO = 'Mobile',
}
