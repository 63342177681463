import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
import { useUniqueId } from '../../hooks/useUniqueId';

const WkWordDocIcon: React.FC<SvgIconProps> = (props) => {
    const lgWordDocId = useUniqueId('lgWordDoc_');
    const lgWordDocId2 = useUniqueId('lgWordDoc2_');
    return (
        <SvgIcon data-testid="wkWordDocIcon" viewBox="0 0 16 16" {...props}>
            <defs>
                <linearGradient id={lgWordDocId} y1=".054" x2="1.109" y2="1.083" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#0a3165" />
                    <stop offset="1" stopColor="#004d9a" />
                </linearGradient>
                <linearGradient id={lgWordDocId2} x1=".295" y1=".5" x2=".98" y2=".5" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#f0f0f0" />
                    <stop offset="1" stopColor="#fff" />
                </linearGradient>
            </defs>
            <g data-name="Group 3033">
                <g data-name="Group 2019">
                    <rect data-name="Rectangle 1055" width="16" height="16" rx="1" fill={`url(#${lgWordDocId})`} />
                </g>
                <g data-name="Group 2020">
                    <path
                        data-name="Path 3152"
                        d="M18.128 31.3L16 23h1.7l1.239 6.319L20.606 23h1.639l1.607 6.417L25.113 23h1.67l-2.162 8.3h-1.76l-1.47-6.333-1.464 6.333z"
                        transform="translate(-13.643 -19)"
                        fill={`url(#${lgWordDocId2})`}
                    />
                </g>
            </g>
        </SvgIcon>
    );
};

export default WkWordDocIcon;
