export enum CapabiltyEnum {
    CHECKOUT = 'CheckOut',
    CHECKIN = 'CheckIn',
    ADD_DOCUMENT = 'AddDocument',
    ADD_FOLDER = 'AddFolder',
    DELETE = 'Delete',
    LIST = 'List',
    CANCEL_CHECKOUT = 'CancelCheckOut',
    RENAME_DOCUMENT = 'RenameDocument',
    DELETE_DOCUMENT = 'DeleteDocument',
    DELETE_FOLDER = 'DeleteFolder',
    ADD_EMAIL = 'AddEmail',
    DELETE_EMAIL = 'DeleteEmail',
    DELETE_EMAIL_FOLDER = 'DeleteEmailFolder',
    RENAME_FOLDER = 'RenameFolder',
    DOWNLOAD_DOCUMENT = 'DownloadDocument',
    DOWNLOAD_VERSION_DOCUMENT = 'DownloadVersionDocument',
    DOCUMENT_METADATA = 'DocumentMetadata',
    FOLDER_METADATA = 'FolderMetadata',
    DOCUMENT_METADATA_FILENAME = 'DocumentMetadataFileName',
    DOWNLOAD_EMAIL = 'DownloadEmail',
    DOCUMENT_METADATA_EMAIL = 'DocumentMetadataEmail',
    GET_NOTIFICATIONS_FILES = 'GetNotificationFiles',
    GET_SUPPORTED_ENTITY_TYPES = 'GetSupportedEntityTypes',
    EDIT_FOLDER = 'EditFolder',
    EDIT_DOCUMENT = 'EditDocument',
    VALIDATE_DOCUMENT = 'ValidateDocument',
    VALIDATE_CHECKIN_DOCUMENT = 'ValidateCheckInDocument',
}
