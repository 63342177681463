import { Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useReduxDispatch } from '../../hooks/useReduxDispatch';
import { useReduxSelector } from '../../hooks/useReduxSelector';
import { useUUIHistory } from '../../hooks/useUUIHistory';
import useWindowWidth from '../../hooks/useWindowWidth';
import IconMapper from '../common/iconMapper';
import UUIButton from '../common/uuiButton';
import { measureStringWidth } from '../../utils/utilities';
import { dispatchListScreenNonBulkOperation } from './context/listScreenAsyncActions';
import { useListScreenState } from './context/listScreenContext';
import { useRefreshList } from './context/listScreenHooks';
import css from './listScreenButtonRow.module.scss';
import { getNonBulkOperations } from './listScreenHelpers';
import { QuickSearch } from './quickSearch';

const ListScreenButtonRow: React.FC = () => {
    const listScreenState = useListScreenState();
    const applicationUrls = useReduxSelector((state) => state.applicationUrls);
    const firstOperation = getNonBulkOperations(listScreenState.metadata, listScreenState.mode)[0];
    const history = useUUIHistory();
    const reduxDispatch = useReduxDispatch();
    const refreshList = useRefreshList();
    const windowWidth = useWindowWidth(100);
    const [quickSearchWidth, setQuickSearchWidth] = useState(150);

    useEffect(() => {
        // Width Paddings:(left+right+button+between+scrollbar) : (left & right)
        const WIDTH_PADDING = firstOperation ? 10 + 10 + 32 + 20 + 20 : 20;
        const BUTTON_TEXT_WIDTH = firstOperation ? measureStringWidth(firstOperation.displayName, '14px') : 0;
        const availableSpace = windowWidth - WIDTH_PADDING - BUTTON_TEXT_WIDTH;
        const maxQuickSearchWidth = 320;
        const minQuickSearchWidth = 150;

        if (availableSpace >= maxQuickSearchWidth) {
            setQuickSearchWidth(maxQuickSearchWidth);
        } else if (availableSpace <= minQuickSearchWidth) {
            setQuickSearchWidth(minQuickSearchWidth);
        } else {
            setQuickSearchWidth(availableSpace);
        }
    }, [firstOperation, quickSearchWidth, windowWidth]);

    return (
        <div className={css.buttonRowRoot}>
            <Grid container direction="row" alignItems="center" spacing={1}>
                <Grid item className={css.buttonRowItems}>
                    <div className={css.quickSearchWrapper}>
                        <QuickSearch width={quickSearchWidth + 'px'} />
                    </div>

                    {firstOperation && (
                        <UUIButton
                            onClick={() =>
                                dispatchListScreenNonBulkOperation(
                                    firstOperation.id,
                                    listScreenState,
                                    applicationUrls,
                                    history,
                                    reduxDispatch,
                                    refreshList,
                                )
                            }
                            variant="contained"
                            title={firstOperation.displayName}
                            color="primary"
                            startIcon={
                                firstOperation?.iconName && firstOperation?.iconName !== 'iconBlank' ? (
                                    <IconMapper customClass={css.addIcon} iconName={firstOperation.iconName} />
                                ) : null
                            }
                            classes={{ contained: css.button }}
                            data-testid="listscreen-firstoperation-button">
                            <Typography noWrap variant="inherit" className={css.buttonText}>
                                {firstOperation.displayName}
                            </Typography>
                        </UUIButton>
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

ListScreenButtonRow.displayName = 'ListScreenButtonRow';

export default ListScreenButtonRow;
