import React, { InputHTMLAttributes } from 'react';
import { Button, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import classNames from 'classnames';
import { getBulkOperations, getColumnHeaderDisplayName, getListItemActionClassName } from './listScreenHelpers';
import css from './listViewHeader.module.scss';
import ListViewItemTextRow from './listViewItemTextRow';
import { LeftColumnWidth, IListScreenColumn, IListScreenPageData } from './types';
import ArrowUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import { parseSortData, getColumnItems } from './listViewHelpers';
import { useListScreenState } from './context/listScreenContext';
import { useReduxSelector } from '../../hooks/useReduxSelector';
import { WkDownArrowIcon } from '../icons/wkDownArrowIcon';

export interface IListViewHeaderProps {
    checkboxType: 'checked' | 'minus' | 'unchecked';
    toggleCheckAll: () => void;
    nonIconColumns: IListScreenColumn[];
    leftColumnWidth: LeftColumnWidth;
    checkAllDisabled?: boolean;
    sortDisabled?: boolean;
    onClick: (sortColumn: IListScreenColumn) => void;
    pageData: IListScreenPageData;
    isEmbeddedList?: boolean;
    subTabs?: boolean;
    stickyTopPosition?: number;
}

export const ListViewHeader: React.FC<IListViewHeaderProps> = React.memo(
    ({
        checkboxType,
        toggleCheckAll,
        nonIconColumns,
        checkAllDisabled,
        sortDisabled,
        onClick,
        pageData,
        isEmbeddedList,
        stickyTopPosition,
    }) => {
        const checkAllAriaLabel = useReduxSelector((state) => state.appResources.checkAllHint);
        const currentSortData = parseSortData(pageData.sortInfo);
        const arrColumns = getColumnItems(nonIconColumns);
        const listScreenState = useListScreenState();
        const metadata = listScreenState.metadata!;
        const bulkOperations = getBulkOperations(metadata, listScreenState.mode);

        const clickableHeader = (column: IListScreenColumn, text: string) => {
            const currentSortEntry = currentSortData[0];
            const columnSorted = currentSortEntry?.path === column.path;
            const renderSortIcon = () => {
                if (!currentSortEntry) {
                    return;
                }

                if (!columnSorted) {
                    return <WkDownArrowIcon data-testid={`${text}__sortInactive`} />;
                }
                if (currentSortEntry.direction === 'ASCENDING') {
                    return <ArrowUpIcon data-testid={`${text}__sortArrowUp`} />;
                }
                return <ArrowDownIcon data-testid={`${text}__sortArrowDown`} />;
            };

            return (
                <React.Fragment>
                    <Button
                        size="small"
                        classes={{
                            label: css.headerText,
                            root: css.headerTextButton,
                            endIcon: classNames(css.iconRoot, { [css.iconSortbyArrow]: !columnSorted }),
                        }}
                        endIcon={renderSortIcon()}
                        onClick={() => onClick(column)}>
                        {text}
                    </Button>
                </React.Fragment>
            );
        };

        const stickyStyles = stickyTopPosition
            ? {
                  top: stickyTopPosition + 'px',
              }
            : undefined;

        const nonClickableHeader = (text: string) => <span className={css.headerTextNoButton}>{text}</span>;

        const CheckBox = () => (
            <ListItemIcon classes={{ root: css.checkboxContainer }}>
                <Checkbox
                    edge="start"
                    color="primary"
                    disabled={checkAllDisabled}
                    indeterminate={checkboxType === 'minus'}
                    checked={checkboxType === 'checked' || checkboxType === 'minus'}
                    tabIndex={-1}
                    disableRipple
                    inputProps={
                        {
                            'aria-label': checkAllAriaLabel,
                            'data-testid': 'checkAll',
                        } as InputHTMLAttributes<HTMLInputElement>
                    }
                    onChange={toggleCheckAll}
                />
            </ListItemIcon>
        );

        return (
            <li className={isEmbeddedList ? css.embeddedListSticky : css.mainListSticky} style={stickyStyles}>
                <ListItem
                    classes={{
                        root: css.listViewHeaderRoot,
                        secondaryAction: getListItemActionClassName(listScreenState),
                    }}
                    dense={true}
                    ContainerComponent="div"
                    component="div">
                    <ListItemText
                        className={`${css.listItemTextRoot} ${css.textContainerForHeader}`}
                        primary={
                            <React.Fragment>
                                {bulkOperations.length > 0 && <CheckBox />}
                                {arrColumns.map((column: any, index: number) => {
                                    const leftHeaderText = getColumnHeaderDisplayName(column[0]);
                                    const rightHeaderText = column[1] ? getColumnHeaderDisplayName(column[1]) : '';

                                    if (index > 2) {
                                        return;
                                    }

                                    return (
                                        <div
                                            className={
                                                bulkOperations.length > 0
                                                    ? css.textHeaderContainerWithCheckBox
                                                    : css.textHeaderContainerNoCheckBox
                                            }
                                            key={index}>
                                            <ListViewItemTextRow
                                                rowIndex={index}
                                                leftText={leftHeaderText}
                                                leftComponent={
                                                    column[0].sortable && !sortDisabled
                                                        ? clickableHeader(column[0], leftHeaderText)
                                                        : nonClickableHeader(leftHeaderText)
                                                }
                                                rightText={rightHeaderText}
                                                rightComponent={
                                                    column[1]
                                                        ? column[1].sortable && !sortDisabled
                                                            ? clickableHeader(column[1], rightHeaderText)
                                                            : nonClickableHeader(rightHeaderText)
                                                        : undefined
                                                }
                                                fontSize="14px"
                                                leftColumnWidth={index === 0 ? '100%' : '50%'}
                                                classes={{
                                                    textContent:
                                                        bulkOperations.length > 0
                                                            ? css.textHeaderContent
                                                            : css.textHeaderContentNoCheckBox,
                                                }} // fix for IE11
                                            />
                                        </div>
                                    );
                                })}
                            </React.Fragment>
                        }
                    />
                    {!checkAllDisabled && <ListItemSecondaryAction></ListItemSecondaryAction>}
                </ListItem>
            </li>
        );
    },
);

ListViewHeader.displayName = 'ListViewHeader';
ListViewHeader.defaultProps = {
    checkAllDisabled: false,
};

export default ListViewHeader;
