import { getLogger, initializeLoggerFactory, LogLevel } from '@wk/elm-uui-common';
import { CHMessageData, IOverlayDialog, IRefreshUUIForEntity } from '@wk/elm-uui-context-handler';
import { useEffect } from 'react';
import { getAuthenticationProvider } from '../components/authentication/AuthenticationProviderService';
import { clSubscribeMessagingService, isOC } from '../components/contextLayerService/contextLayerService';
import { clearLocationDataForOC } from '../utils/ocLocationService';
import { getILogLevelFromKey } from '../components/helpSection/helpScreenHelpers';
import { IAppResources, NotificationType, UUIReduxDispatch } from '../reducers/types';
import { useReduxDispatch } from './useReduxDispatch';
import { useReduxSelector } from './useReduxSelector';
import { updateBatchUploadFlyoutCount } from '../utils/activitiesService';
import { format } from 'date-fns';

export const LOGGING_OUT = 'LOGGING_OUT';

const messageHandler = (
    data: CHMessageData,
    logoutUrl: string,
    reduxDispatch: UUIReduxDispatch,
    appResources: IAppResources,
): void => {
    switch (data.type) {
        case 'Logout': {
            clearLocationDataForOC();
            sessionStorage.setItem(LOGGING_OUT, 'true');
            const authProvider = getAuthenticationProvider();
            authProvider.signOut(logoutUrl);
            break;
        }
        case 'ShowToast': {
            const payload = JSON.parse(data.message);

            reduxDispatch({
                type: 'ShowNotification',
                notification: {
                    key: payload.key,
                    message: payload.message,
                    options: {
                        variant: (['default', 'information'].includes(payload.toastType)
                            ? 'info'
                            : payload.toastType) as NotificationType,
                        persist: payload.toastType.includes('success') ? false : true,
                    },
                },
            });

            break;
        }
        case 'RemoveToast': {
            reduxDispatch({
                type: 'RemoveNotification',
                key: data.message,
            });
            break;
        }
        case 'DismissToast': {
            reduxDispatch({
                type: 'DismissNotification',
                key: data.message,
            });
            break;
        }
        case 'RefreshUUIForEntity': {
            const refreshUUIForEntity: IRefreshUUIForEntity = JSON.parse(data.message);
            getLogger('RefreshUUIForEntity').info(
                'Refresh UUI For Entity Requested: ' + format(new Date(), 'hh:mm:ss.SS a'),
            );
            reduxDispatch({
                type: 'RefreshUUIForEntity',
                refreshUUIForEntity,
            });
            break;
        }
        case 'SetLoggingLevel': {
            const updatedLoglevel = data.message as unknown as LogLevel;
            initializeLoggerFactory(updatedLoglevel);
            reduxDispatch({
                type: 'SaveLogLevel',
                updateLogLevel: getILogLevelFromKey(appResources, updatedLoglevel),
            });
            break;
        }
        case 'FailedUploadFlyoutOpen': {
            const isOpenValue = data.message.toLowerCase() === 'true';
            reduxDispatch({
                type: 'SetFailedUploadFlyoutOpen',
                isOpen: isOpenValue,
            });
            break;
        }
        case 'OpenOverlayDialog': {
            const payload: IOverlayDialog = JSON.parse(data.message);
            reduxDispatch({
                type: 'OpenOverlayDialog',
                overlayDialog: {
                    heading: payload.heading,
                    message: payload.message,
                    icon: payload.icon,
                    showSpinner: payload.showSpinner,
                },
            });
            break;
        }
        case 'CloseOverlayDialog': {
            reduxDispatch({
                type: 'CloseOverlayDialog',
            });
            break;
        }
        case 'FlyoutComplete': {
            updateBatchUploadFlyoutCount(data.message, reduxDispatch);
            break;
        }
        case 'ReloadUUI': {
            location.reload();
        }
    }
};

/**
 * Hook to setup subscriptions for the MessageBus.
 */
export const useMessageBus = (): void => {
    const logoutUrl = useReduxSelector((state) => state.appResources.logoutUrl);
    const appResources = useReduxSelector((state) => state.appResources);
    const reduxDispatch = useReduxDispatch();

    useEffect(() => {
        if (!isOC()) {
            return;
        }

        const messageBusSubscription = clSubscribeMessagingService((data) => {
            messageHandler(data, logoutUrl, reduxDispatch, appResources);
        });

        return () => {
            messageBusSubscription?.unsubscribe();
        };
    }, [appResources, logoutUrl, reduxDispatch]);
};

export default useMessageBus;
