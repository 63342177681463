/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Dispatch, Reducer, ReducerAction, ReducerState, useCallback, useReducer } from 'react';
import { getLogger } from '../utils/loggingService';

const logger = (loggerName: string, action: any) => {
    getLogger(loggerName).debug('Dispatched Context Action: ' + action?.type);
};

export function useReducerWithLogging<R extends Reducer<any, any>>(
    loggerName: string,
    reducer: R,
    initialState: ReducerState<R>,
    initializer?: undefined,
): [ReducerState<R>, Dispatch<ReducerAction<R>>] {
    const [state, dispatch] = useReducer(reducer, initialState, initializer);

    const dispatchWithLogging = useCallback(
        (action: ReducerAction<R>) => {
            logger(loggerName, action);
            dispatch(action);
        },
        [loggerName],
    );

    return [state, dispatchWithLogging];
}
