import { IDocTransformer } from '../../interfaces/transformer/type';
import { IActionableEntity } from '../../interfaces/actionEntity/type';
import { injectable } from 'inversify';
import { factory } from '../../ConfigLog4j';
import { IMatterDoc, ICheckIn } from '../../types';
import { JRAdapterCheckInActionableEntity } from '../actionableEntity/checkInActionableEntity';
import { toBase64 } from '../../utils/main.utils';
import { ICheckIn_JR } from '../types';

const log = factory.getLogger('JRAdapterCheckInEntityTransformer');

@injectable()
export class JRAdapterValidateCheckInDocumentEntityTransformer implements IDocTransformer {
    matterDoc: IMatterDoc;
    async transformIntoActionableEntity(nodeObj: ICheckIn): Promise<IActionableEntity> {
        log.debug('Calling transformIntoActionableEntity');
        const obj = {} as JRAdapterCheckInActionableEntity;

        const nodeObj1 = nodeObj as ICheckIn_JR;
        obj.attributes = {} as {
            name: string;
            associatedEntityName: string;
            documentFile: { name: string };
        };

        if (nodeObj1?.name != undefined || nodeObj1?.name) {
            obj.attributes.name = nodeObj1.name;
        }

        if (nodeObj1?.documentName != undefined || nodeObj1?.documentName) {
            obj.attributes.documentFile = { name: nodeObj1.documentName };
        }

        if (nodeObj1.entityContext?.type) {
            obj.attributes.associatedEntityName = nodeObj1.entityContext.type;
        }

        if (nodeObj.extendedProps.comment) {
            obj.attributes.comments = nodeObj.extendedProps.comment;
        }
        if (nodeObj?.file) {
            obj.data = await toBase64(nodeObj1.file);
        }
        // TO CREATE ACTIONABLE ENTITY FROM nodeObj and matterDoc
        return obj;
    }
}
