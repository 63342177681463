import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const wkCheckOutIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="wkCheckOutIcon" viewBox="0 0 24 24" {...props}>
        <g transform="translate(-1019.402 -508.001)">
            <g transform="translate(1015.502 508.001)">
                <path
                    d="M9.4,16H5.9V0H14l3.9,3.9V16h-5.3l1.1-1.2h3V5.3h-4v-4H7.2v13.4h1L9.4,16z M14,1.9v2.1h2.1
			L14,1.9z"
                />
                <path d="M9.4,12.9l1.1,1V7.5h1.1v6.5l1.1-1.1l0.7,0.7L11.1,16l-2.4-2.3L9.4,12.9z" />
            </g>
        </g>
    </SvgIcon>
);

export default wkCheckOutIcon;
