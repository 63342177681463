import { BehaviorSubject, from, isObservable, map, Observable, of, Subject, switchMap } from 'rxjs';
import { ocRenderer } from '../globals/__global';
import { v4 as uuid } from 'uuid';
import { LoggerInterface } from '../logger';
import { OcRendererEvent } from '../globals/ocRenderer';
import { BaseResponseException } from '../exceptions';

enum StreamRequestType {
    Initial = 'initial',
    DataTransfer = 'dataTransfer',
}

interface TransferObjectHeaders {
    requestId: string;
    requestType?: StreamRequestType;
}

interface TransferObjectRequest<T> {
    data: T;
    error?: TransferObjectError<T>;
    headers: TransferObjectHeaders;
}

interface TransferObjectError<T> extends Error {
    data?: T;
    [key: string]: unknown; // todo: should change to particular properties
}

interface TransferObjectResponse<T, TError = unknown> {
    data: T;
    error?: BaseResponseException<TError>;
    headers: TransferObjectHeaders;
}

interface ChannelInfo {
    request: string;
    response: string;
}

export interface BaseCommunicationEvent<T> {
    data: T;
}

interface CommunicationEvent<T, TError = unknown> extends BaseCommunicationEvent<T | undefined> {
    error?: BaseResponseException<TError>;
}

interface ObserverObject<T> {
    nextValue?: T;
    complete?: boolean;
}

type ChannelNameType = string;

export class BaseCommunicationService {
    constructor(private logger: LoggerInterface) {}

    // eslint-disable-next-line max-lines-per-function
    public on<T, U = unknown>(
        channel: ChannelNameType,
        data?: Observable<U> | U,
        params?: Record<string, unknown>,
    ): Observable<BaseCommunicationEvent<T>> {
        return of(getChannelInfo(channel)).pipe(
            // eslint-disable-next-line max-lines-per-function
            switchMap((channelInfo) => {
                // eslint-disable-next-line max-lines-per-function
                return new Observable<BaseCommunicationEvent<T>>((observer) => {
                    const handshakeDto = toTransferObject({
                        data: undefined,
                        params,
                        headers: { requestType: StreamRequestType.Initial },
                    });
                    const { requestId } = handshakeDto.headers;
                    const requestStream = new Subject<U>();
                    const dataStream = isObservable(data) ? data : new BehaviorSubject(data);

                    this.logger.info(`subscribe to ipcMain channel:'${channel}', requestId:'${requestId}'`);

                    const listener = (
                        event: OcRendererEvent,
                        response: TransferObjectResponse<ObserverObject<T>>,
                    ): void => {
                        if (requestId !== response.headers.requestId) {
                            return;
                        }

                        const { error, data } = fromTransferObject(response);

                        if (error) {
                            observer.error(error);
                            return;
                        }

                        if (data) {
                            const { nextValue, complete } = data;

                            if (complete) {
                                ocRenderer.removeListener(channelInfo.response, listenerId);
                                observer.complete();
                            } else {
                                observer.next(toBaseCommunicationEvent(nextValue as T));
                            }
                        }
                    };

                    const listenerId = ocRenderer.on(channelInfo.response, listener);

                    ocRenderer.send(channelInfo.request, handshakeDto);

                    const send = (value?: ObserverObject<U>, error?: TransferObjectError<T>): void => {
                        const dto = toTransferObject({
                            data: value,
                            params,
                            error,
                            requestId,
                            headers: { requestType: StreamRequestType.DataTransfer },
                        });
                        ocRenderer.send(channelInfo.request, dto);
                    };

                    requestStream.subscribe({
                        next: (value) => send({ nextValue: value }),
                        error: (error: TransferObjectError<T>) => send(undefined, error),
                        complete: () => {
                            const isListenerExist = ocRenderer.hasListener(channelInfo.response, listenerId);
                            if (isListenerExist) {
                                ocRenderer.removeListener(channelInfo.response, listenerId);
                                send({ complete: true });
                            }
                        },
                    });

                    const dataStreamSubscription = dataStream.subscribe(requestStream);

                    return (): void => {
                        this.logger.info(`unsubscribe from subscription to ipcMain channel:'${channel}'`);
                        observer.complete();
                        requestStream.complete();
                        dataStreamSubscription.unsubscribe();
                    };
                });
            }),
        );
    }

    public invoke<T, U = unknown>(
        channel: ChannelNameType,
        data?: U,
        params?: Record<string, unknown>,
    ): Observable<BaseCommunicationEvent<T>> {
        return of(getChannelInfo(channel)).pipe(
            switchMap((channelInfo) => {
                const dto = toTransferObject({ data, params });
                this.logger.info(`invoke ipcMain channel:'${channel}'`);
                return from(ocRenderer.invoke<TransferObjectResponse<T>>(channelInfo.request, dto));
            }),
            map((response) => {
                const { error, data } = fromTransferObject<T>(response);

                if (error) {
                    throw error;
                }

                return { data: data as T };
            }),
        );
    }
}

function getChannelInfo(channel: ChannelNameType): ChannelInfo {
    return {
        request: `${channel}-request`,
        response: `${channel}-response`,
    };
}

// May be we need to use transformers here instead of functions
function toTransferObject<T>({
    data,
    params = {},
    error,
    requestId,
    headers: requestHeaders,
}: {
    data: T;
    params?: Partial<TransferObjectRequest<T>>;
    error?: TransferObjectError<T>;
    requestId?: string;
    headers?: Omit<TransferObjectHeaders, 'requestId'>;
}): TransferObjectRequest<T> {
    // convert data and params to transfer object here (DTO between renderer and main)
    // probably some authorization work goes here (adding tokens or headers to DTO for example)

    const headers: TransferObjectHeaders = {
        ...requestHeaders,
        requestId: requestId || uuid(),
    };

    const dto: TransferObjectRequest<T> = {
        data: data,
        error,
        headers,
        ...params,
    };

    return dto;
}

// May be we need to use transformers here instead of functions
function fromTransferObject<T, TError = unknown>(
    response: TransferObjectResponse<T, TError>,
): CommunicationEvent<T, TError> {
    const { error, data } = response;

    if (error) {
        return {
            ...toBaseCommunicationEvent(data),
            error: new BaseResponseException(error),
        };
    }

    return toBaseCommunicationEvent(response.data);
}

// May be we need to use transformers here instead of functions
function toBaseCommunicationEvent<T>(data: T): BaseCommunicationEvent<T> {
    // convert transfer object to data here
    return { data };
}
