import { injectable } from 'inversify';

export enum BrowserStorageKey {
    LocalInfo = 'local-browser-info',
}

@injectable()
export class BrowserStorageService {
    private get storage(): Storage {
        return window.sessionStorage;
    }

    public get<T>(key: BrowserStorageKey): T | undefined {
        const value = this.storage.getItem(key);
        return value ? (JSON.parse(value) as T) : undefined;
    }

    public set<T>(key: BrowserStorageKey, value: T): T {
        this.storage.setItem(key, JSON.stringify(value));
        return value;
    }

    public remove(key: BrowserStorageKey): boolean {
        this.storage.removeItem(key);
        return true;
    }
}
