import { inject, injectable } from 'inversify';
import 'reflect-metadata';
import * as officeCompanionJS from '@wk/office-companion-js';
import { Locale } from '../locale';
import { DocumentState } from '../enum/enum';
import { factory } from '../configLog4J';
import { ICHCheckoutDocMetaType } from '../interfaces/operations';
import { IBannerInfo, IInputMeta } from '../interfaces/interface';
import { v4 as uuid } from 'uuid';
import { checkOut } from '../contextManager';
import { throwUnExpectedError } from '../utils/main.utils';
import { IOfficeService } from './office.service';

const log = factory.getLogger('BannerService');
export interface IBannerService {
    checkForBannerMessage(): Promise<void>;
    removeBanner(docInfo: string): void;
    bannerInfo: IBannerInfo;
    reopenBanner(): Promise<void>;
}
@injectable()
export class BannerService implements IBannerService {
    private _officeService: IOfficeService;
    bannerInfo: IBannerInfo = {};
    public hasBanner: boolean;
    constructor(@inject('OfficeService') officeService: IOfficeService) {
        this._officeService = officeService;
        this.bannerInfo = {};
        this.hasBanner = false;
        this.startListenToEvent();
    }

    public transformInputMetaData(metaValues: Record<string, unknown>): IInputMeta {
        // Message is type of unknown in ocjs api. Hence transforming appropriately.
        return {
            message: typeof metaValues.message === 'string' ? metaValues.message : '',
            title: typeof metaValues.title === 'string' ? metaValues.title : '',
            contextObj: metaValues.contextObj as ICHCheckoutDocMetaType,
            state: metaValues.state as DocumentState,
            isLatestVersion: typeof metaValues.isLatestVersion === 'boolean' ? metaValues.isLatestVersion : false,
        };
    }

    private showMessageBar = (meta: officeCompanionJS.FileMeta, fullName: string): void => {
        if (!meta?.inputMetadata || !meta.inputMetadata?.state) {
            log.debug('CL - no need to show message bar, conditions are not met' + meta);
            return;
        }
        log.debug('CL - showing message bar');
        const id = uuid();
        const buttons: officeCompanionJS.MessageBarButton[] = [
            {
                id: id,
                label: Locale.banner.checkout_label,
                status: Locale.banner.banner_loading_text,
            },
        ];
        const metaValues: IInputMeta = this.transformInputMetaData(meta.inputMetadata);
        const params: officeCompanionJS.MessageBarOptions = {
            message: metaValues.message,
            title: metaValues.title,
            icon: Locale.banner.icon,
            buttons: DocumentState.checkIn == metaValues.state && metaValues.isLatestVersion ? buttons : undefined,
        };

        const subscription = officeCompanionJS.MessageBar.show(params).subscribe({
            next: async (value) => {
                if (value.created) {
                    this.hasBanner = true;
                    if (metaValues && metaValues.contextObj) {
                        this.bannerInfo = {
                            documentPath: fullName,
                            docId: metaValues.contextObj.documentId,
                            subscription: subscription,
                        };
                        log.info(`CL - message bar has been opened: ${JSON.stringify(value.created)}`);
                    } else {
                        this.bannerInfo = { documentPath: fullName, subscription: subscription };
                        log.info(`CL - message bar has been opened: ${JSON.stringify(value.created)}`);
                    }
                }
                if (value.buttonClicked) {
                    log.debug(`CL - checkout for editing`);
                    await checkOut(metaValues.contextObj);
                    // Reopen banner if Checkout operation failed
                    if (
                        !(
                            (await this._officeService.isBusy()) ||
                            (await this._officeService.isFileExists(this._officeService.uniqueId))
                        )
                    ) {
                        await this.reopenBanner();
                    }
                }
            },
            error: (err: Error) => {
                log.warn('message bar click event subscribe ' + err.message);
            },
        });
    };

    public checkForBannerMessage = async (): Promise<void> => {
        log.info('OC | checkForBannerMessage');
        const isReadOnly = await this._officeService.isReadOnly();
        if (isReadOnly) {
            const fullName = await this._officeService.getCurrentDocumentFullName();
            try {
                const meta: officeCompanionJS.FileMeta = await officeCompanionJS.FileOperations.getMetadataByPath(
                    fullName,
                );
                log.info('CL - meta ' + JSON.stringify(meta));
                if (meta) {
                    this.showMessageBar(meta, fullName);
                }
            } catch (ex) {
                log.warn('CL - failed to get metadata path ' + ex);
                throwUnExpectedError(ex);
            }
        }
    };

    public removeBanner(docInfo: string): void {
        log.info('OC | Removing banner.');
        if (docInfo && (this.bannerInfo.documentPath === docInfo || this.bannerInfo.docId === docInfo)) {
            this.unsubscribeBanner();
        }
    }

    private unsubscribeBanner(): void {
        if (!this.bannerInfo?.subscription) {
            return;
        }

        this.bannerInfo.subscription?.unsubscribe();
        this.bannerInfo = {};
    }

    private startListenToEvent(): void {
        log.info('OC | Start listen to file operation event.');
        officeCompanionJS.ItemOperation.listen().subscribe(async (result) => {
            const { data } = result;
            if (!data) {
                log.info('OC | No data found on file operation event.');
                return;
            }
            if (data.itemOperationSaving || data.itemOperationClosed) {
                const path = data.itemOperationSaving?.itemName || data.itemOperationClosed?.itemName || '';
                if (path === this.bannerInfo.documentPath) {
                    this.removeBanner(path);
                }
            } else if (data.itemOperationOpened) {
                if (!this.bannerInfo.docId) {
                    this.checkForBannerMessage();
                }
            } else if (data.itemOperationSaved) {
                const isReadOnly = await this._officeService.isReadOnly();
                if (isReadOnly) {
                    return;
                }
                this.unsubscribeBanner();
            }
        });
    }

    public async reopenBanner(): Promise<void> {
        log.info('OC | Reopening banner.');
        if (this.bannerInfo.docId) {
            this.removeBanner(this.bannerInfo.docId);
            await this.checkForBannerMessage();
        }
    }
}
