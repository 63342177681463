import { SvgIconProps } from '@material-ui/core';
import React from 'react';
import WkEmailIcon from './wkEmailIcon';
import WkExcelIcon from './wkExcelIcon';
import WkFileIcon from './wkFileIcon';
import WkPdfIcon from './wkPdfIcon';
import WkPowerPointIcon from './wkPowerPointIcon';
import WkWordDocIcon from './wkWordDocIcon';

interface IWkAppFileIcon extends SvgIconProps {
    fileName?: string;
}

const WkAppFileIcon: React.FC<IWkAppFileIcon> = (props) => {
    const { fileName, ...svgProps } = props;
    if (fileName && fileName.length > 0) {
        const extension = fileName.split('.').pop();

        switch (extension) {
            case 'doc':
            case 'docx':
                return <WkWordDocIcon {...svgProps} />;
            case 'xls':
            case 'xlsx':
                return <WkExcelIcon {...svgProps} />;
            case 'ppt':
            case 'pptx':
                return <WkPowerPointIcon {...svgProps} />;
            case 'pdf':
                return <WkPdfIcon {...svgProps} />;
            case 'msg':
                return <WkEmailIcon {...svgProps} style={{ fill: '#007ac3' }} />;
            default:
                return <WkFileIcon {...svgProps} color="primary" />;
        }
    } else {
        return <WkFileIcon {...svgProps} color="primary" />;
    }
};

export default WkAppFileIcon;
