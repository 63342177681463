import { Observable } from 'rxjs';
import { injectable } from 'inversify';
import { ISecurityContextHandler } from '../interfaces/interface';
import { TokenManagment } from '@wk/office-companion-js';

@injectable()
export class SecurityOCContextHandler implements ISecurityContextHandler {
    supportedOperations = async (): Promise<string[]> => await ['securityOCContextHandler'];
    performOperation = async (): Promise<string[]> => await ['securityOCContextHandler'];
    getContextInfo = async (): Promise<string[]> => await ['securityOCContextHandler'];

    // TO DO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public setToken(id: string, secret: string, token: string): Observable<any> {
        return TokenManagment.setToken(id, secret, token);
    }
    // TO DO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getToken(id: string, secret: string): Observable<any> {
        return TokenManagment.getToken(id, secret);
    }
    // TO DO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public deleteToken(id: string, secret: string): Observable<any> {
        return TokenManagment.deleteToken(id, secret);
    }
}
