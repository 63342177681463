import { getLogger } from '@wk/elm-uui-common';
import { clearDefaultUrl, reloadUrl, setDefaultUrl } from '@wk/elm-uui-context-handler';

export class RouteManagementService {
    static async isSupported() {
        return navigator?.userAgent?.includes('Electron');
    }

    static async clearDefaultUrl() {
        try {
            await clearDefaultUrl().toPromise();
        } catch (ex) {
            getLogger('t360.RouteManagementService').error('Error: ', ex);
        }
    }

    static async setDefaultUrl(url: string) {
        try {
            await setDefaultUrl(url).toPromise();
        } catch (ex) {
            getLogger('t360.RouteManagementService').error('Error: ', ex);
        }
    }

    static async reloadUrl() {
        try {
            await reloadUrl().toPromise();
        } catch (ex) {
            getLogger('t360.RouteManagementService').error('Error: ', ex);
        }
    }
}
