import { injectable } from 'inversify';
import { IAuthorizationInfo, IAuthScheme } from '../../interfaces/authScheme/type';
import { IDMToken } from '../../interfaces/dmTokenHandler/type';

@injectable()
export class JRAdapterAccessToken implements IDMToken {
    authScheme: IAuthScheme;

    getAuthInfo = (): Promise<IAuthorizationInfo> => {
        return this.authScheme.getAuthData();
    };
}
