import { injectable } from 'inversify';
import { LoggerService } from '../../common/logger/logger.service';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SettingsInterface {}

@injectable()
export class SettingsService implements SettingsInterface {
    constructor(private logger: LoggerService) {}
}
