import { ITransformer } from './types';
import { ICHDownloadDocument } from '../interfaces/operations';
import 'reflect-metadata';
import { injectable } from 'inversify';
import { IDownloadDocument } from '@wk/elm-uui-doc-component';

@injectable()
export class CtxDownloadDocumentTransformer implements ITransformer {
    data!: ICHDownloadDocument;

    transform(): IDownloadDocument {
        const nodeObj = {} as IDownloadDocument;
        if (this.data.documentId) {
            nodeObj.docId = this.data.documentId;
        }
        nodeObj.entityContext = {
            name: this.data.associatedEntityName || '',
            id: this.data.associatedEntityId,
            type: this.data.associatedEntityType,
        };
        nodeObj.extendedProps = {};
        nodeObj.extendedProps.loggedInUser = this.data?.loggedInUser;
        if (this.data?.version) {
            nodeObj.extendedProps.version = this.data?.version;
        }
        return nodeObj;
    }
}
