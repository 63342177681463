import { CapabiltyEnum } from '../../enums/capability';
import { injectable } from 'inversify';
import { JRAdapterOperationPattern } from '../urlEntity/operationPattern';
import { ICheckIn_JR } from '../types';

@injectable()
export class JRAdapterCheckInUrlPattern extends JRAdapterOperationPattern {
    operationName = CapabiltyEnum.CHECKIN;

    async getUrl(nodeObj: ICheckIn_JR): Promise<string> {
        let baseUrl = await super.getUrl(nodeObj);
        baseUrl = baseUrl + '/' + nodeObj.docId + '?ignoreWarnings=true';
        return baseUrl;
    }
}
