import { IListScreenState, ISavedView, IListScreenPageData, IFolderedListBreadCrumb } from '../types';
import { ListScreenAction } from '../types';
import update from 'immutability-helper';

export const listScreenReducer = (state: IListScreenState, action: ListScreenAction): IListScreenState => {
    switch (action.type) {
        // INITIALIZE
        case 'InitializeListScreen': {
            return {
                ...state,
                isEmbeddedList: action.isEmbeddedList,
                mode: action.mode,
                metadataUrl: action.listScreenHistoryObject?.listMetadataUrl || action.metadataUrl,
                dataUrl: action.listScreenHistoryObject?.listDataUrl || action.dataUrl,
                metadata: action.metadata,
                listData: action.listData,
                quickSearchString: action.listScreenHistoryObject?.postObject?.['search-keywords'] || '',
                baselinePageData: action.listScreenHistoryObject?.baselinePageData || action.listData.page,
                parentItemInfo: action.listScreenHistoryObject?.parentItemInfo || action.parentItemInfo,
                breadCrumbs: action.listScreenHistoryObject?.breadCrumbs
                    ? action.listScreenHistoryObject.breadCrumbs
                    : action.metadata.isDocumentEntity
                    ? [
                          {
                              folderName:
                                  action.metadata.entityName.indexOf('Email') !== -1
                                      ? action.appResources.breadcrumbEmails
                                      : action.appResources.breadcrumbDocuments,
                              listMetadataUrl: action.metadataUrl,
                              listDataUrl: action.dataUrl,
                              parentItemInfo: action.parentItemInfo,
                          },
                      ]
                    : [],
            };
        }
        // REFRESH
        case 'RefreshListScreen':
            return {
                ...state,
                metadata: action.metadata || state.metadata,
                metadataUrl: action.metadataUrl || state.metadataUrl,
                listData: action.listData,
                dataUrl: action.listDataUrl || state.dataUrl,
                parentItemInfo: action.parentItemInfo || state.parentItemInfo,
                // set baselinePageData here since it's been cleared prior to refresh
                baselinePageData: state.baselinePageData || action.listData.page,
                resetToDefaultDialogOpen: false,
            };
        case 'AddBreadCrumb':
            return {
                ...state,
                breadCrumbs: addBreadCrumb(state.breadCrumbs, action.breadCrumb),
            };
        case 'ClickBreadCrumb': {
            return {
                ...state,
                breadCrumbs: loadBreadCrumb(state.breadCrumbs, action.breadCrumb),
            };
        }
        // ENABLE/DISABLE ROW
        case 'DisableRow':
            return disableRow(state, action.id);
        case 'EnableRow':
            return enableRow(state, action.id);
        // CHECKBOXES
        case 'ToggleCheckedRow':
            return toggleCheckedRow(state, action.id);
        case 'ToggleCheckedRowsOn':
            return toggleCheckedRowsOn(state, action.ids);
        case 'ToggleCheckedRowsOff':
            return toggleCheckedRowsOff(state, action.ids);
        case 'ClearAllCheckedRows':
            return { ...state, checkedRows: [] };
        // SAVED VIEWS
        case 'AddAndSelectSavedView':
            return addAndSelectSavedView(state, action.savedView);
        case 'SetBaselinePageData':
            return setBaselinePageData(state, action.pageData);
        case 'ClearBaselinePageData':
            return { ...state, baselinePageData: undefined };
        case 'UpdateMetadataSavedView':
            return updateMetadataSavedView(state, action.existingSavedViewIndex, action.savedView);
        case 'UpdateSavedView':
            return updateSavedView(state, action.existingSavedViewIndex, action.savedView);
        case 'UpdateAndSelectSavedView':
            return updateAndSelectSavedView(state, action.existingSavedViewIndex, action.savedView);
        case 'RemoveSavedView':
            return removeSavedView(state, action.existingSavedViewIndex);
        // DIALOGS
        case 'OpenListScreenEditDialog':
            return { ...state, listScreenEditDialogOpen: true, listScreenEditDialogKey: new Date().getTime() };
        case 'CloseListScreenEditDialog':
            return { ...state, listScreenEditDialogOpen: false };
        case 'OpenEditsAppliedSaveAsDialog':
            return {
                ...state,
                editsAppliedSaveAsDialogOpen: true,
                editsAppliedSaveAsDialogKey: new Date().getTime(),
            };
        case 'CloseEditsAppliedSaveAsDialog':
            return { ...state, editsAppliedSaveAsDialogOpen: false };
        case 'OpenEditsAppliedSaveDialog':
            return { ...state, editsAppliedSaveDialogOpen: true };
        case 'CloseEditsAppliedSaveDialog':
            return { ...state, editsAppliedSaveDialogOpen: false };
        case 'OpenTabOptionsRenameDialog':
            return { ...state, tabOptionsRenameDialogOpen: true };
        case 'CloseTabOptionsRenameDialog':
            return { ...state, tabOptionsRenameDialogOpen: false };
        case 'OpenResetToDefaultDialog':
            return { ...state, resetToDefaultDialogOpen: true };
        case 'CloseResetToDefaultDialog':
            return { ...state, resetToDefaultDialogOpen: false };
        case 'OpenTabOptionsRemoveTabDialog':
            return { ...state, tabOptionsRemoveTabDialogOpen: true };
        case 'CloseTabOptionsRemoveTabDialog':
            return { ...state, tabOptionsRemoveTabDialogOpen: false };
        case 'QuickFileVisibleForRows':
            return { ...state, quickFileVisibleForRows: action.quickFileVisibleForRows };
        case 'SetQuickSearch':
            return { ...state, quickSearchString: action.quickSearchString };
        default:
            return state;
    }
};

export const addBreadCrumb = (
    currentBreadCrumbs: IFolderedListBreadCrumb[],
    newBreadCrumb: IFolderedListBreadCrumb,
): IFolderedListBreadCrumb[] => {
    return currentBreadCrumbs.concat([newBreadCrumb]);
};

export const loadBreadCrumb = (
    currentBreadCrumbs: IFolderedListBreadCrumb[],
    breadCrumbToLoad: IFolderedListBreadCrumb,
): IFolderedListBreadCrumb[] => {
    return currentBreadCrumbs.slice(
        0,
        currentBreadCrumbs.findIndex((bc) => bc.folderName === breadCrumbToLoad.folderName) + 1,
    );
};

const addSavedView = (state: IListScreenState, newView: ISavedView): IListScreenState => {
    return update(state, { metadata: { savedViews: { $push: [newView] } } });
};

export const addAndSelectSavedView = (state: IListScreenState, savedView: ISavedView): IListScreenState => {
    const newState = updateSelectedSavedView(addSavedView(state, savedView), savedView);
    return setBaselinePageData(newState, newState.listData!.page);
};

const setBaselinePageData = (state: IListScreenState, pageData: IListScreenPageData): IListScreenState => {
    return { ...state, baselinePageData: pageData };
};

export const updateMetadataSavedView = (
    state: IListScreenState,
    existingSavedViewIndex: number,
    savedView: ISavedView,
): IListScreenState => {
    const newState = update(state, {
        metadata: { savedViews: { [existingSavedViewIndex]: { $set: savedView } } },
    });
    return newState;
};

export const updateSavedView = (
    state: IListScreenState,
    existingSavedViewIndex: number,
    savedView: ISavedView,
): IListScreenState => {
    const newState = updateMetadataSavedView(state, existingSavedViewIndex, savedView);
    return setBaselinePageData(newState, newState.listData!.page);
};

export const updateAndSelectSavedView = (
    state: IListScreenState,
    existingSavedViewIndex: number,
    savedView: ISavedView,
): IListScreenState => {
    const newState = updateSavedView(state, existingSavedViewIndex, savedView);
    return updateSelectedSavedView(newState, savedView);
};

const removeSavedView = (state: IListScreenState, savedViewIndexToRemove: number): IListScreenState => {
    if (!state.metadata || state.metadata.savedViews[savedViewIndexToRemove].isSystemDefault) {
        return state;
    }

    let removingSelectedView = false;
    let savedViewToSelect = state.metadata.savedViews.find((sv) => sv.isSystemDefault)!;

    if (state.listData?.page.savedView.id == state.metadata.savedViews[savedViewIndexToRemove].id) {
        removingSelectedView = true;
        if (!state.listData?.page.savedView.isUserDefault) {
            if (state.metadata.savedViews.find((sv) => sv.isUserDefault)) {
                savedViewToSelect = state.metadata.savedViews.find((sv) => sv.isUserDefault)!;
            }
        }
    }

    let newState = update(state, { metadata: { savedViews: { $splice: [[savedViewIndexToRemove, 1]] } } });

    if (removingSelectedView) {
        const newState2 = updateSelectedSavedView(newState, savedViewToSelect);
        newState = setBaselinePageData(newState2, newState2.listData!.page);
    }

    return newState;
};

const toggleCheckedRowsOn = (state: IListScreenState, ids: number[]): IListScreenState => {
    let newState = state;
    ids.map((id) => {
        newState = toggleCheckedRowOn(newState, id);
    });
    return newState;
};

const toggleCheckedRowOn = (state: IListScreenState, id: number): IListScreenState => {
    const index = state.checkedRows.indexOf(id);
    if (index === -1) {
        return { ...state, checkedRows: [...state.checkedRows, id] };
    } else {
        return state;
    }
};

const toggleCheckedRowsOff = (state: IListScreenState, ids: number[]): IListScreenState => {
    let newState = state;
    ids.map((id) => {
        newState = toggleCheckedRowOff(newState, id);
    });
    return newState;
};

const toggleCheckedRowOff = (state: IListScreenState, id: number): IListScreenState => {
    const index = state.checkedRows.indexOf(id);
    if (index > -1) {
        return {
            ...state,
            checkedRows: [...state.checkedRows.slice(0, index), ...state.checkedRows.slice(index + 1)],
        };
    } else {
        return state;
    }
};

const toggleCheckedRow = (state: IListScreenState, id: number): IListScreenState => {
    const index = state.checkedRows.indexOf(id);
    if (index > -1) {
        return {
            ...state,
            checkedRows: [...state.checkedRows.slice(0, index), ...state.checkedRows.slice(index + 1)],
        };
    } else {
        return { ...state, checkedRows: [...state.checkedRows, id] };
    }
};

const disableRow = (state: IListScreenState, id: number): IListScreenState => {
    const index = state.disabledRows.indexOf(id);
    if (index === -1) {
        return { ...state, disabledRows: [...state.disabledRows, id] };
    } else {
        return state;
    }
};

const enableRow = (state: IListScreenState, id: number): IListScreenState => {
    const index = state.disabledRows.indexOf(id);
    if (index > -1) {
        return {
            ...state,
            disabledRows: [...state.disabledRows.slice(0, index), ...state.disabledRows.slice(index + 1)],
        };
    } else {
        return state;
    }
};

const updateSelectedSavedView = (state: IListScreenState, savedViewToSelect: ISavedView): IListScreenState => {
    return update(state, {
        listData: {
            page: {
                savedView: {
                    $set: {
                        id: savedViewToSelect.id,
                        isEditable: savedViewToSelect.isEditable,
                        isPublic: savedViewToSelect.isPublic,
                        isSystemDefault: savedViewToSelect.isSystemDefault,
                        isUserDefault: savedViewToSelect.isUserDefault,
                        name: savedViewToSelect.name,
                    },
                },
            },
        },
    });
};
