import { IUuiGlobalState, UUIReduxAction } from './types';

export const globalStateReducer = (state: IUuiGlobalState, action: UUIReduxAction): IUuiGlobalState => {
    switch (action.type) {
        case 'SetPageTitle':
            return { ...state, pageTitle: action.title };
        case 'SetActivelyDraggedFilesCount':
            return { ...state, activelyDraggedFilesCount: action.numFiles };
        case 'SetDraggedFileIsOverValidDropTarget':
            return { ...state, draggedFileIsOverValidDropTarget: action.isOverValidDropTarget };
        case 'SetActiveScreenId':
            return { ...state, activeScreenId: action.activeScreenId };
    }
    return state;
};
