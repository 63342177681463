import { ApplicationType, OcExposeInfo } from '../globals/ocInfo';

export class BrowserUtilitiesService implements OcExposeInfo {
    /**
     * returns the context where current window is opened
     */
    public applicationType(): Promise<ApplicationType> {
        return window.ocInfo?.applicationType();
    }

    /**
     * returns true or false based on the context of if you are in a webbrowser or OfficeService Companion
     */
    public isElectron(): boolean {
        {
            // Renderer process
            if (window?.process?.['type'] === 'renderer') {
                return true;
            }

            // Main process
            if (typeof process !== 'undefined' && process?.versions?.['electron']) {
                return true;
            }

            // Detect the user agent when the `nodeIntegration` option is set to true
            return navigator?.userAgent?.includes('Electron');
        }
    }
}
