import { IAuthScheme, IAuthorizationInfo, IAuthData } from '../interfaces/authScheme/type';
import { injectable } from 'inversify';

@injectable()
export class BasicAuthentication implements IAuthScheme {
    getAuthData: () => Promise<IAuthorizationInfo> = () => {
        return new Promise((resolve, _reject) => {
            resolve({
                token: 'Basic ' + new Buffer(this.authData.username + ':' + this.authData.password).toString('base64'),
            });
        });
    };

    authData: IAuthData;
}
