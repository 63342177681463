import { appsMenuReducer } from './appsMenu.reducer';
import { uiReducer } from './ui.reducer';
import { AppStore } from './types';
import { UUIReduxAction } from './types';
import { merge } from 'lodash';

const rootReducer = (state: AppStore | undefined, action: UUIReduxAction): AppStore => {
    if (!state) {
        throw Error('No Initial Redux Store State provided');
    }
    if (action.type === 'SetInitialReduxState') {
        return merge(state, action.state);
    }
    return { ...state, appsMenu: appsMenuReducer(state.appsMenu, action), ui: uiReducer(state.ui, action) };
};

export default rootReducer;
