import { ScreenHandler } from './screenHandler/screenHandler';
import { interfaces } from 'inversify';
import { UIContextHandler } from './uiContextHandler';
import { OCContextHandler } from './ocContextHandler';
import {
    ITransformer,
    ICtxtOperationTransformer,
    ITransformerNew,
    CtxtOperationTransformerNew,
    ICtxtOperationTransformerNew,
    IValidationTransformer,
} from './transform/types';
import {
    IContextHandler,
    IDocContextHandler,
    IQueueCapability,
    INavigationContextHandler,
    IQueryStringAppender,
    IQueueController,
    ISecurityContextHandler,
    IQueueSubscribed,
} from './interfaces/interface';
import { CtxtOperationTransformer } from './transform/types';
import { CtxtCheckOutTransformer } from './transform/ctxtCheckOutTransformer';
import { CtxCancelCheckOutTransformer } from './transform/ctxCancelCheckoutTransformer';
import { CtxAddTransformer } from './transform/ctxAddDocumentTransformer';
import { CtxDocumentMetadataTransformer } from './transform/ctxDocumentMetadataTransformer';
import { CtxDocumentMetadataFileNameTransformer } from './transform/ctxDocumentMetadataFileNameTransformer';
import { CtxDownloadDocumentTransformer } from './transform/ctxDownloadDocumentTransformer';
import { CtxAddFolderTransformer } from './transform/ctxAddFolderTransformer';
import { CtxCheckInDocumentTransformer } from './transform/ctxCheckInDocumentTransformer';
import { CtxShowDocumentTransformer } from './transform/ctxShowDocumentTransformer';
import { CtxDeleteDocumentTransformer } from './transform/ctxDeleteDocumentTransformer';
import { CtxDeleteFolderTransformer } from './transform/ctxDeleteFolderTransformer';
import { CtxAddEmailTransformer } from './transform/ctxAddEmailTransformer';
import { CtxDeleteEmailTransformer } from './transform/ctxDeleteEmailTransformer';
import { CtxRenameFolderTransformer } from './transform/ctxRenameFolder';
import { CtxEditFolderTransformer } from './transform/ctxEditFolderTransformer';
import { CtxRenameDocumentTransformer } from './transform/ctxRenameDocument';
import { CtxEditDocumentTransformer } from './transform/ctxEditDocumentTransformer';
import {
    DocumentsService,
    IDocumentsService,
    MessageService,
    EventContextHandler,
    IEventService,
    IOfficeService,
    OfficeService,
    IOfficeDocumentService,
    WordService,
    OutlookService,
    ExcelService,
    PowerPointService,
    IOutlookService,
    IEmailService,
    EmailService,
    DocumentUploadService,
    CheckInService,
    CHMessagingService,
    DocumentSummaryCheckInService,
    DragAndDropService,
    ICHProperties,
    CHProperties,
    AutoUpdateService,
    RouterService,
    ConnectionService,
    BannerService,
    DialogService,
    BrowserCommunicationService,
    IBrowserCommunicationService,
} from './services';
import { QueueCapability } from './capability/queueCapability';
import { NavigationOCContextHandler } from './navigationOCContextHandler';
import { TokenQueryStringAppender } from './tokenQueryStringAppender';
import { QueueController } from './queueController';
import { SecurityOCContextHandler } from './securityContextHandler/securityOCContextHandler';
import { SecurityUIContextHandler } from './securityContextHandler/securityUIContextHandler';
import { IDocServiceRequest } from '@wk/elm-uui-doc-component';
import { IContextInputType } from './interfaces/operations';
import { CheckoutDiscardService } from './services';
import { ICHMessagingInterface } from './services/interface';
import { QueueSubscribed } from './capability/queueSubscribed';
import { IAutoUpdate } from './services/interface/autoUpdate.interface';
import { CtxValidateAddDocumentTransformer } from './transform/ctxValidateAddDocumentTransformer';
import { CtxValidateCheckInDocumentTransformer } from './transform/ctxValidateCheckInDocumentTransformer';
import { CtxValidateAddFolderTransformer } from './transform/ctxValidateAddFolderTransformer';
import { CtxValidateEditDocumentTransformer } from './transform/ctxValidateEditDocumentTransformer';
import { CtxValidateEditFolderTransformer } from './transform/ctxValidateEditFolderTransformer';
import { getQueueCapability } from './capability';
import { myContainer } from './core';

myContainer.bind<IContextHandler>('UIContextHandler').to(UIContextHandler);
myContainer.bind<IContextHandler>('OCContextHandler').to(OCContextHandler);

myContainer.bind<MessageService>('MessageService').to(MessageService).inSingletonScope();
myContainer.bind<IOfficeService>('OfficeService').to(OfficeService).inSingletonScope();
myContainer.bind<ScreenHandler>('ScreenHandler').to(ScreenHandler);

myContainer.bind<IOfficeDocumentService>('WordService').to(WordService).inSingletonScope();
myContainer.bind<IOfficeDocumentService>('ExcelService').to(ExcelService).inSingletonScope();
myContainer.bind<IOfficeDocumentService>('PowerPointService').to(PowerPointService).inSingletonScope();
myContainer.bind<IOutlookService>('OutlookService').to(OutlookService).inSingletonScope();

myContainer.bind<CheckoutDiscardService>('CheckoutDiscardService').to(CheckoutDiscardService);

myContainer.bind<IEmailService>('EmailService').to(EmailService);
myContainer.bind<DocumentUploadService>('DocumentUploadService').to(DocumentUploadService);
myContainer.bind<CheckInService>('CheckInService').to(CheckInService);
myContainer.bind<DocumentSummaryCheckInService>('DocumentSummaryCheckInService').to(DocumentSummaryCheckInService);
myContainer.bind<DragAndDropService>('DragAndDropService').to(DragAndDropService);
myContainer.bind<ICHMessagingInterface>('CHMessagingService').to(CHMessagingService).inSingletonScope();
myContainer.bind<ICHProperties>('PropsService').to(CHProperties);
myContainer.bind<IAutoUpdate>('AutoUpdateService').to(AutoUpdateService);
myContainer.bind<ConnectionService>('ConnectionService').to(ConnectionService);
myContainer.bind<IDocumentsService>('DocumentsService').to(DocumentsService).inSingletonScope();

myContainer.bind<ICtxtOperationTransformer>('CtxRenameFolderTransformer').to(CtxRenameFolderTransformer);
myContainer.bind<ICtxtOperationTransformer>('CtxRenameDocumentTransformer').to(CtxRenameDocumentTransformer);

myContainer.bind<ICtxtOperationTransformer>('CtxOperationTransformer').to(CtxtOperationTransformer);

myContainer
    .bind<ICtxtOperationTransformerNew<IContextInputType, IDocServiceRequest>>('CtxOperationTransformerNew')
    .to(CtxtOperationTransformerNew);

myContainer.bind<IQueueSubscribed>('QueueSubscribed').to(QueueSubscribed);

myContainer.bind<BannerService>('BannerService').to(BannerService).inSingletonScope();
myContainer.bind<ITransformer>('CtxCheckOutTransformer').to(CtxtCheckOutTransformer);
myContainer.bind<ITransformer>('CtxCheckInDocumentTransformer').to(CtxCheckInDocumentTransformer);
myContainer.bind<ITransformer>('CtxShowDocumentTransformer').to(CtxShowDocumentTransformer);
myContainer.bind<ITransformer>('CtxEditDocumentTransformer').to(CtxEditDocumentTransformer);

myContainer.bind<ITransformer>('CtxDeleteDocumentTransformer').to(CtxDeleteDocumentTransformer);

myContainer.bind<ITransformer>('CtxDeleteFolderTransformer').to(CtxDeleteFolderTransformer);
myContainer.bind<ITransformer>('CtxEditFolderTransformer').to(CtxEditFolderTransformer);

myContainer.bind<ITransformer>('CtxAddEmailTransformer').to(CtxAddEmailTransformer);

myContainer.bind<ITransformer>('CtxDeleteEmailTransformer').to(CtxDeleteEmailTransformer);
myContainer.bind<ITransformer>('CtxAddFolderTransformer').to(CtxAddFolderTransformer);
myContainer.bind<ITransformer>('CtxAddDocumentTransformer').to(CtxAddTransformer);

myContainer.bind<ITransformer>('CtxDownloadDocumentTransformer').to(CtxDownloadDocumentTransformer);
myContainer.bind<ITransformer>('CtxDocumentMetadataTransformer').to(CtxDocumentMetadataTransformer);
myContainer.bind<IValidationTransformer>('CtxValidateAddDocumentTransformer').to(CtxValidateAddDocumentTransformer);
myContainer
    .bind<IValidationTransformer>('CtxValidateCheckInDocumentTransformer')
    .to(CtxValidateCheckInDocumentTransformer);
myContainer.bind<IValidationTransformer>('CtxValidateAddFolderTransformer').to(CtxValidateAddFolderTransformer);
myContainer.bind<IValidationTransformer>('CtxValidateEditFolderTransformer').to(CtxValidateEditFolderTransformer);
myContainer.bind<IValidationTransformer>('CtxValidateEditDocumentTransformer').to(CtxValidateEditDocumentTransformer);
myContainer.bind<ITransformer>('CtxDocumentMetadataFileNameTransformer').to(CtxDocumentMetadataFileNameTransformer);

myContainer.bind<ITransformer>('CtxCancelCheckOutTransformer').to(CtxCancelCheckOutTransformer);

myContainer.bind<IQueueCapability>('QueueCapability').to(QueueCapability).inSingletonScope();

myContainer.bind<IQueueController>('QueueController').to(QueueController).inSingletonScope();

myContainer
    .bind<interfaces.Provider<IDocContextHandler>>('Provider<IContextHandler>')
    .toProvider<IDocContextHandler>((context) => {
        return (named: string) => {
            const contextHandler = context.container.get<IDocContextHandler>(named);

            return async () => {
                contextHandler.queueCapability = await getQueueCapability();

                contextHandler.queueController = myContainer.get<IQueueController>('QueueController');

                return contextHandler;
            };
        };
    });

myContainer
    .bind<interfaces.Factory<ITransformerNew<IContextInputType, IDocServiceRequest>>>('Factory<ITransformerNew>')
    .toFactory<ITransformer>((context) => {
        return (operation: string, nodeObj: IContextInputType) => {
            const transformerObj = context.container.get<ITransformerNew<IContextInputType, IDocServiceRequest>>(
                'Ctx' + operation + 'Transformer',
            );
            transformerObj.data = nodeObj;
            return transformerObj;
        };
    });

myContainer.bind<INavigationContextHandler>('NavigationOCContextHandler').to(NavigationOCContextHandler);
myContainer.bind<IQueryStringAppender>('TokenQueryStringAppender').to(TokenQueryStringAppender);
myContainer.bind<ISecurityContextHandler>('SecurityOCContextHandler').to(SecurityOCContextHandler);
myContainer.bind<ISecurityContextHandler>('SecurityUIContextHandler').to(SecurityUIContextHandler);
myContainer.bind<RouterService>('RouterService').to(RouterService);
myContainer.bind<DialogService>('DialogService').to(DialogService);
myContainer.bind<IEventService>('EventContextHandler').to(EventContextHandler).inSingletonScope();
myContainer
    .bind<IBrowserCommunicationService>('BrowserCommunicationService')
    .to(BrowserCommunicationService)
    .inSingletonScope();

export { myContainer as mainContainer };
