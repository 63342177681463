import { ITransformer } from './types';
import { IDocumentMetadataFileName } from '@wk/elm-uui-doc-component';
import 'reflect-metadata';
import { injectable } from 'inversify';
import { ICHDocumentMetadataFileName } from '../interfaces/operations';

@injectable()
export class CtxDocumentMetadataFileNameTransformer implements ITransformer {
    data!: ICHDocumentMetadataFileName;

    transform(): IDocumentMetadataFileName {
        const nodeObj = {} as IDocumentMetadataFileName;
        const regExToRemoveStartSpaces = /^\s+/g;
        if (this.data?.documentId) {
            nodeObj.docId = this.data.documentId;
        }

        if (this.data?.folderId) {
            nodeObj.documentFolderId = this.data.folderId;
        }

        if (this.data?.name) {
            nodeObj.documentName = this.data.name.replace(regExToRemoveStartSpaces, '');
        }

        if (this.data?.fileName) {
            nodeObj.fileName = this.data.fileName.replace(regExToRemoveStartSpaces, '');
        }

        nodeObj.entityContext = {
            name: this.data.associatedEntityName || '',
            id: this.data.associatedEntityId,
            type: this.data.associatedEntityType,
        };

        const extendedProps = <IDocumentMetadataFileName['extendedProps']>{};
        if (this.data?.version) {
            extendedProps.version = this.data?.version;
        }
        nodeObj.extendedProps = extendedProps;
        return nodeObj;
    }
}
