import { injectable } from 'inversify';
import { map, Observable } from 'rxjs';
import { baseCommunicationService } from '@wk/office-companion-js-common';

enum MessageBarAction {
    OpenTwoWayStream = 'open-two-way-stream',
    OpenOneWayStream = 'open-one-way-stream',
}

export interface StreamDemoInterface {
    /**
     * Opens two way stream for demo purpose.
     *
     * @param params Demo Options stream send to ipc main
     *
     * @returns Observable Slightly changed Demo Option data
     */
    openTwoWayStream(requestStream: Observable<StreamDemoOptions>): Observable<StreamDemoOptions>;

    /**
     * Opens one way stream for demo purpose.
     *
     * @param params Demo Options send to ipc main
     *
     * @returns Observable Slightly changed Demo Option data
     */
    openOneWayStream(params: StreamDemoOptions): Observable<StreamDemoOptions>;
}

export interface StreamDemoOptions {
    message: string;
}

@injectable()
export class StreamDemoService implements StreamDemoInterface {
    public openTwoWayStream(requestStream: Observable<StreamDemoOptions>): Observable<StreamDemoOptions> {
        const res = baseCommunicationService
            .on<StreamDemoOptions>(MessageBarAction.OpenTwoWayStream, requestStream)
            .pipe(
                map(({ data }) => {
                    return data;
                }),
            );

        return res;
    }

    public openOneWayStream(params: StreamDemoOptions): Observable<StreamDemoOptions> {
        const res = baseCommunicationService
            .on<StreamDemoOptions>(MessageBarAction.OpenOneWayStream, params)
            .pipe(map(({ data }) => data));

        return res;
    }
}
