import { injectable } from 'inversify';
import { CapabiltyEnum } from './enum/enum';
import { IQueueConfig, IQueueController } from './interfaces/interface';

@injectable()
export class QueueController implements IQueueController {
    [CapabiltyEnum.ADD_DOCUMENT]: boolean;
    [CapabiltyEnum.ADD_EMAIL]: boolean;
    [CapabiltyEnum.ADD_FOLDER]: boolean;
    [CapabiltyEnum.CANCEL_CHECKOUT]: boolean;
    [CapabiltyEnum.CHECKIN_DOCUMENT]: boolean;
    [CapabiltyEnum.CHECKOUT]: boolean;
    [CapabiltyEnum.DELETE]: boolean;
    [CapabiltyEnum.DELETE_DOCUMENT]: boolean;
    [CapabiltyEnum.DELETE_EMAIL]: boolean;
    [CapabiltyEnum.DELETE_EMAIL_FOLDER]: boolean;
    [CapabiltyEnum.DELETE_FOLDER]: boolean;
    [CapabiltyEnum.DOCUMENT_METADATA]: boolean;
    [CapabiltyEnum.DOWNLOAD_DOCUMENT]: boolean;
    [CapabiltyEnum.RENAME_DOCUMENT]: boolean;
    [CapabiltyEnum.RENAME_FOLDER]: boolean;

    setQueueController(queueConfig: IQueueConfig): void {
        for (const config in queueConfig) {
            Object.defineProperty(this, config, {
                value: queueConfig[config],
                configurable: true,
            });
        }
    }
}
