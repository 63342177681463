export enum ApplicationType {
    Outlook = 'OUTLOOK',
    Word = 'WORD',
    Excel = 'EXCEL',
    PowerPoint = 'POWER_POINT',
    TrayApp = 'TRAY',
}

export interface OcExposeInfo {
    applicationType: () => Promise<ApplicationType>;
}
