import { DOCUMENT_FILE_SIZE_EXCEEDED, EMAIL_FILE_SIZE_EXCEEDED } from '../constants';
import { DCValidationError } from '../customError/docComponentError';
import { IValidationInfo } from '../dmInfoHandler/types';

export class ValidationUtils {
    getFileSize = (
        validationInfo: IValidationInfo,
        extendedProps: {
            isEmailMessage?: string;
            isVersionable?: string;
            isMajorVersion?: string;
            comment?: string; // T360
            contentType?: string; // T360
            keepCheckedOut?: boolean;
            emailSubject?: string;
        },
    ): number => {
        if (extendedProps.emailSubject != undefined) {
            return validationInfo.emailFileSizeLimit;
        }
        return validationInfo.documentFileSizeLimit;
    };

    getValidationErrorMessage = (
        extendedProps: {
            emailSubject?: string;
        },
        fileSize: number,
    ): string => {
        const fileSizeInMb = (fileSize / (1024 * 1024)).toFixed();
        if (extendedProps.emailSubject != undefined) {
            return EMAIL_FILE_SIZE_EXCEEDED.replace('{fileSize}', fileSizeInMb);
        }
        return DOCUMENT_FILE_SIZE_EXCEEDED.replace('{fileSize}', fileSizeInMb);
    };

    checkPathLength = (folderPath: string, validationInfo: IValidationInfo): boolean => {
        if (
            validationInfo.entityDefaultLength.toString() !== '' &&
            folderPath.length > validationInfo.entityDefaultLength
        ) {
            throw new DCValidationError(
                'Document Path Length exceeds' + validationInfo.entityDefaultLength + 'characters',
            );
        }
        return true;
    };

    checkSpecialCharacters = (fileName: string, validationInfo: IValidationInfo): boolean => {
        const matchedChars = validationInfo.specialCharacterNotAllowed.split('').filter((str) => {
            const regStr = new RegExp('\\' + str);
            return fileName.search(regStr) !== -1;
        });
        if (matchedChars.length > 1) {
            throw new DCValidationError('Document Name contains special characters');
        }
        return true;
    };

    checkDocumentFileSize = (fileSize: number, defaultfileSize: number): boolean => {
        // In case of  zero we assume no restriction and we do not validate
        if (defaultfileSize == 0) {
            return true;
        } else if (defaultfileSize != null && fileSize > defaultfileSize) {
            return false;
        }
        return true;
    };

    checkFileType = (fileName: string, validationInfo: IValidationInfo): boolean => {
        const file = fileName.split('.');
        const fileType = file[file.length - 1];
        if (
            validationInfo.fileTypeNotAllowed.length > 1 &&
            validationInfo.fileTypeNotAllowed.find((elem) => elem === fileType)
        ) {
            throw new DCValidationError('File type is not allowed');
        }
        return true;
    };

    checkNoData = (data: string): boolean => {
        if (data === null || data === '') {
            throw new Error('File to check In is empty');
        }
        return true;
    };

    checkVersionable = (isVersionable: boolean): boolean => {
        if (isVersionable === null) {
            throw new Error('IsVersionable value is not set');
        }
        return true;
    };

    checkMajorVersion = (isVersionable: boolean, majorVersion: boolean): boolean => {
        if (isVersionable && majorVersion === null) {
            throw new Error('IsMajorVersion value is not set');
        }
        return true;
    };

    checkIsEmail = (isEmailMessage: boolean): boolean => {
        if (isEmailMessage) {
            throw new DCValidationError(
                'Unable to undo checkout of e-mails and e-mail folders from the main Documents list page',
            );
        }
        return true;
    };

    checkIsEligibleUser = (isEligible: boolean): boolean => {
        if (!isEligible) {
            throw new DCValidationError('User does not have permission');
        }
        return true;
    };

    checkUserAccess = (userAccess: string): boolean => {
        if (userAccess === 'EDIT') {
            return true;
        } else {
            throw new DCValidationError('User does not have permission');
        }
    };
}
