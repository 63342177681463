import {
    getContext,
    getContextEnvInfo,
    getNavigationContextHander,
    getSecurityContextHandler,
    getEventContextHandler,
    getCHMessagingServiceContextHandler,
    getAutoUpdateServiceContextHandler,
    getRouterServiceContextHandler,
    getBannerService,
} from './contextHandler';

import {
    ICHCancelCheckout,
    ICHAddFolder,
    ICHDeleteDocument,
    ICHDeleteEmail,
    ICHRenameFolder,
    ICHRenameDocument,
    ICHDeleteDocMetaType,
    ICHCheckoutDocMetaType,
    ICHDownloadDocMetaType,
    ICHCancelCheckoutDocMetaType,
    ICHQuickFileIndicator,
    ICHUploadType,
    ICHUploadResponse,
    ICHEditDocument,
    ICHEditFolder,
    ICHProps,
    ICHResponse,
    ICHHelpUrlResponse,
} from './interfaces/operations';
import { CapabiltyEnum, EventType, CHSupportedDocumentTypesEnum } from './enum/enum';
// import { IQueueServiceInterface } from '@wk/elm-uui-queuemanager';
// import { buildHelpFileUrl, getSecretKey } from '@wk/elm-uui-doc-component';
import { buildHelpFileUrl, ERROR_MESSAGE_TO_HANDLE_DATA_INTERNALLY } from '@wk/elm-uui-doc-component';
// import { IQueueConfig, IQueueController } from './interfaces/interface';
// import { mainContainer } from './inversify.config';
import { QueueConfig } from './config';
import { IEvent, IQueuePropItem } from './services';
import {
    // getDatabaseName,
    // getQueueCapability,
    // getQueueManagerObj,
    getSupportedOperationName,
    handleUnExpectedError,
    openHelpDocument,
} from './utils/main.utils';
import { ContextIndicatorEnum } from './enum/applicationContextUI.enum';
import { Observable, Subscription } from 'rxjs';
import { IEventCallback } from './services/interface';
import { CHMessageData } from './services/interface/message.interface';
import { CHAutoUpdateStatus } from './services/interface/autoUpdate.interface';
import { v4 as uuid } from 'uuid';
import { factory } from './configLog4J';
import { setQueueControl } from './capability';
// import { IQueueConfig, IQueueController } from './interfaces/interface';
import { Container } from 'inversify';
// import { getDatabaseName } from './utils/database.utils';
// import { getQueueManagerObj } from './capability';
const usageLog = factory.getLogger('UsageLogging');
const log = factory.getLogger('Context Manager');

export * from './enum/enum';

export * from './services/interface';

export * from './interfaces/operations';

export { IEvent } from './services';

export { ContextIndicatorEnum } from './enum/applicationContextUI.enum';

export { IQueueItem, QueueItemStatus, IQueueServiceInterface } from '@wk/elm-uui-queuemanager';

export { Subscription };

export { getQueue, setQueueControl } from './capability';

// export const setQueueControl = (queueConfig: IQueueConfig): void => {
//     const queueContainer: IQueueController = mainContainer.get<IQueueController>('QueueController');
//     queueContainer.setQueueController(queueConfig);
// };

export const bootstrap = (container: Container) => {
    // todo: mandatory for now to make all dependencies resolved (make proper modules system)
    log.info(`Context Manager bootstrap [${container.id}]`);
    setQueueControl(QueueConfig);
    void checkForBanner();
};

// export const setQueueControl1 = (constainer: Container, queueConfig: IQueueConfig): void => {
//     const queueContainer: IQueueController = constainer.get<IQueueController>('QueueController');
//     queueContainer.setQueueController(queueConfig);
// };

export const checkForBanner = async (): Promise<void> => {
    usageLog.info('Invoked checkForBanner');
    if (ContextIndicatorEnum.OC.match(getContextEnvInfo())) {
        const contextHandler = getBannerService();
        await contextHandler.checkForBannerMessage();
    }
};

// export const getQueue = async (): Promise<IQueueServiceInterface> => {
//     try {
//         const databaseName = await getDatabaseName();
//         const secretKey = await getSecretKey();
//         return await getQueueManagerObj(databaseName, secretKey);
//     } catch (error) {
//         usageLog.error('getQueue encountered error ' + error);
//         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//         // @ts-ignore
//         return undefined;
//     }
// };

// getQueueCapability();

/**
 * Checks out a document from the DMS.
 * @param nodeObj The document to check out
 * @returns
 */
export const checkOut = async (nodeObj: ICHCheckoutDocMetaType): Promise<string | Response> => {
    try {
        usageLog.info('Invoked checkOut');
        const contextHandler = await getContext(CapabiltyEnum.CHECKOUT);
        return await contextHandler.checkOut(nodeObj);
    } catch (error) {
        log.error('checkOut document unexpected error:' + error);
        return handleUnExpectedError(error);
    }
};

// TODO: check if required, used only by tests
export const downloadDocument = async (nodeObj: ICHDownloadDocMetaType): Promise<string | Response> => {
    try {
        usageLog.info('Invoked downloadDocument');
        const contextHandler = await getContext(CapabiltyEnum.DOWNLOAD_DOCUMENT);
        return await contextHandler.downloadDocument(nodeObj);
    } catch (error) {
        log.error('Download document unexpected error:' + error);
        return handleUnExpectedError(error);
    }
};

export const showDocument = async (nodeObj: ICHDownloadDocMetaType): Promise<string | Response> => {
    try {
        usageLog.info('Invoked showDocument');
        const contextHandler = await getContext(CapabiltyEnum.SHOW_DOCUMENT);
        return await contextHandler.showDocument(nodeObj);
    } catch (error) {
        log.error('show document unexpected error:' + error);
        return handleUnExpectedError(error);
    }
};

export const addDocument = async (nodeObj: ICHUploadType): Promise<ICHUploadResponse> => {
    try {
        usageLog.info('Invoked addDocument');
        if (Array.isArray(nodeObj)) {
            nodeObj.map((item) => {
                const { file, ...restObj } = item;
                return restObj;
            });
        }

        const func = async (nodeObj: ICHUploadType) => {
            const contextHandler = await getContext(CapabiltyEnum.ADD_DOCUMENT);
            return contextHandler.addDocument(nodeObj);
        };
        // return Array.isArray(nodeObj) ? await bulkOperation(nodeObj, func) : func(nodeObj);
        if (!nodeObj.groupId) {
            nodeObj.groupId = uuid();
        }
        if (!nodeObj.uploadTime) {
            nodeObj.uploadTime = Date.now();
        }
        return await func(nodeObj);
    } catch (err) {
        log.error('addDocument  unexpected error:' + err);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return handleUnExpectedError(err) as any;
    }
};

// To DO
// to be changed to ICHResponse
export const addFolder = async (nodeObj: ICHAddFolder): Promise<Response> => {
    try {
        usageLog.info('Invoked addFolder');
        // Needed for T360
        nodeObj.documentId = undefined;
        const contextHandler = await getContext(CapabiltyEnum.ADD_FOLDER);
        return (await contextHandler.addFolder(nodeObj)) as unknown as Response;
    } catch (err) {
        log.error('editDocument  unexpected error:' + err);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return handleUnExpectedError(err) as any;
    }
};

export const editDocument = async (nodeObj: ICHEditDocument): Promise<ICHResponse> => {
    try {
        usageLog.info('Invoked editDocument');
        const contextHandler = await getContext(CapabiltyEnum.EDIT_DOCUMENT);
        return await contextHandler.editDocument(nodeObj);
    } catch (err) {
        log.error('editDocument  unexpected error:' + err);
        return handleUnExpectedError(err);
    }
};

export const editFolder = async (nodeObj: ICHEditFolder): Promise<ICHResponse> => {
    try {
        usageLog.info('Invoked editFolder');
        const contextHandler = await getContext(CapabiltyEnum.EDIT_FOLDER);
        return await contextHandler.editFolder(nodeObj);
    } catch (err) {
        log.error('editFolder  unexpected error:' + err);
        return handleUnExpectedError(err);
    }
};

export const deleteDocument = async (nodeObj: ICHDeleteDocMetaType): Promise<ICHResponse> => {
    try {
        usageLog.info('Invoked deleteDocument');
        const contextHandler = await getContext(CapabiltyEnum.DELETE_DOCUMENT);
        return await contextHandler.deleteDocument(nodeObj);
    } catch (err) {
        log.error('Delete document unexpected error:' + err);
        return handleUnExpectedError(err);
    }
};

export const deleteFolder = async (nodeObj: ICHDeleteDocument): Promise<ICHResponse> => {
    try {
        usageLog.info('Invoked deleteFolder');
        const contextHandler = await getContext(CapabiltyEnum.DELETE_FOLDER);
        return await contextHandler.deleteFolder(nodeObj);
    } catch (err) {
        log.error('deleteFolder unexpected error:' + err);
        return handleUnExpectedError(err);
    }
};

export const checkInDocument = async (nodeObj: ICHUploadType): Promise<ICHUploadResponse> => {
    try {
        usageLog.info('Invoked checkInDocument');
        const contextHandler = await getContext(CapabiltyEnum.CHECKIN_DOCUMENT);
        nodeObj.groupId = uuid();
        nodeObj.uploadTime = Date.now();
        return await contextHandler.checkInDocument(nodeObj);
    } catch (err) {
        log.error('checkInDocument unexpected error:' + err);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return handleUnExpectedError(err) as any;
    }
};

export const renameFolder = async (nodeObj: ICHRenameFolder): Promise<Response> => {
    try {
        usageLog.info('Invoked renameFolder');
        const contextHandler = await getContext(CapabiltyEnum.RENAME_FOLDER);
        return await contextHandler.renameFolder(nodeObj);
    } catch (err) {
        log.error('renameFolder unexpected error:' + err);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return handleUnExpectedError(err) as any;
    }
};

export const cancelCheckOut = async (nodeObj: ICHCancelCheckout): Promise<ICHResponse> => {
    try {
        usageLog.info('Invoked cancelCheckOut');
        const contextHandler = await getContext(CapabiltyEnum.CANCEL_CHECKOUT);
        return await contextHandler.cancelCheckOut(nodeObj);
    } catch (err) {
        log.error('cancelCheckOut unexpected error:' + err);
        return handleUnExpectedError(err);
    }
};

export const renameDocument = async (nodeObj: ICHRenameDocument): Promise<Response> => {
    try {
        usageLog.info('Invoked renameDocument');
        const contextHandler = await getContext(CapabiltyEnum.RENAME_DOCUMENT);
        return await contextHandler.renameDocument(nodeObj);
    } catch (err) {
        log.error('renameDocument unexpected error:' + err);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return handleUnExpectedError(err) as any;
    }
};

export const deleteEmail = async (nodeObj: ICHDeleteEmail): Promise<ICHResponse> => {
    try {
        usageLog.info('Invoked deleteEmail');
        const contextHandler = await getContext(CapabiltyEnum.DELETE_EMAIL);
        return await contextHandler.deleteEmail(nodeObj);
    } catch (error) {
        log.error('Delete email unexpected error:' + error);
        return handleUnExpectedError(error);
    }
};

export const overwriteCheckout = async (nodeObj: ICHCheckoutDocMetaType, uniqueId?: string): Promise<ICHResponse> => {
    try {
        usageLog.info('Invoked overwriteCheckout');
        const contextHandler = await getContext(CapabiltyEnum.CHECKOUT);
        return await contextHandler.overwriteCheckout(nodeObj, uniqueId);
    } catch (error) {
        log.error('overwriteCheckout unexpected error:' + error);
        return handleUnExpectedError(error);
    }
};

export const keepCheckout = async (nodeObj: ICHCheckoutDocMetaType, uniqueId?: string): Promise<ICHResponse> => {
    try {
        usageLog.info('Invoked keepCheckout');
        const contextHandler = await getContext(CapabiltyEnum.CHECKOUT);
        return await contextHandler.keepCheckout(nodeObj, uniqueId);
    } catch (error) {
        log.error('keepCheckout unexpected error:' + error);
        return handleUnExpectedError(error);
    }
};

export const keepCancelCheckout = async (nodeObj: ICHCancelCheckoutDocMetaType): Promise<string | Response> => {
    try {
        usageLog.info('Invoked keepCancelCheckout');
        const contextHandler = await getContext(CapabiltyEnum.CANCEL_CHECKOUT);
        return await contextHandler.keepCancelCheckout(nodeObj);
    } catch (error) {
        log.error('keepCancelCheckout unexpected error:' + error);
        return handleUnExpectedError(error);
    }
};

export const removeCancelCheckout = async (nodeObj: ICHCancelCheckoutDocMetaType): Promise<string | Response> => {
    try {
        usageLog.info('Invoked removeCancelCheckout');
        const contextHandler = await getContext(CapabiltyEnum.CANCEL_CHECKOUT);
        return await contextHandler.removeCancelCheckout(nodeObj);
    } catch (error) {
        log.error('removeCancelCheckout unexpected error:' + error);
        return handleUnExpectedError(error);
    }
};

export const openExternalUrl = async (externalUrl: string): Promise<Response> => {
    usageLog.info('Invoked openExternalUrl');
    const navigationContextObj = getNavigationContextHander();
    return await navigationContextObj.openExternalUrl(externalUrl);
};

export const getContextEnv = (): ContextIndicatorEnum => {
    return getContextEnvInfo();
};

// TO DO
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setToken = (id: string, secret: string, token: string): Observable<any> => {
    const contextHandler = getSecurityContextHandler();
    return contextHandler.setToken(id, secret, token);
};

// TO DO
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getToken = (id: string, secret: string): Observable<any> => {
    const contextHandler = getSecurityContextHandler();
    return contextHandler.getToken(id, secret);
};

// TO DO
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deleteToken = (id: string, secret: string): Observable<any> => {
    const contextHandler = getSecurityContextHandler();
    return contextHandler.deleteToken(id, secret);
};

export const notifyMessagingService = async (nodeObj: CHMessageData): Promise<void> => {
    const contextHandler = getCHMessagingServiceContextHandler();
    return await contextHandler.notify(nodeObj);
};

export const subscribeMessagingService = (observer: (data: CHMessageData) => void): Subscription => {
    const contextHandler = getCHMessagingServiceContextHandler();
    return contextHandler.subscribe(observer);
};

export const publish = (nodeObj: IEvent): void => {
    const contextHandler = getEventContextHandler();
    return contextHandler.publish(nodeObj);
};

// tslint:disable-next-line:ban-types
export const subscribe = (name: EventType, callback: IEventCallback, existingSubscriptionToRemove?: string): string => {
    const contextHandler = getEventContextHandler();
    return contextHandler.subscribe(name, callback, existingSubscriptionToRemove);
};

export const unsubscribe = (subscriptionId: string): void => {
    const contextHandler = getEventContextHandler();
    return contextHandler.unsubscribe(subscriptionId);
};

export const performQuickFile = async (nodeObj: ICHUploadType): Promise<ICHUploadResponse | ICHUploadResponse[]> => {
    try {
        usageLog.info('Invoked performQuickFile');
        nodeObj.groupId = uuid();
        nodeObj.uploadTime = Date.now();
        const operation = await getSupportedOperationName(nodeObj);
        const contextHandler = await getContext(operation);
        return await contextHandler.performQuickFile(nodeObj);
    } catch (error) {
        log.error('performQuickFile unexpected error:' + error);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return handleUnExpectedError(error) as any;
    }
};

export const performDragAndDrop = async (nodeObj: ICHUploadType): Promise<ICHUploadResponse | ICHUploadResponse[]> => {
    try {
        usageLog.info('Invoked performDragAndDrop');
        nodeObj.groupId = uuid();
        nodeObj.uploadTime = Date.now();
        const contextHandler = await getContext(CapabiltyEnum.DRAG_AND_DROP);
        return await contextHandler.performDragAndDrop(nodeObj);
    } catch (error) {
        log.error('performDragAndDrop unexpected error:' + error);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return handleUnExpectedError(error) as any;
    }
};
export const updateDocument = async (nodeObj: ICHUploadType): Promise<ICHUploadResponse> => {
    try {
        usageLog.info('Invoked updateDocument');
        if (!nodeObj.groupId) {
            nodeObj.groupId = uuid();
        }
        if (!nodeObj.uploadTime) {
            nodeObj.uploadTime = Date.now();
        }
        if (Array.isArray(nodeObj)) {
            nodeObj.map((item) => {
                const { file, ...restObj } = item;
                return restObj;
            });
        }

        const func = async (nodeObj: ICHUploadType) => {
            const contextHandler = await getContext(CapabiltyEnum.CHECKIN_DOCUMENT);
            return contextHandler.updateDocument(nodeObj);
        };
        return await func(nodeObj);
    } catch (error) {
        log.error('updateDocuments unexpected error:' + error);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return handleUnExpectedError(error) as any;
    }
};

export const getIsQuickFileEnabled = async (
    isFolder?: boolean,
    documentType?: CHSupportedDocumentTypesEnum,
): Promise<ICHQuickFileIndicator> => {
    const contextHandler = await getContext(CapabiltyEnum.GET_QUICK_FILE_SUPPORT);
    return contextHandler.getIsQuickFileEnabled(isFolder, documentType);
};

export const initialize = async (clProps: ICHProps): Promise<void> => {
    const contextHandler = await getContext(CapabiltyEnum.INITIALIZE);
    contextHandler.initialize(clProps);
};

export const getAllProps = async (): Promise<IQueuePropItem[]> => {
    const contextHandler = await getContext(CapabiltyEnum.GET_ALL_PROPS);
    return contextHandler.getAllProps();
};

export const subscribeAutoUpdateService = (observer: (data: CHAutoUpdateStatus) => void): Subscription => {
    const contextHandler = getAutoUpdateServiceContextHandler();
    return contextHandler.subscribe(observer);
};

export const notifyAutoUpdateService = (): void => {
    const contextHandler = getAutoUpdateServiceContextHandler();
    contextHandler.notifyCommandToOC();
};
export const setDefaultUrl = (url: string): Observable<void> => {
    const contextHandler = getRouterServiceContextHandler();
    return contextHandler.setDefaultUrl(url);
};

export const clearDefaultUrl = (): Observable<void> => {
    const contextHandler = getRouterServiceContextHandler();
    return contextHandler.setDefaultUrl();
};

export const reloadUrl = (): Observable<void> => {
    const contextHandler = getRouterServiceContextHandler();
    return contextHandler.reloadUrl();
};

export const buildHelpDocumentUrl = async (apiPath: string, helpFileUrl: string): Promise<ICHHelpUrlResponse> => {
    return await buildHelpFileUrl(apiPath, helpFileUrl);
};

export const showHelpDocument = async (url: string): Promise<ICHResponse> => {
    usageLog.info('Invoked showHelpDocument');
    return openHelpDocument(url);
};
