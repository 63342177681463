import { Locale } from './../locale';
import { Dialog } from './../configurations/dialog.config';
import { ICHUploadType, ICHUploadResponse } from './../interfaces/operations';
import { inject, injectable } from 'inversify';
import 'reflect-metadata';
import { CapabiltyEnum, DocumentState } from '../enum/enum';
import { DocumentEntity } from '../dto/documentEntity';
import { factory } from '../configLog4J';
import { CheckInService } from './checkIn.service';
// import { handleCallback, handleUnExpectedError, implementQueue } from '../utils/main.utils';
import { handleCallback, handleUnExpectedError, deleteFromPersistentStorage } from '../utils/main.utils';
import { implementQueue } from '../capability';
import { IOfficeService } from './office.service';
import { IEventService } from './eventContextHandler.service';
import { IDocumentsService } from './documents.service';
import { CHMessagingService, MessageService } from './message.service';
import { DialogService } from './dialog.service';
const log = factory.getLogger('DocumentSummaryCheckInService');
const EMAIL = 'Emails';
@injectable()
export class DocumentSummaryCheckInService {
    private _officeService: IOfficeService;
    private _eventService: IEventService;
    private _documentsService: IDocumentsService;
    private _messageService: MessageService;
    private _checkInService: CheckInService;
    private newDocument: string;
    private documentEntity: DocumentEntity | undefined;
    private loggedInUser: string;
    private _chMessageService: CHMessagingService;
    private _dialogService: DialogService;

    constructor(
        @inject('OfficeService') officeService: IOfficeService,
        @inject('EventContextHandler') eventService: IEventService,
        @inject('CheckInService') checkInService: CheckInService,
        @inject('DocumentsService') documentsService: IDocumentsService,
        @inject('MessageService') messageService: MessageService,
        @inject('CHMessagingService') chMessageService: CHMessagingService,
        @inject('DialogService') dialogService: DialogService,
    ) {
        this._officeService = officeService;
        this._eventService = eventService;
        this._documentsService = documentsService;
        this._messageService = messageService;
        this._checkInService = checkInService;
        this.newDocument = '';
        this.loggedInUser = '';
        this._chMessageService = chMessageService;
        this._dialogService = dialogService;
    }

    public async processDocumentCheckIn(contextObj: ICHUploadType): Promise<ICHUploadResponse> {
        const isDocumentAvailable = await this._documentsService.wasStaleDocument(contextObj, DocumentState.DOC_EXIST);
        if (isDocumentAvailable) {
            const isBusy = await this._officeService.isBusy();
            if (isBusy) {
                const message = contextObj.isQuickFile
                    ? Locale.documents.quickfile_doc
                    : contextObj.isDragAndDrop
                        ? Locale.documents.drag_and_drop
                        : Locale.documents.checkin_doc;
                await deleteFromPersistentStorage(contextObj.filePaths);
                return { responseText: await this._documentsService.handleIsApplicationBusy(message) };
            }

            if (contextObj.folderArr && contextObj.folderArr[0]?.value == EMAIL && !contextObj.isSaveOrOverwrite) {
                this._officeService.showEmailOrDocumentUploadToast(
                    contextObj.folderArr[0],
                    CapabiltyEnum.CHECKIN_DOCUMENT,
                );
            }

            const currentOpenDocument = await this._officeService.getCurrentDocumentFullName();
            const isActiveDndDocument =
                contextObj.isDragAndDrop && contextObj.filePaths
                    ? await this._officeService.compareFilePaths(contextObj.filePaths[0], currentOpenDocument)
                    : false;
            if (isActiveDndDocument) {
                if (
                    ((await this._officeService.getActiveDocumentSelection()) &&
                        (await this._officeService.isNewDocument())) ||
                    !(await this._officeService.isDocumentSaved())
                ) {
                    log.info('OC | File Not Saved');
                    return await this.checkIfQuickFileDocumentSaved(contextObj);
                }
            }

            log.info('OC | Document Summary CheckIn');
            await this.updateDocumentToLatest(contextObj);
            if (this.documentEntity) {
                contextObj.uniqueId = await this._documentsService.getUniqueIdFromMeta(this.documentEntity);
            } else {
                const errorMessage = Locale.responseText.file_not_found;
                log.info('OC | File not saved');
                this._documentsService.buildPayload(contextObj, contextObj.fileName || '');
                await implementQueue(contextObj, CapabiltyEnum.CHECKIN_DOCUMENT, errorMessage);
                return { responseText: errorMessage };
            }
            log.info('OC | UniqueId ' + contextObj.uniqueId);
            this.loggedInUser = contextObj.loggedInUser || '';

            this.newDocument = contextObj.isDragAndDrop
                ? contextObj.filePaths && contextObj.filePaths?.length
                    ? await this._officeService.getFileNameFromFullPath(contextObj.filePaths[0])
                    : ''
                : await this._officeService.getCurrentDocumentWithExtension();
            log.info('OC | Document Summary | New Document ' + this.newDocument);

            if (this.documentEntity) {
                const isCheckedOutByOther = this._documentsService.isCheckedOutByOther(
                    this.loggedInUser,
                    this.documentEntity.versionSeriesCheckedOutBy,
                );
                log.info('isLocked ' + isCheckedOutByOther);

                if (isCheckedOutByOther) {
                    const message = this._messageService.compileTemplate(
                        Locale.documents.doc_checked_out_to_different_user,
                        {
                            fileName: this.documentEntity.documentFileName,
                            lockedBy: this.documentEntity.versionSeriesCheckedOutBy,
                        },
                    );
                    this._documentsService.buildPayload(contextObj, this.documentEntity.documentFileName || '');
                    await implementQueue(contextObj, CapabiltyEnum.CHECKIN_DOCUMENT, message);
                    return { responseText: message };
                }

                if (
                    !(await this._documentsService.hasFileExtensionMatch(
                        this.documentEntity,
                        this.newDocument,
                        contextObj,
                    ))
                ) {
                    return { responseText: 'File not matched' };
                }

                const isDocumentSaved: boolean = await this._officeService.isDocumentSaved();
                const isNewDocument = await this._officeService.isNewDocument();
                const currentDocument = await this._officeService.getCurrentDocumentFullName();
                const isActiveDocument: boolean = contextObj.filePaths
                    ? await this._officeService.compareFilePaths(contextObj.filePaths[0], currentDocument)
                    : (contextObj.isQuickFile ? await this._officeService.isActiveDocument(contextObj.uniqueId, true) : false);
                if (
                    !(await this._officeService.compareFilePaths(
                        this.documentEntity.documentFileName,
                        this.newDocument,
                    ))
                ) {
                    if (!isDocumentSaved || isNewDocument) {
                        await this.prepareSaveDocumentDialog(contextObj, isNewDocument, !contextObj.isQuickFile);
                        return { responseText: Locale.responseText.file_not_saved };
                    }
                    await this.showDialogForNewVersionUpload(this.documentEntity, contextObj);
                } else {
                    log.debug('OC | Diff. File QF isDocumentSaved' + isDocumentSaved);
                    if (!isDocumentSaved && isActiveDocument) {
                        await this.prepareSaveDocumentDialog(contextObj, false, !contextObj.isQuickFile);
                        return { responseText: Locale.responseText.file_not_saved };
                    }
                    return await this._checkInService.processDocumentCheckIn(contextObj, true, this.documentEntity);
                }
            }
            return { responseText: Locale.responseText.file_not_found };
        } else {
            await deleteFromPersistentStorage(contextObj.filePaths);
            return { responseText: Locale.stale.deleted_document };
        }
    }

    private async showDialogForNewVersionUpload(
        documentEntity: DocumentEntity | undefined,
        contextObj: ICHUploadType,
    ): Promise<string> {
        const buttons = [
            {
                label: Dialog.buttons.proceed,
                callback: async () => {
                    return await handleCallback(
                        this._checkInService.processDocumentCheckIn.bind(
                            this._checkInService,
                            contextObj,
                            true,
                            this.documentEntity,
                            true,
                        ),
                    );
                },
            },
            {
                label: Dialog.buttons.cancel,
                callback: async (): Promise<void> => {
                    await deleteFromPersistentStorage(contextObj.filePaths);
                },
            },
        ];

        if (!this.newDocument) {
            this.newDocument = contextObj.isDragAndDrop
                ? contextObj.filePaths && contextObj.filePaths?.length
                    ? await this._officeService.getFileNameFromFullPath(contextObj.filePaths[0])
                    : ''
                : await this._officeService.getCurrentDocumentWithExtension();
        }

        const messageInfo = Locale.documents.upload_new_document_fileName_with_warning.message;
        const message = this._messageService.compileTemplate(messageInfo, {
            oldFileName: documentEntity?.documentFileName,
            newFileName: this.newDocument,
        });
        this._dialogService.showDialog(
            {
                title: Locale.documents.upload_new_document_fileName_with_warning.title,
                message: message,
                buttons,
            },
            contextObj.filePaths,
        );
        return message;
    }

    private async updateDocumentToLatest(contextObj: ICHUploadType): Promise<void> {
        if (contextObj.filePaths && contextObj.filePaths?.length > 0) {
            contextObj.fileName = await this._officeService.getFileNameFromFullPath(contextObj.filePaths[0]);
            contextObj.name = await this._officeService.getFileNameFromFullPath(contextObj.filePaths[0]);
        } else {
            contextObj.fileName = await this._officeService.getCurrentDocument();
            contextObj.name = await this._officeService.getCurrentDocument();
        }
        this.documentEntity =
            (await this._documentsService.getDocumentMeta(contextObj, !!contextObj.documentId)) || undefined;
    }

    private async checkIfQuickFileDocumentSaved(contextObj: ICHUploadType): Promise<ICHUploadResponse> {
        const isNewDocument = await this._officeService.isNewDocument();
        const isDocumentSaved = await this._officeService.isDocumentSaved();
        if (isNewDocument || !isDocumentSaved) {
            await this.prepareSaveDocumentDialog(contextObj, isNewDocument, false);
        }

        return { responseText: Locale.responseText.file_not_saved };
    }

    private async prepareSaveDocumentDialog(
        contextObj: ICHUploadType,
        isNew?: boolean,
        checkIn?: boolean,
    ): Promise<void> {
        const cancelButton = {
            label: Dialog.buttons.cancel,
            callback: async () => {
                await deleteFromPersistentStorage(contextObj.filePaths);
                return;
            },
        };

        const saveButton = {
            label: Dialog.buttons.save,
            callback: async () => {
                try {
                    const isSaved = await this._officeService.saveActiveDocument();
                    if (isSaved) {
                        contextObj.isSaveOrOverwrite = true;
                        await this.processDocumentCheckIn(contextObj);
                    }
                } catch (error) {
                    log.error(error);
                    handleUnExpectedError(error);
                }
            },
        };

        const saveAsButton = {
            label: Dialog.buttons.save_as,
            callback: async () => {
                try {
                    const isSaved = await this._officeService.saveAsActiveDocument();
                    if (isSaved) {
                        if (contextObj.isDragAndDrop) {
                            contextObj.filePaths = [];
                            contextObj.filePaths.push(await this._officeService.getCurrentDocumentFullName());
                        }
                        contextObj.isSaveOrOverwrite = true;
                        await this.processDocumentCheckIn(contextObj);
                    }
                } catch (error) {
                    log.error(error);
                    handleUnExpectedError(error);
                }
            },
        };

        const saveAndUpload = { ...saveAsButton, label: Dialog.buttons.save_and_upload };
        const readOnlyDocument = await this._officeService.isReadOnly();
        const messageTemplate =
            isNew || checkIn
                ? 'upload_new_active_document'
                : readOnlyDocument
                    ? 'upload_exist_active_saveas_document'
                    : 'upload_exist_active_document';
        const title = Dialog.confirm[messageTemplate].title;
        const message = this._messageService.compileTemplate(Dialog.confirm[messageTemplate].message);
        const buttons =
            isNew || checkIn
                ? [checkIn ? saveButton : saveAndUpload, cancelButton]
                : readOnlyDocument
                    ? [saveAsButton, cancelButton]
                    : [saveButton, saveAsButton, cancelButton];

        await this._dialogService.showDialog(
            {
                title,
                message,
                buttons,
            },
            contextObj.filePaths,
        );
    }
}
