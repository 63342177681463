import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const WkEmailIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="wkEmailIcon" {...props} viewBox="0 0 20 20">
        <path
            d="M20,17.3H0l7.1-5.8l0.3,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.6,0.7,1.4,1.1,2.3,1.2c1-0.1,1.9-0.5,2.5-1.3
	c0.1-0.1,0.1-0.1,0.2-0.2l0.3-0.3L20,17.3z M20,15.7L20,15.7l-6.2-5L20,4.6V15.7z M0,15.7V4.6l6.2,6.1L0,15.7z M10,12
	c-0.6-0.1-1.2-0.4-1.5-0.9C8.3,11,8.2,10.8,8,10.7l-8-8h20l-8,8c-0.2,0.2-0.3,0.3-0.5,0.5C11.2,11.6,10.6,11.9,10,12z"
        />
    </SvgIcon>
);

export default WkEmailIcon;
