import React, { ReactElement } from 'react';
import { AppContext, AppContextData } from '../../../../components/app';
import { AppProviderProps } from '../../../../initialize';
import { useNetworkSwitch } from '../hooks/useNetworkSwitch.hook';

export const AppProvider = (props: AppProviderProps): ReactElement<AppProviderProps> => {
    const { children } = props;

    const network = useNetworkSwitch();

    const appContext: AppContextData = {
        network,
    };

    return <AppContext.Provider value={appContext}>{children}</AppContext.Provider>;
};
