import { ITransferBasketItem } from '../../../transferBasket/types';
import { generatePagePostObject } from '../../listScreenHelpers';
import { IListScreenPageData, IListScreenPostObject } from '../../types';
import {
    IDialogState,
    IFilterObj,
    IListScreenEditDialogState,
    IRawTreeData,
    ListScreenEditDialogAction,
} from '../types';
import { calculateColumnData, columnPickerReducer } from './columnPickerReducer';
import { filterPickerReducer } from './filterPickerReducer';

export const listScreenEditDialogReducer = (
    state: IListScreenEditDialogState,
    action: ListScreenEditDialogAction,
): IListScreenEditDialogState => {
    return {
        ...state,
        dialog: dialogReducer(state.dialog, action),
        columnPicker: columnPickerReducer(state.columnPicker, action),
        filterPicker: filterPickerReducer(state.filterPicker, action),
    };
};

const dialogReducer = (state: IDialogState, action: ListScreenEditDialogAction) => {
    switch (action.type) {
        case 'SetTab': {
            return {
                ...state,
                selectedTab: action.tabIndex,
                visitedTabs:
                    state.visitedTabs.indexOf(action.tabIndex) >= 0
                        ? state.visitedTabs
                        : state.visitedTabs.concat([action.tabIndex]),
            };
        }
        default: {
            return state;
        }
    }
};

// helper functions (not async actions)
export const convertRawTreeDataToTransferBasketItems = (
    rawTreeData: IRawTreeData[],
    parentId?: string,
): ITransferBasketItem[] => {
    return rawTreeData.map((td) => {
        const result: ITransferBasketItem = {
            id: parentId ? `${parentId},${td.attributeId}` : td.attributeId.toString(),
            availableDisplayValue: td.text,
            selectedDisplayValue: td.text,
            attributeType: td.attributeType,
            attributePath: td.attributePath,
            disabled: td.disabled,
            hasChildren: td.hasChildren || false,
            metadata: { relatedEntityId: td.id, unsortable: td.unsortable || false },
            isSelected: false,
            hideFromAvailable: false,
            listOrder: 0,
            isItemUnselectable: true,
        };
        return result;
    });
};

export const calculatePageObject = (
    state: IListScreenEditDialogState,
    pageData: IListScreenPageData,
): IListScreenPostObject => {
    return generatePagePostObject(pageData, {
        pageNumber: 1,
        'column-data': calculateColumnData(state.columnPicker, pageData['column-data']),
        // flatten the groups of filters... this awesomeness flattens a 2 dimensional array
        filters: ([] as IFilterObj[]).concat(...Object.values(state.filterPicker.filtersMap)),
    });
};
