export interface ObjectRefValueType {
    objectRef?: string;
}

export type ValueHolderType = boolean | number | string | Date | ObjectRefValueType | undefined | void;

export enum ValueType {
    Bool = 'boolValue',
    Integer = 'intValue',
    Float = 'floatValue',
    String = 'stringValue',
    Date = 'dateTimeValue',
    ObjectRef = 'objectRefValue',
    Void = 'voidValue',
    Unknown = 'unknownValue',
}

export interface ValueHolder<V = ValueHolderType> {
    value: V;
    type?: ValueType;
}
