import { IValidationTransformer } from './types';
import { ICHValidateEditFolder, IValidateEditFolder } from '../interfaces/operations';
import 'reflect-metadata';
import { injectable } from 'inversify';
import { factory } from '../configLog4J';
const log = factory.getLogger('CtxValidateEditFolderTransformer');

@injectable()
export class CtxValidateEditFolderTransformer implements IValidationTransformer {
    data!: IValidateEditFolder;

    transform(): ICHValidateEditFolder {
        const nodeObj = {} as ICHValidateEditFolder;

        log.debug('printing data: ' + JSON.stringify(this.data));

        if (this.data.editAccessType) {
            nodeObj.folder_edit_access_type_name = this.data.editAccessType;
        }

        if (this.data.viewAccessType) {
            nodeObj.folder_view_access_type_name = this.data.viewAccessType;
        }

        if (this.data.viewMembers) {
            nodeObj.viewMembers = this.data.viewMembers;
        }

        if (this.data.editMembers) {
            nodeObj.editMembers = this.data.editMembers;
        }

        if (this.data.name) {
            nodeObj.document_folder_name = this.data.name;
        }

        if (this.data.documentFolderId) {
            nodeObj.folderId = this.data.documentFolderId;
        }

        if (this.data?.default) {
            nodeObj.default = this.data.default;
        }

        return nodeObj;
    }
}
