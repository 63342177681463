import { ComObject, ValueHolder } from '../comObject';
import { ClientObject, PropertyInit } from './clientObject';
import { v4 as uuid } from 'uuid';
import { lastValueFrom, Observable } from 'rxjs';
import { LoggerService } from '../../../common/logger/logger.service';

interface OfficeAction {
    request: () => Observable<ValueHolder<unknown>>;
}

interface ReferenceInfo {
    reference: ComObject;
    isReleasable: boolean;
}

export class OfficeContextScope {
    private references = new Map<string, ReferenceInfo>();
    private actions = new Map<string, OfficeAction>();

    constructor(protected logger: LoggerService) {}

    public addReference(reference: ComObject, isReleasable = true): void {
        if (!reference.objectRef) {
            return;
        }

        this.references.set(reference.objectRef, { reference, isReleasable });
    }

    public addAction(action: OfficeAction): void {
        this.actions.set(uuid(), action);
    }

    public release(): Promise<void> {
        const referencesKeys = Array.from(this.references.keys());
        const releaseRequests = referencesKeys.map((key) => {
            const { reference, isReleasable } = this.references.get(key) as ReferenceInfo;

            if (!reference) {
                this.logger.error(
                    'comObject reference should not be empty in references map. ' +
                        'Probably there is some errors in office object usage',
                );
                this.references.delete(key);
                return undefined;
            }

            if (!isReleasable) {
                this.logger.info('skip unrealisable object from com objects release process');
                this.references.delete(key);
                return undefined;
            }

            return lastValueFrom(reference.release())
                .then(() => {
                    this.references.delete(key);
                })
                .catch((error) => {
                    // todo: should do proper error handling here
                    this.logger.error(error);
                    return;
                });
        });

        return Promise.all(releaseRequests).then(() => {
            this.logger.info(`${referencesKeys.length - this.references.size} comObject references has been released`);
        });
    }

    public resolve(): Promise<void> {
        const actionsKeys = Array.from(this.actions.keys());
        let queue: Promise<unknown> = Promise.resolve();

        actionsKeys.forEach((key) => {
            queue = queue.then(async () => {
                const action = this.actions.get(key);

                if (!action) {
                    this.actions.delete(key);
                    return undefined;
                }

                const result = await lastValueFrom(action.request());
                this.actions.delete(key);
                return result;
            });
        });

        return queue
            .then(() => {
                this.logger.info(`${actionsKeys.length} actions has been requested during last synchronization`);
            })
            .catch((error) => {
                this.actions.clear();
                throw error;
            });
    }

    public requestInitProperties<T extends ClientObject, K extends keyof T>(
        object: T & PropertyInit<T>,
        propertyNames: K | K[],
    ): void {
        try {
            const loaders = object.getPropertyInitializers();
            const names: K[] = ([] as K[]).concat(propertyNames);

            names.forEach((name) => {
                const requestInitProperty = loaders.get(name);
                if (requestInitProperty) {
                    requestInitProperty();
                }
            });
        } catch (error) {
            this.logger.error('request for properties initialization has failed', error);
        }
    }
}
