import { commonContainer } from '../../common/common.module';
import { Container, interfaces } from 'inversify';
import { ItemOperationInterface, ItemOperationService } from './itemOperation.service';

const importedContainers = [commonContainer];

const itemOperationContainer: interfaces.Container = importedContainers.reduce(
    (container, imports) => Container.merge(imports, container),
    new Container(),
);

itemOperationContainer.bind<ItemOperationInterface>(ItemOperationService).toSelf().inSingletonScope();

export { itemOperationContainer };
