import { ClientObject } from '../common/clientObject';
import { Explorer } from './explorer';
import { RequestType, ValueType } from '../comObject';
import { ObjectCreatorType } from '../common/referenceObject';
import { MailItem } from './mailItem';
import { ContactItem } from './contactItem';
import { NotImplementedException } from '@wk/office-companion-js-common';
import { Inspector } from './inspector';

export enum ItemType {
    MailItem = 0,
    ContactItem = 2,
}

export type OutlookItemType = MailItem | ContactItem;

export class Application extends ClientObject {
    private itemCreator = {
        [ItemType.MailItem]: MailItem,
        [ItemType.ContactItem]: ContactItem,
    };

    public get activeExplorer(): Explorer {
        return this.reference.requestObject({
            type: RequestType.Invoke,
            creator: Explorer,
            name: 'ActiveExplorer',
        }).value;
    }

    public get activeInspector(): Inspector {
        return this.reference.requestObject({
            type: RequestType.Invoke,
            creator: Inspector,
            name: 'ActiveInspector',
        }).value;
    }

    public createItem<T extends OutlookItemType>(type: ItemType): T {
        if (this.itemCreator[type] === undefined) {
            const message =
                `${type} item creation is not supported. ` +
                `Can create only ${Object.keys(ItemType).join(', ')} types`;
            throw new NotImplementedException(message);
        }

        return this.reference.requestObject<T>({
            type: RequestType.Invoke,
            creator: this.itemCreator[type] as ObjectCreatorType<T>,
            name: 'CreateItem',
            args: [{ value: type, type: ValueType.Integer }],
        }).value;
    }
}
