import { ClientObject, PropertyInit } from '../common/clientObject';
import { RequestType } from '../comObject';

export class Recipient extends ClientObject implements PropertyInit {
    private properties = this.clientObjectInitPropsInfo({
        entryId: { propertyName: 'entryId', name: 'EntryID' },
        name: { propertyName: 'name', name: 'Name' },
    });

    public getPropertyInitializers(): Map<keyof Recipient, () => void> {
        return new Map<keyof Recipient, () => void>(
            Object.values(this.properties).map(({ propertyName, name }) => [
                propertyName,
                (): void =>
                    this.reference.request({
                        type: RequestType.GetProperty,
                        cacheName: propertyName,
                        name,
                    }),
            ]),
        );
    }

    public get entryId(): string {
        return this.reference.getValue<string>(this.properties.entryId.propertyName).value;
    }

    public get name(): string {
        return this.reference.getValue<string>(this.properties.name.propertyName).value;
    }
}
