import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const WkCircleLoadingIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="wkCircleLoadingIcon" {...props} viewBox="0 0 20 20">
        <path
            d="M7.5,14H6.5v-3.7h0.9V14z M11.6,12.3L11.6,12.3L9,9.6L9.6,9l2.6,2.6L11.6,12.3L11.6,12.3z M2.3,12.3
      L2.3,12.3l-0.6-0.6L4.3,9L5,9.6L2.3,12.3L2.3,12.3z M14,7.5h-3.7V6.5H14V7.5z M3.7,7.5H0V6.5h3.7C3.7,6.5,3.7,7.5,3.7,7.5z M9.7,5
      L9.7,5L9,4.4l2.6-2.6l0.6,0.6L9.7,5z M4.3,5L4.3,5L1.7,2.4l0.7-0.7L5,4.4L4.3,5L4.3,5z M7.5,3.7H6.5V0h0.9L7.5,3.7L7.5,3.7z"
        />
    </SvgIcon>
);

export default WkCircleLoadingIcon;
