import React from 'react';
import { SvgIconProps } from '@material-ui/core';

const WkZoomInIcon: React.FC<SvgIconProps> = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
        <g transform="translate(-1184 -388)">
            <g transform="translate(-7153.5 11966.5)">
                <line
                    style={{ stroke: '#005b92', strokeWidth: '2px', fill: 'none' }}
                    x2="16"
                    transform="translate(8337.5 -11570.5)"
                />
                <line
                    style={{ stroke: '#005b92', strokeWidth: '2px', fill: 'none' }}
                    y2="16"
                    transform="translate(8345.5 -11578.5)"
                />
            </g>
            <rect style={{ fill: 'none' }} width="16" height="16" transform="translate(1184 388)" />
        </g>
    </svg>
);

export default WkZoomInIcon;
