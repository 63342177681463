export enum SupportedEntityTypesToDocTypeEnum {
    DOCUMENT = 'Document',
    EMAILDOCUMENT = 'Email',
    MATTERMATTERMANAGEMENTDOCUMENT = 'Document',
    INVOICEMATTERMANAGEMENTDOCUMENT = 'Document',
    PEOPLEMATTERMANAGEMENTDOCUMENT = 'Document',
    ORGANIZATIONMATTERMANAGEMENTDOCUMENT = 'Document',
    MATTER = 'Matter',
    INVOICE = 'Invoice',
    ORGANIZATION = 'Organization',
}

export enum DocSupportedBaseEntityTypesEnum {
    DOCUMENT = 'DOCUMENT',
    EMAIL = 'EMAIL',
    MATTER = 'MATTER',
    INVOICE = 'INVOICE',
    ORGANIZATION = 'ORGANIZATION',
}
