import { IDocServiceRequest } from '../../docManager';
import { JRAdapterOperationPattern } from './operationPattern';

export class JRAdapterDownloadPattern extends JRAdapterOperationPattern {
    async getUrl(_nodeObj: IDocServiceRequest): Promise<string> {
        const url = this.hostName + '/' + this.objectRelative;

        return url;
    }
}
