import { CommandPattern } from '../../interfaces/urlPattern/types';
import { IDocServiceRequest, IEntityContext } from '../../types';
import { injectable } from 'inversify';

interface IDocServiceCommandPattern extends IDocServiceRequest {
    docId: string;
    entityContext: IEntityContext;
}

@injectable()
export class JRAdapterCommandPattern extends CommandPattern {
    async getUrl(nodeObj: IDocServiceCommandPattern): Promise<string> {
        const commands = await this.commandId;
        const url =
            this.hostName +
            '/' +
            this.objectRelative +
            '/' +
            this.ObjectTypeMap[nodeObj.entityContext.type] +
            '/' +
            nodeObj.docId +
            '/' +
            'command' +
            '/' +
            commands[this.commandMapName] +
            '?ignoreWarnings=true';
        return url;
    }
}
