import { DCEntityTypeEnum } from '../types';
import { IDmAdapter } from '../interfaces/dmAdapters/types';
import { IDocCapability } from '../capability/type';
import { injectable } from 'inversify';
import { factory } from '../ConfigLog4j';
import { GetUrlEnum } from '../enums/urlPattern';
import { Adapter } from '../adapter';
import { actionPerformerMethods, inputValidators, matchOperations, transformOperation } from './utils/maps';
import { IActionableEntity } from '../interfaces/actionEntity/type';
import { EValidator } from '../enums/validators';
import { DCResponse } from '../docManager';

const log = factory.getLogger('T360Adapter');

@injectable()
export class T360Adapter extends Adapter {
    dmAdapters: IDmAdapter[] = [];

    capability: IDocCapability;

    objectRelative!: string;

    ObjectTypeMap: Record<string, string>;
    getUrlEnum: GetUrlEnum;
    commandIds: Promise<Record<string, string>>;

    constructor() {
        super();
        this.commandIds = new Promise((resolve, _reject) => {
            resolve({});
        });
        this.getUrlEnum = GetUrlEnum.DMINFO;
        this.ObjectTypeMap = {
            [DCEntityTypeEnum.Matter]: 'Matter',
            [DCEntityTypeEnum.Organization]: 'Organization',
            [DCEntityTypeEnum.Person]: 'Person',
            [DCEntityTypeEnum.InvoiceHeader]: 'Invoice',
            [DCEntityTypeEnum.EmailDocument]: 'Email',
            [DCEntityTypeEnum.Invoice]: 'Invoice',
        };
    }

    // TO DO MOBVED to PARENT
    getMappedOperation(capabilityEnum: string): string {
        if (capabilityEnum in transformOperation) {
            return matchOperations[capabilityEnum];
        }
        return capabilityEnum;
    }

    getActionPerformerMethod(
        capabilityEnum: string,
    ): (arg0: string, arg1: IActionableEntity, arg2: string) => Promise<DCResponse> {
        if (capabilityEnum in actionPerformerMethods) {
            return actionPerformerMethods[capabilityEnum];
        } else {
            throw Error('No ActionPerformer Method Found' + 'for ' + capabilityEnum);
        }
    }

    getInputValidators(capabilityEnum: string): Array<EValidator> {
        if (capabilityEnum in inputValidators) {
            return inputValidators[capabilityEnum];
        } else {
            log.debug('No Input Validator Found ' + 'for ' + capabilityEnum);
            return [];
        }
    }
}
