import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const WkCloudCheckmarkIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="wkCloudCheckmarkIcon" viewBox="0 0 30 30" {...props}>
        <g transform="translate(-1673 -250)">
            <g transform="translate(1642.95 210.441)">
                <path
                    style={{ fill: '#940c72' }}
                    d="M56.307,53.657l-.039-.028-.229-.228V52.386a9.838,9.838,0,0,0-9.827-9.827,10.042,10.042,0,0,0-9.673,7.689v.237h-.345A8.566,8.566,0,0,0,30.05,58.72a8.706,8.706,0,0,0,8.591,8.591H52.854a7.363,7.363,0,0,0,7.355-7.352A7.606,7.606,0,0,0,56.307,53.657Zm.4,10.188a5.546,5.546,0,0,1-4,1.733H38.486a6.786,6.786,0,0,1-6.859-6.859,6.688,6.688,0,0,1,5.747-6.7.786.786,0,0,0,.742-.714,8.247,8.247,0,0,1,8.095-7.168,8.1,8.1,0,0,1,8.095,8.095,6.008,6.008,0,0,1-.161,1.6.844.844,0,0,0,.477.98,5.623,5.623,0,0,1,3.546,5.148A5.219,5.219,0,0,1,56.7,63.846Z"
                    transform="translate(0 0)"
                />
                <path
                    style={{ fill: '#940c72' }}
                    d="M82.692,83.726a.822.822,0,0,0-.336-.6.8.8,0,0,0-.657-.241.822.822,0,0,0-.6.336l-6.227,7.346L71.6,87.459a.911.911,0,0,0-1.253,0,.824.824,0,0,0,0,1.248l4.042,4.042a.978.978,0,0,0,.624.248.642.642,0,0,0,.584-.347l.02-.031,6.848-8.25A.767.767,0,0,0,82.692,83.726Z"
                    transform="translate(-31.437 -31.681)"
                />
            </g>
            <rect style={{ fill: 'none' }} width="30" height="30" transform="translate(1673 250)" />
        </g>
    </SvgIcon>
);

export default WkCloudCheckmarkIcon;
