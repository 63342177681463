import { sanitize } from 'dompurify';
import React from 'react';
import { useReduxSelector } from '../../hooks/useReduxSelector';
import { NotificationType } from '../../reducers/types';
import WkCircleCheckIcon from '../icons/wkCircleCheckIcon';
import WkCircleLetteriIcon from '../icons/wkCircleLetteriIcon';
import WkErrorIcon from '../icons/wkErrorIcon';
import WkWarningIcon from '../icons/wkWarningIcon';
import css from './toastMessage.module.scss';

interface IToastMessage {
    type: NotificationType;
    message: string;
}

const ToastMessage: React.FC<IToastMessage> = ({ type, message }) => {
    const appResources = useReduxSelector((state) => state.appResources);
    let icon: React.ReactNode = null;
    let title: string | null = null;

    switch (type) {
        case 'success':
            icon = <WkCircleCheckIcon />;
            title = appResources.userscreenSuccessToast;
            break;
        case 'info':
            icon = <WkCircleLetteriIcon />;
            title = appResources.userscreenInfoToast;
            break;
        case 'warning':
            icon = <WkWarningIcon />;
            title = appResources.userscreenWarningToast;
            break;
        case 'error':
            icon = <WkErrorIcon />;
            title = appResources.userscreenErrorToast;
            break;
    }

    return (
        <div>
            <div className={css.toastTitleContainer}>
                {icon}
                <span className={css.toastTitle}>{title}</span>
            </div>
            <div
                data-testid={title + 'Snackbar'}
                className={css.toastMessage}
                dangerouslySetInnerHTML={{ __html: sanitize(message) }}
            />
        </div>
    );
};

export default ToastMessage;
