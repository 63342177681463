import { FormControl, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
    subText: {
        color: '#757575',
        fontSize: 11,
        width: '100%',
        pointerEvents: 'none',
        transformOrigin: 'top left',
    },
    boldTitleText: {
        color: '#353535',
        fontSize: 16,
        fontWeight: 600,
        width: '100%',
        pointerEvents: 'none',
    },
    boldContentText: {
        color: '#474747',
        fontSize: 14,
        fontWeight: 500,
        width: '100%',
        pointerEvents: 'none',
    },
    standardText: {
        color: '#353535',
        fontSize: 14,
        width: '100%',
        pointerEvents: 'none',
    },
    formRoot: {
        marginBottom: 20,
    },
});

// eslint-disable-next-line max-lines-per-function
const CustomContactsComponent = () => {
    const css = useStyles();
    return (
        <div>
            <Grid container>
                <Grid item xs={12}>
                    <FormControl className={css.formRoot}>
                        <BoldText isTitle>{'TyMetrix Client Care Center Support Hours'}</BoldText>
                        <ContentText>
                            {'8:00 AM to 8:00 PM ET* Mon-Fri, excluding all holidays observed by TyMetrix.'}
                        </ContentText>
                        <ContentText>
                            {
                                '*If the Client Network has Global Service coverage, service begins at 8:00 PM ET on Sunday.'
                            }
                        </ContentText>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl className={css.formRoot}>
                        <BoldText isTitle>{'All Corporate Law Department and Insurance Users'}</BoldText>
                        <SubText>{'Phone'}</SubText>
                        <BoldText isTitle={false}>
                            {'Contact the Product Support Team at (860) 240-9059 or toll free using the numbers below.'}
                        </BoldText>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl className={css.formRoot}>
                        <SubText>{'Email'}</SubText>
                        <a href="mailto:T360Support@wolterskluwer.com">T360Support@wolterskluwer.com</a>
                        <ContentText>
                            {
                                'To help ensure faster resolution, please include any examples and steps to re-create the issue you are experiencing. Feel free to include screen captures if they help in describing the issue.'
                            }
                        </ContentText>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl className={css.formRoot}>
                        <BoldText isTitle>{'All Law Firms and Vendors'}</BoldText>
                        <SubText>{'Phone'}</SubText>
                        <BoldText isTitle={false}>
                            {'Contact the Vendor Support Team at (860) 549-8795 or toll free using the numbers below.'}
                        </BoldText>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl className={css.formRoot}>
                        <SubText>{'Email'}</SubText>
                        <a href="mailto:T360Support@wolterskluwer.com">T360Support@wolterskluwer.com</a>
                        <ContentText>
                            {
                                'To help ensure faster resolution, please include the name of the T360 client you are billing. For invoicing issues, include the TyMetrix Transaction Number found in the invoice submission report email you received from T360.'
                            }
                        </ContentText>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl className={css.formRoot}>
                        <SubText>{'Mail (USPS)'}</SubText>
                        <BoldText isTitle={false}>{'TyMetrix'}</BoldText>
                        <BoldText isTitle={false}>{'115 Glastonbury Blvd., Glastonbury, CT 06033'}</BoldText>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl className={css.formRoot}>
                        <SubText>{'Phone'}</SubText>
                        <BoldText isTitle={false}>{'(860) 549-8795 or toll free using the numbers below'}</BoldText>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl className={css.formRoot}>
                        <SubText>{'Fax'}</SubText>
                        <BoldText isTitle={false}>{'(860) 549-5149'}</BoldText>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl className={css.formRoot}>
                        <BoldText isTitle>{'Client Care Center Toll Free Phone Support'}</BoldText>
                        <ContentText>
                            {
                                'The below toll free numbers have been established for our corporate and law firm customers to reach our Client Care Center. Please use the number next to the country from which you are calling.'
                            }
                        </ContentText>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <BoldText isTitle>{'Argentina'}</BoldText>
                    <SubText>{'Phone'}</SubText>
                    <BoldText isTitle={false}>{'08002661656'}</BoldText>
                </Grid>
                <Grid item xs={6}>
                    <BoldText isTitle>{'Brazil'}</BoldText>
                    <SubText>{'Phone'}</SubText>
                    <BoldText isTitle={false}>{'08000474516'}</BoldText>
                </Grid>
                <Grid item xs={6}>
                    <BoldText isTitle>{'China'}</BoldText>
                    <SubText>{'Phone'}</SubText>
                    <BoldText isTitle={false}>{'4009985092'}</BoldText>
                </Grid>
                <Grid item xs={6}>
                    <BoldText isTitle>{'France'}</BoldText>
                    <SubText>{'Phone'}</SubText>
                    <BoldText isTitle={false}>{'0805542572'}</BoldText>
                </Grid>
                <Grid item xs={6}>
                    <BoldText isTitle>{'Germany'}</BoldText>
                    <SubText>{'Phone'}</SubText>
                    <BoldText isTitle={false}>{'08007239763'}</BoldText>
                </Grid>
                <Grid item xs={6}>
                    <BoldText isTitle>{'India'}</BoldText>
                    <SubText>{'Phone'}</SubText>
                    <BoldText isTitle={false}>{'0008004405201'}</BoldText>
                </Grid>
                <Grid item xs={6}>
                    <BoldText isTitle>{'Italy'}</BoldText>
                    <SubText>{'Phone'}</SubText>
                    <BoldText isTitle={false}>{'800931563'}</BoldText>
                </Grid>
                <Grid item xs={6}>
                    <BoldText isTitle>{'Netherlands'}</BoldText>
                    <SubText>{'Phone'}</SubText>
                    <BoldText isTitle={false}>{'08003949029'}</BoldText>
                </Grid>
                <Grid item xs={6}>
                    <BoldText isTitle>{'Saudi Arabia'}</BoldText>
                    <SubText>{'Phone'}</SubText>
                    <BoldText isTitle={false}>{'8008449449'}</BoldText>
                </Grid>
                <Grid item xs={6}>
                    <BoldText isTitle>{'United Kingdom'}</BoldText>
                    <SubText>{'Phone'}</SubText>
                    <BoldText isTitle={false}>{'08000284036'}</BoldText>
                </Grid>
                <Grid item xs={6}>
                    <BoldText isTitle>{'United States/Canada'}</BoldText>
                    <SubText>{'Phone'}</SubText>
                    <BoldText isTitle={false}>{'(877) 333-2568'}</BoldText>
                </Grid>
            </Grid>
        </div>
    );
};

const SubText: React.FC = ({ children }) => {
    const css = useStyles();
    return <Typography className={css.subText}>{children}</Typography>;
};

interface BoldTextProps {
    isTitle: boolean;
}

const BoldText: React.FC<BoldTextProps> = ({ isTitle, children }) => {
    const css = useStyles();
    return <Typography className={isTitle ? css.boldTitleText : css.boldContentText}>{children}</Typography>;
};

const ContentText: React.FC = ({ children }) => {
    const css = useStyles();
    return <Typography className={css.standardText}>{children}</Typography>;
};

export { CustomContactsComponent };
