import { IconButton, TablePagination, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useReduxSelector } from '../../hooks/useReduxSelector';
import useWindowWidth from '../../hooks/useWindowWidth';
import WkFirstPageIcon from '../icons/wkFirstPageIcon';
import WkLastPageIcon from '../icons/wkLastPageIcon';
import WkNextPageIcon from '../icons/wkNextPageIcon';
import WkPreviousPageIcon from '../icons/wkPreviousPageIcon';
import { measureStringWidth } from '../../utils/utilities';
import { useListScreenState } from './context/listScreenContext';
import { useRefreshList } from './context/listScreenHooks';
import ListScreenActionMenu from './listScreenActionMenu';
import { generatePagePostObject, scrollToTopOfEmbeddedList } from './listScreenHelpers';
import css from './listScreenPager.module.scss';

export interface IListScreenPagerProps {
    displayActionsBtn?: boolean;
    component?: 'div' | 'li';
}

const ListScreenPager: React.FC<IListScreenPagerProps> = React.memo(({ displayActionsBtn = true, component }) => {
    const appResources = useReduxSelector((state) => state.appResources);
    const listScreenState = useListScreenState();
    const pageData = listScreenState.listData!.page;
    const refreshList = useRefreshList();
    const windowWidth = useWindowWidth(100);
    const [displayValue, setDisplayValue] = useState('');
    const [displayValueTooltip, setDisplayValueTooltip] = useState('');
    const [pageNumberValue, setPageNumberValue] = useState('');
    const [pageNumberTooltip, setPageNumberTooltip] = useState('');

    const fromValue = pageData.totalRecords === 0 ? 0 : (pageData.pageNumber - 1) * pageData.pageSize + 1;
    const toValue =
        pageData.totalRecords !== -1
            ? Math.min(pageData.totalRecords, pageData.pageNumber * pageData.pageSize)
            : pageData.pageNumber * pageData.pageSize;
    const countValue = pageData.totalRecords === -1 ? -1 : pageData.totalRecords;
    const lastPageNumber = Math.max(0, Math.ceil(pageData.totalRecords / pageData.pageSize) - 1) + 1;

    useEffect(() => {
        const ELLIPSIS_LABEL = '...';
        // Label Display Row
        let labelDisplayRowsVal =
            fromValue.toLocaleString() +
            ' - ' +
            toValue.toLocaleString() +
            ' ' +
            appResources.listscreenPagerOf +
            ' ' +
            countValue.toLocaleString();
        let labelDisplayLength = measureStringWidth(labelDisplayRowsVal, '14px');
        setDisplayValueTooltip(labelDisplayRowsVal);

        // Pagination Action Area
        const ACTION_COMPONENT_SET_WIDTHS = 120; // leftPadding:14 + 4x12Icons:48 + input:40 + parentPadding:18
        const PG_TEXT_LENGTH = measureStringWidth(appResources.listscreenPagerPg, '14px') + 16;
        let pageValue = appResources.listscreenPagerOf + ' ' + lastPageNumber.toLocaleString();
        let pageTextWidth = measureStringWidth(pageValue, '14px') + 16;
        setPageNumberTooltip(pageValue);

        // Actions Menu Button
        const ACTION_BUTTON_WIDTH = measureStringWidth(appResources.buttonActions, '14px') + 55; // buttonWidth + padding:55

        const availableWidth = windowWidth - ACTION_BUTTON_WIDTH - ACTION_COMPONENT_SET_WIDTHS - PG_TEXT_LENGTH;

        let counter = 0;
        loop: while (availableWidth - labelDisplayLength - pageTextWidth < 0) {
            switch (counter) {
                case 0: {
                    if (pageValue.length > 2) {
                        pageValue =
                            appResources.listscreenPagerOf +
                            ' ' +
                            lastPageNumber.toString().substring(0, 2) +
                            ELLIPSIS_LABEL;
                        pageTextWidth = measureStringWidth(pageValue, '14px') + 16;
                    }
                    counter++;
                    break;
                }
                case 1: {
                    if (pageValue.length > 2) {
                        pageValue = ELLIPSIS_LABEL;
                        pageTextWidth = measureStringWidth(pageValue, '14px') + 16;
                    }
                    counter++;
                    break;
                }
                case 2: {
                    if (countValue.toString().length > 2) {
                        labelDisplayRowsVal =
                            fromValue.toLocaleString() +
                            ' - ' +
                            toValue.toLocaleString() +
                            ' ' +
                            appResources.listscreenPagerOf +
                            ' ' +
                            countValue.toString().substring(0, 2) +
                            ELLIPSIS_LABEL;
                        labelDisplayLength = measureStringWidth(labelDisplayRowsVal, '14px');
                    }
                    counter++;
                    break;
                }
                case 3: {
                    labelDisplayRowsVal = fromValue.toLocaleString() + ' - ' + toValue.toLocaleString();
                    labelDisplayLength = measureStringWidth(labelDisplayRowsVal, '14px');
                    counter++;
                    break;
                }
                case 4: {
                    if (toValue.toString().length > 2) {
                        labelDisplayRowsVal =
                            fromValue.toLocaleString() + ' - ' + toValue.toString().substring(0, 2) + ELLIPSIS_LABEL;
                        labelDisplayLength = measureStringWidth(labelDisplayRowsVal, '14px');
                    }
                    counter++;
                    break;
                }
                case 5: {
                    labelDisplayRowsVal = fromValue.toString().substring(0, 2) + ELLIPSIS_LABEL;
                    labelDisplayLength = measureStringWidth(labelDisplayRowsVal, '14px');
                    counter++;
                    break;
                }
                default: {
                    break loop;
                }
            }
        }

        setPageNumberValue(pageValue);
        setDisplayValue(labelDisplayRowsVal);
    }, [
        appResources.buttonActions,
        appResources.listscreenPagerOf,
        appResources.listscreenPagerPg,
        countValue,
        displayValue,
        fromValue,
        lastPageNumber,
        pageData.pageNumber,
        pageData.pageSize,
        pageData.totalRecords,
        toValue,
        windowWidth,
    ]);

    return (
        <div className={css.pagerBarRoot}>
            <TablePagination
                ActionsComponent={({ count, page, rowsPerPage, onChangePage }) => (
                    <TablePaginationActions
                        count={count}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onChangePage={onChangePage}
                        displayValue={displayValue}
                        displayValueTooltip={displayValueTooltip}
                        pageNumberValue={pageNumberValue}
                        pageNumberTooltip={pageNumberTooltip}
                    />
                )}
                rowsPerPageOptions={[]}
                classes={{
                    root: css.pagerContainer,
                    spacer: css.spacer,
                    toolbar: css.toolbar,
                    caption: css.pageInfo,
                }}
                labelDisplayedRows={() => ``}
                component={component ? component : 'div'}
                onChangePage={(_, pageNumber) => {
                    const fetchPostData = generatePagePostObject(pageData, {
                        pageNumber: pageNumber + 1,
                    });
                    if (pageData.pageNumber != pageNumber + 1) {
                        refreshList({ postObject: fetchPostData });
                    }
                    if (listScreenState.isEmbeddedList) {
                        scrollToTopOfEmbeddedList();
                    }
                }}
                count={pageData.totalRecords}
                rowsPerPage={pageData.pageSize}
                page={pageData.pageNumber - 1}
            />

            {displayActionsBtn && (
                <div className={css.buttonsContainer}>
                    <ListScreenActionMenu />
                </div>
            )}
        </div>
    );
});

interface ITablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => void;
    displayValue: string;
    displayValueTooltip: string;
    pageNumberValue: string;
    pageNumberTooltip: string;
}

const TablePaginationActions = (props: ITablePaginationActionsProps) => {
    const appResources = useReduxSelector((state) => state.appResources);
    const {
        count,
        page,
        rowsPerPage,
        onChangePage,
        displayValue,
        displayValueTooltip,
        pageNumberValue,
        pageNumberTooltip,
    } = props;
    const [currentPageValue, setCurrentPageValue] = useState<string>((page + 1).toString());
    const lastPageNumber = Math.max(0, Math.ceil(count / rowsPerPage) - 1);

    useEffect(() => {
        setCurrentPageValue((page + 1).toString());
    }, [page]);

    const handlePageNumberChange = () => {
        const isNumber =
            currentPageValue.trim().length > 0
                ? !currentPageValue.match(/[a-z!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/i)
                : false;
        let newPageNumber = parseInt(currentPageValue);

        if (!isNumber || isNaN(newPageNumber) || newPageNumber < 1) {
            newPageNumber = 1;
        } else if (newPageNumber > lastPageNumber + 1) {
            newPageNumber = lastPageNumber + 1;
        }
        setCurrentPageValue(newPageNumber.toString());
        onChangePage(null, newPageNumber - 1);
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        if (e.charCode == 13) {
            setCurrentPageValue(target.value);
            target.blur();
        }
    };

    // Place page number details into pagination if width is large enough
    const pageNumberDetails = (
        <React.Fragment>
            <Tooltip
                placement="top-start"
                title={pageNumberTooltip}
                classes={{
                    tooltip: css.tooltip,
                }}>
                <span className={css.totalPageNumberArticle}>{pageNumberValue}</span>
            </Tooltip>
        </React.Fragment>
    );

    return (
        <>
            <div className={css.pagerDisplayValue}>
                <Tooltip
                    placement="top-start"
                    title={displayValueTooltip}
                    classes={{
                        tooltip: css.tooltip,
                    }}>
                    <span>{displayValue}</span>
                </Tooltip>
            </div>
            <div className={css.pagerActionsContainer}>
                <Tooltip
                    placement="top-start"
                    title={page !== 0 ? appResources.listscreenPagerFirstPage : ''}
                    classes={{
                        tooltip: css.tooltip,
                    }}>
                    <IconButton
                        size="small"
                        onClick={(e) => onChangePage(e, 0)}
                        disabled={page === 0}
                        aria-label={appResources.listscreenPagerFirstPage}
                        color="primary"
                        className={css.pagerButton}>
                        <WkFirstPageIcon className={css.icon} />
                    </IconButton>
                </Tooltip>

                <Tooltip
                    placement="top-start"
                    title={page !== 0 ? appResources.listscreenPagerPreviousPage : ''}
                    classes={{
                        tooltip: css.tooltip,
                    }}>
                    <IconButton
                        size="small"
                        onClick={(e) => onChangePage(e, page - 1)}
                        disabled={page === 0}
                        aria-label={appResources.listscreenPagerPreviousPage}
                        color="primary"
                        className={css.pagerButton}>
                        <WkPreviousPageIcon className={css.icon} />
                    </IconButton>
                </Tooltip>

                <span className={css.pageNumberArticleStart}>{appResources.listscreenPagerPg}</span>
                <input
                    className={css.pageNumberInput}
                    type="text"
                    data-testid="pageNumberInput"
                    aria-label={appResources.listscreenPagerPageNumber}
                    value={currentPageValue}
                    onChange={(e) => setCurrentPageValue(e.target.value)}
                    onBlur={handlePageNumberChange}
                    onKeyPress={handleKeyPress}
                />
                {pageNumberDetails}

                <Tooltip
                    placement="top-start"
                    title={page >= lastPageNumber ? '' : appResources.listscreenPagerNextPage}
                    classes={{
                        tooltip: css.tooltip,
                    }}>
                    <IconButton
                        size="small"
                        onClick={(e) => onChangePage(e, page + 1)}
                        disabled={page >= lastPageNumber}
                        aria-label={appResources.listscreenPagerNextPage}
                        color="primary"
                        className={css.pagerButton}>
                        <WkNextPageIcon className={css.icon} />
                    </IconButton>
                </Tooltip>

                <Tooltip
                    placement="top-start"
                    title={page >= lastPageNumber ? '' : appResources.listscreenPagerLastPage}
                    classes={{
                        tooltip: css.tooltip,
                    }}>
                    <IconButton
                        size="small"
                        onClick={(e) => onChangePage(e, lastPageNumber)}
                        disabled={page >= lastPageNumber}
                        aria-label={appResources.listscreenPagerLastPage}
                        color="primary"
                        className={css.pagerButton}>
                        <WkLastPageIcon className={css.icon} />
                    </IconButton>
                </Tooltip>
            </div>
        </>
    );
};

ListScreenPager.displayName = 'ListScreenPager';

export default ListScreenPager;
