import { IDocTransformer } from '../../interfaces/transformer/type';
import { injectable } from 'inversify';
import { factory } from '../../ConfigLog4j';
import { IMatterDoc, IPollFiles } from '../../types';
import { T360AdapterGetNotificationFilesActionableEntity } from '../actionableEntity/getNotificationFilesActionableEntity';
import { IDocOperationInvocation } from '../../interfaces/urlPattern/types';

const log = factory.getLogger('T360AdapterGetNotificationFilesEntityTransformer');

@injectable()
export class T360AdapterGetNotificationFilesEntityTransformer implements IDocTransformer {
    transformIntoActionableEntity(
        nodeObj: IPollFiles,
        _matterDoc: IMatterDoc,
        _urlPattern: IDocOperationInvocation,
    ): T360AdapterGetNotificationFilesActionableEntity {
        log.debug('Calling transformIntoActionableEntity');
        const obj = {} as T360AdapterGetNotificationFilesActionableEntity;
        // TO CREATE ACTIONABLE ENTITY FROM nodeObj and matterDoc
        if (nodeObj?.guids) {
            obj.guids = nodeObj.guids;
        }
        return obj;
    }
}
