import { AppProps, initialize, InitializeAppCallback } from '../../../initialize';
import { config } from '../config';
import { TokenAuthenticationProvider } from '../infrastructure/TokenAuthenticationProvider';
import { collectQueryParams, getSignInArgs, getUserManager } from '../infrastructure/oidc';
import { UserManager } from 'oidc-client';
import { CustomContactsComponent } from './CustomContactsComponent';
import { CustomAboutComponent } from './CustomAboutComponent';
import { ConnectivityService } from '../infrastructure/connectivityService';
import { RouteManagementService } from '../infrastructure/routeManagementService';
import { getLogger } from '@wk/elm-uui-common';
import { AppProvider } from './appProvider';

declare global {
    interface Window {
        // tslint:disable-next-line: no-any
        Props: (AppProps & { userManager: UserManager }) | any;
    }
}

function setupProps() {
    window.Props = {
        apiContextRoot: `${config.get('REACT_APP_API_URL')}/api`,
        apiContextPath: '/v4/views',
        uiContextRoot: config.get('REACT_APP_UI_URL'),
        staticContext: config.get('REACT_APP_UI_URL'),
        token: 'foobar',
        initializationPath: '/initialize',
        iconPath: '/icons/icons.svg',
        timeoutPath: '/timeout',
        basePath: '',
        pageNotFoundParam: 'pageNotFoundParam',
        encryptionKey: 'key',
        userManager: getUserManager(),
        noInternetConnection: 'Unable to connect to server',
        reconnecting:
            'Application will automatically reconnect when the connection resumes. Please check your internet connection. If the problem persists, contact your administrator.',
        serverChangesDetectedHeading: 'Server Changes Detected',
        serverChangesDetectedMessage:
            'To retrieve the server changes and refresh all of your OC instances, click Refresh Now.',
        buttonRefreshNow: 'Refresh Now',
        errorOverlayHeading: 'Something went wrong',
        errorOverlayMessage:
            'If the problem persists, please contact your Network Administrator. You can return to the My Matters section from here.',
        returnToHomeButton: 'Go to My Matters',
        unauthorizedAccess: 'You do not have access to this object. Contact Admin to get permission.',
        username: 'user',
        permissionErrorOverlayHeading: 'Access Denied',
        permissionErrorOverlayMessage: 'Access to this content requires permission.',
        permissionErrorOverlayMessage2: 'Please contact your network administrator to get access.',
        logOffUser: 'Log Out',
    };
}

let isOidcCallbackHandled = false;
async function beforeInitializePassport() {
    collectQueryParams();
    // Handle oidccallback before Passport initialize as it can change urls during init
    if (window.location.pathname.indexOf('oidccallback') > -1) {
        await getUserManager().signinRedirectCallback();
        isOidcCallbackHandled = true;
    }
}

export const InitializePassport = () => {
    beforeInitializePassport().then(() => {
        setupProps();
        initialize({
            UUICustomComponents: {
                CustomToolbarIconsComponent: undefined,
                CustomContactsComponent,
                CustomAboutComponent,
                AppProvider,
            },
            applicationAuthProvider: TokenAuthenticationProvider,
            initializeAppCallback,
            UUIConfiguration: {
                isSingleTabSavedViewMode: false,
                heartBeatFunction: ConnectivityService.heartBeatFunction,
            },
        });
    });
};

// This callback stops UUI execution until get's resolved
const initializeAppCallback: InitializeAppCallback = (_, history) => {
    const getLog = () => getLogger('t360.InitializePassport');

    async function handleOidcCallback() {
        try {
            const userManager = getUserManager();
            const authorizedUser = await userManager.getUser();
            if (authorizedUser != null) {
                return await handleRouteManagment(authorizedUser.state);
            }
            const user = await userManager.signinRedirectCallback();
            // if we here then auth is done. need to redirect out from oidccallback endpoint
            return await handleRouteManagment(user.state);
        } catch (error) {
            getLog().error('Error: ', error);
            await ConnectivityService.handleOidcNetworkError(error);
        }
        return false;
    }

    // tslint:disable-next-line
    async function handleRouteManagment(userStateProps: any) {
        try {
            if (userStateProps != null && userStateProps.deepLink != null && userStateProps.deepLink.pathname !== '/') {
                // Hide authentication attributes in oidccallback for better back button support.
                if (await RouteManagementService.isSupported()) {
                    await RouteManagementService.setDefaultUrl(window.location.href);
                    await RouteManagementService.reloadUrl();
                }
                history.replace('/');
                // Replace url to original deep link url.
                history.push(userStateProps.deepLink);
                window.location.reload();
            } else {
                await reloadBaseUrl();
                return true;
            }
        } catch (error) {
            getLog().error('Error: ', error);
            await ConnectivityService.handleOidcNetworkError(error);
        }
        return false;
    }

    async function handleOidc() {
        const userManager = getUserManager();

        // Need to check if we in callback endpoint
        if (window.location.pathname.indexOf('oidccallback') > -1) {
            getLog().debug('Handling oidc response.');
            return await handleOidcCallback();
        } else if (isOidcCallbackHandled === true) {
            isOidcCallbackHandled = false;
            await reloadBaseUrl();
            return true;
        }

        // we are not in auth response handler
        const user = await userManager.getUser();
        if (user && !user.expired) {
            getLog().debug('User is OK, loading application.');
            return true;
        }

        getLog().debug('User is not OK. Need auth.');
        try {
            if (user && user.refresh_token) {
                // Stopping silent renew to disable refresh token errors event
                // This is needed to have different handling (start auth) on app start
                userManager.stopSilentRenew();

                getLog().debug('Trying to authenticate using existing refresh token.');
                const signedIdUser = await userManager.signinSilent();
                if (signedIdUser) {
                    getLog().debug('Successfully authenticated using refresh token.');
                    location.reload();
                    return false; // doesn't matter what we return here since this will refresh the page.
                }
            }
        } catch (error) {
            getLog().error('Error: ', error);
            await ConnectivityService.handleOidcNetworkError(error);
        }

        try {
            getLog().debug('No refresh token or refresh token is invalid. Need to authenticate via redirect.');
            await userManager.signinRedirect(getSignInArgs());
        } catch (error) {
            getLog().error('Error: ', error);
            await ConnectivityService.handleOidcNetworkError(error);
        }
        return false;
    }

    return new Promise((resolve) => {
        handleOidc().then((canContinue) => {
            if (canContinue) {
                resolve();
            }
        });
    });

    async function reloadBaseUrl() {
        if (await RouteManagementService.isSupported()) {
            await RouteManagementService.clearDefaultUrl();
            await RouteManagementService.reloadUrl();
        }
        history.push('/');
    }
};
