import {
    ICHUploadType,
    ICHUploadResponse,
    IValidateAddDocument,
    ICHValidateAddDocument,
} from '../interfaces/operations';
import { IDialogButton } from './interface/dialogButton.interface';
import { DocumentEntity } from '../dto/documentEntity';
import { Locale } from './../locale';
import { ScreenHandler } from './../screenHandler/screenHandler';
import {
    IAddDocument,
    addDocument,
    DCResponse,
    validateDocument,
    splitNameAndExtension,
    DocumentNameSplit,
} from '@wk/elm-uui-doc-component';
import { inject, injectable } from 'inversify';
import 'reflect-metadata';
import { EventType, ToastType, CapabiltyEnum, ScreenName, DocumentState, EntityTypes } from '../enum/enum';
import { getReverseTransformedObject, getTransformedObject } from '../transform/helper';
import { CtxAddTransformer } from '../transform/ctxAddDocumentTransformer';
import { Dialog } from '../configurations';
import { factory } from '../configLog4J';
import { handleUnExpectedError, throwUnExpectedError, deleteFromPersistentStorage } from '../utils/main.utils';
import { CtxValidateAddDocumentTransformer } from '../transform/ctxValidateAddDocumentTransformer';
import { implementQueue } from '../capability';
import { IOfficeService } from './office.service';
import { IEventService } from './eventContextHandler.service';
import { IDocumentsService } from './documents.service';
import { CheckInService } from './checkIn.service';
import { MessageService } from './message.service';
import { DialogService } from './dialog.service';
import { FileExtensions } from '../enum/file-extensions.enum';
import { IEmailService } from './email.service';

const log = factory.getLogger('DocumentUploadService');

export interface IAddDocumentQueue extends IAddDocument {
    filePath?: string;
}

@injectable()
export class DocumentUploadService {
    private _officeService: IOfficeService;
    private _eventService: IEventService;
    private _screenHandler: ScreenHandler;
    private _documentsService: IDocumentsService;
    private _checkInService: CheckInService;
    private _messageService: MessageService;
    private _dialogService: DialogService;
    private _emailService: IEmailService;
    // private _chMessageService: Services.CHMessagingService;
    private fileName: string;
    private documentEntity: DocumentEntity | undefined;

    constructor(
        @inject('OfficeService') officeService: IOfficeService,
        @inject('EventContextHandler') eventService: IEventService,
        @inject('ScreenHandler') screenHandler: ScreenHandler,
        @inject('DocumentsService') documentsService: IDocumentsService,
        @inject('MessageService') messageService: MessageService,
        @inject('CheckInService') checkInService: CheckInService,
        @inject('DialogService') dialogService: DialogService,
        @inject('EmailService') emailService: IEmailService,
        // @inject('CHMessagingService') chMessageService: Services.CHMessagingService,
    ) {
        this._officeService = officeService;
        this._eventService = eventService;
        this._screenHandler = screenHandler;
        this._documentsService = documentsService;
        this._checkInService = checkInService;
        this._messageService = messageService;
        this._dialogService = dialogService;
        this._emailService = emailService;
        // this._chMessageService = chMessageService;

        this.fileName = '';
    }

    public async processDocumentUpload(contextObj: ICHUploadType, forceSaveAs = false): Promise<ICHUploadResponse> {
        // Check stale validation when QF or DND document on folder
        if (contextObj.folderId) {
            const documentEntity = await this._documentsService.wasStaleDocument(
                contextObj,
                DocumentState.FOLDER_EXIST,
            );
            if (!documentEntity) {
                return { responseText: Locale.stale.deleted_folder };
            }
        }

        if (contextObj.folderArr && !contextObj.isSaveOrOverwrite) {
            this._officeService.showEmailOrDocumentUploadToast(contextObj.folderArr[0], CapabiltyEnum.ADD_DOCUMENT);
        }

        if (forceSaveAs) {
            await this.setFileName(contextObj);
            const documentNameSplit: DocumentNameSplit = splitNameAndExtension(this.fileName);
            const itemScreenRequest = await this._screenHandler.getItemScreenRequest(
                ScreenName.QUICKFILE_ADD_DOCUMENT,
                {
                    name: documentNameSplit.name,
                    extension: documentNameSplit.extension,
                    fileName: this.fileName,
                    comments: '',
                    parentEntityId: contextObj.associatedEntityTypeId || '',
                    folderId: contextObj.folderId || '',
                },
                contextObj,
                Locale.itemPage.quickfile_save_with_new_name_document,
            );
            log.info('OC | Save with new name Doc Item Screen ' + JSON.stringify(itemScreenRequest));
            this._eventService.publish({
                name: EventType.ITEMSCREEN,
                itemScreen: itemScreenRequest,
            });

            const message = this._messageService.compileTemplate(Locale.documents.save_with_new_name, {
                fileName: this.fileName,
            });
            this._eventService.publish({
                name: EventType.TOAST,
                toast: {
                    toastMessage: message,
                    type: ToastType.WARNING,
                },
            });

            return { responseText: Locale.responseText.itemScreen_save_with_new_name_document };
        }

        await this.setFileName(contextObj);
        await this.updateDocumentToLatest(contextObj);

        if (this.documentEntity && this.documentEntity.id) {
            if (contextObj.isDragAndDrop) {
                const currentDocument = await this._officeService.getCurrentDocumentFullName();
                const isActiveDocument: boolean = contextObj.filePaths
                    ? await this._officeService.compareFilePaths(contextObj.filePaths[0], currentDocument)
                    : false;
                const isDocumentSaved: boolean = await this._officeService.isDocumentSaved();
                if (!isDocumentSaved && isActiveDocument) {
                    return await this.checkIfQuickFileDocumentSaved(contextObj);
                }
            } else if (
                !contextObj.isDragAndDrop &&
                ((await this._officeService.isNewDocument()) || !(await this._officeService.isDocumentSaved()))
            ) {
                return await this.checkIfQuickFileDocumentSaved(contextObj);
            }

            await this.setFileName(contextObj);
            await this.updateDocumentToLatest(contextObj);

            if (this.documentEntity && this.documentEntity.id) {
                // document with same name exists
                log.info('OC | Doc with same name already exists');
                log.info('OC | Render Overwrite Dialog');
                contextObj.documentId = this.documentEntity.id;
                return await this.prepareOverwriteSaveAsDialog(contextObj);
            }
        } else {
            if (
                !contextObj.isDragAndDrop &&
                ((await this._officeService.isNewDocument()) || !(await this._officeService.isDocumentSaved()))
            ) {
                return await this.checkIfQuickFileDocumentSaved(contextObj);
            }
        }

        await this.setFileName(contextObj);

        const documentNameSplit: DocumentNameSplit = splitNameAndExtension(this.fileName);
        let documentName: string = documentNameSplit.name;

        if (
            contextObj.associatedEntityType == EntityTypes.Invoice &&
            (documentNameSplit.extension == FileExtensions.Msg || documentNameSplit.extension == FileExtensions.Eml)
        ) {
            documentName = await this.getEmailFileName(contextObj);
        }
        const itemScreenRequest = await this._screenHandler.getItemScreenRequest(
            ScreenName.QUICKFILE_ADD_DOCUMENT,
            {
                name: documentName,
                extension: documentNameSplit.extension,
                fileName: this.fileName,
                comments: '',
                parentEntityId: contextObj.associatedEntityTypeId || '',
                folderId: contextObj.folderId || '',
            },
            contextObj,
            Locale.itemPage.quickfile_add_document,
        );
        log.info('OC | Add Doc Item Screen ' + JSON.stringify(itemScreenRequest));
        this._eventService.publish({
            name: EventType.ITEMSCREEN,
            itemScreen: itemScreenRequest,
        });
        return { responseText: Locale.responseText.itemScreen_quickfile_add_document };
    }

    private async getEmailFileName(contextObj: ICHUploadType): Promise<string> {
        const newEmailData = await this._emailService.getEmailMetaData(contextObj.filePaths!);
        let emailFileName = `${newEmailData[0].emailSubject}_${newEmailData[0].receivedTime}`;
        emailFileName = emailFileName.replace(/[*|?:"<>\\\\/]/gi, ''); // sanitize file name with special characters not allowed *|?:"<>\/.
        return emailFileName;
    }

    private async updateDocumentToLatest(contextObj: ICHUploadType): Promise<void> {
        this.documentEntity =
            (await this._documentsService.getDocumentMeta(contextObj, !!contextObj.documentId)) || undefined;
    }

    private async prepareOverwriteSaveAsDialog(contextObj: ICHUploadType): Promise<ICHUploadResponse> {
        if (this.documentEntity) {
            const docEntity = this.documentEntity;
            let dialogButtons: IDialogButton[] = [];
            let warningMessage = '',
                title = Dialog.document.upload_document.title;
            await this.setFileName(contextObj);
            const cancelButton = {
                label: Dialog.buttons.cancel,
                callback: async () => {
                    await deleteFromPersistentStorage(contextObj.filePaths);
                    return;
                },
            };

            const saveAsNewButton = {
                label: Dialog.buttons.save_with_a_new_name,
                callback: async () => {
                    try {
                        contextObj.fileName = this.fileName;
                        contextObj.name = this.fileName;
                        log.info('OC | SaveAsNew Doc');
                        contextObj.isSaveOrOverwrite = true;
                        return await this.processDocumentUpload(contextObj, true);
                    } catch (error) {
                        log.error(JSON.stringify(error));
                        handleUnExpectedError(error);
                        return;
                    }
                },
            };

            const overwriteButton = {
                label: Dialog.buttons.overwrite,
                callback: async () => {
                    try {
                        contextObj.fileName = this.fileName;
                        contextObj.name = this.fileName;
                        contextObj.isSaveOrOverwrite = true;
                        await this._checkInService.processDocumentCheckIn(contextObj, true, this.documentEntity);
                    } catch (error) {
                        log.error(JSON.stringify(error));
                        handleUnExpectedError(error);
                    }
                },
            };

            const isCheckedOutByOther = this._documentsService.isCheckedOutByOther(
                contextObj.loggedInUser || '',
                this.documentEntity.versionSeriesCheckedOutBy,
            );

            if (isCheckedOutByOther) {
                dialogButtons = [saveAsNewButton, cancelButton];
                warningMessage = this._messageService.compileTemplate(
                    Locale.documents.doc_checked_out_to_different_user,
                    {
                        lockedBy: docEntity.versionSeriesCheckedOutBy,
                        fileName: this.fileName,
                    },
                );
            } else {
                const regExToRemoveStartSpaces = /^\s+/g;
                const fileName = this.fileName.replace(regExToRemoveStartSpaces, '');
                if (await this._officeService.compareFilePaths(docEntity.documentFileName, fileName)) {
                    dialogButtons = [overwriteButton, saveAsNewButton, cancelButton];
                    warningMessage = this._messageService.compileTemplate(Locale.documents.quickfile_documentFile, {
                        fileName: fileName,
                        documentName: docEntity.documentFileName,
                    });
                } else {
                    title = Dialog.document.document_mismatch.title;
                    const closeButton = {
                        label: Dialog.buttons.close,
                        callback: () => {
                            return;
                        },
                    };
                    dialogButtons = [closeButton];
                    warningMessage = Dialog.document.document_mismatch.message;
                }
            }
            log.info('OC | Dialog ' + warningMessage);
            log.info('OC | Dialog Buttons' + JSON.stringify(dialogButtons));
            this._dialogService.showDialog(
                {
                    title: title,
                    message: warningMessage,
                    buttons: dialogButtons,
                },
                contextObj.filePaths,
            );
            return { responseText: warningMessage };
        } else {
            log.info('OC | Document not found');
            return { responseText: 'Document not found' };
        }
    }

    public async addNewDocument(contextObj: ICHUploadType): Promise<ICHUploadResponse> {
        if (!contextObj.filePaths || (contextObj.filePaths && contextObj.filePaths?.length == 0)) {
            contextObj.filePaths = [];
            contextObj.filePaths[0] = await this._officeService.getCurrentDocumentFullName();
        }
        log.info('OC | Add New Document');
        const data: IAddDocumentQueue = getTransformedObject<ICHUploadType, IAddDocument, CtxAddTransformer>(
            CapabiltyEnum.ADD_DOCUMENT,
            contextObj,
        );

        // Added for Activity pane. For T360 documentName is not available in payload
        if (contextObj.payload) {
            if (contextObj.payload.name) {
                contextObj.payload.name.value = data.name || '';
            }
            if (contextObj.payload.documentName) {
                contextObj.payload.documentName.value = data.documentName || '';
            } else {
                contextObj.payload.documentName = { value: data.documentName || '' };
            }
            if (contextObj.payload.document_original_type) {
                contextObj.payload.document_original_type.value = data.documentType || '';
            }
        }
        const response = await validateDocument(data);
        if (response && response.ok) {
            if (!contextObj.noOfItems) {
                contextObj.noOfItems = 1;
            }
            const result = await implementQueue(contextObj, CapabiltyEnum.ADD_DOCUMENT);
            return { response: result.response };
        } else {
            log.info('OC | validation failed');
            const transformData = { errors: {} };
            transformData.errors = getReverseTransformedObject<
                IValidateAddDocument,
                ICHValidateAddDocument,
                CtxValidateAddDocumentTransformer
            >(CapabiltyEnum.VALIDATE_ADD_DOCUMENT, response.statusInfo.errors as unknown as IValidateAddDocument);

            response.statusInfo.errors = transformData.errors;
            const transformedResponse = this._documentsService.transformValidationResponse(response);
            return { response: transformedResponse };
        }
    }

    public async fileUpload(contextObj: ICHUploadType): Promise<ICHUploadResponse> {
        const data: IAddDocumentQueue = getTransformedObject<ICHUploadType, IAddDocument, CtxAddTransformer>(
            CapabiltyEnum.ADD_DOCUMENT,
            contextObj,
        );
        let response: DCResponse;
        try {
            data.filePath = contextObj.filePaths && contextObj.filePaths[0];
            const { fileUploadControlId } = this._documentsService.createFileUploadControl();
            data.fileId = fileUploadControlId;
            const files = await this._documentsService.getInjectedFile(data.fileId, data.filePath);
            if (files && files.length > 0) {
                log.info('OC | Add New Document | File Size ' + files[0].size);
                if (files[0].size === 0) {
                    log.error(Locale.failure.blank_document);
                    return { responseText: Locale.failure.blank_document };
                }
                data.file = files[0];
                // needed for virus scaning
                data.extendedProps.uploadGuid = contextObj.uploadGuid;
                response = await addDocument(data);
                return { response };
            }
        } catch (error) {
            if (error) {
                log.error('OC | Add Document Error ' + error);
            }
            log.info('OC | Error during add Doc ' + JSON.stringify(error));
            throwUnExpectedError(error);
            return { responseText: (error as Error).message };
        }
        return { responseText: 'Error occured | Document not uploaded successfully' };
    }

    private async setFileName(contextObj: ICHUploadType) {
        // DnD Operations
        if (!contextObj.isQuickFile && contextObj.filePaths && contextObj.filePaths?.length > 0) {
            this.fileName = await this._officeService.getFileNameFromFullPath(contextObj.filePaths[0]);
            log.info('OC | File Name ' + this.fileName);
            contextObj.fileName = this.fileName;
            contextObj.name = this.fileName;
        } else {
            // QF Operations
            this.fileName = await this._officeService.getCurrentDocument();
            log.info('OC | File Name ' + this.fileName);
            contextObj.fileName = this.fileName;
            contextObj.name = this.fileName;
            contextObj.filePaths = [];
            contextObj.filePaths.push(await this._officeService.getCurrentDocumentFullName());
        }
    }

    private async checkIfQuickFileDocumentSaved(contextObj: ICHUploadType): Promise<ICHUploadResponse> {
        const isNewDocument = await this._officeService.isNewDocument();
        const isDocumentSaved = await this._officeService.isDocumentSaved();
        if (isNewDocument || !isDocumentSaved) {
            await this.prepareSaveDocumentDialog(contextObj, isNewDocument);
        }
        return { responseText: Locale.responseText.file_not_saved };
    }

    private async prepareSaveDocumentDialog(
        contextObj: ICHUploadType,
        isNew?: boolean,
        checkIn?: boolean,
    ): Promise<void> {
        const cancelButton = {
            label: Dialog.buttons.cancel,
            callback: async () => {
                await deleteFromPersistentStorage(contextObj.filePaths);
                return;
            },
        };

        const saveButton = {
            label: Dialog.buttons.save,
            callback: async () => {
                try {
                    const isSaved = await this._officeService.saveActiveDocument();
                    if (isSaved) {
                        contextObj.isSaveOrOverwrite = true;
                        await this.processDocumentUpload(contextObj);
                    }
                } catch (error) {
                    log.error(JSON.stringify(error));
                    handleUnExpectedError(error);
                }
            },
        };

        const saveAsButton = {
            label: Dialog.buttons.save_as,
            callback: async () => {
                try {
                    const isSaved = await this._officeService.saveAsActiveDocument();
                    if (isSaved) {
                        contextObj.filePaths = [];
                        contextObj.filePaths.push(await this._officeService.getCurrentDocumentFullName());
                        await this.setFileName(contextObj);
                        contextObj.isSaveOrOverwrite = true;
                        await this.processDocumentUpload(contextObj);
                    }
                } catch (error) {
                    log.error(JSON.stringify(error));
                    handleUnExpectedError(error);
                }
            },
        };

        const saveAndUpload = { ...saveAsButton, label: Dialog.buttons.save_and_upload };
        const readOnlyDocument = await this._officeService.isReadOnly();
        const messageTemplate =
            isNew || checkIn
                ? 'upload_new_active_document'
                : readOnlyDocument
                ? 'upload_exist_active_saveas_document'
                : 'upload_exist_active_document';
        const title = Dialog.confirm[messageTemplate].title;
        const message = this._messageService.compileTemplate(Dialog.confirm[messageTemplate].message);
        const buttons =
            isNew || checkIn
                ? [checkIn ? saveButton : saveAndUpload, cancelButton]
                : readOnlyDocument
                ? [saveAsButton, cancelButton]
                : [saveButton, saveAsButton, cancelButton];

        this._dialogService.showDialog(
            {
                title,
                message,
                buttons,
            },
            contextObj.filePaths,
        );
    }
}
