import { ITransformer } from './types';
import { ICHDeleteEmail } from '../interfaces/operations';
import { ICancelCheckOut, IDeleteEmail } from '@wk/elm-uui-doc-component';
import 'reflect-metadata';
import { injectable } from 'inversify';

@injectable()
export class CtxDeleteEmailTransformer implements ITransformer {
    data!: ICHDeleteEmail;

    transform(): IDeleteEmail {
        const nodeObj = {} as ICancelCheckOut;
        nodeObj.docId = this.data.documentId;
        nodeObj.entityContext = {
            name: this.data?.associatedEntityName || '',
            id: this.data?.associatedEntityId,
            type: this.data?.associatedEntityType,
        };
        return nodeObj;
    }
}
