export class OidcStorageHelper {
    static get idKey() {
        return 'uui_id';
    }

    static get secretKey() {
        return 'uui_secret';
    }

    static get refreshTokenTempKey() {
        return 'uui_refresh_token_temp';
    }

    static get ssoIdKey() {
        return 'uui_sso_id';
    }

    static getId() {
        let result = window.localStorage.getItem(this.idKey);
        if (result === null) {
            result = this.uuid4();
            window.localStorage.setItem(this.idKey, result);
        }
        return result;
    }

    static getSecret() {
        let result = window.localStorage.getItem(this.secretKey);
        if (result === null) {
            result = this.uuid4();
            window.localStorage.setItem(this.secretKey, result);
        }
        return result;
    }

    static getSsoId() {
        return window.localStorage.getItem(this.ssoIdKey);
    }

    static setSsoId(ssoId: string) {
        window.localStorage.setItem(this.ssoIdKey, ssoId);
    }

    static removeSsoId() {
        window.localStorage.removeItem(this.ssoIdKey);
    }

    static uuid4() {
        const ho = (n: number, p: number) => n.toString(16).padStart(p, '0');
        const view = new DataView(new ArrayBuffer(16));
        crypto.getRandomValues(new Uint8Array(view.buffer));
        view.setUint8(6, (view.getUint8(6) & 0xf) | 0x40);
        view.setUint8(8, (view.getUint8(8) & 0x3f) | 0x80);
        return `${ho(view.getUint32(0), 8)}-${ho(view.getUint16(4), 4)}-${ho(view.getUint16(6), 4)}-${ho(
            view.getUint16(8),
            4,
        )}-${ho(view.getUint32(10), 8)}${ho(view.getUint16(14), 4)}`; // / Compile the canonical textual form from the array data
    }
}
