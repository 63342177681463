import React, { useEffect } from 'react';
import classNames from 'classnames';
import Link from '../common/link';
import { ScreenMode } from '../common/types';
import WkFolderIcon from '../icons/wkFolderIcon';
import css from './listScreenField.module.scss';
import {
    getCellDisplayValue,
    getListLinkPath,
    isFieldALink,
    isFolderRow,
    getColumnKey,
    getCell,
    isDocumentRow,
    isFieldAShowDocumentLink,
} from './listScreenHelpers';
import { IListScreenColumn, IListScreenRow } from './types';
import { useListScreenDispatch, useListScreenState } from './context/listScreenContext';
import { getItemScreenPropsFromUrl } from '../itemScreen/itemScreenHelpers';
import { Button } from '@material-ui/core';
import { isOC } from '../contextLayerService/contextLayerService';
import useCancellablePromise from '../../hooks/useCancellablePromise';
import { useRefreshList } from './context/listScreenHooks';
import { performListScreenContextLayerOperation } from '../contextLayerService/contextLayerHelpers';
import { ICHUploadResponse } from '@wk/elm-uui-context-handler';
import { useReduxSelector } from '../../hooks/useReduxSelector';
import { useReduxDispatch } from '../../hooks/useReduxDispatch';

interface IListScreenFieldProps {
    row: IListScreenRow;
    column: IListScreenColumn;
    mode?: ScreenMode;
    folderIcon?: React.ReactElement;
    fileIcon?: React.ReactElement;
}

const ListScreenField: React.FC<IListScreenFieldProps> = ({ row, column, mode, folderIcon, fileIcon }) => {
    const appResources = useReduxSelector((state) => state.appResources);
    const reduxDispatch = useReduxDispatch();
    const listScreenState = useListScreenState();
    const listScreenDispatch = useListScreenDispatch();
    const metadata = listScreenState.metadata!;
    const refreshList = useRefreshList();
    const cancellablePromise = useCancellablePromise<string | ICHUploadResponse | Response>({
        trackPromise: false,
    });
    const rowIsDisabled = listScreenState.disabledRows.indexOf(row.id) > -1;

    useEffect(() => {
        // The returned method is invoked when this component unmounts
        // no matter what, when this component unmounts, make sure the row gets enabled
        return () => {
            if (rowIsDisabled) {
                listScreenDispatch({ type: 'EnableRow', id: row.id });
            }
        };
    }, [listScreenDispatch, row.id, rowIsDisabled]);

    const shouldRenderLinkBasedOnScreenMode = (): boolean => {
        // if no mode is provided in the query string, always render the link
        if (!mode) {
            return true;
        }

        const cell = getCell(row, getColumnKey(column))!;
        if (mode === 'add') {
            return cell.displayLinkOnAdd!;
        }
        if (mode === 'edit') {
            return cell.displayLinkOnEdit!;
        }
        if (mode === 'show') {
            return cell.displayLinkOnShow!;
        }
        // shouldn't get here
        return false;
    };

    const isTargetScreenNormalRenderingStyle = (): boolean => {
        return getCell(row, getColumnKey(column))!.targetScreenRenderingStyle === 'Normal';
    };

    const shouldShowLink = (): boolean => {
        return (
            getCellDisplayValue(row, column).length > 0 &&
            isFieldALink(row, column) &&
            shouldRenderLinkBasedOnScreenMode()
        );
    };

    const showDocument = () => {
        listScreenDispatch({ type: 'DisableRow', id: row.id });
        cancellablePromise(
            performListScreenContextLayerOperation('UUI Show Document', listScreenState, row, appResources.username),
        ).then(() => {
            listScreenDispatch({ type: 'EnableRow', id: row.id });
        });
    };

    if (isFolderRow(metadata, row)) {
        return (
            <div className={css.folderContainer}>
                <span className={css.folderIconWrapper}>
                    {folderIcon ? (
                        React.cloneElement(folderIcon, { className: css.folderIcon })
                    ) : (
                        <WkFolderIcon color="secondary" className={css.icon} />
                    )}
                </span>
                {row._metadata.docOrFolderName}
            </div>
        );
    }

    return (
        <>
            {fileIcon && (
                <span
                    className={isDocumentRow(metadata, row) && isOC() ? css.iconWrapperLink : css.iconWrapper}
                    onClick={(e) => {
                        if (isDocumentRow(metadata, row) && isOC()) {
                            showDocument();
                        }
                        e.stopPropagation();
                    }}
                    data-testid={'file_Icon'}>
                    {React.cloneElement(fileIcon, { className: css.icon })}
                </span>
            )}

            {isDocumentRow(metadata, row) && isOC() && isFieldAShowDocumentLink(row, column) ? (
                <ButtonLink
                    dataTestId="oc-open-document-link"
                    fileIcon={fileIcon}
                    onClick={(e: any) => {
                        // don't click the checkbox on the row
                        e.stopPropagation();
                        showDocument();
                    }}>
                    {getCellDisplayValue(row, column)}
                </ButtonLink>
            ) : shouldShowLink() ? (
                isTargetScreenNormalRenderingStyle() ? (
                    <Link className={css.matterLink} to={getListLinkPath(row, column)}>
                        {getCellDisplayValue(row, column)}
                    </Link>
                ) : (
                    <ButtonLink
                        dataTestId="list-link-button"
                        fileIcon={fileIcon}
                        onClick={(e: any) => {
                            reduxDispatch({
                                type: 'OpenItemScreenDialog',
                                itemScreenProps: {
                                    ...getItemScreenPropsFromUrl(getListLinkPath(row, column)),
                                    onClosePopupInline: () => {
                                        refreshList();
                                    },
                                },
                            });
                            // don't click the checkbox on the row
                            e.stopPropagation();
                        }}>
                        {getCellDisplayValue(row, column)}
                    </ButtonLink>
                )
            ) : column.appearance && column.appearance === 'Priority' ? (
                <span
                    className={classNames(css.appearance, {
                        [css.high]: getCellDisplayValue(row, column).toLowerCase() === 'high',
                        [css.med]:
                            getCellDisplayValue(row, column).toLowerCase() === 'med' ||
                            getCellDisplayValue(row, column).toLowerCase() === 'error',
                        [css.low]: getCellDisplayValue(row, column).toLowerCase() === 'low',
                        [css.info]: getCellDisplayValue(row, column).toLowerCase() === 'info',
                    })}>
                    {getCellDisplayValue(row, column)}
                </span>
            ) : (
                getCellDisplayValue(row, column)
            )}
        </>
    );
};

const ButtonLink = (props: any) => (
    <Button
        color="primary"
        classes={{
            root: props.fileIcon
                ? `${css.listLinkButton} ${css.linkButtonWithFileIcon}`
                : `${css.listLinkButton} ${css.linkButtonWithoutFileIcon}`,
            label: css.listLinkLabel,
        }}
        disableFocusRipple={true}
        disableTouchRipple={true}
        data-testid={props.dataTestId}
        onClick={props.onClick}>
        {props.children}
    </Button>
);

export default ListScreenField;
