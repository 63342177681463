/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useEffect, useState } from 'react';
import {
    FormControlLabel,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    MenuItem,
    OutlinedInput,
    Radio,
    RadioGroup,
    Select,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import WkZoomInIcon from '../../icons/wkZoomInIcon';
import CloseIcon from '@material-ui/icons/Close';
import WkZoomOutIcon from '../../icons/wkZoomOutIcon';
import { Rating } from '@material-ui/lab';
import { format, parse, isEqual } from 'date-fns';
import { apiFetch } from '../../../utils/fetchUtils';
import UUIButton from '../../common/uuiButton';
import DatePicker from '../../common/datePicker';
import { useListScreenState } from '../context/listScreenContext';
import AttributeSelectionDialog from './attributeSelectionDialog';
import { createFiltersMap } from './context/filterPickerReducer';
import { useListScreenEditDialogDispatch, useListScreenEditDialogState } from './context/listScreenEditDialogContext';
import css from './filterPicker.module.scss';
import {
    displayComparisonOnly,
    isMultiRowFilter,
    isSpecificDateFilter,
    isValuelessComparisonType,
} from './filterPickerHelpers';
import { IFilterObj, IFilterPickerState } from './types';
import { useReduxSelector } from '../../../hooks/useReduxSelector';

const FilterPicker: React.FC = () => {
    const listScreenState = useListScreenState();
    const listData = listScreenState.listData!;
    const filterPickerState = useListScreenEditDialogState((s) => s.filterPicker) as IFilterPickerState;
    const filtersMap = filterPickerState.filtersMap;
    const dispatch = useListScreenEditDialogDispatch();

    useEffect(() => {
        dispatch({
            type: 'SaveFiltersMap',
            filtersMap: createFiltersMap(listData.page.filters),
        });
    }, [dispatch, listData.page.filters]);

    const getFilterInputChangeHandler = (filter: IFilterObj, filterIndex: number) => {
        return (newLeftValue: string | undefined, newRightValue?: string) => {
            dispatch({
                type: 'UpdateFilterSearchValue',
                filterToUpdate: filter,
                indexOfFilter: filterIndex,
                newLeftValue: newLeftValue,
                newRightValue: newRightValue,
            });
        };
    };

    return (
        <div className={css.container}>
            <Grid container spacing={2} alignItems="center" className={css.filterPickerContents}>
                {Object.entries(filtersMap).map(([filterDisplayValue, filters]) => (
                    <React.Fragment key={filterDisplayValue}>
                        <Grid item xs={12} className={css.filterName}>
                            <Typography variant="subtitle2">{filterDisplayValue}</Typography>
                        </Grid>
                        {filters.map((filter: IFilterObj, j: number) => (
                            <React.Fragment key={j}>
                                <Grid
                                    item
                                    xs={displayComparisonOnly(filter) ? 12 : 4}
                                    className={css.filterPart}
                                    data-testid={`${filterDisplayValue}_ct`}>
                                    <div
                                        className={displayComparisonOnly(filter) ? css.fullWidthFilterPart : undefined}>
                                        <ComparisonTypeSelect
                                            filterType={filter.attributeType}
                                            selectedType={filter.comparisonType}
                                            onChange={(newValue: string) =>
                                                dispatch({
                                                    type: 'UpdateFilterComparisonType',
                                                    filterToUpdate: filter,
                                                    indexOfFilter: j,
                                                    newComparisonType: newValue,
                                                })
                                            }
                                        />
                                    </div>

                                    {displayComparisonOnly(filter) && (
                                        <FilterAddRemoveButtons filters={filters} filter={filter} indexOfFilter={j} />
                                    )}
                                </Grid>
                                {(isSpecificDateFilter(filter) || !isMultiRowFilter(filter)) && (
                                    <Grid
                                        item
                                        xs={8}
                                        className={css.filterPartSecondRow}
                                        style={
                                            isValuelessComparisonType(filter.comparisonType) ? { display: 'none' } : {}
                                        }
                                        data-testid={`${filterDisplayValue}_val`}>
                                        <FilterInput
                                            filter={filter}
                                            onChange={getFilterInputChangeHandler(filter, j)}
                                        />
                                        <FilterAddRemoveButtons filters={filters} filter={filter} indexOfFilter={j} />
                                    </Grid>
                                )}
                                {isMultiRowFilter(filter) && (
                                    <Grid
                                        item
                                        xs={12}
                                        className={css.filterPart}
                                        data-testid={`${filterDisplayValue}_multi`}>
                                        <FilterValueControl
                                            filter={filter}
                                            onChange={getFilterInputChangeHandler(filter, j)}
                                        />
                                    </Grid>
                                )}
                            </React.Fragment>
                        ))}
                        <Grid item xs={12} className={css.borderLineContainer}>
                            <hr className={css.borderLine} />
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>
            <AddFilterWidget />
        </div>
    );
};

interface IFilterInput {
    filter: IFilterObj;
    onChange: (newLeftValue: string | undefined, newRightValue?: string) => void;
}

export const FilterInput: React.FC<IFilterInput> = ({ filter, onChange }) => {
    return (
        <div className={css.fullWidthFilterPart}>
            {isSpecificDateFilter(filter) ? (
                <RelativeDateRanges filter={filter} onChange={onChange} />
            ) : (
                <FilterValueControl filter={filter} onChange={onChange} />
            )}
        </div>
    );
};

const AddFilterWidget: React.FC = () => {
    const listScreenState = useListScreenState();
    const [open, setOpen] = useState(false);

    // every time we open this dialog, we need a new instance
    const [dialogKey, setDialogKey] = useState(new Date().getUTCMilliseconds());

    // this keeps the dialog from being rendered until the user clicks the Add Filter button
    // controlling it with open made the slide closed animation not work since it is immediately
    // removed from the DOM.
    const [hasBeenOpened, setHasBeenOpened] = useState(false);

    const comparisonTypesMap = useReduxSelector((state) => state.comparisonTypes);
    const dispatch = useListScreenEditDialogDispatch();
    const addFilterText = useReduxSelector((state) => state.appResources.listscreenAddFilter);
    return (
        <div>
            <UUIButton
                color="primary"
                endIcon={<WkZoomInIcon />}
                onClick={() => {
                    setDialogKey(new Date().getUTCMilliseconds());
                    setOpen(true);
                    setHasBeenOpened(true);
                }}
                classes={{ root: css.buttonRoot }}>
                {addFilterText}
            </UUIButton>
            {hasBeenOpened && (
                <AttributeSelectionDialog
                    open={open}
                    key={dialogKey}
                    onClose={() => setOpen(false)}
                    screenMetadata={listScreenState.metadata}
                    onSelected={(selectedItem, names, attributeIds, attributePath) => {
                        const ctDisplayValueToSqlValueMap = comparisonTypesMap.ComparisonTypeMap;
                        const selectedCtDisplayValue =
                            // @ts-ignore
                            comparisonTypesMap[selectedItem.attributeType][0];
                        const comparisonType = ctDisplayValueToSqlValueMap[selectedCtDisplayValue];
                        dispatch({
                            type: 'AddFilter',
                            path: attributePath,
                            entityId:
                                selectedItem.metadata && selectedItem.metadata.relatedEntityId
                                    ? parseInt(selectedItem.metadata.relatedEntityId)
                                    : listScreenState.metadata!.entityId,
                            attributeIds: attributeIds,
                            attributeType: selectedItem.attributeType,
                            names,
                            comparisonType,
                        });
                    }}
                />
            )}
        </div>
    );
};

interface IFilterValueControl {
    filter: IFilterObj;
    onChange: (newLeftValue: string | undefined, newRightValue?: string) => void;
}

interface IFilterValueSelectControl extends IFilterValueControl {
    url: string;
}

export const FilterValueControl: React.FC<IFilterValueControl> = ({ filter, onChange }) => {
    const apiPath = Props['apiContextRoot'] + Props['apiContextPath'];
    const applicationUrls = useReduxSelector((state) => state.applicationUrls);

    switch (filter.attributeType) {
        case 'SingleAssociation':
            return (
                <FilterValueSelect
                    filter={filter}
                    url={apiPath + applicationUrls.entityInstances.replace('{entityId}', filter.entityId.toString())}
                    onChange={onChange}
                />
            );
        case 'Boolean':
            return (
                <FilterValueSelect
                    filter={filter}
                    url={
                        apiPath +
                        applicationUrls.booleanLabels.replace(
                            '{attributeId}',
                            filter.attributeIds[filter.attributeIds.length - 1].toString(),
                        )
                    }
                    onChange={onChange}
                />
            );
        case 'Enum':
            return (
                <FilterValueSelect
                    filter={filter}
                    url={
                        apiPath +
                        applicationUrls.enumValues.replace(
                            '{attributeId}',
                            filter.attributeIds[filter.attributeIds.length - 1].toString(),
                        )
                    }
                    onChange={onChange}
                />
            );
        case 'Currency':
            return <FilterValueSelect filter={filter} url={apiPath + applicationUrls.currencies} onChange={onChange} />;
        case 'Money':
            return <FilterValueMoney filter={filter} onChange={onChange} />;
        case 'Rating':
            return <FilterValueRating filter={filter} onChange={onChange} />;
        case 'Date':
        case 'DateTime':
            return <FilterValueDate filter={filter} onChange={onChange} />;
        default:
            return <FilterValueInput filter={filter} onChange={onChange} />;
    }
};

const FilterValueMoney: React.FC<IFilterValueControl> = ({ filter, onChange }) => {
    const placeholderText = useReduxSelector((state) => state.appResources.listscreenFilterValuePlaceholder);

    return (
        <Input
            inputProps={{ 'aria-label': 'search value' }}
            placeholder={`(${placeholderText})`}
            fullWidth
            value={filter.leftValue ? filter.leftValue : ''}
            onChange={(e) => onChange(e.target.value)}
        />
    );
};

const FilterValueRating: React.FC<IFilterValueControl> = ({ filter, onChange }) => (
    <Rating
        className={css.rating}
        name={filter.names.toString()}
        value={filter.leftValue ? parseInt(filter.leftValue) : 0}
        onChange={(_, newValue) => {
            if (newValue) {
                onChange(newValue.toString());
            }
        }}
    />
);

const RelativeDateRanges: React.FC<IFilterValueControl> = ({ filter, onChange }) => {
    const appResources = useReduxSelector((state) => state.appResources);

    const relativeDateRanges = useReduxSelector((state) => state.comparisonTypes.RelativeDateRanges);

    const anyText = appResources.listscreenFilterValueAny;

    return (
        <Select
            fullWidth
            value={filter.leftValue ? filter.leftValue : ' '}
            onChange={(e: React.ChangeEvent<{ value: any }>) => onChange(e.target.value)}
            data-testid="filters-dropdown"
            variant="outlined"
            className={css.selectMenu}>
            <MenuItem key="any" dense value=" ">
                {`(${anyText})`}
            </MenuItem>
            {relativeDateRanges.map((relativeDateRange: string[]) => {
                return (
                    <MenuItem key={relativeDateRange[0]} dense value={relativeDateRange[0]}>
                        {relativeDateRange[1]}
                    </MenuItem>
                );
            })}
        </Select>
    );
};

const FilterValueDate: React.FC<IFilterValueControl> = ({ filter, onChange }) => {
    const appResources = useReduxSelector((state) => state.appResources);
    const dateTimeTextBoxesAreWrapping = useMediaQuery('(max-width:437px') && filter.attributeType === 'DateTime';

    const dateFormat: string = appResources.displayedDateFormat;
    const dateTimeFormat: string = appResources.displayedDateTimeFormat;
    const formatToUse = filter.attributeType === 'Date' ? dateFormat : dateTimeFormat;
    const [specificDateObject, setSpecificDateObject] = useState(() =>
        filter.rightValue ? parse(filter.rightValue, formatToUse, new Date()) : undefined,
    );
    const [leftDateObject, setLeftDateObject] = useState(() =>
        filter.leftDate ? parse(filter.leftDate, formatToUse, new Date()) : undefined,
    );
    const [rightDateObject, setRightDateObject] = useState(() =>
        filter.rightDate ? parse(filter.rightDate, formatToUse, new Date()) : undefined,
    );

    if (filter.comparisonType === 'EQUALS') {
        if (filter.leftValue === 'Specific Date') {
            return (
                <Grid container spacing={1}>
                    <Grid item xs={12} className={css.datePickerContainer}>
                        <DatePicker
                            key="specificDatePicker"
                            inputProps={{ 'aria-label': 'search value date', 'data-testid': 'specificDatePicker' }}
                            selected={specificDateObject}
                            onChange={(date) => {
                                if (date === null) {
                                    setSpecificDateObject(undefined);
                                    onChange('Specific Date', undefined);
                                } else if (!rightDateObject || !isEqual(rightDateObject, date)) {
                                    const formattedDate = format(date, formatToUse);
                                    setSpecificDateObject(date);
                                    onChange('Specific Date', formattedDate);
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            );
        } else {
            return <RelativeDateRanges filter={filter} onChange={onChange} />;
        }
    } else if (filter.comparisonType === 'BETWEEN') {
        const conjunctionText: string = appResources.listscreenFilterConjunction;
        return (
            <Grid container spacing={1} alignItems="flex-start">
                <Grid item xs={12} className={css.datePickerContainer}>
                    <DatePicker
                        key="leftDatePicker"
                        inputProps={{ 'aria-label': 'search value date', 'data-testid': 'fromDatePicker' }}
                        selected={leftDateObject}
                        onChange={(date) => {
                            if (date === null) {
                                setLeftDateObject(undefined);
                                onChange(undefined, filter.rightDate);
                            } else if (!leftDateObject || !isEqual(leftDateObject, date)) {
                                const formattedDate = format(date, formatToUse);
                                setLeftDateObject(date);
                                onChange(formattedDate, filter.rightDate);
                            }
                        }}
                        showTimeSelect={filter.attributeType === 'DateTime'}
                    />
                </Grid>
                <Grid item>
                    <Typography variant="body1" align="center">
                        {conjunctionText}
                    </Typography>
                </Grid>
                <Grid item xs={12} className={css.datePickerContainer}>
                    <DatePicker
                        inputProps={{ 'aria-label': 'search value date', 'data-testid': 'toDatePicker' }}
                        selected={rightDateObject}
                        onChange={(date) => {
                            if (date === null) {
                                setRightDateObject(undefined);
                                onChange(filter.leftDate, undefined);
                            } else if (!rightDateObject || !isEqual(rightDateObject, date)) {
                                if (date.getHours() == 0 && date.getMinutes() == 0) {
                                    date.setHours(23);
                                    date.setMinutes(59);
                                }
                                const formattedDate = format(date, formatToUse);
                                setRightDateObject(date);
                                onChange(filter.leftDate, formattedDate);
                            }
                        }}
                        showTimeSelect={filter.attributeType === 'DateTime'}
                        popperPlacement={
                            filter.attributeType === 'Date' ||
                            (filter.attributeType === 'DateTime' && !dateTimeTextBoxesAreWrapping)
                                ? 'bottom-end'
                                : undefined
                        }
                    />
                </Grid>
            </Grid>
        );
    } else {
        return null;
    }
};

interface IFilterValueSelectItem {
    value: number | ' ';
    label: string;
}

const FilterValueSelect: React.FC<IFilterValueSelectControl> = ({ filter, url, onChange }) => {
    const anyText = useReduxSelector((state) => state.appResources.listscreenFilterValueAny);
    const labelAnyText = '(' + anyText + ')';

    const [items, setItems] = useState<IFilterValueSelectItem[]>([{ value: ' ', label: labelAnyText }]);

    const loadItems = useCallback(() => {
        if (items.length == 1) {
            setItems([{ value: ' ', label: 'Loading' }]);
            apiFetch<IFilterValueSelectItem[]>(url).then((fetchedItems) => {
                fetchedItems.unshift({ value: ' ', label: labelAnyText });
                setItems(fetchedItems);
            });
        }
    }, [setItems, items.length, labelAnyText, url]);

    useEffect(() => {
        if (filter.leftValue) {
            loadItems();
        }
    }, [filter.leftValue, loadItems]);

    return (
        <Select
            fullWidth
            value={items.length == 1 ? ' ' : filter.leftValue ? filter.leftValue : ' '}
            onOpen={loadItems}
            onChange={(e: React.ChangeEvent<{ value: any }>) => onChange(e.target.value.toString().trim())}
            data-testid="filters-dropdown"
            variant="outlined"
            className={css.selectMenu}>
            {items.map((item: IFilterValueSelectItem, index: number) => {
                return (
                    <MenuItem key={index} dense value={item.value}>
                        {item.label}
                    </MenuItem>
                );
            })}
        </Select>
    );
};

const FilterValueInput: React.FC<IFilterValueControl> = ({ filter, onChange }) => {
    const placeholderText = useReduxSelector((state) => state.appResources.listscreenFilterValuePlaceholder);

    return (
        <OutlinedInput
            inputProps={{ 'aria-label': 'search text' }}
            placeholder={`(${placeholderText})`}
            fullWidth
            value={filter.leftValue ? filter.leftValue : ''}
            endAdornment={
                filter.leftValue ? (
                    <InputAdornment position="end">
                        <IconButton aria-label="clear search input" size="small" onClick={() => onChange('')}>
                            <CloseIcon />
                        </IconButton>
                    </InputAdornment>
                ) : undefined
            }
            onChange={(e) => onChange(e.target.value)}
            className={css.selectMenu}
        />
    );
};

interface IComparisonTypeSelect {
    filterType: string;
    selectedType: string;
    onChange: (newValue: string) => void;
}

export const ComparisonTypeSelect: React.FC<IComparisonTypeSelect> = ({ filterType, selectedType, onChange }) => {
    const ctMap = useReduxSelector((state) => state.comparisonTypes);
    // @ts-ignore
    const ctDisplayValues = ctMap[filterType];
    const ctDisplayValueToSqlValueMap = ctMap.ComparisonTypeMap;

    if (filterType === 'MultiAssociation') {
        return (
            <RadioGroup
                value={selectedType}
                onChange={(e: React.ChangeEvent<{ value: any }>) => onChange(e.target.value)}
                className={css.radioGroup}>
                {ctDisplayValues.map((ctDisplayValue: string, index: number) => (
                    <FormControlLabel
                        key={index}
                        value={ctDisplayValueToSqlValueMap[ctDisplayValue]}
                        control={<Radio color="primary" />}
                        label={ctDisplayValue}></FormControlLabel>
                ))}
            </RadioGroup>
        );
    } else {
        return (
            <Select
                fullWidth
                value={selectedType}
                onChange={(e: React.ChangeEvent<{ value: any }>) => onChange(e.target.value)}
                data-testid="filters-dropdown"
                variant="outlined"
                className={css.selectMenu}>
                {ctDisplayValues.map((ctDisplayValue: string, index: number) => (
                    <MenuItem
                        data-testid={`filter-select-item${index}`}
                        key={index}
                        dense
                        value={ctDisplayValueToSqlValueMap[ctDisplayValue]}>
                        {ctDisplayValue}
                    </MenuItem>
                ))}
            </Select>
        );
    }
};

interface IFilterAddRemoveButtons {
    filters: any;
    filter: IFilterObj;
    indexOfFilter: number;
}

export const FilterAddRemoveButtons: React.FC<IFilterAddRemoveButtons> = ({ filters, filter, indexOfFilter }) => {
    const dispatch = useListScreenEditDialogDispatch();
    const comparisonTypesMap = useReduxSelector((state) => state.comparisonTypes);

    return (
        <div style={{ maxWidth: '70px', marginLeft: '5px', display: 'inline-block' }}>
            <IconButton
                aria-label="remove filter"
                size="small"
                onClick={() =>
                    dispatch({ type: 'RemoveFilterRow', filterToRemove: filter, indexOfFilter: indexOfFilter })
                }>
                <WkZoomOutIcon />
            </IconButton>
            {indexOfFilter === filters.length - 1 && filter.attributeType !== 'MultiAssociation' && (
                <IconButton
                    aria-label="add filter"
                    size="small"
                    onClick={() => {
                        const ctDisplayValueToSqlValueMap = comparisonTypesMap.ComparisonTypeMap;
                        // @ts-ignore
                        const selectedCtDisplayValue = comparisonTypesMap[filter.attributeType][0];
                        const comparisonType = ctDisplayValueToSqlValueMap[selectedCtDisplayValue];
                        dispatch({
                            type: 'AddFilter',
                            path: filter.path,
                            entityId: filter.entityId,
                            attributeIds: filter.attributeIds,
                            attributeType: filter.attributeType,
                            names: filter.names,
                            comparisonType,
                        });
                    }}>
                    <WkZoomInIcon className={css.addIcon} />
                </IconButton>
            )}
        </div>
    );
};

export default FilterPicker;
