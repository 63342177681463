import { apiRestFetchGet } from '../../restApi/fetch.utils';
import { JRAdapterActionPerformer } from './actionPerformer';
import { convertToMapMetaData } from '../../utils/main.utils';
import { matchMetadata } from '../utils/maps';
import { IDocOperationInvocation } from '../../interfaces/urlPattern/types';
import { factory } from '../../ConfigLog4j';
import { injectable, inject } from 'inversify';
import * as convert from 'xml-js';
import { IDocServiceRequest, IEntityContext, DCResponse } from '../../types';
import { JRAdapterDocumentMetadataActionPerformer } from './documentMetadataActionPerformer';
import { JRAdapterDocumentMetadataFileNameActionableEntity } from '../actionableEntity/documentMetadataFileNameActionableEntity';
import { JRAdapterDocumentMetadataActionableEntity } from '../actionableEntity/documentMetadataActionableEntity';

const log = factory.getLogger('JRAdapterDocumentMetadataFileNameActionPerformer');

@injectable()
export class JRAdapterDocumentMetadataFileNameActionPerformer extends JRAdapterActionPerformer {
    fetchRequest = apiRestFetchGet;

    @inject('JRAdapterDownloadDocumentUrlPattern') public downloadUrl: IDocOperationInvocation;
    @inject('JRAdapterDownloadVersionDocumentUrlPattern') public downloadUrlVersion: IDocOperationInvocation;

    @inject('JRAdapterDocumentMetadataActionPerformer')
    public documentMetadataAction: JRAdapterDocumentMetadataActionPerformer;

    @inject('JRAdapterDocumentMetadataUrlPattern') public documentMetadataUrl: IDocOperationInvocation;

    async performAction(
        obj: JRAdapterDocumentMetadataFileNameActionableEntity,
        urlObj: Promise<string>,
        urlPatternObj: IDocOperationInvocation,
    ): Promise<DCResponse> {
        const response = await super.performAction(obj, urlObj, urlPatternObj);

        const outputInfo = async () => {
            const xmlData = await response.text();

            const data = convert.xml2json(xmlData);
            const dataObj = JSON.parse(data);

            const jsonArray = dataObj['elements'][0]['elements'];

            let docId = 0;
            if (jsonArray) {
                docId = jsonArray[0]['attributes']['href'].replace('/', '');

                this.documentMetadataUrl.hostName = urlPatternObj?.hostName;
                this.documentMetadataUrl.objectRelative = urlPatternObj?.objectRelative;
                this.documentMetadataUrl.ObjectTypeMap = urlPatternObj?.ObjectTypeMap;
                const url = this.documentMetadataUrl.getUrl({
                    docId: docId,
                    entityContext: { name: '', type: obj.relatedEntityType, id: '' },
                } as IDocServiceRequest);

                const urlFunc = async () => {
                    return url;
                };

                this.documentMetadataAction.tokenHandler = this.tokenHandler;

                const documentMetatdataEntity = {} as JRAdapterDocumentMetadataActionableEntity;
                documentMetatdataEntity.docId = docId.toString();
                documentMetatdataEntity.entityContext = {
                    name: '',
                    type: obj.relatedEntityType,
                    id: '',
                } as IEntityContext;
                const response_metadata = await this.documentMetadataAction.performAction(
                    documentMetatdataEntity,
                    urlFunc(),
                    urlPatternObj,
                );

                // if (response_metadata?.outputInfo) {
                const outputInfo = await response_metadata?.outputInfo();
                // } else {
                //   log.error('Cannot find  outputInfo');
                //   throw Error('Cannot find outputInfo');
                // }
                return outputInfo;
            } else {
                log.debug('Cannot find the document');
                return convertToMapMetaData(matchMetadata, { id: '0' });
            }
        };
        const response2: DCResponse = Object.assign(new DCResponse(), response);
        response2.outputInfo = outputInfo;
        return response2;
    }
}
