import React, { useRef } from 'react';
import { IconButton, InputAdornment, InputBaseComponentProps } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { startOfDay } from 'date-fns';
import de from 'date-fns/locale/de';
import enGB from 'date-fns/locale/en-GB';
import fr from 'date-fns/locale/fr';
import { omit } from 'lodash';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import css from './datePicker.module.scss';
import { UUIInput } from './uuiInput';
import EventIcon from '@material-ui/icons/Event';
import { useReduxSelector } from '../../hooks/useReduxSelector';

const supportedLocales = ['de', 'en_GB', 'fr'];

registerLocale('de', de);
registerLocale('en_GB', enGB);
registerLocale('fr', fr);

interface IDatePicker {
    selected?: Date | null | undefined;
    onChange(date: Date | null, event: React.SyntheticEvent<any, Event> | undefined): void;
    showTimeSelect?: boolean;
    popperPlacement?:
        | 'auto-start'
        | 'auto'
        | 'auto-end'
        | 'top-start'
        | 'top'
        | 'top-end'
        | 'right-start'
        | 'right'
        | 'right-end'
        | 'bottom-end'
        | 'bottom'
        | 'bottom-start'
        | 'left-end'
        | 'left'
        | 'left-start';
    inputProps?: InputBaseComponentProps;
    popperModifiers?: any;
    error?: boolean;
    warning?: boolean;
}

export const DatePicker: React.FC<IDatePicker> = React.memo(
    ({ selected, inputProps, onChange, showTimeSelect, popperPlacement, popperModifiers, error, warning }) => {
        const appResources = useReduxSelector((state) => state.appResources);

        const dateFormat: string = appResources.displayedDateFormat;
        const dateTimeFormat: string = appResources.displayedDateTimeFormat;
        const formatToUse = showTimeSelect ? dateTimeFormat : dateFormat;
        const datePickerRef = useRef<HTMLDivElement>(null);
        const shiftTabUsed = useRef(false);

        const getLocale = (): string | undefined => {
            if (supportedLocales.indexOf(appResources.locale) > -1) {
                return appResources.locale;
            }
            if (appResources.locale.indexOf('_') > -1) {
                const language = appResources.locale.split('_')[0];
                if (supportedLocales.indexOf(language) > -1) {
                    return language;
                }
            }

            return;
        };

        return (
            <div ref={datePickerRef}>
                <ReactDatePicker
                    selected={selected}
                    openToDate={selected ? selected : startOfDay(new Date())}
                    onChange={(date, e) => {
                        // only do this if they have selected a date, not when they clear a date
                        if (date && !shiftTabUsed.current) {
                            // this will bring the focus back from the portal to the textbox and then blur it so tab order is preserved.
                            const input = datePickerRef.current?.querySelector<HTMLInputElement>('input[type="text"]');
                            if (document.activeElement != input) {
                                input?.focus();
                                input?.blur();
                            }
                        }
                        shiftTabUsed.current = false;
                        onChange(date as Date, e);
                    }}
                    dateFormat={formatToUse}
                    showTimeSelect={showTimeSelect}
                    timeIntervals={15}
                    closeOnScroll={(e) => {
                        try {
                            return (e.target as HTMLDivElement)?.getAttribute('data-scrollid') === 'itemscreen-view';
                        } catch (e) {
                            return false;
                        }
                    }}
                    popperPlacement={popperPlacement}
                    popperModifiers={popperModifiers}
                    popperClassName={css.reactDatePicker}
                    wrapperClassName={css.datePicker}
                    locale={getLocale()}
                    portalId="date-picker"
                    calendarContainer={(props: any) => (
                        // this was copied from react-datepicker library source code adding on
                        // keydown event handler to fix a escape focus issue
                        <div
                            className={props.className}
                            onKeyDown={(e) => {
                                if (e.key === 'Escape') {
                                    setTimeout(
                                        () =>
                                            datePickerRef.current
                                                ?.querySelector<HTMLInputElement>('input[type="text"]')
                                                ?.blur(),
                                        100,
                                    );
                                } else if (e.shiftKey && e.key === 'Tab') {
                                    shiftTabUsed.current = true;
                                }
                            }}>
                            {props.children}
                        </div>
                    )}
                    customInput={
                        <DatePickerInput
                            inputProps={inputProps}
                            isForDateTimePicker={showTimeSelect == true}
                            formatString={formatToUse}
                            error={error}
                            warning={warning}
                        />
                    }>
                    <div className="react-datepicker__triangle"></div>
                </ReactDatePicker>
            </div>
        );
    },
);

DatePicker.displayName = 'DatePicker';

interface IDatePickerInput {
    isForDateTimePicker: boolean;
    formatString: string;
    inputProps?: InputBaseComponentProps;
    error?: boolean;
    warning?: boolean;
    // the following will be injected by the React DatePicker library.
    value?: string;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClick?: any;
    onFocus?: any;
    onKeyDown?: any;
    id?: any;
    name?: any;
    autoFocus?: any;
    placeholder?: string;
    disabled?: boolean;
    autoComplete?: string;
    className?: string;
    title?: string;
    readOnly?: boolean;
    required?: boolean;
    tabIndex?: number;
    'aria-labelledby'?: string;
}

const DatePickerInput = React.forwardRef<HTMLInputElement, IDatePickerInput>((props, ref) => {
    const inputValue = props.value as string;
    const endAdornment = (
        <div className={css.dateFieldIcons}>
            {inputValue.length > 0 ? (
                <InputAdornment position="end">
                    {inputValue.length > 0 && (
                        <IconButton
                            data-testid="clearIcon"
                            aria-label="clear"
                            size="small"
                            onClick={(e) => {
                                props.onChange!({ target: { value: '' } } as any);
                                e.stopPropagation();
                            }}>
                            <CloseIcon className={css.closeIcon} />
                        </IconButton>
                    )}
                </InputAdornment>
            ) : undefined}
            <EventIcon className={css.calendarIcon} />
        </div>
    );

    const onKeyDown = (e: any) => {
        if (!e.shiftKey && e.key === 'Tab') {
            // this will cause the focus to go into the datepicker
            document.querySelector<HTMLDivElement>('.react-datepicker__navigation--next')?.focus();
            props.onKeyDown!(e);
        } else if (e.shiftKey && e.key === 'Tab') {
            // HACK: shift tab is not implemented in this library, so simulate an escape key to close the calendar,
            // and we are mocking out the entire event object here so that preventDefault will have no effect.
            // This means shift tab will still navigate the user to the previous field.
            props.onKeyDown!({
                key: 'Escape',
                keyCode: -1,
                preventDefault: () => {
                    /* mock*/
                },
            });
        } else {
            props.onKeyDown!(e);
        }
    };

    const inputProps = omit(props, ['isForDateTimePicker', 'formatString']);

    return (
        <UUIInput
            {...inputProps}
            inputProps={props.inputProps}
            onKeyDown={onKeyDown}
            inputRef={ref}
            endAdornment={endAdornment}
            className={css.datePickerUUIInput}
            error={props.error}
            warning={props.warning}
        />
    );
});

DatePickerInput.displayName = 'DatePickerInput';

export default DatePicker;
