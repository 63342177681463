import React from 'react';
import { Link as ReactRouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import MaterialUiLink from '@material-ui/core/Link';

interface ILinkProps {
    to: string;
    className?: string;
}

const Link: React.FC<ILinkProps> = ({ to, children, className }) => {
    const reactRouterLink = React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'innerRef' | 'to'>>(
        (props, ref) => <ReactRouterLink innerRef={ref} to={to} {...props} />,
    );
    reactRouterLink.displayName = 'ReactRouterLink';
    return (
        <MaterialUiLink component={reactRouterLink} className={className}>
            {children}
        </MaterialUiLink>
    );
};

export default Link;
