import { useRef, useEffect, MutableRefObject } from 'react';

const useIsMounted = (): MutableRefObject<boolean> => {
    const isMounted = useRef(true);
    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);
    return isMounted;
};

export default useIsMounted;
