import { CapabiltyEnum } from '../../enums/capability';
import { injectable } from 'inversify';
import { JRAdapterDownloadPattern } from '../urlEntity/downloadPattern';
import { IDownloadVersionDocument } from '../../types';

@injectable()
export class JRAdapterDownloadVersionDocumentUrlPattern extends JRAdapterDownloadPattern {
    operationName = CapabiltyEnum.DOWNLOAD_VERSION_DOCUMENT;
    async getUrl(nodeObj: IDownloadVersionDocument): Promise<string> {
        let baseUrl = await super.getUrl(nodeObj);

        baseUrl =
            baseUrl + '/document/' + nodeObj.docId + '/' + 'DocumentVersion' + '/' + nodeObj.extendedProps.version;

        return baseUrl;
    }
}
