import { interfaces } from 'inversify';
import { IDMInfoHandler, ActionSequence, IAdapterInfo } from './dmInfoHandler/types';
import { DMInfoHandler } from './dmInfoHandler';
import { IDocTransformer } from './interfaces/transformer/type';
import { IInputValidator } from './interfaces/inputValidators/type';
import { IDocAdaptor } from './interfaces/adapter/types';
import { IDmAdapter } from './interfaces/dmAdapters/types';
import { IDocCapability } from './capability/type';
import { IDMToken } from './interfaces/dmTokenHandler/type';
import { IAuthScheme } from './interfaces/authScheme/type';
import { OAuth } from './authSchemes/oath';
import { AuthSchemeEnum } from './enums/authScheme';
import { IDocActionPerformer } from './interfaces/actionPerformers/type';
import { BasicAuthentication } from './authSchemes/basicAuth';
import { IDocOperationInvocation, UrlPattern } from './interfaces/urlPattern/types';
import { ITransformFulfiller } from './interfaces/transformFulfiller/transformFulfiller';
import { t360Container } from './inversify_T360.config';
import { jrContainer } from './inversify_JR.config';
import { GetUrlEnum } from './enums/urlPattern';
import { Capability } from './capability/capability';
import { EValidator } from './enums/validators';
import { DocInputValidator } from './validators/docValidator';
import { EntityContextValidator } from './validators/entityContextValidator';
import { DummyValidator } from './validators/dummyValidator';
import { FileNameMetadataInputValidator } from './validators/docMetadaFileName';
import { factory } from './ConfigLog4j';
import { DocFileSizeValidator } from './validators/fileSizeValidation';
import { myContainer } from './core';

const log = factory.getLogger('Inversify');

myContainer.load(t360Container);
myContainer.load(jrContainer);

myContainer.bind<IAuthScheme>(AuthSchemeEnum.OAUTH).to(OAuth);
myContainer.bind<IAuthScheme>(AuthSchemeEnum.BASIC_AUTHENTICATION).to(BasicAuthentication);
// Token
myContainer.bind<IDMInfoHandler>('dmInfoHandler').to(DMInfoHandler).inSingletonScope();

myContainer.bind<IDocOperationInvocation>('UrlPattern').to(UrlPattern);

myContainer.bind<IDocCapability>('Capability').to(Capability);

myContainer.bind<IInputValidator>(EValidator.DocValidator).to(DocInputValidator);
myContainer.bind<IInputValidator>(EValidator.DocFileSizeValidator).to(DocFileSizeValidator);

myContainer.bind<IInputValidator>(EValidator.EntityContextValidator).to(EntityContextValidator);

myContainer.bind<IInputValidator>(EValidator.DummyValidator).to(DummyValidator);

myContainer.bind<IInputValidator>(EValidator.DocMetaFileNameValidator).to(FileNameMetadataInputValidator);

myContainer.bind<interfaces.Factory<IAuthScheme>>('Factory<IAuthScheme>').toFactory<IAuthScheme>((context) => {
    return (named: string) => {
        return context.container.get<IAuthScheme>(named);
    };
});

myContainer.bind<interfaces.Factory<IDocAdaptor>>('Factory<IDocAdaptor>').toFactory<IDocAdaptor>((context) => {
    return (adapter: IAdapterInfo) => {
        const adapterInstance: IDocAdaptor = context.container.get<IDocAdaptor>(adapter.adapter);
        return (inpOperation: string, _matterDetails) => {
            // adapterInstance

            const operation = adapterInstance.getMappedOperation(inpOperation);

            adapter.dmInfos.map((dmInfo) => {
                // getting the dm
                const dmAdapter: IDmAdapter = context.container.get<IDmAdapter>(dmInfo.dm);

                dmAdapter.capability = context.container.get<IDocCapability>(dmInfo.dm + operation + 'Capability');
                const actionSequence = {} as ActionSequence;
                actionSequence.actionName = operation;

                actionSequence.actionObject = context.container.get<IDocActionPerformer>(
                    dmInfo.dm + operation + 'ActionPerformer',
                );

                // getting the auth Schema
                // TO DO
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const factoryAuth: any = context.container.get<IAuthScheme>('Factory<IAuthScheme>');
                const authSchemaObj: IAuthScheme = factoryAuth(dmInfo.authScheme);
                authSchemaObj.authData = dmInfo.authInfo;

                // setting the Token Handler
                const tokenHandler = context.container.get<IDMToken>(dmInfo.dm + 'Token');
                tokenHandler.authScheme = authSchemaObj;
                // setting the tokenHandler in ActionPerformer
                actionSequence.actionObject.tokenHandler = tokenHandler;
                const transformFulfiller = context.container.get<ITransformFulfiller>(dmInfo.dm + 'TransformFulfiller');
                actionSequence.transformFulfiller = transformFulfiller;

                const actionTransformer = context.container.get<IDocTransformer>(
                    dmInfo.dm + operation + 'ActionTransformer',
                );
                actionSequence.actionTransformer = actionTransformer;

                // actionSequence.inputValidator = context.container.get<IInputValidator>(
                //   dmInfo.dm + operation + 'InputValidator',
                // );

                // actionSequence.inputValidator.validationInfo = dmInfo.validationInfo;

                dmAdapter.capability.actionSequence = actionSequence;
                adapterInstance.dmAdapters.push(dmAdapter);
            });

            adapterInstance.capability = context.container.get<IDocCapability>('Capability');
            let urlPatternObj: IDocOperationInvocation;

            // if it is got by DMINfo
            if (adapterInstance.getUrlEnum == GetUrlEnum.DMINFO) {
                urlPatternObj = context.container.get<IDocOperationInvocation>('UrlPattern');
            } else {
                if (context.container.isBound(adapter.adapter + operation + 'UrlPattern')) {
                    urlPatternObj = context.container.get<IDocOperationInvocation>(
                        adapter.adapter + operation + 'UrlPattern',
                    );
                } else {
                    const { commandName, urlPattern } = adapterInstance.getUrlPatternMethodAndCommandName(operation);
                    // log.debug('UrlPattern not bound so creating default ' + adapter.adapter + urlPattern + 'UrlPattern');

                    urlPatternObj = context.container.get<IDocOperationInvocation>(
                        adapter.adapter + urlPattern + 'UrlPattern',
                    );
                    urlPatternObj.commandMapName = commandName;
                }

                // urlPattern = context.container.get<IDocOperationInvocation>(adapter.adapter + operation + 'UrlPattern');
            }
            urlPatternObj.objectRelative = adapterInstance.objectRelative;
            if (adapterInstance?.commandIds) {
                urlPatternObj.commandId = adapterInstance.commandIds;
            }
            urlPatternObj.ObjectTypeMap = adapterInstance.ObjectTypeMap;
            urlPatternObj.hostName = adapter.authInfo.hostname;
            urlPatternObj.docStorId = adapter.authInfo?.storId;
            urlPatternObj.operationName = operation;
            urlPatternObj.supportedEntityTypes = adapter.supportedEntityTypes;
            urlPatternObj.getUrlEnum = adapterInstance.getUrlEnum;

            const operations: Record<string, string> = {};
            adapter.supportedOperations?.map((op) => {
                operations[op['operationName']] = op['operationUrl'];
            });

            urlPatternObj.operationsUrl = operations;

            const actionSequenceDocAdapter = {} as ActionSequence;
            actionSequenceDocAdapter.actionName = operation;

            // Action Performer

            if (context.container.isBound(adapter.adapter + operation + 'ActionPerformer')) {
                actionSequenceDocAdapter.actionObject = context.container.get<IDocActionPerformer>(
                    adapter.adapter + operation + 'ActionPerformer',
                );
            } else {
                log.debug('Transformer not bound so creating default ' + adapter.adapter + 'ActionPerformer');

                actionSequenceDocAdapter.actionObject = context.container.get<IDocActionPerformer>(
                    adapter.adapter + 'ActionPerformer',
                );

                actionSequenceDocAdapter.actionObject.fetchRequest =
                    adapterInstance.getActionPerformerMethod(operation);
            }

            // {
            // getting the auth Schema
            // TO DO
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const factoryAuth: any = context.container.get<IAuthScheme>('Factory<IAuthScheme>');
            const authSchemaObj: IAuthScheme = factoryAuth(adapter.authScheme);
            authSchemaObj.authData = adapter.authInfo;

            // setting the Token Handler
            const tokenHandler = context.container.get<IDMToken>(adapter.adapter + 'Token');
            tokenHandler.authScheme = authSchemaObj;
            // setting the tokenHandler in ActionPerformer
            actionSequenceDocAdapter.actionObject.tokenHandler = tokenHandler;

            urlPatternObj.tokenHandler = tokenHandler;

            adapterInstance.capability.urlPattern = urlPatternObj;
            // adapterInstance.capability.urlReplacer = context.container.get<IUrlReplacer>(adapter.adapter + 'UrlReplacer');

            const transformFulfiller = context.container.get<ITransformFulfiller>(
                adapter.adapter + 'TransformFulfiller',
            );
            actionSequenceDocAdapter.transformFulfiller = transformFulfiller;

            // check if transformer is available

            // Transformer
            let actionTransformerString = '';
            if (context.container.isBound(adapter.adapter + operation + 'ActionTransformer')) {
                log.debug(
                    'Transformer not bound so creating default ' + adapter.adapter + operation + 'ActionTransformer',
                );
                actionTransformerString = adapter.adapter + operation + 'ActionTransformer';
            } else {
                actionTransformerString = adapter.adapter + 'ActionTransformer';
            }

            const actionTransformerDocAdapter = context.container.get<IDocTransformer>(actionTransformerString);
            actionSequenceDocAdapter.actionTransformer = actionTransformerDocAdapter;

            const validatorsObjArray: Array<IInputValidator> = [];
            // if (context.container.isBound(adapter.adapter + operation + 'InputValidator')) {
            //   validatorsObjArray.push(context.container.get<IInputValidator>(adapter.adapter + operation + 'InputValidator'));
            // }

            // actionSequenceDocAdapter.actionObject = context.container.get<IDocActionPerformer>(
            //   adapter.adapter + 'ActionPerformer',
            // );

            const validators = adapterInstance.getInputValidators(operation);

            validators.forEach((validatorString) => {
                validatorsObjArray.push(context.container.get<IInputValidator>(validatorString));
            });

            if (validatorsObjArray.length == 0) {
                validatorsObjArray.push(context.container.get<IInputValidator>(EValidator.DummyValidator));
            }

            log.debug('InputValidators for operation ' + operation + ' ' + validatorsObjArray.toString());

            validatorsObjArray.forEach((validatorsObj) => {
                validatorsObj.validationInfo = adapter.validationInfo;
            });

            actionSequenceDocAdapter.inputValidator = validatorsObjArray;

            adapterInstance.capability.actionSequence = actionSequenceDocAdapter;
            // }
            return adapterInstance;
        };
    };
});

export { myContainer };
