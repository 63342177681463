import {
    renameDocument,
    renameFolder,
    deleteDocument,
    deleteFolder,
    deleteEmail,
    deleteEmailFolder,
    addFolder,
    documentMetadata,
    IRenameDocument,
    IDocumentMetadata,
    IRenameFolder,
    IDeleteDocument,
    IDeleteEmail,
    IAddFolder,
    IDownloadDocument,
    editDocument,
    editFolder,
    getAuthToken,
    DocSupportedBaseEntityTypesEnum,
} from '@wk/elm-uui-doc-component';
import { IDocContextHandler } from './interfaces/interface';
import { inject, injectable } from 'inversify';
// import { Transformer } from "./transformer";
import 'reflect-metadata';
import * as Services from './services';
import { getReverseTransformedObject, getTransformedObject } from './transform/helper';
import { Locale } from './locale';
import { CapabiltyEnum, CHSupportedDocumentTypesEnum, DocumentState, EventType, ToastType } from './enum/enum';
import { QueueCapability } from './capability/queueCapability';
import { QueueController } from './queueController';

import { factory } from './configLog4J';
import { DocumentEntity } from './dto/documentEntity';
import {
    ICHAddFolder,
    ICHDeleteEmail,
    ICHDocumentMetadata,
    ICHRenameDocument,
    ICHRenameFolder,
    ICHCheckoutDocMetaType,
    ICHCancelCheckoutDocMetaType,
    ICHDeleteDocMetaType,
    ICHDeleteDocument,
    ICHDownloadDocMetaType,
    ICHQuickFileIndicator,
    ICHUploadType,
    ICHUploadResponse,
    ICHEditDocument,
    ICHEditFolder,
    ICHProps,
    ICHResponse,
    ICHValidateEditDocument,
    IValidateEditDocument,
    ICHValidateEditFolder,
    IValidateAddFolder,
    ICHValidateAddFolder,
    IValidateEditFolder,
} from './interfaces/operations';
import { CtxDocumentMetadataTransformer } from './transform/ctxDocumentMetadataTransformer';
import { CtxRenameFolderTransformer } from './transform/ctxRenameFolder';
import { CtxDeleteDocumentTransformer } from './transform/ctxDeleteDocumentTransformer';
import { CtxDeleteEmailTransformer } from './transform/ctxDeleteEmailTransformer';
import { CtxRenameDocumentTransformer } from './transform/ctxRenameDocument';
import { CtxAddFolderTransformer } from './transform/ctxAddFolderTransformer';
import { CtxEditDocumentTransformer } from './transform/ctxEditDocumentTransformer';
import { DCResponse, IEditDocument, IEditFolder } from '@wk/elm-uui-doc-component/dist/types';
import { CtxEditFolderTransformer } from './transform/ctxEditFolderTransformer';
import { IQueuePropItem } from './services';
import { CtxValidateEditDocumentTransformer } from './transform/ctxValidateEditDocumentTransformer';
import { CtxValidateEditFolderTransformer } from './transform/ctxValidateEditFolderTransformer';
import { CtxValidateAddFolderTransformer } from './transform/ctxValidateAddFolderTransformer';
import { ItemScreenOperationConfig } from './config';
import { deleteFromPersistentStorage, throwUnExpectedError } from './utils/main.utils';

const log = factory.getLogger('OCContextHandler');
const usageLog = factory.getLogger('UsageLogging');
@injectable()
export class OCContextHandler implements IDocContextHandler {
    private _documentsService: Services.IDocumentsService;
    private _officeService: Services.IOfficeService;
    private _eventService: Services.IEventService;
    private _messageService: Services.MessageService;
    private _emailService: Services.IEmailService;
    private _checkoutDiscardService: Services.CheckoutDiscardService;
    private _newDocumentUpload: Services.DocumentUploadService;
    private _checkInService: Services.CheckInService;
    private _docSummaryCheckInService: Services.DocumentSummaryCheckInService;
    private _dragAndDropService: Services.DragAndDropService;
    private _propsService: Services.ICHProperties;

    queueController!: QueueController;
    constructor(
        @inject('DocumentsService') documentsService: Services.IDocumentsService,
        @inject('EventContextHandler') eventService: Services.IEventService,
        @inject('OfficeService') officeService: Services.IOfficeService,
        @inject('MessageService') messageService: Services.MessageService,
        @inject('EmailService') emailService: Services.IEmailService,
        @inject('CheckoutDiscardService') checkoutDiscardService: Services.CheckoutDiscardService,
        @inject('DocumentUploadService') newDocumentUpload: Services.DocumentUploadService,
        @inject('CheckInService') checkInService: Services.CheckInService,
        @inject('DocumentSummaryCheckInService') docSummaryCheckInService: Services.DocumentSummaryCheckInService,
        @inject('DragAndDropService') dragAndDropService: Services.DragAndDropService,
        @inject('PropsService') propsService: Services.ICHProperties,
    ) {
        this._documentsService = documentsService;
        this._eventService = eventService;
        this._officeService = officeService;
        this._messageService = messageService;
        this._emailService = emailService;
        this._checkoutDiscardService = checkoutDiscardService;
        this._newDocumentUpload = newDocumentUpload;
        this._checkInService = checkInService;
        this._docSummaryCheckInService = docSummaryCheckInService;
        this._dragAndDropService = dragAndDropService;
        this._propsService = propsService;
    }
    getDocScreenNameToLaunch?: (() => Promise<Response>) | undefined;

    performDragAndDrop = async (contextObject: ICHUploadType): Promise<ICHUploadResponse | ICHUploadResponse[]> => {
        usageLog.info('Invoked OC Context performDragAndDrop');
        return await this._dragAndDropService.processDroppedItems(contextObject);
    };

    // TO DO
    supportedOperations = async (): Promise<Array<string>> => await ['TEST'];
    // TO DO
    performOperation = async (): Promise<Array<string>> => await ['TEST'];
    // TO DO
    getContextInfo = async (): Promise<Array<string>> => await ['TEST'];
    queueCapability!: QueueCapability;

    addDocument = async (contextObj: ICHUploadType): Promise<ICHUploadResponse> => {
        try {
            usageLog.info('Invoked OC Context addDocument');
            log.info('OC | Upload new active document');
            // Check stale validation when QF or DND document on folder
            if (contextObj.folderId) {
                const DocumentEntity = await this._documentsService.wasStaleDocument(
                    contextObj,
                    DocumentState.FOLDER_EXIST,
                );
                if (!DocumentEntity) {
                    return { responseText: Locale.stale.deleted_folder };
                }
            }
            contextObj.documentId = undefined;
            return await this._newDocumentUpload.addNewDocument(contextObj);
        } catch (err) {
            log.error(`add document: ${JSON.stringify(err)}`);
            deleteFromPersistentStorage(contextObj.filePaths);
            throwUnExpectedError(err);
            return { responseText: Locale.responseText.failures.add_document };
        }
    };

    updateDocument = async (contextObj: ICHUploadType): Promise<ICHUploadResponse> => {
        try {
            usageLog.info('Invoked OC Context updateDocument');
            log.info('isQuickFile ' + contextObj.isQuickFile);
            if (!contextObj.noOfItems) {
                contextObj.noOfItems = 1;
            }
            const isQuickFile = contextObj.isQuickFile === undefined ? false : contextObj.isQuickFile;
            const isDragAndDrop = contextObj.isDragAndDrop === undefined ? false : contextObj.isDragAndDrop;
            if (!isQuickFile && !isDragAndDrop) {
                if (!(await this._documentsService.wasStaleDocument(contextObj, DocumentState.DOC_EXIST))) {
                    return { responseText: Locale.stale.deleted_document };
                }
                if (await this._documentsService.wasStaleDocument(contextObj, DocumentState.CHECKEDIN)) {
                    return { responseText: Locale.stale.checkin_document };
                }
            }

            if (contextObj.documentId) {
                if (contextObj.isQuickFile || contextObj.isDragAndDrop) {
                    log.info('OC | Update Active Document');
                    return await this._checkInService.updateActiveDocument(contextObj);
                }
                log.info('OC | Update CheckedOut Document');
                return await this._checkInService.updateCheckedOutDocument(contextObj);
            } else {
                log.info('OC | Update Active Document');
                return await this._checkInService.updateActiveDocument(contextObj);
            }
        } catch (err) {
            log.error(`update document: ${JSON.stringify(err)}`);
            deleteFromPersistentStorage(contextObj.filePaths);
            throwUnExpectedError(err);
            return { responseText: Locale.responseText.failures.checkin_document };
        }
    };

    renameDocument = (contextObj: ICHRenameDocument): Promise<Response> => {
        usageLog.info('Invoked OC Context renameDocument');
        const transformedObject = getTransformedObject<
            ICHRenameDocument,
            IRenameDocument,
            CtxRenameDocumentTransformer
        >(CapabiltyEnum.RENAME_DOCUMENT, contextObj);
        return renameDocument(transformedObject);
    };

    renameFolder = (contextObj: ICHRenameFolder): Promise<Response> => {
        usageLog.info('Invoked OC Context renameFolder');
        const transformedObject = getTransformedObject<ICHRenameFolder, IRenameFolder, CtxRenameFolderTransformer>(
            CapabiltyEnum.RENAME_FOLDER,
            contextObj,
        );
        return renameFolder(transformedObject);
    };

    deleteDocument = async (contextObj: ICHDeleteDocMetaType): Promise<ICHResponse> => {
        usageLog.info('Invoked OC Context deleteDocument');
        try {
            const isDocumentAvailable = await this._documentsService.wasStaleDocument(
                contextObj,
                DocumentState.DOC_EXIST,
            );
            if (isDocumentAvailable) {
                const transformedObject = getTransformedObject<
                    ICHDeleteDocMetaType,
                    IDeleteDocument,
                    CtxDeleteDocumentTransformer
                >(CapabiltyEnum.DELETE_DOCUMENT, contextObj);
                const { extendedProps } = transformedObject;
                const response = await this.documentMetadata(contextObj);
                // TO DO
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const { id, versionSeriesCheckedOutBy, documentFileName } = await response.outputInfo();
                if (id) {
                    const checkedOutMessage = this._documentsService.getCheckedOutMessage(
                        extendedProps.loggedInUser,
                        versionSeriesCheckedOutBy,
                        documentFileName,
                    );
                    if (!checkedOutMessage) {
                        const isBusy = await this._officeService.isBusy();
                        if (isBusy) {
                            return await this._documentsService.handleIsApplicationBusy(Locale.documents.delete);
                        }
                        const successMessage = this._messageService.compileTemplate(
                            Locale.responseText.success.delete_document,
                            {
                                filename: documentFileName,
                            },
                        );
                        const response = await deleteDocument(transformedObject);
                        return await this._documentsService.handleResponse(
                            contextObj,
                            response,
                            successMessage,
                            ItemScreenOperationConfig.DeleteDocument,
                            true,
                        );
                    } else {
                        return this._documentsService.handleErrorMessage({ message: checkedOutMessage });
                    }
                } else {
                    return Locale.stale.deleted_document;
                }
            } else {
                return Locale.stale.deleted_document;
            }
        } catch (error) {
            throwUnExpectedError(error);
            if (error) {
                log.error('OC | delete Document Error ' + error);
            }
            return this._documentsService.handleErrorMessage({ message: error.message });
        }
    };

    deleteFolder = async (contextObj: ICHDeleteDocument): Promise<ICHResponse> => {
        usageLog.info('Invoked OC Context deleteFolder');
        const transformedObject = getTransformedObject<
            ICHDeleteDocument,
            IDeleteDocument,
            CtxDeleteDocumentTransformer
        >(CapabiltyEnum.DELETE_DOCUMENT, contextObj);
        try {
            const isFolderExist = await this._documentsService.wasStaleDocument(contextObj, DocumentState.FOLDER_EXIST);
            if (isFolderExist) {
                const response = await deleteFolder(transformedObject);
                return await this._documentsService.handleResponse(
                    contextObj,
                    response,
                    Locale.responseText.success.delete_folder,
                    ItemScreenOperationConfig.DeleteFolder,
                    true,
                );
            } else {
                return Locale.stale.deleted_folder;
            }
        } catch (error) {
            if (error) {
                log.error('OC | delete Folder Error ' + error);
            }
            throwUnExpectedError(error);
            return this._documentsService.handleErrorMessage({ message: (error as Error).message });
        }
    };

    deleteEmail = async (contextObj: ICHDeleteEmail): Promise<ICHResponse> => {
        usageLog.info('Invoked OC Context deleteEmail');
        try {
            const isEmailAvailable = await this._documentsService.wasStaleDocument(
                contextObj,
                DocumentState.EMAIL_EXIST,
            );
            if (isEmailAvailable) {
                const transformedObject = getTransformedObject<ICHDeleteEmail, IDeleteEmail, CtxDeleteEmailTransformer>(
                    CapabiltyEnum.DELETE_EMAIL,
                    contextObj,
                );
                const response = await deleteEmail(transformedObject);
                return await this._documentsService.handleResponse(
                    contextObj,
                    response,
                    Locale.responseText.success.delete_email,
                    ItemScreenOperationConfig.DeleteEmail,
                    true,
                );
            } else {
                return Locale.stale.deleted_email;
            }
        } catch (error) {
            if (error) {
                log.error('OC | delete Email Error ' + error);
            }
            throwUnExpectedError(error);
            return this._documentsService.handleErrorMessage({ message: (error as Error).message });
        }
    };

    deleteEmailFolder = async (contextObj: ICHDeleteEmail): Promise<ICHResponse> => {
        try {
            const isFolderExist = await this._documentsService.wasStaleDocument(contextObj, DocumentState.FOLDER_EXIST);
            if (isFolderExist) {
                const transformedObject = getTransformedObject<ICHDeleteEmail, IDeleteEmail, CtxDeleteEmailTransformer>(
                    CapabiltyEnum.DELETE_EMAIL_FOLDER,
                    contextObj,
                );
                const response = await deleteEmailFolder(transformedObject);
                return await this._documentsService.handleResponse(
                    contextObj,
                    response,
                    Locale.responseText.success.delete_email_folder,
                    ItemScreenOperationConfig.DeleteEmailFolder,
                    true,
                );
            } else {
                return Locale.stale.deleted_folder;
            }
        } catch (error) {
            if (error) {
                log.error('OC | delete Email Folder Error ' + error);
            }
            throwUnExpectedError(error);
            return this._documentsService.handleErrorMessage({ message: (error as Error).message });
        }
    };

    checkInDocument = async (contextObj: ICHUploadType): Promise<ICHUploadResponse> => {
        usageLog.info('Invoked OC Context checkInDocument');
        log.info('OC | CheckIn Document');
        contextObj.noOfItems = 1;
        contextObj.isQuickFile = false;
        return await this._checkInService.processDocumentCheckIn(contextObj, contextObj.isQuickFile);
    };

    addFolder = async (contextObj: ICHAddFolder): Promise<ICHResponse> => {
        usageLog.info('Invoked OC Context addFolder');
        try {
            const isFolderExist = await this._documentsService.wasStaleDocument(
                contextObj,
                DocumentState.ADD_FOLDER_EXIST,
            );
            if (!isFolderExist) {
                return { responseText: Locale.stale.deleted_folder };
            }
            const transformedbject = getTransformedObject<ICHAddFolder, IAddFolder, CtxAddFolderTransformer>(
                CapabiltyEnum.ADD_FOLDER,
                contextObj,
            );
            const response = await addFolder(transformedbject);
            if (response && !response.ok) {
                const transformedObject = { errors: {} };
                transformedObject.errors = getReverseTransformedObject<
                    IValidateAddFolder,
                    ICHValidateAddFolder,
                    CtxValidateAddFolderTransformer
                >(CapabiltyEnum.VALIDATE_ADD_FOLDER, response.statusInfo.errors as unknown as IValidateAddFolder);
                response.statusInfo = transformedObject;
            }

            return await this._documentsService.handleResponse(
                contextObj,
                response,
                Locale.responseText.success.add_folder,
                ItemScreenOperationConfig.AddFolder,
            );
        } catch (error) {
            if (error) {
                log.error('OC | Add Folder Error ' + error);
            }
            throwUnExpectedError(error);
            return this._documentsService.handleErrorMessage({ message: (error as Error).message });
        }
    };

    downloadDocument = (contextObj: ICHDownloadDocMetaType): Promise<Response | string> => {
        usageLog.info('Invoked OC Context downloadDocument');
        return this.showDocument(contextObj, true);
    };

    documentMetadata = (contextObj: ICHDocumentMetadata): Promise<DCResponse> => {
        const transformedObject = getTransformedObject<
            ICHDocumentMetadata,
            IDocumentMetadata,
            CtxDocumentMetadataTransformer
        >(CapabiltyEnum.DOCUMENT_METADATA, contextObj);
        return documentMetadata(transformedObject);
    };

    documentMetadataContext = (contextObj: ICHDocumentMetadata): Promise<DCResponse> => {
        const transformedObject = getTransformedObject<
            ICHDocumentMetadata,
            IDocumentMetadata,
            CtxDocumentMetadataTransformer
        >(CapabiltyEnum.DOCUMENT_METADATA, contextObj);

        return documentMetadata(transformedObject);
    };

    showDocument = async (
        contextObj: ICHDownloadDocMetaType,
        isDownloadOnly?: boolean,
        isCheckoutRequest?: boolean,
    ): Promise<Response | string> => {
        usageLog.info('Invoked OC Context showDocument');
        const supportedEntity = await this._documentsService.getEntityType(contextObj);
        if (supportedEntity === DocSupportedBaseEntityTypesEnum.EMAIL) {
            return await this.handleShowEmail(contextObj, isDownloadOnly);
        }
        return this._checkoutDiscardService.showDocument(contextObj, isDownloadOnly, isCheckoutRequest);
    };

    handleShowEmail = async (
        contextObj: ICHDownloadDocMetaType,
        isDownloadOnly?: boolean,
    ): Promise<Response | string> => {
        usageLog.info('Invoked OC Context showEmail');
        const isEmailExist = await this._documentsService.wasStaleDocument(contextObj, DocumentState.EMAIL_EXIST);
        if (isEmailExist) {
            const documentEntity: DocumentEntity | undefined = await this._emailService.getEmailMeta(contextObj);
            if (documentEntity) {
                const { downloadUrl } = documentEntity;
                if (isDownloadOnly) {
                    let authHeader = {};
                    const authToken = await getAuthToken();
                    if (authToken) {
                        authHeader = { Authorization: 'Bearer ' + authToken };
                    }
                    return await this._officeService.download(this._documentsService.encodeURI(downloadUrl), {
                        showSaveAsDialog: true,
                        requestHeaders: authHeader,
                    });
                } else {
                    try {
                        await this._documentsService.downloadAndOpen({ url: downloadUrl, isCheckedOutByMe: false });
                    } catch (err) {
                        log.error(err);
                        throwUnExpectedError(err);
                    }
                    return Locale.email.success.download_email;
                }
            } else {
                return Locale.stale.deleted_email;
            }
        } else {
            return Locale.stale.deleted_email;
        }
    };

    checkOut = async (contextObj: ICHCheckoutDocMetaType): Promise<Response | string> => {
        usageLog.info('Invoked OC Context checkOut');
        return this._checkoutDiscardService.checkOut(contextObj);
    };

    overwriteCheckout = async (contextObj: ICHCheckoutDocMetaType, uniqueId?: string): Promise<Response | string> => {
        usageLog.info('Invoked OC Context overwriteCheckout');
        return this._checkoutDiscardService.overwriteCheckout(contextObj, uniqueId);
    };

    keepCheckout = async (contextObj: ICHCheckoutDocMetaType, uniqueId?: string): Promise<Response | string> => {
        usageLog.info('Invoked OC Context keepCheckout');
        return this._checkoutDiscardService.keepCheckout(contextObj, uniqueId);
    };

    cancelCheckOut = async (contextObj: ICHCancelCheckoutDocMetaType): Promise<Response | string> => {
        usageLog.info('Invoked OC Context cancelCheckOut');
        return this._checkoutDiscardService.cancelCheckOut(contextObj);
    };

    removeCancelCheckout = async (contextObj: ICHCancelCheckoutDocMetaType): Promise<Response | string> => {
        usageLog.info('Invoked OC Context removeCancelCheckout');
        return this._checkoutDiscardService.removeCancelCheckout(contextObj);
    };

    keepCancelCheckout = async (contextObj: ICHCancelCheckoutDocMetaType): Promise<Response | string> => {
        usageLog.info('Invoked OC Context keepCancelCheckout');
        return this._checkoutDiscardService.keepCancelCheckout(contextObj);
    };

    performQuickFile = async (contextObj: ICHUploadType): Promise<ICHUploadResponse | ICHUploadResponse[]> => {
        usageLog.info('Invoked OC Context performQuickFile');
        if (!(await this._officeService.isOutlook())) {
            contextObj.noOfItems = 1;
        }
        const isActiveDocSelection = await this._documentsService.isActiveDocumentSelection();
        const isDocumentRelatedApp = await this._officeService.isDocumentRelatedApp();
        if (!isActiveDocSelection && isDocumentRelatedApp) {
            const message = Locale.documents.quickfile_no_document;
            this._eventService.publish({
                name: EventType.TOAST,
                toast: {
                    toastMessage: message,
                    type: ToastType.ERROR,
                },
            });
            return { responseText: message };
        }

        // Checks if Excel Application is busy
        const isBusy = await this._officeService.isBusy();
        if (isBusy) {
            await this._documentsService.handleIsApplicationBusy(Locale.documents.quickfile_doc);
            return { responseText: Locale.documents.excel_document_busy.message };
        }

        contextObj.isQuickFile = true;
        if (!contextObj.documentId && (await this._officeService.isOutlook())) {
            this._emailService.addEmail(contextObj);
            // Don't wait till the return value from add email
            // Resolving promise to close the spinner ASAP
            return { responseText: Locale.email.responseText.add_email };
        } else if (!contextObj.documentId && isDocumentRelatedApp) {
            return await this._newDocumentUpload.processDocumentUpload(contextObj);
        } else if (contextObj.documentId && isDocumentRelatedApp) {
            return await this._docSummaryCheckInService.processDocumentCheckIn(contextObj);
        }
        return { responseText: Locale.responseText.not_supported };
    };

    showToast = (type: ToastType, message: string): void => {
        this._eventService.publish({
            name: EventType.TOAST,
            toast: {
                type: type,
                toastMessage: message,
            },
        });
    };

    showToast_CheckedOutByOther = (documentEntity: DocumentEntity, data: IDownloadDocument): void => {
        const isCheckedOutByOther = this._documentsService.isCheckedOutByOther(
            data?.extendedProps?.loggedInUser,
            documentEntity.versionSeriesCheckedOutBy,
        );
        if (isCheckedOutByOther) {
            const message = this._messageService.compileTemplate(Locale.documents.checked_out_not_by_me, {
                lockedBy: documentEntity?.versionSeriesCheckedOutBy,
                filename: documentEntity.documentFileName,
            });
            this.showToast(ToastType.WARNING, message);
        }
    };

    getIsQuickFileEnabled = async (
        isFolder?: boolean,
        documentType?: CHSupportedDocumentTypesEnum,
    ): Promise<ICHQuickFileIndicator> => {
        const resp = await this._officeService.isOutlook();
        const isFldr = isFolder ? true : false;
        const isDoc = documentType ? (documentType === CHSupportedDocumentTypesEnum.DOCUMENT ? true : false) : false;
        const isEmail = documentType ? (documentType === CHSupportedDocumentTypesEnum.EMAIL ? true : false) : false;
        const isOutLook = resp ? true : false;

        return this.qfForCombinations(isFldr, isDoc, isEmail, isOutLook);
    };

    qfForCombinations = async (
        isFolder: boolean,
        isDoc: boolean,
        isEmail: boolean,
        isOutLook: boolean,
    ): Promise<ICHQuickFileIndicator> => {
        if (
            (!isFolder && !isDoc && !isEmail && !isOutLook) ||
            (!isFolder && isDoc && !isEmail && !isOutLook) ||
            (isFolder && isDoc && !isEmail && !isOutLook) ||
            (isFolder && !isDoc && !isEmail && !isOutLook)
        ) {
            return this.qfForDocument();
        } else if (
            (!isFolder && !isDoc && !isEmail && isOutLook) ||
            (!isFolder && !isDoc && isEmail && isOutLook) ||
            (isFolder && !isDoc && isEmail && isOutLook) ||
            (isFolder && !isDoc && !isEmail && isOutLook)
        ) {
            return this.qfForEmail();
        } else if (
            (!isFolder && isDoc && !isEmail && isOutLook) ||
            (isFolder && isDoc && !isEmail && isOutLook) ||
            (isFolder && !isDoc && isEmail && !isOutLook)
        ) {
            return this.qfNotSupported();
        } else {
            return this.qfNotSupported();
        }
    };

    qfForEmail = (): ICHQuickFileIndicator => {
        return {
            enableQuickFile: true,
            entityType: CHSupportedDocumentTypesEnum.EMAIL,
        };
    };

    qfForDocument = (): ICHQuickFileIndicator => {
        return {
            enableQuickFile: true,
            entityType: CHSupportedDocumentTypesEnum.DOCUMENT,
        };
    };

    qfNotSupported = (): ICHQuickFileIndicator => {
        return {
            enableQuickFile: false,
            entityType: CHSupportedDocumentTypesEnum.EMAIL,
        };
    };

    editDocument = async (contextObj: ICHEditDocument): Promise<ICHResponse> => {
        usageLog.info('Invoked OC Context editDocument');
        const isDocumentExist = await this._documentsService.wasStaleDocument(contextObj, DocumentState.EDIT_DOC_EXIST);
        if (!isDocumentExist) {
            return { responseText: Locale.stale.deleted_document };
        }
        const transformedObject = getTransformedObject<ICHEditDocument, IEditDocument, CtxEditDocumentTransformer>(
            CapabiltyEnum.EDIT_DOCUMENT,
            contextObj,
        );
        const response = await editDocument(transformedObject);
        if (response && !response.ok) {
            const transformedObject = { errors: {} };
            transformedObject.errors = getReverseTransformedObject<
                IValidateEditDocument,
                ICHValidateEditDocument,
                CtxValidateEditDocumentTransformer
            >(CapabiltyEnum.VALIDATE_EDIT_DOCUMENT, response.statusInfo.errors as unknown as IValidateEditDocument);
            response.statusInfo = transformedObject;
        }

        return await this._documentsService.handleResponse(
            contextObj,
            response,
            Locale.responseText.success.edit_document,
            ItemScreenOperationConfig.EditDocument,
        );
    };

    editFolder = async (contextObj: ICHEditFolder): Promise<ICHResponse> => {
        usageLog.info('Invoked OC Context editFolder');
        const isFolderExist = await this._documentsService.wasStaleDocument(
            contextObj,
            DocumentState.EDIT_FOLDER_EXIST,
        );
        if (!isFolderExist) {
            return { responseText: Locale.stale.deleted_folder };
        }
        const transformedObject = getTransformedObject<ICHEditFolder, IEditFolder, CtxEditFolderTransformer>(
            CapabiltyEnum.EDIT_FOLDER,
            contextObj,
        );
        const response = await editFolder(transformedObject);
        if (response && !response.ok) {
            const transformedObject = { errors: {} };
            transformedObject.errors = getReverseTransformedObject<
                IValidateEditFolder,
                ICHValidateEditFolder,
                CtxValidateEditFolderTransformer
            >(CapabiltyEnum.VALIDATE_EDIT_FOLDER, response.statusInfo.errors as unknown as IValidateEditFolder);
            response.statusInfo = transformedObject;
        }

        return await this._documentsService.handleResponse(
            contextObj,
            response,
            Locale.responseText.success.edit_folder,
            ItemScreenOperationConfig.EditFolder,
        );
    };

    initialize = async (clProps: ICHProps): Promise<void> => {
        log.debug(' CL props : ' + JSON.stringify(clProps));
        this._propsService.set(clProps);
    };

    getAllProps = async (): Promise<IQueuePropItem[]> => {
        return this._propsService.getAllProps();
    };
}
