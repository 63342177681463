import { LogLevel } from '@wk/elm-uui-common';
import { alertDialogsReducer } from './alertDialogs.reducer';
import { globalStateReducer } from './globalState.reducer';
import { helpSectionReducer } from './helpSection.reducer';
import { historyScreenReducer } from './historyScreen.reducer';
import { itemScreenDialogsReducer } from './itemScreenDialogs.reducer';
import { messageBusReducer } from './messageBus.reducer';
import { notificationsReducer } from './notifications.reducer';
import { overlayDialogReducer } from './overlayDialog.reducer';
import { UiState, UUIReduxAction } from './types';
import { uploadHistoryReducer } from './uploadHistory.reducer';
import { userScreenReducer } from './userScreen.reducer';

export const defaultUiState: UiState = {
    globalState: {
        pageTitle: 'Loading...',
        activelyDraggedFilesCount: 0,
        draggedFileIsOverValidDropTarget: false,
        activeScreenId: 0,
    },
    userScreen: {
        isUserScreenDialogOpen: false,
        userScreenDialogKey: 0,
    },
    helpSection: {
        isHelpSectionDialogOpen: false,
        aboutDialogOpen: false,
        contactsDialogOpen: false,
        logLevelSelectorDialogOpen: false,
        logLevel: { key: LogLevel.Error, value: '' },
    },
    itemScreenDialogs: [],
    notifications: [],
    alertDialogs: [],
    messageBus: {},
    historyScreen: {
        isHistoryDialogOpen: false,
        isFailedUploadFlyoutOpen: false,
        batchUploadFlyoutCount: '',
        isNewUploadCountUpdated: false,
        isUploadingFlyoutOpen: false,
    },
    uploadHistoryItems: [],
};

export const uiReducer = (state: UiState = defaultUiState, action: UUIReduxAction): UiState => {
    return {
        globalState: globalStateReducer(state.globalState, action),
        userScreen: userScreenReducer(state.userScreen, action),
        helpSection: helpSectionReducer(state.helpSection, action),
        itemScreenDialogs: itemScreenDialogsReducer(state.itemScreenDialogs, action),
        notifications: notificationsReducer(state.notifications, action),
        alertDialogs: alertDialogsReducer(state.alertDialogs, action),
        historyScreen: historyScreenReducer(state.historyScreen, action),
        messageBus: messageBusReducer(state.messageBus, action),
        uploadHistoryItems: uploadHistoryReducer(state.uploadHistoryItems, action),
        overlayDialog: overlayDialogReducer(state.overlayDialog, action),
    };
};
