import { IDocActionPerformer } from '../../interfaces/actionPerformers/type';
import { IActionableEntity } from '../../interfaces/actionEntity/type';
import { factory } from '../../ConfigLog4j';
import { IDMToken } from '../../interfaces/dmTokenHandler/type';
import { injectable } from 'inversify';
import { IAuthorizationInfo } from '../../interfaces/authScheme/type';
import { IDocOperationInvocation } from '../../interfaces/urlPattern/types';
import { DCResponse } from '../../types';

const log = factory.getLogger('JRAdapterActionPerformer');

@injectable()
export class JRAdapterActionPerformer implements IDocActionPerformer {
    tokenHandler: IDMToken;

    fetchRequest: (arg0: string, arg1: IActionableEntity, arg2: string) => Promise<DCResponse>;

    performAction(
        obj: IActionableEntity,
        urlObj: Promise<string>,
        _docOper: IDocOperationInvocation,
    ): Promise<DCResponse> {
        log.debug('Calling performAction function');

        return this.tokenHandler.getAuthInfo().then((token: IAuthorizationInfo) => {
            // TO add call to the server to do operation

            return urlObj.then((url) => {
                return this.fetchRequest(url, obj, token.token);
            });
        });
    }
}
