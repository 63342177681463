import { ContextIndicatorEnum } from './enum/applicationContextUI.enum';
import { CapabiltyEnum } from './enum/enum';

interface IQueueSubscribedMap {
    [x: string]: {
        subscribedFunction: { bindingName: string; functionName: string };
        handleResponseFunc?: { bindingName: string; functionName: string };
        handleErrorFunc?: { bindingName: string; functionName: string };
        handleStartedFunc?: { bindingName: string; functionName: string };
        afterResponseFunc?: { bindingName: string; functionName: string };
    };
}

export const QueueSubscribedMap: IQueueSubscribedMap = {
    [CapabiltyEnum.ADD_DOCUMENT + '_' + ContextIndicatorEnum.UI]: {
        subscribedFunction: { bindingName: 'UIContextHandler', functionName: 'addDocumentQueue' },
    },

    [CapabiltyEnum.CHECKIN_DOCUMENT + '_' + ContextIndicatorEnum.UI]: {
        subscribedFunction: { bindingName: 'UIContextHandler', functionName: 'checkInQueue' },
    },

    [CapabiltyEnum.ADD_DOCUMENT + '_' + ContextIndicatorEnum.OC]: {
        subscribedFunction: { bindingName: 'DocumentUploadService', functionName: 'fileUpload' },
    },
    [CapabiltyEnum.CHECKIN_DOCUMENT + '_' + ContextIndicatorEnum.OC]: {
        subscribedFunction: { bindingName: 'CheckInService', functionName: 'fileUpload' },
    },
    [CapabiltyEnum.ADD_EMAIL + '_' + ContextIndicatorEnum.OC]: {
        subscribedFunction: { bindingName: 'EmailService', functionName: 'emailUpload' },
    },
};
