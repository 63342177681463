// check if the Authorization Token Required
import { isNodeEnvironment, isT360 } from './shared.utils';
import { getAccessToken } from '../t360Adapter/utils/utils';

export function isAuthToken(): boolean {
    // for T360
    if (isT360()) {
        return true;
    } else {
        // For JR
        return isNodeEnvironment();
    }
}

export const getToken = async (): Promise<string> => {
    if (isT360()) {
        return 'Bearer ' + (await getAccessToken());
    }
    // to be used in case of Node Environment Jest Tests
    // TO DO to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return 'Basic ' + new Buffer(credentials.username + ':' + credentials.password).toString('base64');
};
