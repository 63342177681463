import { createContext } from 'react';

export interface NetworkContextData {
    switchNetwork: (networkId: number) => void;
}

export interface AppContextData {
    network?: NetworkContextData;
}

export const AppContext = createContext<AppContextData>({});
