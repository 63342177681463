import { injectable, inject } from 'inversify';
import 'reflect-metadata';
import {
    FileOperations,
    browserUtilitiesService,
    ApplicationType,
    SaveOptions,
    DownloadOptions,
} from '@wk/office-companion-js';
import { factory } from '../configLog4J';
import { WordService, ExcelService, PowerPointService, IOfficeDocumentService, IOutlookService } from './office';
import { Locale } from '../locale';
import { CapabiltyEnum, CHSupportedDocumentTypesEnum, EventType, ToastType } from '../enum/enum';
import { IEventService } from './eventContextHandler.service';
import { MessageService } from './message.service';
import { getErrorMessageOnFileDownloadFromOC } from '../utils/messaging.utils';

export interface IOfficeService {
    isNewDocument(): Promise<boolean>;
    isDocumentSaved(): Promise<boolean>;
    saveAs(filePath: string): Promise<void>;
    save(): Promise<void>;
    close(saveChanges?: SaveOptions): Promise<void>;
    getCurrentDocument(): Promise<string>;
    getCurrentDocumentFullName(): Promise<string>;
    getActiveDocumentSelection(): Promise<boolean>;
    saveActiveDocument(): Promise<boolean>;
    saveAsActiveDocument(): Promise<boolean>;
    isFileExists(uniqueId: string): Promise<boolean>;
    isFileOpen(uniqueId: string): Promise<boolean>;
    isDeleted(uniqueId: string): Promise<boolean>;
    isOutlook(): Promise<boolean>;
    createOfficeInstance(): Promise<void>;
    getExtension(fileName: string): string;
    getDefaultExtension(): Promise<string>;
    getCurrentDocumentWithExtension(): Promise<string>;
    isInspector(): Promise<boolean>;
    isExplorer(): Promise<boolean>;
    isReadOnly(): Promise<boolean>;
    isBusy(): Promise<boolean>;
    getFileNameFromFullPath(path: string): Promise<string>;

    /**
     *
     * @param uniqueId To get file meta from OC
     * @param strictCompare optional flag If you need to compare and return true, only if entire path is matched
     * @returns boolean value
     */
    isActiveDocument(uniqueId: string, strictCompare?: boolean): Promise<boolean>;

    saveAsInTempStorage(isUpload?: boolean): Promise<string>;
    getFileNameWithoutExtension(name: string): string;
    isAnyOfficeApp(): Promise<boolean>;
    isDocumentRelatedApp(): Promise<boolean>;
    persistToStorage(parts: string[], path?: string): Promise<string>;
    activateWindow(uniqueId: string): Promise<void>;
    checkPathToActivate(pathInfo: string): Promise<void>;
    getFileNameFromMeta(uniqueId: string): Promise<string>;
    showEmailOrDocumentUploadToast(folderName: string, capability: string): void;
    compareFilePaths(path1: string, path2: string): Promise<boolean>;
    download(url: string, options?: DownloadOptions): Promise<string>;
    isExcel: boolean;
    isWord: boolean;
    isPowerPoint: boolean;
    uniqueId: string;
}

const log = factory.getLogger('OfficeService');

@injectable()
export class OfficeService implements IOfficeService {
    private _excelService: ExcelService;
    private _wordService: WordService;
    private _powerPointService: PowerPointService;
    private _outlookService: IOutlookService;
    private appService: IOfficeDocumentService | undefined;
    private _eventService: IEventService;
    private _messageService: MessageService;
    isExcel = false;
    isWord = false;
    isPowerPoint = false;
    public uniqueId = '';

    constructor(
        @inject('ExcelService') excelService: ExcelService,
        @inject('WordService') wordService: WordService,
        @inject('PowerPointService') powerPointService: PowerPointService,
        @inject('OutlookService') outlookService: IOutlookService,
        @inject('EventContextHandler') eventService: IEventService,
        @inject('MessageService') messageService: MessageService,
    ) {
        this._excelService = excelService;
        this._wordService = wordService;
        this._powerPointService = powerPointService;
        this._outlookService = outlookService;
        this._eventService = eventService;
        this._messageService = messageService;
    }

    // Need to be invoked before accessing any API's from OfficeDocument Services
    public async initialize(): Promise<void> {
        await this.createOfficeInstance();
    }

    public async createOfficeInstance(): Promise<void> {
        try {
            if (await this.isApplicationType<ApplicationType>(ApplicationType.Excel)) {
                this.isExcel = true;
                this.appService = this._excelService;
            } else if (await this.isApplicationType<ApplicationType>(ApplicationType.Word)) {
                this.isWord = true;
                this.appService = this._wordService;
            } else if (await this.isApplicationType<ApplicationType>(ApplicationType.PowerPoint)) {
                this.isPowerPoint = true;
                this.appService = this._powerPointService;
            }
        } catch (error) {
            log.error((error as Error).message);
        }
    }

    public async isApplicationType<T extends ApplicationType>(applicationType: T): Promise<boolean> {
        try {
            const applicationContextType = await browserUtilitiesService.applicationType();
            return applicationType === applicationContextType;
        } catch (error) {
            log.error('Error occurred when checking is isApplicationType');
        }
        return false;
    }

    public async isNewDocument(): Promise<boolean> {
        await this.initialize();
        return this.appService ? await this.appService.isNewDocument() : false;
    }

    public async isDocumentSaved(): Promise<boolean> {
        if ((await this.isOutlook()) || (await this.isNewDocument())) {
            return true;
        }
        await this.initialize();
        return this.appService ? await this.appService.isDocumentSaved() : false;
    }

    public async save(): Promise<void> {
        await this.initialize();
        await this.appService?.save();
    }

    public async saveAs(filePath: string): Promise<void> {
        await this.initialize();
        await this.appService?.saveAs(filePath);
    }

    public async close(saveChanges: SaveOptions): Promise<void> {
        await this.initialize();
        if (saveChanges === SaveOptions.SaveChanges) {
            await this.saveActiveDocument();
        }
        await this.appService?.close(saveChanges);
    }

    public async getCurrentDocument(): Promise<string> {
        await this.initialize();
        return this.appService ? await this.appService.getCurrentDocument() : '';
    }

    public async getCurrentDocumentFullName(): Promise<string> {
        await this.initialize();
        return this.appService ? await this.appService.getCurrentDocumentFullName() : '';
    }

    public async getCurrentDocumentWithExtension(): Promise<string> {
        await this.initialize();
        let fileName = this.appService ? await this.appService.getCurrentDocument() : '';
        if (!this.getExtension(fileName)) {
            fileName += await this.getDefaultExtension();
        }
        return fileName;
    }

    public async getActiveDocumentSelection(): Promise<boolean> {
        await this.initialize();
        return this.appService ? await this.appService.getActiveDocumentSelection() : false;
    }

    public async saveActiveDocument(): Promise<boolean> {
        if (await this.isReadOnly()) {
            return false;
        }
        await this.save();
        return await this.isDocumentSaved();
    }

    public async saveAsActiveDocument(): Promise<boolean> {
        let fileName = await this.getCurrentDocument();
        if (!this.getExtension(fileName)) {
            fileName += await this.getDefaultExtension();
        }
        const dialogResult = await FileOperations.showSaveDialog(fileName);
        if (!dialogResult) {
            return false;
        }
        await this.saveAs(dialogResult);
        return await this.isDocumentSaved();
    }

    public getExtension(filename: string): string {
        const regexResult = filename.match(/(\.[\w-_]+)$/g);
        return Array.isArray(regexResult) && regexResult.length ? regexResult[0].toLowerCase() : '';
    }

    public async getDefaultExtension(): Promise<string> {
        await this.initialize();
        return this.appService ? await this.appService.getDefaultExtension() : '';
    }

    public async isFileExists(uniqueId: string): Promise<boolean> {
        try {
            return await FileOperations.exists(uniqueId);
        } catch (err) {
            log.error('Error | OC | File Exists ' + (err as Error).message);
        }
        return false;
    }

    public async isFileOpen(uniqueId: string): Promise<boolean> {
        try {
            return await FileOperations.isOpen(uniqueId);
        } catch (err) {
            log.error('Error | OC | isFileOpen ' + (err as Error).message);
        }
        return false;
    }

    public async isDeleted(uniqueId: string): Promise<boolean> {
        try {
            await FileOperations.delete(uniqueId);
            return true;
        } catch (err) {
            log.error('Error | OC | File Exists ' + (err as Error).message);
        }
        return false;
    }

    public async isSupportedDocType(): Promise<boolean> {
        return this.isPowerPoint || this.isExcel || this.isWord;
    }

    public async isOutlook(): Promise<boolean> {
        // check if in Outlook
        try {
            const applicationType = await browserUtilitiesService.applicationType();
            return ApplicationType.Outlook === applicationType;
        } catch (error) {
            log.error('Error occurred when checking if in Outlook ');
        }
        return false;
    }

    public async isAnyOfficeApp(): Promise<boolean> {
        await this.initialize();
        return (await this.isOutlook()) || this.isWord || this.isExcel || this.isPowerPoint;
    }

    public async isDocumentRelatedApp(): Promise<boolean> {
        await this.initialize();
        return this.isWord || this.isExcel || this.isPowerPoint;
    }

    public async isInspector(): Promise<boolean> {
        if (await this.isOutlook()) {
            return await this._outlookService.isInspectorWindow();
        }
        return false;
    }

    public async isExplorer(): Promise<boolean> {
        if (await this.isOutlook()) {
            return await this._outlookService.isExplorerWindow();
        }
        return false;
    }

    public async isReadOnly(): Promise<boolean> {
        await this.initialize();
        return this.appService ? await this.appService.isReadOnly() : false;
    }

    public async getFileNameFromFullPath(path: string): Promise<string> {
        // eslint-disable-next-line no-useless-escape
        return path.replace(/^.*[\\\/]/, '');
    }

    public getFileNameWithoutExtension(name: string): string {
        return name.substr(0, name.lastIndexOf('.'));
    }

    public async isActiveDocument(uniqueId: string, strictCompare = false): Promise<boolean> {
        await this.initialize();
        if (!(await this.isFileExists(uniqueId))) {
            return false;
        }
        const isOpen = await this.isFileOpen(uniqueId);
        const fileMeta = await FileOperations.getMetadata(uniqueId);
        const currentfileName = await this.getCurrentDocument();
        const { fileName, path } = fileMeta;

        if (isOpen || strictCompare) {
            if (await this.isOutlook()) {
                return false;
            }
            const documentFullName = this.appService ? await this.appService.getCurrentDocumentFullName() : '';
            return await this.compareFilePaths(path, documentFullName);
        }
        return await this.compareFilePaths(fileName, currentfileName);
    }

    public async download(url: string, options?: DownloadOptions): Promise<string> {
        try {
            const fileName = await FileOperations.download(url, options);
            return fileName;
        } catch (err) {
            log.error('File download failed : ' + (err as Error).message);
            const errorMessage = getErrorMessageOnFileDownloadFromOC(this._messageService, err as Error);
            if (errorMessage) {
                this._eventService.publish({
                    name: EventType.TOAST,
                    toast: {
                        toastMessage: errorMessage,
                        type: ToastType.ERROR,
                    },
                });
            }
            return Locale.responseText.failures.download_document;
        }
    }

    public async compareFilePaths(documentFileName: string, activeFileName: string): Promise<boolean> {
        if (documentFileName && activeFileName) {
            const comparisonResponse = await FileOperations.compareFilePaths(documentFileName, activeFileName);
            return comparisonResponse.equal;
        } else {
            return false;
        }
    }

    public async saveAsInTempStorage(isUpload?: boolean): Promise<string> {
        await this.initialize();
        return this.appService ? await this.appService.saveAsInTempStorage(isUpload) : '';
    }

    public async isBusy(): Promise<boolean> {
        await this.initialize();
        return this.appService ? await this.appService.isBusy() : false;
    }

    public async persistToStorage(parts: string[], path: string): Promise<string> {
        try {
            const persistedPath = await FileOperations.persistToStorage({ path }, parts);
            return persistedPath.filePath;
        } catch (err) {
            log.info('File is already stored');
        }
        return path;
    }

    public async activateWindow(uniqueId: string): Promise<void> {
        await this.initialize();
        await this.appService?.activateWindow(uniqueId);
    }

    public async checkPathToActivate(pathInfo: string): Promise<void> {
        const isActiveDocument = await this.getActiveDocumentSelection();
        if (isActiveDocument) {
            const currentPath = await this.getCurrentDocumentFullName();
            try {
                const docInfo = JSON.parse(pathInfo);
                if (docInfo && currentPath == docInfo.path) {
                    await this.activateWindow(docInfo.uniqueId);
                }
            } catch (ex) {
                log.warn('OC | Path info parsing error ' + ex);
            }
        }
    }

    public async getFileNameFromMeta(uniqueId: string): Promise<string> {
        const { fileName } = await FileOperations.getMetadata(uniqueId);
        return fileName;
    }

    public showEmailOrDocumentUploadToast(folderName: string, capability: string): void {
        const DOCUMENTS = CHSupportedDocumentTypesEnum.DOCUMENT + 's';
        const EMAILS = CHSupportedDocumentTypesEnum.EMAIL + 's';
        if (folderName && folderName !== DOCUMENTS && capability != CapabiltyEnum.ADD_EMAIL) {
            const infoMessage = Locale.documents.upload_documents_into_email;
            this._eventService.publish({
                name: EventType.TOAST,
                toast: {
                    toastMessage: infoMessage,
                    type: ToastType.INFORMATION,
                },
            });
        }

        if (folderName && folderName !== EMAILS && capability === CapabiltyEnum.ADD_EMAIL) {
            const infoMessage = Locale.documents.upload_emails_into_documents;
            this._eventService.publish({
                name: EventType.TOAST,
                toast: {
                    toastMessage: infoMessage,
                    type: ToastType.INFORMATION,
                },
            });
        }
    }
}
