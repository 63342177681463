import { Tooltip } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { ICHUploadResponse } from '@wk/elm-uui-context-handler';
import React, { InputHTMLAttributes, useEffect, useMemo, useState } from 'react';
import useCancellablePromise from '../../hooks/useCancellablePromise';
import useIsMounted from '../../hooks/useIsMounted';
import { useReduxDispatch } from '../../hooks/useReduxDispatch';
import { useReduxSelector } from '../../hooks/useReduxSelector';
import { useUUIHistory } from '../../hooks/useUUIHistory';
import { useUUILocation } from '../../hooks/useUUILocation';
import FileDragAndDrop from '../common/fileDragAndDrop';
import { getCLQuickFileVisibility } from '../common/screenHelpers';
import { ScreenMode } from '../common/types';
import {
    handleContextLayerPerformFileDragAndDrop,
    handleContextLayerPerformQuickFile,
    IHandleContextLayerPerformQuickFile,
} from '../contextLayerService/contextLayerHelpers';
import { isOC } from '../contextLayerService/contextLayerService';
import WkAppFileIcon from '../icons/wkAppFileIcon';
import WkEmailIcon from '../icons/wkEmailIcon';
import WkFolderIcon from '../icons/wkFolderIcon';
import WkNewQuickFileIcon from '../icons/wkNewQuickFileIcon';
import WkSpinnerIcon from '../icons/wkSpinnerIcon';
import { useParentItemScreenDispatch } from '../itemScreen/context/itemScreenContext';
import { dispatchLoadFolder } from './context/listScreenAsyncActions';
import { useListScreenDispatch, useListScreenState } from './context/listScreenContext';
import { IResponsiveBreadCrumb, useResponsiveBreadCrumbs } from './context/listScreenHooks';
import ListItemMoreMenu from './listItemMoreMenu';
import ListScreenField from './listScreenField';
import {
    getBreadCrumbsForRow,
    getBulkOperations,
    getCellDisplayValue,
    getIsListRowBreadCrumbVisible,
    getListItemActionClassName,
    isDocumentRow,
    isFolderRow,
} from './listScreenHelpers';
import { getColumnItems } from './listViewHelpers';
// eslint-disable-next-line css-modules/no-unused-class
import css from './listViewItem.module.scss';
import ListViewItemTextRow from './listViewItemTextRow';
import { IListScreenColumn, IListScreenRow, LeftColumnWidth } from './types';

export interface IListViewItemProps {
    row: IListScreenRow;
    columns: IListScreenColumn[];
    mode?: ScreenMode;
    isChecked: boolean;
    onCheck: (id: number) => void;
    leftColumnWidth: LeftColumnWidth;
}

const ListViewItem: React.FC<IListViewItemProps> = React.memo(({ row, columns, mode, isChecked, onCheck }) => {
    const [isQuickFileEnabled, setIsQuickFileEnabled] = useState(false);
    const listScreenState = useListScreenState();
    const listScreenDispatch = useListScreenDispatch();
    const parentItemScreenDispatch = useParentItemScreenDispatch();
    const cancellablePromise = useCancellablePromise<ICHUploadResponse | ICHUploadResponse[]>({
        trackPromise: false,
    });
    const metadata = listScreenState.metadata!;
    const appResources = useReduxSelector((state) => state.appResources);
    const reduxDispatch = useReduxDispatch();
    const isFolderListItem = isFolderRow(metadata, row);
    const bulkOperations = getBulkOperations(metadata, listScreenState.mode);
    const location = useUUILocation();
    const history = useUUIHistory();
    const isMounted = useIsMounted();

    useEffect(() => {
        getCLQuickFileVisibility(
            isFolderListItem,
            row._metadata?.enableQFDocuments || false,
            row._metadata?.enableQFEmails || false,
        )?.then((qfInd) => {
            if (!isMounted.current) {
                return;
            }
            setIsQuickFileEnabled(qfInd?.enableQuickFile ? qfInd.enableQuickFile : false);
            if (qfInd?.enableQuickFile) {
                listScreenDispatch({
                    type: 'QuickFileVisibleForRows',
                    quickFileVisibleForRows: qfInd.enableQuickFile,
                });
            }
        });
    }, [
        isFolderListItem,
        row._metadata?.enableQFDocuments,
        row._metadata?.enableQFEmails,
        listScreenDispatch,
        isMounted,
    ]);

    const topLeftText = getCellDisplayValue(row, columns[0]);
    const topLeftIcon = () => {
        if (topLeftText.length > 0 && isDocumentRow(metadata, row)) {
            const fileName = row._metadata.docOrFolderName;
            if (row['senderEmailAddress'] || row['senderName'] || row['sentTime']) {
                return <WkEmailIcon style={{ fill: '#007ac3' }} />;
            } else {
                return <WkAppFileIcon fileName={fileName} />;
            }
        } else {
            return undefined;
        }
    };
    const arrColumns = getColumnItems(columns);

    const isDisabled = listScreenState.disabledRows.indexOf(row.id) != -1;

    const labelId = `checkbox-list-label-${row.id}`;

    const nameColumn = columns.find((c) => c.name === 'name')!;

    const CheckBox = () => (
        <ListItemIcon
            classes={{
                root:
                    getIsListRowBreadCrumbVisible(listScreenState) && !isFolderListItem
                        ? css.checkboxContainerWithBreadCrumb
                        : css.checkboxContainer,
            }}>
            <Checkbox
                color="primary"
                edge="start"
                checked={isChecked}
                tabIndex={-1}
                disableRipple
                inputProps={
                    {
                        'aria-label': labelId,
                        'data-testid': `listItemCheckbox_${row.id}`,
                    } as InputHTMLAttributes<HTMLInputElement>
                }
            />
        </ListItemIcon>
    );

    // check the row metadata first, and if not there, it's a main level matter list, so get info from metadata
    const generateQuickFileArg = (): IHandleContextLayerPerformQuickFile => ({
        associatedEntityType: row._metadata.associatedEntityType || metadata.entityName,
        associatedEntityId: row._metadata.associatedEntityId?.toString() || row.id.toString(),
        cancellablePromise,
        // entityTypeId should be undefined when not on a document list (ex:a matter list)
        entityTypeId: metadata.isDocumentEntity ? metadata.entityId.toString() : undefined,
        folderId: isFolderListItem ? row.id.toString() : undefined,
        associatedEntityTypeId: row._metadata.associatedEntityTypeId?.toString() || metadata.entityId.toString(),
        loggedInUser: appResources.username,
        associatedEntityName: row._metadata.associatedEntityName || row._metadata.objDisplayValue,
        folderArr: getBreadCrumbsForRow(listScreenState, row),
        listScreenDispatch,
        rowId: row.id,
    });

    const clickQuickFile = () => {
        listScreenDispatch({ type: 'DisableRow', id: row.id });
        handleContextLayerPerformQuickFile(generateQuickFileArg());
    };

    const actionFileDropHandler = (filePaths: []) => {
        handleContextLayerPerformFileDragAndDrop({ ...generateQuickFileArg(), filePaths });
    };

    const isDropZoneEnabled = () => {
        return isOC() && mode !== 'edit' && mode !== 'add' && (row._metadata?.enableFileDropzone || false);
    };

    return (
        <div data-testid="listViewItem-container" className={css.listItemContainer}>
            <FileDragAndDrop
                actionFileDropHandler={actionFileDropHandler}
                isFileDropZoneEnabled={isDropZoneEnabled()}
                isTwoLinesLabel={!isFolderListItem}
                areaLabel={isFolderListItem ? getCellDisplayValue(row, nameColumn) : topLeftText}>
                {isDisabled && (
                    <div className={css.enableProgress}>
                        <WkSpinnerIcon data-testid="listViewItem-spinner" className={css.spinnerIcon} />
                    </div>
                )}
                <ListItem
                    button
                    disabled={isDisabled}
                    classes={{
                        root: isChecked
                            ? `${css.checkedRow} ${css.listItemRoot}`
                            : bulkOperations.length < 1 && !isFolderListItem
                            ? `${css.listItemRoot} ${css.noBulkOpsRow}`
                            : css.listItemRoot,
                        dense: css.folderRow,
                        secondaryAction: getListItemActionClassName(listScreenState),
                    }}
                    onClick={(e) => {
                        if ((e.target as HTMLInputElement).type === 'checkbox') {
                            onCheck(row.id);
                        } else {
                            isFolderListItem
                                ? dispatchLoadFolder(
                                      row,
                                      columns,
                                      listScreenDispatch,
                                      listScreenState,
                                      parentItemScreenDispatch,
                                      reduxDispatch,
                                      location,
                                      history,
                                  )
                                : bulkOperations.length > 0 && onCheck(row.id);
                        }
                    }}
                    ContainerComponent="div"
                    dense={isFolderListItem}
                    disableRipple={isFolderListItem || bulkOperations.length < 1}>
                    {isFolderListItem ? (
                        <ListItemText
                            className={css.folderRowText}
                            primary={
                                <React.Fragment>
                                    {bulkOperations.length > 0 && <CheckBox />}
                                    <ListViewItemTextRow
                                        rowIndex={0}
                                        leftComponent={
                                            <ListScreenField
                                                folderIcon={
                                                    <WkFolderIcon color="secondary" style={{ marginTop: '1px' }} />
                                                }
                                                row={row}
                                                column={nameColumn}
                                            />
                                        }
                                        leftText={getCellDisplayValue(row, nameColumn)}
                                        rightText=""
                                        leftColumnWidth="100%"
                                    />
                                </React.Fragment>
                            }
                        />
                    ) : (
                        <ListItemText
                            className={css.listItemTextRoot}
                            primary={
                                <React.Fragment>
                                    {bulkOperations.length > 0 && <CheckBox />}
                                    {getIsListRowBreadCrumbVisible(listScreenState) && (
                                        <ListViewItemBreadCrumb row={row} />
                                    )}
                                    {arrColumns.map((column: any, index: number) => {
                                        const leftColumnText = getCellDisplayValue(row, column[0]);
                                        const rightColumnText = column[1] ? getCellDisplayValue(row, column[1]) : '';

                                        if (index > 2) {
                                            return;
                                        }

                                        return (
                                            <React.Fragment key={index}>
                                                <ListViewItemTextRow
                                                    rowIndex={index}
                                                    leftText={leftColumnText}
                                                    leftComponent={
                                                        leftColumnText.length > 0 ? (
                                                            <ListScreenField
                                                                row={row}
                                                                column={column[0]}
                                                                mode={mode}
                                                                fileIcon={index === 0 ? topLeftIcon() : undefined}
                                                            />
                                                        ) : undefined
                                                    }
                                                    rightText={rightColumnText}
                                                    rightComponent={
                                                        column[1] && rightColumnText.length > 0 ? (
                                                            <ListScreenField row={row} column={column[1]} mode={mode} />
                                                        ) : undefined
                                                    }
                                                    leftColumnWidth={index === 0 ? '100%' : '50%'}
                                                />
                                            </React.Fragment>
                                        );
                                    })}
                                </React.Fragment>
                            }
                        />
                    )}
                    <ListItemSecondaryAction className={isDisabled ? css.disableIconContainer : css.iconContainer}>
                        {isQuickFileEnabled && (
                            <Tooltip
                                enterDelay={500}
                                placement="bottom"
                                title={appResources.listscreenQuickFileToolTip}
                                classes={{
                                    tooltip: css.tooltip,
                                }}>
                                <IconButton
                                    data-testid={'listscreen-quickFile-' + row.id}
                                    edge="end"
                                    aria-label="details"
                                    color="primary"
                                    className={css.fileIconContainer}
                                    onClick={clickQuickFile}>
                                    <WkNewQuickFileIcon className={css.quickFileIcon} />
                                </IconButton>
                            </Tooltip>
                        )}
                        <ListItemMoreMenu row={row} />
                    </ListItemSecondaryAction>
                </ListItem>
                <Divider />
            </FileDragAndDrop>
        </div>
    );
});
ListViewItem.displayName = 'ListViewItem';

interface IListViewItemBreadCrumb {
    row: IListScreenRow;
}

const ListViewItemBreadCrumb: React.FC<IListViewItemBreadCrumb> = ({ row }) => {
    const listScreenState = useListScreenState();
    // get the breadcrumbs with stuff chopped off beginning
    const displayedBreadCrumbs = useMemo((): IResponsiveBreadCrumb[] => {
        if (!row._metadata.folderPath) {
            return [];
        }
        const breadCrumbs = listScreenState.breadCrumbs.map((b) => b.folderName);
        let startingIndex = 0;
        for (let i = 0; i < row._metadata.folderPath!.length; i++) {
            if (row._metadata.folderPath![i] === breadCrumbs[i]) {
                startingIndex++;
            } else {
                break;
            }
        }
        // substract 1 from the startingIndex to retain the current level folder name
        startingIndex = startingIndex >= 1 ? startingIndex - 1 : startingIndex;
        return row._metadata.folderPath!.slice(startingIndex).map((fp) => ({ folderName: fp }));
    }, [listScreenState.breadCrumbs, row._metadata.folderPath]);

    const breadCrumbList = useResponsiveBreadCrumbs(displayedBreadCrumbs, { fontSize: '12px' });
    if (!row._metadata.folderPath) {
        return null;
    }

    const breadCrumbText = breadCrumbList.map((bc) => bc.folderName).join(' > ');
    return (
        <Tooltip
            placement="top-start"
            title={
                breadCrumbText.startsWith('...') ? displayedBreadCrumbs.map((dbc) => dbc.folderName).join(' > ') : ''
            }
            classes={{
                tooltip: css.tooltip,
            }}>
            <div className={css.breadcrumb} data-testid="listViewItem-BreadCrumb">
                {breadCrumbText}
            </div>
        </Tooltip>
    );
};

export default ListViewItem;
