import { IDocTransformer } from '../../interfaces/transformer/type';
import { injectable } from 'inversify';
import { factory } from '../../ConfigLog4j';
import { IMatterDoc, ICheckIn } from '../../types';
import { T360AdapterCheckInActionableEntity } from '../actionableEntity/checkInActionableEntity';
import { IDocOperationInvocation } from '../../interfaces/urlPattern/types';
import { ICheckIn_T360 } from '../types';
import { removeDashes } from '../../utils/string.utils';

const log = factory.getLogger('T360AdapterCheckInEntityTransformer');

@injectable()
export class T360AdapterCheckInEntityTransformer implements IDocTransformer {
    transformIntoActionableEntity(
        nodeObj: ICheckIn,
        _matterDoc: IMatterDoc,
        _urlPattern: IDocOperationInvocation,
    ): T360AdapterCheckInActionableEntity {
        log.debug('Calling transformIntoActionableEntity');

        const nodeObj1 = nodeObj as ICheckIn_T360;
        let obj = {} as T360AdapterCheckInActionableEntity;
        // TO CREATE ACTIONABLE ENTITY FROM nodeObj and matterDoc

        if (nodeObj1?.extendedProps) {
            obj = nodeObj1.extendedProps as T360AdapterCheckInActionableEntity;
        }

        if (nodeObj1.existingDocumentName) {
            obj.documentName = nodeObj1.existingDocumentName;
        }

        if (nodeObj1.documentType) {
            obj.documentType = nodeObj1.documentType;
        }

        if (nodeObj1.file) {
            obj.file = nodeObj1.file;
        }
        if (nodeObj1.docId) {
            obj.documentId = nodeObj1.docId;
        }

        if (obj.file && obj.file instanceof Buffer) {
            obj.contentType = 'text/plain';
        } else if (obj.file) {
            obj.contentType = obj.file.type;
        }

        if (nodeObj1.extendedProps?.comment) {
            obj.comment = nodeObj1.extendedProps?.comment;
        }

        if (nodeObj?.extendedProps?.uploadGuid) {
            obj.uploadGuid = removeDashes(nodeObj.extendedProps.uploadGuid);
        }

        return obj;
    }
}
