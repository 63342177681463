import {
    ComError,
    ComErrorCode,
    ComErrorScope,
    ComObject,
    RequestType,
    ValueHolder,
    ValueHolderType,
    ValueType,
} from '../comObject';
import { OfficeContextScope } from './officeContextScope';
import { ClientObject } from './clientObject';
import { NotLoadedException } from '../exceptions/notLoaded.exception';
import { Observable, tap } from 'rxjs';

export interface RequestObjectParams<T extends ClientObject, V extends ValueHolderType = ValueHolderType> {
    // name the result value will be stored in current object. Keep empty if don't nee to store value
    cacheName?: string;
    // class the requestObject function should return as result
    creator: ObjectCreatorType<T>;
    // comObject request type. can be: getProperty | setProperty | invoke
    type: RequestType;
    // comObject property name or method name
    name: string;
    // arguments passed to comObject in request
    args?: ValueHolder<V>[];
    // indicated that returned object can be releasable (true by default)
    isReleasable?: boolean;
    // must be set if invocation target does not exists in the com object itself, but provided by registered api extension
    extension?: boolean;
}

export interface RequestParams<V extends ValueHolderType> {
    // name the result value will be stored in current object. Keep empty if don't nee to store value
    cacheName?: string;
    // comObject request type. can be: getProperty | setProperty | invoke
    type: RequestType;
    // comObject property name or method name
    name: string;
    // arguments passed to comObject in request
    args?: ValueHolder<V>[];
    // must be set if invocation target does not exists in the com object itself, but provided by registered api extension
    extension?: boolean;
}

type ComObjectRequest = {
    [key in RequestType]: <T, V>(
        name: string,
        args: ValueHolder<V>[] | undefined,
        extension: boolean,
    ) => Observable<ValueHolder<T>>;
};

export type ObjectCreatorType<T extends ClientObject> = new (reference: ReferenceObject) => T;

export class ReferenceObject {
    private values = new Map<string, ValueHolder<ValueHolderType | ClientObject>>();
    private comObject: ComObject;
    private resolved = false;

    constructor(protected scope: OfficeContextScope) {}

    public get isNull(): boolean {
        return !this.comObject;
    }

    public get isResolved(): boolean {
        return this.resolved;
    }

    public addComObject(comObject: ComObject): void {
        this.resolved = true;

        if (comObject) {
            this.comObject = comObject;
        }
    }

    public getValue<T extends ValueHolderType = ValueHolderType>(cacheName: string): ValueHolder<T> {
        if (!this.values.has(cacheName)) {
            throw new NotLoadedException(cacheName);
        }

        return this.values.get(cacheName) as ValueHolder<T>;
    }

    private setValue<T extends ValueHolderType | ClientObject>(
        cacheName: string | undefined,
        value: ValueHolder<T>,
    ): void {
        if (cacheName) {
            this.values.set(cacheName, value);
        }
    }

    public request<T extends ValueHolderType, V extends ValueHolderType = ValueHolderType>({
        type,
        cacheName,
        name,
        args,
        extension = false,
    }: RequestParams<V>): void {
        if (type === RequestType.SetProperty && !args?.[0]) {
            throw new Error('argument should exist when setProperty is called');
        }

        if (type === RequestType.SetProperty && cacheName) {
            this.setValue(cacheName, args?.[0] as ValueHolder<V>);
        }

        const request = (): Observable<ValueHolder> =>
            this.getRequest<T>(type, name, args, extension).pipe(
                tap((valueHolder) => {
                    if (cacheName) {
                        this.setValue(cacheName, valueHolder);
                    }
                }),
            );

        this.scope.addAction({ request });
    }

    public requestObject<T extends ClientObject, V extends ValueHolderType = ValueHolderType>({
        type,
        cacheName,
        creator: ObjectCreator,
        name,
        args,
        isReleasable = true,
        extension = false,
    }: RequestObjectParams<T, V>): ValueHolder<T> {
        if (cacheName && this.values.has(cacheName)) {
            return this.values.get(cacheName) as ValueHolder<T>;
        }

        const reference = new ReferenceObject(this.scope);
        const object = new ObjectCreator(reference);
        const holder: ValueHolder<T> = { value: object, type: ValueType.ObjectRef };

        if (cacheName) {
            this.values.set(cacheName, holder);
        }

        const request = (): Observable<ValueHolder<ComObject>> =>
            this.getRequest<ComObject>(type, name, args, extension, isReleasable).pipe(
                tap(({ value }) => {
                    reference.addComObject(value);
                }),
            );

        this.scope.addAction({ request });

        return holder;
    }

    private comObjectRequest: ComObjectRequest = {
        [RequestType.GetProperty]: <T, V>(name: string, args: ValueHolder<V>[] | undefined, extension: boolean) =>
            this.comObject.getProperty<T, V>(name, args?.[0], extension),
        [RequestType.SetProperty]: <T, V>(name: string, args: ValueHolder<V>[] | undefined, extension: boolean) => {
            if (!args?.[0]) {
                throw new Error('argument should exist when setProperty is called');
            }
            return this.comObject.setProperty<T, V>(name, args?.[0], extension);
        },
        [RequestType.Invoke]: <T, V>(name: string, args: ValueHolder<V>[] = [], extension: boolean) =>
            this.comObject.invoke<T, V>(name, extension, ...args),
    };

    private getRequest<T, V = ValueHolderType>(
        requestType: RequestType,
        name: string,
        args: ValueHolder<V>[] | undefined,
        extension: boolean,
        isReleasable = true,
    ): Observable<ValueHolder<T>> {
        if (!this.resolved) {
            throw new ComError({
                status: ComErrorCode.NotResolvedReferenceObject,
                message: 'Reference Object should be resolved first to do any chain calls',
                scope: ComErrorScope.ComReference,
            });
        }

        if (!this.comObject) {
            throw new ComError({
                status: ComErrorCode.NotComObject,
                message: 'COM Object should be initialized first in referenceObject to make chain calls',
                scope: ComErrorScope.ComReference,
            });
        }

        return this.comObjectRequest[requestType]<T, V>(name, args, extension).pipe(
            tap((valueHolder) => {
                this.initReferenceValue(valueHolder, isReleasable);
            }),
        );
    }

    private initReferenceValue<T extends ValueHolderType>(valueHolder: ValueHolder<T>, isReleasable = true): void {
        const { type, value } = valueHolder;
        if (type === ValueType.ObjectRef && value instanceof ComObject) {
            this.scope.addReference(value, isReleasable);
        }
    }
}
