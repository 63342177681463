import { Container } from 'inversify';
import { commonContainer } from './common/common.module';
import { interfaces } from 'inversify/dts/interfaces/interfaces';
import { browserContainer } from './modules/browser/browser.module';
import { BootstrapService } from './modules/browser/bootstrap.service';
import { messagingContainer } from './modules/messaging/messaging.module';
import { browserUtilitiesService } from '@wk/office-companion-js-common';
import { settingsContainer } from './modules/settings/settings.module';
import { profileContainer } from './modules/profile/profile.module';

const containers = [commonContainer, browserContainer, messagingContainer, settingsContainer, profileContainer];

const mainContainer: interfaces.Container = containers.reduce(
    (container, main) => Container.merge(main, container),
    new Container(),
);

if (browserUtilitiesService.isElectron()) {
    void mainContainer.get(BootstrapService).bootstrap();
}

export { mainContainer };
export { browserUtilitiesService, ApplicationType } from '@wk/office-companion-js-common';
