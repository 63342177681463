import { ClientObject, ClientObjectProperty } from '../common/clientObject';
import { RequestType, ValueHolder, ValueType } from '../comObject';
import { Window } from './window';

export class Application extends ClientObject {
    private properties = this.clientObjectInitPropsInfo({
        visible: { propertyName: 'visible', name: 'Visible' },
    });

    public getPropertyInitializers(): Map<keyof Application, () => void> {
        return new Map<keyof Application, () => void>(
            Object.values(this.properties).map(({ propertyName, name }) => [
                propertyName,
                (): void =>
                    this.reference.request({
                        type: RequestType.GetProperty,
                        cacheName: propertyName,
                        name,
                    }),
            ]),
        );
    }

    private setProperty<T>(property: ClientObjectProperty<Application>, valueHolder: ValueHolder<T>): void {
        const { propertyName, name } = property;

        this.reference.request({
            type: RequestType.SetProperty,
            cacheName: propertyName,
            name,
            args: [valueHolder],
        });
    }

    public set visible(value: boolean) {
        this.setProperty(this.properties.visible, { type: ValueType.Bool, value });
    }

    public get visible(): boolean {
        return this.reference.getValue<boolean>(this.properties.visible.propertyName).value;
    }

    public get activeWindow(): Window {
        return this.reference.requestObject({
            type: RequestType.GetProperty,
            creator: Window,
            name: 'ActiveWindow',
        }).value;
    }

    public activate(): void {
        return this.reference.request({
            type: RequestType.Invoke,
            name: 'Activate',
        });
    }
}
