import { commonContainer } from '../../common/common.module';
import { Container, interfaces } from 'inversify';
import { ApplicationInterface, ApplicationService } from './application.service';
import { AppVersionService } from './appVersion.service';
import { AppStorageService } from './appStorage.service';
import { AppSettingsService } from './appSettings.service';

const importedContainers = [commonContainer];

const applicationContainer: interfaces.Container = importedContainers.reduce(
    (container, imports) => Container.merge(imports, container),
    new Container(),
);

applicationContainer.bind(AppVersionService).toSelf().inSingletonScope();
applicationContainer.bind(AppStorageService).toSelf().inSingletonScope();
applicationContainer.bind(AppSettingsService).toSelf().inSingletonScope();
applicationContainer.bind<ApplicationInterface>(ApplicationService).toSelf().inSingletonScope();

export { applicationContainer };
