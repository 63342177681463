import { injectable } from 'inversify';
import { DOCID_ERROR } from '../constants';
import { DCValidationError } from '../customError/docComponentError';
// import { DCValidationError } from '../customError/docComponentError';
import { IInputValidator } from '../interfaces/inputValidators/type';
import { IDocServiceRequest } from '../types';

interface IDocServiceContainingDocId extends IDocServiceRequest {
    docId: string;
}

@injectable()
export class DocInputValidator implements IInputValidator {
    performValidation = (nodeObj: IDocServiceContainingDocId): boolean => {
        // Condition can be added to fail the operation
        // if not received needed inputs

        if (nodeObj?.docId) {
            return true;
        } else {
            throw new DCValidationError(DOCID_ERROR);
        }
    };
}
