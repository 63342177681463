import { useContext, useMemo } from 'react';
import { AppContext } from '../components/app';
import { useReduxSelector } from './useReduxSelector';

export const useNetwork = () => {
    const networks = useReduxSelector((state) => state.mappedNetworks);
    const { network: networkContext } = useContext(AppContext);

    const currentNetwork = useMemo(() => {
        return networks?.find(({ isCurrent }) => isCurrent);
    }, [networks]);

    return {
        ...networkContext,
        networks,
        currentNetwork,
    };
};
