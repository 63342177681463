import { useRef, useEffect } from 'react';
import { manuallyDecrementPromiseCounter, manuallyIncrementPromiseCounter } from 'react-promise-tracker';
import { CancellablePromise } from '../components/common/types';

export interface IUseCancellablePromiseOptions {
    trackPromise?: boolean;
}

const makeCancelable = <T>(promise: Promise<T>, opts?: IUseCancellablePromiseOptions): CancellablePromise<T> => {
    let isCanceled = false;

    const wrappedPromise = new Promise<T>((resolve) => {
        // track the promise unless they explicity say not to.
        const dontTrackPromise = opts && opts.trackPromise === false;
        if (!dontTrackPromise) {
            manuallyIncrementPromiseCounter();
        }
        promise.then((val) => {
            if (!isCanceled) {
                if (!dontTrackPromise) {
                    manuallyDecrementPromiseCounter();
                }
                resolve(val);
            }
        });
    });

    return {
        promise: wrappedPromise,
        cancel: () => {
            manuallyDecrementPromiseCounter();
            isCanceled = true;
        },
    };
};

const useCancellablePromise = <T>(opts?: IUseCancellablePromiseOptions): ((p: Promise<T>) => Promise<T>) => {
    const promises = useRef<CancellablePromise<T>[]>([]);

    useEffect(() => {
        promises.current = promises.current || [];
        return () => {
            promises.current.forEach((p) => p.cancel());
            promises.current = [];
        };
    }, []);

    const cancellablePromise = (p: Promise<T>): Promise<T> => {
        const cPromise = makeCancelable(p, opts);
        promises.current.push(cPromise);
        return cPromise.promise;
    };

    return cancellablePromise;
};

export default useCancellablePromise;
