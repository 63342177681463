import { Profile, ProfileUrlSettings, DefaultUrlSettingType } from '@wk/office-companion-js';
import { injectable } from 'inversify';
import 'reflect-metadata';
import { Observable } from 'rxjs';
import { factory } from '../configLog4J';

const log = factory.getLogger('RouterService');

@injectable()
export class RouterService {
    public setDefaultUrl(url?: string): Observable<void> {
        url
            ? log.info('Default Url for the active profile is set')
            : log.info('Default Url for the active profile has been removed');

        const type = url ? DefaultUrlSettingType.ExactUrl : DefaultUrlSettingType.None;
        const settings: ProfileUrlSettings = url ? { type, url } : { type };
        return Profile.setDefaultUrl(settings);
    }

    public reloadUrl(): Observable<void> {
        log.info('The active profile url has been reloaded');
        return Profile.reload();
    }
}
