export enum SaveFormat {
    Document = 0,
    Template = 1,
    Text = 2,
    TextLineBreaks = 3,
    DOSText = 4,
    DOSTextLineBreaks = 5,
    RTF = 6,
    EncodedText = 7,
    HTML = 8,
    WebArchive = 9,
    FilteredHTML = 10,
    XML = 11,
    XMLDocument = 12,
    XMLDocumentMacroEnabled = 13,
    XMLTemplate = 14,
    XMLTemplateMacroEnabled = 15,
    DocumentDefault = 16,
    PDF = 17,
    XPS = 18,
    FlatXML = 19,
    FlatXMLMacroEnabled = 20,
    FlatXMLTemplate = 21,
    FlatXMLTemplateMacroEnabled = 22,
    OpenDocumentText = 23,
    StrictOpenXMLDocument = 24,
}
