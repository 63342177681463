import React from 'react';
import { CircularProgress } from '@material-ui/core';
import css from './blockUi.module.scss';
import { usePromiseTracker } from 'react-promise-tracker';

export const BlockUi: React.FC = () => {
    const { promiseInProgress } = usePromiseTracker();

    if (!promiseInProgress) {
        return null;
    }

    return (
        <>
            <div className={css.blockUiContainer} aria-hidden="true"></div>
            <div className={css.blockUiMessageContainer}>
                <CircularProgress data-testid="fullProgressBar" aria-label="progressBar" size={60} />
            </div>
        </>
    );
};
