import { IQueueServiceInterface } from '@wk/elm-uui-queuemanager';
import { getQueue } from '../capability';
import { injectable } from 'inversify';
import { factory } from '../configLog4J';
import { EventType } from '../enum/enum';
import { throwUnExpectedError } from '../utils/main.utils';

const log = factory.getLogger('ConnectionService');

@injectable()
export class ConnectionService {
    queueManagerObj!: IQueueServiceInterface;
    public connectionServiceEventHandler(eventType: EventType): void {
        if (eventType === EventType.NETWORK_DOWN) {
            log.debug(' Connection failure | Network down');
            this.pauseQueue();
        } else if (eventType === EventType.NETWORK_RESTORED) {
            log.debug(' Connection failure | Network restored');

            this.resumeQueue();
        }
    }

    private async pauseQueue(): Promise<void> {
        try {
            (await getQueue()).setQueuePaused();
        } catch (err) {
            log.debug('Error in getting Queue Manager ' + err);
            throwUnExpectedError(err);
        }
    }

    private async resumeQueue(): Promise<void> {
        try {
            (await getQueue()).setQueueResumed();
        } catch (err) {
            log.debug('Error in getting Queue Manager ' + err);
            throwUnExpectedError(err);
        }
    }
}
