import { ReferenceObject } from './referenceObject';

export interface PropertyInit<T extends ClientObject = ClientObject> {
    getPropertyInitializers(): Map<keyof (this & T), () => void>;
}

export interface ClientObjectProperty<T extends ClientObject> {
    propertyName: keyof T;
    name: string;
    extension?: boolean;
}

interface ClientObjectPropertiesMap<T extends ClientObject> {
    [key: string]: ClientObjectProperty<T>;
}

export abstract class ClientObject {
    constructor(protected reference: ReferenceObject) {}

    protected clientObjectInitPropsInfo<M extends ClientObjectPropertiesMap<this>>(props: M): M {
        return props;
    }

    public get isNull(): boolean {
        return this.reference.isNull;
    }

    public get isResolved(): boolean {
        return this.reference.isResolved;
    }
}
