import { ToastType } from './../enum/enum';
import { Locale } from '../locale';

export const Toast = {
    document: {
        delete: {
            type: ToastType.SUCCESS,
            toastMessage: 'Document was deleted successfully.',
        },
        checkIn: {
            type: ToastType.SUCCESS,
            toastMessage: 'Check In was successful.',
        },
        uploadDocument: {
            type: ToastType.SUCCESS,
            toastMessage: 'Upload was successful.',
        },
        completeDialogAction: {
            type: ToastType.SUCCESS,
            toastMessage: `Document operation is completed for the Matter `,
        },
    },
    email: {
        not_valid_email: {
            type: ToastType.ERROR,
            toastMessage: Locale.email.failure.invalid,
        },
        draft_email: {
            type: ToastType.ERROR,
            toastMessage: Locale.email.failure.draft,
        },
        add_email: {
            type: ToastType.SUCCESS,
            toastMessage: Locale.email.success.add_email,
        },
        add_email_t360: {
            type: ToastType.SUCCESS,
            toastMessage: Locale.email.success.add_email_t360,
        },
    },
};
