import { IWindowProps } from '../t360Adapter/types';
import { FETCH_ERROR } from '../constants';
import { UnExpectedError } from '../types';
import { factory } from '../ConfigLog4j';

const log = factory.getLogger('shared.utils');

export const UnExpectedResponseCodes = [403, 401, 500, 503];

export function isNodeEnvironment(): boolean {
    if (typeof process !== 'undefined') {
        if ('release' in process) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const isT360 = (): boolean => {
    const windProps = (window as unknown as IWindowProps).Props;
    if (windProps && windProps?.userManager) {
        return true;
    }
    return false;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export function isEmpty(obj: Object): boolean {
    return Object.keys(obj).length === 0;
}

export const handleUnExpectedFetchError = (err: Error): void => {
    log.error('Error to get reponse' + err);
    if (err.message == FETCH_ERROR) {
        throw new UnExpectedError(err.message);
    }
};

// return an array of values that match on a certain key in the JSON object sent
// eslint-disable-next-line @typescript-eslint/ban-types
export const getValues = (obj: Object, key: string): Record<string, string> => {
    let objects = {};
    for (const i in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (!obj.hasOwnProperty(i)) {
            continue;
        }
        if (typeof obj[i] == 'object') {
            objects = Object.assign(objects, getValues(obj[i], key));
        } else if (i == key) {
            if ('name' in obj) {
                objects[obj['name']] = obj[i];
            } else {
                objects['default'] = obj[i];
            }
        }
    }
    return objects;
};
