import React, { useEffect } from 'react';
import { Typography, Select, MenuItem } from '@material-ui/core';
import css from './networkSwitcher.module.scss';
import { useUserScreenState } from './context/userScreenContext';
import { useReduxSelector } from '../../hooks/useReduxSelector';
import { INetwork } from '../../reducers/types';

const NetworkSwitcher: React.FC = () => {
    const appResources = useReduxSelector((state) => state.appResources);
    const networks = useReduxSelector((state) => state.mappedNetworks);
    const currentNetwork = networks?.find((network) => network.isCurrent);
    const [userScreenState, userScreenDispatch] = useUserScreenState();

    useEffect(() => {
        if (userScreenState.selectedNetwork === 0 && currentNetwork) {
            userScreenDispatch({ type: 'SetSelectedNetwork', selectedNetwork: currentNetwork.networkId });
        }
    }, [currentNetwork, userScreenDispatch, userScreenState.selectedNetwork]);

    const getSelectList = (networksList: INetwork[]) => (
        <Select
            value={userScreenState.selectedNetwork === 0 ? '' : userScreenState.selectedNetwork}
            className={css.networkSwitchSelect}
            data-testid="network-switch-select"
            onChange={(e: React.ChangeEvent<{ value: any }>) => {
                userScreenDispatch({ type: 'SetSelectedNetwork', selectedNetwork: e.target.value });
            }}>
            {networksList.map((network: INetwork) => {
                return (
                    <MenuItem
                        key={network.networkId}
                        dense
                        value={network.networkId}
                        data-testid={'network-switch-select-item-' + network.networkId}>
                        {network.networkName}
                    </MenuItem>
                );
            })}
        </Select>
    );

    if (!networks || networks.length <= 0) {
        return null;
    }

    return (
        <>
            <Typography variant="inherit" className={css.networksTabTitle}>
                {appResources.userscreenNetworkLabel}
            </Typography>
            {networks.length > 1 ? getSelectList(networks) : <p>{networks[0].networkName}</p>}
        </>
    );
};

export default NetworkSwitcher;
