import { ClientObject, ClientObjectProperty, PropertyInit } from '../common/clientObject';
import { isNewDocument } from '../common/officeDocumentUtils';
import { RequestType, ValueType } from '../comObject';
import { Window } from './window';

export class Workbook extends ClientObject implements PropertyInit {
    private properties = this.clientObjectInitPropsInfo({
        fullName: { propertyName: 'fullName', name: 'FullName' },
        saved: { propertyName: 'saved', name: 'Saved' },
        name: { propertyName: 'name', name: 'Name' },
        readOnly: { propertyName: 'readOnly', name: 'ReadOnly' },
        normalizedFullName: { propertyName: 'normalizedFullName', name: 'NormalizedFullName', extension: true },
    });

    public getPropertyInitializers(): Map<keyof Workbook, () => void> {
        return new Map<keyof Workbook, () => void>(
            // FIXME: explicit value argument type should not be required
            Object.values(this.properties).map((value: ClientObjectProperty<Workbook>) => [
                value.propertyName,
                (): void =>
                    this.reference.request({
                        type: RequestType.GetProperty,
                        cacheName: value.propertyName,
                        name: value.name,
                        extension: value.extension,
                    }),
            ]),
        ).set('isNew', () => {
            this.reference.request({
                type: RequestType.GetProperty,
                cacheName: 'normalizedFullName',
                name: 'NormalizedFullName',
                extension: true,
            });
        });
    }

    public get activeWindow(): Window {
        return this.reference.requestObject({
            type: RequestType.GetProperty,
            creator: Window,
            name: 'ActiveWindow',
        }).value;
    }

    /**
     * https://docs.microsoft.com/en-us/office/vba/api/excel.workbook.fullname
     * https://docs.microsoft.com/en-us/dotnet/api/microsoft.office.interop.excel.workbookclass.fullname?view=excel-pia
     */
    public get fullName(): string {
        return this.reference.getValue<string>(this.properties.fullName.propertyName).value;
    }

    /**
     * Extension to {@link fullName}.
     *
     * If original value represents OneDrive url, attempts to convert it to actual path on disk and returns the latter.
     * Returns unmodified value otherwise.
     */
    public get normalizedFullName(): string {
        return this.reference.getValue<string>(this.properties.normalizedFullName.propertyName).value;
    }

    /**
     * https://docs.microsoft.com/en-us/office/vba/api/excel.workbook.close
     * https://docs.microsoft.com/en-us/dotnet/api/microsoft.office.interop.excel.workbookclass.close?view=excel-pia
     */
    public close(saveChanges?: boolean): void {
        return this.reference.request({
            type: RequestType.Invoke,
            name: 'Close',
            args: saveChanges === undefined ? undefined : [{ value: saveChanges, type: ValueType.Bool }],
        });
    }

    public get saved(): boolean {
        return this.reference.getValue<boolean>(this.properties.saved.propertyName).value;
    }

    public get name(): string {
        return this.reference.getValue<string>(this.properties.name.propertyName).value;
    }

    public get readOnly(): boolean {
        return this.reference.getValue<boolean>(this.properties.readOnly.propertyName).value;
    }

    public save(): void {
        return this.reference.request({
            type: RequestType.Invoke,
            name: 'Save',
        });
    }

    public saveAs(filePath: string): void {
        return this.reference.request({
            type: RequestType.Invoke,
            name: 'SaveAs',
            args: [{ value: filePath, type: ValueType.String }],
        });
    }

    public get isNew(): boolean {
        return isNewDocument(this.reference.getValue<string>(this.properties.normalizedFullName.propertyName).value);
    }
}
