import 'xss';

declare global {
    interface Window {
        // tslint:disable-next-line: no-any
        trustedTypes: {
            createPolicy: (type: string, options: object) => void;
        };
    }
}

if (window.trustedTypes && window.trustedTypes.createPolicy) {
    window.trustedTypes.createPolicy('default', {
        createHTML: (str: string) => {
            const hashCode = str.split('').reduce((a, b) => {
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                a = (a << 5) - a + b.charCodeAt(0);
                return a & a;
            }, 0);
            const allowedScriptsHash = [
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                -1326695601, // browser-sync
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                -1197612882, // browser-sync
            ];

            return allowedScriptsHash.includes(hashCode) ? str : filterXSS(str);
        },
        createScriptURL: (src: string) => src, // warning: this is unsafe!
        createScript: (src: string) => src, // warning: this is unsafe!
    });
}
