// this is a hook around dispatchRefreshList.
// if a async action has a lot of dependencies that can be obtained with hooks,

import { IRefreshUUIForEntity } from '@wk/elm-uui-context-handler';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useReduxDispatch } from '../../../hooks/useReduxDispatch';
import { useUUIHistory } from '../../../hooks/useUUIHistory';
import { useUUILocation } from '../../../hooks/useUUILocation';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { measureStringWidth } from '../../../utils/utilities';
import { generatePagePostObject } from '../listScreenHelpers';
import { RefreshList, IListScreenPostObject, ISavedView, IRefreshListOptions } from '../types';
import { dispatchRefreshList } from './listScreenAsyncActions';
import { useListScreenState, useListScreenDispatch, useListScreenIsMounted } from './listScreenContext';

// you can use this pattern to compose a new hook.
export const useRefreshList = (): RefreshList => {
    const listScreenState = useListScreenState();
    const listScreenDispatch = useListScreenDispatch();
    const reduxDispatch = useReduxDispatch();
    const location = useUUILocation();
    const history = useUUIHistory();
    // use the isMounted that is instantiated on the ListScreenProvider.
    // we only care when the ENTIRE list screen is unmounted.
    const isMounted = useListScreenIsMounted();

    const updateHistoryState = useCallback(
        (postObject: IListScreenPostObject, resetBaseline?: boolean) => {
            if (!location.state) {
                location.state = {};
            }
            location.state.listScreen = {
                ...location.state.listScreen,
                postObject,
                baselinePageData: resetBaseline ? undefined : listScreenState.baselinePageData,
            };
            history.replace(location);
        },
        [history, listScreenState.baselinePageData, location],
    );

    const listScreenPageObject = listScreenState.listData?.page;

    const refreshList = useCallback(
        (options?: IRefreshListOptions) => {
            const po = options?.postObject || generatePagePostObject(listScreenPageObject!);
            po['search-keywords'] =
                options?.newQuickSearchText !== undefined
                    ? options?.newQuickSearchText
                    : listScreenState.quickSearchString;

            // Only update history state if calling function passed in a postObject
            // otherwise this is just a data update, and no history update is needed.
            if (options?.postObject) {
                updateHistoryState(po, options?.resetBaselinePageData);
            }
            if (options?.resetBaselinePageData) {
                listScreenDispatch({ type: 'ClearBaselinePageData' });
            }
            let fetchAll = false;
            if (options?.fetchAll) {
                fetchAll = options.fetchAll;
            }
            const refreshUUIForEntity: IRefreshUUIForEntity = {
                entityTypeId: listScreenState.metadata?.entityId.toString() || '',
                entityInstanceId: po.selectedIds && po.selectedIds[0].toString(),
                parentItemInfo: listScreenState.parentItemInfo,
            };
            return dispatchRefreshList({
                postObject: po,
                metadataUrl: listScreenState.metadataUrl!,
                dataUrl: listScreenState.dataUrl!,
                isEmbeddedList: listScreenState.isEmbeddedList,
                currentListScreenStatePageObject: listScreenPageObject,
                listScreenDispatch,
                reduxDispatch,
                isMounted,
                fetchAll,
                getIsCancelled: options?.getIsCancelled,
                setRequestIsActive: options?.setRequestIsActive,
                refreshUUIForEntity: refreshUUIForEntity,
            });
        },
        [
            listScreenPageObject,
            listScreenState.quickSearchString,
            listScreenState.metadataUrl,
            listScreenState.dataUrl,
            listScreenState.isEmbeddedList,
            updateHistoryState,
            listScreenDispatch,
            reduxDispatch,
            isMounted,
            listScreenState.metadata?.entityId,
            listScreenState.parentItemInfo,
        ],
    );

    return refreshList;
};

export interface IUseListScreenSavedViews {
    firstSavedViewValue: number | undefined;
    orderedSavedViews: ISavedView[];
    selectedSavedViewId: number | undefined;
    selectedSavedViewName: string | undefined;
    loadSavedView: (e: ChangeEvent<unknown>, savedViewId: number) => void;
}

export const useListScreenSavedViews = (): IUseListScreenSavedViews => {
    const listScreenState = useListScreenState();
    const refreshList = useRefreshList();
    const metadata = listScreenState.metadata!;
    const listData = listScreenState.listData!;

    const getFirstSavedViewValue = () => {
        let result = metadata.savedViews.find((sv) => sv.isUserDefault);
        if (result) {
            return result.id;
        }
        result = metadata.savedViews.find((sv) => sv.isSystemDefault);
        if (result) {
            return result.id;
        }
        return 0;
    };

    const orderedSavedViews = metadata.savedViews
        .filter((sv) => !sv.isSystemDefault && !sv.isUserDefault)
        .sort((sv1, sv2) => {
            const sv1Name = sv1.name ? sv1.name : '';
            const sv2Name = sv2.name ? sv2.name : '';
            return sv1Name.localeCompare(sv2Name);
        });

    const selectedSavedViewExistsInMetadata =
        metadata.savedViews.find((sv) => sv.id === listData.page.savedView.id) != null;

    const selectedSavedViewId = selectedSavedViewExistsInMetadata
        ? listData.page.savedView.id
        : getFirstSavedViewValue();
    const selectedSavedViewName = selectedSavedViewExistsInMetadata ? listData.page.savedView.name : '';

    const loadSavedView = (_: ChangeEvent<unknown>, savedViewId: number) => {
        if (savedViewId === 0) {
            const postObj = generatePagePostObject(listData.page, {});
            postObj['search-keywords'] = listScreenState.quickSearchString;
            refreshList({ postObject: postObj, resetBaselinePageData: true });
        } else {
            if (savedViewId == listData.page.savedView.id) {
                return;
            }
            const savedViewToLoad: ISavedView = metadata.savedViews.find((sv) => sv.id === savedViewId)!;
            const postObj = generatePagePostObject(listData.page, {
                'column-data': savedViewToLoad.columns ? savedViewToLoad.columns[0] : undefined,
                filters: savedViewToLoad.filters,
                sortInfo: savedViewToLoad.sort,
                pageNumber: 1,
                savedView: savedViewToLoad,
            });
            postObj['search-keywords'] = listScreenState.quickSearchString;
            refreshList({ postObject: postObj, resetBaselinePageData: true });
        }
    };

    return {
        firstSavedViewValue: getFirstSavedViewValue(),
        orderedSavedViews,
        selectedSavedViewId,
        selectedSavedViewName,
        loadSavedView,
    };
};

export interface IResponsiveBreadCrumb {
    folderName: string;
}

export interface IUseResponsiveBreadCrumbsOptions {
    fontSize?: string;
    containerWidth?: number;
}

/**
 * Takes an array of breadcrumbs and returns an array of breadcrumbs that will fit in the container width provided.
 * @param breadCrumbs array of breadcrumbs
 * @param fontSize optional: defaults to 14px
 * @param containerWidth optional: defaults to using the current window width and will respond to window resize
 * @returns a sublist of breadcrumbs that will fit
 */
export const useResponsiveBreadCrumbs = <T extends IResponsiveBreadCrumb>(
    breadCrumbs: Array<T>,
    options?: IUseResponsiveBreadCrumbsOptions,
): Array<T> => {
    const [breadCrumbList, setBreadCrumbList] = useState<Array<T>>([]);
    const windowWidth = useWindowWidth(100);
    const cWidth = options?.containerWidth || windowWidth;

    useEffect(() => {
        if (breadCrumbs.length === 0) {
            return;
        }

        const CARET_WIDTH = 20;
        const PADDING_WIDTH = 26;
        const ELLIPSIS_WIDTH = 11;

        const arrList: Array<T> = [];

        let currentTextLength = PADDING_WIDTH;
        breadCrumbs
            .slice(0)
            .reverse()
            .map((breadCrumb: T) => {
                const folderNameLength = measureStringWidth(breadCrumb.folderName, options?.fontSize || '14px');
                if (cWidth > currentTextLength) {
                    currentTextLength = currentTextLength + folderNameLength + CARET_WIDTH;
                    const thisBreadCrumb = { ...breadCrumb };
                    if (currentTextLength >= cWidth - ELLIPSIS_WIDTH && arrList.length > 0) {
                        thisBreadCrumb.folderName = '...';
                        currentTextLength = currentTextLength + cWidth - currentTextLength + 1;
                    }
                    arrList.unshift(thisBreadCrumb);
                } else {
                    if (arrList.length === 1) {
                        const thisBreadCrumb = { ...breadCrumb };
                        thisBreadCrumb.folderName = '...';
                        arrList.unshift(thisBreadCrumb);
                    }
                    setBreadCrumbList(arrList);
                    return;
                }
            });
        setBreadCrumbList(arrList);
    }, [cWidth, breadCrumbs, options?.fontSize]);

    return breadCrumbList;
};
