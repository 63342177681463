import { CHSupportedDocumentTypesEnum, ICHQuickFileIndicator } from '@wk/elm-uui-context-handler';
import { uniqueId } from 'lodash';
import { IApplicationUrls, IAppResources, UUIReduxDispatch } from '../../reducers/types';
import { clViewQuickFileIcon, isOC } from '../contextLayerService/contextLayerService';
import { IItemScreenOperation } from '../itemScreen/types';
import { IListScreenOperation, IParentItemInfo } from '../listScreen/types';

export interface IDetermineRedirectUrl {
    operation: IListScreenOperation | IItemScreenOperation;
    applicationUrls: IApplicationUrls;
    parentItemInfo?: IParentItemInfo;
    entityInstanceId?: number;
    selectedRowId?: number;
    entityId?: string;
}

export const determineRedirectUrl = ({
    operation,
    applicationUrls,
    parentItemInfo,
    entityInstanceId,
    selectedRowId,
    entityId,
}: IDetermineRedirectUrl): string | undefined => {
    if (operation.linkUrl) {
        // with a linkUrl prop present, this is a row-operation link
        const linkUrl = operation.linkUrl.replace('{selectedRowId}', selectedRowId?.toString() || '');
        return entityId ? `${linkUrl}&entityTypeId=${entityId}` : linkUrl;
    }

    if (operation.targetScreenId && operation.targetScreenMode && operation.targetScreenType) {
        if (operation.targetScreenType === 'list') {
            return applicationUrls.listScreenUrl
                .replace('{screenId}', operation.targetScreenId.toString())
                .replace('{mode}', operation.targetScreenMode?.toLowerCase());
        } else {
            return applicationUrls.itemScreenUrl
                .replace('{screenId}', operation.targetScreenId.toString())
                .replace('{mode}', operation.targetScreenMode?.toLowerCase())
                .replace(
                    '{entityInstanceId}',
                    operation.isLinkToParent && parentItemInfo?.parentInstanceId
                        ? parentItemInfo.parentInstanceId.toString()
                        : entityInstanceId?.toString() || '',
                )
                .replace('{selectedRowId}', selectedRowId?.toString() || '')
                .replace(
                    '{parentInstanceId}',
                    !operation.isLinkToParent ? parentItemInfo?.parentInstanceId?.toString() || '' : '',
                )
                .replace(
                    '{parentEntityId}',
                    !operation.isLinkToParent ? parentItemInfo?.parentEntityId?.toString() || '' : '',
                )
                .replace('{parentFieldName}', !operation.isLinkToParent ? parentItemInfo?.parentFieldName || '' : '')
                .replace(
                    '{parentEntityName}',
                    !operation.isLinkToParent ? parentItemInfo?.parentEntityName?.toString() || '' : '',
                );
        }
    }

    return;
};

export const getCLQuickFileVisibility = (
    isFolder: boolean,
    enableQFDocuments: boolean,
    enableQFEmails: boolean,
): Promise<ICHQuickFileIndicator | undefined> => {
    let retry = false;
    let documentType = CHSupportedDocumentTypesEnum.EMAIL;
    let skipCL = false;

    if (enableQFEmails && enableQFDocuments) {
        retry = true;
    } else if (enableQFEmails) {
        documentType = CHSupportedDocumentTypesEnum.EMAIL;
    } else if (enableQFDocuments) {
        documentType = CHSupportedDocumentTypesEnum.DOCUMENT;
    } else {
        skipCL = true;
    }

    if (!isOC() || skipCL) {
        return Promise.resolve(undefined);
    }

    return clViewQuickFileIcon(isFolder, documentType).then((qfInd) => {
        if (retry && !qfInd.enableQuickFile) {
            const retryDocType =
                documentType === CHSupportedDocumentTypesEnum.EMAIL
                    ? CHSupportedDocumentTypesEnum.DOCUMENT
                    : CHSupportedDocumentTypesEnum.EMAIL;

            return clViewQuickFileIcon(isFolder, retryDocType);
        } else {
            return qfInd;
        }
    });
};

export const showConfirmationDialog = (
    reduxDispatch: UUIReduxDispatch,
    confirmationMessage: string,
    appResources: IAppResources,
): Promise<boolean> => {
    return new Promise<boolean>((resolve) => {
        reduxDispatch({
            type: 'OpenAlertDialog',
            alertDialog: {
                id: uniqueId('dialog_'),
                title: appResources.confirmationDialogTitle,
                message: confirmationMessage,
                buttons: [
                    { label: appResources.buttonProceed, onClick: () => resolve(true) },
                    { label: appResources.buttonCancel, onClick: () => resolve(false) },
                ],
            },
        });
    });
};
