import 'reflect-metadata';
import { IDialog, IToast, IItemScreen, IEventCallback, IRefreshUUIForEntity } from './interface';
import { Observable, Subject } from 'rxjs';
import { inject, injectable } from 'inversify';
import 'reflect-metadata';
import { EventType } from '../enum/enum';
import { v4 as uuid } from 'uuid';
import { IOverlayDialog } from './interface/overlayDialog.interface';
import { ConnectionService } from './connection.service';

export type IEvent =
    | { name: EventType.ITEMSCREEN; itemScreen: IItemScreen }
    | { name: EventType.DIALOG; dialog: IDialog }
    | { name: EventType.TOAST; toast: IToast; onlyThisInstance?: boolean }
    | { name: EventType.REFRESH_UUI_FOR_ENTITY; refreshUUIForEntity: IRefreshUUIForEntity }
    | { name: EventType.UNAUTHORIZED_ACCESS_ATTEMPT }
    | { name: EventType.NETWORK_RESTORED }
    | { name: EventType.NETWORK_DOWN }
    | { name: EventType.OVERLAY; overlay: IOverlayDialog }
    | { name: EventType.OVERLAY_CLOSE }
    | { name: EventType.GLOBAL_SPINNER; isOpen: boolean };

export interface IEventService {
    publish(params: IEvent): void;
    subscribe(name: EventType, callback: IEventCallback, existingIdToRemove?: string): string;
    unsubscribe(subscriberId: string): void;
}

@injectable()
export class EventContextHandler implements IEventService {
    private _connectionService: ConnectionService;

    private _eventSource: Subject<IEvent>;
    public eventListener$: Observable<IEvent>;
    private _subscribers = {};

    supportedOperations = async (): Promise<string[]> => await ['eventContextHandler'];
    performOperation = async (): Promise<string[]> => await ['eventContextHandler'];
    getContextInfo = async (): Promise<string[]> => await ['eventContextHandler'];

    constructor(@inject('ConnectionService') connectionService: ConnectionService) {
        this._connectionService = connectionService;
        this._eventSource = new Subject();
        this.eventListener$ = this._eventSource.asObservable();
        this.eventListener$.subscribe((event: IEvent) => {
            // TO DO
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let subscribers: any[] = this._subscribers[(event || { name: '' }).name];
            if (subscribers === undefined || subscribers === null) {
                subscribers = [];
            }
            try {
                subscribers.forEach((subscriber) => {
                    try {
                        switch (event.name) {
                            case EventType.DIALOG:
                                subscriber.callback(event.dialog);
                                break;
                            case EventType.TOAST:
                                subscriber.callback(event.toast, event.onlyThisInstance);
                                break;
                            case EventType.ITEMSCREEN:
                                subscriber.callback(event.itemScreen);
                                break;
                            case EventType.REFRESH_UUI_FOR_ENTITY:
                                subscriber.callback(event.refreshUUIForEntity);
                                break;
                            case EventType.UNAUTHORIZED_ACCESS_ATTEMPT:
                            case EventType.NETWORK_RESTORED:
                            case EventType.NETWORK_DOWN:
                                subscriber.callback();
                                break;
                            case EventType.OVERLAY:
                                subscriber.callback(event.overlay);
                                break;
                            case EventType.OVERLAY_CLOSE:
                                subscriber.callback();
                                break;
                            case EventType.GLOBAL_SPINNER:
                                subscriber.callback(event.isOpen);
                                break;
                            default:
                                throw new Error('Callback not defined for event name');
                        }
                    } catch (e) {
                        console.error(
                            'Error occured while calling subscriber ',
                            subscriber,
                            ' for event:',
                            event,
                            ':',
                            e,
                        );
                    }
                });
            } catch (e) {
                console.error('Error occured while calling event:', event, ':', e);
            }
        });
    }

    // tslint:disable-next-line:ban-types
    public subscribe(name: EventType, callback: IEventCallback, existingSubscriptionToRemove?: string): string {
        if (existingSubscriptionToRemove) {
            this.unsubscribe(existingSubscriptionToRemove);
        }
        let subscribers = this._subscribers[name];
        if (subscribers === undefined || subscribers === null) {
            subscribers = [];
            this._subscribers[name] = subscribers;
        }
        const subscriberId = uuid();
        subscribers.push({ subscriberId, callback });
        return subscriberId;
    }

    public unsubscribe(subscriberId: string): void {
        Object.keys(this._subscribers).forEach((event) => {
            const subscribers = this._subscribers[event];
            let index = 0;
            let subscriberIndex;
            // TO DO
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            subscribers.forEach((subscriber: any) => {
                if (subscriber.subscriberId === subscriberId) {
                    subscriberIndex = index;
                }

                index = index + 1;
            });

            if (subscriberIndex !== undefined && subscriberIndex !== null) {
                subscribers.splice(subscriberIndex, 1);
            }
        });
    }

    public publish(params: IEvent): void {
        if (this.canEventBeEmitted(params)) {
            this.emit(params);
        } else {
            this.performConnectionEvent(params);
        }
    }

    private performConnectionEvent(params: IEvent): void {
        if (params.name === EventType.NETWORK_DOWN || params.name === EventType.NETWORK_RESTORED) {
            this._connectionService.connectionServiceEventHandler(params.name);
        }
    }
    private canEventBeEmitted(params: IEvent): boolean {
        if (params.name === EventType.NETWORK_DOWN || params.name === EventType.NETWORK_RESTORED) {
            return false;
        }
        return true;
    }
    public emit(nextEvent: IEvent): void {
        this._eventSource.next(nextEvent);
    }
}
