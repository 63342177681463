import React, { useState, useEffect } from 'react';
import { MenuItem, Select } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import css from './currencyControl.module.scss';
import ReadOnlyControl from './readOnlyControl';
import { IItemScreenDataElement, IItemScreenField } from '../types';
import { ControlLabel } from './controlLabel';
import { getReactHookFormFieldName, getValidationMessagesForField } from '../itemScreenHelpers';
import { useReduxSelector } from '../../../hooks/useReduxSelector';

type DropdownClasses = {
    dropdownContainer?: string;
    dropdownMenu?: string;
    menupaper?: string;
};

interface ICurrencyControl {
    field: IItemScreenField;
    fieldData: IItemScreenDataElement;
    classes?: DropdownClasses;
    readOnly?: boolean;
}

const CurrencyControl: React.FC<ICurrencyControl> = ({ field, fieldData, classes, readOnly }) => {
    const currencies = useReduxSelector((state) => state.availableCurrencies);
    const [currency, setCurrency] = useState(fieldData.currency || fieldData.inputValue || '');
    const { register, setValue, errors } = useFormContext();

    const isCurrencyControlOnMoney = field.controlType === 'Money';
    const fieldNameToRegister = getReactHookFormFieldName(field, isCurrencyControlOnMoney);

    useEffect(() => {
        if (!readOnly) {
            register({ name: fieldNameToRegister });
            setValue(fieldNameToRegister, currency);
        }
    }, [register, setValue, fieldNameToRegister, currency, readOnly]);

    if (readOnly) {
        return <ReadOnlyControl field={field} fieldData={fieldData} />;
    }

    const hasError = getValidationMessagesForField(field, errors, 'errors').length > 0;
    const hasWarning = getValidationMessagesForField(field, errors, 'warnings').length > 0;

    const onCurrencyChange = (event: any) => {
        setCurrency(event.target.value);
        setValue(fieldNameToRegister, event.target.value);
    };
    const inputClasses = {
        container:
            (hasError ? css.errorContainer : hasWarning ? css.warningContainer : css.container) +
            ' ' +
            classes?.dropdownContainer,
        root:
            (hasError ? css.errorDropdown : hasWarning ? css.warningDropdown : css.dropdown) +
            ' ' +
            (classes?.dropdownMenu || ''),
        paper: css.paper + ' ' + (classes?.menupaper || ''),
    };

    const DropdownView = () => {
        return (
            <Select
                data-testid={`${field.name}-currency-dropdown-menu`}
                disableUnderline
                name={fieldNameToRegister}
                className={inputClasses.container}
                classes={{ root: inputClasses.root }}
                value={currency}
                error={hasError}
                onChange={onCurrencyChange}
                fullWidth
                MenuProps={{ classes: { paper: inputClasses.paper } }}>
                {currencies.map((currency: string, index: number) => (
                    <MenuItem
                        key={index}
                        dense
                        data-testid="currency-dropdown-menu-item"
                        value={currency}
                        className={css.menuItem}>
                        {currency}
                    </MenuItem>
                ))}
            </Select>
        );
    };

    return field.controlType === 'Currency' ? (
        <ControlLabel field={field} control={<DropdownView />}></ControlLabel>
    ) : (
        <DropdownView />
    );
};

export default CurrencyControl;
