import { ClientObject, ClientObjectProperty, PropertyInit } from '../common/clientObject';
import { RequestType, ValueHolder, ValueType } from '../comObject';
import { Attachments } from './attachments';
import { AddressEntry } from './addressEntry';
import { Recipients } from './recipients';
import { SaveAsType } from './saveAsType.enum';
import { ObjectClass } from './objectClass.enum';

export class MailItem extends ClientObject implements PropertyInit {
    private properties = this.clientObjectInitPropsInfo({
        class: { propertyName: 'class', name: 'Class' },
        messageClass: { propertyName: 'messageClass', name: 'MessageClass' },
        subject: { propertyName: 'subject', name: 'Subject' },
        to: { propertyName: 'to', name: 'To' },
        sent: { propertyName: 'sent', name: 'Sent' },
        body: { propertyName: 'body', name: 'Body' },
        senderName: { propertyName: 'senderName', name: 'SenderName' },
        senderEmailAddress: { propertyName: 'senderEmailAddress', name: 'SenderEmailAddress' },
        receivedTime: { propertyName: 'receivedTime', name: 'ReceivedTime' },
        sentOn: { propertyName: 'sentOn', name: 'SentOn' },
    });

    public getPropertyInitializers(): Map<keyof MailItem, () => void> {
        return new Map<keyof MailItem, () => void>(
            Object.values(this.properties).map(({ propertyName, name }) => [
                propertyName,
                (): void =>
                    this.reference.request({
                        type: RequestType.GetProperty,
                        cacheName: propertyName,
                        name,
                    }),
            ]),
        ).set('isMail', () => {
            this.reference.request({
                type: RequestType.GetProperty,
                cacheName: 'class',
                name: 'Class',
            });
        });
    }

    private setProperty<T>(property: ClientObjectProperty<MailItem>, valueHolder: ValueHolder<T>): void {
        const { propertyName, name } = property;

        this.reference.request({
            type: RequestType.SetProperty,
            cacheName: propertyName,
            name,
            args: [valueHolder],
        });
    }

    public get class(): ObjectClass {
        return this.reference.getValue<ObjectClass>(this.properties.class.propertyName).value;
    }

    public get messageClass(): string {
        return this.reference.getValue<string>(this.properties.messageClass.propertyName).value;
    }

    public get isMail(): boolean {
        // alternative to classic OC regexp check against messageClass
        // new RegExp('^(IPM\\.NOTE(\\.?|\\..*))$|(IPM\\.InfoPathForm(\\.?|\\..*))$', 'i');
        return this.reference.getValue<ObjectClass>(this.properties.class.propertyName).value === ObjectClass.Mail;
    }

    public get subject(): string {
        return this.reference.getValue<string>(this.properties.subject.propertyName).value;
    }

    public set subject(value: string) {
        this.setProperty(this.properties.subject, { type: ValueType.String, value });
    }

    public get to(): string {
        return this.reference.getValue<string>(this.properties.to.propertyName).value;
    }

    public set to(value: string) {
        this.setProperty(this.properties.to, { type: ValueType.String, value });
    }

    public get sent(): boolean {
        return this.reference.getValue<boolean>(this.properties.sent.propertyName).value;
    }

    public get body(): string {
        return this.reference.getValue<string>(this.properties.body.propertyName).value;
    }

    public get senderName(): string {
        return this.reference.getValue<string>(this.properties.senderName.propertyName).value;
    }

    public get senderEmailAddress(): string {
        return this.reference.getValue<string>(this.properties.senderEmailAddress.propertyName).value;
    }

    public get receivedTime(): Date {
        return this.reference.getValue<Date>(this.properties.receivedTime.propertyName).value;
    }

    public get sentOn(): Date {
        return this.reference.getValue<Date>(this.properties.sentOn.propertyName).value;
    }

    public get attachments(): Attachments {
        return this.reference.requestObject({
            type: RequestType.GetProperty,
            creator: Attachments,
            name: 'Attachments',
        }).value;
    }

    public get sender(): AddressEntry {
        return this.reference.requestObject({
            type: RequestType.GetProperty,
            creator: AddressEntry,
            name: 'Sender',
        }).value;
    }

    public get recipients(): Recipients {
        return this.reference.requestObject({
            type: RequestType.GetProperty,
            creator: Recipients,
            name: 'Recipients',
        }).value;
    }

    public display(): void {
        return this.reference.request({
            type: RequestType.Invoke,
            name: 'Display',
        });
    }

    public saveAs(path: string, type = SaveAsType.MsgUnicode): void {
        return this.reference.request<undefined>({
            type: RequestType.Invoke,
            name: 'SaveAs',
            args: [
                { type: ValueType.String, value: path },
                { type: ValueType.Integer, value: type },
            ],
        });
    }
}
