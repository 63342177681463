import { AppConfig, ConfigValue, DynamicConfig } from './config.interface';

type ValueType<T> = T extends keyof AppConfig ? AppConfig[T] : T;
type KeyType<T> = T extends keyof AppConfig ? keyof AppConfig : T;

export class ConfigService {
    private readonly dynamicConfig = new Map<string, ConfigValue>();
    private readonly config = new Map<keyof AppConfig, ConfigValue>();
    private static _instance: ConfigService;

    public static get instance(): ConfigService {
        if (!ConfigService._instance) {
            ConfigService._instance = new ConfigService();
        }
        return ConfigService._instance;
    }

    private constructor() {
        const envMap = Object.entries(process.env).reduce((agg, [key, value]) => {
            if (key && value) {
                agg.set(key, value);
            }

            return agg;
        }, new Map<string, string>());

        envMap.forEach((value, key) => {
            const numberValue = Number(value) || undefined;
            const booleanValue = (value === 'true' && true) || (value === 'false' ? false : undefined);
            const configValue = numberValue ?? booleanValue ?? value;

            this.config.set(key as keyof AppConfig, configValue);
        });

        const realNonce = document.head
            .querySelector('meta[property="csp-nonce"]')
            ?.attributes?.getNamedItem('content')?.value;
        if (realNonce) {
            this.config.set('REACT_APP_CSP_NONCE', realNonce);
        }
    }

    public get<T = keyof AppConfig, TKey extends KeyType<T> = KeyType<T>>(
        key: TKey,
        defaultValue?: ValueType<TKey>,
    ): ValueType<TKey> {
        const value = this.config.get(key as keyof AppConfig) ?? this.dynamicConfig.get(key as string) ?? defaultValue;
        return value as ValueType<TKey>;
    }

    public has(key: keyof AppConfig | string): boolean {
        return this.config.has(key as keyof AppConfig) || this.dynamicConfig.has(key);
    }

    public getAll<T = unknown>(): AppConfig & DynamicConfig<T> {
        return [...this.config.entries(), ...this.dynamicConfig.entries()].reduce<AppConfig & DynamicConfig<T>>(
            (agg, [key, value]) => {
                return { ...agg, [key]: value } as AppConfig & DynamicConfig<T>;
            },
            {} as AppConfig & DynamicConfig<T>,
        );
    }

    public set<T extends ConfigValue>(key: string, value: T, override = false) {
        if (!override && this.dynamicConfig.has(key)) {
            return;
        }

        this.dynamicConfig.set(key, value);
    }
}

export const config = ConfigService.instance;
