import { baseCommunicationService } from '@wk/office-companion-js-common';
import { injectable } from 'inversify';
import { map, Observable } from 'rxjs';
import { LoggerService } from '../../common/logger/logger.service';

enum ProfileAction {
    ReloadUrl = 'reload-profile-url',
    SetProfileDefaultUrl = 'set-profile-default-url',
}

export enum DefaultUrlSettingType {
    None = 'none',
    ExactUrl = 'exactUrl',
}

export interface ProfileUrlSettings {
    /**
     * the url type for the active profile, please refer to {@link DefaultUrlSettingType} for details.
     */
    type: DefaultUrlSettingType;
    /**
     * the default url for the active profile.
     */
    url?: string;
}

export interface ProfileInterface {
    reload(excludeCurrentInstance?: boolean): Observable<void>;
    setDefaultUrl(settings: ProfileUrlSettings): Observable<void>;
}

@injectable()
export class ProfileService implements ProfileInterface {
    constructor(private logger: LoggerService) {}

    /**
     * Sets the profile default url or base url in all open instances.
     *
     * @param excludeCurrentInstance can be set to not reload the current instance, `true` by default
     *
     * @returns  void observable which rejects if operation failed.
     */
    public reload(excludeCurrentInstance = true): Observable<void> {
        return baseCommunicationService
            .invoke<void>(ProfileAction.ReloadUrl, { excludeCurrentWindow: excludeCurrentInstance })
            .pipe(map(({ data }) => data));
    }

    /**
     * Sets the profile default url.
     *
     * @param settings to pass inside the call, please refer to {@link ProfileUrlSettings} for details
     *
     * @returns  void observable which rejects if operation failed
     */
    public setDefaultUrl(settings: ProfileUrlSettings): Observable<void> {
        return baseCommunicationService
            .invoke<void>(ProfileAction.SetProfileDefaultUrl, settings)
            .pipe(map(({ data }) => data));
    }
}
