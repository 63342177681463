import { IDocTransformer } from '../../interfaces/transformer/type';
import { IActionableEntity } from '../../interfaces/actionEntity/type';
import { injectable } from 'inversify';
import { factory } from '../../ConfigLog4j';
import { IMatterDoc, IAddEmail } from '../../types';
import { T360AdapterAddEmailActionableEntity } from '../actionableEntity/addEmailActionableEntity';
import { IDocOperationInvocation } from '../../interfaces/urlPattern/types';
import { removeDashes } from '../../utils/string.utils';

const log = factory.getLogger('T360AdapterAddEmailEntityTransformer');

@injectable()
export class T360AdapterAddEmailEntityTransformer implements IDocTransformer {
    transformIntoActionableEntity(
        nodeObj: IAddEmail,
        _matterDoc: IMatterDoc,
        _urlPattern: IDocOperationInvocation,
    ): IActionableEntity {
        log.debug('Calling transformIntoActionableEntity');

        const obj = {} as T360AdapterAddEmailActionableEntity;
        if (nodeObj?.entityContext?.id) {
            obj.matterId = nodeObj.entityContext.id;
        }
        if (nodeObj.file) {
            obj.file = nodeObj.file;
        }
        if (nodeObj?.extendedProps?.uploadGuid) {
            obj.uploadGuid = removeDashes(nodeObj.extendedProps.uploadGuid);
        }
        return obj;
    }
}
