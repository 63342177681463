// TODO: Must to remove all of this rules in the future.
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable max-lines-per-function */
import { RxAttachment, RxDatabase, RxDocument } from 'rxdb/plugins/core';
import { Observable } from 'rxjs';
import * as Database from '../database';
import { v4 as uuidv4 } from 'uuid';
import { injectable } from 'inversify';
import { QueueStatus, QueueItemStatus, Priority, QueueItemProcess } from './enums';
import {
    IQueueConfig,
    IQueueItem,
    IQueuePropItem,
    IQueueServiceInterface,
    ISuccessAndFailedList,
    ISuccessOrFailedItem,
} from './queueManager.interface';
import { factory, STATUS_CANNOT_BE_CHANGED, CancelledTime } from '../common';
import { BroadcastChannel, createLeaderElection, LeaderElector } from 'broadcast-channel';

const log = factory.getLogger('QueueManager');
const usageLog = factory.getLogger('UsageLogging');

@injectable()
export class QueueManager implements IQueueServiceInterface {
    public db!: RxDatabase;
    public databaseName!: string;
    public hasMigrationErrors?: boolean;
    public processObservable!: Observable<RxDocument<IQueueItem>>;
    public elementsQueued!: Observable<RxDocument<IQueueItem>[]>;
    public elementsProcessing!: Observable<RxDocument<IQueueItem>[]>;
    public elementsStarted!: Observable<RxDocument<IQueueItem>[]>;
    public elementsCompleted!: Observable<RxDocument<IQueueItem>[]>;
    public elementsError!: Observable<RxDocument<IQueueItem>[]>;
    public elementsFailed!: Observable<RxDocument<IQueueItem>[]>;
    public elementsPaused!: Observable<RxDocument<IQueueItem>[]>;
    public elementsPending!: Observable<RxDocument<IQueueItem>[]>;
    public elementsActiveRemoved!: Observable<RxDocument<IQueueItem>[]>;
    public elementsFlyout!: Observable<RxDocument<IQueueItem>[]>;
    public elementsCancelled!: Observable<RxDocument<IQueueItem>[]>;

    private queueStatus: string = QueueItemStatus.STOPPED;
    private isLeader = false;
    private channel?: BroadcastChannel;
    private elector?: LeaderElector;
    private initializeQueueTimeout: NodeJS.Timeout | null = null;

    /** @inheritdoc */
    async dbPromise(databaseName: string, secretKey: string): Promise<RxDatabase> {
        usageLog.info('Invoked dbPromise');
        const dbInfo = await Database.get(
            databaseName, // + currentWindow.custom.dbSuffix, // we add a random timestamp in dev-mode to reset the database on each start
            secretKey,
        );
        this.db = dbInfo.dbCollections;
        this.hasMigrationErrors = dbInfo.hasMigrationErrors;
        log.debug(`db created Successfully${this.db.queue}`);
        this.processObservable = this.db.queue
            .findOne({
                selector: {
                    status: { $eq: QueueItemStatus.PROCESSING },
                    markedForDelete: { $eq: false },
                },
            })
            .sort({
                uniqueId: 'asc',
            }).$;

        this._setupSyncAndObservables();
        return this.db;
    }

    // TODO: change to rxdb built-in api
    /** @inheritdoc */
    async createCustomLeaderElection(): Promise<void> {
        if (this.elector && !this.elector.isDead) {
            await this.elector.die();
        }
        if (this.channel && !this.channel.isClosed) {
            await this.channel.close();
        }

        this.channel = new BroadcastChannel('UUI_Channel');
        this.elector = createLeaderElection(this.channel, {
            fallbackInterval: 4000, // optional configuration for how often will renegotiation for leader occur
            responseTime: 2000, // optional configuration for how long will instances have to respond
        });

        this.elector.onduplicate = async (): Promise<void> => {
            log.error('have duplicate leaders');
            if (this.initializeQueueTimeout !== null) {
                clearTimeout(this.initializeQueueTimeout);
            }
            // To stop processing queue by duplicate leader
            this.isLeader = false;
            await this.elector?.die();
            await this.channel?.close();
            await this.createCustomLeaderElection();
        };

        void this.elector.awaitLeadership().then(() => {
            if (!this.isLeader) {
                this.initializeQueueTimeout = setTimeout(async () => {
                    log.debug('Instance is selected as Leader');
                    log.debug('Opted as leader so updating all processing as New');
                    this.isLeader = true;
                    await this._updateNewWhenInitialize();
                    // only set this if leader is set. otherwise leader should never execute....
                    await this._processQueue();
                    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                }, 5000);
            }
        });
    }

    /** @inheritdoc */
    checkIfLeader(): boolean {
        return this.isLeader;
    }

    destroy = async (): Promise<boolean> => {
        usageLog.info('Invoked destroy');
        log.debug('Destroying the database');
        await this.elector?.die();
        await this.channel?.close();
        return this.db.destroy();
    };

    remove = (): Promise<void> => {
        usageLog.info('Invoked remove');
        log.debug('Removing  the database');
        return this.db.remove();
    };
    /**
     *
     * @param name string
     * @param statusArr Array of Queue Status
     */
    checkQueueIndicatorPresent = async (name: string, statusArr: Array<QueueItemStatus>): Promise<boolean> => {
        const statusSel = [];
        for (const status of statusArr) {
            statusSel.push({ status: { $eq: status } });
        }

        const queryUpdate = this.db.queue.find({
            selector: {
                queue_item_unique_key: { $eq: name },
                $or: statusSel,
            },
        });

        const result = await queryUpdate.exec();
        log.debug('check Queue Indicator Present returned ' + (result.length > 0).toString());
        return result.length > 0;
    };

    setQueuePaused = (): void => {
        usageLog.info('Invoked setQueuePaused');
        this._setQueueStatus(QueueStatus.PAUSED);
    };

    setQueueResumed = async (): Promise<void> => {
        usageLog.info('Invoked setQueueResumed');
        this.queueStatus = QueueStatus.STOPPED;
        await this._processQueue();
    };

    /*
  Setting Props
  */
    setProps = async (key: string, value: string): Promise<RxDocument<IQueuePropItem>> => {
        // create queue Item
        const queueObj: IQueuePropItem = {
            uniqueId: key,
            key,
            value,
        };

        const queueItem = await this.db.queueprops.atomicUpsert(queueObj);

        log.debug('created queue Item' + queueItem.uniqueId);
        // this._processQueue();
        return queueItem;
    };

    /*
  Getting Prop set
  */

    getProps = async (): Promise<RxDocument<IQueuePropItem>[]> => {
        // create queue Item

        const query = this.db.queueprops.find();

        const result = await query.exec();
        log.debug('getProps result' + JSON.stringify(result));
        return result;
    };

    /*
     * Getting Started, Processing and Pending  QueueItem with the given Unique Key and not the given Unique Id
     */
    getStartedItemsWithUniqueId = async (uniqueId: string, uniqueKey: string): Promise<RxDocument<IQueueItem>> => {
        // create queue Item
        const statusValid = [];
        statusValid.push({ status: { $eq: QueueItemStatus.PROCESSING } });
        statusValid.push({ status: { $eq: QueueItemStatus.STARTED } });
        statusValid.push({ status: { $eq: QueueItemStatus.PENDING } });

        const query = this.db.queue.findOne({
            selector: {
                $or: statusValid,
                queue_item_unique_key: { $eq: uniqueKey },
                uniqueId: { $ne: uniqueId },
            },
        });
        const result = await query.exec();
        log.debug('getStartedItemsWithUniqueId result' + result);
        return result;
    };

    /*
  Inserting queue Item - ContextHandler
  */
    addItem = async (
        data: Record<string, unknown>,
        operation: string,
        service: string,
        addedTime?: number,
        groupId?: string,
        noOfItems?: number,
        queueItemUniqueKey?: string,
        metaData?: Record<string, string>,
        priority = Priority.NORMAL,
        maxRetries?: number,
    ): Promise<RxDocument<IQueueItem>> => {
        usageLog.info('Invoked addItem');
        log.debug('data is: ' + data);
        log.debug('operation is: ' + operation);
        log.debug('service is: ' + service);

        const jsonDate = new Date().toJSON();
        const markedForDelete = false;
        if (maxRetries === undefined) {
            const result = await this.getConfig();
            maxRetries = result.maxRetries;
        }
        let uniqueKey = '';
        if (queueItemUniqueKey) {
            uniqueKey = queueItemUniqueKey;
        }
        let meta_data = {};
        if (metaData && Object.keys(metaData).length > 0) {
            meta_data = metaData;
        }

        const location = data.folderArr as string[];
        const elementType = location[0].includes('Email') ? 'Email' : 'Document';

        // create queue Item
        const queueObj: IQueueItem = {
            uniqueId: uuidv4(),
            priority,
            data,
            status: QueueItemStatus.NEW,
            service,
            queue_item_unique_key: uniqueKey,
            operation,
            maxRetries,
            markedForDelete,
            created: jsonDate,
            metaData: meta_data,
            retryCount: 0,
            error: [],
            response: [],
            location,
            elementType,
            groupId,
            noOfItems,
            eventForwarded: false,
            addedTime,
        };
        // log.debug('created queue Obj' + queueObj);

        const props = await this.getProps();

        const cancelledObj = props.find((prop) => {
            return prop.key == CancelledTime;
        });

        // if cancelledTime is present
        // make it cancelled if the addedTime is less than createdTime
        if (cancelledObj && addedTime) {
            if (addedTime < parseInt(cancelledObj.value, 10)) {
                queueObj.status = QueueItemStatus.CANCELLED;
            }
        }

        const queueItem: RxDocument<IQueueItem> = await this.db.queue.insert(queueObj);

        log.debug('added in queue for ' + queueItem.uniqueId);
        // this._processQueue();
        return queueItem;
    };

    addFailedItem = async (
        data: Record<string, unknown>,
        operation: string,
        service: string,
        error: string,
        addedTime?: number,
        groupId?: string,
        noOfItems?: number,
    ): Promise<RxDocument<IQueueItem>> => {
        const jsonDate = new Date().toJSON();
        const location = data.folderArr as string[];
        const elementType = (data.folderArr as string[])[0].toString().indexOf('Email') > -1 ? 'Email' : 'Document';

        // create queue Item
        const queueObj: IQueueItem = {
            uniqueId: uuidv4(),
            priority: Priority.NORMAL,
            data,
            status: QueueItemStatus.FAILED,
            service,
            operation,
            maxRetries: 0,
            markedForDelete: false,
            created: jsonDate,
            retryCount: 0,
            error: [error],
            response: [],
            location,
            elementType,
            groupId,
            noOfItems,
            eventForwarded: false,
            addedTime,
        };

        const props = await this.getProps();

        const cancelledObj = props.find((prop) => {
            return prop.key === CancelledTime;
        });

        // if cancelledTime is present
        // make it cancelled if the addedTi,e is less than createdTime
        if (cancelledObj && addedTime) {
            if (addedTime < parseInt(cancelledObj.value)) {
                queueObj.status = QueueItemStatus.CANCELLED;
            }
        }

        const queueItem: RxDocument<IQueueItem> = await this.db.queue.insert(queueObj);
        log.debug('added in queue for ' + queueItem.uniqueId);
        return queueItem;
    };

    // eslint-disable-next-line @typescript-eslint/ban-types
    addDocument = async (
        queueItem: RxDocument<IQueueItem>,
        id: string,
        data: Blob | Buffer,
        type: string,
    ): Promise<RxAttachment<IQueueItem>> => {
        usageLog.info('Invoked addDocument');
        // log.debug('Entered addDocument and adding attachment');

        const attachment = await queueItem.putAttachment(
            {
                id,
                data,
                type,
            },
            true,
        );

        log.debug('Added attachment for ' + queueItem.uniqueId);
        return attachment;
    };

    getDocument = async (queueItem: RxDocument<IQueueItem>, id: string): Promise<Blob | Buffer | null> => {
        usageLog.info('Invoked getDocument');
        // log.debug('getting Attachment for id' + id);
        const attachment = queueItem.getAttachment(id);
        // log.debug('Successfully got attachment');
        if (attachment) {
            const data = await attachment.getData();
            log.debug('Successfully got Data for ' + queueItem.uniqueId);

            return data;
        } else {
            return null;
        }
    };

    /**
     * Creating the observable for various states
     */
    _setupSyncAndObservables = (): void => {
        const statusActiveRemoved = [];
        statusActiveRemoved.push({ status: { $eq: QueueItemStatus.COMPLETED } });
        statusActiveRemoved.push({ status: { $eq: QueueItemStatus.FAILED } });
        statusActiveRemoved.push({ status: { $eq: QueueItemStatus.CANCELLED } });
        // For Actively Removed
        this.elementsActiveRemoved = this.db.queue
            .find({
                selector: {
                    $or: statusActiveRemoved,
                    processIndicator: { $ne: QueueItemProcess.FILEPATH_REMOVED },
                },
            })
            .sort({
                uniqueId: 'asc',
            }).$;

        this.elementsPaused = this.db.queue
            .find({
                selector: {
                    status: { $eq: QueueItemStatus.PAUSE },
                    markedForDelete: { $eq: false },
                },
            })
            .sort({
                uniqueId: 'asc',
            }).$;

        this.elementsCancelled = this.db.queue
            .find({
                selector: {
                    status: { $eq: QueueItemStatus.CANCELLED },
                    markedForDelete: { $eq: false },
                },
            })
            .sort({
                uniqueId: 'asc',
            }).$;

        this.elementsQueued = this.db.queue
            .find({
                selector: {
                    status: { $eq: QueueItemStatus.NEW },
                    markedForDelete: { $eq: false },
                },
            })
            .sort({
                uniqueId: 'asc',
            }).$;

        const statusSel = [];
        statusSel.push({ status: { $eq: QueueItemStatus.COMPLETED } });
        statusSel.push({ status: { $eq: QueueItemStatus.CANCELLED } });
        statusSel.push({ status: { $eq: QueueItemStatus.PAUSE } });
        statusSel.push({ status: { $eq: QueueItemStatus.FAILED } });
        statusSel.push({ status: { $eq: QueueItemStatus.NEW } });
        statusSel.push({ status: { $eq: QueueItemStatus.PENDING } });
        this.db.queue
            .find({
                selector: {
                    $or: statusSel,
                },
            })
            .$.subscribe(async (queue: IQueueItem[]) => {
                if (!queue) {
                    log.debug('No status changed');
                    return;
                }

                log.debug('Status changed - observable fired and processing the Queue');
                await this._processQueue();
            });
        this.elementsProcessing = this.db.queue
            .find({
                selector: {
                    status: { $eq: QueueItemStatus.PROCESSING },
                    markedForDelete: { $eq: false },
                },
            })
            .sort({
                uniqueId: 'asc',
            }).$;

        this.elementsPending = this.db.queue
            .find({
                selector: {
                    status: { $eq: QueueItemStatus.PENDING },
                    markedForDelete: { $eq: false },
                },
            })
            .sort({
                uniqueId: 'asc',
            }).$;

        this.elementsFailed = this.db.queue
            .find({
                selector: {
                    status: { $eq: QueueItemStatus.FAILED },
                    markedForDelete: { $eq: false },
                },
            })
            .sort({
                uniqueId: 'asc',
            }).$;

        this.elementsError = this.db.queue
            .find({
                selector: {
                    status: { $eq: QueueItemStatus.ERROR },
                    markedForDelete: { $eq: false },
                },
            })
            .sort({
                uniqueId: 'asc',
            }).$;

        this.elementsCompleted = this.db.queue
            .find({
                selector: {
                    status: { $eq: QueueItemStatus.COMPLETED },
                    markedForDelete: { $eq: false },
                },
            })
            .sort({
                uniqueId: 'asc',
            }).$;

        const statusStarted = [];
        statusStarted.push({ status: { $eq: QueueItemStatus.STARTED } });
        statusStarted.push({ status: { $eq: QueueItemStatus.PENDING } });

        this.elementsStarted = this.db.queue
            .find({
                selector: {
                    $or: statusStarted,
                    markedForDelete: { $eq: false },
                },
            })
            .sort({
                uniqueId: 'asc',
            }).$;

        this.elementsFlyout = this.db.queue
            .find({
                selector: {
                    status: {
                        $in: [QueueItemStatus.COMPLETED],
                    },
                    markedForDelete: { $eq: false },
                    eventForwarded: { $eq: false },
                    processIndicator: { $eq: QueueItemProcess.FILEPATH_REMOVED },
                },
            })
            .sort({
                uniqueId: 'asc',
            }).$;
    };

    /**
     *
     * @param queueItems
     * If queueItem status is COMPLETED or FAILED, eventForwarded sets true
     */
    updateFlyoutEvent = async (queueItem: RxDocument<IQueueItem>): Promise<RxDocument<IQueueItem>> => {
        log.debug('Invoked updateFlyout Event');
        const currentId = queueItem.uniqueId;
        try {
            await queueItem.update({
                $set: { eventForwarded: true },
            });

            log.debug('updateFlyoutEvent: set with value true  for ' + queueItem.uniqueId);
            // log.debug('updatePaused: AFTER: Status Update');
        } catch (err) {
            log.error('updateFlyoutEvent: set with value true  for ' + queueItem.uniqueId + ':' + err);
        }
        // log.debug('updatePaused: finding the queueItem for id' + currentId);
        const queryUpdate = this.db.queue.findOne().where('uniqueId').eq(currentId);
        const result = await queryUpdate.exec();

        return result;
    };

    /**
     *
     * @param maxRetries
     * @param numConcurrentElements
     */
    updateConfig: (maxRetries: number, numConcurrentElements: number) => Promise<IQueueConfig> = async (
        maxRetries: number,
        numConcurrentElements: number,
    ) => {
        usageLog.info('Invoked updateConfig');
        log.debug('updateConfig with maxRetries' + +'with nonCurrentElements' + numConcurrentElements);
        const result = await this.db.queueconfig.atomicUpsert({
            id: '1',
            maxRetries,
            numConcurrentElements,
        });
        log.debug('updateConfig updated row', result);
        return result;
    };

    /**
     * get queueItems which are pending
     */
    getElementsPending = async (): Promise<RxDocument<IQueueItem>[]> => {
        const query = this.db.queue
            .find({
                selector: {
                    status: { $eq: QueueItemStatus.PENDING },
                    markedForDelete: { $eq: false },
                },
            })
            .sort({
                uniqueId: 'asc',
            });
        return await query.exec();
    };

    /**
     * To set Started and Processing as Timeout Error
     * When starting so as to prevent the Queue getting struck
     */
    _updateNewWhenInitialize = async (): Promise<void> => {
        const queueItems = await this._getProcessedStartedElements();

        log.debug('Getting Processed and Started Elements when intialized ' + queueItems.length);

        const promises = queueItems.map((queueItem) => this.updateProcessingasNew(queueItem));
        await Promise.all(promises);

        log.debug('Updating all Started and Processing as New');
    };

    _getProcessedStartedElements = async (): Promise<RxDocument<IQueueItem>[]> => {
        const query = this.db.queue.find({
            selector: {
                $or: [{ status: { $eq: QueueItemStatus.STARTED } }, { status: { $eq: QueueItemStatus.PROCESSING } }],
            },
        });
        return await query.exec();
    };

    /**
     *
     * @param queueItem RxDocument<IQueueItem>
     */
    updateStarted = async (queueItem: RxDocument<IQueueItem>): Promise<RxDocument<IQueueItem>> => {
        usageLog.info('Invoked updateStarted');
        const currentId = queueItem.uniqueId;
        // log.debug('updateStarted: currentId: ' + currentId);
        try {
            // log.debug('updateStarted: BEFORE: Status Update');
            // TOOD: does updating this object cause the execute queue item to fail since the
            // object may not be valid anymore....
            await queueItem.update({
                $set: { status: QueueItemStatus.STARTED },
            });
            // log.debug('updateStarted: AFTER: Status Update');
        } catch (err) {
            // log.debug('updateStarted: status update error:');
            log.error('updateStarted: status update error for ' + queueItem.uniqueId + ':' + err);
        }
        // log.debug('updateStarted: finding the queueItem for id' + currentId);
        const queryUpdate = this.db.queue.findOne().where('uniqueId').eq(currentId);
        const result = await queryUpdate.exec();
        log.debug('Moved to Started for ' + queueItem.uniqueId);
        // log.debug(result);
        return result;
    };

    /**
     *
     * @param queueItems - Items to be cleared
     * If status is COMPLETED or FAILED markedForDelete set to true
     */
    updateCleared = async (queueItems: RxDocument<IQueueItem>[]): Promise<ISuccessAndFailedList> => {
        usageLog.info('Invoked updateCleared');
        const successList: ISuccessOrFailedItem[] = [];
        const failureList: ISuccessOrFailedItem[] = [];

        await Promise.all(
            queueItems.map(async (queueItem) => {
                const currentId = queueItem.uniqueId;
                // log.debug('updateCleared: currentId: ' + currentId);
                try {
                    // log.debug('updateCleared: BEFORE: Status Update');

                    if (queueItem.status === QueueItemStatus.COMPLETED || queueItem.status === QueueItemStatus.FAILED) {
                        await queueItem.update({
                            $set: { markedForDelete: true },
                        });
                        successList.push({
                            id: currentId,
                            reason: `Item Cleared sussessfully, status is: ${queueItem.status}`,
                        });
                    } else {
                        failureList.push({
                            id: currentId,
                            reason: `Unable to Clear item, only COMPLETED and FAILED items will be cleared, status is current item: ${queueItem.status}`,
                        });
                    }

                    // log.debug('updateCleared: AFTER: Status Update');
                } catch (err) {
                    log.debug('updateCleared: status update error:' + err);
                }
            }),
        );
        // this._processQueue();
        return { successList: successList, failureList: failureList };
    };

    /**
     *
     * @param queueItems - items to be New
     * if the items found to be started or processing when the leader starts
     * will be marked as New
     */
    updateProcessingasNew = async (queueItem: RxDocument<IQueueItem>): Promise<RxDocument<IQueueItem> | string> => {
        usageLog.info('Invoked updateProcessingasNew');
        const currentId = queueItem.uniqueId;
        log.debug(
            'updated New  as it was found to be Processing or Started when Leader was assigned ' + queueItem.uniqueId,
        );
        try {
            // TOOD: does updating this object cause the execute queue item to fail since the
            // object may not be valid anymore....
            await queueItem.update({
                $set: { status: QueueItemStatus.NEW },
            });
            log.debug('updateProcessingasNew for queueItem ' + queueItem.uniqueId);
        } catch (err) {
            log.error('updateProcessingasNew: status update error for ' + queueItem.uniqueId + ':' + err);
        }
        // log.debug('updateProcessingasNew: finding the queueItem for id' + currentId);
        const queryUpdate = this.db.queue.findOne().where('uniqueId').eq(currentId);
        const result = await queryUpdate.exec();

        // this._processQueue();
        return result;
    };

    /**
     * Cancel All queuitems in New State before the
     * Cancelled Time
     */
    updateCancelAll = async (): Promise<void> => {
        // 1)  set Props with the cancelled time
        // 2) cancel all the New items created before the cancelled time

        const time = Date.now();
        // 1)  set Props with the cancelled time

        await this.setProps(CancelledTime, time.toString());

        // 2) cancel all the New items created before the cancelled time

        const newElements = this.db.queue.find({
            selector: {
                addedTime: { $lt: time },
                status: { $eq: QueueItemStatus.NEW },
            },
        });

        const newElements_res: RxDocument<IQueueItem>[] = await newElements.exec();

        await Promise.all(
            newElements_res.map(async (queueItem) => {
                await queueItem.update({ $set: { status: QueueItemStatus.CANCELLED } });
            }),
        );
    };

    /**
     *
     * @param queueItems - items to be cancelled
     * if the status is NEW this can be changed as CANCELLED
     * else it will return error
     */
    updateCancelled = async (queueItems: RxDocument<IQueueItem>[]): Promise<ISuccessAndFailedList> => {
        usageLog.info('Invoked updateCancelled');
        const successList: ISuccessOrFailedItem[] = [];
        const failureList: ISuccessOrFailedItem[] = [];

        await Promise.all(
            queueItems.map(async (queueItem) => {
                const currentId = queueItem.uniqueId;
                // log.debug('updateCancelled: currentId: ' + currentId);
                try {
                    // log.debug('updateCancelled: BEFORE: Status Update');
                    // TOOD: does updating this object cause the execute queue item to fail since the
                    // object may not be valid anymore....

                    if (queueItem.status === QueueItemStatus.NEW) {
                        await queueItem.update({
                            $set: { status: QueueItemStatus.CANCELLED },
                        });
                        successList.push({
                            id: currentId,
                            reason: `Item Cancelled sussessfully, status is: ${queueItem.status}`,
                        });
                    } else {
                        failureList.push({
                            id: currentId,
                            reason: `unable to cancel item, only NEW items will be cancelled, status is current item: ${queueItem.status}`,
                        });
                    }
                    // log.debug('updateCancelled: AFTER: Status Update');
                } catch (err) {
                    log.debug('updateCancelled: status update error:' + err);
                }
            }),
        );
        // this._processQueue();
        return { successList: successList, failureList: failureList };
    };

    /**
     *
     * @param queueItem - item where processIndicator to be set
     * set the processIndicator status
     */
    updateProcessIndicator = async (
        queueItem: RxDocument<IQueueItem>,
        processIndicator: QueueItemProcess,
    ): Promise<RxDocument<IQueueItem>> => {
        usageLog.info('Invoked updateProcessIndicator');
        const currentId = queueItem.uniqueId;
        try {
            await queueItem.update({
                $set: { processIndicator: processIndicator },
            });

            log.debug('updateProcessIndicator: set with value ' + processIndicator + ' for ' + queueItem.uniqueId);
            // log.debug('updatePaused: AFTER: Status Update');
        } catch (err) {
            log.error('updateProcessIndicator status update error for ' + queueItem.uniqueId + ':' + err);
        }
        // log.debug('updatePaused: finding the queueItem for id' + currentId);
        const queryUpdate = this.db.queue.findOne().where('uniqueId').eq(currentId);
        const result = await queryUpdate.exec();

        return result;
    };

    /**
     *
     * @param queueItem - item to be Paused
     * if the status is NEW or PROCESSING this can be changed as Paused
     * else it will return Error
     */
    updatePaused = async (queueItem: RxDocument<IQueueItem>): Promise<RxDocument<IQueueItem> | string> => {
        usageLog.info('Invoked updatePaused');
        const currentId = queueItem.uniqueId;
        // log.debug('updatePaused: currentId: ' + currentId);
        try {
            // log.debug('updatePaused: BEFORE: Status Update');
            // TOOD: does updating this object cause the execute queue item to fail since the
            // object may not be valid anymore....

            if (queueItem.status === QueueItemStatus.NEW || queueItem.status === QueueItemStatus.PROCESSING) {
                await queueItem.update({
                    $set: { status: QueueItemStatus.PAUSE },
                });
            } else {
                return STATUS_CANNOT_BE_CHANGED;
            }
            log.debug('updatePaused: for ' + queueItem.uniqueId);
            // log.debug('updatePaused: AFTER: Status Update');
        } catch (err) {
            log.error('updatePaused status update error for ' + queueItem.uniqueId + ':' + err);
        }
        // log.debug('updatePaused: finding the queueItem for id' + currentId);
        const queryUpdate = this.db.queue.findOne().where('uniqueId').eq(currentId);
        const result = await queryUpdate.exec();

        // log.debug(result);
        // this._processQueue();
        return result;
    };

    /**
     *
     * @param queueItem - item to be Resumed
     * if the status is Paused this can be New
     * else it will return Error
     */
    updateResumed = async (queueItem: RxDocument<IQueueItem>): Promise<RxDocument<IQueueItem> | string> => {
        usageLog.info('Invoked updateResumed');
        const currentId = queueItem.uniqueId;
        // log.debug('updateResumed: currentId: ' + currentId);
        try {
            // log.debug('updateResumed: BEFORE: Status Update');
            // TOOD: does updating this object cause the execute queue item to fail since the
            // object may not be valid anymore....

            if (queueItem.status === QueueItemStatus.PAUSE) {
                await queueItem.update({
                    $set: { status: QueueItemStatus.NEW },
                });
            } else {
                return STATUS_CANNOT_BE_CHANGED;
            }
            log.debug('updateResumed for ' + queueItem.uniqueId);
            // log.debug('updateResumed: AFTER: Status Update');
        } catch (err) {
            log.error('updateResumed status update error for ' + queueItem.uniqueId + ':' + err);
            // log.debug(err);
        }
        log.debug('updateResumed: finding the queueItem for id' + currentId);
        const queryUpdate = this.db.queue.findOne().where('uniqueId').eq(currentId);
        const result = await queryUpdate.exec();

        // log.debug(result);
        // this._processQueue();
        return result;
    };

    /**
     * Update the queueItem as Pending
     * @param queueItem
     */
    updatePending = async (queueItem: RxDocument<IQueueItem>): Promise<RxDocument<IQueueItem>> => {
        usageLog.info('Invoked updatePending');
        const currentId = queueItem.uniqueId;
        // log.debug('updatePending: currentId: ' + currentId);
        try {
            // log.debug('updatePending: BEFORE: Status Update');
            // TOOD: does updating this object cause the execute queue item to fail since the
            // object may not be valid anymore....
            await queueItem.update({
                $set: { status: QueueItemStatus.PENDING },
            });
            log.debug('updatePending for ' + queueItem.uniqueId);
            // log.debug('updatePending: AFTER: Status Update');
        } catch (err) {
            log.error('updatePending status update error for ' + queueItem.uniqueId + ':' + err);
            // log.debug(err);
        }
        log.debug('updatePending: finding the queueItem for id' + currentId);
        const queryUpdate = this.db.queue.findOne().where('uniqueId').eq(currentId);
        const result = await queryUpdate.exec();

        // this._processQueue();
        return result;
    };

    /**
     * Update the queueItem as Completed
     * @param queueItem
     * @param responseObj
     */
    updateCompleted = async (
        queueItem: RxDocument<IQueueItem>,
        responseObj: Response,
    ): Promise<RxDocument<IQueueItem>> => {
        usageLog.info('Invoked updateCompleted');
        const currentId = queueItem.uniqueId;
        // log.debug('updateCompleted: currentId: ' + currentId);
        try {
            // log.debug('updateCompleted: BEFORE: Status Update');
            // TOOD: does updating this object cause the execute queue item to fail since the
            // object may not be valid anymore....
            await queueItem.update({
                $set: { status: QueueItemStatus.COMPLETED },
                $push: { response: responseObj },
            });
            log.debug('updateCompleted for ' + queueItem.uniqueId);
            // log.debug('updateCompleted: AFTER: Status Update');
        } catch (err) {
            log.error('updateCompleted status update error for ' + queueItem.uniqueId + ':' + err);
            // log.debug(err);
        }
        log.debug('updateCompleted: finding the queueItem for id' + currentId);
        const queryUpdate = this.db.queue.findOne().where('uniqueId').eq(currentId);
        const result = await queryUpdate.exec();

        // this._processQueue();
        return result;
    };

    /**
     * Update the queueItem as Error
     * @param queueItem
     * @param responseObj
     */
    updateError = async (
        queueItem: RxDocument<IQueueItem>,
        error: string,
        failedStatus = false,
    ): Promise<RxDocument<IQueueItem>> => {
        usageLog.info('Invoked updateError');
        const currentId = queueItem.uniqueId;
        // log.debug('updateError: currentId: ' + currentId);
        try {
            // log.debug('updateError: BEFORE: Status Update');
            // TOOD: does updating this object cause the execute queue item to fail since the
            // object may not be valid anymore....
            if (queueItem.maxRetries && queueItem.retryCount < queueItem.maxRetries && !failedStatus) {
                log.debug('Retrying due to failure for ' + queueItem.uniqueId);
                await queueItem.update({
                    $set: { status: QueueItemStatus.NEW },
                    $inc: {
                        retryCount: 1,
                    },
                    $push: { error: error },
                });
            } else {
                log.debug('Failed after retrying ' + queueItem.uniqueId);
                await queueItem.update({
                    $set: { status: QueueItemStatus.FAILED },
                    $push: { error: error },
                });
            }
            log.debug('updateError for ' + queueItem.uniqueId);
            // log.debug('updateError: AFTER: Status Update');
        } catch (err) {
            log.error('updateError status update error for ' + queueItem.uniqueId + ':' + err);
        }
        // log.debug('updateError: finding the queueItem for id' + currentId);
        const queryUpdate = this.db.queue.findOne().where('uniqueId').eq(currentId);
        const result = await queryUpdate.exec();

        // this._processQueue();
        return result;
    };

    _updateQueueItemStatus = async (
        queueItem: RxDocument<IQueueItem>,
        newStatus: QueueItemStatus,
    ): Promise<RxDocument<IQueueItem>> => {
        const currentId = queueItem.uniqueId;
        // log.debug('updateQueueItemStatus: currentId: ' + currentId);
        try {
            // log.debug('updateQueueItemStatus: BEFORE: Status Update');
            // TOOD: does updating this object cause the execute queue item to fail since the
            // object may not be valid anymore....
            if (this.queueStatus !== QueueStatus.PAUSED) {
                await queueItem.update({
                    $set: { status: newStatus },
                });
                log.debug('update Status as ' + newStatus + ' for ' + queueItem.uniqueId);
                // log.debug('updateQueueItemStatus: AFTER: Status Update');
            } else {
                log.debug('Not updating as Queue is Paused');
            }
        } catch (err) {
            log.debug('updateQueueItemStatus: status update error for ' + queueItem.uniqueId);
        }

        const queryUpdate = this.db.queue.findOne().where('uniqueId').eq(currentId);
        const result: RxDocument<IQueueItem> = await queryUpdate.exec();
        // log.debug('updateQueueItemStatus: result is: ');
        // console.log(result.toJSON());
        return result;
    };

    _peekQueueItemToProcess = async (): Promise<RxDocument<IQueueItem> | null> => {
        log.debug('moving inside _peekQueueItemToProcess');
        try {
            const query = this.db.queue.findOne().where('status').eq(QueueItemStatus.NEW).sort({
                priority: 'desc',
            });
            const queueItem: RxDocument<IQueueItem> = await query.exec();
            if (queueItem) {
                log.debug('Picked the New queueItem  ' + queueItem.uniqueId);
            }

            if (queueItem === null) {
                log.debug('No queue items found in New Status');
                return null;
            }
            return queueItem;
        } catch (err) {
            throw Error('Error in _peekQueueItemToProcess' + err);
        } finally {
            // done();
        }
    };
    // return queueItem;

    _setQueueStatus = (status: QueueStatus): void => {
        // Status not equal to QueueStatus PAUSED
        // Existing queue Status is not PAUSED
        if (status === QueueStatus.PAUSED || this.queueStatus !== QueueStatus.PAUSED) {
            log.debug('setting Queue status to ' + status);
            this.queueStatus = status;
        } else {
            log.debug('Not Setting Queue to ' + status + ' as Queue is Paused');
        }
    };

    /**
     * Check Process Queue - if the QueueItemStatus Started
     * or PROCESSING count < 2
     */
    _checkProcessQueue = async (): Promise<boolean> => {
        const result = await this.getConfig();

        const queryResult = await this._getProcessedStartedElements();
        // log.debug('queueu count' + queryResult.length);
        // queryResult.map((queueItem: RxDocument<IQueueItem>) => {
        //   // console.log(
        //   //   "check processss11111",
        //   //   queueItem.operation,
        //   //   queueItem.status
        //   // );
        //   return queueItem;
        // });

        // Allow the process Queue only when the
        // numConcurrentElements!=0
        // and STARTED abd PROCESSING Count less than and
        // equal to numConcurrentElements
        if (
            result.numConcurrentElements !== 0 &&
            queryResult.length < result.numConcurrentElements &&
            this.queueStatus !== QueueStatus.PAUSED
        ) {
            log.debug(
                'Check Process Queue with items in Process ' +
                    queryResult.length +
                    ' Concurrent Elements ' +
                    result.numConcurrentElements +
                    ' returned : true',
            );
            return true;
        } else {
            log.debug(
                'Check Process Queue with items in Process ' +
                    queryResult.length +
                    ' Concurrent Elements ' +
                    result.numConcurrentElements +
                    ' returned : false',
            );
            return false;
        }
    };

    getPendingQueueItems = async (): Promise<void> => {
        const newElements = this.db.queue.find({
            selector: {
                status: { $eq: QueueItemStatus.NEW },
                markedForDelete: { $eq: false },
            },
        });

        const newElements_res = await newElements.exec();

        log.debug('No of items in New State Pending to be Picked up: ' + newElements_res.length);
        const statusSel = [];
        statusSel.push({ status: { $eq: QueueItemStatus.PROCESSING } });
        statusSel.push({ status: { $eq: QueueItemStatus.STARTED } });

        const processElements = this.db.queue.find({
            selector: {
                $or: statusSel,
                markedForDelete: { $eq: false },
            },
        });

        const processElements_res = await processElements.exec();

        log.debug('No of items in Processing or Started State: ' + processElements_res.length);
    };

    /**
     * for
     */
    getConfig: () => Promise<RxDocument<IQueueConfig>> = async (): Promise<RxDocument<IQueueConfig>> => {
        let result;
        try {
            const query = await this.db.queueconfig.findOne().sort({
                id: 'desc',
            });

            result = await query.exec();
            log.debug('getConfig result' + JSON.stringify(result));
            return result;
        } catch (err) {
            log.error('getConfig resulted in error', err);
            throw new Error('getConfig returned error' + err);
        }
        // return result;
    };

    _processQueue = async (): Promise<void> => {
        // setTimeout(async () => {
        // log.debug("in timer. kickIt = " + kickIt);
        log.debug('Trying to process the queue if leader and queue status is stopped ');
        log.debug('the leader value for this instance is ' + this.isLeader);
        log.debug('queue status is ' + this.queueStatus);

        if (this.isLeader && this.queueStatus === QueueStatus.STOPPED) {
            this._setQueueStatus(QueueStatus.RUNNING);
            // set timer before all updates????
            log.debug('RUNNING QUEUE');

            // if kickstart is true run through queue again!
            let queueItem = await this._peekQueueItemToProcess();

            while (queueItem != null && (await this._checkProcessQueue())) {
                await this._updateQueueItemStatus(queueItem, QueueItemStatus.PROCESSING);
                queueItem = await this._peekQueueItemToProcess();
            }

            this._setQueueStatus(QueueStatus.STOPPED);
            if (queueItem != null) {
                log.debug('Queue is waiting for the elements to be finished before Processing new elements');
            } else {
                log.debug('No New queueItem to be processed');
            }
            await this.getPendingQueueItems();
        } else {
            if (!this.isLeader) {
                log.debug('Not Leader so not processing the queue.Please check the leader window');
            }
        }
    };
}
