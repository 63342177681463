import { ITransformer } from './types';
import { ICHCheckOut } from '../interfaces/operations';
import { ICheckOut } from '@wk/elm-uui-doc-component';
import 'reflect-metadata';
import { injectable } from 'inversify';

@injectable()
export class CtxtCheckOutTransformer implements ITransformer {
    data!: ICHCheckOut;

    transform(): ICheckOut {
        const nodeObj = {} as ICheckOut;
        nodeObj.docId = this.data.documentId;
        nodeObj.entityContext = {
            name: this.data.associatedEntityName || '',
            id: this.data.associatedEntityId,
            type: this.data.associatedEntityType,
        };
        nodeObj.extendedProps = {};
        nodeObj.extendedProps.loggedInUser = this.data?.loggedInUser;

        return nodeObj;
    }
}
