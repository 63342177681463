import { injectable } from 'inversify';
import { ISecurityContextHandler } from '../interfaces/interface';
import { Observable } from 'rxjs';

@injectable()
export class SecurityUIContextHandler implements ISecurityContextHandler {
    supportedOperations = async (): Promise<string[]> => await ['securityUIContextHandler'];
    performOperation = async (): Promise<string[]> => await ['securityUIContextHandler'];
    getContextInfo = async (): Promise<string[]> => await ['securityUIContextHandler'];

    // TO DO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public setToken(_id: string, _secret: string, _token: string): Observable<any> {
        throw new Error('Not Supported');
    }

    // TO DO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getToken(_id: string, _secret: string): Observable<any> {
        throw new Error('Not Supported');
    }

    // TO DO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public deleteToken(_id: string, _secret: string): Observable<any> {
        throw new Error('Not Supported');
    }
}
