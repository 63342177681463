import { AdapterEnum } from '../enums/adapter';
import { DMEnum } from '../enums/dm';
import { AuthSchemeEnum } from '../enums/authScheme';

export const adapterSwitch: Record<string, AdapterEnum> = {
    JR: AdapterEnum.JRADAPTER,
    DOC_SVC: AdapterEnum.DOCSERVICE,
    T360: AdapterEnum.T360,
};

export const dmSwitch: Record<string, DMEnum> = {
    SPOL: DMEnum.SHAREPOINT,
    EDOCS: DMEnum.EDOCS,
};

export const authSwitch: Record<string, AuthSchemeEnum> = {
    ACCESS_TOKEN: AuthSchemeEnum.OAUTH,
    BASIC_AUTH: AuthSchemeEnum.BASIC_AUTHENTICATION,
    SAML: AuthSchemeEnum.SAML,
};
