import { Breadcrumbs } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import React, { useMemo } from 'react';
import { useReduxDispatch } from '../../hooks/useReduxDispatch';
import { useUUIHistory } from '../../hooks/useUUIHistory';
import { useUUILocation } from '../../hooks/useUUILocation';
import { UUITooltip } from '../common/uuiTooltip';
import { dispatchLoadFolderFromBreadcrumb } from './context/listScreenAsyncActions';
import { useListScreenDispatch, useListScreenState } from './context/listScreenContext';
import { useResponsiveBreadCrumbs } from './context/listScreenHooks';
import css from './listScreenBreadCrumbs.module.scss';
import ListScreenBreadCrumbsMenu from './listScreenBreadCrumbsMenu';
import { getIsListRowBreadCrumbVisible } from './listScreenHelpers';
import { IFolderedListBreadCrumb } from './types';

export const ListScreenBreadCrumbs: React.FC = () => {
    const listScreenState = useListScreenState();
    const listScreenDispatch = useListScreenDispatch();

    const reduxDispatch = useReduxDispatch();
    const location = useUUILocation();
    const history = useUUIHistory();
    const isListRowBreadCrumbVisible = getIsListRowBreadCrumbVisible(listScreenState);
    const { metadata, breadCrumbs } = listScreenState;

    const listScreenBreadCrumbs = useMemo(() => {
        const result = metadata?.folderPath?.reduce((agg, folder) => {
            const breadcrumbObject = breadCrumbs.find(
                ({ folderId, folderName }) =>
                    (folderId && folderId === folder.id.toString()) || (folderName && folderName === folder.value),
            );

            if (breadcrumbObject) {
                breadcrumbObject.folderName = folder.value;
                agg.push(breadcrumbObject);
            }

            return agg;
        }, [] as IFolderedListBreadCrumb[]);

        return (result?.length && result) || breadCrumbs;
    }, [metadata, breadCrumbs]);

    const breadCrumbList = useResponsiveBreadCrumbs(listScreenBreadCrumbs);
    if (listScreenBreadCrumbs.length <= 1 && !isListRowBreadCrumbVisible) {
        return null;
    }

    const isLastBreadcrumb = (i: number) => i === breadCrumbList.length - 1;

    const handleOnClick = (event: any, breadCrumb: IFolderedListBreadCrumb, index: number) => {
        if (index === 0 || isLastBreadcrumb(index)) {
            event.stopPropagation();
        } else {
            dispatchLoadFolderFromBreadcrumb(
                breadCrumb,
                listScreenState,
                listScreenDispatch,
                reduxDispatch,
                location,
                history,
            );
        }
    };

    return (
        <Breadcrumbs
            aria-label="folder breadcrumbs"
            separator={<NavigateNextIcon fontSize="small" />}
            classes={{ ol: css.ol, li: css.li, separator: css.separator }}>
            {breadCrumbList.map((breadCrumb, i) => {
                return (
                    <div
                        key={i}
                        data-testid="breadcrumbs-item"
                        onClick={!isLastBreadcrumb(i) ? (e) => handleOnClick(e, breadCrumb, i) : undefined}
                        role={isLastBreadcrumb(i) ? undefined : 'button'}
                        tabIndex={isLastBreadcrumb(i) ? -1 : 0}
                        className={css.breadcrumbName}>
                        {i === 0 && breadCrumbList.length > 1 ? (
                            <ListScreenBreadCrumbsMenu breadCrumb={breadCrumb} />
                        ) : (
                            <UUITooltip leaveDelay={200} title={breadCrumb.folderName} placement="top-start">
                                <div className={css.bcTooltip}>{breadCrumb.folderName}</div>
                            </UUITooltip>
                        )}
                    </div>
                );
            })}
        </Breadcrumbs>
    );
};
