import { injectable, inject } from 'inversify';
import { apiRestFetchGet } from '../../restApi/fetch.utils';
import { JRAdapterActionPerformer } from './actionPerformer';
import * as convert from 'xml-js';
import { convertToMapMetaData } from '../../utils/main.utils';
import { matchMetadata } from '../utils/maps';
import { IDocOperationInvocation } from '../../interfaces/urlPattern/types';
import { DCResponse } from '../../types';
import { getLatestVersion } from '../utils/utils';
import { JRAdapterDocumentMetadataActionableEntity } from '../actionableEntity/documentMetadataActionableEntity';

@injectable()
export class JRAdapterDocumentMetadataActionPerformer extends JRAdapterActionPerformer {
    fetchRequest = apiRestFetchGet;

    @inject('JRAdapterDownloadDocumentUrlPattern') public downloadUrl: IDocOperationInvocation;
    @inject('JRAdapterDownloadVersionDocumentUrlPattern') public downloadUrlVersion: IDocOperationInvocation;

    async performAction(
        obj: JRAdapterDocumentMetadataActionableEntity,
        urlObj: Promise<string>,
        urlPatternObj: IDocOperationInvocation,
    ): Promise<DCResponse> {
        const response = await super.performAction(obj, urlObj, urlPatternObj);
        const outputInfo = async () => {
            const xmlData = await response.text();

            const dataObj = JSON.parse(convert.xml2json(xmlData));

            if ('elements' in dataObj['elements'][0]) {
                const jsonArray = dataObj['elements'][0]['elements'][0]['elements'];

                const jsonObject: Record<string, string> = {};

                jsonArray.forEach((command: { [x: string]: { [x: string]: string } }) => {
                    jsonObject[command['attributes']['name']] = command['attributes']['value'];
                });
                if (obj?.extendedProps && obj.extendedProps?.version) {
                    this.downloadUrlVersion.hostName = urlPatternObj?.hostName;
                    this.downloadUrlVersion.objectRelative = urlPatternObj?.objectRelative;
                    jsonObject.downloadUrl = await this.downloadUrlVersion.getUrl(obj);
                } else {
                    this.downloadUrl.hostName = urlPatternObj?.hostName;
                    this.downloadUrl.tokenHandler = this.tokenHandler;

                    this.downloadUrl.objectRelative = urlPatternObj?.objectRelative;

                    jsonObject.downloadUrl = await this.downloadUrl.getUrl(obj);
                }

                const latestVersion = await getLatestVersion(
                    urlPatternObj?.hostName + '/' + urlPatternObj?.objectRelative,
                    obj,
                    await this.tokenHandler.getAuthInfo(),
                );

                jsonObject.latestVersion = latestVersion;
                return convertToMapMetaData(matchMetadata, jsonObject);
            } else {
                return convertToMapMetaData(matchMetadata, { id: '0' });
            }
        };

        const response2: DCResponse = Object.assign(new DCResponse(), response);
        response2.outputInfo = outputInfo;
        return response2;
    }
}
