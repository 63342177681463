import {
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Tooltip,
    Typography,
} from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { CHSupportedDocumentTypesEnum, ICHUploadResponse } from '@wk/elm-uui-context-handler';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import useCancellablePromise from '../../hooks/useCancellablePromise';
import { useHomePageUrl } from '../../hooks/useHomePageUrl';
import useIsMounted from '../../hooks/useIsMounted';
import { useReduxDispatch } from '../../hooks/useReduxDispatch';
import { useReduxSelector } from '../../hooks/useReduxSelector';
import { useUUIHistory } from '../../hooks/useUUIHistory';
import IconMapper, { iconMapping } from '../common/iconMapper';
import { UUIFlyoutMenu, UUIFlyoutMenuButton, UUIFlyoutMenuItem } from '../common/uuiFlyoutMenu';
import { handleContextLayerPerformQuickFile } from '../contextLayerService/contextLayerHelpers';
import { isOC } from '../contextLayerService/contextLayerService';
import WkNewQuickFileIcon from '../icons/wkNewQuickFileIcon';
import { getHistoryStackForCurrentOCInstance } from '../../utils/ocLocationService';
import { dispatchPerformOperation } from './context/itemScreenAsyncActions';
import { useItemScreenState } from './context/itemScreenContext';
import css from './itemScreenHeader.module.scss';
import { getActiveParentAndSubTabMeta, getOperationsForMode } from './itemScreenHelpers';
import { IItemScreenOperation } from './types';

const ItemScreenHeader: React.FC = () => {
    const itemScreenState = useItemScreenState();
    const itemScreenJson = itemScreenState.itemScreenJson!;
    const history = useUUIHistory();
    const homePageUrl = useHomePageUrl();

    if (itemScreenState.renderingStyle !== 'normal') {
        return null;
    }

    const onBackChevronClick = () => {
        if (itemScreenState.viewState) {
            itemScreenState.viewState.activeTab = 0;
        }

        const historyStack = getHistoryStackForCurrentOCInstance();
        if (!isOC() || (historyStack && historyStack.length > 1)) {
            history.goBack();
        } else {
            history.push(homePageUrl);
        }
    };

    return (
        <List className={css.secondaryContainer} id={'itemscreenheader'}>
            <ListItem className={css.header} ContainerComponent="div">
                {itemScreenState.mode === 'add' && (
                    <div>
                        <IconButton
                            className={css.leftChevron}
                            edge="start"
                            size="small"
                            color="primary"
                            aria-label="back"
                            onClick={onBackChevronClick}
                            data-testid="leftChevron">
                            <NavigateBeforeIcon fontSize="large" />
                        </IconButton>
                        <ListItemSecondaryAction>
                            <ItemScreenOperationsMenu />
                        </ListItemSecondaryAction>
                    </div>
                )}
                {itemScreenState.mode !== 'add' && (
                    <ListItemText
                        primary={
                            <>
                                <div className={css.leftChevronAndTextContainer}>
                                    <IconButton
                                        className={css.leftChevron}
                                        edge="start"
                                        size="small"
                                        color="primary"
                                        aria-label="back"
                                        onClick={onBackChevronClick}
                                        data-testid="leftChevron">
                                        <NavigateBeforeIcon fontSize="large" />
                                    </IconButton>
                                    <Tooltip
                                        title={
                                            itemScreenJson.metadata.pageTitle.length > 40
                                                ? itemScreenJson.metadata.pageTitle
                                                : ''
                                        }
                                        enterDelay={500}
                                        classes={{
                                            tooltip: css.tooltip,
                                        }}>
                                        <Typography
                                            className={
                                                itemScreenState.enableQFIcon ||
                                                    itemScreenJson.metadata.operations.length > 0
                                                    ? css.primaryText
                                                    : css.primaryTextWithOutOpsQF
                                            }
                                            variant="h6"
                                            noWrap
                                            data-testid="pageTitle">
                                            {itemScreenJson.metadata.pageTitle}
                                        </Typography>
                                    </Tooltip>
                                </div>
                                <ItemScreenOperationsMenu />
                            </>
                        }
                        primaryTypographyProps={{
                            className:
                                itemScreenJson.metadata.pageSubTitle || itemScreenJson.metadata.pageTag
                                    ? css.primary
                                    : css.primaryNoSubtitleNoTag,
                        }}
                        secondary={
                            <ItemScreenSubTitleAndTag
                                subTitle={itemScreenJson.metadata.pageSubTitle}
                                tag={itemScreenJson.metadata.pageTag}
                            />
                        }
                        secondaryTypographyProps={{ className: css.secondary }}
                    />
                )}
            </ListItem>
        </List>
    );
};

interface IItemScreenSubTitleAndTagProps {
    subTitle?: string;
    tag?: string;
}

const ItemScreenSubTitleAndTag: React.FC<IItemScreenSubTitleAndTagProps> = ({ subTitle, tag }) => {
    if (!subTitle && !tag) {
        return null;
    }
    const subTitleElement =
        subTitle && subTitle.length > 0 ? (
            <Tooltip
                title={subTitle.length > 20 ? subTitle : ''}
                enterDelay={500}
                classes={{
                    tooltip: css.tooltip,
                }}>
                <span className={tag ? css.subtitleHalf : css.subtitleFull} data-testid="subTitle">
                    {subTitle}
                </span>
            </Tooltip>
        ) : undefined;
    const tagElement =
        tag && tag.length > 0 ? (
            <Tooltip
                title={tag.length > 20 ? tag : ''}
                enterDelay={500}
                classes={{
                    tooltip: css.tooltip,
                }}>
                <span className={subTitle ? css.tagHalf : css.tagFull} data-testid="tag">
                    {tag}
                </span>
            </Tooltip>
        ) : undefined;
    return (
        <span className={css.subTitleAndTagContainer}>
            {subTitleElement}
            {tagElement}
        </span>
    );
};

export const ItemScreenOperationsMenu: React.FC = () => {
    const applicationUrls = useReduxSelector((state) => state.applicationUrls);
    const appResources = useReduxSelector((state) => state.appResources);
    const reduxDispatch = useReduxDispatch();
    const itemScreenState = useItemScreenState();
    const itemScreenJson = itemScreenState.itemScreenJson!;
    const formMethods = useFormContext();
    const isMounted = useIsMounted();
    const history = useUUIHistory();
    const cancellablePromise = useCancellablePromise<ICHUploadResponse | ICHUploadResponse[]>();

    const performOperation = (operation: IItemScreenOperation) => {
        dispatchPerformOperation(
            operation,
            itemScreenState,
            reduxDispatch,
            applicationUrls,
            appResources,
            isMounted,
            formMethods,
            history,
        );
    };

    const clickQuickFile = () => {
        const [activeParentTab, activeSubTab] = getActiveParentAndSubTabMeta(itemScreenState);
        const activeTab = activeSubTab || activeParentTab;
        const currentItemScreenIsDocumentEntity = itemScreenJson.metadata.isDocumentEntity;
        const docTypeForQF = itemScreenState.docTypeForQF;
        const activeTabIsDocumentsTab =
            activeTab?.isDocumentOrEmailTab && !activeTab.entityName?.toLowerCase().includes('email');
        const activeTabIsEmailsTab =
            activeTab?.isDocumentOrEmailTab && activeTab.entityName?.toLowerCase().includes('email');

        let entityTypeId: string | undefined;
        let folderId: string | undefined;
        let breadcrumbs: string[] | undefined;

        // only set entityTypeId if we are on a doc summary screen OR we are on matter summary and looking at the Emails or Documents tab with the cooresponding
        // office application open. Otherwise, CL will figure it out for us.
        if (currentItemScreenIsDocumentEntity) {
            entityTypeId = itemScreenState.itemScreenJson!.metadata.entityId.toString();
            breadcrumbs = itemScreenState.itemScreenJson!.metadata.folderPath;
        } else if (
            (activeTabIsEmailsTab && docTypeForQF === CHSupportedDocumentTypesEnum.EMAIL) ||
            (activeTabIsDocumentsTab && docTypeForQF === CHSupportedDocumentTypesEnum.DOCUMENT)
        ) {
            entityTypeId = activeTab.entityTypeId;
            folderId = activeTab.folderId?.toString();
            breadcrumbs = activeTab.breadCrumbs;
        }

        handleContextLayerPerformQuickFile({
            // Ex: Matter
            associatedEntityType: currentItemScreenIsDocumentEntity
                ? itemScreenJson.metadata.associatedEntityType!
                : itemScreenJson.metadata.entityName.toString(),
            // Ex: Matter ID
            associatedEntityTypeId: currentItemScreenIsDocumentEntity
                ? itemScreenJson.metadata.associatedEntityTypeId!
                : itemScreenJson.metadata.entityId.toString(),
            // Ex: Cooper Matter Instance ID
            associatedEntityId: currentItemScreenIsDocumentEntity
                ? itemScreenJson.metadata.associatedEntityId!
                : itemScreenJson.item.id!.toString(),
            //
            associatedEntityName: currentItemScreenIsDocumentEntity
                ? itemScreenJson.metadata.associatedEntityName!
                : itemScreenJson.metadata.objDisplayValue,
            // see note above for entityTypeId
            entityTypeId,
            cancellablePromise,
            // only pass folder id if the active tab metadata has one which means user is on the documents tab with a subfolder open
            folderId,
            // pass folderArr if we have a folderId or if we are on a document summary item page
            folderArr: breadcrumbs,
            // only pass documentId if we are on a document summary screen, so CL will create a new version
            documentId: itemScreenJson.metadata.isDocumentEntity ? itemScreenJson.item.id!.toString() : undefined,
            loggedInUser: appResources.username,
            reduxDispatch,
        });
    };

    const operationsForMode = getOperationsForMode(itemScreenJson.metadata.operations, itemScreenState.mode);

    if (
        (operationsForMode.length == 0 ||
            itemScreenState.renderingStyle === 'popup' ||
            itemScreenState.renderingStyle === 'inline') &&
        !itemScreenState.enableQFIcon
    ) {
        return null;
    }

    const hasIcons =
        operationsForMode.findIndex((operation) => {
            return (
                operation.iconName !== '' && iconMapping[operation.iconName!.trim().toLocaleLowerCase()] !== undefined
            );
        }) > -1;

    return (
        <div className={css.opsMenuIconContainer}>
            {itemScreenState.enableQFIcon && (
                <Tooltip
                    enterDelay={500}
                    placement="bottom"
                    title={appResources.listscreenQuickFileToolTip}
                    classes={{
                        tooltip: css.tooltip,
                    }}>
                    <IconButton
                        data-testid="itemscreen-quickFile-summary"
                        edge="end"
                        aria-label="details"
                        color="primary"
                        className={
                            itemScreenJson.metadata.operations.length > 0 && operationsForMode.length !== 0
                                ? css.fileIconContainer
                                : css.noKebobFilesContainer
                        }
                        onClick={clickQuickFile}>
                        <WkNewQuickFileIcon className={css.quickFileIcon} />
                    </IconButton>
                </Tooltip>
            )}
            {itemScreenJson.metadata.operations.length > 0 && operationsForMode.length !== 0 && (
                <UUIFlyoutMenu anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <UUIFlyoutMenuButton
                        aria-label="operations"
                        data-testid="itemscreen-operations-menu-button"
                        edge="end"
                    />
                    {operationsForMode.map((operation) => (
                        <UUIFlyoutMenuItem
                            key={operation.id}
                            icon={
                                hasIcons ? (
                                    <div>
                                        <IconMapper iconName={operation.iconName!} />
                                    </div>
                                ) : undefined
                            }
                            tooltipText={operation.displayName}
                            data-testid={'kabob-menu-item-operation' + operation.id}
                            onClick={() => {
                                performOperation(operation);
                            }}
                            isWordWrapped={true}>
                            {operation.displayName}
                        </UUIFlyoutMenuItem>
                    ))}
                </UUIFlyoutMenu>
            )}
        </div>
    );
};

export default ItemScreenHeader;
