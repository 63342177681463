import { IDocTransformer } from '../../interfaces/transformer/type';
import { IActionableEntity } from '../../interfaces/actionEntity/type';
import { injectable } from 'inversify';
import { factory } from '../../ConfigLog4j';
import { IDocumentMetadataFileName } from '../../types';
import { T360AdapterDocumentFileNameMetadataActionableEntity } from '../actionableEntity/documentMetadataFileNameActionableEntity';

const log = factory.getLogger(' T360AdapterDocumentFileNameMetadataEntityTransformer');

@injectable()
export class T360AdapterDocumentFileNameMetadataEntityTransformer implements IDocTransformer {
    // eslint-disable-next-line no-empty-pattern
    transformIntoActionableEntity(nodeObj: IDocumentMetadataFileName, {} = {}, {} = {}): IActionableEntity {
        log.debug('Calling transformIntoActionableEntity');
        const obj = {} as T360AdapterDocumentFileNameMetadataActionableEntity;

        if (nodeObj?.documentName) {
            obj.documentName = nodeObj?.documentName;
        }

        if (nodeObj?.fileName) {
            obj.fileName = nodeObj?.fileName;
        }
        // TO CREATE ACTIONABLE ENTITY FROM nodeObj and matterDoc
        return obj;
    }
}
