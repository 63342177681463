import { OfficeContext } from '../common/officeContext';
import { Application } from './application';
import { Presentation } from './presentation';
import { RequestType } from '../comObject';

export class PowerPointContext extends OfficeContext {
    /** returns powerpoint application object */
    public get application(): Application {
        return this.reference.requestObject({
            type: RequestType.GetProperty,
            creator: Application,
            name: 'Application',
            cacheName: 'application',
            isReleasable: false,
        }).value;
    }

    /** returns currently open powerpoint presentation */
    public get currentPresentation(): Presentation {
        return this.reference.requestObject({
            type: RequestType.Invoke,
            creator: Presentation,
            name: 'GetCurrentPresentation',
        }).value;
    }
}
