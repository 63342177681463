import { getLogger } from './utils/loggingService';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const reduxLogger = () => (next: any) => (action: any) => {
    const logger = getLogger('redux');
    try {
        logger.info(`Redux Action Dispatched: ${action.type}`);
        return next(action);
    } catch (err) {
        logger.error('Redux Action Threw an Exception.', err);
        throw err;
    }
};
