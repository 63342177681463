import { IDocServiceRequest } from '../../types';
import { injectable } from 'inversify';
import { IDMToken } from '../dmTokenHandler/type';
import { GetUrlEnum } from '../../enums/urlPattern';

export interface IDocOperationInvocation {
    operationName: string;
    objectRelative: string;
    ObjectTypeMap: Record<string, string>;
    hostName: string;
    docStorId?: string;
    tokenHandler: IDMToken;
    operationsUrl: Record<string, string>;
    getUrlEnum: GetUrlEnum;
    supportedEntityTypes?: Record<string, number>;
    commandId?: Promise<Record<string, string>>;
    commandMapName?: string;
    getUrl: (_nodeObj: IDocServiceRequest) => Promise<string>;
}

/**
 * Dummy Class for setting all the values
 * used in case of t360 are in cases ig getUrl is DMInfo
 */
@injectable()
export class UrlPattern implements IDocOperationInvocation {
    docStorId?: string | undefined;
    operationsUrl: Record<string, string>;
    supportedEntityTypes?: Record<string, number> | undefined;
    commandId?: Promise<Record<string, string>> | undefined;
    commandMapName?: string | undefined;
    operationName = '';
    objectRelative = '';
    hostName = '';
    tokenHandler!: IDMToken;
    getUrlEnum = GetUrlEnum.PATTERN_CREATION;
    ObjectTypeMap = {};
    getUrl(_nodeObj: IDocServiceRequest): Promise<string> {
        return new Promise<string>((resolve) => {
            resolve('NOT IMPLEMENTED');
        });
    }
}

@injectable()
export class CommandPattern extends UrlPattern {
    docStorId?: string | undefined;
    getUrl(_nodeObj: IDocServiceRequest): Promise<string> {
        return new Promise<string>(() => {
            // Not implemented
        });
    }
    commandMapName = '';
    commandId: Promise<Record<string, string>> = new Promise(() => {
        // Not implemented
    });
}

@injectable()
export class OperationPattern extends UrlPattern {
    getUrl(_nodeObj: IDocServiceRequest): Promise<string> {
        return new Promise<string>(() => {
            // Not implemented
        });
    }

    commandId: Promise<Record<string, string>> = new Promise(() => {
        // Not implemented
    });
}
