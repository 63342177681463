import {
    addDocument,
    addFolder,
    cancelCheckOut,
    checkInDocument,
    checkOut,
    CHMessageData,
    CHSupportedDocumentTypesEnum,
    clearDefaultUrl,
    deleteDocument,
    deleteEmail,
    deleteFolder,
    downloadDocument,
    editDocument,
    editFolder,
    EventType,
    getIsQuickFileEnabled,
    getQueue,
    ICHAddFolder,
    ICHCancelCheckout,
    ICHCheckOut,
    ICHDeleteDocMetaType,
    ICHDeleteEmail,
    ICHDeleteFolder,
    ICHDownloadDocument,
    ICHEditDocument,
    ICHEditFolder,
    ICHProps,
    ICHQuickFileIndicator,
    ICHShowDocument,
    ICHUploadResponse,
    ICHUploadType,
    IEvent,
    IEventCallback,
    initialize,
    openExternalUrl,
    performDragAndDrop,
    performQuickFile,
    publish,
    reloadUrl,
    setDefaultUrl,
    showDocument,
    showHelpDocument,
    buildHelpDocumentUrl,
    subscribe,
    subscribeMessagingService,
    updateDocument,
    ICHHelpUrlResponse,
    ICHResponse,
    notifyAutoUpdateService,
} from '@wk/elm-uui-context-handler';

// ALL methods implemented here must also be mocked out in the __mocks__/wkElmUuiContextHandler.ts file

// temporary until context layer gives us official function to call
export const isOC: { (): boolean; simulate?: boolean } = () =>
    isOC.simulate || navigator.userAgent.includes('Electron');

export const clEditDocument = (editDocumentParams: ICHEditDocument): Promise<ICHResponse> => {
    return editDocument(editDocumentParams);
};

export const clEditFolder = (editFolderParams: ICHEditFolder): Promise<ICHResponse> => {
    return editFolder(editFolderParams);
};

export const clShowDocument = (showDocumentParams: ICHShowDocument): Promise<ICHResponse> => {
    return showDocument(showDocumentParams);
};

export const clDownloadDocument = (downloadDocumentParams: ICHDownloadDocument): Promise<string | Response> => {
    return downloadDocument(downloadDocumentParams);
};

export const clOpenDesktopLink = (desktopUrl: string): Promise<Response> => {
    return openExternalUrl(desktopUrl);
};

export const clCheckOut = (checkOutParams: ICHCheckOut): Promise<string | Response> => {
    return checkOut(checkOutParams);
};

export const clCancelCheckOut = (cancelCheckOutParams: ICHCancelCheckout): Promise<ICHResponse> => {
    return cancelCheckOut(cancelCheckOutParams);
};

export const clSubscribe = (
    name: EventType,
    callback: IEventCallback,
    existingSubscriptionToRemove?: string,
): string => {
    return subscribe(name, callback, existingSubscriptionToRemove);
};

export const clPublish = (obj: IEvent): void => {
    return publish(obj);
};

export const clPerformQuickFile = (
    performQuickFileParams: ICHUploadType,
): Promise<ICHUploadResponse | ICHUploadResponse[]> => {
    return performQuickFile(performQuickFileParams);
};

export const clAddDocument = (addDocumentParams: ICHUploadType): Promise<ICHUploadResponse> => {
    return addDocument(addDocumentParams);
};

export const clAddFolder = (addFolderParams: ICHAddFolder): Promise<Response> => {
    return addFolder(addFolderParams);
};

export const clDeleteFolder = (deleteFolderParams: ICHDeleteFolder): Promise<ICHResponse> => {
    return deleteFolder(deleteFolderParams);
};

export const clDeleteDocument = (deleteDocParams: ICHDeleteDocMetaType): Promise<ICHResponse> => {
    return deleteDocument(deleteDocParams);
};

export const clDeleteEmail = (deleteEmailParams: ICHDeleteEmail): Promise<ICHResponse> => {
    return deleteEmail(deleteEmailParams);
};

export const clViewQuickFileIcon: {
    (isFolder?: boolean, documentType?: CHSupportedDocumentTypesEnum): Promise<ICHQuickFileIndicator>;
    simulate?: boolean;
} = (isFolder?: boolean, documentType?: CHSupportedDocumentTypesEnum): Promise<ICHQuickFileIndicator> => {
    if (clViewQuickFileIcon.simulate) {
        return new Promise<ICHQuickFileIndicator>((resolve) =>
            setTimeout(() => {
                resolve({
                    enableQuickFile: true,
                    entityType: CHSupportedDocumentTypesEnum.DOCUMENT,
                });
            }, 100),
        );
    } else {
        const response = getIsQuickFileEnabled(isFolder, documentType);
        return response;
    }
};

export const clCheckInDocument = (checkInParams: ICHUploadType): Promise<ICHUploadResponse> => {
    return checkInDocument(checkInParams);
};

export const clUpdateDocument = (updateDocumentParams: ICHUploadType): Promise<ICHUploadResponse> => {
    return updateDocument(updateDocumentParams);
};

export const clPerformDragAndDrop = (
    performDragAndDropParams: ICHUploadType,
): Promise<ICHUploadResponse | ICHUploadResponse[]> => {
    return performDragAndDrop(performDragAndDropParams);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const clSubscribeMessagingService = (observer: (data: CHMessageData) => void) => {
    return subscribeMessagingService(observer);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const clGetQueue = () => {
    return getQueue();
};

export const clNotifyAutoUpdateService = () => notifyAutoUpdateService();

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const clSetDefaultUrl = (url: string) => {
    setDefaultUrl(url).subscribe();
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const clClearDefaultUrl = () => {
    clearDefaultUrl().subscribe();
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const clReloadUrl = (): any => {
    return reloadUrl();
};

export const clBuildHelpDocumentURL = (apiPath: string, helpFileUrl: string): Promise<ICHHelpUrlResponse> => {
    return buildHelpDocumentUrl(apiPath, helpFileUrl);
};

export const clDownloadHelpPDF = (helpPDFDownloadUrl: string): Promise<ICHResponse> => {
    return showHelpDocument(helpPDFDownloadUrl);
};

export const clInitialize = (clProps: ICHProps): Promise<void> => {
    return initialize(clProps);
};
