import { baseCommunicationService } from '@wk/office-companion-js-common';
import { injectable } from 'inversify';

export enum SystemValues {
    OpenExternal = 'open-external',
}

@injectable()
export class SystemService {
    /**
     * This exposes functions related to desktop integration.
     *  @param url  takes a url. needs to be a https url or error will be thrown.
     *  @param _options  options is optional used with metadata.
     */
    public openExternal(url: string, _options?: unknown): void {
        baseCommunicationService.invoke(SystemValues.OpenExternal, { url, _options }).subscribe();
    }
}
