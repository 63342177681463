import { IOfficeDocumentService } from '..';
import { injectable } from 'inversify';
import { FileOperations, PowerPoint, SaveOptions } from '@wk/office-companion-js';
import { factory } from '../../configLog4J';
import { DocumentDefaultExtensions } from '../../enum/enum';
import { v4 as uuid } from 'uuid';
import { getTemporaryFileName } from '../../utils/main.utils';

const log = factory.getLogger('PowerPointService');

@injectable()
export class PowerPointService implements IOfficeDocumentService {
    private hasValue = false;
    private documentPath = '';

    public async isNewDocument(): Promise<boolean> {
        try {
            await PowerPoint.run(async (context) => {
                const doc = context.currentPresentation;
                await context.sync();
                context.load(doc, ['isNew']);
                await context.sync();
                this.hasValue = doc.isNew;
            });
        } catch (error) {
            log.error(error);
        }
        return this.hasValue;
    }

    public async isDocumentSaved(): Promise<boolean> {
        try {
            await PowerPoint.run(async (context) => {
                const doc = context.currentPresentation;
                await context.sync();
                context.load(doc, ['saved']);
                await context.sync();
                this.hasValue = doc.saved;
            });
        } catch (error) {
            log.error(error);
        }
        return this.hasValue;
    }

    public async save(): Promise<void> {
        try {
            await PowerPoint.run(async (context) => {
                const doc = context.currentPresentation;
                await context.sync();
                doc.save();
                await context.sync();
            });
        } catch (error) {
            log.error(error);
        }
    }

    public async saveAs(filePath: string): Promise<void> {
        try {
            await PowerPoint.run(async (context) => {
                const doc = context.currentPresentation;
                await context.sync();
                doc.saveAs(filePath);
                await context.sync();
            });
        } catch (error) {
            log.error(error);
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public async close(_: SaveOptions): Promise<void> {
        try {
            await PowerPoint.run(async (context) => {
                const doc = context.currentPresentation;
                await context.sync();
                doc.close();
                await context.sync();
            });
        } catch (error) {
            log.error(error);
        }
    }

    public async getCurrentDocument(): Promise<string> {
        try {
            await PowerPoint.run(async (context) => {
                const { currentPresentation } = context;
                await context.sync();

                context.load(currentPresentation, ['name']);
                await context.sync();
                this.documentPath = currentPresentation.name;
            });
        } catch (error) {
            this.documentPath = '';
            log.error(error);
        }
        return this.documentPath;
    }

    public async getCurrentDocumentFullName(): Promise<string> {
        try {
            await PowerPoint.run(async (context) => {
                const { currentPresentation } = context;
                await context.sync();
                context.load(currentPresentation, ['normalizedFullName']);
                await context.sync();
                this.documentPath = currentPresentation.normalizedFullName;
            });
        } catch (error) {
            this.documentPath = '';
            log.error(error);
        }
        return this.documentPath;
    }

    public async getActiveDocumentSelection(): Promise<boolean> {
        try {
            await PowerPoint.run(async (context) => {
                const { currentPresentation } = context;
                await context.sync();
                this.hasValue = currentPresentation.isResolved && !currentPresentation.isNull;
            });
        } catch (error) {
            log.error(error);
        }
        return this.hasValue;
    }

    public async isReadOnly(): Promise<boolean> {
        try {
            await PowerPoint.run(async (context) => {
                const { currentPresentation } = context;
                await context.sync();
                context.load(currentPresentation, ['readOnly']);
                await context.sync();
                this.hasValue = currentPresentation.readOnly;
            });
        } catch (error) {
            log.error(error);
        }
        return this.hasValue;
    }

    public async isBusy(): Promise<boolean> {
        return false;
    }

    public async getDefaultExtension(): Promise<string> {
        return DocumentDefaultExtensions.PowerPoint;
    }

    public async saveAsInTempStorage(): Promise<string> {
        const id = uuid();
        await PowerPoint.run(async (context) => {
            const { currentPresentation } = context;
            await context.sync();
            context.load(currentPresentation, ['name']);
            await context.sync();
            const path = await FileOperations.resolvePath({
                rootName: 'temporaryFileStorage',
                parts: [id],
                filename: await getTemporaryFileName(currentPresentation.name),
            });
            currentPresentation.saveAs(path.filePath);
            this.documentPath = path.filePath;
            await context.sync();
        });
        return this.documentPath;
    }

    public async activateWindow(uniqueId: string): Promise<void> {
        await FileOperations.open(uniqueId);
    }
}
