import { useLocation } from 'react-router-dom';
import { UUIHistoryState } from '../components/common/types';
import * as H from 'history';

export type UUILocation = H.Location<UUIHistoryState>;

export const useUUILocation = (): UUILocation => {
    const location = useLocation<UUIHistoryState>() as UUILocation;
    return location;
};
