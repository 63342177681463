import { OfficeContext } from '../common/officeContext';
import { Application } from './application';
import { Document } from './document';
import { RequestType } from '../comObject';

export class WordContext extends OfficeContext {
    public get application(): Application {
        return this.reference.requestObject({
            type: RequestType.GetProperty,
            creator: Application,
            name: 'Application',
            cacheName: 'application',
            isReleasable: false,
        }).value;
    }

    public get currentDocument(): Document {
        return this.reference.requestObject({
            type: RequestType.Invoke,
            creator: Document,
            name: 'GetCurrentDocument',
        }).value;
    }
}
