import React from 'react';
import css from './editMultipleMoreMenu.module.scss';
import { IListScreenOperation } from './types';
import { UUIFlyoutMenu, UUIFlyoutMenuItem, UUIFlyoutMenuButton } from '../common/uuiFlyoutMenu';

interface IEditMultipleMoreMenu {
    bulkOperations: IListScreenOperation[];
    operationClickHandler: (operationId: number) => void;
    isSnapped: boolean;
}

const EditMultipleMoreMenu: React.FC<IEditMultipleMoreMenu> = ({
    bulkOperations: operations,
    operationClickHandler,
    isSnapped,
}) => {
    return (
        <div className={css.menuButtonWrapper}>
            <UUIFlyoutMenu
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}>
                <UUIFlyoutMenuButton size="small" data-testid="multiOpsBtn" />
                {operations.map((item: IListScreenOperation, index: number) =>
                    isSnapped || (!isSnapped && index > 0) ? (
                        <UUIFlyoutMenuItem key={index} onClick={() => operationClickHandler(item.id)}>
                            {item?.displayName}
                        </UUIFlyoutMenuItem>
                    ) : null,
                )}
            </UUIFlyoutMenu>
        </div>
    );
};

export default EditMultipleMoreMenu;
