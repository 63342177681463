import { ITransformer } from './types';
import { ICHShowDocument } from '../interfaces/operations';
import { IDocumentMetadata } from '@wk/elm-uui-doc-component';
import 'reflect-metadata';
import { injectable } from 'inversify';

@injectable()
export class CtxShowDocumentTransformer implements ITransformer {
    private data!: ICHShowDocument;

    transform(): IDocumentMetadata {
        const nodeObj = {} as IDocumentMetadata;
        nodeObj.docId = this.data.documentId;
        nodeObj.entityContext = {
            name: this.data?.associatedEntityName || '',
            id: this.data?.associatedEntityId,
            type: this.data?.associatedEntityType,
        };
        nodeObj.extendedProps = {};
        nodeObj.extendedProps.loggedInUser = this.data?.loggedInUser;
        nodeObj.extendedProps.documentName = this.data?.documentName;
        nodeObj.extendedProps.documentFileName = this.data?.documentFileName;
        if (this.data?.version) {
            nodeObj.extendedProps.version = this.data?.version;
        }
        return nodeObj;
    }
}
