import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const WkIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon {...props} data-testid="wkFilterSettingsIcon" viewBox="0 0 24 24">
        <g>
            <defs>
                <circle id="SVGID_1_" cx="12" cy="12" r="12" />
            </defs>
            <clipPath id="SVGID_2_">
                <use xlinkHref="#SVGID_1_" overflow="visible" />
            </clipPath>
            <g id="Wheel" clipPath="url(#SVGID_2_)">
                <rect id="Rectangle" fill="#A6D1EA" width="16" height="16" />
                <rect id="Rectangle-2" x="-5" y="8" fill="#D4E8B1" width="34" height="8" />
                <rect id="Rectangle-3" x="8" y="8" fill="#409BD2" width="21" height="8" />
                <rect id="Rectangle-4" x="8" y="-5" fill="#D4E8B1" width="8" height="34" />
                <rect id="Rectangle-5" x="8" y="8" fill="#409BD2" width="8" height="21" />
                <rect id="Rectangle-6" x="8" y="8" fill="#A4CD58" width="16" height="16" />
                <rect id="Rectangle-7" x="8" y="8" fill="#E5202E" width="8" height="8" />
                <rect id="Rectangle-8" x="16" y="16" fill="#007AC3" width="8" height="8" />
            </g>
        </g>
    </SvgIcon>
);

export default WkIcon;
