import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { createStore, applyMiddleware, Middleware, Reducer, PreloadedState } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { getActivePassportContext, getPassportContextDefaultLandingPageUrl } from './utils/passportContextHelper';
import { history } from './history.service';
import { composeWithDevTools } from 'redux-devtools-extension';
import { AppProps } from './app';
import { InitializeAppCallback, UUICustomComponents } from './initialize';
import { AppStore, UUIReduxDispatch } from './reducers/types';
import { pendoAgent } from './utils/pendoAgent.service';
import { FullScreenOverlayIconEnum } from './components/common/types';
import { OverlayDialogButtonAction } from '@wk/elm-uui-context-handler';

export async function handleinitializeResponse(response: Response): Promise<AppStore | undefined> {
    if (response.status != 401) {
        return (await response.json()) as AppStore;
    }

    return;
}

export function handleJsonResponse(reduxDispatch: UUIReduxDispatch, json?: AppStore): AppStore | undefined {
    if (json == null) {
        window.location.href = window.Props['uiContextRoot'] + window.Props['uiContextPath'];
        return;
    } else {
        return setupJsonAndRedirectIfNeeded(reduxDispatch, json);
    }
}

function setupJsonAndRedirectIfNeeded(reduxDispatch: UUIReduxDispatch, json: AppStore) {
    if (sessionStorage.activeContext) {
        json.appsMenu.activeContext = parseInt(sessionStorage.activeContext);
    }

    // Default any missing data from window.Props
    if (!json.appResources.apiContextRoot) {
        json.appResources.apiContextRoot = window.Props['apiContextRoot'];
    }
    if (!json.appResources.apiContextPath) {
        json.appResources.apiContextPath = window.Props['apiContextPath'];
    }
    if (!json.appResources.uiContextRoot) {
        json.appResources.uiContextRoot = window.Props['uiContextRoot'];
    }
    if (!json.appResources.uiContextPath) {
        json.appResources.uiContextPath = window.Props['uiContextPath'];
    }
    if (!json.appResources.staticContext) {
        json.appResources.staticContext = window.Props['staticContext'];
    }

    const initialState = json;

    let href = window.location.href;

    if (href.indexOf('/viewport/show.do') > 0 && sessionStorage.getItem('v3RedirectHash')) {
        // @ts-ignore
        window.location.hash = sessionStorage.getItem('v3RedirectHash');
        sessionStorage.removeItem('v3RedirectHash');
        href = window.location.href;
    }

    var baseUrlPath = window.Props['basePath'] + '/';
    const pathname = window.location.pathname;

    if (window.location.search.indexOf(window.Props['pageNotFoundParam']) < 0) {
        if (pathname.substring(pathname.length - baseUrlPath.length) === baseUrlPath) {
            let landingPage = getPassportContextDefaultLandingPageUrl(
                getActivePassportContext(initialState.appsMenu),
                initialState.appResources.v3HomeUrl,
            );
            if (landingPage == '') {
                reduxDispatch({
                    type: 'OpenOverlayDialog',
                    overlayDialog: {
                        heading: window.Props.permissionErrorOverlayHeading,
                        icon: FullScreenOverlayIconEnum.EXCLAMATION,
                        message: [
                            window.Props.permissionErrorOverlayMessage,
                            window.Props.permissionErrorOverlayMessage2,
                        ],
                        button: {
                            text: window.Props.logOffUser,
                            action: OverlayDialogButtonAction.logOff,
                        },
                    },
                });
            }
            history.replace(landingPage);
        }
    }

    pendoAgent.initialize(initialState);

    return initialState;
}

export function loadApp(
    initialState: PreloadedState<any>,
    reducer: Reducer,
    applicationToolbar: UUICustomComponents,
    AppComponent: FC<AppProps>,
    reduxLogger: Middleware | undefined = undefined,
    initializeAppCallback: InitializeAppCallback | undefined = undefined,
) {
    const middleware = reduxLogger ? applyMiddleware(thunkMiddleware, reduxLogger) : applyMiddleware(thunkMiddleware);
    const store = createStore(reducer, initialState, composeWithDevTools(middleware));

    ReactDOM.render(
        <Provider store={store}>
            <Router history={history}>
                <AppComponent customComponents={applicationToolbar} initializeAppCallback={initializeAppCallback} />
            </Router>
        </Provider>,
        document.getElementById('app'),
    );
}
