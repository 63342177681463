import { CapabiltyEnum } from '../../enums/capability';
import { injectable } from 'inversify';
import { JRAdapterOperationPattern } from '../urlEntity/operationPattern';
import { IDocumentMetadata } from '../../docManager';

@injectable()
export class JRAdapterFolderMetadataUrlPattern extends JRAdapterOperationPattern {
    operationName = CapabiltyEnum.FOLDER_METADATA;
    async getUrl(nodeObj: IDocumentMetadata): Promise<string> {
        let baseUrl = await super.getUrl(nodeObj);

        baseUrl = baseUrl + '/' + nodeObj.docId;
        return baseUrl;
    }
}
