import { OutlookInterface } from './outlook/outlook.service';
import { WordInterface } from './word/word.service';
import { officeContainer } from './office.module';
import { OfficeAppType } from './common/officeAppType.enum';
import { ExcelInterface } from './excel/excel.service';
import { PowerPointInterface } from './powerPoint/powerPoint.service';

export const Outlook = officeContainer.get<OutlookInterface>(OfficeAppType.Outlook);
export const Word = officeContainer.get<WordInterface>(OfficeAppType.Word);
export const Excel = officeContainer.get<ExcelInterface>(OfficeAppType.Excel);
export const PowerPoint = officeContainer.get<PowerPointInterface>(OfficeAppType.PowerPoint);

export * from './outlook';
export * from './word';
export * from './excel';
export * from './powerPoint';
