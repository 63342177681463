import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import ActionDialog from '../../common/actionDialog';
import { useListScreenDispatch, useListScreenState } from '../context/listScreenContext';
import { dispatchRemoveTab, dispatchPostRemoveTab } from '../context/listScreenAsyncActions';
import { useRefreshList } from '../context/listScreenHooks';
import { useReduxSelector } from '../../../hooks/useReduxSelector';

interface IRemoveTabActionDialog {
    selectedTabId: number;
}

export const RemoveTabActionDialog: React.FC<IRemoveTabActionDialog> = ({ selectedTabId }) => {
    const listScreenState = useListScreenState();
    const listScreenDispatch = useListScreenDispatch();
    const refreshList = useRefreshList();
    const metadata = listScreenState.metadata!;
    const applicationUrls = useReduxSelector((state) => state.applicationUrls);
    const appResources = useReduxSelector((state) => state.appResources);
    const [shouldCallPostRemoveTab, setShouldCallPostRemoveTab] = useState(false);
    const [isCurrentTabBeingRemoved, setIsCurrentTabBeingRemoved] = useState(false);

    useEffect(() => {
        if (shouldCallPostRemoveTab) {
            dispatchPostRemoveTab(isCurrentTabBeingRemoved, listScreenState, listScreenDispatch, refreshList);
        }
    }, [isCurrentTabBeingRemoved, listScreenDispatch, listScreenState, refreshList, shouldCallPostRemoveTab]);

    const actionButtonClickHandler = () => {
        dispatchRemoveTab(
            listScreenState,
            listScreenDispatch,
            applicationUrls.applicationDeleteSavedViewUrl,
            selectedTabId,
        ).then(() => {
            setIsCurrentTabBeingRemoved(listScreenState.listData!.page.savedView.id == selectedTabId);
            setShouldCallPostRemoveTab(true);
        });
    };

    const cancelButtonClickHandler = () => {
        listScreenDispatch({ type: 'CloseTabOptionsRemoveTabDialog' });
    };

    const getContentText = () => {
        const tabName = metadata.savedViews.find((sv) => sv.id == selectedTabId)?.name;
        const contentBase = appResources.listscreenRemoveTabDialogContent;
        if (contentBase) {
            return contentBase.replace('{tabName}', tabName!);
        } else {
            return '';
        }
    };

    return (
        <ActionDialog
            open={listScreenState.tabOptionsRemoveTabDialogOpen}
            handleActionClose={cancelButtonClickHandler}
            actionButtonClickHandler={actionButtonClickHandler}
            title={appResources.listscreenRemoveTabDialogTitle}
            actionButtonText={appResources.listscreenRemoveTab}
            disableActionButton={false}>
            <Typography gutterBottom data-testid="removeTab-dialog-content">
                {getContentText()}
            </Typography>
        </ActionDialog>
    );
};
