import { commonContainer } from '../../common/common.module';
import { Container, interfaces } from 'inversify';
import { MessageBarInterface, MessageBarService } from './messageBar.service';

const importedContainers = [commonContainer];

const messageBarContainer: interfaces.Container = importedContainers.reduce(
    (container, imports) => Container.merge(imports, container),
    new Container(),
);

messageBarContainer.bind<MessageBarInterface>(MessageBarService).toSelf().inSingletonScope();

export { messageBarContainer };
