import { LogLevel } from '@wk/elm-uui-common';
import { CHMessagingScope } from '@wk/elm-uui-context-handler';
import { saveLogLevelToLocalStorage } from '../../utils/loggingService';
import { messageBusDispatch } from '../contextLayerService/messageBusService';
import { IAuthenticationProvider } from './types';

const credentials: RequestInit = { credentials: 'same-origin' };

export const DefaultAuthenticationProvider: IAuthenticationProvider = {
    addRequestAuthentication: async (init: RequestInit) => {
        if (!init) {
            return credentials;
        }

        if (init.method === 'post') {
            const headers = init.headers ? new Headers(init.headers) : new Headers();
            headers.set('UNIQUE_TOKEN', window.Props['token']);

            return { ...init, headers, ...credentials };
        }

        return { ...init, ...credentials };
    },
    signOut: (signOutUrl: string, notifyOtherInstances?: boolean) => {
        if (notifyOtherInstances) {
            messageBusDispatch({ type: 'Logout', scope: CHMessagingScope.OtherInstances });
            saveLogLevelToLocalStorage(LogLevel.Error);
        }
        window.location.replace(signOutUrl);
    },
};
