import { IDocTransformer } from '../../interfaces/transformer/type';
import { injectable } from 'inversify';
import { factory } from '../../ConfigLog4j';
import { IMatterDoc, IAddEmail } from '../../types';
import { JRAdapterAddEmailActionableEntity } from '../actionableEntity/addEmailActionableEntity';
import { mapHref, mapNames, urlMap } from '../utils/maps';
import { toBase64 } from '../../utils/main.utils';
import { IAddEmail_JR } from '../types';

const log = factory.getLogger('JRAdapterAddEmailEntityTransformer');

@injectable()
export class JRAdapterAddEmailEntityTransformer implements IDocTransformer {
    matterDoc!: IMatterDoc;
    async transformIntoActionableEntity(nodeObj: IAddEmail): Promise<JRAdapterAddEmailActionableEntity> {
        log.debug('Calling transformIntoActionableEntity');
        const obj = {} as JRAdapterAddEmailActionableEntity;

        const nodeObj1 = nodeObj as IAddEmail_JR;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        obj.attributes = {
            associatedEntityName: 'EmailDocument',
            isFolder: false,
            attachmentPresent: nodeObj1.extendedProps?.isAttachmentPresent,
            mailBody: nodeObj1.extendedProps?.emailBody,
            sentTime: nodeObj1.extendedProps?.sentTime,
            receivedTime: nodeObj1.extendedProps?.receivedTime,
            senderName: nodeObj1.extendedProps?.senderName,
            senderEmailAddress: nodeObj1.extendedProps?.senderEmail,
            recipients: nodeObj1.extendedProps?.emailRecipients,
            subject: nodeObj1.extendedProps?.emailSubject,
            uniqueId: nodeObj1.extendedProps?.uniqueId,
            documentFile: { name: nodeObj1.name },
        };

        if (nodeObj1?.name) {
            obj.attributes.name = nodeObj1.name;
            obj.attributes.documentFile = { name: nodeObj1.name };
        }

        if (nodeObj.folderPath) {
            obj.attributes.parentDocument = { href: '../../' + urlMap['EmailDocument'] + '/' + nodeObj1.folderPath };
        } else {
            obj.attributes.parentDocument = {};
        }

        if (nodeObj.entityContext.id) {
            obj.attributes[mapNames.EmailDocument] = {
                href: '../../' + mapHref['EmailDocument'] + '/' + nodeObj.entityContext.id,
            };
        }

        if (nodeObj?.file) {
            obj.data = await toBase64(nodeObj.file);
        }
        return obj;
    }
}
