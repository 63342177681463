import { injectable } from 'inversify';
import { CHMessagingScope } from '../enum/enum';
import { CHMessageData } from './interface';

export interface IBrowserCommunicationService {
    subscribe(communicationHandler: (data: CHMessageData) => void): void;
    notify(data: CHMessageData): void;
}

@injectable()
export class BrowserCommunicationService implements IBrowserCommunicationService {

    private browserCommunicationChannel = new BroadcastChannel('BROWSER_COMMUNICATION_CHANNEL');
    private communicationHandler :((data: CHMessageData) => void) | undefined;
    public subscribe(communicationHandler: (data: CHMessageData) => void): void {
        this.communicationHandler = communicationHandler;
        this.browserCommunicationChannel.onmessage = (event): void => {
            communicationHandler(event.data as CHMessageData);
        };
    }

    public notify(data: CHMessageData): void {
        // If a scope is AllInstances then need to call the current instance's handler as well
        if(data.scope === CHMessagingScope.AllInstances) {
            this.communicationHandler?.(data);
        }
        // Notifies to other instances/tabs only
        this.browserCommunicationChannel.postMessage(data);
    }

}