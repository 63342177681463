import { injectable } from 'inversify';
import { myContainer } from './core';
import { INavigationContextHandler } from './interfaces/interface';
import { IQueryStringAppender } from './interfaces/interface';
import { System } from '@wk/office-companion-js';

@injectable()
export class NavigationOCContextHandler implements INavigationContextHandler {
    public openExternalUrl = async (externalUrl: string): Promise<void> => {
        const platform = 'T360'; // For now it is hardcoded and will be handled as part of context layer initialization.
        const qryStringAppender = this.getQueryStringAppender(platform);
        const url = await qryStringAppender.appendQueryString(externalUrl);
        System.openExternal(url);
    };

    private getQueryStringAppender(platformContext: string): IQueryStringAppender {
        switch (platformContext) {
            case 'T360': {
                return myContainer.get<IQueryStringAppender>('TokenQueryStringAppender');
            }
            default: {
                throw new Error('IUrlBuilder Not Implemented');
                // break;
            }
        }
    }
}
