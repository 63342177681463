import { IDocTransformer } from '../../interfaces/transformer/type';
import { IActionableEntity } from '../../interfaces/actionEntity/type';
import { injectable } from 'inversify';
import { factory } from '../../ConfigLog4j';

const log = factory.getLogger('T360AdapterEntityTransformer');

@injectable()
export class T360AdapterEntityTransformer implements IDocTransformer {
    transformIntoActionableEntity(): IActionableEntity {
        log.debug('Calling transformIntoActionableEntity');
        const obj = {} as IActionableEntity;
        // TO CREATE ACTIONABLE ENTITY FROM nodeObj and matterDoc
        return obj;
    }
}
