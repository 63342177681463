import { injectable } from 'inversify';
import { apiFetchGet } from '../../api/fetchUtils';
import { T360AdapterActionPerformer } from './actionPerformer';
import { IDocOperationInvocation } from '../../interfaces/urlPattern/types';
import { convertToMapMetaData, getFileNameWithoutExtension, getHostName } from '../../utils/main.utils';
import { matchMetadata } from '../utils/maps';
import { factory } from '../../ConfigLog4j';
import { DocumentName, FileName } from '../../constants';
import { T360AdapterDocumentFileNameMetadataActionableEntity } from '../actionableEntity/documentMetadataFileNameActionableEntity';
import { DCResponse } from '../../types';

const log = factory.getLogger('T360AdapterDocumentFileNameMetadataActionPerformer');

@injectable()
export class T360AdapterDocumentFileNameMetadataActionPerformer extends T360AdapterActionPerformer {
    fetchRequest = apiFetchGet;

    async performAction(
        obj: T360AdapterDocumentFileNameMetadataActionableEntity,
        urlObj: Promise<string>,
        urlPatternObj?: IDocOperationInvocation,
    ): Promise<DCResponse> {
        const response = await super.performAction(
            obj,
            urlObj,
            urlPatternObj,
            '?' +
                DocumentName +
                '=' +
                encodeURIComponent(getFileNameWithoutExtension(obj.documentName)) +
                '&' +
                FileName +
                '=' +
                encodeURIComponent(obj.fileName),
        );

        const outputInfo = async () => {
            const jsonObject = await response.json();

            jsonObject.documentMetadata.downloadURL = getHostName() + '/' + jsonObject.documentMetadata.downloadURL;

            if (jsonObject?.documentMetadata?.versionList && jsonObject?.documentMetadata?.versionList?.length > 0) {
                log.debug(
                    'Latest Version is: ' +
                        jsonObject.documentMetadata.versionList[jsonObject.documentMetadata.versionList.length - 1],
                );
                jsonObject.documentMetadata.latestVersion =
                    jsonObject.documentMetadata.versionList[jsonObject.documentMetadata.versionList.length - 1];
            }

            return convertToMapMetaData(matchMetadata, jsonObject.documentMetadata);
        };
        const response2: DCResponse = Object.assign(new DCResponse(), response);
        response2.outputInfo = outputInfo;
        return response2;
    }
}
