import { ITransformer } from './types';
import { ICHUploadType } from '../interfaces/operations';
import { IAddDocument } from '@wk/elm-uui-doc-component';

import 'reflect-metadata';
import { injectable } from 'inversify';
import { factory } from '../configLog4J';
const log = factory.getLogger('CtxAddTransformer');

@injectable()
export class CtxAddTransformer implements ITransformer {
    data!: ICHUploadType;

    transform(): IAddDocument {
        const nodeObj = {} as IAddDocument;
        nodeObj.entityContext = {
            name: this.data.associatedEntityName || '',
            id: this.data.associatedEntityId,
            type: this.data.associatedEntityType,
        };

        if (this.data?.folderPath != undefined && this.data?.folderPath != null) {
            nodeObj.folderPath = this.data.folderPath;
        }
        // if (this.data?.loggedInUser) {
        //   nodeObj.loggedInUser = this.data.loggedInUser;
        // }
        const extendedProps = <IAddDocument['extendedProps']>{};

        nodeObj.extendedProps = extendedProps;

        if (this.data?.props) {
            if (this.data?.props?.description) {
                extendedProps.description = this.data?.props?.description;
            }
            if (this.data.props?.contentType) {
                extendedProps.contentType = this.data?.props?.contentType;
            }
        }
        if (this.data?.folderId) {
            nodeObj.documentFolderId = this.data?.folderId;
            nodeObj.folderPath = this.data.folderId;
        } else if (this.data?.payload && this.data?.payload?.documentFolderId) {
            nodeObj.documentFolderId = this.data.payload.documentFolderId.value;
            nodeObj.folderPath = this.data.payload.documentFolderId.value;
        }

        if (this.data?.fileId) {
            nodeObj.fileId = this.data?.fileId;
        }

        const regExToRemoveSpaces = /^\s+|\s+$/g;
        const regExToRemoveStartSpaces = /^\s+/g;
        if (this.data?.payload) {
            nodeObj.documentType = this.data.payload?.document_original_type?.value.replace(regExToRemoveSpaces, '');
            if (nodeObj.documentType) {
                nodeObj.name = this.data?.payload?.name?.value.replace(regExToRemoveStartSpaces, '');
                nodeObj.documentName = this.data?.payload?.documentName?.value.replace(regExToRemoveStartSpaces, '');
            } else {
                nodeObj.name = this.data?.payload?.name?.value.replace(regExToRemoveSpaces, '');
                nodeObj.documentName = this.data?.payload?.documentName?.value.replace(regExToRemoveSpaces, '');
            }
            nodeObj.extendedProps.description = this.data?.payload?.comments?.value;
            nodeObj.editAccessType = this.data?.payload?.editAccessType?.value;
            nodeObj.viewAccessType = this.data?.payload?.viewAccessType?.value;
            nodeObj.viewMembers = (this.data?.payload?.viewMembers?.value || undefined)?.split(',').map(Number) || [];
            nodeObj.editMembers = (this.data?.payload?.editMembers?.value || undefined)?.split(',').map(Number) || [];
            log.debug('printing Payload ' + JSON.stringify(this.data.payload));
            if (this.data?.payload?.documentFolderId) {
                log.debug('document folderID found in payload' + this.data.payload.documentFolderId.value);
                nodeObj.documentFolderId = this.data.payload.documentFolderId.value;
            } else if (this.data?.payload?.document_folder_name) {
                log.debug('document folder name found in payload' + this.data.payload.document_folder_name.value);
                nodeObj.documentFolderId = this.data.payload.document_folder_name.value;
                nodeObj.folderPath = this.data.payload.document_folder_name.value;
            }
        }

        if (this.data?.file) {
            nodeObj.file = this.data.file;
        }

        if (this.data?.name) {
            nodeObj.name = this.data?.name;
        }

        if (!this.data?.payload?.documentName && !nodeObj.documentName) {
            nodeObj.documentName = nodeObj.documentType
                ? this.data?.payload?.name?.value.replace(regExToRemoveStartSpaces, '') + nodeObj.documentType
                : this.data?.payload?.name?.value.replace(regExToRemoveSpaces, '');
        } else if (!nodeObj.documentName) {
            nodeObj.documentName = nodeObj.documentType
                ? this.data?.payload?.documentName?.value.replace(regExToRemoveStartSpaces, '') + nodeObj.documentType
                : this.data?.payload?.documentName?.value.replace(regExToRemoveSpaces, '');
        }

        return nodeObj;
    }
}
