import { Container, interfaces } from 'inversify';
import { ComBridgeService } from './comObject';
import { ExcelInterface, ExcelService } from './excel/excel.service';
import { WordInterface, WordService } from './word/word.service';
import { OutlookService, OutlookInterface } from './outlook/outlook.service';
import { OfficeService } from './common/office.service';
import { commonContainer } from '../../common/common.module';
import { OfficeAppType } from './common/officeAppType.enum';
import { PowerPointInterface, PowerPointService } from './powerPoint/powerPoint.service';

const importedContainers = [commonContainer];

const officeContainer: interfaces.Container = importedContainers.reduce(
    (container, imports) => Container.merge(imports, container),
    new Container(),
);

officeContainer.bind<ExcelInterface>(OfficeAppType.Excel).to(ExcelService).inSingletonScope();
officeContainer.bind<PowerPointInterface>(OfficeAppType.PowerPoint).to(PowerPointService).inSingletonScope();
officeContainer.bind<WordInterface>(OfficeAppType.Word).to(WordService).inSingletonScope();
officeContainer.bind<OutlookInterface>(OfficeAppType.Outlook).to(OutlookService).inSingletonScope();
officeContainer.bind(ComBridgeService).toSelf().inSingletonScope();
officeContainer.bind(OfficeService).toSelf().inSingletonScope();

export { officeContainer };
