import { Button } from '@material-ui/core';
import { LogLevel } from '@wk/elm-uui-common';
import { EventType, ToastType, IOverlayDialog, CHMessagingScope, OverlayDialogButtonAction } from '@wk/elm-uui-context-handler';
import { clPublish } from '../../contextLayerService/contextLayerService';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useReduxDispatch } from '../../../hooks/useReduxDispatch';
import { updateLocationDataForOC } from '../../../utils/ocLocationService';
import { useUUIHistory } from '../../../hooks/useUUIHistory';
import { getLogger } from '../../../utils/loggingService';
import ItemScreen from '../../itemScreen/itemScreen';
import { getItemScreenPropsFromUrl } from '../../itemScreen/itemScreenHelpers';
import ListScreen from '../../listScreen/listScreen';
import { FullScreenOverlayIconEnum } from '../../common/types';
import { messageBusDispatch } from '../../contextLayerService/messageBusService';
type UuiPageParams = { screenType: string };

export const UuiPage: React.FC<RouteComponentProps<UuiPageParams>> = ({ location, match }) => {
    const qs = queryString.parse(location.search);
    const screenId = qs.id || qs.screenId;
    const reduxDispatch = useReduxDispatch();
    const history = useUUIHistory();
    const [locationKey, setLocationKey] = useState(location.key);

    useEffect(() => {
        if (match.params.screenType !== 'listscreens' && match.params.screenType !== 'itemscreens') {
            reduxDispatch({ type: 'SetPageTitle', title: '' });
        }
    }, [match.params.screenType, reduxDispatch]);

    const itemScreenProps = useMemo(() => getItemScreenPropsFromUrl(location.search), [location.search]);

    const showToast = (toastType: ToastType) => {
        clPublish({
            name: EventType.TOAST,
            toast: {
                toastMessage: `This is a toast of type ${toastType}.  Here is a clear explanation.  It drops to next line if it is too long.`,
                type: toastType,
            },
        });
    };

    const showOverlay = (overlayObj: IOverlayDialog) => {
        messageBusDispatch({
            type: 'OpenOverlayDialog',
            scope: CHMessagingScope.OtherInstances,
            message: JSON.stringify(overlayObj),
        });
        reduxDispatch({
            type: 'OpenOverlayDialog',
            overlayDialog: overlayObj,
        });
    };

    const showFullScreenOverlayDialog = (dialogType: string) => {
        switch (dialogType) {
            case 'Connectivity':
                showOverlay({
                    heading: 'No Internet Connection',
                    icon: FullScreenOverlayIconEnum.EXCLAMATION,
                    message: ['Passport will automatically reconnect when the connection resumes.'],
                });
                break;
            case 'Downloading':
                showOverlay({
                    heading: 'Downloading software update',
                    icon: FullScreenOverlayIconEnum.DOWNLOAD,
                    message: [
                        'After the update has been downloaded, you can proceed to install it.',
                        'This will take a few minutes.',
                    ],
                    showSpinner: true,
                });
                break;
            case 'SuccessfulDownload':
                showOverlay({
                    heading: 'Update successfully downloaded',
                    icon: FullScreenOverlayIconEnum.CHECKMARK,
                    message: [
                        'Install the software to continue working.',
                        "Please save and close your work before installation to ensure it's not lost.",
                    ],
                    showSpinner: false,
                    button: {
                        text: 'Close Apps and Install Updates',
                        action: OverlayDialogButtonAction.InstallUpdate,
                    },
                });
                break;
        }
    };

    const logMessage = (logLevel: LogLevel) => {
        switch (logLevel) {
            case LogLevel.Error:
                getLogger('test-logger').error('Test Error Message');
                break;
            case LogLevel.Warn:
                getLogger('test-logger').warn('Test Warning Message');
                break;
            case LogLevel.Info:
                getLogger('test-logger').info('Test Info Message');
                break;
            case LogLevel.Debug:
                getLogger('test-logger').debug('Test Debug Message');
                break;
        }
    };

    const showAlertDialog = () => {
        clPublish({
            name: EventType.DIALOG,
            dialog: {
                title: 'TEST TITLE',
                message: 'TEST MESSAGE',
                buttons: [
                    {
                        label: 'Async callback',
                        callback: async () => {
                            return new Promise((resolve) => setTimeout(resolve, 1000));
                        },
                    },
                    {
                        label: 'Sync callback',
                        callback: () => {
                            console.log('CLICK');
                        },
                    },
                    {
                        label: 'Sync with spinner',
                        callback: () => {
                            clPublish({ name: EventType.GLOBAL_SPINNER, isOpen: true });
                            console.log('CLICK');
                            setTimeout(() => {
                                clPublish({ name: EventType.GLOBAL_SPINNER, isOpen: false });
                            }, 3000);
                        },
                    },
                ],
            },
        });
    };

    // this effect listens for history changes, and if it is a replace (like switching tabs)
    // then do not update the key so that we render the same instance of the screen
    useEffect(() => {
        const unlisten = history.listen((newLocation, action) => {
            if (action !== 'REPLACE') {
                setLocationKey(newLocation.key);
            }
            updateLocationDataForOC(newLocation, action);
            getLogger('history').debug(`${action}: ${newLocation.pathname + newLocation.search}`);
        });

        return () => unlisten();
    }, [history, reduxDispatch]);

    return (
        <>
            {match.params.screenType === 'listscreens' ? (
                <ListScreen key={locationKey} screenId={screenId} mode={qs.mode} />
            ) : match.params.screenType === 'itemscreens' ? (
                <ItemScreen key={locationKey} {...itemScreenProps} />
            ) : (
                <div>
                    <h1 className="unifiedui">Welcome to UUI!</h1>
                    <div>
                        <Button onClick={() => showToast(ToastType.SUCCESS)}>Success Toast</Button>
                        <Button onClick={() => showToast(ToastType.WARNING)}>Warning Toast</Button>
                        <Button onClick={() => showToast(ToastType.INFORMATION)}>Info Toast</Button>
                        <Button onClick={() => showToast(ToastType.ERROR)}>Error Toast</Button>
                        <Button onClick={() => showToast(ToastType.DEFAULT)}>Default Toast</Button>
                    </div>
                    <div>
                        <Button onClick={() => logMessage(LogLevel.Error)}>Log Error Message</Button>
                        <Button onClick={() => logMessage(LogLevel.Warn)}>Log Warning Message</Button>
                        <Button onClick={() => logMessage(LogLevel.Info)}>Log Info Message</Button>
                        <Button onClick={() => logMessage(LogLevel.Debug)}>Log Debug Message</Button>
                    </div>
                    <div>
                        <Button onClick={() => showFullScreenOverlayDialog('Connectivity')}>
                            Show Fullscreen Overlay Connectivity
                        </Button>
                        <Button onClick={() => showFullScreenOverlayDialog('Downloading')}>
                            Show Fullscreen Overlay Downloading
                        </Button>
                        <Button onClick={() => showFullScreenOverlayDialog('SuccessfulDownload')}>
                            Show Fullscreen Overlay Successful Download
                        </Button>
                        <Button onClick={() => showAlertDialog()}>Show Alert Dialog</Button>
                    </div>
                    <div>
                        <Button
                            onClick={() => {
                                throw new Error('Test Error');
                            }}>
                            Error Overlay Test
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
};
