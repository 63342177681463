import { IDocServiceRequest } from '../../types';
import { apiRestFetchGet } from '../../restApi/fetch.utils';
import { IWindowPropsPassport } from '../types';
import * as convert from 'xml-js';
import { IAuthorizationInfo } from '../../interfaces/authScheme/type';
import { IDocumentMetadata, IDownloadDocument } from '../../docManager';

const getVersion = async (baseUrl: string, nodeObj: IDownloadDocument, token: IAuthorizationInfo): Promise<string> => {
    const arrayVersionId = await getVersionId(baseUrl, nodeObj, token);

    const response = await apiRestFetchGet(
        baseUrl + '/' + 'DocumentVersion' + '/' + arrayVersionId[arrayVersionId.length - 1] + '?attributeNames=version',
        {},
        token.token,
    );

    const xmlData = await response.text();
    const dataObj = JSON.parse(convert.xml2json(xmlData));

    const version = dataObj['elements'][0]['elements'][0]['elements'][0]['attributes']['value'];
    return version;
};

// to get versionId list
const getVersionId = async (
    baseUrl: string,
    nodeObj: IDownloadDocument | IDocumentMetadata,
    token: IAuthorizationInfo,
): Promise<Array<string>> => {
    const response1 = await apiRestFetchGet(
        baseUrl + '/' + 'document' + '/' + nodeObj.docId + '?attributeNames=versionHistory',
        {},
        token.token,
    );

    const xmlData = await response1.text();

    const dataObj = JSON.parse(convert.xml2json(xmlData));

    const jsonArray1 = dataObj['elements'][0]['elements'][0]['elements'][0]['elements'];

    if (jsonArray1) {
        const arrayVersionIds: string[] = [];
        jsonArray1.forEach((command: { [x: string]: { [x: string]: string } }) => {
            arrayVersionIds.push(command['attributes']['href'].replace(/.*\//, ''));
        });
        return arrayVersionIds;
    } else {
        return [];
    }
};
export const getLatestVersion = async (
    baseUrl: string,
    nodeObj: IDocServiceRequest,
    token: IAuthorizationInfo,
): Promise<string> => {
    const arrayVersionId = await getVersionId(baseUrl, nodeObj, token);

    if (arrayVersionId) {
        // for (let i = 0; i <= arrayVersionId.length - 1; i++) {
        const response = await apiRestFetchGet(
            baseUrl +
                '/' +
                'DocumentVersion' +
                '/' +
                arrayVersionId[arrayVersionId.length - 1] +
                '?attributeNames=version',
            {},
            token.token,
        );

        const xmlData = await response.text();
        const dataObj = JSON.parse(convert.xml2json(xmlData));

        const version = dataObj['elements'][0]['elements'][0]['elements'][0]['attributes']['value'];

        return version;
    } else {
        return '0';
    }
};

export const getUserId_JR = async (): Promise<string> => {
    // TO DO to get userId
    const windProps = (window as unknown as IWindowPropsPassport).Props;
    return windProps.username;
};

export default getVersion;
