import { injectable } from 'inversify';
import { apiFetchPost } from '../../restApi/fetch.utils';
import { T360AdapterActionPerformer } from './actionPerformer';
import { IActionableEntity } from '../../interfaces/actionEntity/type';
import { IDocOperationInvocation } from '../../interfaces/urlPattern/types';
import { factory } from '../../ConfigLog4j';
import { supportedEntityTypesMap } from '../utils/maps';
import { DCResponse } from '../../types';

const log = factory.getLogger('T360AdapterGetSupportedEntityTypesActionPerformer');

@injectable()
export class T360AdapterGetSupportedEntityTypesActionPerformer extends T360AdapterActionPerformer {
    fetchRequest = apiFetchPost;

    /*
     * The input type will be of the form EntityType, value
     * This will be converted to number,string and returned
     */
    async performAction(
        _: IActionableEntity,
        urlObj: Promise<string>,
        urlPatternObj?: IDocOperationInvocation,
    ): Promise<DCResponse> {
        log.debug('inside perform action ' + urlPatternObj);
        return urlObj.then(async (_url) => {
            log.debug('Inside urlObj ' + urlObj);
            const response = new Response();
            const outputInfo = async () => {
                const supportedEntityTypes = urlPatternObj?.supportedEntityTypes as Record<string, number>;
                const supporteETypesOutput: Record<number, string> = {};
                for (const key in supportedEntityTypes) {
                    const value = supportedEntityTypes[key];
                    supporteETypesOutput[value] = supportedEntityTypesMap[key];
                }
                return supporteETypesOutput;
            };
            const response2: DCResponse = Object.assign(new DCResponse(), response);
            response2.outputInfo = outputInfo;
            return response2;
        });
    }
}
