import { UUIFetch } from '@wk/elm-uui-common';
import { CHMessagingScope, EventType, OverlayDialogButtonAction } from '@wk/elm-uui-context-handler';
import { uniqueId } from 'lodash';
import { manuallyResetPromiseCounter, trackPromise } from 'react-promise-tracker';
import { getAuthenticationProvider } from '../components/authentication/AuthenticationProviderService';
import { FullScreenOverlayIconEnum } from '../components/common/types';
import { clPublish } from '../components/contextLayerService/contextLayerService';
import { messageBusDispatch } from '../components/contextLayerService/messageBusService';
import { UUIReduxDispatch } from '../reducers/types';
import { getLogger } from './loggingService';
import { USER_HAS_REFRESHED_OR_TIMED_OUT_SESSION_STORAGE_KEY } from './ocLocationService';

const logger = () => getLogger('FetchUtils');

const postOptions: RequestInit = {
    headers: new Headers({
        'Content-Type': 'text/json',
    }),
    method: 'post',
};

export const FAILED_FETCH_MESSAGE = 'Failed to fetch';
export const ERROR_400_MESSAGE = '400 response found';
export const ERROR_403_MESSAGE = '403 response found';
export const ERROR_404_MESSAGE = '404 response found';

export const initializeUUIFetch = (reduxDispatch: UUIReduxDispatch, heartBeatFunction?: () => Promise<void>): void => {
    const handler400 = (response: Response) => {
        // hide global spinner
        manuallyResetPromiseCounter();
        response.text().then((text) => {
            let message = '';
            try {
                message =
                    JSON.parse(text).message ||
                    JSON.parse(text).Message ||
                    JSON.parse(text)?.errors[0]?.ErrorMessage;
            } catch (e) {
                logger().info(
                    '400 response did not include a message or was invalid JSON. Falling back to default message.',
                    e,
                );
            }
            const key = uniqueId(Date.now().toString());
            if (message) {
                reduxDispatch({
                    type: 'ShowNotification',
                    notification: {
                        key,
                        message,
                        options: {
                            variant: 'error',
                            persist: true,
                        },
                    },
                });
            }
        });
    };

    const handler401 = () => {
        clPublish({ name: EventType.UNAUTHORIZED_ACCESS_ATTEMPT });
    };

    const handler403 = (response: Response) => {
        // hide global spinner
        manuallyResetPromiseCounter();
        response.text().then((text) => {
            let message = window.Props.unauthorizedAccess;
            try {
                message = JSON.parse(text).message || JSON.parse(text).Message || message;
            } catch (e) {
                logger().info(
                    '403 response did not include a message or was invalid JSON. Falling back to default message.',
                    e,
                );
            }
            const key = uniqueId(Date.now().toString());
            reduxDispatch({
                type: 'ShowNotification',
                notification: {
                    key,
                    message,
                    options: {
                        variant: 'error',
                        persist: true,
                    },
                },
            });
        });
    };

    const handler404 = (response: Response) => {
        // hide global spinner
        manuallyResetPromiseCounter();
        response.text().then((text) => {
            let message = window.Props.unauthorizedAccess;
            try {
                message = JSON.parse(text).message || JSON.parse(text).Message || message;
            } catch (e) {
                logger().info(
                    '404 response did not include a message or was invalid JSON. Falling back to default message.',
                    e,
                );
            }
            const key = uniqueId(Date.now().toString());
            reduxDispatch({
                type: 'ShowNotification',
                notification: {
                    key,
                    message,
                    options: {
                        variant: 'error',
                        persist: true,
                    },
                },
            });
        });
    };

    const handler500 = (): void => {
        // hide global spinner
        manuallyResetPromiseCounter();
        reduxDispatch({
            type: 'OpenOverlayDialog',
            overlayDialog: {
                heading: window.Props.errorOverlayHeading,
                icon: FullScreenOverlayIconEnum.EXCLAMATION,
                message: [window.Props.errorOverlayMessage],
                button: {
                    text: window.Props.returnToHomeButton,
                    action: OverlayDialogButtonAction.NavigateToHome,
                },
            },
        });
    };

    const handlerNetworkDown = () => {
        clPublish({ name: EventType.NETWORK_DOWN });
        // hide global spinner
        manuallyResetPromiseCounter();
        messageBusDispatch({
            type: 'OpenOverlayDialog',
            scope: CHMessagingScope.AllInstances,
            message: JSON.stringify({
                heading: window.Props.noInternetConnection,
                icon: FullScreenOverlayIconEnum.EXCLAMATION,
                message: [window.Props.reconnecting],
            }),
        });
        reduxDispatch({
            type: 'OpenOverlayDialog',
            overlayDialog: {
                heading: window.Props.noInternetConnection,
                icon: FullScreenOverlayIconEnum.EXCLAMATION,
                message: [window.Props.reconnecting],
            },
        });
    };

    const handlerNetworkRestored = () => {
        clPublish({ name: EventType.NETWORK_RESTORED });
        messageBusDispatch({
            type: 'CloseOverlayDialog',
            scope: CHMessagingScope.AllInstances,
        });
        reduxDispatch({ type: 'CloseOverlayDialog' });
        location.reload();
    };

    const handlerAPIVersionUpdateRequired = () => {
        reduxDispatch({
            type: 'OpenOverlayDialog',
            overlayDialog: {
                heading: window.Props.serverChangesDetectedHeading,
                icon: FullScreenOverlayIconEnum.DOWNLOAD,
                message: [window.Props.serverChangesDetectedMessage],
                button: {
                    text: window.Props.buttonRefreshNow,
                    action: OverlayDialogButtonAction.Reload,
                },
            },
        });
    };

    UUIFetch.initialize({
        handler400,
        handler401,
        handler403,
        handler404,
        handler500,
        handlerNetworkDown,
        heartBeatFunction: heartBeatFunction || passportHeartBeatFunction,
        handlerNetworkRestored,
        handlerAPIVersionUpdateRequired,
    });
};

export interface ApiFetchOptions<T> {
    /** will not show a global spinner for this ajax request */
    skipTracking?: boolean;
    responseCallbackFn?: (response: Response) => Promise<T> | T;
}

export async function apiFetch<T>(url: string, fetchPostData?: unknown, opts?: ApiFetchOptions<T>): Promise<T> {
    if (opts?.skipTracking) {
        return await apiFetchInternal<T>(url, fetchPostData, opts?.responseCallbackFn);
    } else {
        return await trackPromise(apiFetchInternal<T>(url, fetchPostData, opts?.responseCallbackFn));
    }
}

export async function defaultResponseCallBack<T>(response: Response): Promise<T> {
    if (response.status === 400) {
        throw new Error(ERROR_400_MESSAGE);
    }
    if (response.status === 403) {
        throw new Error(ERROR_403_MESSAGE);
    }
    if (response.status === 404) {
        throw new Error(ERROR_404_MESSAGE);
    }

    if (response.redirected) {
        // send a request of the current the browser url so that Passport always redirects
        // back to a non JSON api endpoint after the user logs back in. Do not wait on the response.
        fetch(window.location.href);
        sessionStorage.setItem(USER_HAS_REFRESHED_OR_TIMED_OUT_SESSION_STORAGE_KEY, Props.username);
        window.location.href = response.url + '?timeout=true';
        // sleep for a long time while the redirect happens so an error
        // dialog does not appear. The browser will not wait for this to complete.
        await new Promise((resolve) => setTimeout(resolve, 10000));
    }

    return (await response.json()) as T;
}

// if fetchPostData is provided, then it will POST, otherwise it will GET
async function apiFetchInternal<T>(
    url: string,
    fetchPostData?: unknown,
    responseCallbackFn: (response: Response) => Promise<T> | T = defaultResponseCallBack,
): Promise<T> {
    const authProvider = getAuthenticationProvider();
    const request: RequestInit = fetchPostData ? { ...postOptions, body: JSON.stringify(fetchPostData) } : {};
    const secureRequest = await authProvider.addRequestAuthentication(request);
    const response = await UUIFetch.fetch(url, secureRequest);

    return await responseCallbackFn(response);
}
// accept heartbeatfunction from wrapper app and pass it here
// externalize Application is Available
export const passportHeartBeatFunction = async (): Promise<void> => {
    const apiPath = Props['apiContextRoot'] + Props['apiContextPath'];
    const systemStatusUrl = apiPath + '/systemStatusCheck/show.do';
    await poll(
        async () => {
            try {
                const response = await fetch(systemStatusUrl);
                if (response.ok) {
                    return await response.text();
                } else {
                    return new Promise<string>((resolve) => resolve(''));
                }
            } catch (err) {
                return new Promise<string>((resolve) => resolve(''));
            }
        },
        (result: string) => {
            return result.indexOf('Application is Available') > -1;
        },
        5000,
    );
};
/**
 * Calls fn every ms milliseconds until fnCondition returns true
 *
 * @param fn The function to poll
 * @param fnCondition This function receives the return value of fn() as a parameter and should return true if you want polling to stop
 * @param ms How often to poll
 * @returns the return value of fn once fnCondition becomes true
 */
export async function poll<T>(fn: () => Promise<T>, fnCondition: (result: any) => boolean, ms: number): Promise<T> {
    let result = await fn();
    while (!fnCondition(result)) {
        await wait(ms);
        result = await fn();
    }
    return result;
}
const wait = (ms = 5000) => {
    return new Promise<void>((resolve) => {
        setTimeout(resolve, ms);
    });
};
