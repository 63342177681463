/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { FileOperations } from '@wk/office-companion-js';
import {
    CHSupportedEntityTypesEnum,
    CHSupportedDocumentTypesEnum,
    CapabiltyEnum,
    CHSupportedOperationTriggersEnum,
    ApplicationContextUI,
    // CHMessageType,
    // CHMessagingScope,
} from '../enum/enum';
import { FileExtensions } from '../enum/file-extensions.enum';
import { factory } from '../configLog4J';
import { IContextInputType, ICHDocumentInputType, ICHUploadResponse } from '../interfaces/operations';
// import { IQueueCapability } from '../interfaces/interface';
import {
    DocSupportedBaseEntityTypesEnum,
    getAuthToken,
    getSupportedEntityTypes,
    // getUserProfile,
    UnExpectedError,
} from '@wk/elm-uui-doc-component';
// import { getQueueManager, IQueueItem, IQueueServiceInterface } from '@wk/elm-uui-queuemanager';
import { IQueueItem } from '@wk/elm-uui-queuemanager';
// import { myContainer } from '../inversify.config';
// import { getCHMessagingServiceContextHandler } from '../contextHandler';

const log = factory.getLogger('main.utils');

// check if it is a node Environment
export function isNodeEnvironment(): boolean {
    if (typeof process !== 'undefined') {
        if ('release' in process) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

// export const getDatabaseName = async (): Promise<string> => {
//     const userProfileObj = await getUserProfile();
//     const databaseName =
//         userProfileObj.baseUrl?.replace('https://', '').replace('http://', '') +
//         '/' +
//         userProfileObj.userId +
//         '_' +
//         userProfileObj.tenantInfo.id;
//     log.debug('DatabaseName created as' + databaseName);
//     return databaseName;
// };

// build the screen pattern to be launched by caller
export function getScreenPatternToLaunch(
    entityType: CHSupportedEntityTypesEnum,
    documentType: CHSupportedDocumentTypesEnum,
    operation: CapabiltyEnum,
    operationTrigger: CHSupportedOperationTriggersEnum,
): string {
    // Ex: Matter_Document_CheckOut_Manual
    return entityType + '_' + documentType + '_' + operation + '_' + operationTrigger;
}

// // eslint-disable-next-line @typescript-eslint/no-explicit-any
// const breadCrumbsforQueue = (nodeObj: any, operation: CapabiltyEnum): any => {
//     const EMAILS = CHSupportedDocumentTypesEnum.EMAIL + 's';
//     const DOCUMENTS = CHSupportedDocumentTypesEnum.DOCUMENT + 's';
//
//     if (operation === CapabiltyEnum.ADD_EMAIL && (!nodeObj.folderArr || nodeObj.folderArr[0] !== EMAILS)) {
//         nodeObj.folderArr = [EMAILS];
//         nodeObj.folderId = undefined;
//     } else if (
//         (operation === CapabiltyEnum.ADD_DOCUMENT || operation === CapabiltyEnum.CHECKIN_DOCUMENT) &&
//         (!nodeObj.folderArr || nodeObj.folderArr[0] !== DOCUMENTS)
//     ) {
//         nodeObj.folderArr = [DOCUMENTS];
//         nodeObj.folderId = undefined;
//     }
//     return nodeObj;
// };

// // eslint-disable-next-line @typescript-eslint/no-explicit-any
// const notifyFlyout = (contextObj: any): any => {
//     if (!contextObj.isFlyoutNotified) {
//         const _chMessagingService = getCHMessagingServiceContextHandler();
//         _chMessagingService.notify({
//             message: (contextObj.noOfItems as number).toString(),
//             type: CHMessageType.flyoutEvent,
//             scope: CHMessagingScope.AllInstances,
//         });
//         contextObj.isFlyoutNotified = true;
//     }
//     return contextObj;
// };

// export const implementQueue = async (
//     // eslint-disable-next-line @typescript-eslint/no-explicit-any
//     nodeObj: any,
//     operation: CapabiltyEnum,
//     error?: string,
// ): Promise<ICHUploadResponse> => {
//     const updatedNodeObj = notifyFlyout(nodeObj);
//     const modifiedNodeObj = breadCrumbsforQueue(updatedNodeObj, operation);
//     const queueObj = await getQueueCapability();
//     const groupId = nodeObj.groupId;
//     const noOfItems = nodeObj.noOfItems;
//     const uploadTime = nodeObj.uploadTime;
//     log.debug('using Queue Manager');
//     return queueObj.doOperation(modifiedNodeObj, operation, error, groupId, noOfItems, uploadTime);
// };

export const bulkOperation = async (
    nodeObj: IContextInputType[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    operation: (nodeObj: any) => Promise<Response>,
): Promise<ICHUploadResponse> => {
    log.debug('Received as Array. Adding as builOperation');
    const respList: Promise<IQueueItem | Response>[] = [];
    nodeObj.forEach((req) => respList.push(operation(req)));
    await Promise.allSettled(respList);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const responseList: any = [];
    respList.forEach(async (resp) => {
        responseList.push(await resp);
    });
    return { responseText: 'Bulk Operation is completed' };
};

export const arrayRemove = (arr: Array<string>, value: string): Array<string> => {
    return arr.filter(function (ele) {
        return ele != value;
    });
};

export function isOC(): boolean {
    return navigator?.userAgent?.includes(ApplicationContextUI.OfficeCompanion);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function throwUnExpectedError(err: any): UnExpectedError | null {
    if (err instanceof UnExpectedError) {
        throw err;
    } else {
        return null;
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function handleUnExpectedError(err: any): string {
    if (err?.message) {
        return err.message;
    } else {
        return '';
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleCallback = async (callback: any) => {
    try {
        await callback();
    } catch (err) {
        handleUnExpectedError(err);
        log.error('Error happened in callback:' + err);
    }
};

export async function getSupportedOperationName(params: ICHDocumentInputType): Promise<CapabiltyEnum> {
    if (params.entityTypeId) {
        let result = false;
        const supportedEntityTypeMap = await getSupportedEntityTypesInfo();
        const supportedEntityType = supportedEntityTypeMap.get(params.entityTypeId);
        result =
            supportedEntityType?.toUpperCase() == DocSupportedBaseEntityTypesEnum.EMAIL ||
            supportedEntityType?.toUpperCase() == DocSupportedBaseEntityTypesEnum.MATTER;
        if (result) {
            return CapabiltyEnum.ADD_EMAIL;
        } else {
            result =
                supportedEntityType?.toUpperCase() == DocSupportedBaseEntityTypesEnum.DOCUMENT ||
                supportedEntityType?.toUpperCase() == DocSupportedBaseEntityTypesEnum.MATTER;
            if (params && !params.documentId) {
                return CapabiltyEnum.ADD_DOCUMENT;
            } else if (params && params.documentId) {
                return CapabiltyEnum.CHECKIN_DOCUMENT;
            }
        }
        return CapabiltyEnum.DEFAULT;
    }
    return CapabiltyEnum.DEFAULT;
}

export async function getSupportedEntityTypesInfo(): Promise<Map<string, string>> {
    const supportedEntityResponse = await getSupportedEntityTypes();

    const response = await supportedEntityResponse.outputInfo();
    const map = new Map<string, string>();
    for (const value in response) {
        map.set(value, response[value]);
    }
    return map;
}

export function convertToPascalCase(name: string): string {
    return name.replace(/\w+/g, (letters) => {
        return letters[0].toUpperCase() + letters.slice(1).toLowerCase();
    });
}

// /**
//  * Factory method to create QueueManager Object
//  */
// let singletonQueueObj: Promise<IQueueServiceInterface> | null = null;
// export const getQueueManagerObj = async (databaseName: string, secretKey: string): Promise<IQueueServiceInterface> => {
//     if (singletonQueueObj == null) {
//         singletonQueueObj = getQueueManager(databaseName, secretKey);
//     }
//
//     return await singletonQueueObj;
// };

/**
 * Factory method to create QueueCapability Object
 */
// let singletonObj: Promise<IQueueCapability> | null = null;
// export const getQueueCapability = async (): Promise<IQueueCapability> => {
//     if (singletonObj == null) {
//         const queueFactoryPromise: () => () => Promise<IQueueCapability> =
//             myContainer.get('Provider<IQueueCapability>');
//
//         const factoryObj = queueFactoryPromise();
//         singletonObj = factoryObj();
//     }
//
//     return await singletonObj;
// };

export function sanitizeDocName(replaceCharacter: string): string {
    return replaceCharacter.replace(/[*?^#:<>|[\]\\]/g, '');
}

export async function getTemporaryFileName(filename: string, isUpload?: boolean): Promise<string> {
    const fileNameWithoutExtension = filename.substr(0, filename.lastIndexOf('.'));
    const regexResult = filename.match(/(\.[\w-_]+)$/g);
    const extension = Array.isArray(regexResult) ? regexResult[0].toLowerCase() : '';
    // needed to avoid excel limitation of opening same name from different location
    // In case of Compatible mode of Word Doc (.doc), saveAs Word file (.docx) to unlock and move the file
    let temporaryFileName = '';
    if (isUpload) {
        temporaryFileName =
            fileNameWithoutExtension + (extension === FileExtensions.Word97_2003 ? FileExtensions.Word : extension);
    } else {
        temporaryFileName =
            fileNameWithoutExtension +
            '_' +
            (extension === FileExtensions.Word97_2003 ? FileExtensions.Word : extension);
    }
    return temporaryFileName;
}

export async function deleteFromPersistentStorage(filePaths?: string[]): Promise<void> {
    if (filePaths && filePaths.length) {
        log.debug(`delete paths from persistent storage, no of items: ${filePaths.length}`);
        await Promise.all(
            filePaths.map(async (filePath) => {
                try {
                    await FileOperations.deleteFromStorage(filePath);
                } catch (err) {
                    log.info('File is uploaded from persistent location');
                    throwUnExpectedError(err);
                }
            }),
        );
    }
}

export function isStringArray(value: unknown): value is string[] {
    return Array.isArray(value) && value.every((element) => typeof element === 'string');
}

export function isString(value: unknown): value is string {
    return typeof value === 'string';
}

export async function openHelpDocument(url: string): Promise<Response | string> {
    let authHeader = {};
    const authToken = await getAuthToken();
    if (authToken) {
        authHeader = { Authorization: 'Bearer ' + authToken };
    }
    return await FileOperations.download(url, {
        requestHeaders: authHeader,
        temporary: true,
        openWhenDone: true,
    });
}

export function isValidDocumentId(docId: string | undefined): boolean {
    if (docId && docId != '-2') {
        return true;
    }
    return false;
}
