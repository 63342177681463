import 'react-datepicker/dist/react-datepicker.css';
import { App } from './app';
import { loadApp } from './initializeCommon';
import { DefaultAuthenticationProvider } from './components/authentication/DefaultAuthenticationProvider';
import { Reducer } from 'redux';
import { IAuthenticationProvider } from './components/authentication/types';
import { setAuthenticationProvider } from './components/authentication/AuthenticationProviderService';
import reducers from './reducers/root.reducer';
import {
    loadLocationDataFromOC,
    purgeOldOCLocationHistory,
    restoreLocationDataForOC,
    setupOCLocationService,
} from './utils/ocLocationService';
import { getMaxLogMessageLength, initializeLoggerFactory } from '@wk/elm-uui-common';
import { readLogLevelFromLocalStorage } from './utils/loggingService';
import { reduxLogger } from './reduxMiddleware';
import { UUIReduxAction, UUIReduxDispatch, AppStore } from './reducers/types';
import { UUIHistory } from './hooks/useUUIHistory';
import { ComponentType, PropsWithChildren, ReactElement } from 'react';

export interface AppProps {
    apiContextRoot: string;
    apiContextPath: string;
    uiContextRoot: string;
    uiContextPath?: string;
    staticContext: string;
    token: string;
    initializationPath: string;
    iconPath: string;
    basePath?: string;
    timeoutPath: string;
    pageNotFoundParam: string;
}

export type AppProviderProps = PropsWithChildren<{}>;

export interface UUICustomComponents {
    CustomToolbarIconsComponent?: ComponentType<unknown>;
    CustomAboutComponent?: ComponentType<unknown>;
    CustomContactsComponent?: ComponentType<unknown>;
    AppProvider: (props: AppProviderProps) => ReactElement<AppProviderProps>;
}

export interface UUIConfiguration {
    isSingleTabSavedViewMode?: boolean;
    heartBeatFunction?: () => Promise<void>;
    minRefreshUUIDebounceWaitTime?: number;
    maxRefreshUUIDebounceWaitTime?: number;
    minRefreshUUIThrottleWaitTime?: number;
    maxRefreshUUIThrottleWaitTime?: number;
}

export type InitializeAppCallback = (reduxDispatch: UUIReduxDispatch, history: UUIHistory) => Promise<void>;

interface UUIInitializationOptions {
    applicationReducer?: Reducer<AppStore>;
    UUICustomComponents: UUICustomComponents;
    UUIConfiguration?: UUIConfiguration;
    applicationAuthProvider?: IAuthenticationProvider;
    initializeAppCallback?: InitializeAppCallback;
}

export async function initialize(initializationOptions: UUIInitializationOptions): Promise<void> {
    const defaultConfig = {
        applicationReducer: (state: AppStore) => state,
        UUIConfiguration: { isSingleTabSavedViewMode: false },
        applicationAuthProvider: DefaultAuthenticationProvider,
    };

    const {
        applicationReducer = defaultConfig.applicationReducer,
        UUICustomComponents,
        UUIConfiguration = defaultConfig.UUIConfiguration,
        applicationAuthProvider = defaultConfig.applicationAuthProvider,
        initializeAppCallback,
    } = initializationOptions;

    setAuthenticationProvider(applicationAuthProvider);

    initializeLoggerFactory(readLogLevelFromLocalStorage(), getMaxLogMessageLength());

    const combinedReducers = (state: AppStore | undefined, action: UUIReduxAction): AppStore => {
        const nextState = reducers(state, action);
        return { ...nextState, ...applicationReducer(nextState, action) };
    };
    const initialState = { uuiConfiguration: UUIConfiguration };

    window.addEventListener('mousedown', () => {
        restoreLocationDataForOC();
    });

    purgeOldOCLocationHistory();
    setupOCLocationService();
    loadLocationDataFromOC();

    loadApp(initialState, combinedReducers, UUICustomComponents, App, reduxLogger, initializeAppCallback);
}
