import React from 'react';
import FullScreenDialog, {
    FullScreenDialogActions,
    FullScreenDialogButton,
    FullScreenDialogContent,
} from '../common/fullScreenDialog';
import { getPassportContextLandingPageUrl } from '../../utils/passportContextHelper';
import { UserScreenProvider, useUserScreenState } from './context/userScreenContext';
import css from './userScreen.module.scss';
import UserScreenTabsView from './userScreenTabsView';
import UUIButton from '../common/uuiButton';
import { useReduxSelector } from '../../hooks/useReduxSelector';
import { useReduxDispatch } from '../../hooks/useReduxDispatch';
import { useUUIHistory } from '../../hooks/useUUIHistory';
import { manuallyIncrementPromiseCounter } from 'react-promise-tracker';
import { useNetwork } from '../../hooks/useNetwork.hook';

const UserScreen: React.FC = () => (
    <UserScreenProvider>
        <UserScreenComponent />
    </UserScreenProvider>
);

const UserScreenComponent: React.FC = () => {
    const appResources = useReduxSelector((state) => state.appResources);
    const passportContexts = useReduxSelector((state) => state.appsMenu.passportContexts);
    const fallbackLandingPage = useReduxSelector((state) => state.appResources.v3HomeUrl);
    const history = useUUIHistory();
    const [userScreenState] = useUserScreenState();
    const isUserScreenDialogOpen = useReduxSelector((state) => state.ui.userScreen.isUserScreenDialogOpen);

    const { currentNetwork, switchNetwork } = useNetwork();

    const reduxDispatch = useReduxDispatch();

    function onDone() {
        const currentNetworkId = currentNetwork?.networkId;
        const selectedNetwork = userScreenState.selectedNetwork;
        if (currentNetworkId && selectedNetwork && selectedNetwork !== currentNetworkId) {
            manuallyIncrementPromiseCounter();
            switchNetwork?.(userScreenState.selectedNetwork);
            return; // Network switch refreshes whole browser
        }

        const selectedPassportContext = passportContexts.find(
            (passportContext) => passportContext.id === userScreenState.selectedContext,
        )!;
        window.sessionStorage.activeContext = userScreenState.selectedContext;
        reduxDispatch({ type: 'CloseUserScreenDialog' });
        reduxDispatch({ type: 'SetActiveContextId', contextId: userScreenState.selectedContext });
        history.push(getPassportContextLandingPageUrl(selectedPassportContext, fallbackLandingPage));
    }

    return (
        <FullScreenDialog
            testId="userscreen-dialog"
            isOpen={isUserScreenDialogOpen || false}
            onClose={() => reduxDispatch({ type: 'CloseUserScreenDialog' })}
            AppBarProps={{ elevation: 0 }}
            showAppHeader={true}>
            <FullScreenDialogContent className={css.dialogContent}>
                <div className={css.root}>
                    <div className={css.useNameContainer}>
                        <span className={css.userName}>{appResources.v3DisplayName}</span>
                    </div>
                    <div>
                        <UserScreenTabsView />
                    </div>
                </div>
            </FullScreenDialogContent>
            <FullScreenDialogActions>
                <UUIButton
                    data-testid={'done-button'}
                    variant="contained"
                    color="primary"
                    size="large"
                    className={css.button}
                    onClick={() => onDone()}>
                    {appResources.buttonDone}
                </UUIButton>

                <FullScreenDialogButton
                    testId="cancel-button"
                    variant="outlined"
                    onClick={() => reduxDispatch({ type: 'CloseUserScreenDialog' })}>
                    {appResources.dialogCancel}
                </FullScreenDialogButton>
            </FullScreenDialogActions>
        </FullScreenDialog>
    );
};

export default UserScreen;
