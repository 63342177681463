export enum SaveAsType {
    Txt,
    Rtf,
    Template,
    Msg,
    Doc,
    Html,
    VCard,
    VCal,
    ICal,
    MsgUnicode,
    MHtml,
}
