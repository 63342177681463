import { IAuthScheme, IAuthData, IAuthorizationInfo } from '../interfaces/authScheme/type';
import { injectable } from 'inversify';
import { apiFetchForAccessToken } from '../api/fetchUtils';

@injectable()
export class OAuth implements IAuthScheme {
    getAuthData: () => Promise<IAuthorizationInfo> = async () => {
        const response = await apiFetchForAccessToken(this.authData.hostname + '/uaa/oauth/token', {
            // password: this.authData.password,
            // username: this.authData.username,
            // grant_type: this.authData.granttype,
            // scope: this.authData.scope,
            // client_secret: this.authData.client_secret,
            // client_id: this.authData.client_id,
        });
        const fetchResponse: { access_token: string } = await response.json();

        return { token: fetchResponse.access_token };
    };

    authData: IAuthData;
}
