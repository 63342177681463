import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const WkCircleCheckIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="WkCircleCheckIcon" viewBox="0 0 16 16" {...props}>
        <g>
            <g id="Icon_Filled_check-circle" transform="translate(0 0.182)">
                <path
                    id="Color_1_"
                    style={{ fill: '#648D18' }}
                    d="M8,15.8c-4.4,0-8-3.6-8-8c0-2.1,0.8-4.2,2.4-5.7C5.5-1,10.5-1,13.7,2.2s3.1,8.2,0,11.3
			C12.2,15,10.1,15.8,8,15.8z M4,7.3L4,7.3L3.2,8l3.2,3.5l6.4-6.7l-0.7-0.8L6.4,10L4,7.3z"
                />
            </g>
            <rect style={{ fill: 'none' }} width="16" height="16" />
        </g>
    </SvgIcon>
);

export default WkCircleCheckIcon;
