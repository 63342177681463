import { ContainerModule, interfaces } from 'inversify';
import { IDocActionPerformer } from './interfaces/actionPerformers/type';
import { IDocTransformer } from './interfaces/transformer/type';

import { IInputValidator } from './interfaces/inputValidators/type';
import { IDocOperationInvocation } from './interfaces/urlPattern/types';
import { JRAdapterDocumentMetadataFileNameUrlPattern } from './jRAdapter/urlPattern/documentMetadataFileNameUrlPattern';
import { JRAdapterDocumentMetadataFileNameActionPerformer } from './jRAdapter/actionPerformer/documentMetadataFileNameActionPerformer';
import { JRAdapterDocumentMetadataFileNameEntityTransformer } from './jRAdapter/transformer/documentMetadataFileNameTransformer';

import { JRAdapterDownloadDocumentUrlPattern } from './jRAdapter/urlPattern/downloadDocumentUrlPattern';
import { JRAdapterRenameDocumentUrlPattern } from './jRAdapter/urlPattern/renameDocumentUrlPattern';
import { JRAdapterRenameDocumentEntityTransformer } from './jRAdapter/transformer/renameDocumentTransformer';
import { JRAdapterRenameDocumentInputValidator } from './jRAdapter/inputValidator/renameDocumentInputValidator';
import { JRAdapterRenameFolderEntityTransformer } from './jRAdapter/transformer/renameFolderTransformer';
import { JRAdapterRenameFolderInputValidator } from './jRAdapter/inputValidator/renameFolderInputValidator';
import { JRAdapterDocumentMetadataUrlPattern } from './jRAdapter/urlPattern/documentMetadataUrlPattern';
import { JRAdapterDocumentMetadataActionPerformer } from './jRAdapter/actionPerformer/documentMetadataActionPerformer';
import { JRAdapterDocumentMetadataEntityTransformer } from './jRAdapter/transformer/documentMetadataTransformer';

import { JRAdapterCheckInUrlPattern } from './jRAdapter/urlPattern/checkInUrlPattern';
import { JRAdapterCheckInEntityTransformer } from './jRAdapter/transformer/checkInTransformer';
import { JRAdapterCheckInInputValidator } from './jRAdapter/inputValidator/checkInInputValidator';

import { JRAdapterDownloadVersionDocumentUrlPattern } from './jRAdapter/urlPattern/downloadVersionDocumentUrlPattern';
import { JRAdapterDownloadVersionDocumentInputValidator } from './jRAdapter/inputValidator/downloadVersionDocumentInputValidator';

import { JRAdapterAddEmailEntityTransformer } from './jRAdapter/transformer/addEmailTransformer';
import { JRAdapterAddEmailInputValidator } from './jRAdapter/inputValidator/addEmailInputValidator';

import { JRAdapterAddFolderEntityTransformer } from './jRAdapter/transformer/addFolderTransformer';
import { JRAdapterAddFolderInputValidator } from './jRAdapter/inputValidator/addFolderInputValidator';

import { JRAdapter } from './jRAdapter';
import { JRAdapterAccessToken } from './jRAdapter/tokenHandler/tokenHandler';

import { JRAdapterAddDocumentInputValidator } from './jRAdapter/inputValidator/addDocumentInputValidator';

import { JRAdapterTransformFulfiller } from './jRAdapter/transformFulfiller/transformFulfiller';
import { IDMToken } from './interfaces/dmTokenHandler/type';
import { ITransformFulfiller } from './interfaces/transformFulfiller/transformFulfiller';
import { IDocAdaptor } from './interfaces/adapter/types';

import { JRAdapterGetSupportedEntityTypesActionPerformer } from './jRAdapter/actionPerformer/getSupportedEntityTypesActionPerformer';
import { JRAdapterGetSupportedEntityTypesUrlPattern } from './jRAdapter/urlPattern/getSupportedEntityTypesUrlPattern';
import { JRAdapterEntityTransformer } from './jRAdapter/transformer/transformer';
import { JRAdapterActionPerformer } from './jRAdapter/actionPerformer/actionPerformer';
import { JRAdapterCommandPattern } from './jRAdapter/urlEntity/commandPattern';
import { JRAdapterOperationPattern } from './jRAdapter/urlEntity/operationPattern';
import { JRAdapterAddDocumentEntityTransformer } from './jRAdapter/transformer/addDocumentTransformer';
import { JRAdapterRenameUrlPattern } from './jRAdapter/urlEntity/renameOperationPattern';
import { JRAdapterDownloadPattern } from './jRAdapter/urlEntity/downloadPattern';
import { JRAdapterAddEmailUrlPattern } from './jRAdapter/urlPattern/addEmailUrlPattern';
import { JRAdapterValidateDocumentEntityTransformer } from './jRAdapter/transformer/ValidateDocumentTransformer';
import { JRAdapterValidateDocumentActionPerformer } from './jRAdapter/actionPerformer/validateDocumentActionPerformer';
import { JRAdapterValidateCheckInDocumentActionPerformer } from './jRAdapter/actionPerformer/validateCheckInDocumentActionPerformer';
import { JRAdapterValidateCheckInDocumentEntityTransformer } from './jRAdapter/transformer/validateCheckInDocumentTransformer';
import { JRAdapterFolderMetadataActionPerformer } from './jRAdapter/actionPerformer/folderMetadataActionPerformer';
import { JRAdapterFolderMetadataUrlPattern } from './jRAdapter/urlPattern/folderMetadataUrlPattern';

export const jrContainer = new ContainerModule((bind: interfaces.Bind) => {
    bind<IInputValidator>('JRAdapterAddDocumentInputValidator').to(JRAdapterAddDocumentInputValidator);

    bind<IDocOperationInvocation>('JRAdapterCommandUrlPattern').to(JRAdapterCommandPattern);
    bind<IDocOperationInvocation>('JRAdapterOperationUrlPattern').to(JRAdapterOperationPattern);
    bind<IDocOperationInvocation>('JRAdapterRenameUrlPattern').to(JRAdapterRenameUrlPattern);
    bind<IDocOperationInvocation>('JRAdapterDownloadUrlPattern').to(JRAdapterDownloadPattern);

    bind<IDMToken>('JRAdapterToken').to(JRAdapterAccessToken);
    bind<ITransformFulfiller>('JRAdapterTransformFulfiller').to(JRAdapterTransformFulfiller);
    bind<IDocAdaptor>('JRAdapter').to(JRAdapter);

    bind<IDocTransformer>('JRAdapterAddDocumentActionTransformer').to(JRAdapterAddDocumentEntityTransformer);
    bind<IDocTransformer>('JRAdapterActionTransformer').to(JRAdapterEntityTransformer);

    bind<IDocActionPerformer>('JRAdapterActionPerformer').to(JRAdapterActionPerformer);

    // JRAdapter AddFolder bindings
    bind<IDocTransformer>('JRAdapterAddFolderActionTransformer').to(JRAdapterAddFolderEntityTransformer);
    bind<IInputValidator>('JRAdapterAddFolderInputValidator').to(JRAdapterAddFolderInputValidator);

    // JRAdapter DeleteDocument bindings

    // JRAdapter AddEmail bindings
    bind<IDocTransformer>('JRAdapterAddEmailActionTransformer').to(JRAdapterAddEmailEntityTransformer);
    bind<IInputValidator>('JRAdapterAddEmailInputValidator').to(JRAdapterAddEmailInputValidator);
    bind<IDocOperationInvocation>('JRAdapterAddEmailUrlPattern').to(JRAdapterAddEmailUrlPattern);

    // JRAdapter CheckIn bindings
    bind<IDocTransformer>('JRAdapterCheckInActionTransformer').to(JRAdapterCheckInEntityTransformer);
    bind<IInputValidator>('JRAdapterCheckInInputValidator').to(JRAdapterCheckInInputValidator);
    bind<IDocOperationInvocation>('JRAdapterCheckInUrlPattern').to(JRAdapterCheckInUrlPattern);

    // JRAdapter DeleteEmail bindings

    // JRAdapter RenameFolder bindings

    bind<IDocTransformer>('JRAdapterRenameFolderActionTransformer').to(JRAdapterRenameFolderEntityTransformer);
    bind<IInputValidator>('JRAdapterRenameFolderInputValidator').to(JRAdapterRenameFolderInputValidator);

    // JRAdapter RenameDocument bindings

    bind<IDocTransformer>('JRAdapterRenameDocumentActionTransformer').to(JRAdapterRenameDocumentEntityTransformer);
    bind<IInputValidator>('JRAdapterRenameDocumentInputValidator').to(JRAdapterRenameDocumentInputValidator);
    bind<IDocOperationInvocation>('JRAdapterRenameDocumentUrlPattern').to(JRAdapterRenameDocumentUrlPattern);

    // JRAdapter DownloadDocument bindings

    bind<IDocOperationInvocation>('JRAdapterDownloadDocumentUrlPattern').to(JRAdapterDownloadDocumentUrlPattern);

    // JRAdapter DownloadVersionDocument bindings

    bind<IInputValidator>('JRAdapterDownloadVersionDocumentInputValidator').to(
        JRAdapterDownloadVersionDocumentInputValidator,
    );

    bind<IDocOperationInvocation>('JRAdapterDownloadVersionDocumentUrlPattern').to(
        JRAdapterDownloadVersionDocumentUrlPattern,
    );

    // JRAdapter Validate Add Document bindings

    bind<IDocTransformer>('JRAdapterValidateDocumentActionTransformer').to(JRAdapterValidateDocumentEntityTransformer);

    bind<IDocActionPerformer>('JRAdapterValidateDocumentActionPerformer').to(JRAdapterValidateDocumentActionPerformer);

    // JRAdapter Validate ChekIn Document bindings

    bind<IDocTransformer>('JRAdapterValidateCheckInDocumentActionTransformer').to(
        JRAdapterValidateCheckInDocumentEntityTransformer,
    );

    bind<IDocActionPerformer>('JRAdapterValidateCheckInDocumentActionPerformer').to(
        JRAdapterValidateCheckInDocumentActionPerformer,
    );

    // JRAdapter DocumentMetadata bindings

    bind<IDocActionPerformer>('JRAdapterDocumentMetadataActionPerformer').to(JRAdapterDocumentMetadataActionPerformer);

    bind<IDocTransformer>('JRAdapterDocumentMetadataActionTransformer').to(JRAdapterDocumentMetadataEntityTransformer);

    bind<IDocOperationInvocation>('JRAdapterDocumentMetadataUrlPattern').to(JRAdapterDocumentMetadataUrlPattern);
    // Do
    bind<IDocActionPerformer>('JRAdapterGetSupportedEntityTypesActionPerformer').to(
        JRAdapterGetSupportedEntityTypesActionPerformer,
    );

    bind<IDocOperationInvocation>('JRAdapterGetSupportedEntityTypesUrlPattern').to(
        JRAdapterGetSupportedEntityTypesUrlPattern,
    );

    bind<IDocActionPerformer>('JRAdapterDocumentMetadataFileNameActionPerformer').to(
        JRAdapterDocumentMetadataFileNameActionPerformer,
    );
    bind<IDocTransformer>('JRAdapterDocumentMetadataFileNameActionTransformer').to(
        JRAdapterDocumentMetadataFileNameEntityTransformer,
    );

    bind<IDocOperationInvocation>('JRAdapterDocumentMetadataFileNameUrlPattern').to(
        JRAdapterDocumentMetadataFileNameUrlPattern,
    );

    // JRAdapter FolderMetadata bindings
    bind<IDocActionPerformer>('JRAdapterFolderMetadataActionPerformer').to(JRAdapterFolderMetadataActionPerformer);

    bind<IDocOperationInvocation>('JRAdapterFolderMetadataUrlPattern').to(JRAdapterFolderMetadataUrlPattern);
});
