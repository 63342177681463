import { injectable } from 'inversify';
import { apiFetchGet } from '../../api/fetchUtils';
import { T360AdapterActionPerformer } from './actionPerformer';
import { convertToMapMetaDataFolder } from '../../utils/main.utils';
import { matchMetadataFolder } from '../utils/maps';
import { T360AdapterDocumentMetadataActionableEntity } from '../actionableEntity/documentMetadataActionableEntity';
import { DCResponse } from '../../types';

// const log = factory.getLogger('T360AdapterDocumentMetadataActionPerformer');

@injectable()
export class T360AdapterFolderMetadataActionPerformer extends T360AdapterActionPerformer {
    fetchRequest = apiFetchGet;

    async performAction(
        obj: T360AdapterDocumentMetadataActionableEntity,
        urlObj: Promise<string>,
    ): Promise<DCResponse> {
        const response = await super.performAction(obj, urlObj);

        const outputInfo = async () => {
            const jsonObject = await response.json();
            return convertToMapMetaDataFolder(matchMetadataFolder, jsonObject);
        };

        const response2: DCResponse = Object.assign(new DCResponse(), response);
        response2.outputInfo = outputInfo;
        return response2;
    }
}
