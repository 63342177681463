import React, { useState } from 'react';
import UUIButton from '../common/uuiButton';
import { Grid, Typography } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import css from './transferBasket.module.scss';
import { ITransferBasketItem } from './types';
import TransferBasketTreeView from './transferBasketTreeView';
import TransferBasketList, { TransferBasketListTitle } from './transferBasketList';
import { useReduxSelector } from '../../hooks/useReduxSelector';

export interface ITransferBasket {
    transferBasketItems: ITransferBasketItem[];
    onToggleTreeNode?: (treeItemClicked: ITransferBasketItem, expanded: boolean) => void;
    saveTransferBasketItems: (items: ITransferBasketItem[]) => void;
}

const TransferBasket: React.FC<ITransferBasket> = ({
    transferBasketItems,
    onToggleTreeNode,
    saveTransferBasketItems,
}) => {
    const hiddenTransferBasketItems = transferBasketItems.filter((item) => item.isHidden);
    const nonHiddenTransferBasketItems = transferBasketItems.filter((item) => !item.isHidden);
    const availableItems = nonHiddenTransferBasketItems.filter((item) => !item.hideFromAvailable);
    const selectedItems = nonHiddenTransferBasketItems.filter((item) => item.isSelected);

    const [currentlyChosenAvailableItem, setCurrentlyChosenAvailableItem] = useState<ITransferBasketItem>();
    const [currentlyChosenSelectedItem, setCurrentlyChosenSelectedItem] = useState<ITransferBasketItem>();

    const transferBasketResources = useReduxSelector((state) => ({
        listscreenColumnsAvailable: state.appResources.listscreenColumnsAvailable,
    }));

    const prepareAndSaveTransferBasketItems = (updatedTransferBasketItems: ITransferBasketItem[]) => {
        saveTransferBasketItems(hiddenTransferBasketItems.concat(updatedTransferBasketItems));
    };

    const onClickArrowUp = (selectedItem?: ITransferBasketItem) => {
        const chosenItem = selectedItem ? selectedItem : currentlyChosenSelectedItem;
        let updatedItem;
        if (chosenItem) {
            const updatedTransferBasketItems = nonHiddenTransferBasketItems.map((item: ITransferBasketItem) => {
                if (item.id !== chosenItem.id) {
                    return {
                        ...item,
                        listOrder:
                            item.isSelected && item.listOrder >= chosenItem.listOrder
                                ? item.listOrder - 1
                                : item.listOrder,
                    };
                } else {
                    updatedItem = { ...item, isSelected: false, listOrder: 0 };
                    return updatedItem;
                }
            });
            setCurrentlyChosenSelectedItem(undefined);
            prepareAndSaveTransferBasketItems(updatedTransferBasketItems);
            // if the selected item was passed in, then this was a double click,
            // so make sure the double clicked item is the currently chosen
            // available item
            setCurrentlyChosenAvailableItem(selectedItem ? updatedItem : undefined);
        }
    };

    const onClickArrowDown = (selectedItem?: ITransferBasketItem) => {
        const chosenItem = selectedItem ? selectedItem : currentlyChosenAvailableItem;
        let newlySelectedItem;
        if (chosenItem && !chosenItem.isSelected) {
            const updatedTransferBasketItems = nonHiddenTransferBasketItems.map((item: ITransferBasketItem) => {
                if (item.id !== chosenItem.id) {
                    return {
                        ...item,
                        listOrder: item.isSelected && item.listOrder >= 5 ? item.listOrder + 1 : item.listOrder,
                    };
                } else {
                    newlySelectedItem = { ...item, isSelected: true, listOrder: Math.min(selectedItems.length, 5) };
                    return newlySelectedItem;
                }
            });
            prepareAndSaveTransferBasketItems(updatedTransferBasketItems);
            if (newlySelectedItem) {
                setCurrentlyChosenSelectedItem(newlySelectedItem);
            }
        }
    };

    const onDoubleClickAvailableItem = (item: ITransferBasketItem) => {
        if (item.isItemUnselectable) {
            if (item.isSelected) {
                onClickArrowUp(item);
            } else {
                onClickArrowDown(item);
            }
        }
    };

    const onDoubleClickSelectedItem = (item: ITransferBasketItem) => {
        setCurrentlyChosenSelectedItem(item);
        onClickArrowUp(item);
    };

    const onReorderList = (reorderedItems: ITransferBasketItem[]) => {
        // reordered items is a subset of only the selected items from the transfer basket array
        // so here we map over all the items, and find the selected ones and update their listOrder
        // then save the entire list
        prepareAndSaveTransferBasketItems(
            nonHiddenTransferBasketItems.map((item) => {
                const found = reorderedItems.find((reorderedItem) => reorderedItem.id === item.id);
                if (found) {
                    return { ...item, listOrder: found.listOrder };
                } else {
                    return item;
                }
            }),
        );
    };

    return (
        <div>
            <div className={css.pickerTitle}>
                <Typography variant="h6">{transferBasketResources.listscreenColumnsAvailable}</Typography>
            </div>
            <TransferBasketTreeView
                items={availableItems}
                onToggleItem={onToggleTreeNode}
                onSelectItem={setCurrentlyChosenAvailableItem}
                onBlur={() => setCurrentlyChosenAvailableItem(undefined)}
                onDoubleClickItem={onDoubleClickAvailableItem}
            />
            <Grid container alignItems="center" justify="center">
                <UUIButton
                    data-testid="arrowUp"
                    variant="outlined"
                    color="primary"
                    classes={{ outlined: css.arrowButton }}
                    onMouseDown={() => onClickArrowUp()}
                    aria-label="Arrow Up">
                    <ArrowUpwardIcon />
                </UUIButton>
                <UUIButton
                    data-testid="arrowDown"
                    variant="outlined"
                    color="primary"
                    classes={{ outlined: css.arrowButton }}
                    onMouseDown={(e) => {
                        onClickArrowDown();
                        e.preventDefault();
                    }}
                    aria-label="Arrow Down">
                    <ArrowDownwardIcon />
                </UUIButton>
            </Grid>
            <div className={css.pickerTitle}>
                <TransferBasketListTitle itemsCount={selectedItems.length} />
            </div>
            <TransferBasketList
                items={selectedItems}
                onBlur={() => setCurrentlyChosenSelectedItem(undefined)}
                currentlySelectedItem={currentlyChosenSelectedItem}
                onSelectItem={setCurrentlyChosenSelectedItem}
                onDoubleClickItem={onDoubleClickSelectedItem}
                onReorder={onReorderList}
            />
        </div>
    );
};

export default TransferBasket;
