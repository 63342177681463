import { inject, injectable } from 'inversify';
import 'reflect-metadata';
import { factory } from '../configLog4J';
import { ICHProps, IPropKeys } from '../interfaces/operations';
import { IQueueServiceInterface } from '@wk/elm-uui-queuemanager';
import { EventType, subscribeAutoUpdateService, ToastType } from '../contextManager';
import { Locale } from '../locale';
import { Dialog } from '../configurations/dialog.config';
import { IEventService } from './eventContextHandler.service';
import { getQueue } from '../capability';
import { DialogService } from './dialog.service';

const log = factory.getLogger('CHProperties');

export interface ICHProperties {
    get(key: IPropKeys): Promise<string | undefined>;
    set(clProps: ICHProps): void;
    getAllProps(): Promise<IQueuePropItem[]>;
}

export interface IQueuePropItem {
    uniqueId: string;
    key: string;
    value: string;
}

@injectable()
export class CHProperties implements ICHProperties {
    private _eventService: IEventService;
    private _dialogService: DialogService;
    queueManagerObj!: IQueueServiceInterface;
    constructor(
        @inject('EventContextHandler') eventService: IEventService,
        @inject('DialogService') dialogService: DialogService,
    ) {
        this._eventService = eventService;
        this._dialogService = dialogService;
    }

    async get(key: IPropKeys): Promise<string | undefined> {
        log.debug('Inside get() :' + JSON.stringify(key));
        const arrStore = await (await getQueue()).getProps();
        const val = arrStore.find((v) => v.key === key);
        return val?.value;
    }

    async set(clProps: ICHProps): Promise<void> {
        log.debug('Inside set() ' + JSON.stringify(clProps));

        for (const key in clProps.props) {
            await (await getQueue()).setProps(key, clProps.props[key]);
        }
        // get autoUpdate server address from props service
        const autoUpdateServerURL = await this.get(IPropKeys.autoUpdateServerURL);
        const queueItem = await getQueue();
        if (queueItem.hasMigrationErrors) {
            this._dialogService.showDialog({
                title: Dialog.migration_failure.title,
                message: Dialog.migration_failure.message,
                buttons: Dialog.migration_failure.buttons,
            });
        }
        if (!autoUpdateServerURL) {
            // show Warning Toast
            this._eventService.publish({
                name: EventType.TOAST,
                toast: {
                    type: ToastType.WARNING,
                    toastMessage: Locale.autoUpdate.server_URL_not_provided,
                },
            });
        } else {
            subscribeAutoUpdateService((au) => {
                log.debug('state: ' + au.state);
            });
        }
    }

    async getAllProps(): Promise<IQueuePropItem[]> {
        log.debug('Inside getAllProps()');
        const arrStore = (await getQueue()).getProps();
        return arrStore;
    }
}
