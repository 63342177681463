import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import useIsMounted from '../../hooks/useIsMounted';
import { useReduxDispatch } from '../../hooks/useReduxDispatch';
import { useReduxSelector } from '../../hooks/useReduxSelector';
import { useUUIHistory } from '../../hooks/useUUIHistory';
import FullScreenDialog, {
    FullScreenDialogContent,
    FullScreenDialogActions,
    FullScreenDialogButton,
} from '../common/fullScreenDialog';
import { dispatchPerformOperation } from './context/itemScreenAsyncActions';
import { useItemScreenState } from './context/itemScreenContext';
import { getOperationsForMode } from './itemScreenHelpers';

export interface IItemScreenPopupWrapper {
    popupTitle?: string;
    onClose?: () => void;
}

export const ItemScreenPopupWrapper: React.FC<IItemScreenPopupWrapper> = ({ children, popupTitle, onClose }) => {
    const reduxDispatch = useReduxDispatch();
    const itemScreenState = useItemScreenState();
    const isMounted = useIsMounted();
    const history = useUUIHistory();
    const formMethods = useFormContext();
    const itemScreenJson = itemScreenState.itemScreenJson!;
    const applicationUrls = useReduxSelector((state) => state.applicationUrls);
    const appResources = useReduxSelector((state) => state.appResources);
    const [isOpen, setIsOpen] = useState(true);
    return (
        <FullScreenDialog
            testId="itemscreen-dialog"
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            onExited={() => reduxDispatch({ type: 'RemoveItemScreenDialog' })}
            headerText={popupTitle}
            variant="green">
            <FullScreenDialogContent>{children}</FullScreenDialogContent>
            <FullScreenDialogActions>
                {itemScreenJson.metadata.operations &&
                    getOperationsForMode(itemScreenJson.metadata.operations, itemScreenState.mode).map(
                        (operation, i) => (
                            <FullScreenDialogButton
                                key={operation.id}
                                testId="button"
                                variant={i === 0 ? 'contained' : 'outlined'}
                                onClick={() => {
                                    dispatchPerformOperation(
                                        operation,
                                        itemScreenState,
                                        reduxDispatch,
                                        applicationUrls,
                                        appResources,
                                        isMounted,
                                        formMethods,
                                        history,
                                    ).then((success) => {
                                        if (success) {
                                            setIsOpen(false);
                                            if (onClose) {
                                                onClose();
                                            }
                                        }
                                    });
                                }}>
                                {operation.displayName}
                            </FullScreenDialogButton>
                        ),
                    )}
            </FullScreenDialogActions>
        </FullScreenDialog>
    );
};
