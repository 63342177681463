import { injectable } from 'inversify';
// import { DCValidationError } from '../customError/docComponentError';
import { IInputValidator } from '../interfaces/inputValidators/type';
import { IDocServiceRequest } from '../types';

@injectable()
export class EntityContextValidator implements IInputValidator {
    performValidation = (_nodeObj: IDocServiceRequest): boolean => {
        // Condition can be added to fail the operation
        // if not received needed inputs

        // if (nodeObj.entityContext?.id && nodeObj.entityContext?.type) {
        return true;
        // } else {
        //   throw new DCValidationError(
        //     'Entity Context Validation Failed. Params: ID: ' +
        //       nodeObj.entityContext?.id +
        //       ' Type: ' +
        //       nodeObj.entityContext?.type,
        //   );
        // }
    };
}
