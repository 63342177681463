import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const WkAdjustIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="wkAdjustIcon" viewBox="0 0 24 24" {...props}>
        <path
            id="Color"
            d="M11.077,16a3.121,3.121,0,0,1-3-2.461H0V12.308H8.077a3.059,3.059,0,0,1,6,0H16v1.231H14.077A3.121,3.121,0,0,1,11.077,16Zm0-4.923a1.846,1.846,0,1,0,1.846,1.846A1.871,1.871,0,0,0,11.077,11.077ZM4.923,6.154a3.121,3.121,0,0,1-3-2.462H0V2.462H1.923A3.122,3.122,0,0,1,4.923,0a3.121,3.121,0,0,1,3,2.462H16v1.23H7.923A3.122,3.122,0,0,1,4.923,6.154Zm0-4.923A1.871,1.871,0,0,0,3.077,3.077a1.846,1.846,0,0,0,3.693,0A1.871,1.871,0,0,0,4.923,1.231Z"
            fill="#005b92"
        />
    </SvgIcon>
);

