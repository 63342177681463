export enum SaveOptions {
    /**
     * Do not save pending changes.
     */
    DoNotSaveChanges = 0,
    /**
     * Prompt the user to save pending changes.
     */
    PromptToSaveChanges = -2,
    /**
     * Save pending changes automatically without prompting the user.
     */
    SaveChanges = -1,
}
