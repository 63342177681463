/* eslint-disable max-lines-per-function */
import { AppBar, Drawer, Grid, IconButton, Toolbar, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useEffect, useState } from 'react';
import { useReduxDispatch } from '../../../hooks/useReduxDispatch';
import { useReduxSelector } from '../../../hooks/useReduxSelector';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { ListItemTooltip } from '../../listScreen/listViewItemTextRow';
import { UUIFlyoutMenu, UUIFlyoutMenuButton, UUIFlyoutMenuItem } from '../../common/uuiFlyoutMenu';
import HelpSection from '../../helpSection/helpSection';
import UUICustomDialog from '../../helpSection/uuiCustomDialog';
import WkIcon from '../../icons/wkIcon';
import WkUserProfileIcon from '../../icons/wkUserProfileIcon';
import MenuScreen from '../../menu/menuScreen';
import UserScreen from '../../userScreen/userScreen';
import HistoryScreen from '../../historyScreen/historyScreen';
import UploadQueueView from '../../historyScreen/uploadQueueView';
import { logOutSession, measureStringWidth } from '../../../utils/utilities';
import css from './uuiAppBar.module.scss';
import IconMapper from '../../common/iconMapper';
import { usePromiseTracker } from 'react-promise-tracker';
import { UUITooltip } from '../../common/uuiTooltip';
import { UUICustomComponents } from '../../../initialize';

export interface IUuiAppBar {
    customComponents?: Omit<UUICustomComponents, 'AppProvider'>;
}

export const UuiAppBar: React.FC<IUuiAppBar> = ({ customComponents = {} }) => {
    const { CustomToolbarIconsComponent, CustomAboutComponent, CustomContactsComponent } = customComponents;
    const appResources = useReduxSelector((state) => state.appResources);
    const [isMainMenuOpen, toggleMainMenu] = React.useState(false);
    const logoutUrl = useReduxSelector((state) => state.appResources.logoutUrl);
    const isUserScreenDialogOpen = useReduxSelector((state) => state.ui.userScreen.isUserScreenDialogOpen);
    const userScreenDialogKey = useReduxSelector((state) => state.ui.userScreen.userScreenDialogKey);
    const isHelpSectionDialogOpen = useReduxSelector((state) => state.ui.helpSection.isHelpSectionDialogOpen);
    const isAboutDialogOpen = useReduxSelector((state) => state.ui.helpSection.aboutDialogOpen);
    const isContactsDialogOpen = useReduxSelector((state) => state.ui.helpSection.contactsDialogOpen);
    const pageTitle = useReduxSelector((state) => state.ui.globalState.pageTitle);
    const reduxDispatch = useReduxDispatch();
    const [pageTitleValue, setPageTitleValue] = useState(pageTitle);
    const [isPageTitleTrimmed, setIsPageTitleTrimmed] = useState(false);
    const windowWidth = useWindowWidth(100);
    const isHistoryDialogOpen = useReduxSelector((state) => state.ui.historyScreen.isHistoryDialogOpen);
    const { promiseInProgress } = usePromiseTracker();

    useEffect(() => {
        const ELLIPSIS_LABEL = '...';
        const PADDING_LENGTH = 53;
        let menuLabelLength = (measureStringWidth(appResources.menuBarMainMegaMenuIcon, '16px', '400') + 41) * 2; // icon:24 + textpadding:5 + rightpadding:12
        let tempPageTitle = pageTitle === undefined ? '' : pageTitle;
        let pageTitleLength = measureStringWidth(tempPageTitle, '16px', '400');
        let isTrim = false;

        while (windowWidth - PADDING_LENGTH - menuLabelLength - pageTitleLength < 0) {
            if (!isTrim) {
                menuLabelLength = menuLabelLength / 2;
                isTrim = true;
            }
            tempPageTitle = tempPageTitle.slice(0, -1);
            pageTitleLength = measureStringWidth(tempPageTitle + ELLIPSIS_LABEL, '16px', '400');
        }

        if (isTrim) {
            tempPageTitle += ELLIPSIS_LABEL;
        }

        setPageTitleValue(tempPageTitle);
        setIsPageTitleTrimmed(isTrim);
    }, [windowWidth, pageTitle, appResources.menuBarMainMegaMenuIcon]);

    const logOut = () => {
        logOutSession(logoutUrl);
    };

    const titleTooltip = !promiseInProgress && pageTitle ? pageTitle : '';
    const pageTitleElement = (
        <UUITooltip placement="top" title={titleTooltip}>
            <Typography className={css.pageTitle}>{promiseInProgress ? 'Loading...' : pageTitleValue}</Typography>
        </UUITooltip>
    );

    return (
        <div className={css.root} id={'uuiappbar'}>
            <AppBar className={css.appBarRoot} position="static">
                <Toolbar>
                    <WkIcon viewBox="0 0 30 31" className={css.logo} />
                    <Typography variant="h6" className={css.title} title={appResources.appName}>
                        {appResources.appName}
                    </Typography>
                    {CustomToolbarIconsComponent && <CustomToolbarIconsComponent />}
                    <UploadQueueView />
                    <ListItemTooltip placement={'bottom-start'} title={appResources.appbarUserProfileToolTip}>
                        <IconButton
                            data-testid="userProfileIcon"
                            color="inherit"
                            disableRipple={isUserScreenDialogOpen}
                            onClick={() => {
                                isHistoryDialogOpen && reduxDispatch({ type: 'CloseHistoryScreenDialog' });
                                isHelpSectionDialogOpen && reduxDispatch({ type: 'CloseHelpSectionDialog' });
                                !isUserScreenDialogOpen && reduxDispatch({ type: 'OpenUserScreenDialog' });
                                isAboutDialogOpen && reduxDispatch({ type: 'AboutDialogOpen', isOpen: false });
                                isContactsDialogOpen && reduxDispatch({ type: 'ContactsDialogOpen', isOpen: false });
                            }}
                            className={isUserScreenDialogOpen ? css.activeProfile : undefined}>
                            <WkUserProfileIcon className={css.wkUserProfileIcon} />
                            <ArrowDropDownIcon />
                        </IconButton>
                    </ListItemTooltip>
                    <div className={css.flyoutMenu}>
                        <UUIFlyoutMenu anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                            <div className={css.flyoutMenuButton}>
                                <UUIFlyoutMenuButton
                                    aria-label="operations"
                                    data-testid="appBarHeaderOperations"
                                    edge="end"
                                />
                            </div>
                            <UUIFlyoutMenuItem
                                data-testid="helpButton"
                                icon={<IconMapper iconName="iconhelp" />}
                                onClick={() => {
                                    isUserScreenDialogOpen && reduxDispatch({ type: 'CloseUserScreenDialog' });
                                    !isHelpSectionDialogOpen && reduxDispatch({ type: 'OpenHelpSectionDialog' });
                                    isAboutDialogOpen && reduxDispatch({ type: 'AboutDialogOpen', isOpen: false });
                                    isContactsDialogOpen &&
                                        reduxDispatch({ type: 'ContactsDialogOpen', isOpen: false });
                                }}>
                                {appResources.applicationLinkHelp}
                            </UUIFlyoutMenuItem>
                            <UUIFlyoutMenuItem
                                data-testid="logOutButton"
                                icon={<IconMapper iconName="iconlogout" />}
                                onClick={logOut}>
                                {appResources.applicationLinkLogout}
                            </UUIFlyoutMenuItem>
                        </UUIFlyoutMenu>
                    </div>
                </Toolbar>
            </AppBar>
            <HelpSection />
            <UUICustomDialog
                CustomChildren={CustomAboutComponent}
                isDialogOpen={useReduxSelector((state) => state.ui.helpSection.aboutDialogOpen)}
                closeDialogAction={() => reduxDispatch({ type: 'AboutDialogOpen', isOpen: false })}
                headerTitle={appResources.helpPageAbout.replace('{appName}', appResources.appName)}
                isCopyFeatureEnabled={true}
            />
            <UUICustomDialog
                CustomChildren={CustomContactsComponent}
                isDialogOpen={useReduxSelector((state) => state.ui.helpSection.contactsDialogOpen)}
                closeDialogAction={() => reduxDispatch({ type: 'ContactsDialogOpen', isOpen: false })}
                headerTitle={appResources.helpPageContactLink.replace('{appName}', appResources.appName)}
            />
            <UserScreen key={userScreenDialogKey} />
            <HistoryScreen />

            <AppBar className={css.secondAppBarRoot} position="static">
                <Toolbar className={css.menuToolBar}>
                    <Grid container alignItems="center">
                        <Grid container item xs={3} justify="flex-start" className={css.leftContainer}>
                            <IconButton
                                edge="start"
                                color="inherit"
                                data-testid="mainMenuButton"
                                aria-label="menu"
                                onClick={() => toggleMainMenu(true)}>
                                <MenuIcon />
                                <Typography className={css.menu}>{appResources.menuBarMainMegaMenuIcon}</Typography>
                            </IconButton>
                        </Grid>
                        {isPageTitleTrimmed && !promiseInProgress ? (
                            <Grid container item xs={9} justify="center">
                                {pageTitleElement}
                            </Grid>
                        ) : (
                            <div className={css.rightContainer}>{pageTitleElement}</div>
                        )}
                    </Grid>
                </Toolbar>
            </AppBar>
            <Drawer
                open={isMainMenuOpen}
                classes={{
                    paper: css.menuBar,
                }}
                ModalProps={{
                    BackdropProps: {
                        classes: { root: css.menuBar },
                    },
                }}>
                <MenuScreen toggleMainMenu={() => toggleMainMenu(false)} />
            </Drawer>
        </div>
    );
};
