import { Typography } from '@material-ui/core';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { useSelector } from 'react-redux';
import { useReduxDispatch } from '../../hooks/useReduxDispatch';
import { AppStore } from '../../reducers/types';
import css from './fileDragAndDrop.module.scss';

interface IFileDragAndDrop {
    actionFileDropHandler: (file: any) => void;
    isFileDropZoneEnabled: boolean;
    isTwoLinesLabel?: boolean;
    isThreeLinesLabel?: boolean;
    height?: string;
    areaLabel?: string;
}

const FileDragAndDrop: React.FC<IFileDragAndDrop> = ({
    actionFileDropHandler,
    isFileDropZoneEnabled,
    isTwoLinesLabel,
    isThreeLinesLabel,
    height,
    areaLabel = '',
    children,
}) => {
    const [isActiveDropTarget, setIsActiveDropTarget] = useState(false);
    const reduxDispatch = useReduxDispatch();
    const activelyDraggedFilesCount = useSelector((state: AppStore) => state.ui.globalState.activelyDraggedFilesCount);
    const appResources = useSelector((state: AppStore) => state.appResources);
    const activeZoneLabel =
        activelyDraggedFilesCount > 1
            ? appResources.dragAndDropMultipleFileText
                  .replace('{0}', activelyDraggedFilesCount.toString())
                  .replace('{1}', areaLabel)
            : appResources.dragAndDropSingleFileText.replace('{0}', areaLabel);

    return isFileDropZoneEnabled && activelyDraggedFilesCount > 0 ? (
        <Dropzone
            noClick={true}
            onDrop={(acceptedFiles) => {
                if (isFileDropZoneEnabled) {
                    const filePaths: string[] = [];
                    acceptedFiles.map((file: any) => {
                        filePaths.push(file.path);
                    });
                    actionFileDropHandler(filePaths);
                }
                setIsActiveDropTarget(false);
                reduxDispatch({ type: 'SetDraggedFileIsOverValidDropTarget', isOverValidDropTarget: false });
            }}
            onDragOver={(e) => {
                e.dataTransfer.dropEffect = 'copy';
            }}
            onDragEnter={(e) => {
                e.dataTransfer.dropEffect = 'copy';
                setIsActiveDropTarget(true);
                reduxDispatch({ type: 'SetDraggedFileIsOverValidDropTarget', isOverValidDropTarget: true });
            }}
            onDragLeave={() => {
                setIsActiveDropTarget(false);
                reduxDispatch({ type: 'SetDraggedFileIsOverValidDropTarget', isOverValidDropTarget: false });
            }}>
            {({ getRootProps, getInputProps }) => (
                <div
                    {...getRootProps({ className: isFileDropZoneEnabled ? css.dropzoneWrapper : '' })}
                    data-testid={'dropzone-wrapper'}>
                    <>
                        <input {...getInputProps()} data-testid={'dropzone-input'} />
                        <div
                            className={
                                isActiveDropTarget && activelyDraggedFilesCount > 0
                                    ? css.dropzoneActive
                                    : css.dropzoneNotActive
                            }
                            style={{ height: height ? height : '100%' }}>
                            {isActiveDropTarget && (
                                <div className={css.activeBorder}>
                                    <div className={css.activeLabelWrapper}>
                                        <Typography
                                            className={
                                                isThreeLinesLabel
                                                    ? css.threeLinesLabel
                                                    : isTwoLinesLabel
                                                    ? css.twoLinesLabel
                                                    : css.activeLabel
                                            }>
                                            {activeZoneLabel}
                                        </Typography>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={css.activeChildren}>{children}</div>
                    </>
                </div>
            )}
        </Dropzone>
    ) : (
        <>{children}</>
    );
};

export default FileDragAndDrop;
