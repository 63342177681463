import React, { useEffect, useMemo } from 'react';
import Tabs, { Tab } from '../../common/tabs';
import FullScreenDialog, {
    FullScreenDialogButton,
    FullScreenDialogContent,
    FullScreenDialogActions,
} from '../../common/fullScreenDialog';
import TabPanel from '../../common/tabPanel';
import { IListScreenPageData } from '../types';
import ColumnPicker from './columnPicker';
import {
    useListScreenEditDialogDispatch,
    useListScreenEditDialogState,
    ListScreenEditDialogProvider,
} from './context/listScreenEditDialogContext';
import { calculatePageObject } from './context/listScreenEditDialogReducer';
import FilterPicker from './filterPicker';
import TabOptions from './tabOptions';
import css from './listScreenEditDialog.module.scss';
import { IDialogState, IListScreenEditDialogState } from './types';
import { getSavedViewTabName } from '../listScreenHelpers';
import { useListScreenDispatch, useListScreenState } from '../context/listScreenContext';
import { useRefreshList } from '../context/listScreenHooks';
import { useReduxSelector } from '../../../hooks/useReduxSelector';

interface IListScreenEditDialog {
    completedRender?: () => void;
}

export const ListScreenEditDialog: React.FC<IListScreenEditDialog> = (props) => (
    <ListScreenEditDialogProvider>
        <ListScreenEditDialogComponent {...props} />
    </ListScreenEditDialogProvider>
);

export const ListScreenEditDialogComponent: React.FC<IListScreenEditDialog> = ({ completedRender }) => {
    const listScreenState = useListScreenState();
    const listScreenDispatch = useListScreenDispatch();
    const dialogState = useListScreenEditDialogState((s) => s.dialog) as IDialogState;
    const dispatch = useListScreenEditDialogDispatch();

    const appResources = useReduxSelector((state) => state.appResources);
    const disableSavedViewTabs = useReduxSelector((state) => state.uuiConfiguration?.isSingleTabSavedViewMode);

    useEffect(() => {
        if (completedRender) {
            completedRender();
        }
    }, [completedRender]);

    const headerText = useMemo(() => {
        const tabName = getSavedViewTabName(listScreenState.listData!.page.savedView, listScreenState.metadata!);
        return tabName === listScreenState.metadata!.screenDisplayName && listScreenState.isEmbeddedList
            ? appResources.listscreenDefaultDropdownText
            : tabName;
    }, [listScreenState, appResources]);

    if (!listScreenState.listData!.page) {
        return null;
    }
    return (
        <FullScreenDialog
            testId="listscreen-edit-dialog"
            isOpen={listScreenState.listScreenEditDialogOpen}
            onClose={() => listScreenDispatch({ type: 'CloseListScreenEditDialog' })}
            headerText={appResources.listscreenFilterCustomizeTab.replace('{tabName}', headerText)}
            variant="green">
            <FullScreenDialogContent>
                <Tabs
                    TabIndicatorProps={{
                        style: { backgroundColor: '#fff' },
                    }}
                    className={css.tabsRoot}
                    textColor="primary"
                    value={dialogState.selectedTab}
                    aria-label="List Screen Components"
                    onChange={(_, value) => {
                        dispatch({ type: 'SetTab', tabIndex: value });
                    }}>
                    <Tab
                        data-testid="filters-tab"
                        classes={{ selected: css.selectedTab, root: css.tab }}
                        label={appResources.listscreenFilters}
                    />
                    <Tab
                        data-testid="columns-tab"
                        classes={{ selected: css.selectedTab, root: css.tab }}
                        label={appResources.listscreenEditColumns}
                    />
                    {!disableSavedViewTabs && (
                        <Tab
                            data-testid="tabs-tab"
                            classes={{ selected: css.selectedTab, root: css.tab }}
                            label={appResources.listscreenTabs}
                        />
                    )}
                </Tabs>
                {dialogState.visitedTabs.includes(0) && (
                    <TabPanel value={dialogState.selectedTab} index={0}>
                        <div className={css.tabPanelContainer}>
                            <FilterPicker key={listScreenState.listScreenEditDialogKey} />
                        </div>
                    </TabPanel>
                )}
                {dialogState.visitedTabs.includes(1) && (
                    <TabPanel value={dialogState.selectedTab} index={1}>
                        <div className={css.tabPanelContainer}>
                            <ColumnPicker key={listScreenState.listScreenEditDialogKey} />
                        </div>
                    </TabPanel>
                )}
                {!disableSavedViewTabs && dialogState.visitedTabs.includes(2) && (
                    <TabPanel value={dialogState.selectedTab} index={2}>
                        <div className={css.tabPanelContainer}>
                            <TabOptions key={listScreenState.listScreenEditDialogKey} />
                        </div>
                    </TabPanel>
                )}
            </FullScreenDialogContent>
            <FullScreenDialogActions>
                <ViewButton
                    testId="preview-button"
                    pageData={listScreenState.listData!.page}
                    buttonText={appResources.listscreenEditPreview}
                />
                <FullScreenDialogButton
                    testId="cancel-button"
                    variant="outlined"
                    onClick={() => listScreenDispatch({ type: 'CloseListScreenEditDialog' })}>
                    {appResources.dialogCancel}
                </FullScreenDialogButton>
            </FullScreenDialogActions>
        </FullScreenDialog>
    );
};

interface IViewButton {
    pageData: IListScreenPageData;
    buttonText: string;
    testId: string;
}

const ViewButton: React.FC<IViewButton> = ({ pageData, buttonText, testId }) => {
    const state = useListScreenEditDialogState() as IListScreenEditDialogState;
    const listScreenDispatch = useListScreenDispatch();
    const refreshList = useRefreshList();
    const applyChanges = () => {
        listScreenDispatch({ type: 'CloseListScreenEditDialog' });
        const fetchPostData = calculatePageObject(state, pageData);
        refreshList({ postObject: fetchPostData });
    };
    return (
        <FullScreenDialogButton testId={testId} variant="contained" onClick={applyChanges}>
            {buttonText}
        </FullScreenDialogButton>
    );
};

export default ListScreenEditDialog;
