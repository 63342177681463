import { CapabiltyEnum } from '../../enums/capability';
import { injectable } from 'inversify';
import { JRAdapterOperationPattern } from '../urlEntity/operationPattern';
import { IDocumentMetadataFileName } from '../../types';

@injectable()
export class JRAdapterDocumentMetadataFileNameUrlPattern extends JRAdapterOperationPattern {
    operationName = CapabiltyEnum.DOCUMENT_METADATA_FILENAME;

    async getUrl(nodeObj: IDocumentMetadataFileName): Promise<string> {
        let baseUrl = await super.getUrl(nodeObj);

        if (nodeObj?.documentFolderId) {
            baseUrl =
                baseUrl +
                '?searchParameters=' +
                '((parentDocument.id)(EQUALS)(' +
                nodeObj.documentFolderId +
                ');(documentFileName)(EQUALS)(' +
                encodeURIComponent(nodeObj.fileName ? nodeObj.fileName : '') +
                '))&sortOrder=Ascending';
        } else {
            baseUrl =
                baseUrl +
                '?searchParameters=' +
                '((parentDocument.id)(IS_NULL)();(matter.id)(EQUALS)(' +
                nodeObj.entityContext.id +
                ');(documentFileName)(EQUALS)(' +
                encodeURIComponent(nodeObj.fileName ? nodeObj.fileName : '') +
                '))&sortOrder=Ascending';
        }

        return baseUrl;
    }
}
