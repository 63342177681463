// eslint-disable-next-line @typescript-eslint/no-explicit-any
import { getCHMessagingServiceContextHandler } from '../contextHandler';
import { CHMessageType, CHMessagingScope } from '../enum/enum';
import { MessageService } from '../services';
import { Locale } from '../locale';

export const notifyFlyout = (contextObj: any): any => {
    if (!contextObj.isFlyoutNotified) {
        const _chMessagingService = getCHMessagingServiceContextHandler();
        _chMessagingService.notify({
            message: (contextObj.noOfItems as number).toString(),
            type: CHMessageType.flyoutEvent,
            scope: CHMessagingScope.AllInstances,
        });
        contextObj.isFlyoutNotified = true;
    }
    return contextObj;
};

export const getErrorMessageOnFileDownloadFromOC = (
    messagingServiceObj: MessageService,
    error: Error,
): string | null => {
    switch (error.message) {
        case Locale.checkOnSpecificError.download_filePath_exceeded_limit:
            // eslint-disable-next-line no-case-declarations
            const fileNameLength = +error?.['error']?.replace(/\D/g, '');
            if (fileNameLength && fileNameLength > 0) {
                return messagingServiceObj.compileTemplate(Locale.responseText.download_filePath_exceeded_limit, {
                    maxFileNameLength: fileNameLength,
                });
            } else {
                return Locale.responseText.download_folderPath_exceeded_limit;
            }
        case Locale.checkOnSpecificError.download_interrupt_OC:
            return Locale.documents.download_permission_not_available;
        default:
            return null;
    }
};
