import { ClientObject } from '../common/clientObject';
import { RequestType } from '../comObject';
import { MailItem } from './mailItem';

export class Inspector extends ClientObject {
    public get currentItem(): MailItem {
        return this.reference.requestObject({
            type: RequestType.GetProperty,
            creator: MailItem,
            name: 'CurrentItem',
        }).value;
    }
}
