import { IDocTransformer } from '../../interfaces/transformer/type';
import { IActionableEntity } from '../../interfaces/actionEntity/type';
import { injectable } from 'inversify';
import { factory } from '../../ConfigLog4j';
import { IMatterDoc, IAddDocument } from '../../types';
import { JRAdapterAddDocumentActionableEntity } from '../actionableEntity/addDocumentActionableEntity';
import { mapHref, mapNames, urlMap } from '../utils/maps';
import { toBase64 } from '../../utils/main.utils';
import { attribute_Add, IAddDocument_JR } from '../types';

const log = factory.getLogger('JRAdapterAddDocumentEntityTransformer');

@injectable()
export class JRAdapterValidateDocumentEntityTransformer implements IDocTransformer {
    matterDoc: IMatterDoc;
    async transformIntoActionableEntity(nodeObj: IAddDocument): Promise<IActionableEntity> {
        log.debug('Calling transformIntoActionableEntity');
        const obj = {} as JRAdapterAddDocumentActionableEntity;

        const nodeObj1 = nodeObj as IAddDocument_JR;
        obj.attributes = {} as attribute_Add & { documentFile: { name: string } };

        if (nodeObj1.documentName != undefined || nodeObj1.documentName) {
            obj.attributes.documentFile = { name: nodeObj1.documentName };
        }
        // else {
        //   obj.attributes.documentFile = { name: nodeObj1.name };
        // }

        if (nodeObj1.name != undefined || nodeObj1.name) {
            obj.attributes.name = nodeObj1.name;
        }

        if (nodeObj1.folderPath && nodeObj1?.entityContext?.type) {
            obj.attributes.parentDocument = {
                href: '../../' + urlMap[nodeObj1.entityContext.type] + '/' + nodeObj1.folderPath,
            };
        } else {
            obj.attributes.parentDocument = {};
        }

        if (nodeObj1.extendedProps?.description) {
            obj.attributes.comments = nodeObj1.extendedProps?.description;
        }

        if (nodeObj1?.entityContext?.id && nodeObj1?.entityContext?.type) {
            obj.attributes[mapNames[nodeObj1.entityContext.type]] = {
                href: '../../' + mapHref[nodeObj1.entityContext.type] + '/' + nodeObj1.entityContext.id,
            };
        }

        if (nodeObj1.entityContext?.type) {
            obj.attributes.associatedEntityName = nodeObj1.entityContext.type;
        }

        if (nodeObj?.file) {
            obj.data = await toBase64(nodeObj1.file);
        }
        return obj;
    }
}
