import { IconButton, InputAdornment } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useReduxSelector } from '../../hooks/useReduxSelector';
import { UUIInput } from '../common/uuiInput';
import WkSpyglassIcon from '../icons/wkSpyglassIcon';
import { useListScreenDispatch, useListScreenState } from './context/listScreenContext';
import { useRefreshList } from './context/listScreenHooks';
import { generatePagePostObject } from './listScreenHelpers';
import css from './quickSearch.module.scss';

interface IQuickSearchProps {
    width?: string;
}

export const QuickSearch: React.FC<IQuickSearchProps> = ({ width = '-webkit-fill-available' }) => {
    const appResources = useReduxSelector((state) => state.appResources);
    const listScreenState = useListScreenState();
    const [searchString, setSearchString] = useState(listScreenState.quickSearchString || '');
    const refreshlist = useRefreshList();
    const listScreenDispatch = useListScreenDispatch();
    const SEARCH_TEXT_MIN = 2;
    const SEARCH_TIME_LIMIT = 500;

    const conductSearch = (clearClick = false) => {
        const postObj = generatePagePostObject(listScreenState.listData!.page, { pageNumber: 1 });
        const searchKeywords = clearClick ? '' : searchString;
        listScreenDispatch({ type: 'SetQuickSearch', quickSearchString: searchKeywords });
        refreshlist({ postObject: postObj, newQuickSearchText: searchKeywords });
    };

    const refreshDebounce = useDebouncedCallback(() => {
        conductSearch();
    }, SEARCH_TIME_LIMIT);

    const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        refreshDebounce.cancel(); // short-circuit ongoing-typing
        const newInputValue = event.target.value ? event.target.value : '';
        setSearchString(newInputValue);
        if (newInputValue.trim().length >= SEARCH_TEXT_MIN) {
            refreshDebounce();
        } else if (newInputValue === '') {
            conductSearch(true);
        }
    };

    const onClearClick = () => {
        setSearchString('');
        if (searchString.trim().length > 0) {
            conductSearch(true);
        }
    };

    return (
        <UUIInput
            id="quickSearch"
            margin="none"
            placeholder={appResources.searchLabel}
            name="quickSearch"
            data-testid="quickSearch"
            onChange={onSearchChange}
            classes={{
                input: css.quickSearchInput,
                root: css.quickSearchInputRoot,
            }}
            style={{
                width: width,
            }}
            value={searchString}
            endAdornment={
                <InputAdornment className={css.icons} position="end">
                    {searchString.length >= SEARCH_TEXT_MIN && (
                        <IconButton
                            data-testid="quickSearchCloseIcon"
                            className={css.iconButton}
                            aria-label="clear"
                            size="small"
                            onClick={onClearClick}>
                            <CloseIcon className={css.closeIcon} />
                        </IconButton>
                    )}
                    <IconButton className={css.iconButton} aria-label="spyglass" size="small">
                        <WkSpyglassIcon viewBox="0 0 18 18" className={css.iconSpyglass} />
                    </IconButton>
                </InputAdornment>
            }
        />
    );
};
