import { getLogger } from '../logging';

export interface UUIFetchInitialization {
    handler400: (response: Response) => void;
    handler401: (response: Response) => void;
    handler403: (response: Response) => void;
    handler404: (response: Response) => void;
    handler500: (response: Response) => void;
    handlerNetworkDown: () => void;
    handlerNetworkRestored: () => any;
    handlerAPIVersionUpdateRequired: () => any;
    heartBeatFunction: () => Promise<void>;
    baseApiVersion?: string;
}

let _initializationObject: UUIFetchInitialization;

const logger = () => getLogger('UUIFetch');

export const UUIFetch = {
    initialize: (initializationObject: UUIFetchInitialization): void => {
        _initializationObject = initializationObject;
    },
    setBaselineAPIVersion: (version: string): void => {
        _initializationObject.baseApiVersion = version;
    },
    // eslint-disable-next-line max-lines-per-function
    fetch: async (urlOrRequestObj: RequestInfo, fetchOptions?: RequestInit): Promise<Response> => {
        const versionHasChanged = async (r: Response): Promise<boolean> => {
            // we must clone the response here because fetch api only allows the body to be read once.
            const responseClone = r.clone();
            const responseText = await responseClone.text();
            try {
                const json = JSON.parse(responseText) as { version: string };
                if (
                    json.version &&
                    _initializationObject.baseApiVersion &&
                    json.version.toString() !== _initializationObject.baseApiVersion
                ) {
                    logger().error(
                        `JSON API Version change detected. New version: ${json.version.toString()} Baseline version: ${
                            _initializationObject.baseApiVersion
                        }`,
                    );
                    return true;
                } else {
                    return false;
                }
            } catch (e) {
                // this was not a json response, so skip versioning check
                return false;
            }
        };

        let response: Response;
        try {
            response = await fetch(urlOrRequestObj, fetchOptions);
            if (response.status === 400) {
                logger().error(`400 Response received for: ${urlOrRequestObj.toString()}`);
                _initializationObject.handler400(response.clone());
                return response;
            } else if (response.status === 401) {
                logger().error(`401 Response received for: ${urlOrRequestObj.toString()}`);
                _initializationObject.handler401(response.clone());
                return response;
            } else if (response.status === 403) {
                logger().error(`403 Response received for: ${urlOrRequestObj.toString()}`);
                _initializationObject.handler403(response.clone());
                return response;
            } else if (response.status === 404) {
                logger().error(`404 Response received for: ${urlOrRequestObj.toString()}`);
                _initializationObject.handler404(response.clone());
                return response;
            } else if (response.status === 500) {
                logger().error(`500 Response received for: ${urlOrRequestObj.toString()}`);
                _initializationObject.handler500(response.clone());
                return response;
            } else if (response.status === 503) {
                UUIFetch.triggerNetworkDownHandler();
                return response;
            }

            if (await versionHasChanged(response)) {
                _initializationObject.handlerAPIVersionUpdateRequired();
                return response;
            }

            return response;
        } catch (err) {
            UUIFetch.triggerNetworkDownHandler();
            throw err;
        }
    },

    triggerNetworkDownHandler: async (): Promise<void> => {
        logger().error('Network is down. Initiating heartbeat function');
        _initializationObject.handlerNetworkDown();
        await _initializationObject.heartBeatFunction();
        logger().error('Heartbeat function resolved. Network is restored');
        _initializationObject.handlerNetworkRestored();
    },
};
