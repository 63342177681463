import { ItemOperationInterface, ItemOperationService } from './itemOperation.service';
import { itemOperationContainer } from './itemOperation.module';

export const ItemOperation = itemOperationContainer.get<ItemOperationInterface>(ItemOperationService);

export {
    ItemOperationSaved,
    ItemOperationClosed,
    ItemOperationOpened,
    ItemOperationSaving,
} from './itemOperation.service';
