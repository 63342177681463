import { getLogger, initializeLoggerFactory, LogLevel } from '@wk/elm-uui-common';
import { Log } from 'oidc-client';

export class LoggingService {
    public static setupOidcLogger(): void {
        getLogger('t360.loggingService').debug('Initializing oidc-client log.');
        const logger = () => getLogger('node-modules.oidc-client');
        Log.logger = {
            error: (message: string) => {
                logger().error(message);
            },
            warn: (message: string) => {
                logger().warn(message);
            },
            info: (message: string) => {
                logger().info(message);
            },
            debug: (message: string) => {
                logger().debug(message);
            },
        };
        // transferring all logs from oidc to our logger.
        // Log level filtering will be applied by our logger.
        Log.level = Log.DEBUG;
    }

    public static initializeLoggerFactory(): void {
        const logLevel =
            localStorage.getItem('loggerLevel') !== null
                ? (localStorage.getItem('loggerLevel') as unknown as LogLevel)
                : undefined;
        initializeLoggerFactory(logLevel);
    }
}
