import { myContainer } from './core';
import { ISecurityContextHandler, IDocContextHandler, INavigationContextHandler } from './interfaces/interface';
import { ApplicationContextUI, ContextHandlers, ContextIndicatorEnum } from './enum/applicationContextUI.enum';
import { IEventService, RouterService, BannerService } from './services';
import { factory } from './configLog4J';
import { ICHMessagingInterface } from './services/interface/message.interface';
import { IAutoUpdate } from './services/interface/autoUpdate.interface';

const log = factory.getLogger('ContextHandler');

/**
 * operation - string,
 * nodeObj -
 * ocOnly - if the call will not be send to DocComponent
 */
export const getContext = (operation: string): Promise<IDocContextHandler> => {
    const factory: (type: string) => (operation: string) => Promise<IDocContextHandler> =
        myContainer.get('Provider<IContextHandler>');
    const isOC = navigator?.userAgent?.includes(ApplicationContextUI.OfficeCompanion);
    let contextHandler;
    if (isOC) {
        log.debug('Getting OC Context Handler');
        contextHandler = ContextHandlers.OfficeCompanionContextHandler;
    }
    // else if (window.context === ApplicationContextUI.Browser) {
    else {
        log.debug('Getting Browser Context Handler');
        // TODO - Replace with elseIf for Browser UI
        contextHandler = ContextHandlers.BrowserContextHandler;
    }
    const contextObj = factory(contextHandler);
    return contextObj(operation);
};

export const getSecurityContextHandler = (): ISecurityContextHandler => {
    if (ContextIndicatorEnum.OC.match(getContextEnvInfo())) {
        return myContainer.get<ISecurityContextHandler>('SecurityOCContextHandler');
    } else {
        return myContainer.get<ISecurityContextHandler>('SecurityUIContextHandler');
    }
};

export const getNavigationContextHander = (): INavigationContextHandler => {
    return myContainer.get<INavigationContextHandler>('NavigationOCContextHandler');
};
export const getEventContextHandler = (): IEventService => {
    return myContainer.get<IEventService>('EventContextHandler');
};

export const getBannerService = (): BannerService => {
    return myContainer.get<BannerService>('BannerService');
};

let singletonMessagingObj: ICHMessagingInterface | null;
export const getMessagingServiceObj = (): ICHMessagingInterface => {
    if (singletonMessagingObj == null) {
        singletonMessagingObj = myContainer.get<ICHMessagingInterface>('CHMessagingService');
    }
    return singletonMessagingObj;
};

export const getCHMessagingServiceContextHandler = (): ICHMessagingInterface => {
    return getMessagingServiceObj();
};

export const getRouterServiceContextHandler = (): RouterService => {
    if (ContextIndicatorEnum.OC.match(getContextEnvInfo())) {
        return myContainer.get<RouterService>('RouterService');
    } else {
        throw new Error('Not Supported');
    }
};

export const getContextEnvInfo = (): ContextIndicatorEnum => {
    const isOC = navigator?.userAgent?.includes(ApplicationContextUI.OfficeCompanion);

    if (isOC) {
        return ContextIndicatorEnum.OC;
    } else {
        return ContextIndicatorEnum.UI;
    }
};

export const getAutoUpdateServiceContextHandler = (): IAutoUpdate => {
    if (ContextIndicatorEnum.OC.match(getContextEnvInfo())) {
        return myContainer.get<IAutoUpdate>('AutoUpdateService');
    } else {
        throw new Error('Not Supported');
    }
};
