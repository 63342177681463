import { injectable } from 'inversify';
import { IDocOperationInvocation } from '../../interfaces/urlPattern/types';
import { IDocServiceRequest, IMatterDoc } from '../../types';
import { ITransformFulfiller } from '../../interfaces/transformFulfiller/transformFulfiller';

@injectable()
export class T360AdapterTransformFulfiller implements ITransformFulfiller {
    getMatterDetails = async (_nodeObj: IDocServiceRequest, _urlObj: IDocOperationInvocation): Promise<IMatterDoc> => {
        return {} as IMatterDoc;
    };
}
