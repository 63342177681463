import { OperationPattern } from '../../interfaces/urlPattern/types';
import { IDocServiceRequest } from '../../types';
import { injectable } from 'inversify';

@injectable()
export class JRAdapterOperationPattern extends OperationPattern {
    async getUrl(nodeObj: IDocServiceRequest): Promise<string> {
        const url = this.hostName + '/' + this.objectRelative + '/' + this.ObjectTypeMap[nodeObj.entityContext.type];
        return url;
    }
}
