import { IInputValidator } from '../../interfaces/inputValidators/type';
import { injectable } from 'inversify';
import { IDownloadVersionDocument } from '../../types';
import { DCValidationError } from '../../customError/docComponentError';

@injectable()
export class JRAdapterDownloadVersionDocumentInputValidator implements IInputValidator {
    performValidation = (nodeObj: IDownloadVersionDocument): boolean => {
        // Condition can be added to fail the operation
        // if not received needed inputs
        if (nodeObj?.docId !== undefined && nodeObj.extendedProps.version) {
            return true;
        } else {
            throw new DCValidationError(
                'JR Passport. Download Version Document Input validation. Params : docId: ' +
                    nodeObj?.docId +
                    'version: ' +
                    nodeObj.extendedProps?.version,
            );
        }
    };
}
