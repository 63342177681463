import { OfficeContext } from '../common/officeContext';
import { Application } from './application';
import { Workbook } from './workbook';
import { RequestType } from '../comObject';

export class ExcelContext extends OfficeContext {
    /** returns excel application object */
    public get application(): Application {
        return this.reference.requestObject({
            type: RequestType.GetProperty,
            creator: Application,
            name: 'Application',
            cacheName: 'application',
            isReleasable: false,
        }).value;
    }

    /** returns currently open workbook */
    public get currentWorkbook(): Workbook {
        return this.reference.requestObject({
            type: RequestType.Invoke,
            creator: Workbook,
            name: 'GetCurrentWorkbook',
        }).value;
    }
}
