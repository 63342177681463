import { IUuiHelpSection, UUIReduxAction } from './types';

const setIsHelpSectionDialogOpen = (state: IUuiHelpSection, isOpen: boolean): IUuiHelpSection => {
    return { ...state, isHelpSectionDialogOpen: isOpen };
};

export const helpSectionReducer = (state: IUuiHelpSection, action: UUIReduxAction): IUuiHelpSection => {
    switch (action.type) {
        case 'OpenHelpSectionDialog':
            return setIsHelpSectionDialogOpen(state, true);
        case 'CloseHelpSectionDialog':
            return setIsHelpSectionDialogOpen(state, false);
        case 'AboutDialogOpen':
            return { ...state, aboutDialogOpen: action.isOpen };
        case 'ContactsDialogOpen':
            return { ...state, contactsDialogOpen: action.isOpen };
        case 'LogLevelSelectorDialogOpen':
            return { ...state, logLevelSelectorDialogOpen: action.isOpen };
        case 'SaveLogLevel':
            return { ...state, logLevel: action.updateLogLevel };
    }
    return state;
};
