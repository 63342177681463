import { injectable } from 'inversify';
import { baseCommunicationService, BaseCommunicationEvent } from '@wk/office-companion-js-common';
import { Observable } from 'rxjs';

export enum AppVersionChannel {
    VersionStream = 'app-version-stream',
}

export enum AutoUpdateStateType {
    Initial = 'initial',
    ClientsAttached = 'clientsAttached',
    CheckingUpdates = 'checkingUpdates',
    AvailableForDownload = 'availableForDownload',
    Downloading = 'downloading',
    ReadyForInstall = 'readyForInstall',
    InstallationInProgress = 'installationInProgress',
}

export enum AutoUpdateCommandType {
    CheckForUpdates = 'checkForUpdates',
    ProvideUpdateServerInfo = 'provideUpdateServerInfo',
    Download = 'download',
    Install = 'install',
    Cancel = 'cancel',
}

export enum AutoUpdateErrorType {
    ClientsDisconnected = 'clientsDisconnected',
    EmptyUpdateServerInfo = 'emptyUpdateServerInfo',
    UpdateServerError = 'updateServerError',
    Canceled = 'canceled',
}

export enum AutoUpdateRequestType {
    UpdateServerInfo = 'updateServerInfo',
}

export enum AutoUpdateRequestCondition {
    OnCheckForUpdate = 'onCheckForUpdate',
    OnDownload = 'onDownload',
}

export enum AutoUpdateValueType {
    State = 'state',
    Request = 'request',
}

export interface AutoUpdateRequest {
    type: AutoUpdateRequestType;
    condition: AutoUpdateRequestCondition;
}

export interface AutoUpdateError {
    type: AutoUpdateErrorType;
    message: string;
}

export interface AppVersionInfo {
    newVersionExist?: boolean;
    currentVersion: string;
    latestVersion?: string;
}

export interface DownloadProgressInfo {
    total: number;
    delta: number;
    transferred: number;
    percent: number;
    bytesPerSecond: number;
}

export interface AutoUpdateState {
    state: AutoUpdateStateType;
    progress?: DownloadProgressInfo;
    versionInfo: AppVersionInfo;
    error?: AutoUpdateError;
}

export interface AutoUpdateValue {
    valueType: AutoUpdateValueType;
    state?: AutoUpdateState;
    request?: AutoUpdateRequest;
}

export interface UpdateServerInfo {
    /**
     * Network address of the update server.
     *
     */
    url: string;

    /**
     * Headers sent to update server in request.
     *
     * Usually contain authorization or other required data.
     *
     */
    requestHeaders?: Record<string, string | number | string[]>;
}

export interface AutoUpdateStateChangeRequest {
    /**
     * Command to send to auto-update application service.
     *
     * This command should be send to oc to move auto-update to required state.
     *
     * __Important__:
     *
     * Auto-update State can be moved only to the next logical step of auto-update process.
     * If the current step of auto-update process is not correspond the type of command then
     * this request would do nothing and state won't be changed.
     *
     * Only the following schema correspond command allowed actions:
     *
     *
     */
    // TODO: add schema here
    command: AutoUpdateCommandType;

    /**
     * Error send to auto-update process.
     *
     * This property allows to send an Error information to auto-update.
     *
     * For example:
     * If process has been canceled on client side due to error this error info can be sent to
     * auto-update along with 'cancel'{@link AutoUpdateRequestType.Cancel} command. All other
     * instances listening for this stream will be notified about the error.
     *
     */
    error?: AutoUpdateError;

    /**
     * Update Server Info.
     *
     * This containing required parameters about a server the updates is going to be checked on/download from.
     *
     */
    serverData?: UpdateServerInfo;
}

@injectable()
export class AppVersionService {
    public versionStream(
        dataStream: Observable<AutoUpdateStateChangeRequest>,
    ): Observable<BaseCommunicationEvent<AutoUpdateValue>> {
        return baseCommunicationService.on<AutoUpdateValue>(AppVersionChannel.VersionStream, dataStream);
    }
}
