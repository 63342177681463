import { IDocServiceRequest, IEntityContext } from '../../types';
import { JRAdapterOperationPattern } from './operationPattern';

interface IDocServiceRenamePattern extends IDocServiceRequest {
    docId: string;
    entityContext: IEntityContext;
}

export class JRAdapterRenameUrlPattern extends JRAdapterOperationPattern {
    async getUrl(nodeObj: IDocServiceRenamePattern): Promise<string> {
        const url =
            this.hostName +
            '/' +
            this.objectRelative +
            '/' +
            this.ObjectTypeMap[nodeObj.entityContext.type] +
            '/' +
            nodeObj.docId;
        return url;
    }
}
