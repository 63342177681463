import { ClientObject, PropertyInit } from '../common/clientObject';
import { Window } from './window';
import { RequestType } from '../comObject';

export class Application extends ClientObject implements PropertyInit {
    private properties = this.clientObjectInitPropsInfo({
        busy: { propertyName: 'busy', name: 'Busy', extension: true },
    });

    public getPropertyInitializers(): Map<keyof Application, () => void> {
        return new Map<keyof Application, () => void>(
            Object.values(this.properties).map(({ propertyName, name, extension }) => [
                propertyName,
                (): void =>
                    this.reference.request({
                        type: RequestType.GetProperty,
                        cacheName: propertyName,
                        name,
                        extension,
                    }),
            ]),
        );
    }

    /**
     * Returns `true` if application is not able to process incoming requests at the current moment, `false` otherwise.
     */
    public get busy(): boolean {
        return this.reference.getValue<boolean>(this.properties.busy.propertyName).value;
    }

    /**
     * https://docs.microsoft.com/en-us/office/vba/api/excel.application.activewindow
     * https://docs.microsoft.com/en-us/dotnet/api/microsoft.office.interop.excel._application.activewindow?view=excel-pia
     */
    public get activeWindow(): Window {
        return this.reference.requestObject({
            type: RequestType.GetProperty,
            creator: Window,
            name: 'ActiveWindow',
        }).value;
    }
}
