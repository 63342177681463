import { INotification, UUIReduxAction } from './types';

export const notificationsReducer = (state: INotification[], action: UUIReduxAction): INotification[] => {
    switch (action.type) {
        case 'ShowNotification':
            return state.concat([action.notification]);
        case 'DismissNotification':
            return state.map<INotification>((notification) =>
                notification.key === action.key ? { ...notification, dismissed: true } : { ...notification },
            );
        case 'RemoveNotification':
            return state.filter((notification) => notification.key !== action.key);
    }
    return state;
};
