import { IconButton } from '@material-ui/core';
import ArrowBackIosSharpIcon from '@material-ui/icons/ArrowBackIosSharp';
import React, { ComponentType } from 'react';
import { useReduxSelector } from '../../hooks/useReduxSelector';
import FullScreenDialog, { FullScreenDialogContent } from '../common/fullScreenDialog';
import UUIButton from '../common/uuiButton';
import css from './uuiCustomDialog.module.scss';

export interface IUuiCustomDialog {
    CustomChildren?: ComponentType<unknown>;
    isDialogOpen: boolean;
    closeDialogAction: () => void;
    headerTitle: string;
    isCopyFeatureEnabled?: boolean;
}

const UUICustomDialog: React.FC<IUuiCustomDialog> = ({
    CustomChildren,
    isDialogOpen,
    closeDialogAction,
    headerTitle,
    isCopyFeatureEnabled,
}) => {
    const appResources = useReduxSelector((state) => state.appResources);

    const handleCopyToClipboardAction = () => {
        const copyBoxElement = document.getElementById('customChildren');
        copyBoxElement!.contentEditable = 'true';
        copyBoxElement!.focus();
        document.execCommand('selectAll');
        document.execCommand('copy');
        copyBoxElement!.contentEditable = 'false';
    };

    return (
        <FullScreenDialog
            testId="custom-dialog"
            isOpen={isDialogOpen}
            onClose={closeDialogAction}
            AppBarProps={{ elevation: 0 }}
            showAppHeader={true}>
            <FullScreenDialogContent className={css.fullScreenDialogContent}>
                <div className={css.headerContainer}>
                    <IconButton
                        size="small"
                        className={css.chevronStyle}
                        data-testid="custom-dialog-back-chevron"
                        onClick={closeDialogAction}>
                        <ArrowBackIosSharpIcon color="primary" classes={{ root: css.backIconRoot }} />
                    </IconButton>
                    <span className={css.header} data-testid="custom-dialog-title">
                        {headerTitle}
                    </span>
                </div>
                <div id="customChildren" className={css.outerContainer} data-testid="custom-dialog-children">
                    {CustomChildren && <CustomChildren />}
                </div>
                {isCopyFeatureEnabled && (
                    <div className={css.actionContainer}>
                        <UUIButton
                            data-testid="custom-dialog-copy-button"
                            variant="outlined"
                            classes={{ outlined: css.copyButton }}
                            onClick={() => handleCopyToClipboardAction()}>
                            {appResources.helpPageCopyToClipboard}
                        </UUIButton>
                    </div>
                )}
            </FullScreenDialogContent>
        </FullScreenDialog>
    );
};

export default UUICustomDialog;
