import React, { useEffect } from 'react';
import { useReduxDispatch } from '../../../hooks/useReduxDispatch';
import { useReduxSelector } from '../../../hooks/useReduxSelector';

// this adds event listeners to the window for when the user drags files into the UUI
// it will set the state.uui.globalState.activelyDraggedFilesCount to the number of
// files the user is dragging in, and set it back to 0 when the user drags out of the window
export const GlobalDragAndDropHandler: React.FC = () => {
    const reduxDispatch = useReduxDispatch();

    const draggedFileIsOverValidDropTarget = useReduxSelector(
        (state) => state.ui.globalState.draggedFileIsOverValidDropTarget,
    );

    useEffect(() => {
        const dragEnter = (e: DragEvent) => {
            if (e.dataTransfer) {
                const filesBeingDragged = Array.from(e.dataTransfer.items).filter((i: any) => i.kind === 'file');
                if (filesBeingDragged.length > 0 && !draggedFileIsOverValidDropTarget) {
                    e.dataTransfer.dropEffect = 'none';
                }
                reduxDispatch({ type: 'SetActivelyDraggedFilesCount', numFiles: filesBeingDragged.length });
            }
            e.preventDefault();
        };
        const dragOver = (e: DragEvent) => {
            if (e.dataTransfer && !draggedFileIsOverValidDropTarget) {
                e.dataTransfer.dropEffect = 'none';
            }
            e.preventDefault();
        };
        const dragLeave = (e: any) => {
            if (!e.fromElement) {
                reduxDispatch({ type: 'SetActivelyDraggedFilesCount', numFiles: 0 });
            }
        };
        const drop = (e: DragEvent) => {
            reduxDispatch({ type: 'SetActivelyDraggedFilesCount', numFiles: 0 });
            e.preventDefault();
        };

        window.addEventListener('dragenter', dragEnter);
        window.addEventListener('dragover', dragOver);
        window.addEventListener('dragleave', dragLeave);
        window.addEventListener('drop', drop);
        return () => {
            window.removeEventListener('dragenter', dragEnter);
            window.removeEventListener('dragover', dragOver);
            window.removeEventListener('dragleave', dragLeave);
            window.removeEventListener('drop', drop);
        };
    }, [draggedFileIsOverValidDropTarget, reduxDispatch]);

    return null;
};
