import React from 'react';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import CommentIcon from '@material-ui/icons/Comment';
import EditIcon from '@material-ui/icons/Edit';
import ErrorIcon from '@material-ui/icons/Error';
import MailIcon from '@material-ui/icons/Mail';

interface IListScreenIconProps {
    iconName: string;
    screenId?: number;
    documentId?: number;
    fileName?: string;
    applicationDownloadDocumentUrl?: string;
}

const ListScreenIcon: React.FC<IListScreenIconProps> = ({ iconName }) => {
    switch (iconName) {
        case 'iconError':
            return <ErrorIcon />;
        case 'iconComment':
            return <CommentIcon />;
        case 'iconPencil':
            return <EditIcon />;
        case 'iconEmail':
            return <MailIcon />;
        default:
            return <BrokenImageIcon />;
    }
};

export default ListScreenIcon;
